import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';

import postStyles from '../../../../api/intergration/pim/products/dropshipment/posts/postStyles';
import Brand from './steps/Brand';
import Categories from './steps/Categories';
import Colors from './steps/Colors';
import Fabric from './steps/Fabric';
import OtherData from './steps/OtherData';
import ProductDescription from './steps/ProductDescription';
import ProductNames from './steps/ProductNames';
import VAT from './steps/VAT';

export default function Steps({ sites, loading }) {
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const { activeStep } = nike_wizard.productData;
  const { productId } = nike_wizard;

  const [brands, setBrands] = React.useState([]);

  React.useEffect(() => {
    postStyles(productId, 'JD')
      .then((res) => {
        setBrands(res.data.Data);
      })
      .catch((err) => console.error(err));
  }, []);

  switch (activeStep) {
    case 0: // brand
      return (
        <Brand
          productItems={brands}
          activeStep={activeStep}
          loading={loading.find((item) => item.name === 'styles')}
        />
      );
    case 1: // product names
      return (
        <ProductNames
          sites={sites}
          loading={loading.find((item) => item.name === 'styles')}
        />
      );
    case 2: // product description
      return (
        <ProductDescription
          sites={sites}
          loading={loading.find((item) => item.name === 'attributes')}
        />
      );
    case 3: // colours
      return (
        <Colors
          loading={loading.find((item) => item.name === 'attributes')}
        />
      );
    case 4: // fabric
      return (
        <Fabric
          sites={sites}
          loading={loading.find((item) => item.name === 'attributes')}
        />
      );
    case 5: // categories
      return (
        <Categories
          loading={loading.find((item) => item.name === 'categories')}
        />
      );
    case 6: // vat
      return (
        <VAT
          productId={productId}
          sites={sites}
        />
      );
    case 7: // other_attributes
      return (
        <OtherData />
      );
    case 8: // review
      return (
        <Typography variant="h6" align="center" className="font-gothammedium">
          Please review your values on the left then continue.
        </Typography>
      );
    default:
      return null;
  }
}
