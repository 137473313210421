import postRequest from '../../../postRequest';

/**
 * Post request to create new brand group.
 *
 * @param {number} siteId - 1
 * @param {object} body
 * @returns {
 *  "BrandGroup": {
 *    "BrandGroupId": 52,
 *    "BrandGroupName": "Matt test",
 *    "BrandCount": 1,
 *    "Created": "2023-12-12T09:33:02.15",
 *    "Updated": "0001-01-01T00:00:00"
 *  },
 *  "InsertedBrandIds": "123"
 */
async function postBrandGroup(siteId, body) {
  const endpoint = `/oc-data-management/v1/brand-group/${siteId}`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Add Product Log')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postBrandGroup;
