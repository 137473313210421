/* eslint-disable no-nested-ternary */
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

const PartialTabRow = memo(({
  data,
  index,
  style,
  setSelected,
  setOpenModal,
}) => {
  const obj = data[index];

  const operationTypes = useSelector((state) => state.bulk_operations.operation_types);

  const handleOperationName = (bulkOperationRequestType) => {
    const foundMatchingOperation = Object.keys(operationTypes ?? {})?.find((key) => operationTypes[key] === bulkOperationRequestType);
    if (foundMatchingOperation) {
      return foundMatchingOperation;
    }
    return 'Operation Type Not Found';
  };

  const handleCrudType = (crudType) => {
    switch (crudType) {
      case 1:
        return 'Insert';
      case 2:
        return 'Read';
      case 3:
        return 'Update';
      case 4:
        return 'Delete';
      case 8:
        return 'Update';
      default:
        return 'CRUD Type Not Found';
    }
  };

  const getSuccessfulTotal = (crudType) => {
    const deleted = obj.Deleted;

    const inserted = obj.Inserted;
    const updated = obj.Updated;
    const updatedAndInserted = inserted + updated;

    const no_change = obj.NoChange;

    let total = 0;
    if (crudType === 1 || crudType === 3 || crudType === 8) {
      total += updatedAndInserted;
    } else if (crudType === 4) {
      total += deleted;
    }

    total += no_change;

    return total;
  };

  return (
    <section key={obj.Id} className="border-b border-neutral-400" style={style}>
      <div className="relative flex items-center w-full">
        <div className="flex flex-col w-3/4 gap-1">
          <p className="pt-2 text-sm">
            <span className="font-gothammedium">ID:</span>
            {' '}
            {obj.Id}
          </p>
          <p className="text-sm">
            Author:
            {' '}
            {obj.UserName}
          </p>
          <p className="text-sm">
            Operation:
            {' '}
            {handleOperationName(obj.BulkOperationRequestType)}
          </p>

          <div className="flex items-center justify-start gap-2">
            <p className="text-sm">
              Type:
              {' '}
              {handleCrudType(obj.CrudType)}
            </p>
            <span className="flex gap-1 text-sm whitespace-nowrap">
              <p>(</p>
              <Tooltip title="Successful">
                <p>{getSuccessfulTotal(obj.CrudType)}</p>
              </Tooltip>
              <p>/</p>
              <Tooltip title="Total"><p>{obj.Total}</p></Tooltip>
              <p>)</p>
            </span>
          </div>

          <p className="text-sm">
            Started:
            {' '}
            {new Date(obj.ProcessStartDate).toLocaleString('en-GB')}
          </p>
        </div>
        <div className="flex items-center justify-center w-1/4">
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setSelected(obj);
              setOpenModal(true);
            }}
          >
            More Info
          </Button>
        </div>
      </div>
    </section>
  );
}, areEqual);

export default PartialTabRow;
