import patchRequest from '../../../../../patchRequest';

/**
 * Calls set-fulfilment endpoint to enable fulfilment for SKU level product in PIM DB.
 *
 * @param {number} variantId - 15974689
 * @param {string} siteCode - JD
 * @param {string} sourceName
 * @param {boolean} fulfilmentEnabled - true
 * @param {boolean} inReview - true
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchSetFulfilment(
  variantId,
  siteCode,
  sourceName,
  fulfilmentEnabled,
  inReview,
) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/set-fulfilment';

  const body = {
    variantId,
    siteCode,
    sourceName,
    fulfilmentEnabled,
    inReview,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Fulfilment')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchSetFulfilment;
