import 'react-quill/dist/quill.snow.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import postProductAttributes from '../../../../../../../api/oc_product/product/attributes/post/postProductAttributes';
import parseDateString from '../../../../../../../utils/parseDateString';

function DescriptionEditor({ content }) {
  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language?.language?.LanguageId);
  const spanRef = React.useRef(null);
  const [updated, setUpdated] = React.useState('');
  const [disableUpdate, setDisableUpdated] = React.useState(true);
  const [disableCancel, setDisableCancel] = React.useState(true);

  const [editDescription, setEditDescription] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [isEditing, setIsEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const paragraphRef = React.useRef(null);

  const getFormattedDate = () => {
    const date = new Date();
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };
  React.useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = description;
    }
  }, [spanRef.current, description, editDescription]);

  React.useEffect(() => {
    if (content) {
      if (content.Value) {
        setDescription(content.Value);
        setUpdated(content.Updated);
      } else {
        setDescription('');
      }
    }
  }, [content]);

  React.useEffect(() => {
    if (editDescription) {
      setDisableUpdated(false);
      setDisableCancel(false);
      setIsEditing(true);
    }
  }, [editDescription]);

  React.useEffect(() => {
    if (paragraphRef.current && paragraphRef.current.innerHTML === '' && description) {
      paragraphRef.current.innerHTML = getFormattedDate();
    }
  }, [updated]);

  const resetDescription = () => {
    setEditDescription(!editDescription);
    const value = content?.Value ? content?.Value : '';
    setDescription(value);
    setDisableUpdated(true);
    setDisableCancel(true);
    setIsEditing(false);
  };
  const normalizeString = (str) => str
    .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
    .replace(/\s+/g, ' ') // Replace multiple whitespace with a single space
    .trim();

  const updateProductDescription = () => {
    const normalizedContentValue = normalizeString(content.Value);
    const normalizedDescription = normalizeString(description);

    if (normalizedContentValue !== normalizedDescription) {
      if (description === '') {
        toast.error('Description is required', {
          autoClose: 4000,
        });
        setEditDescription(!editDescription);
        setDescription(content.Value);
        setUpdated(content.Updated);
      } else {
        setLoading(true);
        const updatedAttribute = {
          attributesValues: [
            {
              productAttributeDefKey: content.ProductAttributeKey,
              attributeDataTypeId: content.AttributeDataTypeId,
              productAttributeDefId: content.ProductAttributeDefId,
              value: description,
              updated: getFormattedDate(),
            },
          ],
        };
        const message = content.ProductAttributeKey === 'product_description'
          ? 'Description Updated'
          : 'Translated Description Updated';
        postProductAttributes(siteId, Number(id), languageId, updatedAttribute, message)
          .then(() => {
            setDescription(updatedAttribute.attributesValues[0].value);
            setEditDescription(!editDescription);
            setUpdated(getFormattedDate()); // Update the "Updated" timestamp
          })
          .catch((err) => {
            console.error(err);
            setDescription(content.Value);
            setUpdated(content.Updated);
          })
          .finally(() => {
            setLoading(false);
            setEditDescription(!editDescription);
            setDisableUpdated(true);
            setDisableCancel(true);
            setIsEditing(false);
          });
      }
    } else {
      toast.error('No change', {
        autoClose: 4000,
      });
    }
  };
  const handleEditClick = () => {
    setEditDescription(!editDescription);
    setIsEditing(true);
    setDisableUpdated(false);
    setDisableCancel(false);
  };

  return (
    <div>
      {editDescription ? (
        <div className="w-full h-96 overflow-y-auto">
          <ReactQuill
            value={description}
            theme="snow"
            onChange={(newValue) => {
              const val = newValue === '<p><br></p>' ? '' : newValue;
              setDescription(val);
            }}
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
          />
        </div>
      ) : (
        <div className="w-full h-96 overflow-y-auto">
          <Box onClick={() => setEditDescription(true)} className="justify-between mt-1 bg-white">
            <span ref={spanRef} className="h-full" />
          </Box>
        </div>
      )}
      <div className="flex items-center justify-between mt-1 overflow-y-auto">
        <div className="flex items-center mt-3 space-x-2">
          <Stack className="mt-1">
            <Typography className="font-gothammedium" variant="body2">
              Updated:
            </Typography>
            {loading ? (
              <Skeleton width="12rem" />
            ) : (
              <Typography ref={paragraphRef} className="MuiTypography-root MuiTypography-body2 css-9v08br-MuiTypography-root" style={{ whiteSpace: 'nowrap' }}>
                {parseDateString(updated)}
              </Typography>
            )}
          </Stack>
        </div>
        <div className="flex items-center mt-3 space-x-2 ml-auto">
          {!isEditing && (
            <Button
              onClick={handleEditClick}
              id="edit-button"
              variant="contained"
              size="small"
              className="mr-2 cursor-pointer max-h-8 px-2 py-1"
            >
              Edit
            </Button>

          )}
        </div>
        <div className="flex items-center mt-3 space-x-2">
          {isEditing && (
            <>
              <Button
                onClick={resetDescription}
                disabled={disableCancel}
                id="cancel-button"
                variant="contained"
                color="info"
                size="small"
                className="mr-2 cursor-pointer max-h-8 px-2 py-1"
              >
                Cancel
              </Button>
              <Button
                onClick={updateProductDescription}
                id="update-button"
                type="submit"
                variant="contained"
                disabled={disableUpdate}
                color="info"
                size="small"
                className="mr-2 cursor-pointer max-h-8 px-2 py-1"
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </div>

  );
}

export default DescriptionEditor;
