import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React from 'react';

import Logo from '../../assets/PrismIcon.png';

function Maintenance() {
  const message = 'Prism is currently undergoing maintenance';

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <img className="mb-4 w-80" src={Logo} alt="PRISM" />
      <section className="flex flex-col items-center p-4 bg-white border rounded-lg text">
        <h1 className="flex items-center pb-4 text-2xl font-extrabold items-base text-error-500">
          <WarningRoundedIcon className="mr-2 text-error-500" />
          Scheduled Maintenance
        </h1>
        <p className="text-lg">{message}</p>
      </section>
    </div>
  );
}

export default Maintenance;
