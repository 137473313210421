/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20240620() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S9/E16 - NEW PAGES</h2>
      <p className="-mt-4">20th June 2024</p>

      <h4>
        Notice
      </h4>

      <p><strong>Central PIM shutdown date Friday 21st June, still stands. Image checker has been migrated to Prism.</strong></p>

      <h3 className="font-gothammedium text-neutral-700">New Pages</h3>

      <h4>Image Checker</h4>

      <ul className="-mt-4">
        <li>
          Accessed - Images / Amplience Image Checker
        </li>
        <li>Allow users to search and view images an media from Amplience, before they are imported in to PIM</li>
        <li>Move Media checker & Amplience Image Checker to - Images</li>
        <li>Rename product info/Media Check to - Media Checker</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Improvements</h3>

      <h4>PDP</h4>

      <ul className="-mt-4">
        <li>(Release Date) - Set release date to empty. This will set the product to unreleased</li>
        <li>(Release date) - Allow users to set AM / PM times</li>
        <li>(Categories and Attributes) - Show if tick boxes (True / False) is blank / empty</li>
        <li>(General) - Style section not refreshing after switching products through the PDP</li>
      </ul>

      <h4>PLP</h4>

      <ul className="-mt-4">
        <li>Increase the width of the ID column in search results</li>
      </ul>

      <h4>Margin Threshold</h4>
      <ul className="-mt-4">
        <li>Added detailed logs for an margin threshold changes</li>
        <li>Add reset button to threshold value column</li>
      </ul>

      <h4>Attribute Definition</h4>
      <ul className="-mt-4">
        <li>UI Improvements, increased the Name Value column. Users are able to see longer names</li>
      </ul>

      <h4>Bulk Operations (Logs)</h4>
      <ul className="-mt-4">
        <li>New UI and look for Bulk Logs</li>
        <li>Added filters to bulk operations</li>
        <ul>
          <li>Users can search logs by user name, operation, date .. Etc etc</li>
        </ul>
        <li>Remove "file update" update text from release date dropdown</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bug fixes</h3>
      <ul className="-mt-4">
        <li>(Fixed) - Margin Threshold : Taking time to update threshold value and getting undefined error message but updating in the background</li>
        <li>(Fixed) - Margin Threshold : UI is not clear on more info pop up</li>
        <li>(Fixed) - Bulk Operations : value showing empty in more info pop up</li>
      </ul>
    </section>
  );
}

export default Release20240620;
