import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TODO: Replace this with the Fascias endpoint
import Fascias from '../../../../dummy_data/Fascias';
import CountrySelect from '../../../../layout/header/country_select/CountrySelect';
import { setProductSitesCountry, setProductSitesFascia } from '../../../../redux/wizard/nike/nike_wizard';

function WhereToSell() {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Grid container className="sm:p-8 sm:px-12 sm:py-0">
      <Grid item xs={12} container className="justify-center sm:justify-start">
        <Typography
          variant={matches ? 'h6' : 'body1'}
          className="font-gothammedium"
          marginTop={4}
          marginBottom={4}
        >
          Select Where To Sell The Product -
          {' '}
          {nike_wizard.productId}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        spacing={2}
        className="px-4 overflow-y-scroll sm:pr-8 sm:px-0"
        sx={{ maxHeight: '30rem' }}
      >
        {nike_wizard.sites.map((obj) => (
          <Grid item xs={12} key={obj.id}>
            <Stack direction={matches ? 'row' : 'column'} spacing={2} alignItems="center">
              <FormControl fullWidth>
                <Autocomplete
                  id="fascia-select"
                  options={Fascias}
                  disabled // May need to remove in future
                  getOptionLabel={(option) => option.name}
                  value={Fascias.find((option) => option.code === obj.fascia)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fascia"
                      variant="outlined"
                      placeholder="Select fascia"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    dispatchToRedux(setProductSitesFascia({ id: obj.id, fascia: value.name }));
                  }}
                />
              </FormControl>

              <FormControl fullWidth>
                <CountrySelect
                  multiple
                  disabled
                  defaultCountries={obj.countries}
                  limitTags={99}
                  onChange={(value) => {
                    if (value == null) return;
                    dispatchToRedux(setProductSitesCountry({ id: obj.id, countries: value }));
                  }}
                />
              </FormControl>

              {/* {index !== 0 ? (
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() => {
                    dispatch({ type: 'REMOVE_ROW', payload: { id: obj.id } });
                  }}
                >
                  <CancelIcon />
                </IconButton>
              ) : (
                matches && <Box sx={{ width: '5.5rem', height: '1rem' }} />
              )} */}
            </Stack>
          </Grid>
        ))}

        {/* <Grid item xs={12}>
          <Button variant="text" onClick={() => dispatch({ type: 'ADD_ROW' })}>
            + Add Another
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default WhereToSell;
