import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import getBrands from '../../../api/oc_product/brands/gets/getBrands';
import deleteSizeMap from '../../../api/oc_product/sizeMapping/delete/deleteSizeMap';
import getSizeMap from '../../../api/oc_product/sizeMapping/get/getSizeMap';
import getSizeMapCountryRegion from '../../../api/oc_product/sizeMapping/get/getSizeMapCountryRegion';
import patchEditSizeMap from '../../../api/oc_product/sizeMapping/patch/patchEditSizeMap';
import postSizeMap from '../../../api/oc_product/sizeMapping/post/postSizeMap';
import Modal from '../../../components/Modal';

function SizeMapping() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOverrideChecked = searchParams.get('isOverrideChecked');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [departmentCode, setDepartmentCode] = React.useState('');
  const [brandName, setBrandName] = React.useState('');
  const [brandsList, setBrandsList] = React.useState([]);
  const [sizeTo, setSizeTo] = React.useState('');
  const [sizeFrom, setSizeFrom] = React.useState('');
  const [countryRegionIdFrom, setCountryRegionIdFrom] = React.useState();
  const [countryRegionIdTo, setCountryRegionIdTo] = React.useState();
  const [countryRegionFromList, setCountryRegionFromList] = React.useState([]);
  const [countryRegionToList, setCountryRegionToList] = React.useState([]);
  const [sizingAttribute, setSizingAttribute] = React.useState('');

  const [regionNameTo, setRegionNameTo] = React.useState();
  const [regionNameFrom, setRegionNameFrom] = React.useState();
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(false);

  const [editRegionNameTo, setEditRegionNameTo] = React.useState();
  const [editRegionNameFrom, setEditRegionNameFrom] = React.useState();

  const [openModal, setOpenModal] = React.useState(false);
  // const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [editRow, setEditRow] = React.useState(false);
  const [deleteRow, setDeleteRow] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [searchByBrandOrDeptCode, setSearchByBrandOrDeptCode] = React.useState(1);
  const [editBrandId, setEditBrandId] = React.useState(null);
  const [editSizeMappingId, setEditSizeMappingId] = React.useState(null);
  const [editDepartmentCode, setEditDepartmentCode] = React.useState('');
  const [editBrandName, setEditBrandName] = React.useState('');
  const [editSizeTo, setEditSizeTo] = React.useState('');
  const [editSizeFrom, setEditSizeFrom] = React.useState('');
  const [editSizingAttribute, setEditSizingAttribute] = React.useState('');

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          0.2 + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            0.2 + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              0.2 + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));
  const handleChangeBrandOrDeptCode = (event) => {
    setSearchByBrandOrDeptCode(event.target.value);
  };

  const fetchCountryRegionIds = async () => {
    try {
      const res = await getSizeMapCountryRegion();
      if (res) {
        setCountryRegionFromList(res.data);
      }
    } catch (error) {
      console.error('Error fetching country region ids:', error);
    }

    try {
      const sizeMapsData = await getSizeMap(siteId);
      const uniqueCountryRegionIdsTo = Array.from(new Set(sizeMapsData?.data?.map((item) => item.RegionNameTo)));
      setCountryRegionToList(uniqueCountryRegionIdsTo);
    } catch (error) {
      console.error('Error fetching country region ids:', error);
    }
  };

  const fetchBrands = async () => {
    try {
      getBrands()
        .then((res) => {
          setBrandsList(res.data);
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const fetchData = async () => {
    try {
      await fetchCountryRegionIds();
      await fetchBrands();

      const sizeMapsData = await getSizeMap(siteId);

      const mappedRows = sizeMapsData.data.map((item, index) => ({
        id: index + 1,
        departmentCode: item.DepartmentCode,
        brandId: item.BrandId,
        brandName: item.BrandName,
        sizeTo: item.SizeTo,
        sizeFrom: item.SizeFrom,
        countryRegionIdFrom: item.CountryRegionIdFrom,
        countryRegionIdTo: item.CountryRegionIdTo,
        sizingAttribute: item.SizingAttribute,
        sizeMappingId: item.SizeMappingId,
        regionNameTo: item.RegionNameTo,
        regionNameFrom: item.RegionNameFrom,
      }));
      setRows(mappedRows);
      setOriginalRows(mappedRows);
    } catch (error) {
      console.error('Error fetching size maps data:', error);
    }
  };

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
    try {
      await fetchCountryRegionIds();
      await fetchBrands();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setDepartmentCode('');
    setBrandName([]);
    setSizeTo('');
    setSizeFrom('');
    setCountryRegionIdFrom();
    setCountryRegionIdTo();
    setRegionNameTo();
    setRegionNameFrom();
    setSizingAttribute('');
    setSearchParams('false');
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalEditRowClose = () => {
    setEditRow(false);
  };

  const handleDeleteBrand = async () => {
    try {
      // setLoading(true);
      await deleteSizeMap(siteId, deleteRow?.sizeMappingId);

      setRows((prevRows) => prevRows.filter((row) => row.id !== deleteRow?.id));
      setOriginalRows((prevOriginalRows) => prevOriginalRows.filter((row) => row.id !== deleteRow?.id));

      toast.success(`${deleteRow?.departmentCode === null ? 'N/A' : deleteRow?.departmentCode}
      of Brand ${deleteRow?.brandName} successfully deleted`);
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete brand');
    } finally {
      setOpenModal(false);
    }
  };

  const handleCreateSizeMap = async () => {
    try {
      const brand = await getBrands(siteId);

      const selectedBrand = brand?.data.find((brandSelected) => brandSelected.Name === brandName);

      const brandId = selectedBrand?.BrandId;

      setCountryRegionIdTo(regionNameTo);
      setCountryRegionIdFrom(regionNameFrom.CountryRegionId);

      const numericCountryRegionIdTo = parseInt((regionNameTo || '').replace(/\D/g, ''), 10);
      const numericCountryRegionIdFrom = regionNameFrom.CountryRegionId;

      await postSizeMap(
        siteId,
        departmentCode,
        brandId,
        numericCountryRegionIdTo,
        sizeTo,
        numericCountryRegionIdFrom,
        sizeFrom,
        sizingAttribute,
      );
      setRegionNameTo(regionNameTo);
      setRegionNameFrom(regionNameFrom);
      setSearchParams(false);

      const newRow = {
        id: rows.length + 1,
        departmentCode,
        brandName,
        sizeTo,
        sizeFrom,
        regionNameTo,
        regionNameFrom,
        sizingAttribute,
      };

      setRows((prevRows) => [...prevRows, newRow]);
      setOriginalRows((prevOriginalRows) => [...prevOriginalRows, newRow]);

      // Close the popover
      handlePopoverClose();
      setDepartmentCode('');
      setBrandName([]);
      setSizeTo('');
      setSizeFrom('');
      setCountryRegionIdFrom();
      setCountryRegionIdTo();
      setRegionNameTo();
      setRegionNameFrom();
      setSizingAttribute('');

      await fetchData();

      toast.success('Size Map Created');
    } catch (error) {
      console.error('Error saving size map:', error);
    }
  };

  const handleRowClick = async (params) => {
    try {
      await fetchData();

      const clickedRow = params.row;

      setSelectedRow(clickedRow);

      const regionFromObj = countryRegionFromList.find((obj) => obj.CountryRegionId === clickedRow.countryRegionIdFrom);

      setEditDepartmentCode(clickedRow.departmentCode);
      setEditBrandName(clickedRow.brandName);
      setEditSizeTo(clickedRow.sizeTo);
      setEditSizeFrom(clickedRow.sizeFrom);
      setEditRegionNameTo(clickedRow.regionNameTo);
      setEditRegionNameFrom(regionFromObj);
      setEditSizingAttribute(clickedRow.sizingAttribute);
      setEditBrandId(clickedRow.brandId);

      const { sizeMappingId } = clickedRow;

      setEditSizeMappingId(sizeMappingId);

      setEditRow(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEditSizeMap = async () => {
    const numericCountryRegionIdTo = parseInt((editRegionNameTo || '').replace(/\D/g, ''), 10);
    const numericCountryRegionIdFrom = editRegionNameFrom.CountryRegionId;

    try {
      await patchEditSizeMap(
        siteId,
        editSizeMappingId,
        editDepartmentCode,
        editBrandId,
        numericCountryRegionIdTo,
        editSizeTo,
        numericCountryRegionIdFrom,
        editSizeFrom,
        editSizingAttribute,
      );

      setEditRegionNameTo(editRegionNameTo || '');
      setEditRegionNameFrom(editRegionNameFrom || '');

      setRows((prevRows) => {
        const updatedRows = prevRows.map((row) => {
          if (row.id === selectedRow.id) {
            return {
              ...row,
              departmentCode: editDepartmentCode,
              brandId: editBrandId,
              brandName: editBrandName,
              regionNameTo: editRegionNameTo,
              sizeTo: editSizeTo,
              regionNameFrom: editRegionNameFrom,
              sizeFrom: editSizeFrom,
              sizingAttribute: editSizingAttribute,
            };
          }
          return row;
        });

        return updatedRows;
      });

      await fetchData();

      toast.success(`Size Map ${editBrandName} successfully updated`);
      handleModalEditRowClose();
    } catch (error) {
      console.error(error);
      toast.error('Failed to update Size Map');
    }
  };

  const open = Boolean(anchorEl);

  const columns = [
    {
      field: 'departmentCode',
      headerName: 'Department Code',
      width: 150,
    },
    {
      field: 'brandName',
      headerName: 'Brand Name',
      width: 200,
      headerAlign: 'center',
    },
    {
      field: 'regionNameTo',
      headerName: 'Region',
      width: 100,
      headerAlign: 'center',
    },
    {
      field: 'sizeTo',
      headerName: 'Size To',
      width: 70,
      headerAlign: 'center',
    },
    {
      field: 'regionNameFrom',
      headerName: 'Country Region From',
      width: 200,
      headerAlign: 'center',
    },
    {
      field: 'sizeFrom',
      headerName: 'Size From',
      width: 90,
      headerAlign: 'center',
    },
    {
      field: 'sizingAttribute',
      headerName: 'Sizing Attribute',
      width: 200,
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Delete',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => (
        <Tooltip
          title="Delete"
        >
          <DeleteForeverIcon
            sx={{
              cursor: 'pointer',
              opacity: '0.5',
              '&:hover': {
                color: 'red',
              },
            }}
            onClick={(event) => {
              event.stopPropagation();
              setOpenModal(true);
              setDeleteRow(params.row);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  React.useEffect(() => {
    if (searchValue.length > 0) {
      setSearchValue('');
    }
    fetchData(siteId);
  }, [siteId]);

  React.useEffect(() => {
    if (searchValue !== '') {
      const filteredRows = (searchByBrandOrDeptCode === 1)
        ? originalRows.filter((row) => (row.brandName.toLowerCase().includes(searchValue.toLowerCase())))
        : originalRows.filter((row) => (row.departmentCode !== null && row.departmentCode.toLowerCase().includes(searchValue.toLowerCase())));
      setRows(filteredRows);
    } else {
      setRows(originalRows);
    }
  }, [searchValue, originalRows]);

  React.useEffect(() => {
  }, [countryRegionIdTo, countryRegionIdFrom]);

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    const areAllFieldsFilled = brandName && sizeTo && sizeFrom && regionNameTo && regionNameFrom;

    if (isOverrideChecked) {
      // If override is checked, ensure all other fields are filled and at least one of sizingAttribute or departmentCode is filled
      if (areAllFieldsFilled && (sizingAttribute || departmentCode)) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    } else if (areAllFieldsFilled && sizingAttribute && departmentCode) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [brandName, sizeTo, sizeFrom, regionNameTo, regionNameFrom, sizingAttribute, departmentCode, isOverrideChecked]);

  const customRenderOption = (props, option) => (
    <li {...props} key={option.brandId}>
      <div>{option.Name}</div>
    </li>
  );

  return (
    <Container maxWidth="xl">
      <div className="flex w-[100%] h-full gap-2 pt-2 m-8 mt-1">
        <div className="flex flex-col w-full">
          <div className="flex flex-col justify-between mt-4">
            <Typography variant="h5" className="pb-2 pl-8 text-secondary-600">Size Mapping</Typography>
            <div className="flex w-4/5 pl-8">
              <FormControl sx={{ m: 3, minWidth: 180 }} size="small">
                <Select
                  labelId="action-label"
                  id="action-select"
                  label="Action"
                  value={searchByBrandOrDeptCode}
                  size="small"
                  variant="standard"
                  onChange={handleChangeBrandOrDeptCode}
                >
                  <MenuItem value={1}>Brand Name</MenuItem>
                  <MenuItem value={2}>Department Code</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ m: 1, minWidth: 180 }}
                label="Search"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                variant="standard"
                size="small"
                className="mr-6 w-[300px]"
              />
              <div className="flex justify-end w-full mb-9">
                <Button
                  onClick={(event) => {
                    handlePopoverOpen(event);
                  }}
                  variant="contained"
                >
                  Create New Size
                </Button>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    style: {
                      width: '400px',
                    },
                  }}
                >
                  <div className="flex flex-col p-8">
                    <Typography variant="h6" className="mb-3">Create</Typography>
                    <div className="flex flex-row items-center mb-3">
                      <TextField
                        label="Department Code"
                        variant="outlined"
                        size="small"
                        className="w-full"
                        value={departmentCode}
                        onChange={(e) => setDepartmentCode(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-row items-center mb-3">
                      <Autocomplete
                        key={brandName}
                        id="brands"
                        size="small"
                        value={{ Name: brandName }}
                        onChange={(event, value) => {
                          if (value && typeof value === 'object' && 'Name' in value) {
                            const selectedName = value.Name;
                            setBrandName(selectedName);
                          }
                        }}
                        options={brandsList || []}
                        getOptionLabel={(brand) => brand.Name || ''}
                        className="w-full"
                        renderInput={(params) => <TextField {...params} label="Brand" key="brand-autocomplete" />}
                        renderOption={customRenderOption}
                      />
                    </div>
                    <div className="flex flex-row items-center mb-3">
                      <Autocomplete
                        id="countryRegionTo"
                        size="small"
                        value={regionNameTo}
                        onChange={(event, value) => setRegionNameTo(value)}
                        className="w-full"
                        options={countryRegionToList || []}
                        renderInput={(params) => <TextField {...params} label="Region" key="country-region-autocomplete" />}
                      />
                    </div>
                    <div className="flex flex-row items-center mb-3">
                      <TextField
                        label="Size To"
                        variant="outlined"
                        size="small"
                        type="text"
                        value={sizeTo}
                        onInput={(event) => setSizeTo(event.target.value)}
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-row items-center mb-3">
                      <Autocomplete
                        id="countryRegionFrom"
                        size="small"
                        value={regionNameFrom}
                        getOptionLabel={(obj) => `${obj.Name}-${obj.CountryRegionId}` || ''}
                        onChange={(event, value) => setRegionNameFrom(value)}
                        options={countryRegionFromList || []}
                        className="w-full"
                        renderInput={(params) => <TextField {...params} label="Country Region From" key="country-region-autocomplete" />}
                      />
                    </div>
                    <div className="flex flex-row items-center mb-3">
                      <TextField
                        label="Size From"
                        variant="outlined"
                        size="small"
                        type="text"
                        value={sizeFrom}
                        onInput={(event) => setSizeFrom(event.target.value)}
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-row items-center mb-3">
                      <TextField
                        label="Sizing Attribute"
                        variant="outlined"
                        size="small"
                        type="text"
                        value={sizingAttribute}
                        onInput={(e) => setSizingAttribute(e.target.value)}
                        className="w-full"
                      />
                    </div>

                    <div className="flex items-center justify-between mt-4">
                      <div className="flex items-center gap-2">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={isOverrideChecked}
                              onChange={(e) => {
                                setSearchParams((params) => {
                                  params.set('isOverrideChecked', e.target.checked);
                                  if (e.target.checked === false) params.delete('isOverrideChecked');
                                  return params;
                                });
                              }}
                            />
                          )}
                          label={<p className="text-[14px]">Override</p>}
                          className="mr-0"
                        />
                        <Tooltip
                          title={(
                            <div className="text-sm">
                              <p>
                                <span className="font-gothambold">Selected:</span>
                                {' '}
                                Override will allow insertion of records without mandatory fields. Size map will not be exported.
                              </p>
                            </div>
                          )}
                          placement="top"
                        >
                          <span>
                            <InfoIcon className="cursor-pointer" />
                          </span>
                        </Tooltip>
                      </div>

                      <div className="flex items-center gap-4">
                        <Button
                          variant="contained"
                          onClick={handleCreateSizeMap}
                          disabled={isSaveDisabled}
                        >
                          Save
                        </Button>
                        <Button variant="text" className="text-primary-600" onClick={handlePopoverClose}>
                          Cancel
                        </Button>
                      </div>

                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <div className="flex flex-col w-4/5 p-8 mt-5 bg-white">
              <StripedDataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[15, 30, 50, 100]}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                onRowClick={handleRowClick}
                sx={{
                  '.MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                  },
                  '& .MuiDataGrid-cell': {
                    justifyContent: 'center',
                  },
                }}
                className="min-h-[40vh]"
              />

              <Dialog
                title="Edit"
                open={editRow}
                onClose={handleModalEditRowClose}
                maxWidth="lg"
                sx={{
                  '& .MuiDialog-paper': {
                    width: '30%',
                  },
                }}
              >
                <Box className="p-8">
                  <Typography variant="h6" className="mb-3 text-center">Edit</Typography>
                  <div className="flex flex-row items-center mb-3">
                    <TextField
                      label="Department Code"
                      variant="outlined"
                      size="small"
                      className="w-full"
                      value={editDepartmentCode}
                      onChange={(e) => setEditDepartmentCode(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-row items-center mb-3">
                    <Autocomplete
                      key={editBrandName}
                      id="brands"
                      size="small"
                      value={editBrandName}
                      onChange={(event, value) => {
                        // Check if value is an object
                        if (value && typeof value === 'object' && 'Name' in value) {
                          const selectedName = value.Name;
                          setEditBrandName(selectedName);

                          const selectedBrand = brandsList.find((brand) => brand.Name === selectedName);
                          if (selectedBrand) {
                            setEditBrandId(selectedBrand.BrandId);
                          }
                        }
                      }}
                      options={brandsList || []}
                      getOptionLabel={(brand) => {
                        if (typeof brand === 'object' && brand !== null && 'Name' in brand) {
                          return brand.Name;
                        } if (typeof brand === 'string') {
                          return brand;
                        }
                        return '';
                      }}
                      className="w-full"
                      renderInput={(params) => <TextField {...params} label="Brand" key="edit-country-region-autocomplete" />}
                      renderOption={customRenderOption}
                    />
                  </div>
                  <div className="flex flex-row items-center mb-3">
                    <Autocomplete
                      id="editCountryRegionTo"
                      size="small"
                      value={editRegionNameTo}
                      onInput={(event, value) => setEditRegionNameTo(value)}
                      options={countryRegionToList || []}
                      className="w-full"
                      renderInput={(params) => <TextField {...params} label="Region" />}
                    />
                  </div>
                  <div className="flex flex-row items-center mb-3">
                    <TextField
                      label="Size To"
                      variant="outlined"
                      size="small"
                      type="text"
                      value={editSizeTo}
                      onInput={(event) => setEditSizeTo(event.target.value)}
                      inputProps={{
                        pattern: '^[0-9]*[.]?[0-9]*$',
                        title: 'Only numbers and decimals are allowed',
                      }}
                      className="w-full"
                      renderInput={(params) => <TextField {...params} label="Size To" />}
                    />
                  </div>
                  <div className="flex flex-row items-center mb-3">
                    <Autocomplete
                      id="editCountryRegionFrom"
                      size="small"
                      value={editRegionNameFrom}
                      getOptionLabel={(obj) => `${obj.Name}-${obj.CountryRegionId}` || ''}
                      onChange={(event, value) => setEditRegionNameFrom(value)}
                      options={countryRegionFromList || []}
                      className="w-full"
                      renderInput={(params) => <TextField {...params} label="Country Region From" key="edit-country-region-autocomplete" />}
                    />
                  </div>
                  <div className="flex flex-row items-center mb-3">
                    <TextField
                      label="Size From"
                      variant="outlined"
                      size="small"
                      type="text"
                      value={editSizeFrom}
                      onInput={(event) => setEditSizeFrom(event.target.value)}
                      className="w-full"
                      renderInput={(params) => <TextField {...params} label="Size From" />}
                    />
                  </div>
                  <div className="flex flex-row items-center mb-3">
                    <TextField
                      label="Sizing Attribute"
                      variant="outlined"
                      size="small"
                      type="text"
                      value={editSizingAttribute}
                      onInput={(e) => setEditSizingAttribute(e.target.value)}
                      className="w-full"
                      renderInput={(params) => <TextField {...params} label="Sizing Attribute" />}
                    />
                  </div>
                  <div className="flex justify-end">
                    <Button
                      variant="contained"
                      onClick={handleEditSizeMap}
                      disabled={!editBrandName || !editSizeTo
                        || !editSizeFrom || !editRegionNameTo || !editRegionNameFrom || !editSizingAttribute}
                    >
                      Save
                    </Button>
                    <Button variant="text" className="text-primary-600" onClick={handleModalEditRowClose}>
                      Cancel
                    </Button>
                  </div>
                </Box>
              </Dialog>
              <Modal
                title="Delete Size Map"
                open={openModal}
                onClose={handleModalClose}
                buttonLabel="Confirm"
                buttonOnClick={handleDeleteBrand}
              >
                <Typography className="text-center">
                  {`Are you sure you want to delete ${deleteRow?.departmentCode === null ? 'N/A' : deleteRow?.departmentCode}
              of ${deleteRow?.brandName}`}
                </Typography>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default SizeMapping;
