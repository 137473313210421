import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataVat,
} from '../../../../../redux/wizard/nike/nike_wizard';
import Table from '../../../../Table';

function VAT() {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const { activeStep } = nike_wizard.productData;

  const [vat, setVat] = React.useState([]);
  const [vatCountries, setVatCountries] = React.useState([]);
  const [vatCodeCountries, setVatCodeCountries] = React.useState([]);
  const [vatRows, setVatRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function getAnatwineSkus() {
      const x = nike_wizard.productData.vat
        .map(({ sizes }) => sizes.map(({ country_code }) => country_code)).flat();
      const uniqueVatCountries = [...new Set(x)].filter((country) => country !== 'US');
      setVatCountries(uniqueVatCountries.sort());

      const z = nike_wizard.productData.vat.map(({ vat_code }) => vat_code
        .map(({ country_code }) => country_code)).flat();
      const uniqueVatCodeCountries = [...new Set(z)];
      setVatCodeCountries(uniqueVatCodeCountries.sort());
    }
    getAnatwineSkus();
    setVat(nike_wizard.productData.vat);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const rows = vat.map((vatObj, index) => ({
      ...vatObj,
      id: index,
      vat_code: vatObj.vat_code.map((vatCodeObj) => ({
        country_code: vatCodeObj.country_code === 'GB' ? 'UK' : vatCodeObj.country_code,
        is_vatable: (
          <Checkbox
            checked={vatCodeObj.is_vatable}
            onChange={(event) => {
              setVat([
                ...vat.map((vatObj2) => (vatObj.sku === vatObj2.sku
                  ? {
                    ...vatObj2,
                    vat_code: [
                      ...vatObj2.vat_code.map((vatCode2) => (
                        vatCode2.country_code === vatCodeObj.country_code
                          ? {
                            ...vatCode2,
                            is_vatable: event.target.checked,
                          }
                          : vatCode2
                      )),
                    ],
                  }
                  : vatObj2)),
              ]);
              dispatchToRedux(setEditedValues(`vat-${vatObj.sku}`));
            }}
          />
        ),
      })),
    }));
    setVatRows(rows);
    setLoading(false);
  }, [vat]);

  return (
    <>
      <Typography
        variant="h5"
        className="border-b font-gothammedium text-neutral-800 border-neutral-300"
        gutterBottom
      >
        VAT
      </Typography>
      <Typography variant="body1" className="text-neutral-800">
        Check products that have VAT applied.
      </Typography>
      <div className="flex justify-end gap-4 mb-4">
        <Button
          variant="contained"
          color="info"
          size="small"
          onClick={() => setVat([
            ...vat.map((vatObj2) => ({
              ...vatObj2,
              vat_code: [
                ...vatObj2.vat_code.map((vatCode2) => ({
                  ...vatCode2,
                  is_vatable: true,
                })),
              ],
            })),
          ])}
        >
          Select All
        </Button>
        <Button
          variant="text"
          color="error"
          size="small"
          onClick={() => setVat([
            ...vat.map((vatObj2) => ({
              ...vatObj2,
              vat_code: [
                ...vatObj2.vat_code.map((vatCode2) => ({
                  ...vatCode2,
                  is_vatable: false,
                })),
              ],
            })),
          ])}
        >
          Deselect All
        </Button>
      </div>
      <Table
        maxHeight="50vh"
        fixed={false}
        columnGrouping={[
          { id: '1', span: 1, label: '' },
          { id: '2', span: vatCountries.length, label: 'Size' },
          { id: '3', span: vatCodeCountries.length, label: 'VAT Code' },
          { id: '4', span: 1, label: '' },
        ]}
        align="center"
        columns={[
          { id: 'sku', label: 'SKU', styles: { position: 'sticky !important' } },
          ...vatCountries.map((country) => ({
            id: 'sizes',
            label: country,
            disableBold: true,
            styles: { position: 'sticky !important' },
          })),
          ...vatCodeCountries.map((country) => ({
            id: 'vat_code',
            label: country === 'GB' ? 'UK' : country,
            disableBold: true,
            styles: { position: 'sticky !important' },
          })),
          { id: 'commodityCode', label: 'Commodity Code', styles: { position: 'sticky !important' } },
        ]}
        rows={vatRows}
        loading={loading}
        className="border rounded-lg"
      />
      <Stack direction="row" spacing={2} className="float-right my-4">
        <Button
          variant="text"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep - 1));
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep + 1));
            dispatchToRedux(setProductDataVat(vat));
          }}
        >
          Confirm
        </Button>
      </Stack>
    </>
  );
}

export default VAT;
