import Cookies from 'js-cookie';

import { setLocalStorage } from '../../../../helper/LocalStorage';
import postRequest from '../../../postRequest';

function setTokens(accessToken, refreshToken, tokenExpires) {
  const tokenExpiresNumber = new Date(new Date(tokenExpires).getTime());
  Cookies.set('AccessToken', accessToken, { expires: tokenExpiresNumber });
  setLocalStorage('RefreshToken', refreshToken);
  setLocalStorage('AccessTokenExpires', tokenExpires);
}

/**
 * Logs you into PRISM.
 *
 * @param {string} username
 * @param {string} password
 * @returns Object from DB
 */
function postAuthenticate(username, password) {
  const endpoint = '/users/authenticate';

  const body = {
    username,
    password,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Authenticate')
      .then((response) => {
        setTokens(response.data.JwtToken, response.data.RefreshToken, response.data.ValidToUtc);
        resolve(response);
      })
      .catch((error) => reject(error));
  });
}

export default postAuthenticate;
