import { useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import React from 'react';
import { useSelector } from 'react-redux';

import getCategoryDetails from '../../../../api/oc_product/productDataManagement/category/get/getCategoryDetails';
import CategoryTreeMenu from '../../../../components/category_tree_menu/CategoryTreeMenu';
import CategoryDetails from './CategoryDetails';

function Categories() {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [categoryName, setCategoryName] = React.useState(null);
  const [categoryDetails, setCategoryDetails] = React.useState(null);
  const [categoryId, setCategoryId] = React.useState(null);
  const [detailsLoading, setDetailsLoading] = React.useState(false);
  const [categoriesLoading, setCategoriesLoading] = React.useState(true);
  const [categoriesNameUpdated, setCategoryNameUpdated] = React.useState(false);

  const handleViewCategory = async (category) => {
    setCategoryName(category.Category);
    setCategoryId(category.Id);
    const selectedCategoryDetails = await getCategoryDetails(siteId, category.Id);
    setCategoryDetails(selectedCategoryDetails.data);
  };

  const handleSetCategoriesLoading = (bool) => {
    setCategoriesLoading(bool);
  };

  const handleCategoryNameUpdate = (bool) => {
    setCategoryNameUpdated(bool);
  };

  return (
    <div className="flex w-full row">
      <Card variant="outlined" className="w-[60%] h-[800px] overflow-auto mr-5">
        <CategoryTreeMenu
          data={[]}
          showMenu
          onViewCategory={(value) => handleViewCategory(value)}
          categoryDetails={categoryDetails}
          setCategoryDetails={setCategoryDetails}
          setDetailsLoading={setDetailsLoading}
          categoriesLoading={categoriesLoading}
          setCategoriesLoading={setCategoriesLoading}
          categoriesNameUpdated={categoriesNameUpdated}
        />
      </Card>
      <Divider
        orientation={isSmallScreen ? 'horizontal' : 'vertical'}
        flexItem={!isSmallScreen}
        className={isSmallScreen ? 'mb-5' : 'mr-5'}
      />
      <div className="w-[40%]">
        <CategoryDetails
          categoryDetails={categoryDetails}
          categoryName={categoryName}
          categoryId={categoryId}
          detailsLoading={detailsLoading}
          setDetailsLoading={setDetailsLoading}
          setCategoriesLoading={handleSetCategoriesLoading}
          setCategoryNameUpdated={handleCategoryNameUpdate}
        />
      </div>
    </div>
  );
}

export default Categories;
