import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import Table from '../../../components/Table';
import CreateModal from './modals/CreateModal';
import EditModal from './modals/EditModal';

function createData(brand, actions) {
  return { brand, actions };
}

function Brands() {
  const [edit, setEdit] = useState(null);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const apiExample = [
    { id: 8269, name: '' },
    { id: 11871, name: '**TOPGRADE**CHANGE!!!**' },
    { id: 11691, name: '/' },
    { id: 11479, name: '0' },
    { id: 8896, name: '10 DEEP' },
    { id: 8364, name: '100%' },
    { id: 157, name: '1000 MILE' },
    { id: 357, name: '11 Degrees' },
    { id: 11789, name: '12D' },
    { id: 12187, name: '16arlington' },
    { id: 559, name: '1STFORCE' },
    { id: 3008, name: '22.00' },
    { id: 12035, name: '2394' },
  ];
  const columns = [
    { id: 'brand', label: 'Brands' },
    { id: 'actions', label: '' },
  ];

  const editBrandName = (brand, index) => {
    if (edit === index) {
      return (
        <TextField
          label="Brand"
          variant="standard"
          className="border"
          size="small"
          defaultValue={brand.name}
          onChange={(e) => {
            // eslint-disable-next-line no-param-reassign
            brand.name = e.target.value;
          }}
        />
      );
    }
    return brand;
  };
  const actions = (index) => {
    if (edit === index) {
      return (
        <Stack direction="row" spacing={4} justifyContent="center">
          <Button
            variant="outlined"
            color="success"
            size="small"
            onClick={() => setOpenEditModal(true)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => setEdit(null)}
          >
            Cancel
          </Button>
        </Stack>
      );
    }
    return (
      <Stack direction="row" spacing={4} justifyContent="center">
        <Button
          variant="outlined"
          size="small"
          color="warning"
          onClick={() => setEdit(index)}
        >
          Edit
        </Button>
        <Button variant="outlined" color="error" size="small">Delete</Button>
      </Stack>
    );
  };

  const rows = apiExample.map((row, index) => createData(
    editBrandName(row.name, index),
    actions(index),
  ));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, pageNumber) => {
    setPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Container maxWidth="md">
      <CreateModal open={openCreateModal} onCloseModal={() => setOpenCreateModal(false)} />
      <EditModal
        open={openEditModal}
        onCloseModal={() => setOpenEditModal(null) || setEdit(null)}
      />

      <Grid container spacing={2} marginTop>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className="font-gothamlight"
            gutterBottom
          >
            Brands
          </Typography>
        </Grid>

        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField label="Filter" />
            <Button variant="contained" color="info">Search</Button>
          </Stack>
          <Button variant="contained" color="success" onClick={() => setOpenCreateModal(true)}>Create</Button>
        </Grid>

        <Grid item xs={12}>
          <Table columns={columns} rows={rows} />
          <TablePagination
            rowsPerPageOptions={[15, 30, 90]}
            component="div"
            count={rows.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Brands;
