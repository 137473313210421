import React from 'react';

function Release20240214() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">NEW PAGES</h2>
      <p className="-mt-4">14th Feb 2024</p>

      <h4>
        Data Management - Categories
      </h4>
      <ul className="-mt-4">
        <li>VIEW, EDIT, CREATE Categories</li>
        <li>ERP Category Mapping</li>
        <li>Enhanced Logs</li>
      </ul>
      <h4>
        Data Management - Product Type
      </h4>
      <ul className="-mt-4">
        <li>VIEW, EDIT & CREATE  Product Types</li>
        <li>Assign Attributes to Product Types</li>
        <li>Added Date Created to Table</li>
        <li>Faster Searching and loading</li>
      </ul>
      <h4>
        Product Info- Media Check
      </h4>
      <ul className="-mt-4">
        <li>Faster Search</li>
        <li>New UI</li>
        <li>Refresh Functionality is now a Bulk Upload Process</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PLP</h3>
      <h4>Improvements</h4>
      <ul className="-mt-4">
        <li>Search by Attributes - Attributes now used the Def Name</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Search By Attributes : JD X Sale Price - Money attribute accepts the value alphabet ‘E’ and is able to search the products</li>
        <li>(Fixed) - Export is ignoring the user inputted file name</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PDP</h3>
      <h4>Improvements</h4>
      <ul className="-mt-4">
        <li>Enable the user to see more of the Product Name without hovering over the name</li>
        <li>Rename Attributes Tab to Attributes and Categories</li>
        <li>Expand the Categories Section to show the full category names</li>
        <li>Hide read only attributes that do not have values</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Not able to update/edit the decimal attributes on the PDP Page</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bulk Uploads</h3>
      <h4>Improvements</h4>
      <ul className="-mt-4">
        <li>Single Line Attributes - Remove nvarchar validation restrictions</li>
        <li>Allow spaces at the end or beginning of a PLU</li>
        <li>(Enable Stock) : Show product status on the page</li>
        <li>(Pre-Release Dates) - Add Important Text Box</li>
        <li>(Pre-Release Dates) - Rename Pre-Release Dates to Pre-Release Products</li>
      </ul>

      <h4>Not a Bug</h4>
      <ul className="-mt-4">
        <li>(Attributes) - Special Characters are not loading on site (User File Format Issue)</li>
        <li>(Attributes) - Commas in files are causing the copy to break up (User File Format Issue)</li>
        <li>(Attributes) - Not allowing special characters in Germany / Denmark for NavChar</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Bulk Operations: Duplicate Key Value - SQL Error</li>
        <li>(Fixed) - Product Name - Duplicate key error when users try to bulk using Product Name</li>
        <li>(Fixed) - Pre-Release Products - UNIQUE KEY constraint UC_Remoresystemid.</li>
        <li>(Fixed) - Bulk Operation - Single select and Multi select attributes - Getting ‘No Change’</li>
        <li>(Fixed) - Pre-Release dates treating PLUs as unique across facias</li>
        <li>(Fixed) - Single Line Attributes - No Change when we single line attributes are inserted</li>
        <li>(Fixed) - Enable stock - Has stock filter showing as empty</li>
        <li>(Fixed) - Enable stock - Products are showing as Disable, but the same product is showing as Enabled in PDP</li>
        <li>(Fixed) - Categories - Outdoor facias. Categories added using bulk need to be in a hierarchy</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Prism General Improvements</h3>
      <ul className="-mt-4">
        <li>Changes for applications pulling images instead of adis to to use new URLs</li>
        <li>On Login, Set the default site to one the user has assigned to them</li>
        <li>[UAT/PROD-CPIM] sfs_enabled attribute not displayed on PDP for JD DK/SE/FI</li>
        <li>Central PIM Prod - Attributes values are displaying but attribute names are not displaying in PDP page for JD Denmark site product</li>
        <li>PRISM / PIM Prod - In Prism pdp page showing less flag attributes but in central pim showing multiple flag attributes</li>
        <li>PRISM / PIM Dev & Prod - PDP Page - is PFS enabled is showing as Enabled (=True) on General tab but on Attributes tab its showing as unticked (False?)  for JD Denmark site product</li>
      </ul>
    </section>
  );
}

export default Release20240214;
