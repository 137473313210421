import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getOperationTypes from '../../api/oc_product/bulk/bulk_process/reports/gets/getOperationTypes';
import getQueueProcessStatuses from '../../api/oc_product/bulk/bulk_process/reports/gets/getQueueProcessStatuses';
import getTransactionTypes from '../../api/oc_product/bulk/bulk_process/reports/gets/getTransactionTypes';

// First, create the thunk
export const transactionTypes = createAsyncThunk(
  'bulk/transaction-types',
  async () => {
    try {
      const { data } = await getTransactionTypes();
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
);
export const operationTypes = createAsyncThunk(
  'bulk/operation-types',
  async () => {
    try {
      const { data } = await getOperationTypes();
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
);
export const queueProcessStatuses = createAsyncThunk(
  'bulk/queue-process-statuses',
  async () => {
    try {
      const { data } = await getQueueProcessStatuses();
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
);

const bulkOperationsReducer = createSlice({
  initialState: {},
  name: 'bulkOperations',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(transactionTypes.fulfilled, (state, action) => {
      state.transaction_types = action.payload;
    });
    builder.addCase(operationTypes.fulfilled, (state, action) => {
      state.operation_types = action.payload;
    });
    builder.addCase(queueProcessStatuses.fulfilled, (state, action) => {
      state.queue_process_statuses = action.payload;
    });
  },
});

// export const { } = bulkOperationsReducer.actions;

export default bulkOperationsReducer.reducer;
