import postRequest from '../../../../postRequest';

/**
 * Post request for product type.
 *
 * @param {number} siteId Site Id
 * @param {string} productTypeName Product Type Name
 * @param {string} departmentCode Department Code
 * @param {number[]} attributeDefIds Attribute Def Ids
 * @param {boolean} isDefault Attribute Def Ids
 *
 */
export default function postProductType(
  siteId,
  productTypeName,
  departmentCode,
  attributeDefIds,
  isDefault,
) {
  const endpoint = `/oc-data-management/v1/product-type/${siteId}`;

  const body = {
    siteId,
    productTypeName,
    departmentCode,
    attributeDefIds,
    isDefault,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Product Type')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
