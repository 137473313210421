import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a Categories to multiple products.
 *
 * @param {{
 *  referenceIds: string[],
 *  categoryId: number[],
 *  siteId: number,
 *  languageId: number,
 * }}
 * @param {string[]} referenceIds PLU Ids
 * @param {number[]} categoryId Category Ids
 * @param {number} siteId Site Id
*/
export default function postCategoriesPluId({
  referenceIds,
  categoryId,
  siteId,
  sourceId,
}) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/categories/plu-id/${sourceId}`;

  const body = {
    referenceIds,
    categoryId,
    siteId,
    languageId: 0,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Categories (PLU Id\'s)', 'Started : Categories : PLU (Update)')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
