import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a primary category.
 *
 * @param {number} SiteId Site Id
 * @param {file} File Either a csv or excel file
*/
export default function postPrimaryCategoryProductId({
  SiteId,
  File,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/primary-category/product-id';

  const body = new FormData();
  body.append('SiteId', SiteId);
  body.append('LanguageId', 0);
  body.append('File', File);

  return new Promise((resolve, reject) => {
    postRequest(
      endpoint,
      body,
      'Primary Category Product Ids',
      'STARTED: Primary Category Product Ids',
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
