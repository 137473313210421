import getRequest from '../../../../../getRequest';

/**
 * Get selected categories data for specific product.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} languageId Language Id of the product
 * @returns {AxiosResponse}
 * @example
 *
  [
    {
      "Id": 107921,
      "Category": "Boots & Shoes",
      "FullCategoryPath": "Men/Mens Footwear/Boots & Shoes"
    },
    {
      "Id": 107966,
      "Category": "Canvas & Plimsolls",
      "FullCategoryPath": "Men/Mens Footwear/Canvas & Plimsolls"
    },
 *]
 */
export default function getSelectedCategories(productId, siteId, languageId) {
  const query = languageId ? `?LanguageId=${languageId}` : '';
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/attributes/categories${query}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Selected Categories')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
