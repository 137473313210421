import deleteRequest from '../../../../../deleteRequest';

/**
 * Deletes attribute from product in PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @param {string} attributeKey - subbrandcode
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function deleteCustomAttribute(productId, siteCode, attributeKey) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/custom-attribute';

  const body = {
    productId,
    siteCode,
    attributeKey,
  };

  // const successMessage = `Successfully Deleted ${attributeKey} for ${productId}`;
  const errorMessageHeader = `Delete ${attributeKey}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteCustomAttribute;
