import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import assignErp from '../../../../api/oc_product/productDataManagement/category/patch/assignErp';
import getCategories from '../../../../api/oc_product/sites/categories/gets/getCategories';
import getSources from '../../../../api/oc_product/sites/sources/gets/getSources';
import VirtualizedAutocomplete from '../../../../components/VirtualizedAutocomplete';
import { CategoryContext } from '../CategoryContext';

function ErpCategoryMapping() {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const { setReFetchLogs } = useContext(CategoryContext);

  const [sources, setSources] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [selectedSource, setSelectedSource] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [remoteId, setRemoteId] = React.useState('');

  React.useEffect(() => {
    getSources(siteId)
      .then((res) => setSources(res.data))
      .catch((err) => console.error(err));
    getCategories(siteId)
      .then((res) => setCategories(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await assignErp(selectedCategory.CategoryId, siteId, selectedSource.SourceId, remoteId);
      if (res) {
        setSelectedSource(null);
        setSelectedCategory(null);
        setRemoteId('');
        toast.success('ERP category id successfully assigned');
      }
    } catch (err) {
      console.error(err);
    } finally {
      setReFetchLogs(true);
    }
  };

  return (
    <Box component="div" className="flex justify-center w-[100%]">
      <Box
        component="form"
        className="flex flex-col justify-center w-[50%]"
        onSubmit={handleSubmit}
      >
        <Typography variant="body1" className="mb-4">
          Here you can attach category remote id to categories. Just select the categories from the dropdown and provide a Category Remote Id
        </Typography>
        <VirtualizedAutocomplete
          id="sources-virtualized-autocomplete"
          options={sources}
          getOptionLabel={(option) => option.Name}
          onSelectedValue={(value) => setSelectedSource(value || undefined)}
          value={selectedSource}
          textFieldLabel="Source"
          optionKey="Name"
          className="mb-4"
        />
        <TextField
          id="remote_id"
          label="Remote ID"
          variant="outlined"
          className="mb-4"
          value={remoteId}
          onChange={(e) => setRemoteId(e.target.value)}
        />
        <VirtualizedAutocomplete
          id="categories-virtualized-autocomplete"
          options={categories}
          getOptionLabel={(option) => option.Name}
          onSelectedValue={(value) => setSelectedCategory(value || undefined)}
          value={selectedCategory}
          textFieldLabel="Category"
          optionKey="Name"
          className="mb-4"
        />
        <Button
          variant="contained"
          color="success"
          type="submit"
          onClick={handleSubmit}
          className="w-[40%]"
          style={{ margin: 'auto' }}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
}

export default ErpCategoryMapping;
