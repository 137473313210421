import axios from 'axios';

import patchCustomAttribute from '../../../api/intergration/pim/products/dropshipment/patches/patchCustomAttribute';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function seasonYear(userEmail, nike_wizard) {
  return new Promise((resolve, reject) => {
    patchCustomAttribute(
      nike_wizard.productId,
      'JD',
      'seasonyear',
      nike_wizard.productData.other_attributes.season_year,
      'GB',
    )
      .then((res) => {
        if (res.data.Success) {
          // if (nike_wizard.editedValues.includes('seasonYear')) {
          postAddProductLog(
            nike_wizard.productId,
            'JD',
            `[INFO]: User ${userEmail} updated SeasonYear: ${nike_wizard.productData.other_attributes.season_year} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB`,
          );
          // }
          resolve(res.data);
        } else {
          postAddProductLog(
            nike_wizard.productId,
            'JD',
            `[ERROR]: User ${userEmail} FAILED to update SeasonYear: ${nike_wizard.productData.other_attributes.season_year} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB. Return Error Message: ${res.data.ErrorMessage}`,
          );
          reject(res.data.ErrorMessage);
        }
      })
      .catch((err) => {
        // If the error message contains the word Cancel then dont do error stuff.
        // Reason for this is because if a request is cancelled, this isn't an error to do with
        // saving data so the user doesn't need to be notified of this.
        if (axios.isCancel(err)) {
          resolve();
        } else {
          postAddProductLog(
            nike_wizard.productId,
            'JD',
            `[ERROR]: User ${userEmail} FAILED to update SeasonYear: ${nike_wizard.productData.other_attributes.season_year} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB. Return Error Message: ${err.message}`,
          );
          reject(err);
        }
      });
  });
}

export default seasonYear;
