import getRequest from '../../../../getRequest';

/**
 * Get Product Data from Fredhopper.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 */
export default function getFredhopperData(productId, siteId) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/fredhopper`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Fredhopper')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
