import patchRequest from '../../../patchRequest';
/**
 * Updates the brand group brands.
 *
 * @param {number} siteId - 1
*  @param {number} SizeMappingId
 * @param {string} DepartmentCode
 * @param {number} BrandId
 * @param {string} BrandName
 * @param {string} SizeTo
 * @param {string} SizeFrom
 * @param {number} CountryRegionIdFrom
 * @param {number} CountryRegionIdTo
 * @param {string} SizingAttribute
 * @returns {Promise} { Success: true }, status: 200 }
 */
export default async function patchEditSizeMap(
  siteId,
  SizeMappingId,
  DepartmentCode,
  BrandId,
  CountryRegionIdTo,
  SizeTo,
  CountryRegionIdFrom,
  SizeFrom,
  SizingAttribute,
) {
  const endpoint = `/oc-product/v1/size-mapping/${SizeMappingId}/${siteId}`;

  const body = {
    SizeMappingId,
    DepartmentCode,
    BrandId,
    CountryRegionIdTo,
    SizeTo,
    CountryRegionIdFrom,
    SizeFrom,
    SizingAttribute,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Size Map')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
