import getRequest from '../../../../getRequest';

/**
 * Get Languages.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} languageId Language Id of the product
 */
export default function getGeneral(siteId) {
  const endpoint = `/oc-product/v1/product/${siteId}/languages`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Languages')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
