import postRequest from '../../../../../postRequest';

/**
 * Calls categories endpoint to get the products linked categories.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @returns {Promise}
 * @example
    {
      data: {
        Data: [
          {
              "PimCategoryId": 107883,
              "SiteId": 1,
              "PimParentCategoryId": 107891,
              "ShogunCategoryId": "0928",
              "CategoryName": "Shoe Care",
              "CategoryFullPath": "Men/Mens Footwear/Shoe Care/",
              "ShogunCategory": "DT",
              "Buyer": "3"
          }
        ],
        Pagination: {},
      },
      status: 200,
    }
 */
async function postCategories(productId, siteCode) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/categories';

  const body = {
    productId,
    siteCode,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Categories')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postCategories;
