import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataColour,
} from '../../../../../redux/wizard/nike/nike_wizard';

function Colors({ loading }) {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);

  const { activeStep } = nike_wizard.productData;

  const [colours, setColors] = React.useState({
    primary_colour: '',
    secondary_colour: '',
    shogun_colour_code: '999',
  });

  React.useEffect(() => {
    setColors(nike_wizard.productData.colours);
  }, [nike_wizard.productData]);

  return (
    <section>
      <Typography
        variant="h5"
        className="font-gothammedium text-neutral-800 border-b border-neutral-300"
        marginBottom={4}
      >
        Colours
      </Typography>
      <Stack spacing={2}>
        <TextField
          label="Primary Colour"
          onChange={(e) => setColors({ ...colours, primary_colour: e.target.value })}
          value={colours.primary_colour}
          required
          error={colours.primary_colour === ''}
          disabled={loading.loading}
        />
        <TextField
          label="Secondary Colour"
          helperText="(Optional)"
          onChange={(e) => setColors({ ...colours, secondary_colour: e.target.value })}
          value={colours.secondary_colour}
        />
        <TextField
          label="Shogun Colour Code"
          disabled
          className="cursor-not-allowed"
          value={colours.shogun_colour_code}
        />
      </Stack>
      <Stack direction="row" spacing={2} className="float-right my-4">
        <Button
          variant="text"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep - 1));
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep + 1));
            dispatchToRedux(setProductDataColour(colours));
            if (nike_wizard.productData.primary_colour !== colours.primary_colour) {
              dispatchToRedux(setEditedValues('colour'));
            }
          }}
        >
          Confirm
        </Button>
      </Stack>
    </section>
  );
}

export default Colors;
