import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import getSourceLocations from '../../api/oc_product/sites/source_locations/gets/getSourceLocations';
// Filters
import ActiveFilter from './filters/active/ActiveFilter';
import ApprovedFilter from './filters/approved/ApprovedFilter';
import BrandsFilter from './filters/brands/BrandsFilter';
import CategoriesFilter from './filters/categories/CategoriesFilter';
import CategorisedFilter from './filters/categorised/CategorisedFilter';
import CompleteFilter from './filters/complete/CompleteFilter';
import CreatedDateFilter from './filters/dates/CreatedDateFilter';
import ReleasedDateFilter from './filters/dates/ReleasedDateFilter';
import UpdatedDateFilter from './filters/dates/UpdatedDateFilter';
import DropshippableFilter from './filters/dropshippable/DropshippableFilter';
import ERPCategoryIDFilter from './filters/erp_category_id/ERPCategoryIDFilter';
import HasImageFilter from './filters/has_image/HasImageFilter';
import HasStockFilter from './filters/has_stock/HasStockFilter';
import IdsFilter from './filters/ids/IdsFilter';
import NameFilter from './filters/name/NameFilter';
import NoProductTypeFilter from './filters/no_product_type/NoProductTypeFilter';
import OnSiteFilter from './filters/on_site/OnSiteFilter';
import ProductTypesFilter from './filters/product_types/ProductTypesFilter';
import SearchByAttributes from './filters/searchbyattributes/SearchByAttributes';
import SourcesFilter from './filters/sources/SourcesFilter';
import StockLocationFilter from './filters/stock_location/StockLocationFilter';
/**
 *
 * @param {Object} props
 * @param {boolean} props.open
 * @param {Array} props.siteSources
 * @param {Function} props.onSearch
 * @param {Array<'ids' | 'sources' | 'categories' | 'approved' | 'active' | 'on site' | 'no product type' | 'categorised' | 'name' | 'brands' | 'product types' | 'has image' | 'complete' | 'dropshippable' | 'erp category' | 'stock location' | 'has stock' | 'created' | 'updated' | 'released' | 'attribute' >} props.hideFilters
 */
function Filter({
  open,
  siteSources,
  onSearch,
  hideFilters = [],
  disableSearch = false,
}) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const [searchParams, setSearchParams] = useSearchParams();

  if (!Array.isArray(siteSources)) {
    throw Error('siteSources prop is missing. This will cause infinite loop.');
  }

  const resetAllFilters = () => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
       * For some reason we have to do this...
       * If you do the, if not then delete, statement below within the for above
       * or do params.forEach
       * The loop with miss every even indexed key...
       */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  };

  const [siteLocations, setSiteLocations] = React.useState([]);

  const [extraFiltersOpen, setExtraFiltersOpen] = React.useState(false);
  const [searchBtnDisabled, setSearchBtnDisable] = React.useState(true);
  const [firstMount, setFirstMount] = React.useState(true);

  React.useEffect(() => {
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) { // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter((str) => str !== 'page' && str !== 'siteCode' && str !== 'omsId' && str !== 'pageSize' && str !== 'idType');
    const count = filtered.length;
    const hasParams = count > 0;
    setSearchBtnDisable(!hasParams);
  }, [searchParams]);

  React.useEffect(() => {
    getSourceLocations(siteId)
      .then((res) => setSiteLocations(res.data))
      .catch((err) => console.error(err));
    if (firstMount) {
      setFirstMount(false);
    } else {
      resetAllFilters();
    }
  }, [siteId]);

  const [idsError, setIdsError] = React.useState(false);

  return (
    <Collapse in={open}>
      <div className="flex flex-col w-full h-auto gap-4 p-4 bg-white border rounded-lg">
        <Typography variant="h6" className="font-gothamlight">
          Filters
        </Typography>
        <div className="flex items-center">
          <p className="mr-4 text-sm w-max whitespace-nowrap">
            User Favorites
          </p>
          <hr className="w-full" />
        </div>
        <div className="flex flex-wrap justify-center w-full h-full gap-4 sm:justify-evenly">
          <div className="flex flex-1 gap-4 justify-evenly">
            {!hideFilters.includes('ids') && (
              <IdsFilter
                onError={setIdsError}
              />
            )}
            {!hideFilters.includes('sources') && (
              <SourcesFilter
                sources={siteSources}
              />
            )}
            {!hideFilters.includes('categories') && (<CategoriesFilter />)}
          </div>
          <div className="flex flex-1 gap-4 justify-evenly">
            {!hideFilters.includes('approved') && <ApprovedFilter />}
            {!hideFilters.includes('active') && <ActiveFilter />}
            {!hideFilters.includes('on site') && <OnSiteFilter />}
            {!hideFilters.includes('no product type') && <NoProductTypeFilter />}
            {!hideFilters.includes('categorised') && <CategorisedFilter />}
          </div>
        </div>
        <div className="flex items-center">
          {extraFiltersOpen ? (
            <Button
              className="pl-6 pr-6 text-sm w-max whitespace-nowrap"
              onClick={() => setExtraFiltersOpen(!extraFiltersOpen)}
              style={{ cursor: 'pointer' }}
            >
              Other Filters -
            </Button>
          ) : (
            <Button
              className="pl-6 pr-6 text-sm w-max whitespace-nowrap"
              onClick={() => setExtraFiltersOpen(!extraFiltersOpen)}
              style={{ cursor: 'pointer' }}
            >
              Other Filters +
            </Button>
          )}
          <hr className="w-full" />
          <Button
            variant="text"
            size="small"
            onClick={() => setExtraFiltersOpen(!extraFiltersOpen)}
          >
            {extraFiltersOpen ? 'Hide' : 'Show'}
          </Button>
        </div>
        <div className={`flex flex-wrap justify-start w-full overflow-hidden ${extraFiltersOpen ? 'h-full' : 'h-0'} gap-10 pl-6`}>
          {!hideFilters.includes('name') && <NameFilter />}
          {!hideFilters.includes('brands') && <BrandsFilter />}
          {!hideFilters.includes('product types') && <ProductTypesFilter />}
          {!hideFilters.includes('has image') && <HasImageFilter />}
          {!hideFilters.includes('complete') && <CompleteFilter />}
          {!hideFilters.includes('dropshippable') && <DropshippableFilter />}
          {!hideFilters.includes('erp category id') && (
            <ERPCategoryIDFilter
              sources={siteSources}
            />
          )}
          {!hideFilters.includes('stock location') && (
            <StockLocationFilter
              locations={siteLocations}
            />
          )}
          {!hideFilters.includes('has stock') && (
            <HasStockFilter
              locations={siteLocations}
              sources={siteSources}
            />
          )}
          {!hideFilters.includes('created') && <CreatedDateFilter />}
          {!hideFilters.includes('updated') && <UpdatedDateFilter />}
          {!hideFilters.includes('released') && <ReleasedDateFilter />}
          {!hideFilters.includes('attribute') && <SearchByAttributes />}
        </div>
        <div className="flex items-center justify-end w-full gap-10">
          <Button variant="text" className="text-primary-600" onClick={() => resetAllFilters()}>
            Reset
          </Button>
          <Button
            variant="contained"
            disabled={searchBtnDisabled || idsError || disableSearch}
            onClick={() => onSearch(true)}
          >
            Search
          </Button>
        </div>
      </div>
    </Collapse>
  );
}

export default Filter;
