import getRequest from '../../../../../getRequest';

/**
 * Get a list of the attributes values.
 *
 * @param {number} siteId
 * @param {number} productAttributeDefId
 * @param {number} languageId
 * @returns Attributes
 */
export default function getAttributeValues(siteId, productAttributeDefId, languageId) {
  const endpoint = `/oc-product-bulkapi/v1/sites/${siteId}/${productAttributeDefId}/${languageId}/attribute-values`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Attribute Values')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
