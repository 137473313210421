import patchRequest from '../../../../patchRequest';

/**
 * Put request for updating an attribute definition.
 *
 * @param {obj} body {
 *  "productAttributeDefId": number,
 *  "SiteId": number,
 *  "LanguageId": number,
 *  "LanguageName": "string",
 *  "productAttributeDef": {
 *    "productAttributeDefKey": "string",
 *    "englishName": "string",
 *    "name": "string",
 *    "isReadOnly": boolean,
 *    "allowUpdate": boolean,
 *    "isMandatory": boolean,
 *    "isHidden": boolean,
 *    "includeInSearchKeywords": boolean
 *   },
 *   "deletedProductAttributeLookupOptionIds": [
 *      number
 *   ],
 *   "updatedAttributeLookupValues": [
 *     {
 *      "productAttributeLookupOptionId": number,
 *      "value": "string",
 *      "isDefaultValue": boolean
 *      }
 *   ],
 *   "createdAttributeLookupValues": [
 *     {
 *      "languageId": number,
 *      "value": "string",
 *      "isDefaultValue": boolean
 *      }
 *   ],
 * }
 */
export default function patchAttribute(body) {
  const endpoint = '/oc-data-management/v1/attributes';

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Attribute Definition')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
