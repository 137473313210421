import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import instanceAxios from '../../../axios/instanceAxios';
import Table from '../../../components/Table';
import { store } from '../../../redux/store';
import DatesFilter from './filter/DatesFilter';
import EditModal from './modals/EditModal';

function createData(productId, source, plu, releaseDate, actions) {
  return {
    productId, source, plu, releaseDate, actions,
  };
}

createData.PropTypes = {
  productId: PropTypes.number.isRequired,
  pluSource: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
};

function Dates() {
  const [openEditModal, setOpenEditModal] = useState(false);

  const [selectedRowToEdit, setSelectedRowToEdit] = useState({
    source: '',
    plu: '',
    releaseDate: '',
  });

  const handleOpenModal = (source, plu, releaseDate) => {
    setSelectedRowToEdit({
      source,
      plu,
      releaseDate,
    });
    setOpenEditModal(true);
  };
  const handleCloseModal = () => setOpenEditModal(false);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([
    {
      items: [
        {
          id: 0,
          preOrderForm: null,
          releaseDate: null,
          remoteId: '',
          remoteSourceId: 0,
          sourceId: 0,
          sourceName: '',
          visableFrom: null,
        },
      ],
      totalItems: 0,
    },
  ]);

  const [page, setPage] = useState(0);
  const [siteId] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const urlParameters = {
    languageId: 1,
    page: 1,
    pageSize: 15,
    siteId,
    productIdsString: null,
  };
  const [productUrlParameters, setProductUrlParameters] = useState(urlParameters);

  const [rows, setRows] = useState([]);
  const columns = [
    { id: 'productId', label: 'Product ID' },
    { id: 'source', label: 'Source' },
    { id: 'plu', label: 'PLU' },
    { id: 'releaseDate', label: 'Release Date' },
    { id: 'actions', label: '' },
  ];

  const handleSearch = async () => {
    setLoading(true);
    const { data } = await instanceAxios(null, store)
      .get(`/productDates?${new URLSearchParams(productUrlParameters)}`);
    setProducts(data);

    const mappedRows = data.items.map((product) => createData(
      product.id,
      product.sourceName,
      product.remoteId,
      product.releaseDate == null ? 'No Release Date Set' : new Date(product.releaseDate).toLocaleString(),
      (
        <Button
          variant="outlined"
          size="small"
          color="warning"
          onClick={() => handleOpenModal(product.sourceName, product.remoteId, product.releaseDate)}
        >
          Edit
        </Button>
      ),
    ));
    setRows(mappedRows);
    setLoading(false);
  };

  useEffect(async () => {
    await handleSearch();
  }, [page, siteId, rowsPerPage]);

  const handleChangePage = (event, pageNumber) => {
    setProductUrlParameters({
      ...productUrlParameters,
      page: pageNumber + 1,
    });
    setPage(pageNumber);
    handleSearch();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setProductUrlParameters({
      ...productUrlParameters,
      pageSize: event.target.value,
    });
    setPage(1);
    handleSearch();
  };

  return (
    <Container>
      <EditModal
        open={openEditModal}
        onCloseModal={() => handleCloseModal()}
        selectedRow={selectedRowToEdit}
      />
      <Grid container spacing={2} marginTop>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className="font-gothamlight"
            gutterBottom
          >
            Dates
          </Typography>
        </Grid>

        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Box className="flex items-center gap-4">
            <TextField
              label="Product ID"
              onChange={(event) => setProductUrlParameters({
                ...productUrlParameters,
                productIdsString: event.target.value,
              })}
            />
            <Button variant="contained" color="info" onClick={handleSearch}>Search</Button>
          </Box>
          <DatesFilter />
        </Grid>

        <Grid item xs={12}>
          <Table columns={columns} rows={rows} loading={loading} />
          <TablePagination
            rowsPerPageOptions={[15, 30, 90]}
            component="div"
            count={products.totalItems || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ height: 'fit-content' }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dates;
