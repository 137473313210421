import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataOtherDataExclusiveDateFrom,
  setProductDataOtherDataExclusiveDateTo,
  setProductDataOtherDataObject,
} from '../../../../../redux/wizard/nike/nike_wizard';

function OtherData() {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const { activeStep } = nike_wizard.productData;

  const [otherAttributes, setOtherAttributes] = React.useState({
    season_year: '',
    season: '',
    age_restricted: '',
    licensed: 0,
    connected_customers: 0,
    from: '',
    to: '',
  });

  React.useEffect(() => {
    setOtherAttributes(nike_wizard.productData.other_attributes);
  }, []);

  return (
    <section>
      <Typography
        variant="h5"
        className="border-b font-gothammedium text-neutral-800 border-neutral-300"
        marginBottom={4}
      >
        Other Attributes
      </Typography>
      <Stack spacing={2} mb={2}>
        <TextField
          label="Season Year"
          onChange={(e) => {
            setOtherAttributes({ ...otherAttributes, season_year: e.target.value });
          }}
          value={otherAttributes.season_year}
          required
          error={otherAttributes.season_year === ''}
        />

        <TextField
          label="Season"
          onChange={(e) => {
            setOtherAttributes({ ...otherAttributes, season: e.target.value });
          }}
          value={otherAttributes.season}
          required
          error={otherAttributes.season === ''}
        />

        <FormControl disabled>
          <FormLabel id="radio-buttons-age-restrited">Age Restricted</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-age-restrited"
            value={otherAttributes.age_restricted}
            defaultValue="no"
            name="age-restrited"
            onChange={(e) => {
              setOtherAttributes({ ...otherAttributes, age_restricted: e.target.value });
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel id="radio-buttons-licensed">Licensed</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-licensed"
            value={otherAttributes.licensed}
            defaultValue={0}
            name="licensed"
            onChange={(e) => {
              setOtherAttributes({ ...otherAttributes, licensed: Number(e.target.value) });
            }}
          >
            <FormControlLabel value={1} control={<Radio />} label="Yes" />
            <FormControlLabel value={0} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel id="radio-buttons-connected-customers">Connected Customers</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-connected-customers"
            value={otherAttributes.connected_customers}
            defaultValue={0}
            name="connected-customers"
            onChange={(e) => {
              setOtherAttributes({
                ...otherAttributes,
                connected_customers: Number(e.target.value),
                from: '',
                to: '',
              });
            }}
          >
            <FormControlLabel value={1} control={<Radio />} label="Yes" />
            <FormControlLabel value={0} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {otherAttributes.connected_customers === 1 && (
          <>
            <Typography variant="body1" className="text-neutral-800">
              Timeframe from when the product is available to connected customers.
            </Typography>
            <Stack spacing={2} direction="row">
              <DateTimePicker
                label="From"
                inputFormat="dd/MM/yyyy hh:mm a"
                value={otherAttributes.from}
                onChange={(date) => {
                  setOtherAttributes({ ...otherAttributes, from: date });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DateTimePicker
                label="To"
                inputFormat="dd/MM/yyyy hh:mm a"
                value={otherAttributes.to}
                onChange={(date) => {
                  setOtherAttributes({ ...otherAttributes, to: date });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Stack>
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={2} className="float-right my-4">
        <Button
          variant="text"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep - 1));
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            if (otherAttributes.connected_customers === 0) {
              dispatchToRedux(setProductDataOtherDataExclusiveDateFrom(otherAttributes.from));
              dispatchToRedux(setProductDataOtherDataExclusiveDateTo(otherAttributes.to));
            }
            dispatchToRedux(setProductDataOtherDataObject(otherAttributes));
            if (nike_wizard.productData.other_attributes.season !== otherAttributes.season) {
              dispatchToRedux(setEditedValues('season'));
            }
            if (nike_wizard.productData.other_attributes.season_year
              !== otherAttributes.season_year) {
              dispatchToRedux(setEditedValues('seasonYear'));
            }
            if (nike_wizard.productData.other_attributes.licensed
              !== otherAttributes.licensed) {
              dispatchToRedux(setEditedValues('licensed'));
            }
            if (nike_wizard.productData.other_attributes.connected_customers
              !== otherAttributes.connected_customers) {
              dispatchToRedux(setEditedValues('connectedCustomers'));
            }
            if (nike_wizard.productData.other_attributes.from !== otherAttributes.from) {
              dispatchToRedux(setEditedValues('from'));
            }
            if (nike_wizard.productData.other_attributes.to !== otherAttributes.to) {
              dispatchToRedux(setEditedValues('to'));
            }
          }}
        >
          Confirm
        </Button>
      </Stack>
    </section>
  );
}

export default OtherData;
