import { Pagination, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';

import Move from './modals/Move';
import Split from './modals/Split';
import Row from './Row';

export default function DataTable(props) {
  const {
    searchedData, requestBody, pagination, summary, onSearch,
  } = props;
  const dataArray = Object.values(searchedData);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selected, setSelected] = useState([]);
  const [isMoveModalOpen, setIsMoveModalOpen] = React.useState(false);
  const [isSplitModalOpen, setIsSplitModalOpen] = React.useState(false);
  const [clickedRowData, setClickedRowData] = React.useState([]);

  const handleChangeItemsPerPage = async (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(currentPage, newPageCount);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newPage);
  };

  const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage);
  };

  React.useEffect(() => {
    onSearch(currentPage, itemsPerPage);
    setSelected([]);
  }, [currentPage, itemsPerPage]);

  React.useEffect(() => {
    setTotalPages(pagination.PageCount);
    setTotalRows(summary.TotalRowCount);
  }, [pagination, summary]);

  return (
    <section className="w-full">
      <div>
        <Button
          variant="contained"
          onClick={() => setIsMoveModalOpen(true)}
          disabled={selected.length === 0}
        >
          Move
        </Button>
        <Button
          variant="contained"
          className="ml-2"
          onClick={() => setIsSplitModalOpen(true)}
          disabled={selected.length === 0}
        >
          Split
        </Button>
        <TableContainer component={Paper} className="mt-3">
          <Table aria-label="collapsible table" className="relative mb-[65px]">
            <TableHead>
              <TableRow>
                <TableCell className="w-[10px]" />
                <TableCell className="text-center">Style ID</TableCell>
                <TableCell className="text-center">Style Name</TableCell>
                <TableCell className="text-center">Brand</TableCell>
                <TableCell className="text-center">Product Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataArray.map((row, index) => (
                <Row
                  key={row.ProductId || index}
                  row={row}
                  requestBody={requestBody}
                  setSelected={setSelected}
                  selected={selected}
                  clickedRowData={clickedRowData}
                  setClickedRowData={setClickedRowData}
                />
              ))}
            </TableBody>
            <tfoot className="absolute w-full">
              <tr className="flex justify-end mt-[20px]">
                <FormControl sx={{ minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center">
                  <Typography variant="body2" className="mr-4">Rows per page:</Typography>
                  <Select
                    labelId="logs-per-page"
                    value={itemsPerPage}
                    onChange={handleChangeItemsPerPage}
                    autoWidth
                    size="small"
                    variant="standard"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handleChangePage}
                />
              </tr>
            </tfoot>
          </Table>
        </TableContainer>
      </div>
      {isMoveModalOpen && (
        <Move
          open={isMoveModalOpen}
          onCloseModal={() => setIsMoveModalOpen(false)}
          selectedRow={selected}
          dataArray={dataArray}
          requestBody={requestBody}
          setSelected={setSelected}
          setClickedRowData={setClickedRowData}
        />
      )}
      {isSplitModalOpen && (
        <Split
          open={isSplitModalOpen}
          onCloseModal={() => setIsSplitModalOpen(false)}
          selectedRow={selected}
          dataArray={dataArray}
          requestBody={requestBody}
          setSelected={setSelected}
        />
      )}
    </section>
  );
}
