import InfoIcon from '@mui/icons-material/Info';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import VirtualizedAutocomplete from '../../../VirtualizedAutocomplete';

function SourcesFilter({ sources }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const sourcesFromQuery = searchParams.get('sources');
  const defaultValues = sources.filter((obj) => sourcesFromQuery?.split(',').includes(obj.SourceId.toString()));
  const selectedSourcesOnly = searchParams.get('selectedSourcesOnly');

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Sources</Typography>
        <div className="flex flex-col w-[215px]">
          <VirtualizedAutocomplete
            id="sources-virtualized-autocomplete"
            multiple
            options={sources}
            getOptionLabel={(option) => option.Name}
            onSelectedValue={(value) => {
              setSearchParams((params) => {
                params.set('sources', value.map((obj) => obj.SourceId));
                if (value.length === 0) params.delete('sources');
                return params;
              });
            }}
            size="small"
            value={defaultValues}
            limitTags={2}
            textFieldLabel="Source"
            placeholder="Sources"
            helperText="Select one or multiple sources"
            optionKey="Name"
          />
          <div className="flex items-center gap-2">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={selectedSourcesOnly}
                  onChange={(e) => {
                    setSearchParams((params) => {
                      params.set('selectedSourcesOnly', e.target.checked);
                      if (e.target.checked === false) params.delete('selectedSourcesOnly');
                      return params;
                    });
                  }}
                />
              )}
              label={<p className="text-[14px]">Selected sources only</p>}
              className="mr-0"
              disabled={sourcesFromQuery === null}
            />
            <Tooltip
              title={(
                <div className="text-sm">
                  <p>
                    <span className="font-gothambold">Selected:</span>
                    {' '}
                    Filtered results will show products with the selected Sources only.
                  </p>
                  <p>
                    <span className="font-gothambold">Un-Selected:</span>
                    {' '}
                    Filtered results will show products with the selected Sources but the product
                    may also contain other unselected Sources.
                  </p>
                </div>
              )}
            >
              <InfoIcon />
            </Tooltip>
          </div>
        </div>
      </Stack>
    </div>
  );
}

export default SourcesFilter;
