import patchRequest from '../../../../../patchRequest';

/**
 * Bulk approve products.
 *
 * @param {{ productId: string, isApproved: boolean }[]} productApproveList Id of the product
 * @param {number} siteId Site Id of the product
 */
export default function patchProductApprove(productApproveList, siteId) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/product-and-variants-status-approve';

  const body = {
    productApproveList,
    siteId,
  };
  const status = productApproveList[0].isApproved ? 'Approve' : 'Unapprove';
  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Patch Product Approve', `Started: Bulk Operation : ${status}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
