import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Balancer from 'react-wrap-balancer';

import JDGroup from '../../assets/JDGroupLogo.png';
import LoginLogo from '../../assets/LoginLogo.png';
import { removeLocalStorage } from '../../helper/LocalStorage';
import { fetchUser } from '../../redux/user/User';
import jwtDecoder from '../../utils/jwtDecoder';
import useStyles from './styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

function LoginLocal() {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [bIsLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [bShowPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onHandleChange = (event) => {
    const stringValue = event.target.value;
    return event.target.name === 'email'
      ? setEmail(stringValue)
      : setPassword(stringValue);
  };

  const onHandleMouseDownPassword = (event) => event.preventDefault();

  const onSubmitForm = async (event) => {
    event.preventDefault();

    setLoading(true);

    dispatch(fetchUser({ email, password }))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          toast.info('Browser may ask to save your credentials', { autoClose: 2000 });
          removeLocalStorage('logout-event');
          const userDecoded = jwtDecoder(res.JwtToken);
          if (userDecoded.typ === 'PrivateApi') {
            setLoading(false);
            navigate('/');
          } else {
            setError(true);
            console.error('User does NOT have access to PrivateApi:', res);
            const responseErrorMessage = 'You do not have access to login.';
            setErrorMessage(`Error: ${responseErrorMessage}`);
            setLoading(false);
          }
        } else {
          setError(true);
          console.error('Status NOT 200:', res);
          const responseErrorMessage = res.response.data.message == null
            ? 'Timeout - Please try again'
            : res.response.data.message;
          setErrorMessage(`Error: ${responseErrorMessage}`);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage('502: Please contact Frontend Dev Team.');
        console.error('Login Error:', e.message);
      });
  };

  const TooltipError = (
    <ul className="flex flex-col gap-2 text-sm list-disc list-inside">
      <li>You entered your username or password incorrectly.</li>
      <li>It has been 3 months so you are due a password change.</li>
      <li>
        You have not been granted access to PRISM.
        Please contact
        {' '}
        <a href="mailto:Multi-ChannelFrontEndDevTeam@jdplc.com" className="underline">
          Frontend Dev Team
        </a>
        .
      </li>
    </ul>
  );

  return (
    <div className="flex items-center justify-center w-screen h-screen bg-neutral-200">
      <Grid container classes={{ root: styles.model }} className="bg-white rounded-lg">

        {/* Left */}
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          className={[
            styles.imageBackgroundGradient,
            'flex items-center justify-center rounded-tl-lg rounded-bl-lg overflow-hidden',
          ].join(' ')}
        >
          <Box className="flex flex-col items-center w-1/2 h-1/2 sm:block">
            <img src={LoginLogo} className="hidden object-contain w-full h-full mb-8 sm:block" alt="Prism" />
          </Box>
        </Grid>

        {/* Right */}
        <Grid item xs={12} sm={8} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center" alignItems="flex-end" sx={{ height: '10rem' }}>
              <Box sx={{ width: 150 }}>
                <img src={JDGroup} alt="JD Group" />
              </Box>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Typography component="h5" variant="h5" color="secondary" gutterBottom>
                Welcome to
                {' '}
                <span className="text-2xl bold">PRISM</span>
              </Typography>
              <Typography component="p" variant="p">
                Sign in to PRISM with your JD account to access.
              </Typography>
            </Grid>

            {error && (
              <Grid item xs={12}>
                <div className="flex items-center justify-center gap-2">
                  <Balancer>
                    {errorMessage?.length >= 140
                      ? (
                        <CustomWidthTooltip
                          title={
                            errorMessage.split('\n').map((line, index) => (
                              <span key={`error-${index + 1}`}>
                                {line}
                              </span>
                            ))
                          }
                          arrow
                          placement="top"
                        >
                          <div className="w-2/3 text-red-500">
                            {`${errorMessage.slice(0, 140)}...`}
                          </div>
                        </CustomWidthTooltip>
                      )
                      : (
                        <Typography variant="body1" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                  </Balancer>
                  <Tooltip title={TooltipError} arrow placement="right" className="text-lg">
                    <HelpOutlineOutlinedIcon className="text-error-600" />
                  </Tooltip>
                </div>
              </Grid>
            )}

            <Grid item xs={12} container justifyContent="center">
              <form
                className="flex flex-col items-center justify-center w-3/4"
                onSubmit={onSubmitForm}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={error}
                  autoFocus
                  value={email}
                  onInput={(event) => setEmail(event.target.value)}
                />
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={bShowPassword ? 'text' : 'password'}
                  id="password"
                  error={error}
                  autoComplete="new-password"
                  value={password}
                  onChange={onHandleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!bShowPassword)}
                          onMouseDown={onHandleMouseDownPassword}
                          edge="end"
                        >
                          {bShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  color="secondary"
                  className="px-12 my-6"
                  loading={bIsLoading}
                >
                  Sign In
                </LoadingButton>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginLocal;
