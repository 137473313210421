const Fascias = [
  {
    name: 'JD Sports',
    code: 'JD',
  },
  {
    name: 'Size?',
    code: 'SZ',
  },
  {
    name: 'Tessuti',
    code: 'TS',
  },
  {
    name: 'Base',
    code: 'BS',
  },
  {
    name: 'Blacks',
    code: 'BL',
  },
];

export default Fascias;
