import deleteRequest from '../../../../deleteRequest';

/**
 * Deletes attribute definition.
 *
 * @param {number} productAttributeDefId
 * @returns {Promise} { Success: true }, status: 200 }
 */
export default function deleteAttribute(productAttributeDefId) {
  const endpoint = `/oc-data-management/v1/attributes/${productAttributeDefId}`;
  const errorMessageHeader = `Delete ${productAttributeDefId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
