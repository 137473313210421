import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const fromExample = [
  {
    variantId: 895419,
    size: 'S',
    barcode: '4052227275651',
  },
  {
    variantId: 895420,
    size: 'M',
    barcode: '4052227209618',
  },
  {
    variantId: 895421,
    size: 'L',
    barcode: '4052227275668',
  },
  {
    variantId: 895422,
    size: 'XL',
    barcode: '4052227275675',
  },
  {
    variantId: 895423,
    size: 'XXL',
    barcode: '4052227275682',
  },
];

const toExample = [
  {
    variantId: 997480,
    size: '6',
    barcode: '8718108441701',
  },
  {
    variantId: 997481,
    size: '7',
    barcode: '8718108441725',
  },
  {
    variantId: 997482,
    size: '8',
    barcode: '8718108441749',
  },
  {
    variantId: 997483,
    size: '9',
    barcode: '8718108441589',
  },
  {
    variantId: 997484,
    size: '10',
    barcode: '8718108441602',
  },
  {
    variantId: 997485,
    size: '11',
    barcode: '8718108441626',
  },
];

function SkuMerging() {
  const [fromPlu, setFromPlu] = useState('');
  const [fromLoading, setFromLoading] = useState(false);

  const [toPlu, setToPlu] = useState('');
  const [toLoading, setToLoading] = useState(false);

  const [skus, setSkus] = useState({
    fromSku: fromExample,
    toSku: toExample,
  });

  const handleFindFromPlu = () => {
    setFromLoading(true);
    setTimeout(() => {
      setFromLoading(false);
    }, 2000);
  };

  const handleFindToPlu = () => {
    setToLoading(true);
    setTimeout(() => {
      setToLoading(false);
    }, 2000);
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const start = skus[source.droppableId];
    const finish = skus[destination.droppableId];

    if (start === finish) {
      const newSku = [...start];
      const [removed] = newSku.splice(source.index, 1);
      newSku.splice(destination.index, 0, removed);

      const newSkus = {
        ...skus,
        [source.droppableId]: newSku,
      };

      setSkus(newSkus);
    }

    const [removed] = start.splice(source.index, 1);
    const newStart = [...start];

    finish.splice(destination.index, 0, removed);
    const newFinish = [...finish];

    const newState = {
      ...skus,
      [source.droppableId]: newStart,
      [destination.droppableId]: newFinish,
    };

    setSkus(newState);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Container>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            container
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography
              variant="h4"
              className="font-gothamlight"
              gutterBottom
            >
              Sku Merging
            </Typography>
            <Typography variant="subtitle1">
              Drag and drop chips over then click confirm to merge
            </Typography>
          </Grid>

          <Grid container item xs={12}>
            <Grid
              item
              xs={5}
              container
            >
              <Box className="flex flex-col items-start justify-start">
                <Typography variant="h6" className="bold" gutterBottom>
                  From
                </Typography>

                <Box className="flex items-center gap-4 mb-4">
                  <TextField label="PLU/Product ID" value={fromPlu} onChange={(e) => setFromPlu(e.target.value)} />
                  <LoadingButton loading={fromLoading} variant="contained" color="info" onClick={() => handleFindFromPlu()}>
                    Search
                  </LoadingButton>
                </Box>

                <Box className="my-4">
                  <Typography variant="body1" gutterBottom>
                    00004 - Borg Long Sleeve Shirt
                  </Typography>
                </Box>

                <Box className="bg-gray border border-gray-500 rounded-sm p-4 w-full flex flex-col items-start">
                  <Typography variant="caption" gutterBottom>
                    Variant - Size - EAN
                  </Typography>
                  <Droppable droppableId="fromSku">
                    {(provided) => (
                      <Box className="w-full p-2" ref={provided.innerRef} {...provided.droppableProps}>
                        {skus.fromSku.map((item, index) => (
                          <Draggable key={item.barcode} draggableId={item.barcode} index={index}>
                            {(provided2, snapshot) => (
                              <Chip
                                key={item.barcode}
                                label={`${item.variantId} - ${item.size} - ${item.barcode}`}
                                className={`${snapshot.isDragging && 'shadow-md'} mr-2 mb-2`}
                                {...provided2.draggableProps}
                                {...provided2.dragHandleProps}
                                ref={provided2.innerRef}
                              />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2} container justifyContent="center" alignItems="flex-start">
              <Button>
                <SwapHorizIcon className="text-4xl" />
              </Button>
            </Grid>

            <Grid
              item
              xs={5}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Box className="flex flex-col items-start justify-start">
                <Typography variant="h6" className="bold" gutterBottom>
                  To
                </Typography>

                <Box className="flex items-center gap-4 mb-4">
                  <TextField label="PLU/Product ID" value={toPlu} onChange={(e) => setToPlu(e.target.value)} />
                  <LoadingButton loading={toLoading} variant="contained" color="info" onClick={() => handleFindToPlu()}>
                    Search
                  </LoadingButton>
                </Box>

                <Box className="my-4">
                  <Typography variant="body1" gutterBottom>
                    00040 - GEL-Lyte III
                  </Typography>
                </Box>

                <Box className="bg-gray border border-gray-500 rounded-sm p-4 w-full flex flex-col items-start">
                  <Typography variant="caption" gutterBottom>
                    Variant - Size - EAN
                  </Typography>
                  <Droppable droppableId="toSku">
                    {(provided) => (
                      <Box className="w-full p-2" ref={provided.innerRef} {...provided.droppableProps}>
                        {skus.toSku.map((item, index) => (
                          <Draggable key={item.barcode} draggableId={item.barcode} index={index}>
                            {(provided2, snapshot) => (
                              <Chip
                                key={item.barcode}
                                label={`${item.variantId} - ${item.size} - ${item.barcode}`}
                                className={`${snapshot.isDragging && 'shadow-md'} mr-2 mb-2`}
                                {...provided2.draggableProps}
                                {...provided2.dragHandleProps}
                                ref={provided2.innerRef}
                              />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end">
            <Button variant="contained" color="success">
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Container>
    </DragDropContext>
  );
}

export default SkuMerging;
