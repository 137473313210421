import putRequest from '../../../../putRequest';

/**
 * Put request for creating a new attribute definition.
 *
 * @param {obj} body {
 *   "productAttributeDefKey": "string",
 *   "englishName": "string",
 *   "name": "string",
 *   "language": "string",
 *   "languageId": 0,
 *   "siteIds": [
 *     0
 *   ],
 *   "attributeDataTypeId": 0,
 *   "attributeDataTypeName": "string",
 *   "isReadOnly": true,
 *   "allowUpdate": true,
 *   "isMandatory": true,
 *   "isHidden": true,
 *   "includeInSearchKeywords": true,
 *   "attributeLookupValues": [
 *     {
 *       "languageId": 0,
 *       "value": "string",
 *       "isDefaultValue": true
 *     }
 *   ]
 * }
 */
export default function putAttribute(body) {
  const endpoint = '/oc-data-management/v1/attributes';

  return new Promise((resolve, reject) => {
    putRequest(endpoint, body, 'Create Attribute Definition')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
