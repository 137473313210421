import postRequest from '../../../../postRequest';

/**
 * Calls products endpoint that submits PIM DB data to Shogun.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @param {array} additionalSiteCodes - [JF, JI]
 * @returns {Promise} { data: {}, status: 200 }
 */
async function postProducts(
  productId,
  siteCode,
  additionalSiteCodes,
) {
  const endpoint = '/integration-shogun/v1/products';

  const body = {
    productId,
    siteCode,
    additionalSiteCodes,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Error Submitting to Shogun')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postProducts;
