import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import postMoveStyles from '../../../../api/oc_product/styles/post/postMoveStyles';
import postStyleName from '../../../../api/oc_product/styles/post/postStyleName';
import postStyles from '../../../../api/oc_product/styles/post/postStyles';
import Modal from '../../../../components/Modal';
import { ProductContext } from '../../../product_info/products/product/ProductContext';
import { StylesContext } from '../../StylesContext';
import Row from '../Row';

function Move({
  open, onCloseModal, selectedRow, requestBody, dataArray: initialDataArray, selected, setSelected,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [styleName, setStyleName] = React.useState();
  const [styleNameList, setStyleNameList] = React.useState([]);
  const [clickedRowData, setClickedRowData] = React.useState([]);
  const [moveData, setMoveData] = React.useState([]);
  const [styleId, setStyleId] = React.useState();
  const [loading, setLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);

  const [dataArray, setDataArray] = useState(initialDataArray);
  const { setStylesReRender } = useContext(StylesContext);
  const { setProductStyleRerender } = useContext(ProductContext);

  const page = 1;
  const pageSize = 100;

  const siteId = useSelector(
    (state) => state.user.loginResponse.selectedSite.SiteId,
  );
  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);
  const siteCode = useSelector((state) => state?.user?.loginResponse?.selectedSite?.SiteCode);

  const handleShowNames = async (event, value) => {
    try {
      // Splitting the value by the hyphen and getting the first part
      const id = value.split(' - ')[0];
      setStyleId(parseInt(id, 10));
      const parts = value.split(' - ');
      const nameWithoutPrefix = parts.length > 1 ? parts[1] : value;

      const req = {
        siteId,
        name: nameWithoutPrefix,
        languageId,
      };

      const res = await postStyleName(page, pageSize, req);
      if (res && res.data && res.data.length > 0) {
        setStyleNameList(res.data);
      }
    } catch (error) {
      console.error('Error fetching style names');
    }
  };

  const handleSaveMove = async () => {
    try {
      setLoading(true);
      const productIds = selectedRow.map((row) => row.ProductId);

      const res = await postMoveStyles(productIds, styleId);

      selectedRow.forEach((selectedProduct) => {
        dataArray?.forEach((item) => {
          item.Products.forEach((product, index) => {
            if (item.Products) {
              if (product.ProductId === selectedProduct.ProductId) {
                dataArray[0].Products.splice(index, 1);
                setDataArray([...dataArray]);
              }
            }
          });
        });
      });

      setTimeout(async () => {
        if (res) {
          if (window.location.href.includes('product-info')) {
            setProductStyleRerender(true);
          } else {
            setStylesReRender(true);
          }
        }
      }, 7000);

      toast.success('Successfully Moved Style');
      toast.success('Updating data');
      onCloseModal(false);
    } catch (error) {
      console.error('Error during save:', error);
    } finally {
      setLoading(false);
      setSelected([]);
    }
  };

  const handleChange = async (e, value) => {
    if (value === null) {
      setStyleName('');
    } else {
      setStyleName(value.Name);
    }
    try {
      setProductsLoading(true);
      const newDataBody = {
        siteId,
        siteCode,
        name: value.Name,
        styleIds: [value.StyleId],
        productId: [],
        plus: [],
        brandIds: [],
        hasStyle: null,
      };
      const newData = await postStyles(page, pageSize, newDataBody);
      setMoveData(newData.data.Data);
    } catch (error) {
      console.error('Error while handling change:', error);
    } finally {
      setProductsLoading(false);
    }
  };

  React.useEffect(() => {
    searchParams.get('pageSize');
    setSearchParams((params) => {
      params.delete('pageSize');
      params.delete('page');
      return params;
    });
  }, [siteId]);

  return (
    <Modal
      open={open}
      title="Moving Styles"
      buttonLabel="Save"
      buttonOnClick={handleSaveMove}
      onClose={() => onCloseModal(false)}
      disableButton={!styleName}
      desktopModalWidth="50%"
      buttonLoading={loading}
    >
      <Typography variant="h6" className="mb-4">This will move the following products</Typography>

      <div className="flex flex-wrap mb-[20px] justify-center">
        {selectedRow.map((row) => (
          <p className="pr-5 text-center">{row.ProductId}</p>
        ))}
      </div>
      <div className="max-h-[400px] overflow-scroll">
        <Typography variant="h6" className="mb-2 text-center">To this style</Typography>
        <div className="flex">
          <Typography variant="subtitle1" className="w-[150px] mb-4 mt-2">Search Style:</Typography>
          <Autocomplete
            id="free-solo-demo"
            size="small"
            value={styleName}
            onChange={handleChange}
            onInputChange={handleShowNames}
            options={styleNameList || []}
            getOptionLabel={(option) => `${option.StyleId} - ${option.Name}` || ''}
            renderInput={(params) => <TextField {...params} label="" key="style-name-autocomplete" />}
            rowSelectionModel={selected}
            className="w-full"
            clearOnBlur={false}
          />
        </div>
        {productsLoading ? (
          <div className="flex items-center justify-center w-full">
            <CircularProgress color="primary" />
          </div>
        ) : (
          styleName && (
            moveData.map((row) => (
              <Table>
                <TableBody sx={{
                  '& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox': {
                    display: 'none',
                  },
                }}
                >
                  <Row
                    key={row.ProductId || ''}
                    row={row}
                    requestBody={requestBody}
                    clickedRowData={clickedRowData}
                    setClickedRowData={setClickedRowData}
                    setSelected={setSelected}
                    page={page}
                    pageSize={pageSize}
                  />
                </TableBody>
              </Table>
            ))
          )
        )}
      </div>
    </Modal>
  );
}

export default Move;
