import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Table from '../../../../components/Table';
import CreateModal from './modals/CreateModal';
import EditModal from './modals/EditModal';

function Attributes() {
  const navigate = useNavigate();

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [selectedEditRow, setSelectedEditRow] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event, pageNumber) => {
    setPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'department_code', label: 'Department Code' },
    { id: 'global', label: 'Global' },
    { id: 'default', label: 'Default' },
    { id: 'actions', label: '' },
  ];

  const apiResponse = [
    {
      id: '1',
      name: 'Product Type 1',
      department_code: 'A8MQ',
      global: true,
      default: true,
    },
    {
      id: '2',
      name: 'Product Type 2',
      department_code: 'K3DF',
      global: true,
      default: false,
    },
    {
      id: '3',
      name: 'Product Type 3',
      department_code: 'M6SS',
      global: true,
      default: false,
    },
  ];
  const buttons = (row) => (
    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
      <Button
        variant="outlined"
        color="warning"
        onClick={() => {
          setSelectedEditRow(row);
          setOpenEditModal(true);
        }}
      >
        Edit
      </Button>
      <Button variant="outlined" color="error">Delete</Button>
    </Stack>
  );

  const rows = apiResponse.map((row) => ({
    ...row,
    actions: buttons(row),
  }));

  return (
    <Container>
      <CreateModal
        open={openCreateModal}
        onCloseModal={() => setOpenCreateModal(false)}
      />
      <EditModal
        open={openEditModal}
        onCloseModal={() => setOpenEditModal(false)}
        selectedRow={selectedEditRow}
      />
      <Grid container spacing={4} marginTop>
        <Grid item xs={12}>
          <Typography variant="h4" className="font-gothamlight">
            Product Type Attributes
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={() => navigate('/data-management/product-type')}>
            <ArrowBackIosIcon />
            Product Types
          </Button>
        </Grid>

        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <TextField label="Filter" variant="outlined" />
          <Button variant="contained" color="success" onClick={() => setOpenCreateModal(true)}>
            Create
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Table columns={columns} rows={rows} fixed={false} />
          <TablePagination
            rowsPerPageOptions={[15, 30, 90]}
            component="div"
            count={rows.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Attributes;
