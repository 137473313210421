import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList as VirtualizedList } from 'react-window';

import postMediaCheckReports from '../../api/oc_product/bulk/bulk_process/sites/reports/posts/postMediaCheckReports';
import JobModal from './components/JobModal';
import FailedTabRow from './components/tab_rows/FailedTabRow';
import PartialTabRow from './components/tab_rows/PartialTabRow';
import ProcessingTabRow from './components/tab_rows/ProcessingTabRow';
import SuccessTabRow from './components/tab_rows/SuccessTabRow';
import TabPanel from './components/TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: `${theme.palette.secondary[500]} !important`,
    height: '4px !important',
  },
  selected: {
    color: '#fff !important',
    opacity: '1 !important',
  },
  tabRoot: {
    color: '#fff !important',
    flex: 1,
    fontSize: '12px !important',
  },
}));

// const STATUS_DEF = {
//   Wait: 0,
//   New: 1,
//   Active: 2,
//   Processing: 3,
//   PartiallyProcessed: 4,
//   Processed: 5,
//   Failed: 10,
//   Removed: 20,
//   Disabled: 30,
// };

export default function ProgressSideBarMediaCheck({ inProgress }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const tabPanel = React.useRef(null);

  const styles = useStyles();

  const [value, setValue] = React.useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [
    selectedId,
    setSelectedId,
  ] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const [inProgressData, setInProgressData] = React.useState([]);
  const [failedData, setFailedData] = React.useState([]);
  const [partialData, setPartialData] = React.useState([]);
  const [successData, setSuccessData] = React.useState([]);

  const [tabPanelHeight, setTabPanelHeight] = React.useState(0);

  const [selectedTransaction, setSelectedTransaction] = React.useState({});

  const handleGetReports = () => {
    postMediaCheckReports(siteId, [2, 3]) // "Active": 2, "Processing": 3,
      .then((res) => setInProgressData(res.data))
      .catch((err) => console.error(err));
    postMediaCheckReports(siteId, [4]) // "PartiallyProcessed": 4,
      .then((res) => setPartialData(res.data))
      .catch((err) => console.error(err));
    postMediaCheckReports(siteId, [5]) // "Processed": 5,
      .then((res) => setSuccessData(res.data))
      .catch((err) => console.error(err));
    postMediaCheckReports(siteId, [10]) // "Failed": 10,
      .then((res) => setFailedData(res.data))
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    setInProgressData(inProgress);
  }, [inProgress]);

  React.useEffect(() => {
    if (tabPanel.current) {
      const { height } = tabPanel.current.getBoundingClientRect();
      setTabPanelHeight((height - 65));
    }
  }, [tabPanel.current]);

  React.useEffect(() => {
    handleGetReports();
    const interval = setInterval(() => {
      handleGetReports();
    }, 10000); // 10 seconds
    return () => clearInterval(interval);
  }, [siteId]);

  return (
    <section className="h-[400px] sticky top-0 overflow-scroll">
      {openModal && <JobModal id={selectedId} open={openModal} setIsOpen={setOpenModal} parentData={selectedTransaction} />}
      <div className="h-full min-w-[400px]">
        <Box sx={{ width: '100%' }} className="h-full border rounded bg-neutral-100" ref={tabPanel}>
          <Box sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            zIndex: 20,
          }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              classes={{ indicator: styles.indicator, root: styles.selected }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                label="Processing"
                {...a11yProps(0)}
                classes={{
                  root: styles.tabRoot,
                  selected: styles.selected,
                }}
              />
              <Tab
                label="Failed"
                {...a11yProps(1)}
                classes={{
                  root: styles.tabRoot,
                  selected: styles.selected,
                }}
              />
              <Tab
                label="Partial"
                {...a11yProps(2)}
                classes={{
                  root: styles.tabRoot,
                  selected: styles.selected,
                }}
              />
              <Tab
                label="Success"
                {...a11yProps(3)}
                classes={{
                  root: styles.tabRoot,
                  selected: styles.selected,
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {inProgressData.length > 0 ? (
              <VirtualizedList
                height={tabPanelHeight}
                itemCount={inProgressData.length}
                itemSize={130}
                width={400}
                itemData={inProgressData}
              >
                {ProcessingTabRow}
              </VirtualizedList>
            ) : (
              <div className="flex items-center justify-center h-16 w-[400px]">
                <p>No Operations Processing</p>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {failedData.length > 0 ? (
              <VirtualizedList
                height={tabPanelHeight}
                itemCount={failedData.length}
                itemSize={180}
                width={400}
                itemData={failedData}
              >
                {({ data, index, style }) => (
                  <FailedTabRow
                    data={data}
                    index={index}
                    style={style}
                    setSelected={(obj) => {
                      setSelectedId(obj.Id);
                      setSelectedTransaction(obj);
                    }}
                    setOpenModal={setOpenModal}
                  />
                )}
              </VirtualizedList>
            ) : (
              <div className="flex items-center justify-center h-16 w-[400px]">
                <p>No Failed Operations</p>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={2} sx={{ position: 'relative' }}>
            {partialData.length > 0 ? (
              <VirtualizedList
                height={tabPanelHeight}
                itemCount={partialData.length}
                itemSize={130}
                width={400}
                itemData={partialData}
              >
                {({ data, index, style }) => (
                  <PartialTabRow
                    data={data}
                    index={index}
                    style={style}
                    setSelected={(obj) => {
                      setSelectedId(obj.Id);
                      setSelectedTransaction(obj);
                    }}
                    setOpenModal={setOpenModal}
                  />
                )}
              </VirtualizedList>
            ) : (
              <div className="flex items-center justify-center h-16 w-[400px]">
                <p>No Partial Successful Operations</p>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {successData.length > 0 ? (
              <VirtualizedList
                height={tabPanelHeight}
                itemCount={successData.length}
                itemSize={128}
                width={400}
                itemData={successData}
              >
                {({ data, index, style }) => (
                  <SuccessTabRow
                    data={data}
                    index={index}
                    style={style}
                    setSelected={(obj) => {
                      setSelectedId(obj.Id);
                      setSelectedTransaction(obj);
                    }}
                    setOpenModal={setOpenModal}
                  />
                )}
              </VirtualizedList>
            ) : (
              <div className="flex items-center justify-center h-16 w-[400px]">
                <p>No Successful Operations</p>
              </div>
            )}
          </TabPanel>
        </Box>

      </div>
    </section>
  );
}
