import deleteRequest from '../../../../../deleteRequest';

/**
 * Calls product-category endpoint to delete one of the categories mapped to a product in PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {number} categoryId - 1
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function deleteProductCategory(productId, categoryId) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/product-category';

  const body = {
    productId,
    categoryId,
  };

  // const successMessage = `Successfully Deleted CategoryID: ${categoryId} for ${productId}`;
  const errorMessageHeader = 'Delete Category';

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteProductCategory;
