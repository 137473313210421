import { createSlice } from '@reduxjs/toolkit';

const sitePermissionsSlice = createSlice({
  name: 'sitePermissions',
  initialState: { selected_site_permissions: [] },
  reducers: {
    setSelectedSitePermissions: (state, action) => {
      state.selected_site_permissions = action.payload;
    },
  },
});

export const { setSelectedSitePermissions } = sitePermissionsSlice.actions;

export default sitePermissionsSlice.reducer;
