/**
 * Takes an ISO Date string and makes it user readable.
 *
 * @param {string} str ISO Date String
 * @returns {string}
 */
export default function parseDateString(str) {
  if (typeof str !== 'string') throw Error('Date needs to be a string in YYYY-MM-DD format.');
  const dateParts = str.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})/);
  if (dateParts) {
    return `${dateParts[3]}/${dateParts[2]}/${dateParts[1]}, ${dateParts[4]}`;
  }
  return '';
}
