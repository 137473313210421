import patchRequest from '../../../patchRequest';

/**
 * Updates the brand group brands.
 *
 * @param {number} siteId - 1
 * @param {number} brandGroupId - 1
  * @param {object} body
 * @returns {Promise} { Success: true }, status: 200 }
 */
export default async function patchAddRemoveBrands(
  siteId,
  brandGroupId,
  body,
) {
  const endpoint = `/oc-data-management/v1/brand-group/${brandGroupId}/${siteId}/add-remove-brands`;

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Product Brand')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
