import 'react-tridi/dist/index.css';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Tridi from 'react-tridi';

import postProductMediaCheck from '../../../../../../../api/oc_product/product/mediaCheck/postProductMediaCheck';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`media-tabpanel-${index}`}
      aria-labelledby={`media-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ backgroundColor: 'white', height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((() => ({
  tabRoot: {
    width: '33.333%',
  },
})));

function MediaDisplay({
  images,
  video,
  spinSets,
  setImages,
  setVideo,
  setSpinSets,
}) {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [imagePagination, setImagePagination] = React.useState(0);
  const [parentImages, setParentImages] = React.useState([]);

  const [refreshLoading, setRefreshLoading] = React.useState(false);

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const { id } = useParams();
  const styles = useStyles();

  React.useEffect(() => {
    if (images) {
      setParentImages(images);
    }
  }, [images]);

  const getDisplayMedia = async () => {
    try {
      const data = await postProductMediaCheck(siteId, [Number(id)]);
      // const data = await getMediaCheck(siteId, rowData.ProductId);

      const mediaLink = data?.data.MediaSetLink;

      if (mediaLink !== 'https://cdn.media.amplience.net/i/jpl/imagemissing') {
        const secureMediaSetLink = mediaLink.replace(/^http:\/\//i, 'https://');

        const response = await axios.get(secureMediaSetLink);
        const itemsArray = response.data;

        if (itemsArray) {
          const productImages = itemsArray?.items?.filter((item) => item.type === 'img');
          const productVideo = itemsArray.items.find((item) => item.type === 'video');
          const productSpinSet = itemsArray.items.filter((item) => item.type === 'set');

          setImages(productImages);
          setVideo(productVideo);

          if (productSpinSet.length > 0) {
            const spinSetSrc = productSpinSet[0].src;
            const httpsSpinSet = spinSetSrc?.slice(0, 5) === 'http:' ? `https${spinSetSrc.slice(4)}` : spinSetSrc;
            const jsonSpinSet = httpsSpinSet.includes('.json') ? httpsSpinSet : `${httpsSpinSet}.json`;
            const spinSetResponse = await axios.get(jsonSpinSet);
            setSpinSets(spinSetResponse.data);
          }
        } else {
          console.error('Invalid response data:', itemsArray);
        }
      } else {
        setImages([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the case where the file is not found, if needed
        setImages([]);
      } else {
        console.error('Error fetching media:', error);
      }
    }
  };

  return (
    <Box className="w-full overflow-hidden border rounded h-max">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          className="bg-white"
        >
          <Tab
            label="Images"
            classes={{
              root: styles.tabRoot,
            }}
          />
          <Tab
            label="Video"
            classes={{
              root: styles.tabRoot,
            }}
          />
          <Tab
            label="Spin Set"
            classes={{
              root: styles.tabRoot,
            }}
          />
        </Tabs>
      </Box>
      {/* Images */}
      <TabPanel value={selectedTab} index={0}>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="px-12 h-2/3">
            {parentImages && parentImages.length > 0 ? (
              <img
                src={`https${parentImages[imagePagination]?.src?.slice(4) || '://cdn.media.amplience.net/i/jpl/imagemissing'}`}
                alt="product"
              // className="h-full"
              />
            ) : (
              <img
                src="https://cdn.media.amplience.net/i/jpl/imagemissing"
                alt="product"
              // className="h-full"
              />
            )}
          </div>
          <div className="h-1/3">
            <div className="flex items-center justify-center w-full pt-2 pb-1 flex-0">
              <LoadingButton
                loading={refreshLoading}
                size="small"
                variant="outlined"
                startIcon={<RefreshRoundedIcon />}
                onClick={() => {
                  setRefreshLoading(true);
                  toast.success('Images/Videos/Spin Sets have been refreshed');
                  getDisplayMedia()
                    .catch((err) => {
                      toast.error('Failed to Refresh Assets');
                      console.error('Asset Refresh', err);
                    })
                    .finally(() => setRefreshLoading(false));
                }}
              >
                Refresh
              </LoadingButton>
            </div>
            {parentImages.length > 0 && (
              <div className="flex justify-center flex-1 py-2">
                <Pagination
                  count={parentImages.length}
                  onChange={(e, v) => setImagePagination((v - 1))}
                  siblingCount={0}
                />
              </div>
            )}
          </div>
        </div>
      </TabPanel>
      {/* Video */}
      <TabPanel value={selectedTab} index={1}>
        <div className="flex items-center justify-center p-2">
          {video?.src ? (
            <video controls>
              <track default kind="captions" />
              <source src={`${video.src}/mp4_720p`} type="video/mp4" />
              Sorry your browser does not support HTML5 video.
            </video>
          ) : (
            <p>No Video Available</p>
          )}
        </div>
      </TabPanel>
      {/* Spin Set */}
      <TabPanel value={selectedTab} index={2}>
        <div className="flex items-center justify-center p-2">
          {spinSets.items ? (
            <Tridi
              images={spinSets.items?.map((obj) => obj.src) || []}
              hintOnStartup
              hintText="Drag To View"
              autoplaySpeed={80}
              showControlBar
              className="pb-12"
            />
          ) : (
            <p>No Spin Set Available</p>
          )}
        </div>
      </TabPanel>
    </Box>
  );
}

export default MediaDisplay;
