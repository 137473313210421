import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Image component which will display a loading skeleton whilst the image is downloading.
 *
 * @component
 * @param {string} src Source of the image.
 * @param {string} height Default is "auto". Enter any value for the height.
 * @param {string} width Default is "auto". Enter any value for the width.
 * @param {string} skeletonVariant Shape of Skeleton.
 * @example
 * 'circular' | 'rectangular' | 'rounded' | 'text'
 * @param {string} className Default is "auto". Enter any value for the width.
 * @returns {Component} <Image src="" height="" width="" skeletonVariant="" className="" />
 */
function Image({
  src,
  className,
  height = 'auto',
  width = 'auto',
  skeletonVariant = 'rectangular',
  alt,
}) {
  const [loading, setLoading] = React.useState(true);
  const [showMissing, setShowMissing] = React.useState(false);

  const imageLoaded = () => {
    setLoading(false);
  };

  const imageStyle = loading ? { width: 0, height: 0 } : {};

  React.useEffect(() => {
    if (src === null) {
      setLoading(false);
      setShowMissing(true);
    }
  }, []);

  return (
    <>
      {loading && (
        <Skeleton
          variant={skeletonVariant}
          width={width}
          height={height}
          animation="wave"
        />
      )}
      {showMissing ? (
        <img
          src="https://cdn.media.amplience.net/i/jpl/imagemissing"
          alt={alt}
          height={height}
          width={width}
          loading="lazy"
          className={className}
          style={imageStyle}
        />
      ) : (
        <img
          src={src}
          alt="Coming Soon"
          height={height}
          width={width}
          loading="lazy"
          className={className}
          onLoad={imageLoaded}
          style={imageStyle}
          onError={() => {
            setShowMissing(true);
            imageLoaded();
          }}
        />
      )}
    </>
  );
}

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  skeletonVariant: PropTypes.string,
};

Image.defaultProps = {
  className: '',
  height: 'auto',
  width: 'auto',
  skeletonVariant: 'rectangular',
};
