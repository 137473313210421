import CallSplitIcon from '@mui/icons-material/CallSplit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import patchProductBrand from '../../../../../api/oc_product/product/brand/patches/patchProductBrand';
import patchProductStyles from '../../../../../api/oc_product/product/style/patches/patchProductStyles';
// import getSources from '../../../../../api/oc_product/sites/sources/gets/getSources';
import Image from '../../../../../components/image/Image';
import VirtualizedAutocomplete from '../../../../../components/VirtualizedAutocomplete';
import Move from '../../../../styles/components/modals/Move';
import Split from '../../../../styles/components/modals/Split';
import { ProductContext } from '../ProductContext';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontFamily: 'GothamBold',
  },
  color: {
    color: '#285B72',
  },
  productName: {
    color: `${theme.palette.secondary[500]} !important`,
    fontSize: '1.5rem !important',
  },
  brandTextFieldInputRootStyling: {
    marginBottom: '0.5rem',
  },
  brandInputLabelStyling: {
    color: 'black !important',
    fontSize: '1.15rem !important',
  },
  // dropdownStyling: {
  //   width: '22rem',
  // },
}));

function ProductData({
  parentLoading,
  productImage,
  productName,
  productBrand,
  existingBrands,
  productColor,
  styleId,
  selected,
}) {
  const styles = useStyles();

  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language?.language?.LanguageId);
  const sitePermissions = useSelector((state) => state.site_permissions.selected_site_permissions);
  const { historyReRender, setHistoryReRender } = React.useContext(ProductContext);

  const [previousName, setPreviousName] = useState(productName);
  const [name, setName] = useState('');
  const [brand, setBrand] = useState(null);
  const [color, setColor] = useState('');
  const [brands, setBrands] = useState([]);
  const [editName, setEditName] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);

  const [updateRequestPending, setUpdateRequestPending] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSave = () => {
    if (name !== previousName) {
      setUpdateRequestPending(true);
      patchProductStyles(id, siteId, name, languageId)
        .then(() => setUpdateRequestPending(false))
        .then(() => {
          toast.success('Product name updated', { autoClose: 2000 });
          setPreviousName(name);
        })
        .catch((error) => {
          console.error(error);
          setUpdateRequestPending(false);
          setName(previousName);
        })
        .finally(() => {
          setHistoryReRender(!historyReRender);
          setEditName(false);
        });
    }
  };

  const handleCancel = () => {
    setName(previousName);
    setEditName(false);
  };

  const renderName = () => {
    if (editName) {
      return (
        <div className="flex items-center">
          <TextField
            id="name-filter-textfield"
            placeholder="Enter product name"
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            size="small"
          />
          {updateRequestPending ? (
            <CircularProgress />
          ) : (
            <div className="flex flex-row">
              <IconButton onMouseDown={(e) => e.preventDefault()} onMouseUp={handleSave}>
                <CheckIcon className="text-success-600" />
              </IconButton>
              <IconButton onMouseDown={(e) => e.preventDefault()} onMouseUp={handleCancel}>
                <CloseIcon className="text-error-600" />
              </IconButton>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="flex items-center">
        <Typography className={`${styles.productName}`}>{name}</Typography>
        <IconButton
          onMouseDown={(e) => e.preventDefault()}
          onMouseUp={() => setEditName(true)}
        >
          <CreateIcon />
        </IconButton>
      </div>
    );
  };

  useEffect(() => {
    setName(productName);
    setPreviousName(productName);
    if (productBrand) {
      setBrand(productBrand);
    }
    setColor(productColor);
    setBrands(existingBrands);
  }, [productName, productBrand, productColor]);

  useEffect(() => {
    // convert object to Array to match split data that is being passed
    const selectedArray = Object.values(selected).map((item, index) => {
      if (index === 0) {
        // If it's the first item, create an object with ProductId property
        return { ProductId: item };
      }
      return item;
    });
    // remove arrays so only contains objects
    const filteredArray = selectedArray.filter((item) => !Array.isArray(item));

    setSelectedRows(filteredArray);
  }, [id, showMoveModal]);

  return (
    <div>
      <div className="flex flex-col items-start w-full">
        <div className="flex flex-row items-center mb-2">
          {parentLoading ? <Skeleton height="2.5rem" width="22rem" />
            : (
              renderName()
            )}
        </div>
        <div className="flex flex-row items-start w-full">
          <div className="flex items-center justify-center w-48 mr-8">
            <Image
              src={productImage}
              alt="product"
            />
          </div>
          <Box sx={{ width: '100%' }}>
            {parentLoading ? <Skeleton height="2.5rem" width="12rem" /> : (
              <VirtualizedAutocomplete
                options={brands}
                textFieldVariant="standard"
                textFieldLabel="Brand"
                value={brand}
                getOptionLabel={(option) => option.Name}
                inputLabelStyling={styles.brandInputLabelStyling}
                autoCompleteInputRootStyling={styles.brandTextFieldInputRootStyling}
                dropdownStyling={styles.dropdownStyling}
                disableClearable
                onSelectedValue={(value) => {
                  setBrand(value);
                  patchProductBrand(id, siteId, value.BrandId, languageId)
                    .then(() => {
                      toast.success('Product brand updated', { autoClose: 2000 });
                    });
                  setHistoryReRender(!historyReRender);
                }}
                optionKey="Name"
                disabled={sitePermissions.find((obj) => obj.Name === 'BrandUpdatePermission')?.Value !== 'true'}
              />
            )}
            <div className="flex justify-between">
              <div>
                <Typography variant="body2">Colour</Typography>
                {parentLoading ? <Skeleton height="2.5rem" width="12rem" /> : (
                  <Typography variant="body2" className="font-bold">{color || 'NOT SET'}</Typography>
                )}
              </div>
            </div>
            <div>
              <Typography variant="body2">Style</Typography>
              {parentLoading ? <Skeleton height="2.5rem" width="12rem" /> : (
                <Typography variant="body2" className="font-bold">{styleId}</Typography>
              )}
            </div>
            <div className="mt-[2px]">
              <Button variant="text" className="text-sm text-error-700 ml-[-10px]" onClick={() => setShowSplitModal(true)}>
                <CallSplitIcon />
                Split
              </Button>
              <Button variant="text" className="text-sm text-success-700" onClick={() => setShowMoveModal(true)}>
                <MergeTypeIcon />
                Move
              </Button>
            </div>
          </Box>
        </div>
      </div>

      {showSplitModal && <Split selectedRow={selectedRows} open={showSplitModal} onCloseModal={() => setShowSplitModal(false)} />}
      {showMoveModal && <Move selectedRow={selectedRows} open={showMoveModal} onCloseModal={() => setShowMoveModal(false)} />}

    </div>
  );
}

export default ProductData;

ProductData.defaultProps = {
  existingBrands: [],
  parentLoading: false,
  // productBrand: {},
  productColor: '',
  productName: '',
};

ProductData.propTypes = {
  existingBrands: PropTypes.arrayOf(PropTypes.object),
  parentLoading: PropTypes.bool,
  // productBrand: PropTypes.object,
  productColor: PropTypes.string,
  productName: PropTypes.string,
};
