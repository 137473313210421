import axios from 'axios';

import instanceAxios from '../axios/instanceAxios';
import catchError from './catchError';

/**
 * The HTTP GET method requests a representation of the specified resource.
 * Requests using GET should only be used to request data (they shouldn't include data).
 *
 * @param {string} endpoint
 * @example
 * "/example/endpoint"
 * @param {string} errorMessageHeader - Message that proceeds the error message.
 * Usually to provide context on what exactly has errored.
 * @example
 * "Get Categories"
 * @param {object} options Request options.
 * @example
 * {
 *   headers: {
 *     'Content-Type': 'application/json'
 *   }
 * }
 * @returns {Promise} { data: {}, status: 200 }
 */
async function getRequest(endpoint, errorMessageHeader, options) {
  return new Promise((resolve, reject) => {
    instanceAxios()
      .get(endpoint, options)
      .then((response) => resolve(response))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          catchError(error, errorMessageHeader);
          reject(error);
        }
        console.error(error);
      });
  });
}

export default getRequest;
