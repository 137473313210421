import getRequest from '../../../../getRequest';

/**
 * Get site permissions.
 *
 * @param {number} siteId
 * @returns {[
 *   {
 *    SourceOptionId: number,
 *    SourceId: number,
 *    Name: string,
 *    Value: boolean,
 *    SiteId: number
 *  }
 * ]}
 */
export default function getPermissions(siteId) {
  const endpoint = `/oc-product/v1/sites/${siteId}/permissions`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Site Permissions')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
