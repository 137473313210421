import axios from 'axios';

import patchProductCategory from '../../../api/intergration/pim/products/dropshipment/patches/patchProductCategory';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function saveCategory(userEmail, nike_wizard, category) {
  return new Promise((resolve, reject) => {
    if (nike_wizard.editedValues.includes('categories')) {
      patchProductCategory(nike_wizard.productId, category.PimCategoryId)
        .then((res) => {
          if (res.data.Success) {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[INFO]: User ${userEmail} added CategoryId: ${category.PimCategoryId} to ProductId: ${nike_wizard.productId} - SiteCode: JD`,
            );
            resolve(res.data);
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to add CategoryId: ${category.PimCategoryId} to ProductId: ${nike_wizard.productId} - SiteCode: JD. Returned Error Message: "${res.data.ErrorMessage}".`,
            );
            reject(res.data.ErrorMessage);
          }
        })
        .catch((err) => {
          // If the error message contains the word Cancel then dont do error stuff.
          // Reason for this is because if a request is cancelled, this isn't an error to do with
          // saving data so the user doesn't need to be notified of this.
          if (axios.isCancel(err)) {
            resolve();
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to add CategoryId: ${category.PimCategoryId} to ProductId: ${nike_wizard.productId} - SiteCode: JD. Returned Error Message: "${err.message}".`,
            );
            reject(err);
          }
        });
    } else {
      resolve();
    }
  });
}

export default saveCategory;
