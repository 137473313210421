import DateTimePicker from '@mui/lab/DateTimePicker';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import Modal from '../../../../components/Modal';

function EditModal({
  open,
  onCloseModal,
  selectedRow,
  onChange,
}) {
  const [date, setDate] = useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setDate(newValue);
    onChange(date);
  };

  return (
    <Modal
      open={open}
      title="Edit Release Date"
      buttonLabel="Save"
      buttonOnClick={() => onCloseModal(false)}
      onClose={() => onCloseModal(false)}
      containerWidth="xs"
    >
      <Box className="flex flex-col items-center">
        <Typography id="dates-modal-source" variant="body1" gutterBottom>
          <b>Source</b>
          {' '}
          -
          {' '}
          {selectedRow?.source}
        </Typography>
        <Typography id="dates-modal-plu" variant="body1">
          <b>PLU</b>
          {' '}
          -
          {' '}
          {selectedRow?.plu}
        </Typography>
      </Box>
      <Box className="flex justify-center mt-4">
        <DateTimePicker
          label="Date&Time Picker"
          value={selectedRow?.releaseDate}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </Modal>
  );
}

export default EditModal;
