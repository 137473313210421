import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import getBrands from '../../../../api/oc_product/brands/gets/getBrands';
import postSplitStyles from '../../../../api/oc_product/styles/post/postSplitStyles';
import Modal from '../../../../components/Modal';
import { ProductContext } from '../../../product_info/products/product/ProductContext';
import { StylesContext } from '../../StylesContext';

function Split({
  open, onCloseModal, selectedRow, dataArray: initialDataArray, setSelected,
}) {
  const [styleName, setStyleName] = React.useState();
  const [brandName, setBrandId] = React.useState('');
  const [brandsList, setBrandsList] = React.useState([]);
  const [overrideChecked, setOverrideChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataArray, setDataArray] = useState(initialDataArray);
  const [disableButton, setDisableButton] = useState(true);
  const siteId = useSelector(
    (state) => state.user.loginResponse.selectedSite.SiteId,
  );

  const { setStylesReRender } = useContext(StylesContext);
  const { setProductStyleRerender } = useContext(ProductContext);
  const { id } = useParams();
  const customRenderOption = (props, option) => (
    <li {...props} key={option.brandId}>
      <div>{option.Name}</div>
    </li>
  );

  const fetchBrands = async () => {
    try {
      getBrands()
        .then((res) => {
          setBrandsList(res.data);
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const handleSaveSplit = async () => {
    try {
      setLoading(true);

      const productIds = selectedRow.map((row) => row.ProductId);

      const brand = await getBrands(siteId);

      const selectedBrand = brand?.data.find((brandSelected) => brandSelected.Name === brandName);

      const brandId = selectedBrand?.BrandId;
      let res = [];
      if (window.location.href.includes('product-info')) {
        res = await postSplitStyles([Number(id)], styleName, brandId, overrideChecked);
      } else {
        res = await postSplitStyles(productIds, styleName, brandId, overrideChecked);
      }

      selectedRow?.forEach((selectedProduct) => {
        dataArray?.forEach((item) => {
          item?.Products?.forEach((product, index) => {
            if (item.Products) {
              if (product.ProductId === selectedProduct.ProductId) {
                dataArray[0].Products.splice(index, 1);
                setDataArray([...dataArray]);
              }
            }
          });
        });
      });

      setTimeout(async () => {
        if (res) {
          if (window.location.href.includes('product-info')) {
            setProductStyleRerender(true);
          } else {
            setStylesReRender(true);
          }
        }
      }, 7000);

      toast.success('Successfully Split Style Product Type');
      toast.success('Updating data');
      onCloseModal(false);
    } catch (error) {
      console.error('Error during save:', error);
    } finally {
      setLoading(false);
      if (!window.location.href.includes('product-info')) {
        setSelected([]);
      }
    }
  };

  React.useEffect(() => {
    fetchBrands();
  }, []);

  React.useEffect(() => {
    setDisableButton(!brandName || !styleName);
  }, [brandName, styleName]);

  return (
    <Modal
      open={open}
      title="Split Styles"
      buttonLabel="Save"
      buttonOnClick={handleSaveSplit}
      onClose={() => onCloseModal(false)}
      buttonLoading={loading}
      disableButton={disableButton}
    >
      <Typography variant="h6" className="mb-4">This will move the following products</Typography>

      <div className="flex flex-wrap mb-[20px] justify-center">
        {selectedRow.map((row) => (
          <p className="pr-5 text-center">{row.ProductId}</p>
        ))}
      </div>
      <div>
        <div className="flex">
          <Typography variant="subtitle1" className="w-[150px] mb-4 mt-2">New Style:</Typography>
          <TextField
            label="Enter Style"
            variant="outlined"
            size="small"
            className="w-full"
            value={styleName}
            onChange={(e) => setStyleName(e.target.value)}
          />
        </div>
        <div className="flex justify-end">
          <FormControlLabel
            control={(
              <Checkbox
                checked={overrideChecked}
                onChange={(e) => setOverrideChecked(e.target.checked)}
                disabled={!styleName}
              />
            )}
            label={<p className="text-[14px]">Override</p>}
            className="mr-0"
          />
        </div>
        <div className="flex mt-5">
          <Typography variant="subtitle1" className="w-[150px] mb-4 mt-2">Brands:</Typography>
          <Autocomplete
            key={brandName}
            id="brands"
            size="small"
            value={{ Name: brandName }}
            onChange={(event, value) => {
              if (value && typeof value === 'object' && 'Name' in value) {
                const selectedBrandId = value.Name;
                setBrandId(selectedBrandId);
              } else {
                // Deselecting the brand, clear the value
                setBrandId('');
              }
            }}
            options={brandsList || []}
            getOptionLabel={(brand) => brand.Name || ''}
            className="w-full"
            renderInput={(params) => <TextField {...params} label="" key="brand-autocomplete" />}
            renderOption={customRenderOption}
          />
        </div>
        {brandName.length > 0 && selectedRow.some((row) => row.BrandName !== brandName) && (
          <p className="ml-[118px] text-sm text-error-700">Warning - Moving to a different brand</p>
        )}
      </div>
    </Modal>
  );
}

export default Split;
