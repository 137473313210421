import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import Image from '../../../components/image/Image';

function Row(props) {
  const {
    row, setSelected, selected,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [nestedRows, setNestedRows] = React.useState([]);
  const [pluColumns, setPluColumns] = useState([]);
  const [searchParams] = useSearchParams();
  const [selectedRowsMap, setSelectedRowsMap] = useState({});
  const [siteSources, setSiteSources] = useState([]);
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const siteCode = useSelector((state) => state.user.loginResponse.selectedSite.SiteCode);
  const searchIds = searchParams.get('ids')?.split(/\s+/).map(Number) || [];

  const showStylesData = async () => {
    try {
      const pluMapped = new Map();

      const mappedRows = row.Products.map((item, index) => {
        const newRow = {
          id: index + 1,
          ProductImage: item.ProductImage,
          ProductId: item.ProductId,
          Skus: item.ProductImage,
          Name: item.Name,
          BrandName: item.BrandName,
          IsActive: item.IsActive,
          IsApproved: item.IsApproved,
          IsOnSite: item.IsOnSite,
          IsReleaseDateProduct: item.IsReleaseDateProduct,
          Hidden: item.Hidden,
          IsRestricted: item.IsRestricted,
          DropShipements: item.DropShipements,
          Completion: item.Completion,
          VariantsCount: item.VariantsCount,
          // PluSource: item.PluSource?.map((pluItem) => `${pluItem.SourceShortDescription} - ${pluItem.RemoteSystemId}`).join(', ') || '',
        };
        const rowToMap = new Map(Object.entries(newRow));
        item.PluSource?.forEach((pluSource) => {
          rowToMap.set(
            pluSource.SourceShortDescription,
            pluSource.RemoteSystemId,
          );

          const foundSource = siteSources?.find(
            (source) => source.ShortDescription === pluSource.SourceShortDescription,
          );
          if (foundSource) {
            pluMapped?.set(foundSource?.ShortDescription, foundSource?.Name);
          }
        });

        const backToObject = Object.fromEntries(rowToMap);

        return backToObject;
      });

      setNestedRows(mappedRows);

      const pluColumnsArray = [];

      pluMapped.forEach((value, key) => {
        switch (value) {
          case 'Shogun':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 70,
              flex: 1,
            });
            break;
          case value.includes('Fluent'):
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 80,
              flex: 1,
            });
            break;
          case 'AnatwineUK':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 100,
              flex: 1,
            });
            break;
          case 'PFA':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 40,
              flex: 1,
            });
            break;
          case 'ChannelAdvisor':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 118,
              flex: 1,
            });
            break;
          default:
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 80,
              flex: 1,
            });
            break;
        }
      });

      setPluColumns(pluColumnsArray);
    } catch (error) {
      toast.error('Error fetching styles', error);
      console.error('Error fetching styles:', error);
    }
  };

  const dropshipValueOptions = nestedRows.flatMap((nestedRow) => (nestedRow.dropship || []).map((item) => item.SourceName))
    .filter((value, index, self) => self.indexOf(value) === index);

  const columns = [
    {
      field: 'ProductImage',
      headerName: 'Image',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: params.row.id === 1 ? '#f0f0f0' : 'transparent',
        }}
        >
          <Image
            src={params.value}
            height="45px"
            width="45px"
            alt="File not found"
          />
        </Box>
      ),
      valueGetter: (params) => params.value,
    },
    { field: 'ProductId', headerName: 'ID', width: 100 },
    ...pluColumns,
    {
      field: 'Skus',
      headerName: 'Skus',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          className="ml-2 h-[22px] text-[8px]"
          onClick={() => {
            const modifiedUrl = `product-info/products/${params.row.ProductId}?siteCode=${siteCode}&tab=1`;
            const urlWithoutStyles = modifiedUrl.replace(/^styles\//, '');
            window.open(urlWithoutStyles, '_blank');
          }}
        >
          {`${params.row.VariantsCount} View`}
        </Button>
      ),
    },
    { field: 'Name', headerName: 'Product Name', width: 200 },
    { field: 'BrandName', headerName: 'Brand', width: 100 },
    {
      field: 'IsActive',
      headerName: 'Active',
      type: 'boolean',
      flex: 1,
      minWidth: 60,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsApproved',
      headerName: 'Approved',
      type: 'boolean',
      flex: 1,
      minWidth: 82,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsOnSite',
      headerName: 'On-Site',
      type: 'boolean',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsReleaseDateProduct',
      headerName: 'Pre Release Product',
      type: 'boolean',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'Hidden',
      headerName: 'Hidden',
      type: 'boolean',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsRestricted',
      headerName: 'Restricted',
      type: 'boolean',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'DropShipements',
      headerName: 'Dropship',
      description: 'What dropshippable sources are enabled on.',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: dropshipValueOptions,
      renderCell: (params) => (
        <div className="flex flex-col items-center justify-center w-full gap-2">
          {params.value?.length > 0 ? params.value.map((obj) => (obj.IsDropShippable ? (
            <Chip
              icon={<CheckIcon />}
              color="success"
              label={obj.SourceName}
              size="small"
              variant="outlined"
              className="w-full"
            />
          ) : (
            <Chip
              icon={<CloseIcon />}
              color="error"
              label={obj.SourceName}
              size="small"
              variant="outlined"
              className="w-full"
            />
          ))) : 'N/A'}
        </div>
      ),
    },
    {
      field: 'Completion',
      headerName: 'Completion',
      flex: 1,
      minWidth: 95,
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return `${params.value} %`;
      },
      renderCell: (params) => (
        <div className="flex items-center justify-center w-full">
          <Chip
            label={`${params.value}%`}
            size="small"
            variant="outlined"
            color={
              // eslint-disable-next-line no-nested-ternary
              params.value < 50 ? 'error'
                : ((params.value >= 50 && params.value < 100)
                  ? 'warning'
                  : 'success')
            }
          />
        </div>
      ),
    },
  ];

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleRowSelectionChange = (rowGroupId, selectedRows) => {
    setSelectedRowsMap((prevSelectedRowsMap) => ({
      ...prevSelectedRowsMap,
      [rowGroupId]: selectedRows,
    }));
  };

  const onCheckboxHeaderClick = async () => {
    // Create a copy of the selected array
    const updatedSelected = [...selected];

    const exists = nestedRows.some((nestedRow) => selected.some((item) => item.ProductId === nestedRow.ProductId));

    if (exists) {
      // Iterate over nestedRows array
      nestedRows.forEach((nestedRow) => {
        // Check if nestedRow already exists in selected array
        const index = updatedSelected.findIndex((item) => item.ProductId === nestedRow.ProductId);
        if (index !== -1) {
          // If exists, remove it
          updatedSelected.splice(index, 1);
        }
      });
    } else {
      // Iterate over nestedRows array
      nestedRows.forEach((nestedRow) => {
        // Check if nestedRow already exists in selected array
        const index = updatedSelected.findIndex((item) => item.ProductId === nestedRow.ProductId);
        if (index !== -1) {
          // If exists, remove it
          updatedSelected.splice(index, 1);
        } else {
          // If not exists, add it
          updatedSelected.push(nestedRow);
        }
      });
    }

    // Update the selected state with the updated array
    if (!window.location.href.includes('product-info')) {
      setSelected(updatedSelected);
    }
  };

  const onCellClick = async (params) => {
    const rowData = params.row;
    const updatedSelected = [...selected];
    const index = selected.findIndex((item) => item.ProductId === rowData.ProductId);
    if (index !== -1) {
      // If exists, remove it
      updatedSelected.splice(index, 1);
    } else {
      // If not exists, add it
      updatedSelected.push(rowData);
    }

    // Update the selected state with the updated array
    if (!window.location.href.includes('product-info')) {
      setSelected(updatedSelected);
    }
  };

  React.useEffect(() => {
    showStylesData();
    setOpen(false);
    setSelectedRowsMap({});
  }, [row]);

  React.useEffect(() => {
    const fetchData = async () => {
      await showStylesData();
    };
    fetchData();
  }, [siteSources]);

  React.useEffect(() => {
    getSources(siteId)
      .then((res) => setSiteSources(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={row.StyleId}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleToggle}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.StyleId}</TableCell>
        <TableCell align="center">{row.StyleName ? row.StyleName : 'N/A'}</TableCell>
        <TableCell align="center">{row.Brand}</TableCell>
        <TableCell align="center">{row.Count}</TableCell>
      </TableRow>
      <TableRow key={`${row.StyleId}-nested`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <DataGrid
                rows={nestedRows}
                columns={columns}
                checkboxSelection
                rowSelectionModel={selectedRowsMap[row.StyleId] || []}
                onRowSelectionModelChange={(selectionModel) => handleRowSelectionChange(row.StyleId, selectionModel)}
                onColumnHeaderClick={onCheckboxHeaderClick}
                onCellClick={onCellClick}
                getRowHeight={() => 'auto'}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={[
                  {
                    groupId: 'PLUs',
                    children: siteSources?.map((obj) => ({ field: obj.ShortDescription })),
                  },
                ]}
                getRowClassName={(params) => (searchIds.includes(params.row.ProductId) ? 'highlight' : '')}
                sx={{
                  '& .MuiDataGrid-footerContainer': {
                    display: 'none !important',
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    minHeight: '100px',
                    maxHeight: '400px',
                  },
                  '& .highlight': {
                    backgroundColor: '#effff4',
                  },
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
Row.propTypes = {
  row: PropTypes.shape({
    StyleId: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    BrandName: PropTypes.string.isRequired,
    productCount: PropTypes.string.isRequired,
  }).isRequired,
};

export default Row;
