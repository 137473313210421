/* eslint-disable eqeqeq */
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TODO: Replace this Countries dummy data with the endpoint in intergration-pim
import Countries from '../../../../dummy_data/Countries';
import { decreaseActivePage, setProductDataActiveStep } from '../../../../redux/wizard/nike/nike_wizard';
import VatTable from './VatTable';

function Summary() {
  const matches = useMediaQuery('(min-width:600px)');

  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const {
    productId,
    productPlu,
    sites,
    productData,
  } = nike_wizard;

  const countrySelect = (code) => {
    const country = Countries.find((obj) => obj.code === code);
    return country?.name;
  };

  const editChip = (stepIndex) => {
    dispatchToRedux(decreaseActivePage());
    dispatchToRedux(setProductDataActiveStep(stepIndex - 1));
  };

  const primaryDescriptionRef = React.useRef(null);
  useEffect(() => {
    if (primaryDescriptionRef.current) {
      primaryDescriptionRef.current.innerHTML = productData.product_description?.description;
    }
  }, [primaryDescriptionRef.current]);

  const descriptionRefs = React.useRef([]);
  // eslint-disable-next-line max-len
  descriptionRefs.current = productData.product_description?.translations.map((_, i) => descriptionRefs.current[i] ?? React.createRef());
  useEffect(() => {
    if (descriptionRefs.current) {
      productData.product_description?.translations?.forEach((obj, index) => {
        if (index !== 0) {
          descriptionRefs.current[index].current.innerHTML = (obj.translation || 'N/A');
        }
      });
    }
  }, [descriptionRefs.current]);

  return (
    <Grid container px={matches ? 6 : 2} spacing={2}>
      <Grid item xs={12} className="sticky top-0 z-10 py-6 bg-white rounded-tl-xl">
        <Typography
          variant="h6"
          className="font-gothammedium"
        >
          Summary
        </Typography>
      </Grid>

      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} container spacing={1} className="pb-4 border-b">
          <Grid item xs={12}>
            <div className="flex items-baseline gap-5">
              <Typography variant="body1" className="font-gothambold">
                Product ID
              </Typography>
              <Typography variant="body1" className="ml-4">
                {productId}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="flex items-baseline gap-2">
              <Typography variant="body1" className="font-gothambold">
                Product PLU
              </Typography>
              <Typography variant="body1" className="ml-4">
                {productPlu}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} container className="pb-2 border-b">
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="body1" className="font-gothambold" gutterBottom>
                Sites
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              {sites.map(({ id, fascia, countries }) => (
                <Grid item xs={12} container px={2}>
                  <Grid item xs={matches ? 4 : 12}>
                    <Typography variant="body1">
                      Fascia:
                    </Typography>
                    <Typography variant="body1" gutterBottom key={id}>
                      {fascia}
                    </Typography>
                  </Grid>
                  <Grid item xs={matches ? 8 : 12}>
                    <Typography variant="body1">
                      Countries:
                    </Typography>
                    <div className="flex gap-4">
                      {countries.map((country, index) => (
                        <Typography variant="body1" gutterBottom key={country}>
                          {countrySelect(country)}
                          {index === (countries.length - 1) ? '.' : ','}
                        </Typography>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2} className="pr-4">
          <Grid item xs={12}>
            <Typography variant="body1" className="font-gothambold">
              Product Data
            </Typography>
          </Grid>

          <Grid item xs={12} container spacing={2} className="pb-4 border-b">
            <Grid item xs={12} container className="pb-2 border-b">
              <Grid item xs={12} container px={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="body2" className="font-gothammedium" gutterBottom>
                      Product Names
                    </Typography>
                    <Tooltip title="Edit Product Name" arrow placement="top">
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                          cursor: 'pointer',
                          opacity: '0.8',
                          zIndex: 1,
                        }}
                        onClick={() => editChip(2)}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1">
                      {productData.product_name?.name}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              {productData.product_name?.translations?.map((translation) => translation?.country_code !== 'GB' && (
                <Grid item xs={12} container key={Math.random()} px={2}>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      {countrySelect(translation?.country_code)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="flex items-center h-full">
                      <Typography variant="body1" gutterBottom>
                        {translation?.translation || 'N/A'}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} container className="pb-2 border-b">
              <Grid item xs={12} container px={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="body2" className="font-gothammedium" gutterBottom>
                      Brand Name
                    </Typography>
                    <Tooltip title="Edit Brand Name" arrow placement="top">
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                          cursor: 'pointer',
                          opacity: '0.8',
                        }}
                        onClick={() => editChip(1)}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Brand ID
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.brand?.brand_name.id}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.brand?.brand_name.name}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Sub-Brand
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.brand?.subbrand || 'N/A'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Shogun Supplier
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.brand?.shogun_supplier_number || 'N/A'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container className="pb-2 border-b">
              <Grid item xs={12} container px={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="body2" className="font-gothammedium" gutterBottom>
                      Colours
                    </Typography>
                    <Tooltip title="Edit Colour Information" arrow placement="top">
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                          marginLeft: '4px',
                          cursor: 'pointer',
                          opacity: '0.8',
                        }}
                        onClick={() => editChip(4)}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Primary Colour
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.colours?.primary_colour}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Secondary Colour
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.colours?.secondary_colour || 'N/A'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Shogun Colour Code
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.colours?.shogun_colour_code || 'N/A'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container className="pb-2 border-b">
              <Grid item xs={12} container px={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="body2" className="font-gothammedium" gutterBottom>
                      Fabric
                    </Typography>
                    <Tooltip title="Edit Fabric Name" arrow placement="top">
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                          marginLeft: '4px',
                          cursor: 'pointer',
                          opacity: '0.8',
                        }}
                        onClick={() => editChip(5)}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Fabric Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {productData.fabric?.fabric_type}
                  </Typography>
                </Grid>
              </Grid>
              {productData.fabric?.translations?.map((translation) => (
                translation?.country_code !== 'GB' && translation?.translation !== '' && (
                <Grid item xs={12} container key={Math.random()} px={2}>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      {countrySelect(translation?.country_code)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      {translation?.translation || 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
                )
              ))}
            </Grid>

            <Grid item xs={12} container className="pb-2 border-b">
              <Grid item xs={12} container px={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="body2" className="font-gothammedium" gutterBottom>
                      Categories
                    </Typography>
                    <Tooltip title="Edit Category Information" arrow placement="top">
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                          marginLeft: '4px',
                          cursor: 'pointer',
                          opacity: '0.8',
                        }}
                        onClick={() => editChip(6)}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={matches ? 4 : 12}>
                  <Typography variant="body1" gutterBottom>
                    PIM Category
                  </Typography>
                </Grid>
                <Grid item xs={matches ? 8 : 12} container gap={1}>
                  {productData.categories?.pim_category.map((category, index) => (
                    <Typography variant="body1" gutterBottom>
                      {category.CategoryName}
                      {index !== productData.categories.pim_category.length - 1 ? ', ' : ''}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={matches ? 4 : 7}>
                  <Typography variant="body1" gutterBottom>
                    Shogun Category
                  </Typography>
                </Grid>
                <Grid item xs={matches ? 4 : 5}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.categories?.shogun_category}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2} spacing={0}>
                <Grid item xs={matches ? 4 : 7}>
                  <Typography variant="body1" gutterBottom>
                    Shogun Subcategory
                  </Typography>
                </Grid>
                <Grid item xs={matches ? 4 : 5}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.categories?.shogun_subcategory}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={matches ? 4 : 7}>
                  <Typography variant="body1" gutterBottom>
                    Shogun Buyer Number
                  </Typography>
                </Grid>
                <Grid item xs={matches ? 4 : 5}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.categories?.shogun_buyer_number}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={matches ? 4 : 7}>
                  <Typography variant="body1" gutterBottom>
                    Shogun Division Number
                  </Typography>
                </Grid>
                <Grid item xs={matches ? 4 : 5}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.categories?.shogun_division_number}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container className="pb-2 border-b">
              <Grid item xs={12} container px={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="body2" className="font-gothammedium" gutterBottom>
                      Other Attributes
                    </Typography>
                    <Tooltip title="Edit Other Attributes" arrow placement="top">
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                          marginLeft: '4px',
                          cursor: 'pointer',
                          opacity: '0.8',
                        }}
                        onClick={() => editChip(8)}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Season Year
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.other_attributes?.season_year}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Season
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.other_attributes?.season}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Age Restricted
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.other_attributes?.age_restricted === 'no' ? 'No' : 'Yes'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Licensed
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.other_attributes?.licensed == '1' ? 'Yes' : 'No'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} container px={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Connected Customers
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div className="flex items-center h-full">
                    <Typography variant="body1" gutterBottom>
                      {productData.other_attributes?.connected_customers ? 'Yes' : 'No'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              {productData.other_attributes?.connected_customers === 1 && (
                <>
                  <Grid item xs={12} container px={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        From
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        {new Date(productData.other_attributes?.from).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container px={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        To
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        {new Date(productData.other_attributes?.to).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={12} container px={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="body2" className="font-gothammedium" gutterBottom>
                      Product Description
                    </Typography>
                    <Tooltip title="Edit Product Description" arrow placement="top">
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                          marginLeft: '4px',
                          cursor: 'pointer',
                          opacity: '0.8',
                        }}
                        onClick={() => editChip(3)}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className="list-disc"
                    ref={primaryDescriptionRef}
                  />
                  {/* <Typography variant="body1" gutterBottom>
                    {productData.product_description?.description}
                  </Typography> */}
                </Grid>
              </Grid>
              {productData.product_description?.translations?.map((translation, index) => translation?.country_code !== 'GB' && translation?.country_code !== '' && (
                <Grid
                  item
                  xs={12}
                  container
                  key={Math.random()}
                  px={2}
                  py={2}
                >
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      {countrySelect(translation?.country_code)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className="list-disc"
                      ref={descriptionRefs.current[index]}
                    />
                    {/* <Typography variant="body1" gutterBottom>
                      {translation?.translation || 'N/A'}
                    </Typography> */}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} container marginLeft={2}>
            <Stack direction="row" alignItems="baseline" spacing={1}>
              <Typography variant="body2" className="font-gothammedium" gutterBottom>
                VAT Information
              </Typography>
              <Tooltip title="Edit VAT Table" arrow placement="top">
                <EditIcon
                  sx={{
                    fontSize: '16px',
                    marginLeft: '4px',
                    cursor: 'pointer',
                    opacity: '0.8',
                  }}
                  onClick={() => editChip(7)}
                />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12} className="p-4 pt-0">
            <VatTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Summary;
