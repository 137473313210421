import { createTheme } from '@mui/material/styles';

import componentThemes from './component_themes';

const theme = createTheme({
  components: componentThemes,
  palette: {
    background: {
      default: '#fafafa',
    },
    boxShadow: '0 12px 24px 0 rgb(0, 0, 0, .08)',
    mode: 'light',
    primary: {
      main: '#217ABA',
      50: '#f2f8fd',
      100: '#E9F2F8',
      200: '#D3E4F1',
      300: '#A6CAE3',
      400: '#7AAFD6',
      500: '#4D95C8',
      600: '#217ABA',
      700: '#1A6295',
      800: '#144970',
      900: '#0D314A',
      1000: '#071825',
    },
    secondary: {
      main: '#1AA0B3',
      50: '#eefcfd',
      100: '#E8F6F7',
      200: '#D1ECF0',
      300: '#A3D9E1',
      400: '#76C6D1',
      500: '#48B3C2',
      600: '#1AA0B3',
      700: '#15808F',
      800: '#10606B',
      900: '#0A4048',
      1000: '#052024',
    },
    tertiary: {
      main: '#384451',
      50: '#f3f7f8',
      100: '#EBECEE',
      200: '#D7DADC',
      300: '#AFB4B9',
      400: '#888F97',
      500: '#606974',
      600: '#384451',
      700: '#2D3641',
      800: '#222931',
      900: '#161B20',
      1000: '#0B0E10',
    },
    info: {
      main: '#217ABA',
      50: '#f2f8fd',
      100: '#E9F2F8',
      200: '#D3E4F1',
      300: '#A6CAE3',
      400: '#7AAFD6',
      500: '#4D95C8',
      600: '#217ABA',
      700: '#1A6295',
      800: '#144970',
      900: '#0D314A',
      1000: '#071825',
    },
    success: {
      contrastText: '#fff',
      main: '#4CAF50',
      50: '#f3faf3',
      100: '#EDF7EE',
      200: '#DBEFDC',
      300: '#B7DFB9',
      400: '#94CF96',
      500: '#70BF73',
      600: '#4CAF50',
      700: '#3D8C40',
      800: '#2E6930',
      900: '#1E4620',
      1000: '#0F2310',
    },
    warning: {
      contrastText: '#fff',
      main: '#E9AE14',
      50: '#fffbeb',
      100: '#FDF7E8',
      200: '#FBEFD0',
      300: '#F7DFA1',
      400: '#F2CE72',
      500: '#EEBE43',
      600: '#E9AE14',
      700: '#BB8B10',
      800: '#8C680C',
      900: '#5E4608',
      1000: '#2F2304',
    },
    error: {
      contrastText: '#fff',
      main: '#CC2513',
      50: '#fff2f1',
      100: '#FAE9E7',
      200: '#F5D3D0',
      300: '#EBA8A1',
      400: '#E07C71',
      500: '#D65142',
      600: '#CC2513',
      700: '#A31E0F',
      800: '#7A160B',
      900: '#520F08',
      1000: '#290704',
    },
    neutral: { // tailwind neutral colors
      main: '#B2BAC2',
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#e5e5e5',
      300: '#d4d4d4',
      400: '#a3a3a3',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262626',
      900: '#171717',
      1000: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: 'gothambook',
    fontSize: 14,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
