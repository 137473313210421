import patchRequest from '../../../../patchRequest';

/**
 * Bulk new-in-dates products.
 *
 * @param {number[]} productIds Id of the product
 * @param {number} siteId Site Id of the product
 * @param {Datetime} newInDate
 */
export default function patchProductNewInDate(productIds, siteId, newInDate) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/product-new-in-date';

  const body = {
    productIds,
    siteId,
    newInDate,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Patch Product NewInDates', 'STARTED: Product Status NewInDates')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
