import axios from 'axios';

import patchCustomAttribute from '../../../api/intergration/pim/products/dropshipment/patches/patchCustomAttribute';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function subbrand(userEmail, nike_wizard) {
  return new Promise((resolve, reject) => {
    if (nike_wizard.productData.brand.subbrand === '') {
      resolve({ Success: true, ErrorMessage: null });
    } else {
      patchCustomAttribute(
        nike_wizard.productId,
        'JD',
        'subbrandcode',
        nike_wizard.productData.brand.subbrand,
        'GB',
      )
        .then((res) => {
          if (res.data.Success) {
            // if (nike_wizard.editedValues.includes('subbrand')) {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[INFO]: User ${userEmail} updated SubBrandCode: ${nike_wizard.productData.brand.subbrand} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB`,
            );
            // }
            resolve(res.data);
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to update SubBrandCode: ${nike_wizard.productData.brand.subbrand} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB. Return Error Message: ${res.data.ErrorMessage}`,
            );
            reject(res.data.ErrorMessage);
          }
        })
        .catch((err) => {
          // If the error message contains the word Cancel then dont do error stuff.
          // Reason for this is because if a request is cancelled, this isn't an error to do with
          // saving data so the user doesn't need to be notified of this.
          if (axios.isCancel(err)) {
            resolve();
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to update SubBrandCode: ${nike_wizard.productData.brand.subbrand} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB. Return Error Message: ${err.message}`,
            );
            reject(err);
          }
        });
    }
  });
}

export default subbrand;
