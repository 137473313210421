import getRequest from '../../../getRequest';

export default async function getSizeMapCountryRegion() {
  const endpoint = '/oc-product/v1/size-mapping/country-region';
  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'GET')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
