import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import getSiteAttributes from '../../../api/oc_product/bulk/sites/site_attributes/gets/getSiteAttributes';
import deleteProductType from '../../../api/oc_product/productDataManagement/productTypes/delete/deleteProductType';
import getProductTypes from '../../../api/oc_product/productDataManagement/productTypes/get/getProductTypes';
import postProductType from '../../../api/oc_product/productDataManagement/productTypes/post/postProductType';
import Modal from '../../../components/Modal';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import EditModal from './components/modals/EditModal';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ATTRIBUTE':
      return {
        ...state,
        attribute: action.payload,
      };
    default:
      return state;
  }
};

function ProductTypes() {
  const siteId = useSelector(
    (state) => state.user.loginResponse.selectedSite.SiteId,
  );
  const siteCode = useSelector((state) => state.user.loginResponse.selectedSite.SiteCode);

  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);

  const initialState = {
    attribute: null,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchValue, setSearchValue] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [deleteRow, setDeleteRow] = React.useState(null);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [name, setName] = React.useState('');
  const [newDepartmentCode, setNewDepartmentCode] = React.useState('');
  const [newIsDefault, setNewIsDefault] = React.useState(false);
  const [siteAttributeOptions, setSiteAttributeOptions] = React.useState([]);
  const [selectedAttribute, setSelectedAttribute] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const pageSize = searchParams.get('pageSize');

  const fetchData = async () => {
    try {
      const [response1, response2, response3] = await Promise.all([
        getSiteAttributes(siteId, 2, languageId),
        getSiteAttributes(siteId, 3, languageId),
        getSiteAttributes(siteId, 1, languageId),
      ]);
      const productTypeAttributes = [
        ...response1.data,
        ...response2.data,
        ...response3.data,
      ];

      const attributeId = state.attribute || 0;
      const productTypesData = await getProductTypes(siteId);
      const mappedRows = productTypesData.data.map((item, index) => ({
        id: index + 1,
        name: item.ProductTypeName,
        departmentCode: item.DepartmentCode,
        default: item.IsDefault,
        dateCreated: item.Created,
        productTypeId: item.ProductTypeId,
        attributeName: productTypeAttributes.find((attr) => attr.ProductAttributeDefId === attributeId)?.AttributeName || '',
      }));
      setRows(mappedRows);
      setOriginalRows(mappedRows);
    } catch (error) {
      console.error('Error fetching product types data:', error);
    }
  };

  const fetchSiteAttributeOptions = async () => {
    try {
      const [response1, response2, response3] = await Promise.all([
        getSiteAttributes(siteId, 2, languageId),
        getSiteAttributes(siteId, 3, languageId),
        getSiteAttributes(siteId, 1, languageId),
      ]);
      const attributeOptions = [
        ...response1.data,
        ...response2.data,
        ...response3.data,
      ];

      setSiteAttributeOptions(attributeOptions);
    } catch (error) {
      console.error('Error fetching site attribute options:', error);
    }
  };

  const handleDeleteProductType = async () => {
    try {
      await deleteProductType(siteId, deleteRow?.productTypeId);

      setRows((prevRows) => prevRows.filter((row) => row.id !== deleteRow?.id));
      setOriginalRows((prevOriginalRows) => prevOriginalRows.filter((row) => row.id !== deleteRow?.id));

      toast.success(`${deleteRow?.departmentCode === null ? 'N/A' : deleteRow?.departmentCode}
      of Product Type ${deleteRow?.name} successfully deleted`);
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete brand');
    } finally {
      setOpenModal(false);
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      headerAlign: 'center',
    },
    {
      field: 'departmentCode',
      headerName: 'Department Code',
      width: 100,
      headerAlign: 'center',
    },
    {
      field: 'isDefault',
      headerName: 'Default',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => (
        params.row.default === true ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      width: 300,
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Search',
      width: 150,
      headerAlign: 'center',
      renderCell: (row) => (
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          <SearchIcon
            onClick={() => {
              const { productTypeId } = row.row;
              const url = `/product-info/products?pageSize=15&page=1&productTypes=${productTypeId}`;
              window.open(url, '_blank');
            }}
          />
        </Stack>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      headerAlign: 'center',
      renderCell: (params) => (
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          <DeleteForeverIcon
            sx={{
              cursor: 'pointer',
              opacity: '0.5',
              '&:hover': {
                color: 'red',
              },
            }}
            onClick={(event) => {
              event.stopPropagation();
              setOpenModal(true);
              setDeleteRow(params.row);
            }}
          />
        </Stack>
      ),
    },
  ];

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setName('');
    setNewDepartmentCode('');
    setNewIsDefault(false);
    dispatch({ type: 'ATTRIBUTE', payload: initialState.attribute });
    setSelectedAttribute([]);
  };

  const handleCreateProductType = async () => {
    try {
      setLoading(true);
      // await getSiteAttributes(siteId, 1, languageId);
      const attributeId = state.attribute || 0;
      const productTypeData = await postProductType(
        siteId,
        name,
        newDepartmentCode,
        attributeId,
        newIsDefault,
      );

      const relevantData = productTypeData.data.Data || [];
      const mappedRows = relevantData.map((item, index) => ({
        id: index + 1,
        name: item.ProductTypeName,
        departmentCode: item.DepartmentCode,
        default: item.IsDefault,
        dateCreated: item.Created,
        productTypeId: item.ProductTypeId,
      }));
      setRows(mappedRows);
      await fetchData();
      handlePopoverClose();
      toast.success('Succesfully Created Product Type');
    } catch (error) {
      console.error('Error saving product type', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleAttributeChange = (event, values) => {
    const selectedIds = values.map((v) => v.ProductAttributeDefId);
    setSelectedAttribute(selectedIds);
    dispatch({ type: 'ATTRIBUTE', payload: selectedIds });
  };

  const handleUpdateSelectedRowData = async (updatedData) => {
    const rowIndex = rows.findIndex((row) => row.productTypeId === updatedData.productTypeId);

    if (rowIndex !== -1) {
      // Create a new array with the updated row
      const updatedRows = [...rows];
      updatedRows[rowIndex] = updatedData;

      setRows(updatedRows);
      setSelectedRowData(updatedData);
    }
  };

  const open = Boolean(anchorEl);

  React.useEffect(() => {
    const fetchDataAndAttributes = async () => {
      try {
        if (searchValue.length > 0) {
          setSearchValue('');
        }
        await fetchData(siteId);
        dispatch({ type: 'RESET', payload: initialState });
        fetchSiteAttributeOptions();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataAndAttributes();
  }, [siteId]);

  React.useEffect(() => {
    if (searchValue !== '') {
      const filteredRows = originalRows.filter((row) => (row.name.toLowerCase().includes(searchValue.toLowerCase())));
      setRows(filteredRows);
    } else {
      setRows(originalRows);
    }
  }, [searchValue, originalRows]);

  React.useEffect(() => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
     * For some reason we have to do this...
     * If you do the, if not then delete, statement below within the for above
     * or do params.forEach
     * The loop with miss every even indexed key...
     */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  }, []);

  return (
    <Container>
      {/* <CreateModal open={openCreateModal} onCloseModal={() => setOpenCreateModal(false)} /> */}
      <Grid container spacing={2} marginTop>
        <Grid item xs={12}>
          <Typography variant="h5" className="pb-2 text-secondary-600">Product Types</Typography>
        </Grid>

        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="Search by Product Type Name"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              variant="outlined"
              size="small"
              className="mr-6 w-[300px]"
            />
          </Stack>
          <Button
            variant="contained"
            onClick={(event) => handlePopoverOpen(event)}
          >
            Create Product Type
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                width: '400px',
              },
            }}
          >
            <div className="flex flex-col p-8">
              <Typography variant="h6" className="mb-3">
                Create New Product Type -
                {siteCode}
              </Typography>
              <div className="flex flex-row items-center mb-3">
                <TextField
                  label="Name"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full"
                />
              </div>
              <div className="flex flex-row items-center mb-3">
                <TextField
                  label="Department Code"
                  variant="outlined"
                  size="small"
                  value={newDepartmentCode}
                  onChange={(e) => setNewDepartmentCode(e.target.value)}
                  className="w-full"
                />
              </div>
              <div className="flex flex-row items-center mb-3">
                <VirtualizedAutocomplete
                  multiple
                  key="Attribute"
                  options={siteAttributeOptions || []}
                  textFieldLabel="Attribute"
                  textFieldVariant="outlined"
                  size="small"
                  getOptionLabel={(option) => (option ? option.AttributeName : '')}
                  onChange={handleAttributeChange}
                  optionKey="AttributeName"
                  value={siteAttributeOptions.filter((obj) => selectedAttribute.includes(obj.ProductAttributeDefId)) || []}
                  className="w-full"

                />
              </div>
              <div className="flex flex-row items-center mt-3 mb-3">
                <p className="text-[14px]">Is Default</p>
                <Checkbox
                  onClick={(event) => setNewIsDefault(event.target.checked)}
                  checked={newIsDefault}
                  className="mr-0"
                />
              </div>
              <div className="flex justify-end">
                {loading ? <CircularProgress color="primary" />
                  : (
                    <Button
                      variant="contained"
                      disabled={!name}
                      onClick={handleCreateProductType}
                    >
                      Save
                    </Button>
                  )}
                <Button variant="text" className="ml-2 text-primary-600" onClick={handlePopoverClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </Popover>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[15, 30, 50, 100]}
            onCellClick={(params, event) => {
              event.stopPropagation();
              setOpenEditModal(true);
              setSelectedRowData({
                ...params.row,
              });
            }}
            sx={{
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-cell': {
                justifyContent: 'center',
              },
            }}
            className="min-h-[40vh]"
          />
        </Grid>
      </Grid>
      <EditModal
        open={openEditModal}
        onClose={handleEditModalClose}
        selectedRowData={selectedRowData}
        selectedAttribute={selectedAttribute}
        updatedSelectedRowData={handleUpdateSelectedRowData}
      />
      <Modal
        title="Delete Product Type"
        open={openModal}
        onClose={handleModalClose}
        buttonLabel="Confirm"
        buttonOnClick={handleDeleteProductType}
      >
        <Typography className="text-center">
          {`Are you sure you want to delete ${deleteRow?.departmentCode === null ? 'N/A' : deleteRow?.departmentCode}
             of ${deleteRow?.name}`}
        </Typography>
      </Modal>
    </Container>
  );
}

export default ProductTypes;
