import postRequest from '../../../../postRequest';

/**
 * Post request to create a new category.
 *
 * @param {number} siteId 1
 * @param {number} parentCategoryId 1
 * @param {number} categoryName Footwear
 */
export default function postCategory(
  siteId,
  parentCategoryId,
  categoryName,
) {
  const endpoint = `/oc-data-management/v1/category/${siteId}/`;

  const body = {
    parentCategoryId,
    categoryName,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Create new category')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
