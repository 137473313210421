import getRequest from '../../../../getRequest';

/**
 * Product information. Designed for the PDP.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} languageId Language Id of the product
 */
export default function getStyle(productId, siteId, languageId) {
  const query = languageId ? `?LanguageId=${languageId}` : '';
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/style/${query}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Style')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
