import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import postPrimaryCategoryPluId from '../../../api/oc_product/bulk/bulk_process/primary_category/plu_id/posts/postPrimaryCategoryPluId';
import postPrimaryCategoryProductId from '../../../api/oc_product/bulk/bulk_process/primary_category/product_id/posts/postPrimaryCategoryProductId';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import PluCsv from '../../../assets/bulk_templates/Bulk_Plu_Template.csv';
import ProductIdCsv from '../../../assets/bulk_templates/Bulk_Product_Id_Template.csv';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import doesObjectContainEmptyValues from '../../../utils/doesObjectContainEmptyValues';

const Input = styled('input')({
  display: 'none',
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SOURCE':
      return {
        ...state,
        sourceId: action.payload,
      };
    case 'FILE':
      return {
        ...state,
        file: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};

const MAX_FILE_SIZE_MB = 5;

function PrimaryCategory({ refreshReports }) {
  const submitBtn = React.useRef(null);
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const fileInputRef = React.useRef(null);

  const initialState = {
    sourceId: null,
    file: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const [idType, setIdType] = React.useState('plu');

  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [buttonWidth, setButtonWidth] = React.useState(0);
  const [buttonHeight, setButtonHeight] = React.useState(0);
  const [sourceOptions, setSourceOptions] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.get('omsId');

  React.useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input value
      dispatch({ type: 'FILE', payload: '' }); // Reset file state
      dispatch({ type: 'RESET', payload: initialState });
    }
    setIdType('plu');

    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect(() => {
    if (submitBtn.current) {
      const sbmBtn = submitBtn.current.getBoundingClientRect();
      setButtonWidth(sbmBtn.width);
      setButtonHeight(sbmBtn.height);
    }
  }, [submitBtn.current]);

  React.useEffect(() => {
    if (idType !== 'plu') {
      delete state.sourceId;
    } else if (!state.sourceId) {
      state.sourceId = '';
    }
    const isEmpty = doesObjectContainEmptyValues(state);
    setSubmitDisabled(isEmpty);
  }, [state, idType]);

  React.useEffect(() => {
    if (state.file !== '' && state.file.type !== 'text/csv') {
      toast.error('Please upload CSV file');
    }

    if (state.file.type !== 'text/csv') {
      const isEmpty = doesObjectContainEmptyValues(state);
      setSubmitDisabled(isEmpty);
      state.file = '';
    }

    if (state.file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.error('File size is too big. Please upload a file smaller than 5MB.');
      const isEmpty = doesObjectContainEmptyValues(state);
      setSubmitDisabled(isEmpty);
      state.file = '';
    }
  }, [state.file]);

  React.useEffect(() => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
         * For some reason we have to do this...
         * If you do the, if not then delete, statement below within the for above
         * or do params.forEach
         * The loop with miss every even indexed key...
         */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  }, []);

  const handleBulk = (e) => {
    e.preventDefault();
    if (idType === 'plu') {
      postPrimaryCategoryPluId({
        SiteId: siteId,
        SourceId: state.sourceId,
        File: state.file,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    }

    if (idType === 'productId') {
      postPrimaryCategoryProductId({
        SiteId: siteId,
        File: state.file,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="flex items-start justify-start w-full gap-10 mt-4 lg:flex-col xl:flex-row">
      <div
        className="mb-8 bg-white border rounded-lg right-8"
      >
        <div className="flex flex-col h-full p-4 w-96">
          <span className="text-lg">Download Templates</span>
          <a
            href={PluCsv}
            download="Bulk_Plu_Template.csv"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-700"
          >
            Plu Template
          </a>
          <a
            href={ProductIdCsv}
            download="Bulk_Product_Id_Template.csv"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-700"
          >
            Prod Id Template
          </a>
        </div>
      </div>
      <Container maxWidth="md">
        <section className="w-full">
          <form className="flex flex-col w-9/12 gap-8 justify-evenly" onSubmit={handleBulk}>

            <div className="flex flex-col gap-4 step2">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    1
                  </Typography>
                </div>
                <Typography variant="body2">PLU or Product ID</Typography>
              </div>

              <Box className="flex justify-start">
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="type"
                    defaultValue="plu"
                    name="radio-buttons-group"
                    onChange={(e) => setIdType(e.target.value)}
                    value={idType}
                    sx={{ mb: 2 }}
                  >
                    <FormControlLabel value="plu" control={<Radio />} label="PLU" />
                    <FormControlLabel
                      value="productId"
                      control={<Radio />}
                      label="Product ID"
                    />
                  </RadioGroup>
                  {idType === 'plu' ? (
                    <VirtualizedAutocomplete
                      options={sourceOptions}
                      textFieldLabel="Sources"
                      textFieldVariant="outlined"
                      value={sourceOptions.find((obj) => obj?.SourceId === state.sourceId) || null}
                      getOptionLabel={(option) => option.Name}
                      onSelectedValue={(value) => dispatch({ type: 'SOURCE', payload: value?.SourceId })}
                      optionKey="Name"
                    />
                  ) : (
                    <div className="hidden" />
                  )}
                </FormControl>
              </Box>
            </div>

            <div key="step3">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    2
                  </Typography>
                </div>
                <Typography variant="body2">
                  Choose File
                </Typography>
              </div>

              <Box sx={{ paddingTop: '1rem' }}>
                <label htmlFor="contained-button-file">
                  <Input
                    accept=".csv"
                    id="contained-button-file"
                    multiple
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        dispatch({ type: 'FILE', payload: e.target.files[0] });
                      }
                    }}
                  />
                  <Button
                    disabled={idType === 'plu' && !state.sourceId}
                    variant="outlined"
                    component="span"
                    color="info"
                    className="mr-4"
                  >
                    Choose File
                  </Button>
                </label>
                <TextField
                  disabled
                  value={state.file?.name || ''}
                  variant="standard"
                  placeholder="No File Chosen"
                  className="mr-4"
                />
                {state.file && (
                  <CancelIcon
                    className="cursor-pointer text-error-400"
                    onClick={() => {
                      dispatch({ type: 'FILE', payload: '' });
                      fileInputRef.current.value = '';
                    }}
                  />
                )}
              </Box>
            </div>

            <div className="w-full">
              <div className="relative float-right w-max">
                <Button variant="contained" ref={submitBtn} className="z-10" disabled={submitDisabled} type="submit">Upload</Button>
                {submitDisabled ? null : (
                  <div
                    className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 bg-primary-400 animate-ping"
                    style={{ width: `${buttonWidth / 1.5}px`, height: `${buttonHeight / 1.25}px` }}
                  />
                )}
              </div>
            </div>

          </form>
        </section>

      </Container>
    </div>
  );
}

export default PrimaryCategory;
