import React from 'react';

import { setSessionStorage } from '../../helper/SessionStorage';

function BackgroundColorSelect() {
  return (
    <div
      className={`
        absolute top-[4px] 
        z-10
        flex flex-col justify-evenly items-center gap-4
        p-2
        bg-white
        rounded-full
        transition-all duration-500 ease-in-out
        h-0 group-hover:h-[150px]
        opacity-0 group-hover:opacity-100
      `}
    >
      <div className="w-10 h-10 bg-white rounded-full" />
      <button
        type="button"
        className="w-6 h-6 bg-red-900 border rounded-full opacity-0 group-hover:opacity-100 border-red-950"
        onClick={() => {
          setSessionStorage('custom-background-sidebar', 'linear-gradient(to top right, #7F1D1D, #651717)');
          setSessionStorage('bg-user', '#d95959');
          window.location.reload();
        }}
      >
        D
      </button>
      <button
        type="button"
        className="w-6 h-6 bg-yellow-600 border border-yellow-600 rounded-full opacity-0 group-hover:opacity-100"
        onClick={() => {
          setSessionStorage('custom-background-sidebar', '#b58010');
          setSessionStorage('bg-user', '#c08812');
          window.location.reload();
        }}
      >
        S
      </button>
      <button
        type="button"
        className="w-6 h-6 border rounded-full opacity-0 group-hover:opacity-100 bg-tertiary-600 border-tertiary-800"
        onClick={() => {
          setSessionStorage('custom-background-sidebar', 'linear-gradient(to top right, #384451, #2C3640)');
          setSessionStorage('bg-user', '#384451');

          window.location.reload();
        }}
      >
        P
      </button>
    </div>
  );
}
export default BackgroundColorSelect;
