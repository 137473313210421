// import Cookies from 'js-cookie';
import React from 'react';
import { Navigate } from 'react-router-dom';

import Login from '../pages/login/Login';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router';
// import { clearPending } from '../axios/pendingRequests';
import LoginLocal from '../pages/login/LoginLocal';
import Maintenance from '../pages/maintenance/Maintenance';

/**
 *
 * Handles the route to the login page.
 * If you try and move got to the login page whilst already being logged in
 * Then you'll be redirected to the dashboard.
 *
 * @returns {object}
 * @example
 * {
 *   path: '/login',
 *   element: <Login />,
 * }
 */
function LoginRoute() {
  const maintenanceMode = 'no';
  // const handleMount = () => {
  //   const token = Cookies.get('AccessToken');
  //   const user = useSelector((state) => state.user);

  //   const loggedIn = token?.length > 0 && user?.userProfile?.typ === 'PrivateApi';

  //   clearPending();

  //   return loggedIn ? <Navigate to="/" /> : <Login />;
  // };
  const currentURL = window.location.href.replace(/\/$/, '');
  const { port } = window.location;
  const isLocalURL = currentURL === `http://localhost:${port}/login-local`
  || currentURL === 'https://local.prism.jdplc.com.127.0.0.1.nip.io/login-local';

  return {
    path: '/',
    children: [
      {
        path: 'login',
        element: maintenanceMode.toLowerCase() === 'yes' ? <Maintenance /> : <Login />,
      },
      {
        path: 'login-local',
        element: isLocalURL ? <LoginLocal /> : <Navigate to="/login" />,
      },
    ],
  };
}

export default LoginRoute;
