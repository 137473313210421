import { Button, Popover, TextField } from '@mui/material';
import React from 'react';

function NewMapping({
  open,
  anchorEl,
  onClose,
  language,
  englishColour,
  translatedColour,
  swatchColour1,
  setEnglishColour,
  setTranslatedColour,
  setSwatchColour1,
  addColourDetails,
}) {
  const isEnglish = language === 1;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      PaperProps={{ style: { width: '400px' } }}
    >
      <div className="flex flex-col p-8">
        <div className="mb-3">
          <div className="flex flex-col">
            <TextField
              sx={{ m: 1, minWidth: 180 }}
              label="Colour"
              variant="standard"
              size="small"
              value={englishColour}
              onChange={(e) => setEnglishColour(e.target.value)}
              className="mr-6 w-[300px]"
            />
            {isEnglish ? (
              <>
                <TextField
                  sx={{ m: 1, minWidth: 180 }}
                  label="Swatch Colour 1"
                  variant="standard"
                  size="small"
                  value={swatchColour1}
                  onChange={(e) => setSwatchColour1(e.target.value)}
                  className="mr-6 w-[300px]"
                />
                <TextField
                  sx={{ m: 1, minWidth: 180 }}
                  label="Swatch Colour 2"
                  variant="standard"
                  size="small"
                  className="mr-6 w-[300px]"
                />
              </>
            ) : (
              <TextField
                sx={{ m: 1, minWidth: 180 }}
                label="Translated Swatch Colour"
                variant="standard"
                size="small"
                value={translatedColour}
                onChange={(e) => setTranslatedColour(e.target.value)}
                className="mr-6 w-[300px]"
              />
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                addColourDetails();
                onClose();
              }}
              disabled={isEnglish ? !englishColour || !swatchColour1 : !englishColour || !translatedColour}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
}

export default NewMapping;
