import getRequest from '../../../../getRequest';
/**
 * Gets all colours for the english language.
 *
 * @param {number} languageId
 * @param {string} colourName
 * @param {string} colourType
 */
export default async function getNonEnglishSwatchColour(languageId, colourName, colourType) {
  let endpoint = `/oc-data-management/v1/colourMatching/${languageId}/non-englishSwatchColour`;
  endpoint += `?colourName=${colourName}&colourType=${colourType}`;
  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'GetEnglishSwatchColour')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
