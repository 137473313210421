import postRequest from '../../../../../../postRequest';

/**
 * Get reports of the current bulk operations.
 *
 * @param {number} siteId
 * @param {Array<0 | 1 | 2 | 3 | 4 | 5 | 10 | 20 | 30>} statuses
 * @returns Report of current Bulk Uploads
 */
export default function postReports(
  siteId,
  fromDate,
  toDate,
  userName,
  selectedBulkOperationType,
  selectedStatusType,
  statuses,
) {
  // const pageQuery = page ? `?Page=${page}` : '';
  // const sizeQuery = size ? `&Size=${size}` : '';
  const endpoint = `oc-product-bulkapi/v1/bulk-process/sites/${siteId}/reports`;

  const body = {
    fromDate,
    toDate,
    userName,
    selectedBulkOperationType,
    selectedStatusType,
    statusList: statuses,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Reports')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
