import postRequest from '../../../../postRequest';

/**
 * Post request for getting all attribute definition logs.
 *
 * @param {string} searchTerm "matthewg.williams"
 * @param {number} Page 1
 * @param {number} Size 5
 *
 */
export default function postAttributeLogs(
  searchTerm,
  Page,
  Size,
) {
  const endpoint = `/oc-data-management/v1/attributes/logs?searchTerm=${searchTerm}&Page=${Page}&Size=${Size}`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, 'Post attribute logs')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
