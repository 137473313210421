import React from 'react';

export default function Release20230824() {
  return (
    <section>
      <h2 className="font-gothammedium text-neutral-700">
        Introducing Products Table and Product Page
      </h2>
      <p className="-mt-4">13th September 2023</p>

      <h3 className="font-gothammedium text-neutral-700">
        Products Table (PLP)
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          <strong>
            New Design
          </strong>
          <ul>
            <li>
              We&apos;ve revamped the UI and filters for the Product Listing Page (PLP), prioritizing the most frequently
              used filters and placing them front and centre to expedite your searches.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Quicker Search Results
          </strong>
          <ul>
            <li>
              The search speed has been significantly enhanced, leading to faster retrieval of results than ever before.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Make the table yours
          </strong>
          <ul>
            <li>
              You now have the ability to hide columns you know you rarely use
              and filter the current page of the table directly instead of making another request.
            </li>
            <li>
              During the exporting process you can assign a custom name to the file.
              Stopping the accumulation of files named “Central PIM - Products Copy 50.csv”.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Deeplinking
          </strong>
          <ul>
            <li>
              Your selected filters now appear in the URL.
            </li>
            <li>
              Once you&apos;ve conducted a search, you have the option to copy the URL and (share / paste) it into a fresh browser window.
              This will allow you to revisit your previous search or send someone the search, no more need to describe it to them.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Other changes compared to CentralPIM
          </strong>
          <ul>
            <li>
              Tooltips for the most confusing filters.
            </li>
            <li>
              <i>No Product Type</i>
              {' '}
              renamed to
              {' '}
              <i>Has Product Type</i>
              .
            </li>
            <li>
              <i>Uncategoried</i>
              {' '}
              renamed to
              {' '}
              <i>Categorised</i>
              .
            </li>
            <li>
              <i>With Remote Category ID</i>
              {' '}
              renamed to
              {' '}
              <i>ERP Category ID</i>
              .
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Export All
          </strong>
          <ul>
            <li>
              Added an option to the export functionality that allows a user to export all the returned products.
            </li>
          </ul>
        </li>
      </ol>
      <h4>
        Bug Fixes:
      </h4>
      <ul>
        <li>
          Exported data will now say the dropship source name and whether it is true or false. Example, AnatwineUK - TRUE
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Product Page (PDP)
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          <strong>
            New Design
          </strong>
          <ul>
            <li>
              We have revamped the style section and added all the necessary information at the top.
            </li>
            <li>
              The different sections within CentralPIM&apos;s PDP have been organized into tabs.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Separate Product Page
          </strong>
          <ul>
            <li>
              You can now copy the URL of the product you are on and open it in a new tab to save for later or send it to someone else.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Faster Load Times
          </strong>
          <ul>
            <li>
              We have worked hard to decrease the load times of all operations.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Familiar Surroundings
          </strong>
          <ul>
            <li>
              All the same functionality that you know from CentralPIM.
            </li>
          </ul>
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Upcoming Features
      </h3>
      <ul>
        <li>
          Product Types.
        </li>
        <li>
          Bug fixes and improvements.
        </li>
        <li>
          Even more speed.
        </li>
      </ul>
    </section>
  );
}
