import 'react-quill/dist/quill.snow.css';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import postProductAttributes from '../../../../../../api/oc_product/product/attributes/post/postProductAttributes';
import getGeneral from '../../../../../../api/oc_product/product/general/gets/getGeneral';
import Model from '../../../../../../components/Modal';
import Table from '../../../../../../components/Table';
import DescriptionEditor from './components/DescriptionEditor';
import MediaDisplay from './components/MediaDisplay';
import ProductEnrichment from './components/ProductEnrichment';

function CustomTabLabel() {
  return (
    <div className="flex flex-col items-center">
      <span>Translated</span>
      <span className="text-xs" style={{ textTransform: 'lowercase' }}>product description</span>
    </div>
  );
}
function AttrModal({
  open,
  onClose,
  onClick,
  title,
  status,
  loading,
}) {
  return (
    <Model
      open={open}
      onClose={onClose}
      buttonOnClick={onClick}
      buttonLabel="Confirm"
      title={(
        <span>
          Are you sure you want to update
          {' '}
          <i>{title}</i>
          ?
        </span>
      )}
      buttonLoading={loading}
    >
      <p>
        This will
        {' '}
        <span className="font-gothammedium">{status === 'True' || status === 'true' ? 'Disable' : 'Enable'}</span>
        {' '}
        <i>{title}</i>
      </p>
    </Model>
  );
}

function General() {
  const isDesktop = useMediaQuery('(min-width:600px)');

  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language?.language?.LanguageId);
  const isLeadSite = useSelector((state) => state.user?.loginResponse?.selectedSite.IsLead);

  const [selectedAttrModal, setSelectedAttrModal] = useState(null);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [spinSets, setSpinSets] = useState([]);
  const [generalData, setGeneralData] = useState({});
  const [description, setDescription] = useState('');
  const [translatedDescription, setTranslatedDescription] = useState('');
  const [attributeTableData, setAttributeTableData] = useState([]);
  const [selectedAttr, setSelectedAttr] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const columns = [
    {
      id: 'label',
      label: 'Attribute',
      styles: {
        width: '60%',
      },
    },
    { id: 'value', label: 'Enabled' },
  ];
  const [attributeTableRows, setAttributeTableRows] = useState([]);
  const [pendingAttributeUpdate, setPendingAttributeUpdate] = useState(false);
  const getMedia = async (data) => {
    const mediaSetLink = data?.Media?.MediaSetLink;
    if (mediaSetLink && mediaSetLink !== 'http://i1.adis.ws/i/jpl/imagemissing') {
      const httpsMediaSetLink = mediaSetLink.slice(0, 5) === 'http:' ? `https${mediaSetLink.slice(4)}` : mediaSetLink;
      const mediaResponse = await axios.get(httpsMediaSetLink);
      const productImages = mediaResponse?.data?.items?.filter((obj) => obj.type === 'img');
      setImages(productImages);
      const productVideos = mediaResponse?.data?.items?.find((obj) => obj.type === 'video');
      setVideo(productVideos);
      const productSpinSet = mediaResponse?.data?.items?.find((obj) => obj.type === 'set');
      if (productSpinSet) {
        const spinSetSrc = productSpinSet.src;
        const httpsSpinSet = spinSetSrc?.slice(0, 5) === 'http:' ? `https${spinSetSrc.slice(4)}` : spinSetSrc;
        const jsonSpinSet = httpsSpinSet.includes('.json') ? httpsSpinSet : `${httpsSpinSet}.json`;
        if (jsonSpinSet) {
          const spinSetResponse = await axios.get(jsonSpinSet);
          setSpinSets(spinSetResponse.data);
        }
      }
    } else {
      setImages([]);
    }
  };
  const getAttributes = (data) => {
    const attr = data.ProductFlagAttribute?.AttributeDefWithValues;
    const rows = attr?.map((obj, index) => ({
      id: index.toString(),
      label: (
        <p className="w-3/4 px-2 mx-auto text-sm text-center break-words">
          {obj.Description}
        </p>
      ),
      value: (
        <Switch
          size="small"
          checked={!(obj.Value === 'False' || obj.Value === 'false')}
          onClick={() => {
            setSelectedAttr({
              productAttributeDefKey: obj.ProductAttributeKey,
              attributeDataTypeId: obj.AttributeDataTypeId,
              productAttributeDefId: obj.ProductAttributeDefId,
              value: obj.Value === 'False' ? 'True' : 'False',
            });
            setSelectedAttrModal(index);
          }}
        />
      ),
    }));
    const addedIndex = attr?.map((obj, index) => ({
      id: index.toString(),
      label: obj.Description,
      value: obj.Value,
    }));
    if (rows) {
      setAttributeTableData(addedIndex);
      setAttributeTableRows(rows);
    }
  };

  const updateAttr = async () => {
    const payload = {
      attributesValues: [selectedAttr],
    };
    const attrLabel = attributeTableData[selectedAttrModal].label;
    setPendingAttributeUpdate(true);
    await postProductAttributes(siteId, Number(id), languageId, payload, `Updated ${attrLabel}`)
      .then(() => {
        setSelectedAttrModal(null);
        getGeneral(id, siteId, languageId)
          .then((res) => {
            const descriptionFromAPI = res?.data.Description;
            const translatedDescriptionFromAPI = res?.data.TranslatedDescription;
            getMedia(res.data);
            setDescription(descriptionFromAPI);
            setTranslatedDescription(translatedDescriptionFromAPI);
            getAttributes(res.data);
            setGeneralData(res.data);
          });
      })
      .catch((err) => console.error(err))
      .finally(() => setPendingAttributeUpdate(false));
  };

  React.useEffect(() => {
    getGeneral(id, siteId, languageId)
      .then((res) => {
        let descriptionFromAPI = res?.data.Description;
        let transaltedDescriptionFromAPI = res?.data.TranslatedDescription;
        if (transaltedDescriptionFromAPI === null) {
          transaltedDescriptionFromAPI = {
            ProductAttributeDefId: 16870,
            ProductAttributeKey: 'translated_product_description',
            AttributeDataTypeId: 4,
            Value: '',
            Updated: '',
          };
        }
        if (descriptionFromAPI === null) {
          descriptionFromAPI = {
            ProductAttributeDefId: 116,
            ProductAttributeKey: 'product_description',
            AttributeDataTypeId: 4,
            Value: '',
            Updated: '',
          };
        }
        getMedia(res.data);
        setDescription(descriptionFromAPI);
        setTranslatedDescription(transaltedDescriptionFromAPI);
        getAttributes(res.data);
        setGeneralData(res.data);
      })
      .then(() => {
        setLoading(false);
      });
  }, [id, siteId, languageId]);

  const handleTabChange = async (event, newValue) => {
    setSelectedTab(newValue);
    try {
      const res = await getGeneral(id, siteId, languageId);
      const descriptionFromAPI = res?.data.Description;
      const translatedDescriptionFromAPI = res?.data.TranslatedDescription;

      setDescription(descriptionFromAPI || {
        ProductAttributeDefId: 116, ProductAttributeKey: 'product_description', AttributeDataTypeId: 4, Value: '', Updated: '',
      });
      setTranslatedDescription(translatedDescriptionFromAPI || {
        ProductAttributeDefId: 16870, ProductAttributeKey: 'translated_product_description', AttributeDataTypeId: 4, Value: '', Updated: '',
      });
    } catch (error) {
      console.error('Failed to fetch data on tab change:', error);
    }
  };
  const tabs = [];
  if (description) {
    tabs.push({
      key: 116,
      value: 0,
      label: 'Description',
      content: description,
    });
  }
  if (!isLeadSite) {
    tabs.push({
      key: 16870,
      value: 1,
      label: <CustomTabLabel />,
      content: translatedDescription,
    });
  }
  if (tabs.length > 0 && selectedTab === null) {
    setSelectedTab(tabs[0].value);
  }
  return (
    <Grid container xs={12} spacing={isDesktop ? 2 : 0} height="492px">
      <Grid item xs={12} md={6} lg={3} className="flex items-start justify-center" mb={isDesktop ? 0 : 4}>
        {loading ? (
          <Skeleton variant="rounded" width={350} height={400} />
        ) : (
          <MediaDisplay
            images={images}
            video={video}
            spinSets={spinSets}
            generalTabData={generalData}
            setImages={setImages}
            setVideo={setVideo}
            setSpinSets={setSpinSets}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3} className="flex items-start justify-center" mb={isDesktop ? 0 : 4}>
        {loading ? (
          <Skeleton variant="rounded" width={350} height={400} />
        ) : (
          <div className="w-full h-full bg-white border rounded" overflow="hidden">
            <Tabs value={selectedTab} onChange={handleTabChange} className="bg-white">
              {tabs.map((tab, index) => (
                (!isLeadSite || index < 2) && (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    style={{ color: tab.content.Value === '' ? 'red' : 'inherit' }}
                  />
                )
              ))}
            </Tabs>
            <div className="h-96 p-5" overflow="auto">
              {tabs.map((tab) => (
                selectedTab === tab.value && (
                  loading ? (
                    <Skeleton key={tab.value} variant="rounded" width={350} height={400} />
                  ) : (
                    <DescriptionEditor key={tab.value} content={tab.content} label={tab.label} />
                  )
                )
              ))}
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3} className="flex items-start justify-center" mb={isDesktop ? 0 : 4}>
        {loading ? (
          <Skeleton variant="rounded" width={350} height={400} />
        ) : (
          <div className="overflow-hidden bg-white border rounded">
            <Table
              columns={columns}
              rows={attributeTableRows}
            />
            {selectedAttrModal !== null && (
              <AttrModal
                open
                onClick={() => updateAttr(selectedAttr)}
                onClose={() => setSelectedAttrModal(null)}
                title={attributeTableData[selectedAttrModal].label}
                status={attributeTableData[selectedAttrModal].value}
                loading={pendingAttributeUpdate}
              />
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3} className="flex items-start justify-center">
        <ProductEnrichment />
      </Grid>
    </Grid>
  );
}

export default General;
