import patchRequest from '../../../../../patchRequest';

/**
 * Calls product-style endpoint to update products name and brand in PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @param {string} brandId - Nike
 * @param {string} productName - Air Max
 * @param {number} languageId - 1
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchProductStyle(
  productId,
  siteCode,
  brandId,
  productName,
  languageId,
) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/product-style';

  const body = {
    productId,
    siteCode,
    brandId,
    productName,
    languageId,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Product Style')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchProductStyle;
