import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

function NameFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const name = searchParams.get('name');

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Product Name</Typography>
        <div className="flex flex-col w-[215px]">
          <TextField
            id="name-filter-textfield"
            placeholder="Enter product name"
            value={name || ''}
            onChange={(event) => {
              setSearchParams((params) => {
                params.set('name', event.target.value);
                if (event.target.value.length === 0) params.delete('name');
                return params;
              });
            }}
            size="small"
          />
        </div>
      </Stack>
    </div>
  );
}

export default NameFilter;
