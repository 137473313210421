import { useRoutes } from 'react-router-dom';

import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoute from './LoginRoute';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const Router = () => useRoutes([AuthenticationRoutes(), LoginRoute(), MainRoutes()]);

export default Router;
