import postRequest from '../../../../postRequest';

/**
 * Post request for category logs.
 *
 * @param {number} siteId Site Id
 * @param {number} page Page count
 * @param {number} size Page size
 */
export default function postCategoryLogs(
  siteId,
  page,
  size,
  {
    sourceId,
    fromDate,
    toDate,
    userName,
    remoteSystemId,
    isLeadSite,
  },
) {
  const pageQuery = page ? `?Page=${page}` : '';
  const sizeQuery = size ? `&Size=${size}` : '';
  const endpoint = `/oc-data-management/v1/category/log/${siteId}/${pageQuery}${sizeQuery}`;

  const body = {
    sourceId,
    fromDate,
    toDate,
    userName,
    remoteSystemId,
    isLeadSite,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Category Logs')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
