import postRequest from '../../../../postRequest';

/**
 * Post request for getting all attribute definitions.
 *
 * @param {number} Page 1
 * @param {number} Size 5
 * @param {number} siteId 1
 * @param {number} languageId 1
 * @param {string} keyAndNameSearch _gender
 * @param {number} productAttributeDefId 20054
 * @param {boolean} isReadOnly true
 * @param {boolean} isHidden true
 * @param {boolean} isMandatory true
 * @param {boolean} includeInSearchKeywords true
 * @param {boolean} hasDefaultValue false
 * @param {boolean} isGlobal true
 * @param {number} attributeDataTypeId 8
 * @param {boolean} includeTotalCount false
 *
 */
export default function postAttributes(
  Page,
  Size,
  siteId,
  languageId,
  keyAndNameSearch,
  productAttributeDefId,
  isReadOnly,
  isHidden,
  isMandatory,
  includeInSearchKeywords,
  hasDefaultValue,
  isGlobal,
  attributeDataTypeId,
  includeTotalCount,
) {
  const endpoint = `/oc-data-management/v1/attributes?Page=${Page}&Size=${Size}`;

  const body = {
    siteId,
    languageId,
    keyAndNameSearch,
    productAttributeDefId,
    isReadOnly,
    isHidden,
    isMandatory,
    includeInSearchKeywords,
    hasDefaultValue,
    isGlobal,
    attributeDataTypeId,
    includeTotalCount,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Attribute Definitions')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
