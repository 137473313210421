import InfoIcon from '@mui/icons-material/Info';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles({
  paper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
  },
});

function HasStockFilter({ locations, sources }) {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const hasStockLocations = searchParams.get('hasStockLocations');
  const hasStockLocationsOnly = searchParams.get('hasStockLocationsOnly');

  const [omsLocations, setOmsLocations] = React.useState([]);

  const [selectedLocations, setSelectedLocations] = React.useState([]);

  React.useEffect(() => {
    const onlyOms = sources.filter((source) => source.SourceType === 'Oms');
    if (onlyOms.length > 0) {
      const onlyOmsIds = onlyOms.map((omsSource) => omsSource.SourceId);
      setSearchParams((params) => {
        params.set('omsId', onlyOmsIds[0]);
        return params;
      });
      const onlyOmsLocations = locations.filter((location) => onlyOmsIds.includes(location.SourceId));
      if (!onlyOmsLocations.length) {
        setOmsLocations(locations);
        return;
      }
      setOmsLocations(onlyOmsLocations);
    } else {
      setSearchParams((params) => {
        params.delete('omsId');
        return params;
      });
    }
  }, [locations, sources]);

  React.useEffect(() => {
    if (hasStockLocations) {
      const selected = hasStockLocations.split(',').map((id) => omsLocations.find((obj) => obj.SourceLocationId === Number(id)));
      if (!selected.includes(undefined)) {
        setSelectedLocations(selected);
      }
    } else {
      setSelectedLocations([]);
    }
  }, [omsLocations, hasStockLocations]);

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Has Stock</Typography>
        <div className="flex flex-col w-max">
          <Stack>
            <Autocomplete
              id="has-stock-autocomplete"
              multiple
              options={omsLocations}
              getOptionLabel={(option) => `${option.LocationRef} - ${option.Description}`}
              value={selectedLocations}
              filterSelectedOptions
              size="small"
              limitTags={2}
              onChange={(e, value) => {
                setSearchParams((params) => {
                  params.set('hasStockLocations', value.map((obj) => obj.SourceLocationId));
                  if (value.length === 0) params.delete('hasStockLocations');
                  return params;
                });
                setSelectedLocations(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Locations"
                  placeholder="Locations"
                  helperText="Select one or multiple locations"
                />
              )}
              classes={{ paper: classes.paper }}
            />
            <div className="flex items-center gap-2">
              <FormControlLabel
                control={(
                  <Checkbox
                    id="has-stock-checkbox"
                    checked={hasStockLocationsOnly}
                    onChange={(e) => {
                      setSearchParams((params) => {
                        if (hasStockLocationsOnly) {
                          params.delete('hasStockLocationsOnly');
                        } else {
                          params.set('hasStockLocationsOnly', e.target.value);
                        }
                        return params;
                      });
                    }}
                    disabled={selectedLocations.length === 0}
                  />
                )}
                label={<p className="text-[14px]">Selected locations only</p>}
                className="mr-0"
              />
              <Tooltip
                title={(
                  <div className="text-sm">
                    <p>
                      <span className="font-gothambold">Selected:</span>
                      {' '}
                      The products shown will only have the selected locations and no other.
                    </p>
                    <p>
                      <span className="font-gothambold">Un-Selected:</span>
                      {' '}
                      The products shown will have selected
                      locations and might contain other non-selected locations.
                    </p>
                  </div>
                )}
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </Stack>
        </div>
      </Stack>
    </div>
  );
}

export default HasStockFilter;
