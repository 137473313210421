import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toString, uniqueId } from 'lodash';
import React from 'react';

import { removePendingSubstring } from '../../../axios/pendingRequests';

function ConfirmModal({
  openModal,
  loading,
  type,
  errors,
  submitClicked,
  cancelClicked,
}) {
  const matches = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    cancelClicked(true);
    setOpen(false);
  };

  React.useEffect(() => setOpen(openModal), [openModal]);

  const positioningStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: matches ? 'auto' : '80%',
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={positioningStyles}>
        <CloseIcon
          className="absolute transition-colors ease-in cursor-pointer top-4 right-4 text-neutral-600 hover:text-neutral-900"
          onClick={() => handleClose()}
        />
        <div className="p-8 border-2 rounded-lg bg-neutral-100 border-neutral-300">
          {loading && (
            <Stack justifyContent="center" alignItems="center" spacing={2}>
              <CircularProgress />

              <div className="flex flex-col items-center justify-center gap-2">
                <Typography variant="h4">
                  Please wait...
                </Typography>

                <Typography variant="h6">
                  We are processing your request.
                </Typography>
              </div>

              <Button
                variant="contained"
                color="error"
                onClick={() => removePendingSubstring('post&/integration-shogun/v1/products')}
              >
                Cancel
              </Button>
            </Stack>
          )}
          {loading === false && Array.from(errors).length <= 0 && type === 'save' && (
            <>
              <Typography variant="h4" gutterBottom>Confirmation</Typography>
              <Typography variant="body1" className="mb-8" align="center">
                Confirm you want to save data.
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button onClick={cancelClicked}>Cancel</Button>
                <Button variant="contained" color="success" onClick={() => submitClicked(true)}>
                  Submit
                </Button>
              </Stack>
            </>
          )}
          {loading === false && type === 'submit' && (
            <>
              <Typography variant="h4" gutterBottom>Confirmation</Typography>
              <Typography variant="body1" className="mb-8" align="center">
                Confirm you want to submit to Shogun.
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button onClick={cancelClicked}>Cancel</Button>
                <Button variant="contained" color="success" onClick={() => submitClicked(true)}>
                  Submit
                </Button>
              </Stack>
            </>
          )}
          {Array.from(errors).length > 0 && loading === false && type === 'save' && (
            <div className="flex flex-col items-center justify-center">
              <ErrorIcon className="mb-2 text-4xl text-error-600" />
              <Typography variant="h4" gutterBottom>
                Error
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Please report any errors seen here to:
                <br />
                <a href="mailto:frontenddevteam@jdplc.com" className="underline">
                  Front-End Dev Team
                </a>
              </Typography>
              <Typography variant="body1" className="mb-4 font-gothammedium" align="center">
                Please click RETRY button
              </Typography>
              <ul className="overflow-y-scroll max-h-[600px] list-outlist pl-8 list-decimal mb-4">
                {Array.from(errors).map((error) => (
                  <li key={uniqueId()}>
                    {toString(error)}
                  </li>
                ))}
              </ul>
              <Button variant="contained" color="info" onClick={() => submitClicked(true)}>
                Retry
              </Button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default ConfirmModal;
