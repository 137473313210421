import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
  Pagination, Popper, Stack, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import postCategoryLogs from '../../../../api/oc_product/productDataManagement/category/post/postCategoryLogs';
import getSources from '../../../../api/oc_product/sites/sources/gets/getSources';
import VirtualizedAutocomplete from '../../../../components/VirtualizedAutocomplete';
import parseDateString from '../../../../utils/parseDateString';
import { CategoryContext } from '../CategoryContext';

function CategoryLogs() {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const { reFetchLogs, setReFetchLogs } = useContext(CategoryContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [username, setUsername] = useState(''.toLowerCase());
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [resetData, setResetData] = useState(false);
  const [logs, setLogs] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [expandedLogs, setExpandedLogs] = useState([]);
  const [openMoreFilters, seOpenMoreFilters] = useState(false);
  const [isLogsOpen, setIsLogsOpen] = useState(true);
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState();
  const [remoteSystemId, setRemoteSystemId] = useState();
  const [isLeadSite, setIsLeadSite] = useState(false);
  const { id } = useParams();

  const fetchLogs = async () => {
    const logsData = await postCategoryLogs(
      siteId,
      currentPage,
      itemsPerPage,
      {
        sourceId: selectedSource?.SourceId,
        fromDate,
        toDate,
        userName: username,
        remoteSystemId,
        isLeadSite,
      },
    );

    setLogs(logsData.data.Data);
    setTotalPages(logsData.data.Pagination.PageCount);
    setTotalRows(logsData.data.Summary.TotalRowCount);
    setReFetchLogs(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterFromDateChange = (selectedDate) => {
    setFromDate(selectedDate);
  };

  const handleFilterToDateChange = (selectedDate) => {
    const endOfDay = selectedDate ? new Date(selectedDate.setHours(23, 59, 59, 999)) : null;
    setToDate(endOfDay);
  };

  const handleSearch = () => {
    fetchLogs();
    setResetData(false);
    seOpenMoreFilters(false);
  };

  const handleFilterReset = () => {
    setUsername('');
    setFromDate(null);
    setToDate(null);
    setResetData(true);
    setSelectedSource(null);
    setRemoteSystemId('');
    setIsLeadSite(false);
  };

  const handleChangeItemsPerPage = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(currentPage, newPageCount);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newPage);
  };

  const handleExpandClick = (logId) => {
    setExpandedLogs((prevExpandedLogs) => {
      if (prevExpandedLogs.includes(logId)) {
        return prevExpandedLogs.filter((log) => log !== logId);
      }
      return [...prevExpandedLogs, logId];
    });
  };

  const handleOpenMoreFilter = () => {
    seOpenMoreFilters(true);
  };

  const handleCloseMoreFilter = () => {
    seOpenMoreFilters(false);
  };

  const handleCloseLogs = () => {
    setIsLogsOpen(false);
  };

  const handleOpenLogs = () => {
    setIsLogsOpen(true);
  };

  const open = Boolean(openMoreFilters);

  const handleClickAway = () => {
    handleCloseMoreFilter();
  };

  React.useEffect(() => {
    getSources(siteId)
      .then((res) => setSources(res.data))
      .catch((err) => console.error(err));
    if (username.length > 0) {
      setUsername('');
    }
    if (fromDate) {
      setFromDate(null);
    }
    if (toDate) {
      setToDate(null);
    }
    if (setIsLogsOpen === false) {
      if (username.length > 0) {
        setUsername('');
      }
    }
    fetchLogs();
    setReFetchLogs(true);
  }, [siteId, isLogsOpen]);

  React.useEffect(() => {
    fetchLogs();
  }, [currentPage, itemsPerPage, id]);

  React.useEffect(() => {
    if (resetData) {
      fetchLogs();
    }
  }, [resetData]);

  React.useEffect(() => {
    if (reFetchLogs) {
      fetchLogs();
    }
  }, [reFetchLogs]);

  React.useEffect(() => {
    console.log('selectedSource:', selectedSource);
  }, [selectedSource]);

  return (
    <div className="relative">
      {!isLogsOpen ? (
        <div className="h-[125px] z-10 absolute right-[-14px] top-[35%]">
          <a
            href={handleOpenLogs}
            onClick={handleOpenLogs}
            className="h-full items-center text-1xl flex break-all text-center rounded text-white bg-[#217ABA] p-1.5 font-black cursor-pointer"
          >
            LOGS
          </a>
        </div>
      ) : (
        <div className="h-[25px] w-[25px] bg-[#737373] flex justify-center items-center rounded-full z-10 absolute right-[-12px] top-[40%]">
          <ArrowForwardIosIcon
            className="text-white cursor-pointer"
            onClick={handleCloseLogs}
          />
        </div>
      )}
      <Box
        className="h-[calc(100vh-120px)] border rounded bg-neutral-100 relative
        transition-all ease-in-out duration-300 max-w-[600px]"
        style={{
          right: isLogsOpen ? '0' : '-100%',
        }}
      >
        {isLogsOpen && (
          <section className="h-full overflow-scroll">
            <div className="p-2 m-1 bg-white border rounded-lg shadow-md">
              <div className="flex flex-col items-center sm:flex-row">
                <TextField
                  label="Name"
                  variant="outlined"
                  value={username}
                  size="small"
                  onChange={(event) => setUsername(event.target.value)}
                  className="w-[250px]"
                />
                <Button
                  onClick={handleOpenMoreFilter}
                  variant="text"
                  className="text-[10px] w-[70px] text-primary-600 mt-[16px] sm:mt-0 sm:ml-[5px] min-w-[42px] w-10 mr-5"
                  anchorEl={document.getElementById('more-filters-button')}
                >
                  <Tooltip
                    title={(
                      <p className="text-sm">More Filters</p>
                    )}
                  >
                    <FilterListIcon />
                  </Tooltip>
                </Button>
                {openMoreFilters && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Popper
                      open={open}
                      onClose={handleCloseMoreFilter}
                      placement="right"
                      className="absolute left-auto w-1/4 pr-8 bg-white border rounded-lg shadow-md right-32 top-44"
                      id="more-filters-button"
                    >
                      <Typography className="text-primary-600" sx={{ p: 2 }}>More Filters</Typography>
                      <DatePicker
                        label="From"
                        inputFormat="dd-MM-yyyy"
                        value={fromDate}
                        onChange={handleFilterFromDateChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                          />
                        )}
                        showDaysOutsideCurrentMonth
                        maxDate={toDate ? new Date(toDate) : new Date()}
                        className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
                      />
                      <DatePicker
                        label="To"
                        inputFormat="dd-MM-yyyy"
                        value={toDate}
                        onChange={handleFilterToDateChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                          />
                        )}
                        showDaysOutsideCurrentMonth
                        minDate={fromDate && new Date(fromDate)}
                        maxDate={new Date()}
                        className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
                      />
                      <VirtualizedAutocomplete
                        id="sources-virtualized-autocomplete"
                        options={sources}
                        getOptionLabel={(option) => option.Name}
                        onSelectedValue={(value) => setSelectedSource(value)}
                        size="small"
                        value={selectedSource || null}
                        textFieldLabel="Source"
                        placeholder="Sources"
                        helperText="Select one or multiple sources"
                        optionKey="Name"
                        className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
                      />
                      <TextField
                        label="Remote System ID"
                        variant="outlined"
                        value={remoteSystemId}
                        size="small"
                        onChange={(event) => setRemoteSystemId(event.target.value)}
                        className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
                      />
                      <div className="flex flex-row items-center pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]">
                        <Checkbox
                          checked={isLeadSite}
                          onChange={() => setIsLeadSite(!isLeadSite)}
                        />
                        <Typography variant="body1" style={{ color: '#6f7686' }}>
                          Is lead site?
                        </Typography>
                      </div>
                      <div className="pb-8 w-max">
                        <Button
                          onClick={handleSearch}
                          variant="contained"
                          className="mt-[16px] sm:mt-0 sm:ml-[16px]"
                          disabled={(!fromDate && !toDate) && !selectedSource && !remoteSystemId && !isLeadSite}
                        >
                          Apply
                        </Button>
                        <Button
                          onClick={handleFilterReset}
                          variant="text"
                          className="text-primary-600 mt-[16px] sm:mt-0 sm:ml-[16px]"
                          disabled={(!fromDate && !toDate) && !selectedSource && !remoteSystemId && !isLeadSite}
                          sx={{
                            '&.Mui-disabled': {
                              WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
                            },
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </Popper>
                  </ClickAwayListener>
                )}
                <Button
                  onClick={handleSearch}
                  variant="contained"
                  className="mt-[16px] sm:mt-0 sm:ml-[16px] text-sm pl-[22px] pr-[22px]"
                  disabled={username === '' && !fromDate && !toDate}
                >
                  Search
                </Button>
                <Button
                  onClick={handleFilterReset}
                  variant="text"
                  className="text-primary-600 mt-[16px] sm:mt-0 sm:ml-[5px] text-sm"
                >
                  Reset
                </Button>
              </div>
            </div>

            <div className="py-2">
              {logs.length > 0 ? (
                <Timeline
                  className="mb-10"
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 'none',
                      width: '110px',
                    },
                  }}
                >
                  {logs.map((log) => {
                    const jsonStartIndex = log.Message.indexOf('[');
                    const jsonEndIndex = log.Message.lastIndexOf(']') + 1;
                    const nonJsonMessage = log.Message.substring(0, jsonStartIndex);
                    const jsonString = log.Message.substring(jsonStartIndex, jsonEndIndex);

                    if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
                      try {
                        const isExpanded = expandedLogs.includes(log.Created);
                        return (
                          <TimelineItem key={log.created}>
                            <TimelineOppositeContent style={{ padding: '2px 16px 6px 16px' }}>
                              <Stack>
                                <Typography variant="body2" style={{ color: '#6f7686' }}>
                                  {parseDateString(log.Created)}
                                </Typography>
                              </Stack>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                              <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                            </TimelineSeparator>
                            <TimelineContent style={{ padding: '0 16px 6px 16px' }} className="break-words max-w-[400px]">
                              <Typography>{nonJsonMessage}</Typography>
                              {isExpanded ? (
                                <>
                                  <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                                    Show less
                                  </Typography>
                                  <pre className="whitespace-pre-wrap">{JSON.stringify(jsonString, null, 2)}</pre>
                                </>
                              ) : (
                                <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                                  Show more
                                </Typography>
                              )}
                              <Typography className="flex items-center" variant="body2" style={{ color: '#6f7686' }}>
                                <PersonOutlineIcon style={{ color: '#6f7686', marginRight: '5px' }} fontSize="small" />
                                {' '}
                                {log.UserName}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        );
                      } catch (error) {
                        console.error('Error parsing JSON:', error);
                        return null;
                      }
                    } else {
                      return (
                        <TimelineItem key={log.created}>
                          <TimelineOppositeContent style={{ padding: '2px 16px 6px 16px' }}>
                            <Stack>
                              <Typography variant="body2" style={{ color: '#6f7686' }}>
                                {parseDateString(log.Created)}
                              </Typography>
                            </Stack>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                            <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                          </TimelineSeparator>
                          <TimelineContent style={{ padding: '0 16px 6px 16px' }}>
                            <Typography>{log.Message}</Typography>
                            <Typography className="flex items-center break-all min-w-[165px]" variant="body2" style={{ color: '#6f7686' }}>
                              <PersonOutlineIcon style={{ color: '#6f7686', marginRight: '5px' }} fontSize="small" />
                              {' '}
                              {log.UserName}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    }
                  })}
                </Timeline>
              ) : <Typography className="pl-4">No results found</Typography>}
            </div>
            <div className="flex flex-col items-center gap-6 p-2 pl-4 bg-white border rounded-lg sm:flex-row w-[98%]
          absolute bottom-0 right-0 m-1 shadow-md"
            >
              <FormControl sx={{ minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center">
                <Typography variant="body2" className="mr-4">Rows per page:</Typography>
                <Select
                  labelId="logs-per-page"
                  value={itemsPerPage}
                  onChange={handleChangeItemsPerPage}
                  autoWidth
                  size="small"
                  variant="standard"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePage}
              />
            </div>
          </section>
        )}
      </Box>
    </div>
  );
}

export default CategoryLogs;
