import getRequest from '../../../../getRequest';

/**
 * Get all Site Sources for the given site id from PRISM db.
 *
 * @endpoint `/oc-product/v1/sites/${siteId}/sources`
 *
 * @param {number} siteId
 * @returns {[
 *   {
 *     sourceId: number,
 *     description: string,
 *     name: string,
 *     shortDescription: string,
 *     sourceType: string
 *   }
 * ]}
 */
function getSources(siteId) {
  const endpoint = `/oc-product/v1/sites/${siteId}/sources`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Site Sources')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getSources;
