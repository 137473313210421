const WizardPages = [
  {
    id: 1,
    order: 1,
    title: 'Find The Product',
    description: 'Select a product to review.',
    pageName: 'FindProduct',
  },
  {
    id: 2,
    order: 2,
    title: 'Select Where To Sell The Product',
    description: 'Select the fascias and countries where you want to sell the product.',
    pageName: 'WhereToSell',
  },
  {
    id: 3,
    order: 3,
    title: 'Edit Group Product Data',
    description: 'Confirm or edit the products information.',
    pageName: 'ProductData',
  },
  {
    id: 5,
    order: 4,
    title: 'Summary',
    description: 'Review each section before submitting to Shogun.',
    pageName: 'Summary',
  },
  // {
  //   id: 6,
  //   order: 5,
  //   title: 'Review Selling Price',
  //   description: 'Review whether the selling price is correct.',
  //   pageName: 'SellingPrice',
  // },
  {
    id: 7,
    order: 5,
    title: 'Enable Fulfilment',
    description: 'Can the product be enabled for fulfilment on a SKU level.',
    pageName: 'EnableFulfilment',
  },
];

// {
//   id: 4,
//   order: 4,
//   title: 'Edit Translation',
//   description: 'Based on your previous country selections,
//     all of the Product data selected and can be edited.',
//   pageName: 'Translations',
// },

export default WizardPages;
