import getRequest from '../../../../getRequest';

/**
 * Get all products types.
 * @param {number} siteId
 */

export default async function getProductTypes(siteId) {
  const endpoint = `/oc-data-management/v1/product-type/${siteId}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get product types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
