import patchRequest from '../../../../patchRequest';

/**
 * Patch request to change category grouping name.
 *
 * @param {number} categoryId 1
 * @param {number} siteId 1
 * @param {number} categoryName Grouping name
 */
export default function patchCategoryGroupingName(
  categoryId,
  siteId,
  categoryName,
) {
  const endpoint = `/oc-data-management/v1/category/${categoryId}/${siteId}/grouping-name`;
  const errorMessageHeader = 'Change category grouping name';
  const body = {
    categoryName,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
