// import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import patchProductTypes from '../../../../../../api/oc_product/product/attributes/attributeProductTypes/gets/patchProductTypes';
import postEditable from '../../../../../../api/oc_product/product/attributes/editable/posts/postEditable';
import getMultiLookupAttributes from '../../../../../../api/oc_product/product/attributes/multiLookupAttributes/getMultiLookupAttributes';
import postProductAttributes from '../../../../../../api/oc_product/product/attributes/post/postProductAttributes';
import postReadOnly from '../../../../../../api/oc_product/product/attributes/read_only/posts/postReadOnly';
import getProductsTypes from '../../../../../../api/oc_product/product/types/getProductsTypes';
import getProductTypes from '../../../../../../api/oc_product/sites/product_types/gets/getProductTypes';
import Modal from '../../../../../../components/Modal';
import VirtualizedAutocomplete from '../../../../../../components/VirtualizedAutocomplete';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: `${theme.palette.secondary[500]} !important`,
    height: '4px !important',
  },
  selected: {
    color: '#fff !important',
    opacity: '1 !important',
  },
  tabRoot: {
    color: '#fff !important',
    width: '50%',
  },
  table: {
    '& .MuiTableCell-root': {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
    '&.MuiTableCell-head': {
      backgroundColor: theme.palette.grey[100],
    },
    width: '70%',
    '&:first-child': {
      width: '30%',
    },
  },
}));

function TabPanel({
  children,
  value,
  index,
  ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: {},
  value: 0,
  index: 0,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

function MultiLookupAutocomplete({
  isMultiple, ProductAttributeDefId, ProductAttributeDefKey, defaultValue, onChange, disabled, InputLabelProps,
}) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language.language.LanguageId);
  const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
  const [val, setVal] = React.useState(isMultiple ? [] : null);

  React.useEffect(() => {
    if (defaultValue) {
      if (Array.isArray(defaultValue)) {
        const filteredOptions = autocompleteOptions.filter((option) => defaultValue.find((value) => value.Id === option?.Id));
        setVal(filteredOptions);
      } else {
        setVal(defaultValue);
      }
    }
  }, [autocompleteOptions, defaultValue]);
  React.useEffect(() => {
    getMultiLookupAttributes(siteId, languageId, ProductAttributeDefId)
      .then((res) => {
        const transformedOptions = res.data.map((option) => ({
          Id: option.ProductAttributeLookupOptionId,
          Value: option.Value,
        }));
        setAutocompleteOptions(transformedOptions);
      })
      .catch((err) => {
        console.error('err:', err);
      });
  }, [ProductAttributeDefId]);

  return (
    <div className="w-full">
      <VirtualizedAutocomplete
        multiple={isMultiple}
        textFieldLabel={ProductAttributeDefKey}
        shrink={InputLabelProps}
        options={autocompleteOptions}
        size="small"
        getOptionLabel={(option) => option.Value}
        value={val}
        onChange={(e, value) => {
          onChange(value);
          setVal(value);
        }}
        disabled={disabled}
        optionKey="Value"
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
      />
    </div>
  );
}

function DisplayInput(props) {
  const {
    row: {
      ProductAttributeDefId,
      AttributeDataTypeId,
      ProductAttributeDefKey,
      AttributeValues,
    },
    addChangedAttr,
  } = props;

  const [checkboxNumValue, setCheckboxNumValue] = React.useState(() => {
    if (AttributeValues.length >= 1) {
      // Check for changed val
      const changedValue = AttributeValues.find(
        (item) => item.Level === 'Changed',
      );

      if (changedValue) {
        return changedValue.Value === '1' ? 'Yes' : 'No';
      }

      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        if (siteValue.Id > 0 && siteValue.Value === '1') {
          return siteValue.Value === '1' ? 'Yes' : 'No';
        }
        if (siteValue.Id > 0 && siteValue.Value === '0') {
          return siteValue.Value === '0' ? 'No' : null;
        }
      }

      // Check for Level = "SiteGroup"
      const siteGroupValue = AttributeValues.find(
        (item) => item.Level === 'SiteGroup',
      );

      if (siteGroupValue) {
        if (siteGroupValue.Id > 0 && siteGroupValue.Value === '1') {
          return siteGroupValue.Value === '1' ? 'Yes' : 'No';
        }
        if (siteGroupValue.Id > 0 && siteGroupValue.Value === '0') {
          return siteGroupValue.Value === '0' ? 'No' : null;
        }
      }

      // Check for Level = "Global"
      const globalValue = AttributeValues.find(
        (item) => (item.Level === 'global' || item.Level === 'Global'),
      );

      if (globalValue) {
        if (globalValue.Id > 0 && globalValue.Value === '1') {
          return globalValue.Value === '1' ? 'Yes' : 'No';
        }
        if (globalValue.Id > 0 && globalValue.Value === '0') {
          return globalValue.Value === '0' ? 'No' : null;
        }
      }
      const NaLevel = AttributeValues.find(
        (item) => item.Level === 'NA',
      );
      if (NaLevel) {
        if (NaLevel.Level === 'NA' && NaLevel.Id === 0) {
          return NaLevel.Value === '' ? '' : null;
        }
      }
    } else if (AttributeValues.length === 1) {
      return AttributeValues[0].Id > 0 ? AttributeValues[0].Value === '1' : AttributeValues[0].Value === '0';
    }
    return '0';
  });

  const [checkboxBoolValue, setCheckboxBoolValue] = React.useState(() => {
    if (AttributeValues.length >= 1) {
      // Check for changed val
      const changedValue = AttributeValues.find(
        (item) => item.Level === 'Changed',
      );
      if (changedValue) {
        return changedValue.Value === 'True' || changedValue.Value === 'true';
      }

      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        if (siteValue.Id > 0 && (siteValue.Value.trim() === 'True' || siteValue.Value.trim() === 'true')) return 'True';
      }

      // Check for Level = "SiteGroup"
      const siteGroupValue = AttributeValues.find(
        (item) => item.Level === 'SiteGroup',
      );

      if (siteGroupValue) {
        if (siteGroupValue.Id > 0 && (siteGroupValue.Value.trim() === 'True' || siteGroupValue.Value.trim() === 'true')) return 'True';
      }

      // Check for Level = "Global"
      const globalValue = AttributeValues.find(
        (item) => (item.Level === 'global' || item.Level === 'Global'),
      );

      if (globalValue) {
        if (globalValue.Id > 0 && (globalValue.Value.trim() === 'True' || globalValue.Value.trim() === 'true')) return 'True';
      }
    } else if (AttributeValues.length === 1) {
      return AttributeValues[0].Id > 0 && (AttributeValues[0].Value.trim() === 'True' || AttributeValues[0].Value.trim() === 'true');
    }
    const NaLevel = AttributeValues.find(
      (item) => item.Level === 'NA',
    );
    if (NaLevel) {
      if (NaLevel.Level === 'NA' && NaLevel.Id === 0) {
        return NaLevel.Value === '' ? '' : null;
      }
    }

    return 'False';
  });

  const [numTextFieldValue, setNumTextFieldValue] = React.useState(() => {
    if (AttributeValues.length > 1) {
      // Check for changed val
      const changedValue = AttributeValues.find(
        (item) => item.Level === 'Changed',
      );

      if (changedValue) {
        return Number(changedValue.Value);
      }

      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        return siteValue.Id > 0 && Number(siteValue.Value);
      }

      // Check for Level = "SiteGroup"
      const siteGroupValue = AttributeValues.find(
        (item) => item.Level === 'SiteGroup',
      );

      if (siteGroupValue) {
        return siteGroupValue.Id > 0 && Number(siteGroupValue.Value);
      }

      // Check for Level = "Global"
      const globalValue = AttributeValues.find(
        (item) => item.Level === 'global',
      );

      if (globalValue) {
        return globalValue.Id > 0 && Number(globalValue.Value);
      }
    } else if (AttributeValues.length === 1) {
      return AttributeValues[0].Id > 0 ? Number(AttributeValues[0].Value) : '0';
    }

    return '0';
  });

  const [textFieldValue, setTextFieldValue] = React.useState(() => {
    if (AttributeValues.length > 1) {
      // Check for changed val
      const changedValue = AttributeValues.find(
        (item) => item.Level === 'Changed',
      );

      if (changedValue) {
        return changedValue.Value;
      }

      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        return siteValue.Id > 0 && siteValue.Value;
      }

      // Check for Level = "SiteGroup"
      const siteGroupValue = AttributeValues.find(
        (item) => item.Level === 'SiteGroup',
      );

      if (siteGroupValue) {
        return siteGroupValue.Id > 0 && siteGroupValue.Value;
      }

      // Check for Level = "Global"
      const globalValue = AttributeValues.find(
        (item) => item.Level === 'global',
      );

      if (globalValue) {
        return globalValue.Id > 0 && globalValue.Value;
      }
    } else if (AttributeValues.length === 1) {
      return AttributeValues[0].Id > 0 ? AttributeValues[0].Value : '';
    }

    return '';
  });

  const [multiLookupValue, setMultiLookupValue] = React.useState(() => {
    const siteValues = AttributeValues.filter((item) => item.Level === 'Site' || item.Level === 'Changed');
    const siteGroupValues = AttributeValues.filter((item) => item.Level === 'SiteGroup' || item.Level === 'Changed');
    const globalValues = AttributeValues.filter((item) => item.Level === 'Global' || item.Level === 'Changed');

    if (siteValues.length > 0) {
      return siteValues;
    } if (siteGroupValues.length > 0) {
      return siteGroupValues;
    } if (globalValues.length > 0) {
      return globalValues;
    }
    return [];
  });

  const [singleLookupValue, setSingleLookupValue] = React.useState(() => {
    if (AttributeValues.length > 1) {
      // Check for changed val
      const changedValue = AttributeValues.find(
        (item) => item.Level === 'Changed',
      );

      if (changedValue) {
        return changedValue;
      }

      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        return siteValue.Id > 0 && siteValue;
      }

      // Check for Level = "SiteGroup"
      const siteGroupValue = AttributeValues.find(
        (item) => item.Level === 'SiteGroup',
      );

      if (siteGroupValue) {
        return siteGroupValue.Id > 0 && siteGroupValue;
      }

      // Check for Level = "Global"
      const globalValue = AttributeValues.find(
        (item) => item.Level === 'global',
      );

      if (globalValue) {
        return globalValue.Id > 0 && globalValue;
      }
    } else if (AttributeValues.length === 1) {
      return AttributeValues[0].Id > 0 ? AttributeValues[0] : null;
    }

    return null;
  });

  const [dateValue, setDateValue] = React.useState(() => {
    if (AttributeValues.length > 1) {
      // Check for changed val
      const changedValue = AttributeValues.find(
        (item) => item.Level === 'Changed',
      );

      if (changedValue) {
        return new Date(changedValue.Value);
      }

      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        return siteValue.Id > 0 && new Date(siteValue.Value);
      }

      // Check for Level = "SiteGroup"
      const siteGroupValue = AttributeValues.find(
        (item) => item.Level === 'SiteGroup',
      );

      if (siteGroupValue) {
        return siteGroupValue.Id > 0 && new Date(siteGroupValue.Value);
      }

      // Check for Level = "Global"
      const globalValue = AttributeValues.find(
        (item) => item.Level === 'global',
      );

      if (globalValue) {
        return globalValue.Id > 0 && new Date(globalValue.Value);
      }
    } else if (AttributeValues.length === 1) {
      return AttributeValues[0].Id > 0 ? new Date(AttributeValues[0].Value) : null;
    }

    return null;
  });

  const [quillValue, setQuillValue] = React.useState(() => {
    if (AttributeValues.length > 1) {
      // Check for changed val
      const changedValue = AttributeValues.find(
        (item) => item.Level === 'Changed',
      );

      if (changedValue) {
        return changedValue.Value;
      }

      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        return siteValue.Id > 0 && siteValue.Value;
      }

      // Check for Level = "SiteGroup"
      const siteGroupValue = AttributeValues.find(
        (item) => item.Level === 'SiteGroup',
      );

      if (siteGroupValue) {
        return siteGroupValue.Id > 0 && siteGroupValue.Value;
      }

      // Check for Level = "Global"
      const globalValue = AttributeValues.find(
        (item) => item.Level === 'global',
      );

      if (globalValue) {
        return globalValue.Id > 0 && globalValue.Value;
      }
    } else if (AttributeValues.length === 1) {
      return AttributeValues[0].Id > 0 ? AttributeValues[0].Value : '';
    }

    return '';
  });

  const handleCheckboxNumChange = (e, newValue) => {
    let oldVal = '0';
    if (AttributeValues.length > 1) {
      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        oldVal = siteValue.Value;
      } else {
        // Check for Level = "SiteGroup"
        const siteGroupValue = AttributeValues.find(
          (item) => item.Level === 'SiteGroup',
        );

        if (siteGroupValue) {
          oldVal = siteGroupValue.Value;
        } else {
          // Check for Level = "Global"
          const globalValue = AttributeValues.find(
            (item) => item.Level === 'global',
          );

          if (globalValue) {
            oldVal = globalValue.Value;
          }
        }
      }
    } else if (AttributeValues.length === 1) {
      oldVal = AttributeValues[0].Id > 0 ? AttributeValues[0].Value : 0;
    }
    setCheckboxNumValue(newValue.label);
    addChangedAttr({
      productAttributeDefKey: ProductAttributeDefKey,
      attributeDataTypeId: AttributeDataTypeId,
      productAttributeDefId: ProductAttributeDefId,
      value: newValue.value,
    }, oldVal);
  };

  const handleCheckboxBoolChange = (e, newValue) => {
    let oldVal = 'False';

    if (AttributeValues.length > 1) {
      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        oldVal = siteValue.Value;
      } else {
        // Check for Level = "SiteGroup"
        const siteGroupValue = AttributeValues.find(
          (item) => item.Level === 'SiteGroup',
        );

        if (siteGroupValue) {
          oldVal = siteGroupValue.Value;
        } else {
          // Check for Level = "Global"
          const globalValue = AttributeValues.find(
            (item) => item.Level === 'global',
          );

          if (globalValue) {
            oldVal = globalValue.Value;
          }
        }
      }
    } else if (AttributeValues.length === 1) {
      oldVal = AttributeValues[0].Id > 0 ? AttributeValues[0].Value : 'False';
    }
    setCheckboxBoolValue(newValue.label);
    addChangedAttr({
      productAttributeDefKey: ProductAttributeDefKey,
      attributeDataTypeId: AttributeDataTypeId,
      productAttributeDefId: ProductAttributeDefId,
      value: newValue.value,
    }, oldVal);
  };
  const handleNumTextFieldChange = (e) => {
    let oldVal = '0';

    if (AttributeValues.length > 1) {
      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        oldVal = siteValue.Value;
      } else {
        // Check for Level = "SiteGroup"
        const siteGroupValue = AttributeValues.find(
          (item) => item.Level === 'SiteGroup',
        );

        if (siteGroupValue) {
          oldVal = siteGroupValue.Value;
        } else {
          // Check for Level = "Global"
          const globalValue = AttributeValues.find(
            (item) => item.Level === 'global',
          );

          if (globalValue) {
            oldVal = globalValue.Value;
          }
        }
      }
    } else if (AttributeValues.length === 1) {
      oldVal = AttributeValues[0].Id > 0 ? AttributeValues[0].Value : '0';
    }
    const newValue = e.target.value;
    setNumTextFieldValue(newValue);
    if (newValue !== (AttributeValues[0].Id > 0 ? Number(AttributeValues[0].Value) : 0)) {
      addChangedAttr({
        productAttributeDefKey: ProductAttributeDefKey,
        attributeDataTypeId: AttributeDataTypeId,
        productAttributeDefId: ProductAttributeDefId,
        value: newValue.toString() === '' ? '-no-value-' : newValue.toString(),
      }, Number(oldVal).toString());
    }
  };

  const handleTextFieldChange = (e) => {
    let oldVal = '';

    if (AttributeValues.length > 1) {
      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        oldVal = siteValue.Value;
      } else {
        // Check for Level = "SiteGroup"
        const siteGroupValue = AttributeValues.find(
          (item) => item.Level === 'SiteGroup',
        );

        if (siteGroupValue) {
          oldVal = siteGroupValue.Value;
        } else {
          // Check for Level = "Global"
          const globalValue = AttributeValues.find(
            (item) => item.Level === 'global',
          );

          if (globalValue) {
            oldVal = globalValue.Value;
          }
        }
      }
    } else if (AttributeValues.length === 1) {
      oldVal = AttributeValues[0].Id > 0 ? AttributeValues[0].Value : '';
    }
    const newValue = e.target.value;
    setTextFieldValue(newValue);

    if (newValue !== (AttributeValues[0].Id > 0 ? Number(AttributeValues[0].Value) : 0)) {
      addChangedAttr({
        productAttributeDefKey: ProductAttributeDefKey,
        attributeDataTypeId: AttributeDataTypeId,
        productAttributeDefId: ProductAttributeDefId,
        value: newValue === '' ? '-no-value-' : newValue,
      }, oldVal);
    }
  };

  const handleSingleLookupChange = (newValue) => {
    let oldVal = null;

    if (AttributeValues.length > 1) {
      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        oldVal = siteValue.Id;
      } else {
        // Check for Level = "SiteGroup"
        const siteGroupValue = AttributeValues.find(
          (item) => item.Level === 'SiteGroup',
        );

        if (siteGroupValue) {
          oldVal = siteGroupValue.Id;
        } else {
          // Check for Level = "Global"
          const globalValue = AttributeValues.find(
            (item) => item.Level === 'global',
          );

          if (globalValue) {
            oldVal = globalValue.Id;
          }
        }
      }
    } else if (AttributeValues.length === 1) {
      oldVal = AttributeValues[0].Id > 0 ? AttributeValues[0].Id : null;
    }
    setSingleLookupValue(newValue);

    addChangedAttr({
      productAttributeDefKey: ProductAttributeDefKey,
      attributeDataTypeId: AttributeDataTypeId,
      productAttributeDefId: ProductAttributeDefId,
      value: newValue ? newValue.Id : '0',
      Val: newValue ? newValue.Value : '',
    }, oldVal);
  };

  const handleMultiLookupChange = (newValues) => {
    let oldVal = [];
    const siteValues = AttributeValues.filter((item) => item.Level === 'Site').sort((a, b) => a.Id - b.Id);
    const siteGroupValues = AttributeValues.filter((item) => item.Level === 'SiteGroup').sort((a, b) => a.Id - b.Id);
    const globalValues = AttributeValues.filter((item) => item.Level === 'Global').sort((a, b) => a.Id - b.Id);

    if (siteValues.length > 0) {
      oldVal = siteValues.map((value) => ({
        productAttributeDefKey: ProductAttributeDefKey,
        attributeDataTypeId: AttributeDataTypeId,
        productAttributeDefId: ProductAttributeDefId,
        value: value.Id,
      }));
    } else if (siteGroupValues.length > 0) {
      oldVal = siteGroupValues.map((value) => ({
        productAttributeDefKey: ProductAttributeDefKey,
        attributeDataTypeId: AttributeDataTypeId,
        productAttributeDefId: ProductAttributeDefId,
        value: value.Id,
      }));
    } else if (globalValues.length > 0) {
      oldVal = globalValues.map((value) => ({
        productAttributeDefKey: ProductAttributeDefKey,
        attributeDataTypeId: AttributeDataTypeId,
        productAttributeDefId: ProductAttributeDefId,
        value: value.Id,
      }));
    }
    setMultiLookupValue(newValues);

    const updatedValues = newValues.length > 0 ? newValues.map((value) => ({
      productAttributeDefKey: ProductAttributeDefKey,
      attributeDataTypeId: AttributeDataTypeId,
      productAttributeDefId: ProductAttributeDefId,
      value: value.Id,
      Val: value.Value,
    })) : {
      productAttributeDefKey: ProductAttributeDefKey,
      attributeDataTypeId: AttributeDataTypeId,
      productAttributeDefId: ProductAttributeDefId,
      value: '0',
    };

    addChangedAttr(updatedValues.length > 1 ? updatedValues.sort((a, b) => a.value - b.value) : updatedValues, oldVal.length < 1 ? updatedValues : oldVal);
  };

  const handleDateChange = (newDate) => {
    let oldVal = null;

    if (AttributeValues.length > 1) {
      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        oldVal = siteValue.Value;
      } else {
        // Check for Level = "SiteGroup"
        const siteGroupValue = AttributeValues.find(
          (item) => item.Level === 'SiteGroup',
        );

        if (siteGroupValue) {
          oldVal = siteGroupValue.Value;
        } else {
          // Check for Level = "Global"
          const globalValue = AttributeValues.find(
            (item) => item.Level === 'global',
          );

          if (globalValue) {
            oldVal = globalValue.Value;
          }
        }
      }
    } else if (AttributeValues.length === 1) {
      oldVal = AttributeValues[0].Id > 0 ? AttributeValues[0].Value : null;
    }

    setDateValue(newDate);

    addChangedAttr({
      productAttributeDefKey: ProductAttributeDefKey,
      attributeDataTypeId: AttributeDataTypeId,
      productAttributeDefId: ProductAttributeDefId,
      value: newDate === null ? '-no-value-' : newDate,
    }, oldVal);
  };

  const handleQuillChange = (newValue) => {
    let oldVal = '';
    let newVal = '';
    if (AttributeValues.length > 1) {
      // Check for Level = "Site"
      const siteValue = AttributeValues.find(
        (item) => item.Level === 'Site',
      );

      if (siteValue) {
        oldVal = siteValue.Value;
      } else {
        // Check for Level = "SiteGroup"
        const siteGroupValue = AttributeValues.find(
          (item) => item.Level === 'SiteGroup',
        );

        if (siteGroupValue) {
          oldVal = siteGroupValue.Value;
        } else {
          // Check for Level = "Global"
          const globalValue = AttributeValues.find(
            (item) => item.Level === 'global',
          );

          if (globalValue) {
            oldVal = globalValue.Value;
          }
        }
      }
    } else if (AttributeValues.length === 1) {
      oldVal = AttributeValues[0].Id > 0 ? AttributeValues[0].Value : '';
    }
    if (newValue === '<p><br></p>') {
      newVal = '';
    } else {
      newVal = newValue;
    }
    setQuillValue(newVal);

    if (newValue !== (AttributeValues[0].Id > 0 ? Number(AttributeValues[0].Value) : 0)) {
      addChangedAttr({
        productAttributeDefKey: ProductAttributeDefKey,
        attributeDataTypeId: AttributeDataTypeId,
        productAttributeDefId: ProductAttributeDefId,
        value: newVal === '' ? '-no-value-' : newVal,
      }, oldVal);
    }
  };

  switch (AttributeDataTypeId) {
    case 1:
      return (
        <Autocomplete
          options={[
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ]}
          size="small"
          InputLabelProps={{ shrink: true }}
          defaultValue={checkboxNumValue}
          onChange={handleCheckboxNumChange}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" style={{ width: '200px', height: '40px', fontSize: '16px' }} />
          )}
        />
      );
    case 2:
      return (
        <TextField
          size="small"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          defaultValue={numTextFieldValue}
          onChange={handleNumTextFieldChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    case 3:
      return (
        <TextField
          size="small"
          fullWidth
          label={ProductAttributeDefKey}
          InputLabelProps={{ shrink: true }}
          defaultValue={textFieldValue}
          onChange={handleTextFieldChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    case 4:
      return (
        <TextField
          size="small"
          fullWidth
          label={ProductAttributeDefKey}
          InputLabelProps={{ shrink: true }}
          defaultValue={textFieldValue}
          onChange={handleTextFieldChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    case 5:
      return (
        <DatePicker
          label={ProductAttributeDefKey}
          value={dateValue}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} size="small" InputLabelProps={{ shrink: true }} />}
        />
      );
    case 6:
      return (
        <TextField
          size="small"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          defaultValue={numTextFieldValue}
          onChange={handleNumTextFieldChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    case 7: {
      return (
        <TextField
          size="small"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          value={numTextFieldValue === '0' ? '' : numTextFieldValue}
          onChange={handleNumTextFieldChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    }
    case 8:
      return (
        <MultiLookupAutocomplete
          ProductAttributeDefId={ProductAttributeDefId}
          ProductAttributeDefKey={ProductAttributeDefKey}
          isMultiple={false}
          InputLabelProps={{ shrink: true }}
          defaultValue={singleLookupValue}
          onChange={handleSingleLookupChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );

    case 9:
      return (
        <MultiLookupAutocomplete
          ProductAttributeDefId={ProductAttributeDefId}
          ProductAttributeDefKey={ProductAttributeDefKey}
          isMultiple
          InputLabelProps={{ shrink: true }}
          defaultValue={multiLookupValue}
          onChange={handleMultiLookupChange}
          className="hus-test"
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    case 10:
      return (
        <ReactQuill
          theme="snow"
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
          value={quillValue}
          onChange={handleQuillChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    case 11:
      return (
        <Autocomplete
          options={[
            { label: 'True', value: 'True' },
            { label: 'False', value: 'False' },
          ]}
          size="small"
          defaultValue={checkboxBoolValue}
          onChange={handleCheckboxBoolChange}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" style={{ width: '200px', height: '40px', fontSize: '16px' }} />
          )}
        />
      );
    default:
      break;
  }
}

function DisplayReadOnlyInput(props) {
  const {
    row: {
      ProductAttributeDefId,
      AttributeDataTypeId,
      ProductAttributeDefKey,
      AttributeValues,
    },
  } = props;

  switch (AttributeDataTypeId) {
    case 1:
      return (
        <Checkbox
          checked={AttributeValues[0].Id > 0 && AttributeValues[0].Value === '1'}
          disabled
        />
      );
    case 2:
      return (
        <TextField
          size="small"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          defaultValue={AttributeValues[0].Id > 0 ? Number(AttributeValues[0].Value) : 0}
          disabled
        />
      );
    case 3:
      return (
        <TextField
          size="small"
          fullWidth
          label={ProductAttributeDefKey}
          InputLabelProps={{ shrink: true }}
          defaultValue={AttributeValues[0].Id > 0 ? AttributeValues[0].Value : ''}
          disabled
        />
      );
    case 4:
      return (
        <TextField
          size="small"
          fullWidth
          label={ProductAttributeDefKey}
          InputLabelProps={{ shrink: true }}
          defaultValue={AttributeValues[0].Id > 0 ? AttributeValues[0].Value : ''}
          disabled
        />
      );
    case 5:
      return (
        <DatePicker
          label={ProductAttributeDefKey}
          value={AttributeValues[0].Id > 0 ? new Date(AttributeValues[0].Value) : null}
          disabled
          renderInput={(params) => <TextField {...params} size="small" InputLabelProps={{ shrink: true }} />}
        />
      );
    case 6:
      return (
        <TextField
          size="small"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          defaultValue={AttributeValues[0].Id > 0 ? Number(AttributeValues[0].Value) : 0}
          disabled
        />
      );
    case 7:
      return (
        <TextField
          size="small"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          defaultValue={AttributeValues[0].Id > 0 ? Number(AttributeValues[0].Value) : 0}
          disabled
        />
      );
    case 8:
      return (
        <MultiLookupAutocomplete
          ProductAttributeDefId={ProductAttributeDefId}
          ProductAttributeDefKey={ProductAttributeDefKey}
          isMultiple={false}
          InputLabelProps={{ shrink: true }}
          defaultValue={AttributeValues[0].Id > 0 ? AttributeValues[0] : null}
          disabled
        />
      );
    case 9:
      return (
        <MultiLookupAutocomplete
          ProductAttributeDefId={ProductAttributeDefId}
          ProductAttributeDefKey={ProductAttributeDefKey}
          isMultiple
          InputLabelProps={{ shrink: true }}
          defaultValue={AttributeValues[0].Id > 0 ? [AttributeValues[0]] : []}
          disabled
        />
      );
    case 10:
      return (
        <ReactQuill
          theme="snow"
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
          value={AttributeValues[0].Id > 0 ? AttributeValues[0].Value : ''}
          disabled
        />
      );
    case 11:
      return (
        <Checkbox
          checked={AttributeValues[0].Id > 0 && AttributeValues[0].Value === '1'}
          disabled
        />
      );
    default:
      break;
  }
}

function CustomEditablePagination(
  selectedEditablePage,
  onChangeSelectedEditablePage,
  editablePageSize,
  onChangeEditablePageSize,
  editablePageAmount,
  totalAttributes,
  updateAttributes,
  cancelUpdates,
  changedAttributes,
) {
  return (
    <div className="w-[100%] flex justify-between gap-4">
      <div className="flex flex-row items-center ml-2">
        <Button
          variant="contained"
          className="mr-2 max-h-11"
          disabled={changedAttributes.length < 1}
          onClick={updateAttributes}
        >
          Update
        </Button>
        <Button
          variant="outlined"
          className="max-h-11"
          disabled={changedAttributes.length < 1}
          onClick={cancelUpdates}
          color="error"
        >
          Cancel
        </Button>
      </div>
      <div className="flex flex-row items-center">
        <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center mt-5 mb-0 sm:mt-0">
          <Typography variant="body2" className="mr-5">Rows per page:</Typography>
          <Select
            value={editablePageSize}
            onChange={onChangeEditablePageSize}
            autoWidth
            size="small"
            variant="standard"
          >
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={400}>400</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={editablePageAmount}
          page={selectedEditablePage}
          onChange={onChangeSelectedEditablePage}
          size="small"
        />
        <div className="flex items-center justify-center mr-4">
          <Tooltip title="Total Attributes" arrow placement="top">
            <p>{totalAttributes}</p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

function CustomReadOnlyPagination(
  selectedReadOnlyPage,
  onChangeSelectedReadOnlyPage,
  readOnlyPageSize,
  onChangeReadOnlyPageSize,
  readOnlyPageAmount,
  totalAttributes,
) {
  return (
    <div className="w-[100%] flex justify-between gap-4">
      <div className="flex flex-row items-center ml-2">
        <Button
          variant="contained"
          className="mr-2 max-h-11"
          disabled
        >
          Update
        </Button>
        <Button
          variant="outlined"
          className="max-h-11"
          disabled
        >
          Cancel
        </Button>
      </div>
      <div className="flex flex-row items-center">
        <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center mt-5 mb-0 sm:mt-0">
          <Typography variant="body2" className="mr-5">Rows per page:</Typography>
          <Select
            value={readOnlyPageSize}
            onChange={onChangeReadOnlyPageSize}
            autoWidth
            size="small"
            variant="standard"
          >
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={400}>400</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={readOnlyPageAmount}
          page={selectedReadOnlyPage}
          onChange={onChangeSelectedReadOnlyPage}
          size="small"
        />
        <div className="flex items-center justify-center mr-4">
          <Tooltip title="Total Attributes" arrow placement="top">
            <p>{totalAttributes}</p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

function Attributes() {
  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language?.language?.LanguageId);

  const styles = useStyles();

  const [openProductTypesModal, setOpenProductTypesModal] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [editableLoading, setEditableLoading] = React.useState(false);
  const [readOnlyLoading, setReadOnlyLoading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [productTypes, setProductTypes] = React.useState([]);
  const [selectedProductTypes, setSelectedProductTypes] = React.useState([]);
  const [initiallySelectedProductTypes, setInitiallySelectedProductTypes] = React.useState([]);
  const [changedAttributes, setChangedAttributes] = React.useState([]);
  const [editableFilterValue, setEditableFilterValue] = React.useState('');
  const [readOnlyFilterValue, setReadOnlyFilterValue] = React.useState('');

  const [totalEditableRows, setTotalEditableRows] = React.useState(0);
  const [editableRowCountState, setEditableRowCountState] = React.useState(totalEditableRows);
  const [totalReadOnlyRows, setTotalReadOnlyRows] = React.useState(0);
  const [readOnlyRowCountState, setReadOnlyRowCountState] = React.useState(totalEditableRows);
  const [selectedEditablePage, setSelectedEditablePage] = React.useState(1);
  const [selectedReadOnlyPage, setSelectedReadOnlyPage] = React.useState(1);
  const [editablePageSize, setEditablePageSize] = React.useState(50);
  const [readOnlyPageSize, setReadOnlyPageSize] = React.useState(50);
  const [editablePageAmount, setEditablePageAmount] = React.useState(0);
  const [readOnlyPageAmount, setReadOnlyPageAmount] = React.useState(0);

  const tabs = ['Editable', 'Read Only'];

  const [editableAttributes, setEditableAttributes] = React.useState([]);
  const [readOnlyAttributes, setReadOnlyAttributes] = React.useState([]);

  function addChangedAttr(obj, originalVal) {
    const existingIndex = changedAttributes.findIndex(
      (attr) => attr.productAttributeDefKey === obj.productAttributeDefKey,
    );
    // Remove obj if new value matches original value
    if (existingIndex !== -1) {
      if (obj.value === '-no-value-' && originalVal === '') {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes.splice(existingIndex, 1);
        setChangedAttributes(updatedAttributes);
        return;
      }
      // if (obj.attributeDataTypeId === 1) {
      //   if (obj === originalVal) {
      //     const updatedAttributes = [...changedAttributes];
      //     updatedAttributes.splice(existingIndex, 1);
      //     setChangedAttributes(updatedAttributes);
      //     return;
      //   }
      // }
      if (JSON.stringify(obj.value) === JSON.stringify(originalVal)) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes.splice(existingIndex, 1);
        setChangedAttributes(updatedAttributes);
        return;
      }
      if (obj.attributeDataTypeId === 8) {
        if (obj.value === originalVal) {
          const updatedAttributes = [...changedAttributes];
          updatedAttributes.splice(existingIndex, 1);
          setChangedAttributes(updatedAttributes);
          return;
        }
      }
      if (obj.attributeDataTypeId === 9) {
        if (obj === originalVal) {
          const updatedAttributes = [...changedAttributes];
          updatedAttributes.splice(existingIndex, 1);
          setChangedAttributes(updatedAttributes);
          return;
        }
      }
    }

    // Checkbox True False
    if (obj.attributeDataTypeId === 11) {
      if (existingIndex !== -1) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes[existingIndex] = obj;
        setChangedAttributes(updatedAttributes);
      } else {
        setChangedAttributes([...changedAttributes, obj]);
      }
    }
    // Checkbox Yes No or 0,1
    if (obj.attributeDataTypeId === 1) {
      if (existingIndex !== -1) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes[existingIndex] = obj;
        setChangedAttributes(updatedAttributes);
      } else {
        setChangedAttributes([...changedAttributes, obj]);
      }
    }
    // TextField's
    if (obj.attributeDataTypeId === 2 || obj.attributeDataTypeId === 3 || obj.attributeDataTypeId === 4 || obj.attributeDataTypeId === 6 || obj.attributeDataTypeId === 7) {
      if (existingIndex !== -1) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes[existingIndex] = obj;
        setChangedAttributes(updatedAttributes);
      } else {
        setChangedAttributes([...changedAttributes, obj]);
      }
    }

    // Single Autocompletes
    if (obj.attributeDataTypeId === 8) {
      if (existingIndex !== -1) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes[existingIndex] = obj;
        setChangedAttributes(updatedAttributes);
      } else {
        setChangedAttributes([...changedAttributes, obj]);
      }
    }

    // Multi Autocompletes
    if (obj[0]?.attributeDataTypeId === 9) {
      const newAttributes = obj.map((attribute) => {
        // Check if the object has the field "Val"
        if ('Val' in attribute) {
          // Create a new object without the "Val" field
          const { Val, ...newAttribute } = attribute;
          return newAttribute;
        }
        return attribute;
      });
      if (JSON.stringify(newAttributes) === JSON.stringify(originalVal)) {
        const updatedAttributes = changedAttributes.filter(
          (attr) => attr.productAttributeDefKey !== obj[0].productAttributeDefKey,
        );
        setChangedAttributes(updatedAttributes);
        return;
      }

      const filteredMultiAttributes = changedAttributes.filter((attr) => !obj.some((newValue) => newValue.productAttributeDefId === attr.productAttributeDefId));

      obj.forEach((object) => {
        filteredMultiAttributes.push(object);
      });

      setChangedAttributes(filteredMultiAttributes);
    }

    if (obj?.attributeDataTypeId === 9) {
      if (existingIndex !== -1) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes[existingIndex] = obj;
        setChangedAttributes(updatedAttributes);
      } else {
        setChangedAttributes([...changedAttributes, obj]);
      }
    }

    // Datepicker
    if (obj.attributeDataTypeId === 5) {
      if (existingIndex !== -1) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes[existingIndex] = obj;
        setChangedAttributes(updatedAttributes);
      } else {
        setChangedAttributes([...changedAttributes, obj]);
      }
    }

    // Quill's
    if (obj.attributeDataTypeId === 10) {
      if (existingIndex !== -1) {
        const updatedAttributes = [...changedAttributes];
        updatedAttributes[existingIndex] = obj;
        setChangedAttributes(updatedAttributes);
      } else {
        setChangedAttributes([...changedAttributes, obj]);
      }
    }
  }

  function fetchEditableData() {
    setEditableLoading(true);
    if (selectedProductTypes.length > 0) {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: selectedProductTypes,
        name: editableFilterValue,
      })
        .then((res) => {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setEditableAttributes(res.data.Data);
          setEditablePageAmount(res.data.Pagination.PageCount);
          setTotalEditableRows(res.data.Pagination.TotalCount);
        })
        .then(() => {
          setEditableLoading(false);
        });
    } else {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: [0],
        name: editableFilterValue,
      })
        .then((res) => {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setEditableAttributes(res.data.Data);
          setEditablePageAmount(res.data.Pagination.PageCount);
          setTotalEditableRows(res.data.Pagination.TotalCount);
        })
        .then(() => {
          setEditableLoading(false);
        });
    }
  }

  React.useEffect(() => {
    setEditableLoading(true);
    setReadOnlyLoading(true);
    const editableBody = {
      productTypes: [
        0,
      ],
      name: '',
    };
    const readOnlyBody = {
      name: '',
    };
    postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, editableBody)
      .then((res) => {
        if (res) {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setEditableAttributes(res.data.Data);
          setEditablePageAmount(res.data.Pagination.PageCount);
          setTotalEditableRows(res.data.Pagination.TotalCount);
        }
      });

    postReadOnly(id, siteId, languageId, selectedReadOnlyPage, readOnlyPageSize, readOnlyBody)
      .then((res) => {
        if (res) {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setReadOnlyAttributes(res.data.Data);
          setReadOnlyPageAmount(res.data.Pagination.PageCount);
          setTotalReadOnlyRows(res.data.Pagination.TotalCount);
        }
      });

    getProductTypes(siteId)
      .then((res) => {
        setProductTypes(res.data);
      });
    getProductsTypes(id, siteId, languageId)
      .then((res) => {
        const ids = res.data.map((productType) => productType.ProductTypeId);
        setInitiallySelectedProductTypes(ids);
        setSelectedProductTypes(ids);
      });
  }, [id]);

  React.useEffect(() => {
    const found = editableAttributes.filter((attr) => changedAttributes.some((changedAttr) => attr.ProductAttributeDefId === changedAttr.productAttributeDefId));
    if (found) {
      editableAttributes.forEach((attr) => {
        changedAttributes.forEach((changedAttr) => {
          if (attr.ProductAttributeDefId === changedAttr.productAttributeDefId) {
            attr.AttributeValues.push({
              Id: changedAttr?.Val ? changedAttr.value : 0,
              Value: changedAttr.Val ? changedAttr.Val : changedAttr.value,
              Level: 'Changed',
            });
          }
        });
      });
    }
  }, [editableAttributes]);

  React.useEffect(() => {
    setEditableLoading(true);
    setSelectedEditablePage(1);
    if (selectedProductTypes.length > 0) {
      postEditable(id, siteId, languageId, 1, 50, {
        productTypes: selectedProductTypes,
        name: editableFilterValue,
      })
        .then((res) => {
          if (res.data.length === 0) {
            toast.error('No Attribute found for selected Product Type');
            setEditableAttributes([]);
          } else {
            const m = new Map();
            for (let i = 0; i < res.data.Data.length; i++) {
              const element = res.data.Data[i];
              m.set(element.AttributeDataTypeId, element.Description);
            }
            setEditableAttributes(res.data.Data);
            setEditablePageAmount(res.data.Pagination.PageCount);
            setTotalEditableRows(res.data.Pagination.TotalCount);
          }
        })
        .then(() => {
          setEditableLoading(false);
        });
    } else {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: [
          0,
        ],
        name: editableFilterValue,
      })
        .then((res) => {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setEditableAttributes(res.data.Data);
          setEditablePageAmount(res.data.Pagination.PageCount);
          setTotalEditableRows(res.data.Pagination.TotalCount);
        })
        .then(() => {
          setEditableLoading(false);
        });
    }
  }, [selectedProductTypes]);

  React.useEffect(() => {
    setEditableLoading(true);
    setReadOnlyLoading(true);

    if (editableFilterValue.length > 0) {
      setSelectedEditablePage(1);
    }

    if (selectedProductTypes.length > 0) {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: selectedProductTypes,
        name: editableFilterValue,
      })
        .then((res) => {
          if (res.data.length < 1) {
            setEditableAttributes([]);
          } else {
            const m = new Map();
            for (let i = 0; i < res.data.Data.length; i++) {
              const element = res.data.Data[i];
              m.set(element.AttributeDataTypeId, element.Description);
            }
            setEditableAttributes(res.data.Data);
            setEditablePageAmount(res.data.Pagination.PageCount);
            setTotalEditableRows(res.data.Pagination.TotalCount);
          }
        })
        .then(() => {
          setEditableLoading(false);
        });
    } else {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: [
          0,
        ],
        name: editableFilterValue,
      })
        .then((res) => {
          if (res.data.length < 1) {
            setEditableAttributes([]);
          } else {
            const m = new Map();
            for (let i = 0; i < res.data.Data.length; i++) {
              const element = res.data.Data[i];
              m.set(element.AttributeDataTypeId, element.Description);
            }
            setEditableAttributes(res.data.Data);
            setEditablePageAmount(res.data.Pagination.PageCount);
            setTotalEditableRows(res.data.Pagination.TotalCount);
          }
        })
        .then(() => {
          setEditableLoading(false);
        });
    }

    if (editableFilterValue === '') {
      setSelectedEditablePage(1);
    }
  }, [editableFilterValue]);

  React.useEffect(() => {
    setReadOnlyLoading(true);

    if (readOnlyFilterValue.length > 0) {
      setSelectedReadOnlyPage(1);
    }

    postReadOnly(id, siteId, languageId, selectedReadOnlyPage, readOnlyPageSize, {
      name: readOnlyFilterValue,
    })
      .then((res) => {
        if (res.data.length < 1) {
          setReadOnlyAttributes([]);
        } else {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setReadOnlyAttributes(res.data.Data);
          setReadOnlyPageAmount(res.data.Pagination.PageCount);
          setTotalReadOnlyRows(res.data.Pagination.TotalCount);
        }
      })
      .then(() => {
        setReadOnlyLoading(false);
      });

    if (readOnlyFilterValue === '') {
      setSelectedReadOnlyPage(1);
    }
  }, [readOnlyFilterValue]);

  React.useEffect(() => {
    setEditableRowCountState((prev) => (totalEditableRows !== undefined ? totalEditableRows : prev));
  }, [totalEditableRows, setEditableRowCountState]);

  React.useEffect(() => {
    setReadOnlyRowCountState((prev) => (totalReadOnlyRows !== undefined ? totalReadOnlyRows : prev));
  }, [totalReadOnlyRows, setReadOnlyRowCountState]);

  React.useEffect(() => {
    setEditableLoading(true);
    setEditableAttributes([]);
    if (selectedProductTypes.length > 0) {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: selectedProductTypes,
        name: editableFilterValue,
      })
        .then((res) => {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setEditableAttributes(res.data.Data);
          setEditablePageAmount(res.data.Pagination.PageCount);
          setTotalEditableRows(res.data.Pagination.TotalCount);
        })
        .then(() => {
          setEditableLoading(false);
        });
    } else {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: [
          0,
        ],
        name: editableFilterValue,
      })
        .then((res) => {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setEditableAttributes(res.data.Data);
          setEditablePageAmount(res.data.Pagination.PageCount);
          setTotalEditableRows(res.data.Pagination.TotalCount);
        })
        .then(() => {
          setEditableLoading(false);
        });
    }
  }, [selectedEditablePage, editablePageSize]);

  React.useEffect(() => {
    setReadOnlyLoading(true);
    postReadOnly(id, siteId, languageId, selectedReadOnlyPage, readOnlyPageSize, {
      name: readOnlyFilterValue,
    })
      .then((res) => {
        if (res) {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setReadOnlyAttributes(res.data.Data);
          setReadOnlyPageAmount(res.data.Pagination.PageCount);
          setTotalReadOnlyRows(res.data.Pagination.TotalCount);
        }
      })
      .then(() => {
        setReadOnlyLoading(false);
      });
  }, [selectedReadOnlyPage, readOnlyPageSize]);

  React.useEffect(() => {
    setEditableLoading(true);
    setReadOnlyLoading(true);
    setEditableAttributes([]);
    setReadOnlyAttributes([]);
    if (selectedProductTypes.length > 0) {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: selectedProductTypes,
        name: editableFilterValue,
      })
        .then((res) => {
          if (res.data.length < 1) {
            setEditableAttributes([]);
          } else {
            const m = new Map();
            for (let i = 0; i < res.data.Data.length; i++) {
              const element = res.data.Data[i];
              m.set(element.AttributeDataTypeId, element.Description);
            }
            setEditableAttributes(res.data.Data);
            setEditablePageAmount(res.data.Pagination.PageCount);
            setTotalEditableRows(res.data.Pagination.TotalCount);
          }
        })
        .then(() => {
          setEditableLoading(false);
        });
    } else {
      postEditable(id, siteId, languageId, selectedEditablePage, editablePageSize, {
        productTypes: [
          0,
        ],
        name: editableFilterValue,
      })
        .then((res) => {
          if (res.data.length < 1) {
            setEditableAttributes([]);
          } else {
            const m = new Map();
            for (let i = 0; i < res.data.Data.length; i++) {
              const element = res.data.Data[i];
              m.set(element.AttributeDataTypeId, element.Description);
            }
            setEditableAttributes(res.data.Data);
            setEditablePageAmount(res.data.Pagination.PageCount);
            setTotalEditableRows(res.data.Pagination.TotalCount);
          }
        })
        .then(() => {
          setEditableLoading(false);
        });
    }
    postReadOnly(id, siteId, languageId, selectedReadOnlyPage, readOnlyPageSize, {
      name: readOnlyFilterValue,
    })
      .then((res) => {
        if (res.data.length < 1) {
          setReadOnlyAttributes([]);
        } else {
          const m = new Map();
          for (let i = 0; i < res.data.Data.length; i++) {
            const element = res.data.Data[i];
            m.set(element.AttributeDataTypeId, element.Description);
          }
          setReadOnlyAttributes(res.data.Data);
          setReadOnlyPageAmount(res.data.Pagination.PageCount);
          setTotalReadOnlyRows(res.data.Pagination.TotalCount);
        }
      })
      .then(() => {
        setReadOnlyLoading(false);
      });
  }, [languageId]);

  const onChangeEditablePageSize = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalEditableRows / newItemsPerPage);
    const newPage = Math.min(selectedEditablePage, newPageCount);
    setEditablePageSize(newItemsPerPage);
    setSelectedEditablePage(newPage);
  };

  const onChangeReadOnlyPageSize = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalReadOnlyRows / newItemsPerPage);
    const newPage = Math.min(selectedEditablePage, newPageCount);
    setReadOnlyPageSize(newItemsPerPage);
    setSelectedEditablePage(newPage);
  };

  const onChangeSelectedEditablePage = (event, newPage) => {
    setSelectedEditablePage(newPage);
  };

  const onChangeSelectedReadOnlyPage = (event, newPage) => {
    setSelectedReadOnlyPage(newPage);
  };

  const updateAttributes = () => {
    const updatedAttributes = changedAttributes.map((attribute) => {
      // Check if the object has the field "Val"
      if ('Val' in attribute) {
        // Create a new object without the "Val" field
        const { Val, ...newAttribute } = attribute;
        return newAttribute;
      }
      return attribute;
    });
    const body = {
      attributesValues: updatedAttributes,
    };

    postProductAttributes(siteId, id, languageId, body)
      .then(() => {
        toast.success('Attribute updated', { autoClose: 2000 });
      })
      .then(() => {
        setChangedAttributes([]);
        setEditableAttributes([]);
        fetchEditableData();
      })
      .catch(() => {
        toast.fail('Attribute update failed', { autoClose: 2000 });
      });
  };

  const cancelUpdates = () => {
    setChangedAttributes([]);
    setEditableAttributes([]);
    fetchEditableData();
  };

  const updateProductAttributes = () => {
    setModalLoading(true);
    patchProductTypes(id, siteId, selectedProductTypes)
      .then(() => {
        toast.success('Product types updated', { autoClose: 2000 });
        setModalLoading(false);
        setOpenProductTypesModal(false);
      })
      .then(() => {
        getProductsTypes(id, siteId, languageId)
          .then((res) => {
            const ids = res.data.map((productType) => productType.ProductTypeId);
            setInitiallySelectedProductTypes(ids);
            setSelectedProductTypes(ids);
          });
      })
      .catch(() => {
        toast.fail('Product types update failed', { autoClose: 2000 });
      });
  };

  return (
    <div className="flex flex-col w-full h-[calc(100vh-100px)] lg:flex-row justify-center">
      <div className="w-full lg:w-[80%] mb-5">
        <div className="flex flex-col justify-between w-full mb-5 md:flex-row">
          {selectedTab === 1
            ? (
              <>
                <Autocomplete
                  multiple
                  options={productTypes}
                  getOptionLabel={(option) => option.Name}
                  value={productTypes.Name}
                  filterSelectedOptions
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product Type"
                      placeholder="Product Type"
                    />
                  )}
                  size="small"
                  className="w-[100%] md:min-w-[300px] md:max-w-[300px] mb-3 md:mb-0"
                />
                <TextField
                  size="small"
                  label="Filter"
                  value={readOnlyFilterValue}
                  onChange={(e) => setReadOnlyFilterValue(e.target.value)}
                />
              </>
            )
            : (
              <>
                <div className="flex flex-row">
                  <Autocomplete
                    multiple
                    id="product-type"
                    options={productTypes}
                    getOptionLabel={(option) => option?.Name}
                    value={selectedProductTypes.map((productId) => productTypes.find((type) => type.ProductTypeId === productId))}
                    onChange={(event, selectedOptions) => {
                      if (selectedOptions) {
                        setSelectedProductTypes(selectedOptions.map((option) => option.ProductTypeId));
                      } else {
                        setSelectedProductTypes([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Product Type"
                        placeholder="Select product type"
                      />
                    )}
                    size="small"
                    className="w-[100%] md:min-w-[300px] md:max-w-[300px] mb-3 md:mb-0 mr-3"
                  />
                  {JSON.stringify(initiallySelectedProductTypes) !== JSON.stringify(selectedProductTypes) && (
                    <Tooltip title="This will update the product types for this product" arrow placement="bottom">
                      <Button
                        variant="contained"
                        className="mr-2 max-h-11"
                        onClick={() => setOpenProductTypesModal(true)}
                      >
                        Update
                      </Button>
                    </Tooltip>
                  )}
                  <Modal
                    open={openProductTypesModal}
                    onClose={() => setOpenProductTypesModal(false)}
                    buttonOnClick={updateProductAttributes}
                    buttonLabel="Update"
                    title={`Update product types for product ${id}`}
                  >
                    {modalLoading ? (
                      <div className="flex items-center justify-center h-16">
                        <CircularProgress color="primary" />
                      </div>
                    ) : (
                      <p>
                        {`Are you sure you want to update the product types for product ${id}?`}
                      </p>
                    )}
                  </Modal>
                </div>
                <TextField
                  size="small"
                  label="Filter"
                  value={editableFilterValue}
                  onChange={(e) => setEditableFilterValue(e.target.value)}
                />
              </>
            )}
        </div>
        <Tabs
          value={selectedTab}
          onChange={(event, newTab) => setSelectedTab(newTab)}
          classes={{ indicator: styles.indicator, root: styles.selected }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          className="flex flex-row"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab}
              label={tab}
              classes={{
                root: styles.tabRoot,
                selected: styles.selected,
              }}
              sx={{ maxWidth: 'none' }}
            />
          ))}
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <Box sx={{
            height: 'calc(100vh - 250px)', width: '100%', background: 'white', overflow: 'auto',
          }}
          >
            <DataGrid
              disableVirtualization
              rows={editableAttributes}
              loading={editableLoading}
              disableRowSelectionOnClick
              getRowId={(row) => row.ProductAttributeDefId}
              columns={[
                {
                  field: 'Name',
                  headerName: 'Name',
                  flex: 1,
                  renderCell: (params) => (
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body1">{params.value}</Typography>
                      <Typography variant="body2" color="success" className="text-success-700">
                        (
                        {params.row.ProductAttributeDefId}
                        )
                      </Typography>
                      <Typography variant="body1" color="success" className="text-2xl text-error-700">
                        {params.row.Required ? '*' : ''}
                      </Typography>
                    </Stack>
                  ),
                },
                {
                  field: 'AttributeDataTypeId',
                  headerName: 'Value',
                  flex: 1,
                  // eslint-disable-next-line react/jsx-no-bind
                  renderCell: (params) => <DisplayInput {...params} addChangedAttr={addChangedAttr} />,
                },
              ]}
              components={{
                Pagination: () => CustomEditablePagination(
                  selectedEditablePage,
                  onChangeSelectedEditablePage,
                  editablePageSize,
                  onChangeEditablePageSize,
                  editablePageAmount,
                  totalEditableRows,
                  updateAttributes,
                  cancelUpdates,
                  changedAttributes,
                ),
              }}
              pageSizeOptions={[50, 100, 200, 400]}
              rowsPerPageOptions={[50, 100, 200, 400]}
              rowCount={editableRowCountState}
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 100}
              density="compact"
              sx={{ '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '12px' } }}
            />
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Box sx={{
            height: 'calc(100vh - 250px)', width: '100%', background: 'white', overflow: 'auto',
          }}
          >
            <DataGrid
              rows={readOnlyAttributes}
              loading={readOnlyLoading}
              disableRowSelectionOnClick
              getRowId={(row) => row.ProductAttributeDefId}
              columns={[
                {
                  field: 'Name',
                  headerName: 'Name',
                  flex: 1,
                  renderCell: (params) => (
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body1">{params.value}</Typography>
                      <Typography variant="body2" color="success" className="text-success-700">
                        (
                        {params.row.ProductAttributeDefId}
                        )
                      </Typography>
                    </Stack>
                  ),
                },
                {
                  field: 'AttributeDataTypeId',
                  headerName: 'Value',
                  flex: 1,
                  // eslint-disable-next-line react/jsx-no-bind
                  renderCell: (params) => <DisplayReadOnlyInput {...params} />,
                },
              ]}
              components={{
                Pagination: () => CustomReadOnlyPagination(
                  selectedReadOnlyPage,
                  onChangeSelectedReadOnlyPage,
                  readOnlyPageSize,
                  onChangeReadOnlyPageSize,
                  readOnlyPageAmount,
                  totalReadOnlyRows,
                ),
              }}
              pageSizeOptions={[50, 100, 200, 400]}
              rowsPerPageOptions={[50, 100, 200, 400]}
              rowCount={readOnlyRowCountState}
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 100}
              density="compact"
              sx={{ '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '12px' } }}
            />
          </Box>
        </TabPanel>
      </div>
    </div>
  );
}

export default Attributes;
