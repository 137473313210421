import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';

function ConfirmationModal({
  open, onClose, onConfirm, newSites, oldSites,
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        width: 500, // Increased width for less cramped text
        bgcolor: 'background.paper',
        p: 4,
        m: 'auto',
        mt: '20%',
        borderRadius: 2, // Added rounded corners
      }}
      >
        {oldSites && oldSites.length > 0 && (
          <Typography sx={{ mt: 2 }}>
            Attribute already exists in:
            {' '}
            <ul>
              {oldSites.map((site) => (
                <li key={site.SiteId}>{site.SiteName}</li>
              ))}
            </ul>
          </Typography>
        )}
        <Typography sx={{ mt: 2 }}>
          {oldSites && oldSites.length > 0
            ? 'Create duplicate attribute def for below facias ?'
            : 'Create attribute def for below facias ?'}
          {' '}
          {' '}
          <ul>
            {newSites.map((site) => (
              <li key={site.SiteId}>{site.SiteName}</li>
            ))}
          </ul>
        </Typography>

        <Box sx={{ mt: 6, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="secondary" onClick={onClose} sx={{ mr: 1 }}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>Submit</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfirmationModal;
