import { FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { toast } from 'react-toastify';

import getSites from '../../../api/intergration/pim/sites/gets/getSites';
import postAddUser from '../../../api/private/users_management/posts/postAddUser';
import Modal from '../../../components/Modal';

function NewUserModal({
  open,
  handleClose,
  userTypesMapping,
  userRightsMapping,
  apiAccessTypesMapping,
}) {
  /**
  {
    "accountName": "string", // first.last
    "userType": 1, // JdplcUser, ApiUser
    "userRight": 10, // Admin, Dev, Finance etc
    "siteIds": [
      0
    ],
    "status": 0, // New user, Edited user (Handled by the API)
    "apiAccessType": 1, // PublicApi, PrivateApi, None
    "userName": "string" // Email Address
  }
  */
  // eslint-disable-next-line no-control-regex
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [userRight, setUserRight] = React.useState(null);
  const [userType, setUserType] = React.useState(null);
  const [chosenSites, setChosenSites] = React.useState([]);
  const [apiAccessType, setApiAccessType] = React.useState(null);

  const [sites, setSites] = React.useState([]);

  const [errorExists, setErrorExists] = React.useState(false);

  const disableCreateButton = () => {
    const emptyUsername = username?.length === 0;
    const emptyUserType = userType == null;
    const emptyUserRight = userRight == null;
    const emptyChosenSites = chosenSites?.length === 0;
    const emptyApiAccessType = apiAccessType == null;
    const emptyEmail = email?.length === 0;

    return emptyUsername
      || emptyUserType
      || emptyUserRight
      || emptyChosenSites
      || emptyApiAccessType
      || emptyEmail
      || errorExists;
  };

  const addUser = async () => {
    await postAddUser(username, userType, userRight, chosenSites, 0, apiAccessType, email)
      .then(() => {
        handleClose(false);
        toast.success('Successfully created user');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const sitesCall = async () => {
    await getSites(1, 100)
      .then((res) => setSites(res.data.Data))
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    sitesCall();
  }, []);

  // * This has been commented out as it overwrites what the user enters.
  // When user enters their email it will take the characters before the @ and use it as their
  // account name.
  // React.useEffect(() => {
  //   const getStringBeforeSymbol = email.match(/([^@]+)/);
  //   const user = getStringBeforeSymbol || '';
  //   const uncheckedUsername = user[0];
  //   const validUsername = uncheckedUsername?.replace(/[^A-Za-z]+/g, '');

  //   setUsername(validUsername);
  // }, [email]);

  React.useEffect(() => {
    if ((!email.includes('@jdplc.com') && !email.includes('@cosmossport.gr')) && userType === 1) {
      setErrorExists(true);
    } else {
      setErrorExists(false);
    }
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title="Create New User"
      buttonLabel="CREATE"
      buttonOnClick={() => addUser()}
      disableButton={disableCreateButton()}
    >
      <Stack spacing={3}>
        <TextField
          label="Email"
          fullWidth
          type="email"
          error={!(email.match(emailRegex))}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            label="Account Name"
            fullWidth
            value={username}
            InputLabelProps={{ shrink: username?.length > 0 }}
            onChange={(event) => setUsername(event.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="user-right">User Right</InputLabel>
            <Select
              labelId="user-right"
              label="User Right"
              value={userRight}
              onChange={(event) => setUserRight(event.target.value)}
            >
              {(Object.entries(userRightsMapping)).map(([key, value]) => (
                <MenuItem value={value}>{key}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Autocomplete
          multiple
          id="tags-standard"
          options={sites}
          getOptionLabel={(option) => (`${option.Fascia} ${option.CountryCode}`)}
          onChange={(event, value) => {
            const siteIdArray = value.map((obj) => obj.SiteId);
            setChosenSites(siteIdArray);
          }}
          renderInput={(params) => <TextField {...params} label="Sites" />}
        />
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth error={!email.includes('@jdplc.com') && userType === 1}>
            <InputLabel id="user-type">User Type</InputLabel>
            <Select
              labelId="user-type"
              label="User Type"
              value={userType}
              onChange={(event) => setUserType(event.target.value)}
            >
              {(Object.entries(userTypesMapping)).map(([key, value]) => (
                <MenuItem value={value}>{key}</MenuItem>
              ))}
            </Select>
            {(!email.includes('@jdplc.com') && !email.includes('@cosmossport.gr')) && userType === 1 && (
              <FormHelperText>
                JdplcUser account can only be applied for @jdplc.com or @cosmossport.gr emails.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="access-type">Access Type</InputLabel>
            <Select
              labelId="access-type"
              label="Access Type"
              value={apiAccessType}
              onChange={(event) => setApiAccessType(event.target.value)}
            >
              {(Object.entries(apiAccessTypesMapping)).map(([key, value]) => (
                <MenuItem value={value}>{key}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Modal>
  );
}

export default NewUserModal;
