import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a single attribute to multiple products.
 *
 * @param {{
 *  referenceIds: string[],
 *  productAttributeDefId: number,
 *  productAttributeLookupOptionId: number[],
 *  languageId: number,
 *  sourceId: number,
 *  siteId: number,
 *  siteGroupId: number
 * }}
 * @param {string[]} referenceIds Product Ids
 * @param {number} productAttributeDefId Attribute Id
 * @param {number[]} productAttributeLookupOptionId Lookup Option Id
 * @param {number} sourceId Source Id - Example, 1 for Shogun
 * @param {number} languageId Language Id
 * @param {number} siteId Site Id
 * @param {number} siteGroupId Id of the Site Group
*/
export default function postSingleSelectedAttributesPlu({
  referenceIds,
  productAttributeDefId,
  productAttributeLookupOptionId,
  sourceId,
  languageId,
  siteId,
  siteGroupId,
}) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/single-selected-attributes/plu-id/${sourceId}`;

  const body = {
    referenceIds,
    productAttributeDefId,
    productAttributeLookupOptionId,
    languageId,
    siteId,
    siteGroupId,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Single Selected Attributes (PLU)', 'Started : Single Selected Attributes : PLU (Update)')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
