import postRequest from '../../../../../postRequest';
/**
 * Bulk upload a Categories to multiple products.
 *
 * @param {{
 *  productId: number,
 *  categoryIds: number[],
 *  siteId: number,
 * }}
 * @param {int} productId Product Ids
 * @param {number[]} categoryIds Category Ids
 * @param {number} siteId Site Id
*/
export default function postCategoriesProductId({
  productId,
  categoryIds,
  siteId,
}) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/assign-categories-to-product`;

  const body = {
    categoryIds,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Assign Categories')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

//   return new Promise((resolve, reject) => {
//     postRequest(endpoint, body, 'Categories (ProductId)', 'Started : Categories : Prod ID (Update)')
//       .then((response) => resolve(response))
//       .catch((error) => reject(error));
//   });
}
