import patchRequest from '../../../../patchRequest';
/**
 * Updates the product type.
 * @param {number} siteId
 * @param {string} productTypeName
 * @param {string} departmentCode
 * @param {boolean} isDefault
 * @param {number[]} attributeDefIds
 * @returns {Promise} { Success: true }, status: 200 }
 */
export default async function patchProductType(
  siteId,
  productTypeId,
  productTypeName,
  departmentCode,
  isDefault,
  attributeDefIds,
) {
  const endpoint = `/oc-data-management/v1/product-type/${productTypeId}/${siteId}`;

  const body = {
    siteId,
    productTypeName,
    departmentCode,
    isDefault,
    attributeDefIds,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update product attribute')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
