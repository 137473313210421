import DateTimePicker from '@mui/lab/DateTimePicker';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import Modal from '../../../../components/Modal';

function EditModal({
  open,
  onCloseModal,
  selectedRow,
  onChange,
}) {
  const [date, setDate] = useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setDate(newValue);
    onChange(date);
  };

  return (
    <Modal
      open={open}
      title="Edit New-In Date"
      buttonLabel="Save"
      buttonOnClick={() => onCloseModal(false)}
      onClose={() => onCloseModal(false)}
      containerWidth="xs"
    >
      <Box className="flex flex-col items-center gap-8 -mt-2 mb-2">
        <Box className="flex flex-col items-center">
          <Typography id="dates-modal-plu" variant="body1" gutterBottom>
            <b>Product ID</b>
            {' '}
            -
            {' '}
            {selectedRow?.productId}
          </Typography>
          <b>PLU - Source</b>
          <Typography id="dates-modal-source" variant="body1">
            {selectedRow?.plu}
          </Typography>
        </Box>
        <Box className="flex justify-center">
          <DateTimePicker
            label="Date&Time Picker"
            value={selectedRow?.newInDate}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default EditModal;
