import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import VirtualizeBrandSelect from '../../../VirtualizedBrandsAutocomplete';

function BrandsFilter() {
  const [, setSearchParams] = useSearchParams();

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Brands</Typography>
        <div className="flex flex-col w-[215px]">
          <VirtualizeBrandSelect
            id="brand-virtualized-autocomplete"
            multiple
            limitTags={2}
            className="max-w-300"
            label="Brands"
            helperText="Select one or multiple"
            size="small"
            onChange={(e, v) => {
              setSearchParams((params) => {
                params.set('brands', v.map((obj) => obj.BrandId));
                if (v.length === 0) params.delete('brands');
                return params;
              });
            }}
          />
        </div>
      </Stack>
    </div>
  );
}

export default BrandsFilter;
