/* eslint-disable no-nested-ternary */
import Chip from '@mui/material/Chip';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

import CircularProgressWithLabel from '../../../CircularProgressWithLabel';

const ProcessingTabRow = memo(({ data, index, style }) => {
  const obj = data[index];

  const operationTypes = useSelector((state) => state.bulk_operations.operation_types);

  const handleOperationName = (bulkOperationRequestType) => {
    const foundMatchingOperation = Object.keys(operationTypes).find((key) => operationTypes[key] === bulkOperationRequestType);
    if (foundMatchingOperation) {
      return foundMatchingOperation;
    }
    return 'Operation Type Not Found';
  };
  const handleCrudType = (crudType) => {
    switch (crudType) {
      case 1:
        return 'Insert';
      case 2:
        return 'Read';
      case 8:
        return 'Update';
      case 4:
        return 'Delete';
      default:
        return 'CRUD Type Not Found';
    }
  };

  return (
    <section key={obj.Id} className="h-28" style={style}>
      <div className="relative flex items-center w-full gap-2 pb-2 mb-4 border-b border-neutral-400 group">
        <div className="flex flex-col w-2/3 gap-1 my-1">
          <p className="text-sm">
            ID:
            {' '}
            {obj.Id}
          </p>
          <p className="text-sm">
            Author:
            {' '}
            {obj.UserName}
          </p>
          <p className="text-sm whitespace-pre-wrap">
            Operation:
            {' '}
            {handleOperationName(obj.BulkOperationRequestType)}
          </p>
          <p className="text-sm whitespace-nowrap">
            Type:
            {' '}
            {handleCrudType(obj.CrudType)}
          </p>
          <p className="text-sm">
            Started:
            {' '}
            {new Date(obj.InsertDate).toLocaleString('en-GB')}
          </p>
        </div>
        <div className="flex items-center justify-center w-1/3">
          {obj.Status === 2 ? (
            <Chip label="Queueing" size="small" color="warning" sx={{ fontWeight: 900 }} />
          ) : (
            <CircularProgressWithLabel index={obj.Index} total={obj.Total} />
          )}
        </div>
      </div>
    </section>
  );
}, areEqual);

export default ProcessingTabRow;
