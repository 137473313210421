import postRequest from '../../../../../../postRequest';

/**
 * Calls categories endpoint that gets existing categories in PIM DB.
 *
 * @param {number} siteId - 1
 * @param {number} size - 100
 * @param {boolean} selectOnlyMapped - Mapped categories with no null values.
 * @returns {Promise} { data: {}, status: 200 }
 */
async function getMarginThresholdLogs(bulkOperationRequestType, statuses) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/reports/${bulkOperationRequestType}`;

  const body = {
    statusList: statuses,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Getting MarginThreshold Logs')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getMarginThresholdLogs;
