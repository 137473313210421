import getRequest from '../../../../getRequest';

/**
 * Get language details.

 */
export default async function getLanguageDetails() {
  const endpoint = '/oc-data-management/v1/colourMatching';
  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Language Details')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
