/* eslint-disable no-nested-ternary */
import LinkIcon from '@mui/icons-material/Link';
import PreviewIcon from '@mui/icons-material/Preview';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import getFredhopperData from '../../../../../api/oc_product/product/fredhopper/gets/getFredhopperData';
import getOms from '../../../../../api/oc_product/product/oms/gets/getOms';
import patchVariants from '../../../../../api/oc_product/variants/patches/patchVariants';
import Image from '../../../../../components/image/Image';
import Modal from '../../../../../components/Modal';
import isProduction from '../../../../../helper/IsProduction';
import parseDateString from '../../../../../utils/parseDateString';
import { ProductContext } from '../ProductContext';

function OmsModal({
  open,
  onClose,
  data,
}) {
  return (
    <Modal desktopModalWidth="60%" open={open} onClose={onClose}>
      <section className="h-[calc(100vh-200px)] overflow-auto flex flex-col gap-8">
        <div>
          <h2 className="mb-2 text-xl font-gothammedium">Product Details:</h2>
          <div className="flex flex-col gap-4">
            {data?.OmsProduct?.Results.map((obj) => (
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-8">
                  <div>
                    <label htmlFor="ProductRef" className="text-sm font-gothammedium">
                      Product Id:
                    </label>
                    <p id="ProductRef">{obj.ProductRef}</p>
                  </div>
                  <div>
                    <label htmlFor="ProductId" className="text-sm font-gothammedium">
                      OMS Product Id:
                    </label>
                    <p id="ProductId">{obj.ProductId}</p>
                  </div>
                  <div>
                    <label htmlFor="Name" className="text-sm font-gothammedium">
                      Name:
                    </label>
                    <p id="Name">{obj.Name}</p>
                  </div>
                </div>
                <table className="border">
                  <thead className="border-b bg-neutral-50">
                    <tr>
                      <th className="px-2 border-r">
                        Attribute
                      </th>
                      <th className="px-2 border-r">
                        Values
                      </th>
                      <th className="px-2 border-r">
                        Category Id&apos;s
                      </th>
                      <th className="px-2 border-r">
                        Created
                      </th>
                      <th className="px-2">
                        Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {obj.Attributes?.length > 0 ? (
                      <tr>
                        <td className="border-r">
                          <table className="w-full">
                            <tbody>
                              {obj.Attributes.map((x) => (
                                <tr className="border-b last:border-none">
                                  <td className="p-2">
                                    {x.Name}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                        <td className="border-r">
                          <table className="w-full ">
                            <tbody>
                              {obj.Attributes.map((x) => (
                                <tr className="border-b last:border-none">
                                  <td className="p-2">
                                    {x.Value?.length > 0 ? x.Value : <span className="opacity-0">N/A</span>}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                        <td className="px-2 border-r">
                          {obj.Categories?.map((str) => <p>{str}</p>)}
                        </td>
                        <td className="px-2 border-r">
                          {parseDateString(obj.CreatedOn)}
                        </td>
                        <td className="px-2">
                          {obj.UpdatedOn !== null ? parseDateString(obj.UpdatedOn) : ''}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">No Data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h2 className="mb-2 text-xl font-gothammedium">SKU Details:</h2>
          <div className="flex flex-col gap-4">
            <table className="w-full border">
              <thead className="w-full border-b bg-neutral-50">
                <tr>
                  <th className="w-1/4 px-2 border-r">
                    Sku
                  </th>
                  <th className="w-3/4 px-2">
                    Attributes
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.SkuDetails?.map((obj) => (
                  <tr className="even:bg-neutral-50">
                    <td className="px-2 border-r">
                      {obj.SkuId}
                    </td>
                    <td className="px-2">
                      <table className="w-full">
                        {obj.Attributes.map((x) => (
                          <tr className="border-b">
                            <td className="w-1/2 px-2 py-1 border-r">{x.Name}</td>
                            <td className="w-1/2 px-2 py-1">{x.Value}</td>
                          </tr>
                        ))}
                      </table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Modal>
  );
}

function ApprovedModal({
  open,
  onClose,
  patchProductVariant,
  productId,
  approvedState,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      buttonOnClick={patchProductVariant}
      buttonLabel={`${approvedState ? 'Un-approve' : 'Approve'}`}
      title={`${approvedState ? 'Un-approve' : 'Approve'} product ${productId}?`}
    >
      <p>
        {`This will also ${approvedState ? 'un-approve' : 'approve'} all variants in this product.`}
      </p>
    </Modal>
  );
}

function FredhopperModal({
  open,
  onClose,
  data,
}) {
  const handleValueRender = (value) => {
    if (Array.isArray(value)) {
      return (
        <div>
          {value?.map((obj) => <p>{obj['#text']}</p>)}
        </div>
      );
    }
    if (typeof value === 'object') {
      return value['#text'];
    }
    return value;
  };

  const [fredhopperImage, setFredhopperImage] = React.useState('');

  const [similarItemFields, setSimilarItemFields] = React.useState({});
  const [itemAttributes, setItemAttributes] = React.useState({});

  React.useEffect(() => {
    setFredhopperImage(data?.data.item.attribute.find((obj) => obj['@name'] === '_imageurl')?.value);
    const similarItemFieldsAttribute = data?.data.item['similar-item-fields'].attribute;
    setSimilarItemFields({ isArray: Array.isArray(similarItemFieldsAttribute), data: similarItemFieldsAttribute });
    const itemAttributesData = data?.data.item.attribute;
    setItemAttributes({ isArray: Array.isArray(itemAttributesData), data: itemAttributesData });
  }, [data]);

  return (
    <Modal open={open} onClose={onClose} desktopModalWidth="75%">
      <legend className="text-sm font-gothammedium">FH Identifier:</legend>
      <span>{data?.data.item['@id']}</span>
      <div className="w-full h-[calc(100%-50px)] mt-2 overflow-auto border rounded">
        <table className="w-full">
          <thead className="sticky top-0">
            <tr className="border-b bg-neutral-50">
              <th className="py-1">Name</th>
              <th className="py-1">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b">
              <td className="px-3 py-2 border-r">Image</td>
              <td className="px-3 py-2 break-all">
                <Image src={fredhopperImage} alt="product" width="150" height="150" />
              </td>
            </tr>
            {similarItemFields.isArray ? similarItemFields.data.map((obj) => (
              <tr className="border-b">
                <td className="px-3 py-2 border-r">{obj['@name']}</td>
                <td className="px-3 py-2 break-all whitespace-pre-wrap">
                  {handleValueRender(obj.value)}
                </td>
              </tr>
            )) : (
              <tr className="border-b">
                <td className="px-3 py-2 border-r">{similarItemFields.data?.['@name']}</td>
                <td className="px-3 py-2 break-all whitespace-pre-wrap">
                  {handleValueRender(similarItemFields.data?.value)}
                </td>
              </tr>
            )}
            {itemAttributes.isArray ? itemAttributes.data.map((obj) => (
              <tr className="border-b">
                <td className="px-3 py-2 border-r">{obj['@name']}</td>
                <td className="px-3 py-2 break-all whitespace-pre-wrap">
                  {handleValueRender(obj.value)}
                </td>
              </tr>
            )) : (
              <tr className="border-b">
                <td className="px-3 py-2 border-r">{itemAttributes.data?.['@name']}</td>
                <td className="px-3 py-2 break-all whitespace-pre-wrap">
                  {handleValueRender(itemAttributes.data?.value)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

function ProductStatus({
  status,
  isInOms,
  approved,
  parentLoading,
}) {
  const { id } = useParams();

  const {
    enrichmentRerender,
    setEnrichmentRerender,
    historyReRender,
    setHistoryReRender,
    variantReRender,
    setVariantReRender,
  } = React.useContext(ProductContext);

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language?.language?.LanguageId);
  const isProd = isProduction();

  const [openApprovedModal, setOpenApprovedModal] = React.useState(false);
  const [approveLoading, setApproveLoading] = React.useState(false);
  const [approvedState, setApprovedState] = React.useState();
  const [fredhopperData, setFredhopperData] = React.useState({});
  const [openFredhopperModal, setOpenFredhopperModal] = React.useState(false);
  const [omsData, setOmsData] = React.useState({});
  const [openOmsModal, setOpenOmsModal] = React.useState(false);
  const [productUrl, setProductUrl] = React.useState();

  const patchProductVariant = () => {
    setApproveLoading(true);
    patchVariants(id, siteId, !approvedState)
      .then(() => {
        setApprovedState(!approvedState);
        setEnrichmentRerender(!enrichmentRerender);
        setVariantReRender(!variantReRender);
        setHistoryReRender(!historyReRender);
      })
      .catch((error) => console.error(error))
      .finally(() => setApproveLoading(false));
    setOpenApprovedModal(false);
  };

  React.useEffect(() => {
    if (isInOms) {
      getOms(id, siteId, languageId)
        .then((res) => setOmsData(res?.data))
        .catch((err) => console.error(err));
    }
  }, [isInOms, id]);

  React.useEffect(() => {
    if (approved === true || approved === false) {
      setApprovedState(approved);
    }
  }, [approved]);

  React.useEffect(() => {
    const fredhopperUrl = fredhopperData?.data?.item?.attribute.find((attr) => attr['@name'] === 'product_url')?.value;
    if (fredhopperUrl) {
      setProductUrl(fredhopperUrl);
    }
  }, [fredhopperData]);

  React.useEffect(() => {
    getFredhopperData(id, siteId)
      .then((res) => setFredhopperData(res.data))
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    getFredhopperData(id, siteId)
      .then((res) => setFredhopperData(res.data))
      .catch((err) => console.error(err));
  }, [id]);
  return (
    <div className="h-full">
      <Typography variant="body1" gutterBottom className="font-gothammedium">Product Status</Typography>
      <table className="mt-3 h-3/4">
        <tbody>
          <tr>
            <td className="pb-4">
              <Typography variant="body2">Status:</Typography>
            </td>
            <td className="pb-4 pl-4">
              {parentLoading ? <Skeleton width="8rem" /> : (
                <p className={`text-sm text-${status ? 'success' : 'error'}-700 font-gothammedium`}>
                  {status ? 'Active' : 'Inactive'}
                </p>
              )}
            </td>
          </tr>
          <tr>
            <td className="pb-4">
              <Typography variant="body2">Approved:</Typography>
            </td>
            <td className="pb-4 pl-4">
              {parentLoading ? <Skeleton width="8rem" /> : (
                <>
                  <Chip
                    size="small"
                    classes={{ label: 'font-gothammedium' }}
                    label={approvedState ? 'Approved' : 'Unapproved'}
                    color={approvedState ? 'success' : 'error'}
                    variant="outlined"
                    icon={
                      approveLoading ? <CircularProgress size="1rem" thickness={6} className="ml-1" />
                        : approvedState ? <ToggleOnIcon />
                          : <ToggleOffIcon />
                    }
                    onClick={() => setOpenApprovedModal(true)}
                    style={{ minWidth: '80px' }}
                  />
                  <ApprovedModal
                    open={openApprovedModal}
                    onClose={() => setOpenApprovedModal(false)}
                    patchProductVariant={patchProductVariant}
                    productId={id}
                    approvedState={approvedState}
                  />
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="pb-4">
              <Typography variant="body2">OMS:</Typography>
            </td>
            <td className="pb-4 pl-4">
              {parentLoading ? <Skeleton width="8rem" /> : Object.keys(omsData).length > 0 ? (
                <>
                  <Chip
                    size="small"
                    classes={{ label: 'font-gothammedium' }}
                    label="Open"
                    color="success"
                    variant="outlined"
                    icon={<PreviewIcon />}
                    onClick={() => setOpenOmsModal(true)}
                    style={{ minWidth: '80px' }}
                  />
                  <OmsModal
                    open={openOmsModal}
                    onClose={() => setOpenOmsModal(false)}
                    data={omsData}
                  />
                </>
              ) : (
                <p className="text-sm text-error-700 font-gothammedium">N/A</p>
              )}
            </td>
          </tr>
          <tr>
            <td className="pb-4">
              <Typography variant="body2">On Site:</Typography>
            </td>
            <td className="pb-4 pl-4">
              {parentLoading ? <Skeleton width="8rem" /> : productUrl ? (
                <Tooltip title={isProd ? 'View this product on the live site' : 'This product is on site'}>
                  {isProd ? (
                    <Chip
                      size="small"
                      classes={{ label: 'font-gothammedium' }}
                      label="View"
                      color="success"
                      variant="outlined"
                      icon={<LinkIcon />}
                      onClick={() => window.open(productUrl, '_blank')}
                      style={{ minWidth: '80px' }}
                    />
                  ) : (
                    <p className="text-sm text-success-700 font-gothammedium">
                      On Site
                    </p>
                  )}
                </Tooltip>
              ) : (
                <p className="text-sm text-error-700 font-gothammedium">Not On Site</p>
              )}
            </td>
          </tr>
          <tr>
            <Typography variant="body2">Fredhopper:</Typography>
            <td className="pl-4">
              {parentLoading ? <Skeleton width="8rem" /> : Object.values(fredhopperData).length ? (
                <>
                  <Chip
                    size="small"
                    classes={{ label: 'font-gothammedium' }}
                    label="Open"
                    color="success"
                    variant="outlined"
                    icon={<PreviewIcon />}
                    onClick={() => setOpenFredhopperModal(true)}
                    style={{ minWidth: '80px' }}
                  />
                  <FredhopperModal
                    open={openFredhopperModal}
                    onClose={() => setOpenFredhopperModal(false)}
                    data={fredhopperData}
                  />
                </>
              ) : (
                <p className="text-sm text-error-700 font-gothammedium">Not In Searchendiser</p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ProductStatus;
