import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
  Pagination, Stack, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import postAttributeLogs from '../../../../../api/oc_product/productDataManagement/attributes/post/postAttributeLogs';
import parseDateString from '../../../../../utils/parseDateString';
import { AttributeDefinitionsContext } from '../../AttributeDefinitionsContext';

function AttributeLogs() {
  const { reRenderLogs, setReRenderLogs } = useContext(AttributeDefinitionsContext);
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [username, setUsername] = useState(''.toLowerCase());
  const [resetData, setResetData] = useState(false);
  const [logs, setLogs] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [expandedLogs, setExpandedLogs] = useState([]);
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const { id } = useParams();

  const fetchLogs = async () => {
    const logsData = await postAttributeLogs(
      username,
      currentPage,
      itemsPerPage,
    );

    setLogs(logsData.data.Data);
    setTotalPages(logsData.data.Pagination.PageCount);
    setTotalRows(logsData.data.Summary.TotalRowCount);
    setReRenderLogs(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = () => {
    fetchLogs();
    setResetData(false);
  };

  const handleFilterReset = () => {
    setUsername('');
    setResetData(true);
  };

  const handleChangeItemsPerPage = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(currentPage, newPageCount);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newPage);
  };

  const handleExpandClick = (logId) => {
    setExpandedLogs((prevExpandedLogs) => {
      if (prevExpandedLogs.includes(logId)) {
        return prevExpandedLogs.filter((log) => log !== logId);
      }
      return [...prevExpandedLogs, logId];
    });
  };
  const handleCloseLogs = () => {
    setIsLogsOpen(false);
  };

  const handleOpenLogs = () => {
    setIsLogsOpen(true);
  };

  React.useEffect(() => {
    if (username.length > 0) {
      setUsername('');
    }
    if (setIsLogsOpen === false) {
      if (username.length > 0) {
        setUsername('');
      }
    }
    fetchLogs();
    // setReFetchLogs(true);
  }, [siteId, isLogsOpen]);

  React.useEffect(() => {
    fetchLogs();
  }, [currentPage, itemsPerPage, id]);

  React.useEffect(() => {
    if (resetData) {
      fetchLogs();
    }
  }, [resetData]);

  React.useEffect(() => {
    if (reRenderLogs) {
      fetchLogs();
    }
  }, [reRenderLogs]);

  return (
    <div className="relative mt-20">
      {!isLogsOpen ? (
        <div className="h-[125px] z-10 absolute right-[5px] top-[35%]">
          <a
            href={handleOpenLogs}
            onClick={handleOpenLogs}
            className="h-full items-center text-1xl flex break-all text-center rounded text-white bg-[#217ABA] p-1.5 font-black cursor-pointer"
          >
            LOGS
          </a>
        </div>
      ) : (
        <div className="h-[25px] w-[25px] bg-[#737373] flex justify-center items-center rounded-full z-10 absolute right-[10px] top-[50%]">
          <ArrowForwardIosIcon
            className="text-white cursor-pointer"
            onClick={handleCloseLogs}
          />
        </div>
      )}
      <Box
        className="h-[calc(100vh-180px)] border rounded w-[95%] bg-neutral-100 relative
        transition-all ease-in-out duration-300"
        style={{
          right: isLogsOpen ? '0' : '-100%',
        }}
      >
        {isLogsOpen && (
          <section className="h-full overflow-scroll">
            <div className="p-2 m-1 bg-white border rounded-lg shadow-md">
              <div className="flex flex-col items-center sm:flex-row">
                <TextField
                  label="Name"
                  variant="outlined"
                  value={username}
                  size="small"
                  onChange={(event) => setUsername(event.target.value)}
                  className="w-[250px]"
                />
                <Button
                  onClick={handleSearch}
                  variant="contained"
                  className="mt-[16px] sm:mt-0 sm:ml-[16px] text-sm pl-[22px] pr-[22px]"
                  disabled={username === ''}
                >
                  Search
                </Button>
                <Button
                  onClick={handleFilterReset}
                  variant="text"
                  className="text-primary-600 mt-[16px] sm:mt-0 sm:ml-[5px] text-sm"
                >
                  Reset
                </Button>
              </div>
            </div>

            <div className="pt-2 pb-12">
              {logs.length > 0 ? (
                <Timeline
                  className="mb-10"
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 'none',
                      width: '110px',
                    },
                  }}
                >
                  {logs.map((log) => {
                    const jsonStartIndex = log.Message.indexOf('[');
                    const jsonEndIndex = log.Message.lastIndexOf(']') + 1;
                    const nonJsonMessage = log.Message.substring(0, jsonStartIndex);
                    const jsonString = log.Message.substring(jsonStartIndex, jsonEndIndex);

                    if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
                      try {
                        const isExpanded = expandedLogs.includes(log.Created);
                        if (jsonString.includes('{')) {
                          const parsedJson = JSON.parse(jsonString);
                          return (
                            <TimelineItem key={log.created}>
                              <TimelineOppositeContent style={{ padding: '2px 16px 6px 16px' }}>
                                <Stack>
                                  <Typography variant="body2" style={{ color: '#6f7686' }}>
                                    {parseDateString(log.Created)}
                                  </Typography>
                                </Stack>
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                                <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                              </TimelineSeparator>
                              <TimelineContent style={{ padding: '0 16px 6px 16px' }} className="break-words max-w-[400px]">
                                <Typography>{nonJsonMessage}</Typography>
                                {isExpanded ? (
                                  <>
                                    <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                                      Show less
                                    </Typography>
                                    <pre className="whitespace-pre-wrap">{JSON.stringify(parsedJson, null, 2)}</pre>
                                  </>
                                ) : (
                                  <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                                    Show more
                                  </Typography>
                                )}
                                <Typography className="flex items-center" variant="body2" style={{ color: '#6f7686' }}>
                                  <PersonOutlineIcon style={{ color: '#6f7686', marginRight: '5px' }} fontSize="small" />
                                  {' '}
                                  {log.UserName}
                                </Typography>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                        return (
                          <TimelineItem key={log.created}>
                            <TimelineOppositeContent style={{ padding: '2px 16px 6px 16px' }}>
                              <Stack>
                                <Typography variant="body2" style={{ color: '#6f7686' }}>
                                  {parseDateString(log.Created)}
                                </Typography>
                              </Stack>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                              <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                            </TimelineSeparator>
                            <TimelineContent style={{ padding: '0 16px 6px 16px' }} className="break-words max-w-[400px]">
                              <Typography>{nonJsonMessage}</Typography>
                              {isExpanded ? (
                                <>
                                  <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                                    Show less
                                  </Typography>
                                  <pre className="whitespace-pre-wrap">{JSON.stringify(jsonString, null, 2)}</pre>
                                </>
                              ) : (
                                <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                                  Show more
                                </Typography>
                              )}
                              <Typography className="flex items-center" variant="body2" style={{ color: '#6f7686' }}>
                                <PersonOutlineIcon style={{ color: '#6f7686', marginRight: '5px' }} fontSize="small" />
                                {' '}
                                {log.UserName}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        );
                      } catch (error) {
                        console.error('Error parsing JSON:', error);
                        return null;
                      }
                    } else {
                      return (
                        <TimelineItem key={log.created}>
                          <TimelineOppositeContent style={{ padding: '2px 16px 6px 16px' }}>
                            <Stack>
                              <Typography variant="body2" style={{ color: '#6f7686' }}>
                                {parseDateString(log.Created)}
                              </Typography>
                            </Stack>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                            <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                          </TimelineSeparator>
                          <TimelineContent style={{ padding: '0 16px 6px 16px' }}>
                            <Typography>{log.Message}</Typography>
                            <Typography className="flex items-center break-all min-w-[165px]" variant="body2" style={{ color: '#6f7686' }}>
                              <PersonOutlineIcon style={{ color: '#6f7686', marginRight: '5px' }} fontSize="small" />
                              {' '}
                              {log.UserName}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    }
                  })}
                </Timeline>
              ) : <Typography className="pl-4">No results found</Typography>}
            </div>
            <div className="flex flex-col items-center gap-6 p-2 pl-4 bg-white border rounded-lg sm:flex-row w-[98%]
          absolute bottom-0 right-0 m-1 shadow-md"
            >
              <FormControl sx={{ minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center">
                <Typography variant="body2" className="mr-4">Rows per page:</Typography>
                <Select
                  labelId="logs-per-page"
                  value={itemsPerPage}
                  onChange={handleChangeItemsPerPage}
                  autoWidth
                  size="small"
                  variant="standard"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePage}
              />
            </div>
          </section>
        )}
      </Box>
    </div>
  );
}

export default AttributeLogs;
