import 'react-tridi/dist/index.css';

import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Tridi from 'react-tridi';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`media-tabpanel-${index}`}
      aria-labelledby={`media-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ backgroundColor: 'white', height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((() => ({
  tabRoot: {
    width: '33.333%',
  },
})));

function CustomMediaDisplay({
  images,
  video,
  spinSets,
  // setImages,
  // setVideo,
  // setSpinSets,
  // generalTabData,
}) {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [imagePagination, setImagePagination] = React.useState(0);
  const [parentImages, setParentImages] = React.useState([]);

  const styles = useStyles();

  React.useEffect(() => {
    setParentImages(images);
  }, [images]);

  // const getMedia = async () => {
  //   const mediaSetLink = generalTabData?.Media?.MediaSetLink;
  //   if (mediaSetLink) {
  //     const httpsMediaSetLink = mediaSetLink.slice(0, 5) === 'http:' ? `https${mediaSetLink.slice(4)}` : mediaSetLink;
  //     const mediaResponse = await axios.get(httpsMediaSetLink);
  //     const productImages = mediaResponse.data.items.filter((obj) => obj.type === 'img');
  //     setImages(productImages);
  //     const productVideos = mediaResponse.data.items.find((obj) => obj.type === 'video');
  //     setVideo(productVideos);
  //     const productSpinSet = mediaResponse.data.items.find((obj) => obj.type === 'set');
  //     if (productSpinSet) {
  //       const spinSetSrc = productSpinSet?.src;
  //       const httpsSpinSet = spinSetSrc?.slice(0, 5) === 'http:' ? `https${spinSetSrc.slice(4)}` : spinSetSrc;
  //       const jsonSpinSet = httpsSpinSet?.includes('.json') ? httpsSpinSet : `${httpsSpinSet}.json`;
  //       if (jsonSpinSet) {
  //         const spinSetResponse = await axios.get(jsonSpinSet);
  //         setSpinSets(spinSetResponse.data);
  //       }
  //     }
  //   }
  // };

  return (
    <Box className="w-full overflow-hidden border rounded h-max">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          className="bg-white"
        >
          <Tab
            label="Images"
            classes={{
              root: styles.tabRoot,
            }}
          />
          <Tab
            label="Video"
            classes={{
              root: styles.tabRoot,
            }}
          />
          <Tab
            label="Spin Set"
            classes={{
              root: styles.tabRoot,
            }}
          />
        </Tabs>
      </Box>
      {/* Images */}
      <TabPanel value={selectedTab} index={0}>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="px-12 h-2/3">
            {parentImages.length > 0 ? (
              <img
                src={`https${parentImages[imagePagination]?.src?.slice(4) || '://cdn.media.amplience.net/i/jpl/imagemissing'}`}
                alt="product"
              // className="h-full"
              />
            ) : (
              <p className="py-4 mx-auto text-center">No Images Available</p>
            )}
          </div>
          <div className="h-1/3">
            {parentImages.length > 0 ? (
              <div className="flex justify-center flex-1 py-2">
                <Pagination
                  count={parentImages.length}
                  onChange={(e, v) => setImagePagination((v - 1))}
                  siblingCount={0}
                />
              </div>
            ) : (
              <p> </p>
            )}
          </div>
        </div>
      </TabPanel>
      {/* Video */}
      <TabPanel value={selectedTab} index={1}>
        <div className="flex items-center justify-center p-2">
          {video?.src ? (
            <video controls>
              <track default kind="captions" />
              <source src={`${video.src}/mp4_720p`} type="video/mp4" />
              Sorry your browser does not support HTML5 video.
            </video>
          ) : (
            <p>No Video Available</p>
          )}
        </div>
      </TabPanel>
      {/* Spin Set */}
      <TabPanel value={selectedTab} index={2}>
        <div className="flex items-center justify-center p-2">
          {spinSets.items ? (
            <Tridi
              images={spinSets.items?.map((obj) => obj.src)}
              hintOnStartup
              hintText="Drag To View"
              autoplaySpeed={80}
              showControlBar
              className="pb-12"
            />
          ) : (
            <p>No Spin Set Available</p>
          )}
        </div>
      </TabPanel>
    </Box>
  );
}

export default CustomMediaDisplay;
