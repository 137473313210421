// import CancelIcon from '@mui/icons-material/Cancel';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import patchVariantStockEnabled from '../../../../../api/oc_product/product/style/patches/patchVariantStockEnabled';
import Modal from '../../../../../components/Modal';
import theme from '../../../../../theme';
import { ProductContext } from '../ProductContext';

function EnableStock({ locations, parentLoading }) {
  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const {
    variantReRender, setVariantReRender, historyReRender, setHistoryReRender,
  } = useContext(ProductContext);

  const [locationsData, setLocationsData] = React.useState();
  const [locationIds, setLocationIds] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (locations.length > 0) {
      setLocationsData(locations);
    }
  }, [locations]);

  const patchVariants = () => {
    setLoading(true);

    patchVariantStockEnabled(id, siteId, null, locationIds, action)
      .then((response) => {
        const activeCountFromResponse = response.data.Locations
          .filter((location) => locationIds.includes(location.LocationId))
          .map((location) => location.ActiveCount);

        if (response?.data.VariantFailureMessages.length > 0) {
          let multipleErrors = '';
          response?.data.VariantFailureMessages.forEach((stringValue) => {
            if (stringValue != null) {
              multipleErrors += `\n${stringValue}`;
            }
          });
          toast.error(`${'Updated Stock enabled failed'}: ${multipleErrors}`);
        }
        toast.success('Variants stock enabled updated', { autoClose: 2000 });
        setVariantReRender(!variantReRender);
        setHistoryReRender(!historyReRender);
        setLoading(false);
        setOpenModal(false);
        // Create a copy of the locationsData array to modify it
        const updatedLocationsData = locationsData.map((location) => {
          const shouldUpdate = locationIds.includes(location.LocationId);

          if (shouldUpdate) {
            if (action) {
              // When action is true
              return {
                ...location,
                Status: true,
                ActiveCount: activeCountFromResponse,
              };
            }
            // When action is false
            return {
              ...location,
              Status: false,
              ActiveCount: 0,
            };
          }
          return location; // No change needed for this location
        });

        // Update the state with the modified locationsData
        setLocationsData(updatedLocationsData);
      })
      .catch(() => {
        toast.error('Variants stock enabled update failed', { autoClose: 2000 });
        setVariantReRender(!variantReRender);
        setHistoryReRender(!historyReRender);
        setLoading(false);
        setOpenModal(false);
      });
  };

  return (
    <div className="flex flex-col items-start justify-center w-full px-4">
      <div>
        <Typography variant="body1" className="font-gothammedium">
          Enable Stock
        </Typography>
      </div>

      <div className="flex justify-center w-full my-2 mt-3">
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            size="small"
            color="success"
            sx={{ borderColor: theme.palette.success[700], color: theme.palette.success[700] }}
            onClick={() => {
              setLocationIds(locations.map((item) => item.LocationId));
              setOpenModal(true);
              setAction(true);
            }}
          >
            All On
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            sx={{ borderColor: theme.palette.error[700], color: theme.palette.error[700] }}
            onClick={() => {
              setLocationIds(locations.map((item) => item.LocationId));
              setOpenModal(true);
              setAction(false);
            }}
          >
            All Off
          </Button>
        </Stack>
      </div>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: 2 }} />

      {parentLoading ? <Skeleton width="12rem" height="4rem" /> : (
        <Stack direction="row" flexWrap="wrap" sx={{ width: '100%' }}>
          {locationsData?.map((obj) => (
            <div key={obj.LocationId} className={`${locations.length === 1 ? 'w-full' : 'w-1/2'} border-4 border-transparent`}>
              <Tooltip
                title={(
                  <p className="text-sm">
                    {obj.LocationRef.replace(/\s*\(.*?\)\s*/g, '')}
                    {' '}
                    (
                    {obj.ActiveCount}
                    /
                    {obj.TotalCount}
                    {' '}
                    enabled
                    )
                  </p>
                )}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setLocationIds([obj.LocationId]);
                    if (obj.Status) {
                      setAction(false);
                    } else {
                      setAction(true);
                    }
                    setOpenModal(true);
                  }}
                  sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                  color={obj.Status ? 'success' : 'error'}
                  fullWidth
                >
                  {obj.Name}
                </Button>
              </Tooltip>
            </div>
          ))}

        </Stack>
      )}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Status Change"
        buttonLabel="Confirm"
        buttonOnClick={patchVariants}
      >
        {loading ? (
          <div className="flex items-center justify-center h-16">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Typography className="mb-4">
            Are you sure you want to change the status?
          </Typography>
        )}
      </Modal>
    </div>
  );
}

export default EnableStock;
