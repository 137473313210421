import deleteRequest from '../../../deleteRequest';

/**
 * Deletes brand group.
 *
 * @param {string} siteId - 1
 * @param {string} brandGroupId - 1
 * @returns {Promise} { Success: true }, status: 200 }
 */
async function deleteBrandGroup(siteId, brandGroupId) {
  const endpoint = `/oc-data-management/v1/brand-group/${brandGroupId}/${siteId}`;

  const errorMessageHeader = `Delete ${brandGroupId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteBrandGroup;
