import getRequest from '../../../getRequest';

/**
 * Get all users from PRISM db.
 *
 * @returns {object}
 */
function getAllUsers() {
  const endpoint = '/users-management/get-all-users/';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get All Users')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getAllUsers;
