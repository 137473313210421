import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import getMarginThresholds from '../../../api/oc_product/productDataManagement/marginThreshold/get/getMarginThresholds';
import Row from './Row';

function DropdownsContainer() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getMarginThresholds();
      if (data) {
        setRows(data.data.Data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [siteId]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {loading ? (
        <div className="flex items-center justify-center h-16">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableBody>
              {rows.length > 0 && (
                rows.map((row, index) => (
                  <Row key={row.id} row={row} fetchData={fetchData} backgroundColor={index % 2 === 0 ? '#ffffff' : '#f2f2f2'} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default DropdownsContainer;
