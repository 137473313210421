import postRequest from '../../../../../postRequest';

/**
 * Calls sites endpoint to get existing sites.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @returns {Promise} { data: { Data: [{}], Pagination: {} }, status: 200 }
 */
async function postSites(productId, siteCode) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/sites';

  const body = {
    productId,
    siteCode,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Sites')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postSites;
