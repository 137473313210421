// import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import Table from '../../../components/Table';
import EditModal from './EditModal';

function createData(
  productId,
  varientId,
  source,
  sku,
  size,
  barcode,
  rrp,
  sell,
  cost,
  actions,
) {
  return {
    productId,
    varientId,
    source,
    sku,
    size,
    barcode,
    rrp,
    sell,
    cost,
    actions,
  };
}
function Pricing() {
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState('');
  const handleOpen = (id) => {
    setProduct(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const columns = [
    { id: 'productId', label: 'Product ID' },
    { id: 'varientId', label: 'Varient ID' },
    { id: 'source', label: 'Source' },
    { id: 'sku', label: 'SKU' },
    { id: 'size', label: 'Size' },
    { id: 'barcode', label: 'Bar Code' },
    { id: 'rrp', label: 'RRP' },
    { id: 'sell', label: 'Sell' },
    { id: 'cost', label: 'Cost' },
    { id: 'actions', label: '' },
  ];

  const apiData = [
    {
      productId: '444',
      varientId: '135521',
      source: 'Shogun',
      sku: '000986569',
      size: 'UK-7/EU-40/3',
      barcode: '4069322682391',
      rrp: 75,
      sell: 75,
      cost: 30.4,
    },
    {
      productId: '444',
      varientId: '135521',
      source: 'Shogun',
      sku: '000986569',
      size: 'UK-7/EU-40/3',
      barcode: '4069322682391',
      rrp: 75,
      sell: 75,
      cost: 30.4,
    },
    {
      productId: '444',
      varientId: '135521',
      source: 'Shogun',
      sku: '000986569',
      size: 'UK-7/EU-40/3',
      barcode: '4069322682391',
      rrp: 75,
      sell: 75,
      cost: 30.4,
    },
  ];

  const rows = apiData.map((row) => createData(
    row.productId,
    row.varientId,
    row.source,
    row.sku,
    row.size,
    row.barcode,
    row.rrp,
    row.sell,
    row.cost,
    <Button
      className="editButton"
      size="small"
      variant="outlined"
      color="warning"
      onClick={() => handleOpen(row.productId)}
    >
      Edit
    </Button>,
  ));

  return (
    <Container>
      <EditModal
        open={open}
        onCloseModal={() => handleClose()}
        product={product}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="w-full flex">
            <Typography variant="h4">Pricing</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" alignItems="center" marginY={2}>
            <TextField
              id="PLU-field"
              label="PLU"
            />
            <TextField
              id="SKU-field"
              label="SKU"
            />
            <TextField
              id="ProductID-field"
              label="Product ID"
            />
            <Button
              variant="contained"
              color="info"
            >
              Filter
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Table columns={columns} rows={rows} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Pricing;
