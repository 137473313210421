import postRequest from '../../../../postRequest';

/**
 * Product information. Designed for the PDP.
 * @param {number} productId - The product ID.
 * @param {number} siteId - The site ID.
 */
export default function getIndexDetails(productId, siteId) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/getIndexDetails`;
  return new Promise((resolve, reject) => {
    postRequest(endpoint)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
