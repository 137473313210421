import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import postMediaCheckReports from '../../../api/oc_product/bulk/bulk_process/sites/reports/posts/postMediaCheckReports';
import getMediaCheck from '../../../api/oc_product/product/mediaCheck/getMediaCheck';
import patchMediaCheck from '../../../api/oc_product/product/mediaCheck/patchMediaCheck';
import postMediaCheck from '../../../api/oc_product/product/mediaCheck/postMediaCheck';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import ExportButton from '../../../components/ExportButton';
import HasImageFilter from '../../../components/filter/filters/has_image/HasImageFilter';
import Image from '../../../components/image/Image';
import ProgressSideBarMediaCheck from '../../../components/progress_sidebar/ProgressSideBarMediaCheck';
import splitString from '../../../utils/splitIdString';
import CustomMediaDisplay from '../products/product/components/tabs/components/CustomMediaDisplay';
import CustomIdsFilter from './components/filters/CustomIdsFilter';
import HasSpinSetFilter from './components/filters/HasSpinSetFilter';
import HasVideoFilter from './components/filters/HasVideoFilter';

function CustomPagination(
  selectedPage,
  onChangeSelectedPage,
  pageSize,
  onChangePageSize,
  pageAmount,
  totalProducts,
) {
  return (
    <div className="flex items-center justify-end gap-4">
      <FormControl
        sx={{ m: 1, minWidth: 120 }}
        variant="standard"
        size="small"
        className="flex flex-row items-center mt-5 mb-0 sm:mt-0"
      >
        <Typography variant="body2" className="mr-5">
          Rows per page:
        </Typography>
        <Select
          value={pageSize}
          onChange={onChangePageSize}
          autoWidth
          size="small"
          variant="standard"
        >
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={pageAmount}
        page={selectedPage}
        onChange={onChangeSelectedPage}
        size="small"
      />
      <Typography variant="body2">Total:</Typography>
      <Typography variant="body2" className="mr-5">
        {totalProducts}
      </Typography>
    </div>
  );
}

function MediaCheck() {
  const siteId = useSelector(
    (state) => state.user.loginResponse.selectedSite.SiteId,
  );
  const siteCode = useSelector((state) => state.user.loginResponse.selectedSite.SiteCode);
  const [filterOpen, setFilterOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState([]);
  const [showMediaDisplay, setShowMediaDisplay] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filteredData, setFilteredData] = useState();
  const [siteSources, setSiteSources] = useState([]);

  const [inProgressData, setInProgressData] = React.useState([]);
  const handleRefreshReports = () => {
    postMediaCheckReports(siteId, [2, 3]) // "Active": 2, "Processing": 3,
      .then((res) => setInProgressData(res.data.reverse()))
      .catch((err) => console.error(err));
  };

  const pageNumber = searchParams.get('page');
  const pageSize = searchParams.get('pageSize');
  const [selectedPage, setSelectedPage] = useState(Number(pageNumber) || 1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(pageSize) || 15);
  const [pageAmount, setPageAmount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [firstLoadExample, setFirstLoadExample] = useState(true);

  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [spinSets, setSpinSets] = useState([]);

  const [ProductId, setProductId] = useState(null);
  const [partialLink, setPartialLink] = useState();
  const [mediaSetLink, setMediaSetLink] = useState();
  const [remoteId, setRemoteId] = useState();

  const idType = searchParams.get('idType');
  const ids = searchParams.get('ids');
  const hasImage = searchParams.get('hasImage');
  const hasVideo = searchParams.get('hasVideo');
  const hasSpinSet = searchParams.get('hasSpinSet');

  const productIds = idType === 'productIds' ? splitString(ids, 'number') : [];
  const plus = idType === 'plus' ? splitString(ids) : [];

  const [rows, setRows] = useState([]);
  const [pluColumns, setPluColumns] = useState([]);
  const [idsError, setIdsError] = React.useState(false);
  const [searchBtnDisabled, setSearchBtnDisable] = React.useState(true);

  const resetAllFilters = () => {
    setShowMediaDisplay(false);
    setSelected([]);
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
       * For some reason we have to do this...
       * If you do the, if not then delete, statement below within the for above
       * or do params.forEach
       * The loop with miss every even indexed key...
       */
      arr.forEach((key) => {
        if (
          key !== 'siteCode'
          && key !== 'page'
          && key !== 'omsId'
          && key !== 'pageSize'
        ) {
          params.delete(key);
        }
      });
      setRows([]);
      setTotalPages(0);
      setPageAmount(0);
      setTotalProducts(0);
      return params;
    });
  };

  const fetchMediaCheck = async () => {
    setFirstLoadExample(false);
    setFilterOpen(false);

    try {
      const mediaData = await postMediaCheck(
        siteId,
        siteCode,
        productIds,
        plus,
        hasImage,
        hasSpinSet,
        hasVideo,
        selectedPage,
        rowsPerPage,
      );

      const pluMapped = new Map();

      const relevantData = mediaData.data.Data || [];
      const mappedRows = relevantData.map((item, index) => {
        const newRow = {
          id: index + 1,
          ProductImage: item.ProductImage,
          ProductId: item.ProductId,
          // PluSource: item.PluSource?.map((pluItem) => `${pluItem.SourceShortDescription} - ${pluItem.RemoteSystemId}`).join(', ') || '',
          HasImage: item.HasImage ? 'Yes' : 'No',
          HasVideo: item.HasVideo ? 'Yes' : 'No',
          HasSpinSet: item.HasSpinSet ? 'Yes' : 'No',
        };
        const rowToMap = new Map(Object.entries(newRow));
        item.PluSource?.forEach((pluSource) => {
          rowToMap.set(
            pluSource.SourceShortDescription,
            pluSource.RemoteSystemId,
          );

          const foundSource = siteSources.find(
            (source) => source.ShortDescription === pluSource.SourceShortDescription,
          );
          if (foundSource) {
            pluMapped.set(foundSource.ShortDescription, foundSource.Name);
          }
        });

        const backToObject = Object.fromEntries(rowToMap);

        return backToObject;
      });

      const pluColumnsArray = [];
      pluMapped.forEach((value, key) => {
        switch (value) {
          case 'Shogun':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 70,
              flex: 1,
            });
            break;
          case value.includes('Fluent'):
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 80,
              flex: 1,
            });
            break;
          case 'AnatwineUK':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 100,
              flex: 1,
            });
            break;
          case 'PFA':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 40,
              flex: 1,
            });
            break;
          case 'ChannelAdvisor':
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 118,
              flex: 1,
            });
            break;
          default:
            pluColumnsArray.push({
              field: key,
              headerName: value,
              minWidth: 80,
              flex: 1,
            });
            break;
        }
      });

      setPluColumns(pluColumnsArray);

      const convertToBoolean = (value) => {
        if (value === null) {
          return null;
        }

        const mapping = {
          // eslint-disable-next-line quote-props
          yes: true,
          // eslint-disable-next-line quote-props
          no: false,
          // eslint-disable-next-line quote-props
          true: true,
          // eslint-disable-next-line quote-props
          false: false,
        };

        return mapping[value] || null;
      };

      const filterData = {
        siteId,
        siteCode,
        ProductImage: partialLink,
        productIds: idType === 'productIds' ? splitString(ids, 'number') : [],
        plus: idType === 'plus' ? splitString(ids) : [],
        HasImage: convertToBoolean(hasImage),
        HasVideo: convertToBoolean(hasVideo),
        HasSpinSet: convertToBoolean(hasSpinSet),
      };

      setRows(mappedRows);
      setFilteredData(filterData);
      setTotalPages(mediaData.data.Pagination.TotalCount);
      setPageAmount(mediaData.data?.Pagination.PageCount);
      setTotalProducts(mediaData.data?.Pagination.TotalRowCount);
    } catch (error) {
      console.error('Error fetching media check data:', error);
      throw error;
    }
  };

  const columns = [
    {
      field: 'ProductImage',
      headerName: 'Image',
      width: 70,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            key={params.value}
            src={params.value}
            height="45px"
            width="45px"
            alt="File not found"
          />
        </Box>
      ),
    },
    { field: 'ProductId', headerName: 'Product ID', width: 90 },
    ...pluColumns,
    {
      field: 'HasImage',
      headerName: 'Has Image',
      width: 90,
      renderCell: (params) => (params.value === 'Yes' ? (
        <CheckCircleIcon className="text-success-600" />
      ) : (
        <CancelIcon className="text-error-600" />
      )),
    },
    {
      field: 'HasVideo',
      headerName: 'Has Video',
      width: 90,
      renderCell: (params) => (params.value === 'Yes' ? (
        <CheckCircleIcon className="text-success-600" />
      ) : (
        <CancelIcon className="text-error-600" />
      )),
    },
    {
      field: 'HasSpinSet',
      headerName: 'Has Spin Set',
      width: 100,
      renderCell: (params) => (params.value === 'Yes' ? (
        <CheckCircleIcon className="text-success-600" />
      ) : (
        <CancelIcon className="text-error-600" />
      )),
    },
  ];

  const fetchMediaDisplayData = async () => {
    try {
      if (!ProductId) {
        console.error('ProductId is not defined.');
        return;
      }

      const mediaDisplayData = await getMediaCheck(
        siteId,
        ProductId,
        partialLink,
        mediaSetLink,
        remoteId,
      );

      const {
        ProductId: fetchedProductId,
        PartialLink: fetchedPartialLink,
        MediaSetLink: fetchedMediaSetLink,
        RemoteId: fetchedRemoteId,
      } = mediaDisplayData?.data || {};

      setProductId(fetchedProductId);
      setPartialLink(fetchedPartialLink);
      setMediaSetLink(fetchedMediaSetLink);
      setRemoteId(fetchedRemoteId);
    } catch (error) {
      console.error('Error fetching media check data:', error);
      throw error;
    }
  };

  const exportColumns = [
    {
      field: 'ProductImage',
      headerName: 'Image',
      width: 70,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            key={params.value}
            src={params.value}
            height="45px"
            width="45px"
            alt="File not found"
          />
        </Box>
      ),
    },
    { field: 'ProductId', headerName: 'Product ID', width: 90 },
    ...pluColumns,
    {
      field: 'HasImage',
      headerName: 'Has Image',
      width: 90,
      renderCell: (params) => (params.value === 'Yes' ? (
        <CheckCircleIcon className="text-success-600" />
      ) : (
        <CancelIcon className="text-error-600" />
      )),
    },
    {
      field: 'HasVideo',
      headerName: 'Has Video',
      width: 90,
      renderCell: (params) => (params.value === 'Yes' ? (
        <CheckCircleIcon className="text-success-600" />
      ) : (
        <CancelIcon className="text-error-600" />
      )),
    },
    {
      field: 'HasSpinSet',
      headerName: 'Has Spin Set',
      width: 100,
      renderCell: (params) => (params.value === 'Yes' ? (
        <CheckCircleIcon className="text-success-600" />
      ) : (
        <CancelIcon className="text-error-600" />
      )),
    },
  ];

  const handleSearch = async () => {
    fetchMediaCheck();
    setShowMediaDisplay(false);
    setSelected([]);
  };

  const onChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
    setShowMediaDisplay(false);
  };

  const onChangePageSize = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalPages / newItemsPerPage);
    const newPage = Math.min(selectedPage, newPageCount);
    setSearchParams((params) => {
      params.set('pageSize', newItemsPerPage);
      return params;
    });
    setRowsPerPage(newItemsPerPage);
    setSelectedPage(newPage);
  };

  const getDisplayMedia = async (rowData) => {
    try {
      const data = await getMediaCheck(siteId, rowData.ProductId);

      const mediaLink = data?.data.MediaSetLink;

      if (mediaLink !== 'https://cdn.media.amplience.net/i/jpl/imagemissing') {
        const secureMediaSetLink = mediaLink.replace(/^http:\/\//i, 'https://');

        const response = await axios.get(secureMediaSetLink);
        const itemsArray = response.data;

        if (itemsArray) {
          const productImages = itemsArray?.items?.filter(
            (item) => item.type === 'img',
          );
          const productVideo = itemsArray.items.find(
            (item) => item.type === 'video',
          );
          const productSpinSet = itemsArray.items.filter(
            (item) => item.type === 'set',
          );

          setImages(productImages);
          setVideo(productVideo);

          if (productSpinSet.length > 0) {
            const spinSetSrc = productSpinSet[0].src;
            const httpsSpinSet = spinSetSrc?.slice(0, 5) === 'http:'
              ? `https${spinSetSrc.slice(4)}`
              : spinSetSrc;
            const jsonSpinSet = httpsSpinSet.includes('.json')
              ? httpsSpinSet
              : `${httpsSpinSet}.json`;
            const spinSetResponse = await axios.get(jsonSpinSet);
            setSpinSets(spinSetResponse.data);
          }
        } else {
          console.error('Invalid response data:', itemsArray);
        }
      } else {
        setImages([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the case where the file is not found, if needed
        setImages([]);
      } else {
        console.error('Error fetching media:', error);
      }
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const fetchRefreshImages = async () => {
    try {
      const productsToUpdate = selected.map((id) => {
        const product = rows.find((item) => item.id === id);
        return product ? product.ProductId : null;
      });
      await patchMediaCheck(siteId, productsToUpdate);
      handleRefreshReports();

      toast.success('Images/Videos/Spin Sets have been refreshed');
    } catch (error) {
      console.error('Error refreshing images:', error);
      throw error;
    }
  };

  const handleRefreshImages = async () => {
    fetchRefreshImages();
  };

  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setSearchParams((params) => {
      params.set('pageSize', rowsPerPage);
      return params;
    });
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
      // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter(
      (str) => str !== 'page'
        && str !== 'siteCode'
        && str !== 'omsId'
        && str !== 'pageSize',
    );
    const count = filtered.length;
    const hasParams = count > 0;
    if (!firstLoadExample && hasParams) {
      fetchMediaCheck();
    }
  }, [selectedPage, rowsPerPage]);

  React.useEffect(() => {
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
      // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter(
      (str) => str !== 'page'
        && str !== 'siteCode'
        && str !== 'omsId'
        && str !== 'pageSize'
        && str !== 'idType',
    );
    const count = filtered.length;
    const hasParams = count > 0;
    setSearchBtnDisable(!hasParams);
  }, [searchParams]);

  React.useEffect(() => {
    setSelected([]);
  }, [selectedPage]);

  React.useEffect(() => {
    resetAllFilters();
    setRows([]);
    setFilteredData();
    setShowMediaDisplay(false);
    setImages([]);
    setTotalPages(0);
    setPageAmount(0);
    setTotalProducts(0);
    setSelectedPage(1);
    setSearchParams((params) => {
      params.delete('pageSize');
      params.delete('page');
      return params;
    });
    getSources(siteId)
      .then((res) => setSiteSources(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect((rowData) => {
    if (ProductId) {
      getDisplayMedia(rowData);
      fetchMediaDisplayData();
    }
  }, []);

  return (
    <div className="flex justify-center h-full gap-8 pt-2 m-8 mt-4">
      <div className="w-full overflow-auto">
        <Collapse
          in={filterOpen}
          timeout="auto"
          sx={{
            '& .MuiCollapse-wrapperInner': {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <Grid
            container
            spacing={2}
            className="rounded-lg border bg-white transition-all w-[60%]"
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
              marginTop={2}
            >
              <Typography variant="h6" className="pl-5 font-gothamlight">
                Filters
              </Typography>

              <div className="flex flex-wrap w-full h-full gap-4 p-5 mt-5">
                <div className="flex flex-wrap w-full h-full gap-4">
                  <Box className="flex gap-6">
                    <div className="mr-10">
                      <CustomIdsFilter onError={setIdsError} />
                    </div>
                    <div className="mr-10">
                      <HasImageFilter />
                    </div>
                    <div className="mr-10">
                      <HasVideoFilter />
                    </div>
                    <HasSpinSetFilter />
                  </Box>
                  <div className="flex items-center justify-end w-full gap-10 pb-5 mb-5">
                    <Button
                      variant="text"
                      className="text-primary-600"
                      onClick={() => resetAllFilters()}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      disabled={idsError || searchBtnDisabled}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <ProgressSideBarMediaCheck inProgress={inProgressData} />
        </Collapse>
        <Stack
          className="mb-5 mt-10 flex w-full justify-between pl-[24px] pr-[24px]"
          direction="row"
          alignItems="center"
        >
          <Typography variant="h5" className="font-gothamlight">
            Media Check
          </Typography>
          <Stack direction="row" gap={4}>
            <Button
              variant="contained"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {filterOpen ? 'Close' : 'Filter'}
            </Button>
          </Stack>
        </Stack>
        <section className="p-4 mb-5 bg-white">
          <div className="flex items-center w-full gap-10 pb-5 mb-5">
            <Button
              variant="contained"
              disabled={selected.length < 1}
              onClick={handleRefreshImages}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              disabled={selected.length < 1}
              onClick={(event) => {
                setShowExportModal(true);
                handlePopoverOpen(event);
              }}
            >
              <FileDownloadOutlinedIcon className="mr-2" />
              Export
            </Button>
            {showExportModal && (
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ExportButton
                  columns={exportColumns}
                  rows={rows}
                  title="MediaCheckExport"
                  filteredData={filteredData}
                />
              </Popover>
            )}
          </div>
          <section className="flex flex-row justify-between w-full">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              checkboxSelection
              pageSizeOptions={[5, 10, 25, 50, 100]}
              className="min-h-[40vh] h-[80vh] max-w-[650px] flex"
              rowSelectionModel={selected}
              onRowSelectionModelChange={(selectionModel) => setSelected(selectionModel)}
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 100}
              sx={{
                width: `${selected.length > 0 ? 'auto' : '100%'}`,
                '& .MuiDataGrid-cell': {
                  justifyContent: 'center',
                },
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: '100px',
                },
              }}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={[
                {
                  groupId: 'PLUs',
                  children: siteSources?.map((obj) => ({ field: obj.ShortDescription })),
                },
              ]}
              slots={{
                pagination: () => CustomPagination(
                  selectedPage,
                  onChangeSelectedPage,
                  rowsPerPage,
                  onChangePageSize,
                  pageAmount,
                  totalPages,
                  totalProducts,
                ),
              }}
              onCellClick={(params, event) => {
                event.stopPropagation();
                const rowData = params.row;
                setProductId(rowData.ProductId);
                setShowMediaDisplay(true);
                setVideo(null);
                setSpinSets([]);
                getDisplayMedia(rowData);
              }}
            />
            {!showMediaDisplay && (
              <div className="flex items-center justify-center">
                <Typography variant="h6" className="text-center w-[250px] pl-5 font-gothamlight">Please click a cell to view a product</Typography>
              </div>
            )}
            <Collapse
              in={showMediaDisplay}
              timeout="auto"
              className="ml-5"
              sx={{ width: `${showMediaDisplay ? '450px' : '0%'}` }}
              key={images.length}
            >
              <CustomMediaDisplay
                images={images}
                video={video}
                spinSets={spinSets}
                setImages={setImages}
                setVideo={setVideo}
                setSpinSets={setSpinSets}
                sx={{
                  width: `${selected.length > 0 ? 'auto' : '100%'}`,
                  '& .MuiDataGrid-cell': {
                    justifyContent: 'center',
                  },
                }}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={[
                  {
                    groupId: 'PLUs',
                    children: siteSources?.map((obj) => ({
                      field: obj.ShortDescription,
                    })),
                  },
                ]}
                slots={{
                  pagination: () => CustomPagination(
                    selectedPage,
                    onChangeSelectedPage,
                    rowsPerPage,
                    onChangePageSize,
                    pageAmount,
                    totalPages,
                    totalProducts,
                  ),
                }}
                onCellClick={(params, event) => {
                  event.stopPropagation();
                  const rowData = params.row;
                  setProductId(rowData.ProductId);
                  setShowMediaDisplay(true);
                  setVideo(null);
                  setSpinSets([]);
                  getDisplayMedia(rowData);
                }}
              />
            </Collapse>
          </section>
        </section>
      </div>
    </div>
  );
}

export default MediaCheck;
