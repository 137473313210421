/* eslint-disable no-nested-ternary */
import Button from '@mui/material/Button';
import React, { memo } from 'react';
import { areEqual } from 'react-window';

const SuccessTabRowMarginThreshold = memo(({
  data,
  index,
  style,
  setSelected,
  setOpenModal,
}) => {
  const obj = data[index];
  const parsedHeaderInfo = JSON.parse(obj.HeaderInfo);

  return (
    <section key={obj.Id} className="h-42 border-b border-neutral-400" style={style}>
      <div className="relative flex items-center w-full">
        <div className="flex flex-col w-3/4 gap-1">
          <p className="text-sm">
            <span className="font-gothammedium">ID:</span>
            {' '}
            {obj.Id}
          </p>
          <p className="text-sm">{obj.UserName}</p>

          <div className="flex items-center justify-start gap-4">
            <p className="text-sm">
              Count:
            </p>
            <span className="flex gap-[2px] whitespace-nowrap">
              <p>(</p>
              <p>{obj.Updated}</p>
              <p>)</p>
            </span>
          </div>
          <p className="text-sm">
            Location:
            {' '}
            {parsedHeaderInfo[0].SiteName}
          </p>
          <p className="text-sm">
            Source:
            {' '}
            {`${parsedHeaderInfo[0].SourceName} / ${parsedHeaderInfo[0].SourceLocation}`}
          </p>
          <p className="text-sm">
            Started:
            {' '}
            {new Date(obj.ProcessStartDate).toLocaleString('en-GB')}
          </p>
        </div>
        <div className="flex items-center justify-center w-1/4">
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setSelected(obj);
              setOpenModal(true);
            }}
          >
            More Info
          </Button>
        </div>
      </div>
    </section>
  );
}, areEqual);

export default SuccessTabRowMarginThreshold;
