import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a pre release date.
 *
 * @param {number} SiteId Site Id
 * @param {number} sourceId Source Id - Example, 1 for Shogun
 * @param {Datetime} releaseDates Release Dates
 * @param {string[]} plus PLUs
*/
export default function postPreReleaseProductsPluId({
  SiteId,
  sourceId,
  releaseDates,
  plus,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/pre-release-product';

  const body = {
    siteId: SiteId,
    sourceId,
    releaseDate: releaseDates, // Assuming releaseDates is a single date string
    plus,
  };

  return new Promise((resolve, reject) => {
    postRequest(
      endpoint,
      body,
      'Pre Release Dates PLU Ids',
      'STARTED: Pre Release Dates PLU Ids',
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
