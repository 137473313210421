import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import ExportButton from '../../../components/ExportButton';
import Table from '../../../components/Table';
import SearchByAttributeFilter from './filter/SearchByAttributeFilter';

// function createData(productId, pluSource, name, brand, active, status, lastUpdated) {
//   return {
//     productId, pluSource, name, brand, active, status, lastUpdated,
//   };
// }

function SearchByAttribute() {
  const [loading, setLoading] = useState(false);
  const [
    products,
    // setProducts
  ] = useState({});
  const [
    rows,
    // setRows
  ] = useState([]);
  const [page, setPage] = useState(0);
  const [siteId] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [productsPostData, setProductsPostData] = useState({
    asc: true,
    field: 1,
    languageId: 1,
    orderBy: {
      asc: true,
      field: 1,
    },
    page: page + 1,
    pageSize: rowsPerPage,
    selectedSourcesOnly: false,
    siteId,
    sourceIds: [],
  });

  const columns = [
    { id: 'productId', label: 'Product ID' },
    { id: 'pluSource', label: 'PLU - Source' },
    { id: 'name', label: 'Name' },
    { id: 'brand', label: 'Brand' },
    { id: 'active', label: 'Active' },
    { id: 'status', label: 'Status' },
    { id: 'lastUpdated', label: 'Last Updated' },
  ];

  useEffect(() => {
    setLoading(true);
    // async function fetchData() {
    //   const search = await postProducts(productsPostData, null, store);
    //   setProducts(search.data);

    //   const mappedRows = search.data.items.map((product) => createData(
    //     product.id,
    //     product.remoteId,
    //     product.name,
    //     product.brand,
    //     product.isActive,
    //     product.isApproved,
    //     product.lastUpdated == null ? 'No Rel
    // ease Date Set' : new Date(product.lastUpdated).toLocaleString(),
    //   ));
    //   setLoading(false);
    //   setRows(mappedRows);
    // }
    // fetchData();
  }, [page, rowsPerPage, productsPostData, siteId]);

  const handleChangePage = (event, pageNumber) => {
    setProductsPostData({
      ...productsPostData,
      page: pageNumber + 1,
    });
    setPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setProductsPostData({
      ...productsPostData,
      page: 1,
      pageSize: event.target.value,
    });
  };

  return (
    <Container>
      <Grid container spacing={2} marginTop>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Typography variant="h4" className="font-gothamlight">Search By Attributes</Typography>
          <Box sx={{
            width: '20%', display: 'flex', justifyContent: 'space-between',
          }}
          >
            <ExportButton columns={columns} rows={rows} filename="SearchByAttributeTableExport" title="Search By Attribute" />
            <SearchByAttributeFilter />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Table columns={columns} rows={rows} loading={loading} fixed={false} />
          <TablePagination
            rowsPerPageOptions={[15, 30, 90]}
            component="div"
            count={products.totalItems || 16}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ height: 'fit-content' }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SearchByAttribute;
