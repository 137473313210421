import deleteRequest from '../../../../../../deleteRequest';

/**
 * Bulk upload a campaign to multiple products.
 *
 * @param {{
 *  referenceIds: string[],
 *  productTypeIds: number[],
 *  sourceId: number,
 *  siteId: number
 * }}
 * @param {string[]} referenceIds Product Ids
 * @param {number[]} productTypeIds Product Types Ids
 * @param {number} sourceId Source Id
 * @param {number} siteId Site Id
*/
export default function deleteProductTypesPluId({
  referenceIds,
  productTypeIds,
  sourceId,
  siteId,
}) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/product-types/plu-id/${sourceId}`;

  const body = {
    referenceIds,
    productTypeIds,
    siteId,
  };

  const successMessage = 'Started : Product Types : PLU (Delete);';
  const errorMessageHeader = `Failed to DELETE - IDs: ${productTypeIds} from Products: ${referenceIds}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, errorMessageHeader, successMessage)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
