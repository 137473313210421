import {
  Button,
  Container, FormControl, Typography,
} from '@mui/material';
import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import { toast } from 'react-toastify';

import getEnglishSwatchColour from '../../../api/oc_product/productDataManagement/colourMatching/get/getEnglishSwatchColour';
import getLanguageDetails from '../../../api/oc_product/productDataManagement/colourMatching/get/getLanguageDetails';
import getNonEnglishSwatchColour from '../../../api/oc_product/productDataManagement/colourMatching/get/getNonEnglishSwatchColour';
import addSwatchcolour from '../../../api/oc_product/productDataManagement/colourMatching/post/postSwatchColour';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import ColourDataTable from './components/ColourTable';
import NewMappingPopover from './components/NewMapping';
import SearchColour from './components/SearchColour';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONTENT_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};
function ColourMatching() {
  const initialState = {
    language: null,
    attribute: null,
    sourceId: null,
    file: '',
  };
  const [languages, setLanguages] = useState([]);
  const [rowsData, setRows] = useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [colourType, setcolourType] = React.useState(1);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [englishColour, setEnglishColour] = React.useState('');
  const [translatedColour, setTranslatedColour] = React.useState('');
  const [enabledMapingButton, setEnabledMapingButton] = React.useState(true);
  const [swatchColour1, setSwatchColour1] = React.useState('');
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    async function fetchLanguages() {
      try {
        const languageRes = await getLanguageDetails();
        setLanguages(languageRes.data);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    }

    fetchLanguages();
  }, []);

  const getSwatchColourDetails = async (languageId, colourName, SelectedcolourType) => {
    try {
      let response;
      if (languageId === 1 && state.language > 0) {
        response = await getEnglishSwatchColour(languageId, colourName, SelectedcolourType);
      } if (languageId !== 1 && state.language > 0) {
        response = await getNonEnglishSwatchColour(languageId, colourName, SelectedcolourType);
      }
      if (response) {
        const data = response?.data;
        setRows(data);
        setPage(0);
      }
    } catch (error) {
      console.error('Error fetching Swatch Colour Details:', error);
      setRows([]);
    }
  };

  useEffect(() => {
    setSearchValue('');
    setcolourType(1);
    if (state.language) {
      setEnabledMapingButton(false);
      getSwatchColourDetails(state.language, searchValue, colourType);
    } else {
      setEnabledMapingButton(true);
    }
  }, [state.language]);

  const addColourDetails = useCallback(async () => {
    try {
      const swatchOrTranslatedColour = state.language === 1 ? swatchColour1 : translatedColour;
      await addSwatchcolour(state.language, englishColour, swatchOrTranslatedColour);
      toast.success('Colour successfully added');
    } catch (error) {
      console.error('Error while adding the colour:', error);
    } finally {
      setEnglishColour('');
      setTranslatedColour('');
      setSwatchColour1('');
      getSwatchColourDetails(state.language, searchValue, colourType);
    }
  }, [state.language, englishColour, translatedColour, swatchColour1, searchValue, colourType]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSearch = () => {
    if (state.language && searchValue) {
      getSwatchColourDetails(state.language, searchValue, colourType);
    }
  };
  const handleReset = () => {
    setSearchValue('');
    setcolourType(1);
    getSwatchColourDetails(state.language, '', colourType);
  };
  const colorHexCodes = {
    purple: '#800080',
    cream: '#FFFDD0',
    silver: '#C0C0C0',
    green: '#008000',
    pink: '#FFC0CB',
    pin: '#FF7373',
    offWhite: '#FAF9F6',
    darkBlue: '#00008B',
    grey: '#808080',
    red: '#FF0000',
    brown: '#A52A2A',
    multi: '#FF00FF',
    gold: '#FFD700',
    beige: '#F5F5DC',
    black: '#000000',
    blue: '#0000FF',
    yellow: '#FFFF00',
    white: '#FFFFFF',
    'Dark Brown': '#654321',
    orange: '#FFA500',
    'Dark Grey': '#A9A9A9',
    Navy: '#000080',
  };

  return (
    <Container maxWidth="xl">

      <style>
        {`
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
          }

          .custom-scrollbar::-webkit-scrollbar {
            width: 10px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 10px;
            border: 2px solid #f1f1f1;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #555;
          }
        `}
      </style>
      <div className="flex w-[100%] h-full gap-2 pt-2 m-8 mt-1">
        <div className="flex flex-col w-full">
          <div className="flex flex-col justify-center mt-4">
            <Typography variant="h5" className="pb-2 pl-8 text-secondary-600">Colour Matching</Typography>
            <div className="flex flex-col w-4/5 p-8 mt-5 bg-white mx-auto shadow-lg">
              <div style={{ minWidth: '250px', height: '300px' }}>
                <FormControl sx={{ m: 3, minWidth: 200 }} size="small">
                  <VirtualizedAutocomplete
                    size="small"
                    options={languages.map((obj) => ({
                      ...obj,
                      Name: obj.LanguageName,
                    }))}
                    textFieldLabel="Language"
                    textFieldVariant="outlined"
                    value={languages.find((obj) => obj?.LanguageId === state.language) || null}
                    getOptionLabel={(option) => option.LanguageName}
                    onSelectedValue={(value) => dispatch({ type: 'CONTENT_LANGUAGE', payload: value?.LanguageId })}
                    optionKey="LanguageName"
                  />
                </FormControl>
                <SearchColour
                  language={state.language}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  onSearch={handleSearch}
                  onReset={handleReset}
                  colourType={colourType}
                  setcolourType={setcolourType}
                />
                <div className="flex justify-end w-full mb-9">
                  <Button
                    sx={{ m: 4, height: '30px', width: '150px' }}
                    onClick={(event) => handlePopoverOpen(event)}
                    variant="contained"
                    disabled={enabledMapingButton}
                  >
                    New Mapping
                  </Button>

                  <NewMappingPopover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    language={state.language}
                    englishColour={englishColour}
                    translatedColour={translatedColour}
                    swatchColour1={swatchColour1}
                    setEnglishColour={setEnglishColour}
                    setTranslatedColour={setTranslatedColour}
                    setSwatchColour1={setSwatchColour1}
                    addColourDetails={addColourDetails}
                  />

                </div>
              </div>
            </div>
            <ColourDataTable
              language={state.language}
              rowsData={rowsData}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={(event, newPage) => setPage(newPage)}
              handleChangeRowsPerPage={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
              colorHexCodes={colorHexCodes}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ColourMatching;
