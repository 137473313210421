import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function CircularProgressWithLabel(props) {
  const { index, total } = props;
  const completionNum = Number.isNaN(index / total) ? 1 : (index / total);
  const percent = Math.round(completionNum * 100);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#e6e7e8',
        }}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        value={percent}
        sx={{
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${percent}%`}
        </Typography>
      </Box>
    </Box>
  );
}
