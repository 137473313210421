import postRequest from '../../../../postRequest';

/**
 * Post request to enable a margin threshold.
 *
 * @param {number} marginThresholdConfigId
 *
 */
function postEnableMarginThreshold(marginThresholdConfigId) {
  const endpoint = `/oc-data-management/v1/margin-thresholds/${marginThresholdConfigId}/activate-job`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, 'Enable Margin Threshold')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postEnableMarginThreshold;
