import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
  TreeItem,
  treeItemClasses,
  TreeView,
} from '@mui/x-tree-view';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getAllCategories from '../../api/oc_product/product/attributes/categories/gets/getAllCategories';
import disableCategory from '../../api/oc_product/productDataManagement/category/patch/disableCategory';
import postCategory from '../../api/oc_product/productDataManagement/category/post/postCategory';
import { CategoryContext } from '../../pages/data_management/category/CategoryContext';
import Modal from '../Modal';

const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    overflow: 'visible',
    width: '100%',
    [`& .${treeItemClasses.root}`]: {
      overflow: 'visible',
      margin: '.25rem 0',
      borderRadius: '5px',
    },
    [`& .${treeItemClasses.focused}`]: {
      outline: '#a3a3a3 solid 1px',
      borderRadius: '5px',
      background: 'transparent !important',
    },
    [`& .${treeItemClasses.selected}`]: {
      background: 'transparent !important',
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      marginLeft: 16,
      paddingLeft: 20,
    },
  }),
);

const CategoryTreeMenu = forwardRef((props, ref) => {
  const {
    showMenu,
    // enableSelect,
    onViewCategory,
    categoryDetails,
    setCategoryDetails,
    setDetailsLoading,
    categoriesLoading,
    setCategoriesLoading,
    categoriesNameUpdated,
    disabledCategories = [],
  } = props;

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const { setReFetchLogs } = useContext(CategoryContext);

  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);

  const [buttonAnchor, setButtonAnchor] = useState(null);
  const [addChildAnchor, setAddChildAnchor] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMenuOptionId, setSelectedMenuOptionId] = useState(null);
  const [selectedAddChild, setSelectedAddChild] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();

  const [newChild, setNewChild] = useState('');

  const fetchData = async () => {
    try {
      setCategoriesLoading(true);
      const res = await getAllCategories(siteId);
      if (res) {
        setData(res.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setCategoriesLoading(false);
    }
  };

  React.useEffect(() => {
    if (categoriesNameUpdated) {
      fetchData();
    }
  }, [categoriesNameUpdated]);

  const getAllNodes = (nodes) => {
    const result = [];
    const traverse = (node) => {
      result.push(node);
      if (node.SubCategories) {
        node.SubCategories.forEach((subNode) => traverse(subNode));
      }
      if (node.ChildCategories) {
        node.ChildCategories.forEach((childNode) => traverse(childNode));
      }
    };
    nodes.forEach((node) => traverse(node));
    return result;
  };

  const handleExpandAll = () => {
    const allNodes = getAllNodes(data);
    setExpandedItems(allNodes.map((node) => node.Id));
  };

  const handleCollapseAll = () => {
    setExpandedItems([]);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    // setNewGroupBrands([]);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleMenuOpen = (event, id) => {
    event.stopPropagation();
    setSelectedMenuOptionId(id);
    setButtonAnchor(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setSelectedMenuOptionId(null);
    setButtonAnchor(null);
    setAddChildAnchor(null);
    setSelectedAddChild(null);
  };

  const handleOption = (event) => {
    event.stopPropagation();
    setOpenModal(true);
  };

  const handleChildOption = (event, name, id) => {
    event.stopPropagation();
    setAddChildAnchor(event.currentTarget);
    setSelectedAddChild(id);
  };

  const submitNewChild = async (event) => {
    event.stopPropagation();
    try {
      setCategoriesLoading(true);
      const res = await postCategory(siteId, selectedCategory.Id, newChild);
      handlePopoverClose();
      if (res) {
        toast.success('Category successfully created');
        await fetchData();
      }
    } catch (err) {
      handlePopoverClose();
      console.error(err);
    } finally {
      setCategoriesLoading(false);
      setReFetchLogs(true);
    }
    handleMenuClose(event);
  };

  const submitRootCategory = async (event) => {
    event.stopPropagation();
    try {
      setCategoriesLoading(true);
      const res = await postCategory(siteId, 0, newChild);
      handlePopoverClose();
      if (res) {
        toast.success('Category successfully created');
        await fetchData();
      }
    } catch (err) {
      handlePopoverClose();
      console.error(err);
    } finally {
      setCategoriesLoading(false);
      setReFetchLogs(true);
    }
    handleMenuClose(event);
  };

  const handleDisableCategory = async () => {
    try {
      setCategoriesLoading(true);
      setDetailsLoading(true);
      const res = await disableCategory(selectedCategory.Id, siteId);
      handlePopoverClose();
      if (res.data) {
        toast.success('Category successfully disabled');
        await fetchData();
      }
    } catch (err) {
      handlePopoverClose();
      setCategoriesLoading(false);
      console.error(err);
    } finally {
      setCategoryDetails(null);
      setDetailsLoading(false);
      setReFetchLogs(true);
    }
  };

  useImperativeHandle(ref, () => ({
    removeCategory(category) {
      const newSelectedItems = selectedItems.filter(
        (item) => item !== category,
      );
      setSelectedItems(newSelectedItems);
    },
    resetSelectedCategories() {
      setSelectedItems([]);
    },
  }));

  const handleSelect = (event, node) => {
    event.preventDefault();
    setExpandedItems((prevItems) => {
      if (prevItems.includes(node.Id)) {
        return prevItems.filter((item) => item !== node.Id);
      }
      return [...prevItems, node.Id];
    });
    onViewCategory(node);
  };

  const labelAndButton = (node) => (
    <>
      <span>{node.Category}</span>
      <Button
        size="small"
        onClick={(event) => {
          onViewCategory(node);
          setSelectedCategory(node);
          handleMenuOpen(event, node.Id);
        }}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id={`tree-menu-${node.Id}`}
        anchorEl={buttonAnchor}
        open={selectedMenuOptionId === node.Id}
        onClose={(event) => handleMenuClose(event)}
      >
        <MenuItem
          onClick={(event) => handleChildOption(event, node.Id, node.Id)}
        >
          Add Child
        </MenuItem>
        <MenuItem onClick={(event) => {
          setSelectedCategory(node);
          handleOption(event);
        }}
        >
          Disable
        </MenuItem>
      </Menu>
      <Popover
        id={node.Id}
        open={selectedAddChild === node.Id}
        anchorEl={addChildAnchor}
        onClick={(event) => event.stopPropagation()}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Paper className="flex items-center gap-4 p-4">
          <TextField
            label="Category Name"
            size="small"
            variant="outlined"
            onChange={(e) => setNewChild(e.target.value)}
          />
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={(event) => submitNewChild(event)}
          >
            Submit
          </Button>
        </Paper>
      </Popover>
    </>
  );

  const subCategories = (nodes, callback) => {
    if (Array.isArray(nodes.SubCategories) && !disabledCategories.includes(nodes)) {
      return nodes.SubCategories.map((node) => !disabledCategories.includes(node) && callback(node));
    }
    if (Array.isArray(nodes.ChildCategories) && !disabledCategories.includes(nodes)) {
      return nodes.ChildCategories.map((node) => !disabledCategories.includes(node) && callback(node));
    }
    return null;
  };

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.Id}
      nodeId={nodes.Id}
      label={showMenu ? labelAndButton(nodes) : nodes.Category}
      onClick={(e) => handleSelect(e, nodes)}
      className={selectedItems.includes(nodes) ? 'bg-neutral-200' : ''}
    >
      {subCategories(nodes, renderTree)}
    </StyledTreeItem>
  );

  React.useEffect(() => {
    fetchData();
  }, [siteId]);

  const open = Boolean(anchorEl);

  return (
    <div>
      <div className="flex flex-row justify-between m-2">
        <div>
          <Button variant="outlined" onClick={handleExpandAll} className="mr-2">
            Expand All
          </Button>
          <Button variant="outlined" onClick={handleCollapseAll}>
            Collapse All
          </Button>
        </div>
        <Button variant="contained" onClick={handlePopoverOpen} onClose={handlePopoverClose}>
          Add root cat
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              width: '400px',
            },
          }}
        >
          <Paper className="flex items-center gap-4 p-4">
            <TextField
              label="Category Name"
              size="small"
              variant="outlined"
              onChange={(e) => setNewChild(e.target.value)}
            />
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={(event) => submitRootCategory(event)}
            >
              Submit
            </Button>
          </Paper>
        </Popover>
      </div>
      <TreeView
        defaultExpanded={['root']}
        expanded={expandedItems}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          overflow: 'visible',
          overflowY: 'auto',
          width: '100%',
          padding: '4px',
        }}
        {...props}
      >
        {categoriesLoading ? 'Loading...' : data?.map((node) => renderTree(node))}
      </TreeView>
      {selectedCategory && (
        <Modal
          title={`Are you sure you want to disable the ${selectedCategory.Category} category`}
          open={openModal}
          onClose={handleModalClose}
          buttonLabel="Confirm"
          buttonOnClick={() => {
            setOpenModal(false);
            handleDisableCategory();
          }}
        >
          <Typography>
            {`Please note it will disable all child categories and will affect ${categoryDetails?.ProductCounts} products`}
          </Typography>
        </Modal>
      )}
    </div>
  );
});

export default CategoryTreeMenu;
