// import getRequest from '../../../getRequest';
import postRequest from '../../../../../postRequest';

/**
 * Editable attributes data.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} languageId Language Id of the product (optional)
 * @param {number} page Page number (optional)
 * @param {number} size Number of items per page (optional)
 * @returns {AxiosResponse}
 * @example
 * {
 *    data: [
 *      {
 *        "ProductAttributeDefId": 16367,
 *        "ProductAttributeDefKey": "%_deet",
 *        "Value": null,
 *        "Id": 0,
 *        "Name": "DEET",
 *        "IsReadOnly": false
 *      }
 *    ]
 * }
 */
export default function postEditable(productId, siteId, languageId, page, size, body) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/attributes/editable?languageId=${languageId}&Page=${page}&Size=${size}`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Editable Attributes')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
