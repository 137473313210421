const componentThemes = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      outlined: {
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        // backgroundColor: '#FDF7E8',
        // color: '#BB8B10',
        // border: '1px solid #BB8B10',
      },
      contained: {
        fontWeight: 'bold',
        letterSpacing: '0.5px',
      },
      text: {
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        color: '#333',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        fontSize: '12px',
      },
      colorSuccess: {
        backgroundColor: '#f3faf3', // success-50
        color: '#358438', // success-600
        borderColor: '#358438', // success-600
      },
      colorError: {
        backgroundColor: '#fff2f1', // error-50
        color: '#a12113', // error-800
        borderColor: '#a12113', // error-800
      },
      // colorWarning: {
      //   backgroundColor: '#fffbeb', // error-50
      // },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        height: 'max-content',
        width: '250px',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingTop: '.75rem',
        paddingBottom: '0',
      },
      title: {
        fontFamily: 'gothammedium',
        fontSize: '18px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: '#384451',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        fontSize: '14px',
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '.MuiDataGrid-columnHeaderTitle': {
          fontFamily: 'gothammedium',
        },
      },
    },
  },
};

export default componentThemes;
