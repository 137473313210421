import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const TabPanel = React.forwardRef((props, ref) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      ref={ref}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="h-[calc(100%-50px)]"
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 2, py: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
});

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
