import getRequest from '../../../../getRequest';

/**
 * Calls shogun-response-codes endpoint that gets existing mapped response codes.
 *
 * @returns {Promise} { data: {}, status: 200 }
 */
async function getShogunResponseCodes() {
  const endpoint = '/integration-shogun/v1/shogun-response-codes';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Shogun Response Codes')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getShogunResponseCodes;
