import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import deleteCategoriesPluId from '../../../api/oc_product/bulk/bulk_process/categories/plu_id/deletes/deleteCategoriesPluId';
import postCategoriesPluId from '../../../api/oc_product/bulk/bulk_process/categories/plu_id/posts/postCategoriesPluId';
import deleteCategoriesProductId from '../../../api/oc_product/bulk/bulk_process/categories/product_id/deletes/deleteCategoriesProductId';
import postCategoriesProductId from '../../../api/oc_product/bulk/bulk_process/categories/product_id/posts/postCategoriesProductId';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import doesObjectContainEmptyValues from '../../../utils/doesObjectContainEmptyValues';
import restrictKeys from '../../../utils/restrictKeys';
import splitString from '../../../utils/splitIdString';
import CategoryMenu from './components/CategoryMenu';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ACTION':
      return {
        ...state,
        action: action.payload,
      };
    case 'SOURCE':
      return {
        ...state,
        sourceId: action.payload,
      };
    case 'CATEGORIES':
      return {
        ...state,
        categoryId: action.payload,
      };
    case 'REFERENCES':
      return {
        ...state,
        referenceIds: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};

function Categories({ refreshReports }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const submitBtn = React.useRef(null);

  const initialState = {
    referenceIds: '',
    categoryId: [],
    sourceId: null,
    action: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const [idType, setIdType] = React.useState('plu');

  const [sourceOptions, setSourceOptions] = React.useState([]);

  const [productIdLengthError, setProductIdLengthError] = React.useState(false);

  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [buttonWidth, setButtonWidth] = React.useState(0);
  const [buttonHeight, setButtonHeight] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.get('omsId');

  const treeRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (idType === 'plu') {
      if (state.action === 4) { // delete
        deleteCategoriesPluId({
          referenceIds: splitString(state.referenceIds),
          categoryId: state.categoryId.map((obj) => obj.Id),
          siteId,
          sourceId: state.sourceId,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      } else {
        postCategoriesPluId({
          referenceIds: splitString(state.referenceIds),
          categoryId: state.categoryId.map((obj) => obj.Id),
          siteId,
          sourceId: state.sourceId,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      }
    } else if (state.action === 4) { // delete
      deleteCategoriesProductId({
        referenceIds: splitString(state.referenceIds),
        categoryId: state.categoryId.map((obj) => obj.Id),
        siteId,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    } else {
      postCategoriesProductId({
        referenceIds: splitString(state.referenceIds),
        categoryId: state.categoryId.map((obj) => obj.Id),
        siteId,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    }
  };

  const handleKeyPress = (event) => {
    if (idType === 'productId') {
      restrictKeys(event);
    }
  };

  React.useEffect(() => {
    treeRef.current.resetSelectedCategories();
    dispatch({ type: 'RESET', payload: initialState });
    setIdType('plu');
    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect(() => {
    if (submitBtn.current) {
      const sbmBtn = submitBtn.current.getBoundingClientRect();
      setButtonWidth(sbmBtn.width);
      setButtonHeight(sbmBtn.height);
    }
  }, [submitBtn.current]);

  React.useEffect(() => {
    if (idType !== 'plu') {
      delete state.sourceId;
    } else if (!state.sourceId) {
      state.sourceId = '';
    }
    const isEmpty = doesObjectContainEmptyValues(state);
    setSubmitDisabled(isEmpty);
  }, [state, idType]);

  React.useEffect(() => {
    const splitIds = splitString(state.referenceIds);
    if (splitIds.some((str) => str.length > 9)) {
      if (idType === 'productId') {
        setProductIdLengthError(true);
        setSubmitDisabled(true);
      } else {
        setProductIdLengthError(false);
      }
    } else {
      setProductIdLengthError(false);
    }
  }, [state.referenceIds, idType]);

  React.useEffect(() => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
         * For some reason we have to do this...
         * If you do the, if not then delete, statement below within the for above
         * or do params.forEach
         * The loop with miss every even indexed key...
         */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 2 }}>
      <section className="flex justify-center w-full gap-8">
        <div className="w-full h-full">
          <Typography gutterBottom>
            Click on category to select.
          </Typography>

          <CategoryMenu
            ref={treeRef}
            enableSelect
            onSelectedValue={(value) => dispatch({ type: 'CATEGORIES', payload: value })}
            className="max-h-[calc(100vh-220px)] border rounded-lg bg-white pr-8"
          />
        </div>
        <form className="flex flex-col w-full h-full gap-4" onSubmit={handleSubmit}>
          <Typography gutterBottom>Selected Categories.</Typography>

          <Box className="flex flex-wrap w-full gap-2 p-4 border border-gray-400 rounded-md">
            {state.categoryId.length > 0 ? (
              state.categoryId.map((category) => (
                <Chip
                  key={category.FullCategoryPath}
                  label={category.FullCategoryPath}
                  onDelete={() => treeRef.current.removeCategory(category)}
                  size="small"
                />
              ))
            ) : (
              <Chip
                label="No Categories Selected"
                className="bg-transparent"
              />
            )}
          </Box>

          <FormControl fullWidth>
            <InputLabel id="action-label">Action</InputLabel>
            <Select
              labelId="action-label"
              id="action-select"
              label="Action"
              value={state.action}
              onChange={(e) => dispatch({ type: 'ACTION', payload: e.target.value })}
            >
              {/* <MenuItem value={1}>Insert</MenuItem> */}
              {/* <MenuItem value={2}>Read</MenuItem> */}
              <MenuItem value={3}>Update</MenuItem>
              <MenuItem value={4}>Delete</MenuItem>
            </Select>
          </FormControl>

          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              aria-label="type"
              defaultValue="plu"
              name="radio-buttons-group"
              onChange={(e) => setIdType(e.target.value)}
              value={idType}
              sx={{ mb: 2 }}
            >
              <FormControlLabel value="plu" control={<Radio />} label="PLU" />
              <FormControlLabel
                value="productId"
                control={<Radio />}
                label="Product ID"
              />
            </RadioGroup>
            {idType === 'plu' ? (
              <VirtualizedAutocomplete
                options={sourceOptions}
                textFieldLabel="Sources"
                textFieldVariant="outlined"
                value={sourceOptions.find((obj) => obj?.SourceId === state.sourceId) || null}
                getOptionLabel={(option) => option.Name}
                onSelectedValue={(value) => dispatch({ type: 'SOURCE', payload: value?.SourceId })}
                optionKey="Name"
              />
            ) : (
              <div className="hidden" />
            )}
          </FormControl>

          <TextField
            label="PLUs/ProductIDs"
            placeholder="Enter PLUs and Product IDs separated by comma or spaces."
            multiline
            rows={4}
            value={state.referenceIds}
            onKeyPress={handleKeyPress}
            onChange={(e) => dispatch({ type: 'REFERENCES', payload: e.target.value })}
            error={productIdLengthError}
            helperText={productIdLengthError ? 'One or more Id\'s contain 10 or more digits' : ''}
          />

          <div className="flex justify-end w-full gap-10">
            <Button onClick={() => {
              treeRef.current.resetSelectedCategories();
              dispatch({ type: 'RESET', payload: initialState });
            }}
            >
              Reset
            </Button>
            <div className="relative float-right w-max">
              <Button
                ref={submitBtn}
                variant="contained"
                type="submit"
                id="submit-button"
                disabled={submitDisabled}
                className="z-10"
              >
                Submit
              </Button>
              {!submitDisabled && (
                <div
                  className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 bg-primary-400 animate-ping"
                  style={{ width: `${buttonWidth / 1.5}px`, height: `${buttonHeight / 1.25}px` }}
                />
              )}
            </div>
          </div>
        </form>
      </section>
    </Container>
  );
}

export default Categories;
