import patchRequest from '../../../../patchRequest';

/**
 * Patch request to change category name.
 *
 * @param {number} categoryId 1
 * @param {number} siteId 1
 * @param {number} categoryName "Footwear"
 */
export default function patchCategoryName(
  categoryId,
  siteId,
  categoryName,
) {
  const endpoint = `/oc-data-management/v1/category/${categoryId}/${siteId}`;
  const errorMessageHeader = 'Change category name';
  const body = {
    categoryName,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
