import React from 'react';
import { toast } from 'react-toastify';

function errorToast(message) {
  const options = {
    autoClose: 30000,
  };
  toast.error(
    (
      // eslint-disable-next-line react/jsx-filename-extension
      <p className="w-full break-words whitespace-pre-line">
        {message}
      </p>
    ), options,
  );
}

// ! Need to standardise errors from API as theres too many combinations
function catchError(error, errorMessageHeader) {
  console.error('Catch Error', error);
  if (error.response?.status === 502) return 'Internal Server Error';
  const errorObject = error.response;

  if (errorObject?.data == null) return error;

  if (errorObject?.data.errors) {
    const errors = Object.entries(errorObject?.data.errors);

    errors.forEach(([key, value]) => {
      value.forEach((str) => errorToast(`${key}: ${str}`));
    });

    return errorObject?.data.errors;
  }

  if (errorObject?.data.Errors) {
    errorObject?.data.Errors.forEach((obj) => {
      errorToast(`${errorMessageHeader}: ${obj.ErrorMessage}`);
    });
    return errorObject?.data.Errors;
  }

  if (typeof errorObject?.data === 'string') {
    if (errorObject?.data.includes('504 Gateway Time-out')) {
      errorToast(`${errorMessageHeader}: Server Took Too Long To Respond`);
      return 'Server Took Too Long To Respond';
    }
    if (errorObject?.data.includes('503 Service Temporarily Unavailable')) {
      errorToast(`${errorMessageHeader}: Server Temporarily Unavailable`);
      return 'Server Temporarily Unavailable';
    }
    errorToast(`${errorMessageHeader}: ${errorObject?.data}`);
    return errorObject?.data;
  }

  if (Array.isArray(errorObject?.data)) {
    let multipleErrors = '';
    errorObject?.data.forEach((obj) => {
      if (typeof obj === 'object') {
        if (obj.Message != null) {
          multipleErrors += `\n${obj.Message}`;
          return obj.Message;
        }
      }
      return obj;
    });
    errorToast(`${errorMessageHeader}: ${multipleErrors}`);
    return errorObject?.data;
  }

  if (errorObject?.data.VariantFailureMessages) {
    errorToast(`${errorMessageHeader}: ${errorObject?.data.VariantFailureMessages}`);
    return errorObject?.data;
  }

  if (errorObject?.data.ValidationErrors) {
    const errMsg = errorObject?.data.ValidationErrors[0].ErrorMessage;
    errorToast(`${errorMessageHeader}: ${errMsg}`);
    return errorObject?.data;
  }

  errorToast(`${errorMessageHeader}: ${errorObject?.data.message}`);
  return errorObject?.data.message;
}

export default catchError;
