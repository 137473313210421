// import getRequest from '../../../getRequest';
import postRequest from '../../../../postRequest';

/**
 * Post request to update product attributes on the PDP.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} languageId Language id selected
 */
export default function postProductAttributes(
  siteId,
  productId,
  languageId,
  updatedAttributesArr,
  successMsg,
) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/${languageId}/update-product-attributes`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, updatedAttributesArr, 'Update Product Attributes', successMsg)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
