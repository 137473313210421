import patchRequest from '../../../../patchRequest';

/**
 * Patch request to disable a category.
 *
 * @param {number} categoryId 1
 * @param {number} siteId 1
 */
export default function disableCategory(
  categoryId,
  siteId,
) {
  const endpoint = `/oc-data-management/v1/category/${categoryId}/${siteId}/disable`;
  const errorMessageHeader = 'Disable category';

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
