import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataProductNameObject,
} from '../../../../../redux/wizard/nike/nike_wizard';

function ProductNames({ sites, loading }) {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);

  const { activeStep, product_name } = nike_wizard.productData;

  const [productName, setProductName] = React.useState({
    name: '',
    translations: sites.map((site) => site.countries.map((country) => ({
      country_code: country,
      translation: '',
    }))).flat(),
  });
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const addedCountries = {
      ...product_name,
      translations: sites.map((site) => site.countries.map((country) => ({
        translation: product_name.translations.find((item) => item.country_code
          === country)?.translation || '',
        country_code: country,
      }))).flat(),
    };
    setProductName(addedCountries);
    dispatchToRedux(setProductDataProductNameObject(addedCountries));
  }, []);

  const handleTranslations = (original, setter) => sites.map((site) => site.countries
    .map(async (country) => {
      setIsLoading(true);
      if (country === 'GB' || country === 'IE') {
        setProductName((prev) => ({
          ...prev,
          translations: prev.translations.map((translation) => {
            if (translation.country_code === country) {
              return {
                ...translation,
                translation: original,
              };
            }
            return translation;
          }),
        }));
      }
      const { data } = await axios.post('https://translationuatapi.cloud.jdplc.com/translate', {
        ObjectId: 0,
        Language: country,
        Text: original,
      }, { withCredentials: false })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
      setIsLoading(false);

      setter((prev) => ({
        ...prev,
        translations: prev.translations.map((translation) => {
          if (translation.country_code === country && translation.translation === '') {
            return {
              ...translation,
              translation: data.text,
            };
          }
          return translation;
        }),
      }));
    }));

  return (
    <section>
      <Typography
        variant="h5"
        className="font-gothammedium text-neutral-800 border-b border-neutral-300"
        gutterBottom
      >
        Product Name
      </Typography>
      <Typography variant="body1" className="text-neutral-800" marginBottom={4}>
        If translations don&apos;t load please click the translate button.
      </Typography>
      <Stack direction="column" spacing={2}>
        <TextField
          label="Enter Product Name"
          placeholder="Click transaltion button to auto translate."
          disabled={loading.loading}
          fullWidth
          required
          error={productName.name === ''}
          onChange={(e) => setProductName({ ...productName, name: e.target.value })}
          value={productName.name}
          InputProps={{
            endAdornment: sites[0].countries.length > 1 && (
            <InputAdornment position="end">
              <Button
                variant="outlined"
                color="info"
                size="small"
                onClick={() => handleTranslations(productName.name, setProductName)}
              >
                Translate
              </Button>
            </InputAdornment>
            ),
          }}
        />
        {sites.map((site) => site.countries.map((country) => country !== 'GB' && (
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            key={`flag-${country}`}
          >
            <img
              loading="lazy"
              width="40"
              src={`https://flagcdn.com/w40/${country.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`}
              alt=""
            />
            <TextField
              label={`${country} Translation`}
              placeholder="Click translate button for auto translation."
              disabled={isLoading}
              fullWidth
              value={productName.translations
                .find((translation) => translation.country_code === country)?.translation}
              onChange={(e) => setProductName({
                ...productName,
                translations: productName.translations
                  .map((translation) => (translation.country_code === country
                    ? {
                      ...translation,
                      translation: e.target.value,
                    } : translation)),
              })}
            />
          </Stack>
        )))}
      </Stack>
      <Stack direction="row" spacing={2} className="float-right my-4">
        <Button
          variant="text"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep - 1));
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep + 1));
            dispatchToRedux(setProductDataProductNameObject(productName));
            if (product_name !== productName.name) {
              dispatchToRedux(setEditedValues('productName'));
            }
            const stateTranslations = productName.translations;
            const reduxTranslations = product_name.translations;
            stateTranslations.forEach((state) => {
              const reduxTranslation = reduxTranslations
                .find((redux) => state.country_code === redux.country_code);
              if (state?.translation !== reduxTranslation.translation) {
                dispatchToRedux(setEditedValues(`productNameTranslation-${reduxTranslation.country_code}`));
              }
            });
          }}
        >
          Confirm
        </Button>
      </Stack>
    </section>
  );
}

export default ProductNames;
