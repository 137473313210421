import patchRequest from '../../../patchRequest';

/**
 * Product information. Designed for the PDP.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} variantId Id of the variant
 * @param {boolean} status Approved or unapproved
 */
export default function patchVariants(
  productId,
  siteId,
  status,
  variantId,
) {
  const variantIdQuery = variantId ? `?variantId=${variantId}` : '';
  const statusQuery = `status=${status}`;
  const querySeparator = variantId ? '&' : '?';
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/product-variant-status${variantIdQuery}${querySeparator}${statusQuery}`;
  const isApproved = status ? 'Successfully approved product' : 'Successfully unapproved product';
  const successMsg = variantId ? `Successfully updated variant: ${variantId} for product: ${productId}` : isApproved;
  return new Promise((resolve, reject) => {
    patchRequest(endpoint, {}, 'Variants', successMsg)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
