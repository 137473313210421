import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TablePagination from '@mui/material/TablePagination';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import postDropshipment from '../../../../api/intergration/pim/products/dropshipment/posts/postDropshipment';
import postSites from '../../../../api/intergration/pim/products/dropshipment/posts/postSites';
import getLogs from '../../../../api/intergration/shogun/logs/gets/getLogs';
import getShogunResponseCodes from '../../../../api/intergration/shogun/shogun_response_codes/gets/getShogunResponseCodes';
import { removePendingSubstring } from '../../../../axios/pendingRequests';
import { fetchProductData } from '../../../../redux/wizard/nike/selected_product_data';
import isEmpty from '../../../../utils/isEmpty';
import Image from '../../../image/Image';
import Table from '../../../Table';
import VirtualizedIntergrationCategoryAutocomplete from '../../../VirtualizedIntergrationCategoryAutocomplete';
import ErrorModal from './modals/ErrorModal';

/**
 * TAB FUNCTIONS
 *
 * @param {number} index Index of the tab.
 * @param {number} value Index of the selected tab.
 */
function TabPanel({
  children, value, index, ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box component="div">{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
/**
 * Accessiblity values for tab component.
 *
 * @param {number} index Index of the tab.
 * @returns {object} Id and aria-controls props.
 */
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * MAIN COMPONENT
 *
 * @param {Func} onSubmit Emitted to parent when function is called.
 * @param {Func} onChange Emitted to parent when function is called.
 * @param {Func} onMount Emitted to parent when function is called.
 */
export default function FindProduct({ onSubmit, onChange, onMount }) {
  const reduxDispatch = useDispatch();
  const isDesktop = useMediaQuery('(min-width:600px)');

  /**
   * ----------
   *   STATES
   * ----------
   */

  // Errors to show user.
  const [error, setError] = React.useState(null);
  const [requestError, setRequestError] = React.useState('');

  // Product Id or PLU that the user filters the tables by.
  const [filterProduct, setFilterProduct] = React.useState('');

  // In-Review Table states.
  const [newTableRows, setNewTableRows] = React.useState([]);
  const [, setAllNewProductRows] = React.useState([]);
  const [rowsPerPageNewProducts, setRowsPerPageNewProducts] = React.useState(20);
  const [newProductsCurrentPage, setNewProductsCurrentPage] = React.useState(1);
  const [newProductsTotalCount, setNewProductsTotalCount] = React.useState(0);
  const [newTableLoading, setNewTableLoading] = React.useState(false);
  // Success Table states.
  const [successTableRows, setSuccessTableRows] = React.useState([]);
  const [, setAllSuccessProductRows] = React.useState([]);
  const [rowsPerPageSuccessProducts, setRowsPerPageSuccessProducts] = React.useState(20);
  const [successProductsCurrentPage, setSuccessProductsCurrentPage] = React.useState(1);
  const [successProductsTotalCount, setSuccessProductsTotalCount] = React.useState(0);
  const [successTableLoading, setSuccessTableLoading] = React.useState(false);

  // Failed-Submission Table states.
  const [, setAllErroredProductRows] = React.useState([]);
  const [erroredTableRows, setErroredTableRows] = React.useState([]);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [rowsPerPageErroredProducts, setRowsPerPageErroredProducts] = React.useState(20);
  const [erroredProductsCurrentPage, setErroredProductsCurrentPage] = React.useState(1);
  const [erroredProductsTotalCount, setErroredProductsTotalCount] = React.useState(0);
  const [erroredTableLoading, setErroredTableLoading] = React.useState(false);

  // States to pass into model
  const [errorModal, setErrorModal] = React.useState(false);
  const [modalRows, setErrorModalRows] = React.useState([]);

  // Plu of selected product that will appear in top-right corner
  const [productPlu, setProductPlu] = React.useState('');

  // Filter by category states
  const [selectedFilter, setSelectedFilter] = React.useState({
    Id: '',
    CategoryName: 'All',
    CategoryFullPath: 'All',
  });
  const [loadingCategories, setLoadingCategories] = React.useState(false);

  // TabPanel states
  const [selectedTab, setSelectedTab] = React.useState(0);

  // Filter by ErrorCode states
  const [errorCodes, setErrorCodes] = React.useState([
    {
      Id: '',
      Description: 'All',
    },
  ]);
  const [selectedErrorCode, setSelectedErrorCode] = React.useState({
    Id: '',
    Description: 'All',
  });

  /**
   * --------------------
   *   USER INTERACTION
   * --------------------
   */

  /**
   * Takes ProductId to make request for the products sites and then calls the onChange callback.
   *
   * @param {number} id ProductId the user clicked on.
   */
  const handleProductClickedRequest = (id) => {
    postSites(id, 'JD')
      .then(({ data }) => {
        if (data?.Data.length <= 0) setError('No Product Site Data Found.');
        if (data?.Data.length > 0) {
          // group data by fascia e.g. { JD: ['GB','IT','FR'] }
          const groupedData = data?.Data.reduce((previousValue, currentValue) => {
            const { LeadSiteCode } = currentValue;
            if (!previousValue[LeadSiteCode]) {
              // eslint-disable-next-line no-param-reassign
              previousValue[LeadSiteCode] = [];
            }
            previousValue[LeadSiteCode].push({
              country: currentValue.Country,
              siteId: currentValue.SiteId,
            });
            return previousValue;
          }, {});

          const fascias = Object.entries(groupedData);
          const mappedFascias = fascias.map(([key, values], index) => ({
            id: index,
            fascia: key,
            countries: values.map((v) => v.country),
            sitesIds: values.map((v) => v.siteId),
          }));

          onChange(mappedFascias);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response?.data.message);
        toast(err.response?.data.message, {
          type: 'error',
        });
      });
  };

  /**
   * --------------
   *   TABLE DATA
   * --------------
   */

  /**
   * Generates list for In-Review products table.
   *
   * @param {string} siteCode
   * @param {number} productId
   * @param {string} pluId
   * @param {number} categoryId
   */
  const newDropshipmentProducts = (
    siteCode,
    productId,
    pluId,
    categoryId,
  ) => {
    removePendingSubstring('statusCategory=1');
    setNewTableLoading(true);
    const fields = new Map([
      ['siteCode', siteCode],
      ['productId', productId],
      ['pluId', pluId],
      ['categoryId', categoryId],
      ['statusCategory', 1],
    ]);
    fields.forEach((value, key) => {
      if (isEmpty(value)) fields.delete(key);
      return value;
    });
    const body = Object.fromEntries(fields);
    postDropshipment(body, newProductsCurrentPage, rowsPerPageNewProducts)
      .then((res) => {
        // if (res.cancelled) setNewTableLoading(true);
        const resData = res.data.Data;
        const mappedData = resData.map(async (item) => {
          const product = resData.find((dropship) => dropship.ProductId === item.ProductId);
          product.ProductImageUrl = item?.ProductImageUrl ? (
            <div className="flex items-center justify-center">
              <Image src={item.ProductImageUrl} width="40px" height="48px" />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <Typography variant="body2">
                No Image Available
              </Typography>
            </div>
          );
          product.action = (
            <IconButton
              onClick={() => {
                reduxDispatch(fetchProductData({ productId: item.ProductId, statusCategory: 1 }));
                setFilterProduct(item.ProductId);
                onSubmit({ productId: item.ProductId, productPlu: item.PluId });
                handleProductClickedRequest(item.ProductId);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          );
          return product;
        });
        Promise.all(mappedData)
          .then((completedData) => {
            setNewTableRows(completedData);
            setAllNewProductRows(completedData);
          })
          .catch((err) => {
            console.error(err);
            setError(err.response?.data.message);
            toast(err.response?.data.message, {
              type: 'error',
            });
          })
          .finally(() => setNewTableLoading(false));
        setRowsPerPageNewProducts(res.data.Pagination.PerPage);
        setNewProductsCurrentPage(res.data.Pagination.Page);
        setNewProductsTotalCount(res.data.Pagination.TotalCount);
      })
      .catch((err) => {
        console.error(err);
        setError(err.response?.data.message);
        toast(err, {
          type: 'error',
        });
      });
  };
  const successDropshipmentProducts = (
    siteCode,
    productId,
    pluId,
    categoryId,
  ) => {
    removePendingSubstring('statusCategory=0');
    setSuccessTableLoading(true);
    const fields = new Map([
      ['siteCode', siteCode],
      ['productId', productId],
      ['pluId', pluId],
      ['categoryId', categoryId],
    ]);
    fields.forEach((value, key) => {
      if (isEmpty(value)) fields.delete(key);
      return value;
    });
    fields.set('statusCategory', 0);
    const body = Object.fromEntries(fields);
    postDropshipment(body, successProductsCurrentPage, rowsPerPageSuccessProducts)
      .then(({ data }) => {
        const resData = data.Data;
        const mappedData = resData.map(async (item) => {
          const product = resData.find((dropship) => dropship.ProductId === item.ProductId);
          product.ProductImageUrl = item?.ProductImageUrl ? (
            <div className="flex items-center justify-center">
              <Image src={item.ProductImageUrl} width="40px" height="48px" />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <Typography variant="body2">
                No Image Available
              </Typography>
            </div>
          );
          product.action = (
            <IconButton
              onClick={() => {
                reduxDispatch(fetchProductData({ productId: item.ProductId, statusCategory: 0 }));
                setFilterProduct(item.ProductId);
                onSubmit({ productId: item.ProductId, productPlu: item.PluId });
                handleProductClickedRequest(item.ProductId);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          );
          return product;
        });
        Promise.all(mappedData)
          .then((res) => {
            setSuccessTableRows(res);
            setAllSuccessProductRows(res);
          })
          .catch((err) => {
            console.error(err);
            setError(err.response?.data.message);
            toast(err.response?.data.message, {
              type: 'error',
            });
          })
          .finally(() => setSuccessTableLoading(false));
        setRowsPerPageSuccessProducts(data.Pagination.PerPage);
        setSuccessProductsCurrentPage(data.Pagination.Page);
        setSuccessProductsTotalCount(data.Pagination.TotalCount);
      })
      .catch((err) => {
        // If the error message contains the word Cancel then dont set loading to false.
        // Reason for this is because if a request is cancelled, another (similar) request will
        // be made and that needs the loading bar to be visable.
        if (axios.isCancel(err) === false) {
          setSuccessTableLoading(false);
        }
        console.error(err);
        setError(err.response?.data.message);
        toast(err.response?.data.message, {
          type: 'error',
        });
      });
  };

  const erroredDropshipmentProducts = async (
    siteCode,
    productId,
    pluId,
    categoryId,
    shogunTransferResult,
  ) => {
    removePendingSubstring('statusCategory=2');
    setErroredTableLoading(true);
    const erroredProductFields = new Map([
      ['siteCode', siteCode],
      ['productId', productId],
      ['pluId', pluId],
      ['categoryId', categoryId],
      ['statusCategory', 2],
      ['shogunTransferResult', shogunTransferResult],
    ]);
    erroredProductFields.forEach((value, key) => {
      if (isEmpty(value)) erroredProductFields.delete(key);
      return value;
    });
    const erroredProductsBody = Object.fromEntries(erroredProductFields);
    postDropshipment(
      erroredProductsBody,
      erroredProductsCurrentPage,
      rowsPerPageErroredProducts,
    )
      .then(({ data }) => {
        const resData = data.Data;
        const mappedData = resData.map(async (item) => {
          const product = resData.find((dropship) => dropship.ProductId === item.ProductId);
          product.ProductImageUrl = item?.ProductImageUrl ? (
            <div className="flex items-center justify-center">
              <Image src={item.ProductImageUrl} width="40px" height="48px" />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <Typography variant="body2">
                No Image Available
              </Typography>
            </div>
          );
          product.error = Number(product.ShogunTransferResult) < 0 ? (
            <p>
              {`${product.ShogunTransferResult}`}
            </p>
          ) : (
            <Tooltip arrow placement="top" title="Click to see more details">
              <IconButton
                onClick={async () => {
                  setErrorLoading(true);
                  setErrorModalRows([]);
                  setProductPlu(item.PluId);
                  setErrorModal(true);
                  await getLogs(item.ShogunTransferMongoLogId)
                    .then((res) => {
                      setErrorModalRows(res.data);
                    })
                    .catch((err) => {
                      setRequestError(err.response?.data.message);
                      return ({ data: { ErrorMessage: err.message } });
                    })
                    .finally(() => setErrorLoading(false));
                }}
                key={item.ShogunTransferMongoLogId}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          );
          product.action = (
            <IconButton
              onClick={() => {
                reduxDispatch(fetchProductData({ productId: item.ProductId, statusCategory: 2 }));
                setFilterProduct(item.ProductId);
                onSubmit({ productId: item.ProductId, productPlu: item.PluId });
                handleProductClickedRequest(item.ProductId);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          );
          return product;
        });
        Promise.all(mappedData)
          .then((res) => {
            setErroredTableRows(res);
            setAllErroredProductRows(res);
          })
          .catch((err) => {
            console.error(err);
            setError(err.response?.data.message);
            toast(err.response?.data.message, {
              type: 'error',
            });
          })
          .finally(() => setErroredTableLoading(false));
        setErroredProductsCurrentPage(data.Pagination.Page);
        setErroredProductsTotalCount(data.Pagination.TotalCount);
      })
      .catch((err) => {
        // If the error message contains the word Cancel then dont set loading to false.
        // Reason for this is because if a request is cancelled, another (similar) request will
        // be made and that needs the loading bar to be visable.
        if (axios.isCancel(err) === false) {
          setErroredTableLoading(false);
        }
        console.error(err);
        setError(err.response?.data.message);
        toast(err.response?.data.message, {
          type: 'error',
        });
      });
  };

  /**
   * ---------------
   *   FILTER DATA
   * ---------------
   */

  const handleFilter = (reset) => {
    if (selectedTab === 0) {
      // if (filterProduct.length === 0) setNewTableRows(allNewProductRows);
      // If filtering by PLU
      if (filterProduct?.includes('-') || /[a-zA-Z]/g.test(filterProduct)) {
        newDropshipmentProducts(
          'JD',
          null,
          reset ? '' : filterProduct,
          selectedFilter.PimCategoryId,
          null, // Always null otherwise it will return errored products
        );
      } else {
        newDropshipmentProducts(
          'JD',
          reset ? '' : filterProduct,
          null,
          selectedFilter.PimCategoryId,
          null, // Always null otherwise it will return errored products
        );
      }
    } else if (selectedTab === 1) {
      // if (filterProduct.length === 0) setErroredTableRows(allErroredProductRows);
      if (filterProduct?.includes('-') || /[a-zA-Z]/g.test(filterProduct)) {
        erroredDropshipmentProducts(
          'JD',
          null,
          reset ? '' : filterProduct,
          selectedFilter.PimCategoryId,
          selectedErrorCode.Id,
        );
      } else {
        erroredDropshipmentProducts(
          'JD',
          reset ? '' : filterProduct,
          null,
          selectedFilter.PimCategoryId,
          selectedErrorCode.Id,
        );
      }
    } else if (selectedTab === 2) {
      // if (filterProduct.length === 0) setNewTableRows(allSuccessProductRows);
      // If filtering by PLU
      if (filterProduct?.includes('-') || /[a-zA-Z]/g.test(filterProduct)) {
        successDropshipmentProducts(
          'JD',
          null,
          reset ? '' : filterProduct,
          selectedFilter.PimCategoryId,
          null, // Always null otherwise it will return errored products
        );
      } else {
        successDropshipmentProducts(
          'JD',
          reset ? '' : filterProduct,
          null,
          selectedFilter.PimCategoryId,
          null, // Always null otherwise it will return errored products
        );
      }
    }
  };

  const handleReset = () => {
    setFilterProduct('');
    newDropshipmentProducts(
      'JD',
      null,
      null,
      selectedFilter.PimCategoryId,
      null, // Always null otherwise it will return errored products
    );
    erroredDropshipmentProducts(
      'JD', // SiteCode
      null, // ProductId
      null, // PLU Id
      selectedFilter.PimCategoryId, // Category Id
      selectedErrorCode.Id, // Shogun Transfer Result
    );
    successDropshipmentProducts(
      'JD',
      null,
      null,
      selectedFilter.PimCategoryId,
      null, // Always null otherwise it will return errored products
    );
  };

  const handleCategoryFilterChange = (value) => {
    if (value == null) {
      setSelectedFilter({
        id: '',
        CategoryName: '',
        CategoryFullPath: '',
      });
    } else {
      setSelectedFilter(value);
    }
    setNewProductsCurrentPage(1);
    setErroredProductsCurrentPage(1);
    setSuccessProductsCurrentPage(1);
    setRowsPerPageNewProducts(20);
    setRowsPerPageErroredProducts(20);
    setRowsPerPageSuccessProducts(20);
  };

  const handleErrorCodeFilterChange = (value) => {
    if (value == null) {
      setSelectedErrorCode({
        Id: '',
        Description: 'All',
      });
    } else {
      setSelectedErrorCode(value);
    }
    setNewProductsCurrentPage(1);
    setErroredProductsCurrentPage(1);
    setSuccessProductsCurrentPage(1);
    setRowsPerPageNewProducts(20);
    setRowsPerPageErroredProducts(20);
    setRowsPerPageSuccessProducts(20);
  };

  // ==================================================
  // ====               USE EFFECTS                ====
  // ==================================================

  React.useEffect(() => {
    onMount(true);
    setLoadingCategories(true);
    getShogunResponseCodes()
      .then((res) => {
        setErrorCodes([
          ...res.data,
          {
            Id: '',
            Description: 'All',
          },
        ]);
      })
      .catch((err) => {
        toast.error(`Error: Shogun Codes - ${err.message}`, {
          autoClose: 30000,
        });
        console.error(err.message);
      });
    erroredDropshipmentProducts(
      'JD', // SiteCode
      null, // ProductId
      null, // PLU Id
      null, // Category Id
      null, // Shogun Transfer Result
    );
    successDropshipmentProducts(
      'JD',
      null,
      null,
      null,
      null,
    );
  }, []);

  React.useEffect(() => {
    handleFilter();
  }, [
    // Dont include filterProduct as it will run this whilst user types
    selectedFilter,
    selectedErrorCode,
    // Watch for pagination changes
    newProductsCurrentPage,
    erroredProductsCurrentPage,
    successProductsCurrentPage,
    rowsPerPageNewProducts,
    rowsPerPageErroredProducts,
    rowsPerPageSuccessProducts,
  ]);

  return (
    <Grid container px={isDesktop ? 6 : 2} spacing={2}>
      <ErrorModal
        errorModal={errorModal}
        modalRows={modalRows}
        cancelClicked={() => setErrorModal(false)}
        productId={filterProduct}
        productPlu={productPlu}
        loading={errorLoading}
        requestError={requestError}
      />
      <Grid item xs={12}>
        <Typography variant="h6" className="font-gothammedium" marginTop={isDesktop ? 4 : 2} gutterBottom={isDesktop}>
          Find The Product
        </Typography>
        <Typography variant="body2" className="font-gothambook" color="error">
          {error}
        </Typography>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Stack direction={isDesktop ? 'row' : 'column'} spacing={2}>
            <Stack direction="row" spacing={isDesktop ? 2 : 1} alignItems="center" className="w-full">
              <TextField
                label="Product ID / PLU"
                size="small"
                value={filterProduct}
                onChange={(event) => {
                  const removedWhiteSpace = event.target.value.trim();
                  setFilterProduct(removedWhiteSpace);
                }}
                error={!!error}
              />
              <Button
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  if ((newProductsCurrentPage === 1 && selectedTab === 0)
                    || (erroredProductsCurrentPage === 1 && selectedTab === 1)
                    || (successProductsCurrentPage === 1 && selectedTab === 2)) {
                    handleFilter();
                  }
                  setNewProductsCurrentPage(1);
                  setErroredProductsCurrentPage(1);
                  setSuccessProductsCurrentPage(1);
                }}
              >
                Search
              </Button>
              <Button variant="text" color="info" size="small" onClick={() => handleReset()}>
                Reset
              </Button>
            </Stack>

            <VirtualizedIntergrationCategoryAutocomplete
              loading={loadingCategories}
              label="Category Filter"
              size="small"
              onChange={(event, value) => handleCategoryFilterChange(value)}
              className="w-1/2"
              value={selectedFilter}
            />

            {selectedTab === 1 && (
              <Autocomplete
                value={selectedErrorCode}
                size="small"
                options={
                  (errorCodes.sort((a, b) => a.Id - b.Id)).filter((errorCode) => errorCode.Id !== 0)
                }
                getOptionLabel={(option) => (option.Description === 'All'
                  ? option.Description
                  : `${option.Id} - ${option.Description}`)}
                onChange={(event, value) => handleErrorCodeFilterChange(value)}
                className="w-1/2"
                sx={{ fontSize: '10px' }}
                componentsProps={{
                  popper: {
                    sx: {
                      width: 'max-content !important',
                    },
                  },
                }}
                renderInput={(params) => <TextField {...params} label="Error Filter" />}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className="w-full h-full">
          <Tabs
            value={selectedTab}
            onChange={(event, value) => setSelectedTab(value)}
            centered
            variant="fullWidth"
            className="rounded-t-lg"
            indicatorColor="secondary"
            classes={{ indicator: 'h-1' }}
          >
            <Tab
              label="In-Review"
              {...a11yProps(0)}
              className="text-white font-gothammedium"
            />
            <Tab
              label="Failed Submission"
              {...a11yProps(1)}
              className="text-white font-gothammedium"
            />
            <Tab
              label="Successful Submission"
              {...a11yProps(1)}
              className="text-white font-gothammedium"
            />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <Table
              loading={newTableLoading}
              columns={[
                { id: 'ProductImageUrl', label: 'Thumbnail', styles: { position: 'sticky !important' } },
                { id: 'ProductId', label: 'Product ID', styles: { position: 'sticky !important' } },
                { id: 'PluId', label: 'PLU', styles: { position: 'sticky !important' } },
                { id: 'action', label: '', styles: { position: 'sticky !important' } },
              ]}
              rows={newTableRows}
              maxHeight="45vh"
              className="border border-b-0"
            />
            <TablePagination
              component="div"
              rowsPerPage={rowsPerPageNewProducts}
              rowsPerPageOptions={[20, 40, 60, 100]}
              page={newProductsCurrentPage - 1}
              count={newProductsTotalCount}
              onPageChange={(event, page) => setNewProductsCurrentPage(page + 1)}
              onRowsPerPageChange={(event) => setRowsPerPageNewProducts(event.target.value)}
              className="border rounded-bl-lg rounded-br-lg"
            />
          </TabPanel>
          {/* Error Table */}
          <TabPanel value={selectedTab} index={1}>
            <Table
              loading={erroredTableLoading}
              columns={[
                { id: 'ProductImageUrl', label: 'Thumbnail', styles: { position: 'sticky !important' } },
                { id: 'ProductId', label: 'Product ID', styles: { position: 'sticky !important' } },
                { id: 'PluId', label: 'PLU', styles: { position: 'sticky !important' } },
                { id: 'error', label: 'Error', styles: { position: 'sticky !important' } },
                { id: 'action', label: '', styles: { position: 'sticky !important' } },
              ]}
              rows={erroredTableRows.filter((row) => row.ShogunTransferResult !== 0)}
              maxHeight="45vh"
              className="border border-b-0"
            />
            <TablePagination
              component="div"
              rowsPerPage={rowsPerPageErroredProducts}
              rowsPerPageOptions={[20, 40, 60, 100]}
              page={erroredProductsCurrentPage - 1}
              count={erroredProductsTotalCount}
              onPageChange={(event, page) => setErroredProductsCurrentPage(page + 1)}
              onRowsPerPageChange={(event) => setRowsPerPageErroredProducts(event.target.value)}
              className="border rounded-bl-lg rounded-br-lg"
            />
          </TabPanel>
          {/* Successful Table */}
          <TabPanel value={selectedTab} index={2}>
            <Table
              loading={successTableLoading}
              columns={[
                { id: 'ProductImageUrl', label: 'Thumbnail', styles: { position: 'sticky !important' } },
                { id: 'ProductId', label: 'Product ID', styles: { position: 'sticky !important' } },
                { id: 'PluId', label: 'PLU', styles: { position: 'sticky !important' } },
                { id: 'action', label: '', styles: { position: 'sticky !important' } },
              ]}
              rows={successTableRows}
              maxHeight="45vh"
              className="border border-b-0"
            />
            <TablePagination
              component="div"
              rowsPerPage={rowsPerPageSuccessProducts}
              rowsPerPageOptions={[20, 40, 60, 100]}
              page={successProductsCurrentPage - 1}
              count={successProductsTotalCount}
              onPageChange={(event, page) => setSuccessProductsCurrentPage(page + 1)}
              onRowsPerPageChange={(event) => setRowsPerPageSuccessProducts(event.target.value)}
              className="border rounded-bl-lg rounded-br-lg"
            />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
}
