import getRequest from '../../../../getRequest';

/**
 * Calls categories endpoint that gets existing categories in PIM DB.
 *
 * @param {number} page - 1
 * @param {number} size - 100
 * @param {boolean} selectOnlyMapped - Mapped categories with no null values.
 * @returns {Promise} { data: {}, status: 200 }
 */
async function getCategories(siteCode, selectOnlyMapped, page, size) {
  const queries = `selectOnlyMapped=${selectOnlyMapped}&Page=${page}&Size=${size}`;
  const endpoint = `/integration-pim/v1/categories/${siteCode}?${queries}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Getting Categories')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getCategories;
