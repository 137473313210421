import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React from 'react';

export default function WarningMessage({
  date, start, end, message,
}) {
  if (!date) {
    throw Error('Please provide a release date.');
  }
  return (
    <section className="absolute max-w-sm p-4 bg-white border rounded-lg top-4 right-4">
      <h1 className="flex text-lg items-base">
        <WarningRoundedIcon className="mr-2 text-error-500" />
        Scheduled Maintenance
      </h1>
      <div className="my-2">
        <p className="text-lg">
          {new Date(date).toLocaleDateString('en-US', {
            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
          })}
        </p>
        <p className="text-lg">
          {start}
          {' '}
          -
          {' '}
          {end}
          {' '}
          (GMT)
        </p>
      </div>
      <p className="text-lg text-red-600">{message}</p>
    </section>
  );
}
