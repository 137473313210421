import deleteRequest from '../../../deleteRequest';

/**
 * Deletes attribute from product in PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */

async function deleteIndex(SiteCodes, ProductIds) {
  const endpoint = '/oc-product/v1/index';
  const body = {
    SiteCodes,
    ProductIds,

  };
  const errorMessageHeader = `Delete ${ProductIds}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteIndex;
