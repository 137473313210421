import axios from 'axios';

import deleteProductCategory from '../../../api/intergration/pim/products/dropshipment/deletes/deleteProductCategory';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function deleteCategory(userEmail, nike_wizard, category) {
  return new Promise((resolve, reject) => {
    const foundCategory = nike_wizard.productData.categories.pim_category
      .find((x) => x.PimCategoryId === category.id);

    if (foundCategory == null) {
      deleteProductCategory(nike_wizard.productId, category.id)
        .then((res) => {
          if (res.data.Success) {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[INFO]: User ${userEmail} deleted CategoryId: ${category.id} from ProductId: ${nike_wizard.productId} - SiteCode: JD`,
            );
            resolve(res.data);
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to delete CategoryId: ${category.id} from ProductId: ${nike_wizard.productId} - SiteCode: JD. Returned Error Message: "${res.data.ErrorMessage}".`,
            );
            console.error(res.data.ErrorMessage);
            reject(res.data.ErrorMessage);
          }
        })
        .catch((err) => {
          // If the error message contains the word Cancel then dont do error stuff.
          // Reason for this is because if a request is cancelled, this isn't an error to do
          // with saving data so the user doesn't need to be notified of this.
          if (axios.isCancel(err)) {
            resolve();
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to delete CategoryId: ${category.id} from ProductId: ${nike_wizard.productId} - SiteCode: JD. Returned Error Message: "${err.message}".`,
            );
            console.error(err);
            reject(err);
          }
        });
    } else {
      resolve({ Success: true, ErrorMessage: null });
    }
  });
}

export default deleteCategory;
