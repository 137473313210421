import getRequest from '../../../getRequest';

/**
 * Get all Brand Groups.
 * @param {number} siteId - 1
 * @param {string} brandName - adidas
 * @param {string} brandGroupName - adidas
 * @returns [
 *     {
 *       "BrandGroupId": 1,
 *       "BrandGroupName": "adidas",
 *       "BrandCount": 11,
 *       "Created": "2023-11-21T15:10:50.267",
 *       "Updated": "0001-01-01T00:00:00"
 *     },
 *   ]
 */
export default async function getBrandGroups(siteId, brandType, searchValue) {
  let endpoint = `/oc-data-management/v1/brand-group/${siteId}`;

  if (brandType === 'group') {
    endpoint += `?brandGroupName=${searchValue}`;
  }

  if (brandType === 'brand') {
    endpoint += `?brandName=${searchValue}`;
  }

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Brand Groups')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
