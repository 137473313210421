import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getFascias from '../../api/oc_product/fascias/gets/getFascias';

// First, create the thunk
export const fetchFascias = createAsyncThunk(
  'fascias',
  async () => {
    try {
      const { data } = await getFascias();
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
);

const fasciasSlice = createSlice({
  initialState: [],
  name: 'fascias',
  reducers: {
    setFascias: (state, action) => {
      state.fascias = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFascias.fulfilled, (state, action) => action.payload);
  },
});

export const { setFascias } = fasciasSlice.actions;

export default fasciasSlice.reducer;
