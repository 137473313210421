import postRequest from '../../../../postRequest';

/**
 * Post request for product type attribute.
 *
 * @param {number} productTypeId productTypeId
 * @param {number} productAttributeDefId productAttributeDefId
 * @param {boolean} enabled enabled
 * @param {boolean} required enabled
 *
 */
export default function postProductTypeAttribute(
  productTypeId,
  productAttributeDefId,
  enabled,
  required,
) {
  const endpoint = `/oc-data-management/v1/product-type/attributes/${productTypeId}`;

  const body = {
    productAttributeDefId,
    enabled,
    required,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Product Type')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
