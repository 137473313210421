import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import getProductTypes from '../../../../api/oc_product/sites/product_types/gets/getProductTypes';

function NoProductTypeFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const hasProductType = searchParams.get('hasProductType');
  const fetchProductTypes = async () => {
    try {
      if (siteId === 1) {
        if (hasProductType === 'no') {
          const productTypesData = await getProductTypes(siteId);
          const typeIds = productTypesData.data.find((item) => item.Name === 'CORE JDUK')?.ProductTypeId;
          setSearchParams((params) => {
            params.set('productTypes', typeIds);
            return params;
          });
        } else {
          setSearchParams((params) => {
            params.delete('productTypes');
            return params;
          });
        }
      }
    } catch (error) {
      toast.error('Error fetching product types', error);
      console.error('Error fetching product types:', error);
    }
  };

  React.useEffect(() => {
    fetchProductTypes();
  }, [hasProductType]);

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Has Product Type</Typography>
        <div className="flex flex-col w-max">
          <RadioGroup
            aria-labelledby="hasproducttype-radio-buttons-group-label"
            defaultValue="all"
            value={hasProductType || 'all'}
            name="hasProductType-radio-buttons-group"
            onChange={(event) => {
              setSearchParams((params) => {
                params.set('hasProductType', event.target.value);
                if (event.target.value === 'all') params.delete('hasProductType');
                return params;
              });
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>
      </Stack>
    </div>
  );
}

export default NoProductTypeFilter;
