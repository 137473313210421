import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function DropshippableFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const dropshippable = searchParams.get('dropshippable');

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Dropshippable</Typography>
        <div className="flex flex-col w-max">
          <RadioGroup
            defaultValue="all"
            value={dropshippable || 'all'}
            name="dropshippable-radio-buttons-group"
            onChange={(event) => {
              setSearchParams((params) => {
                params.set('dropshippable', event.target.value);
                if (event.target.value === 'all') params.delete('dropshippable');
                return params;
              });
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>
      </Stack>
    </div>
  );
}
