import patchRequest from '../../../../patchRequest';
/**
 * Updates a product type attribute.
 *
 * @param {number} productTypeAttributeId
 * @param {boolean} enabled
 * @param {boolean} required
 * @returns {Promise} { Success: true }, status: 200 }
 */
export default async function patchProductTypeAttributes(
  productTypeAttributeId,
  enabled,
  required,
) {
  const endpoint = `/oc-data-management/v1/product-type/attributes/${productTypeAttributeId}/${enabled}/${required}`;

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, 'Update product type attribute')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
