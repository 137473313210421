import Container from '@mui/material/Container';
import React from 'react';

import Wizard from '../../../../components/wizard/Wizard';

function ProductWizard() {
  return (
    <Container maxWidth="xl" className="items-center justify-center h-full p-0 sm:flex">
      <Wizard />
    </Container>
  );
}

export default ProductWizard;
