import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  resetState,
  setEnableFulfilment,
  setProductData,
  setProductDataActiveStep,
  setProductId,
  setProductPlu,
  setProductsSites,
} from '../../redux/wizard/nike/nike_wizard';
import EnableFulfilment from './wizard_pages/enable_fulfilment/EnableFulfilment';
import FindProduct from './wizard_pages/find_product/FindProduct';
import ProductData from './wizard_pages/product_data/ProductData';
import Summary from './wizard_pages/summary/Summary';
import WhereToSell from './wizard_pages/where_to_sell/WhereToSell';

function Pages({
  pageName,
  nextButtonDisabledReasons,
  reset,
  forceNext,
  enableSubmit,
  save,
  shogunResponse,
  changeStep,
}) {
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const dispatchToRedux = useDispatch();

  useEffect(() => {
    dispatchToRedux(resetState());
  }, [reset]);

  switch (pageName) {
    case 'FindProduct':
      return (
        <FindProduct
          initialProductId={nike_wizard.productId}
          initialSites={nike_wizard.sites}
          onMount={() => {
            dispatchToRedux(resetState());
          }}
          onSubmit={(object) => {
            dispatchToRedux(setProductId(object.productId));
            dispatchToRedux(setProductPlu(object.productPlu));
          }}
          onChange={(value) => {
            dispatchToRedux(setProductsSites(value));
            forceNext();
          }}
        />
      );
    case 'WhereToSell':
      return (
        <WhereToSell
          initialSites={nike_wizard.sites}
          productId={nike_wizard.productId}
          onChange={(value) => {
            dispatchToRedux(setProductsSites(value));
          }}
        />
      );
    case 'ProductData':
      save(true);
      return (
        <ProductData
          initialProductData={nike_wizard.productData}
          reasons={nextButtonDisabledReasons}
          group={nike_wizard.sites}
          productId={nike_wizard.productId}
          onChange={(value) => {
            dispatchToRedux(setProductData(value));
          }}
        />
      );
    // NOT IN USE
    // case 'Translations':
    //   return (
    //     <Translations
    //       initialTranslations={state.translations}
    //       sites={state.sites}
    //       product={state.productData}
    //       onChange={(value) => dispatch({ type: 'TRANSLATIONS', payload: value })}
    //     />
    //   );
    // NOT IN USE
    // case 'SellingPrice': {
    //   return (
    //     <SellingPrice
    //       productId={state.productId}
    //       onChange={(value) => dispatch({ type: 'SELLING_PRICE', payload: value })}
    //     />
    //   );
    // }
    case 'EnableFulfilment':
      return (
        <EnableFulfilment
          productId={nike_wizard.productId}
          shogunResponse={shogunResponse}
          onChange={(value) => {
            dispatchToRedux(setEnableFulfilment(value));
          }}
        />
      );
    case 'Summary':
      enableSubmit(nike_wizard);
      return (
        <Summary
          state={nike_wizard}
          dispatch={(value) => {
            dispatchToRedux(setProductDataActiveStep(value.reducer.payload));
            changeStep(value.step);
          }}
        />
      );
    default:
      return (
        <Box p={4} className="flex flex-col justify-center gap-4 align-center">
          <Typography
            variant="h4"
            className="font-gothamlight"
            gutterBottom
            align="center"
            marginTop={4}
          >
            Error: You are not on a valid page.
          </Typography>
          <Typography
            variant="h4"
            className="font-gothamlight"
            gutterBottom
            align="center"
            marginTop={4}
          >
            Please refresh your browser and try again.
          </Typography>
          <Typography
            variant="h4"
            className="font-gothamlight"
            gutterBottom
            align="center"
            marginTop={4}
          >
            If you already have please contact FrontEnd Dev Team.
          </Typography>
        </Box>
      );
  }
}

export default Pages;
