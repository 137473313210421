import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a campaign to multiple products.
 *
 * @param {{
 *  referenceIds: string[],
 *  productAttributeDefId: number,
 *  productAttributeLookupOptionId: number[],
 *  languageId: number,
 *  siteId: number,
 *  siteGroupId: number
 * }}
 * @param {string[]} referenceIds Product Ids
 * @param {number} productAttributeDefId Attribute Id
 * @param {number[]} productAttributeLookupOptionId Lookup Option Id
 * @param {number} languageId Language Id
 * @param {number} siteId Site Id
 * @param {number} siteGroupId Id of the Site Group
*/
export default function postCampaignsProductId({
  referenceIds,
  productAttributeDefId,
  productAttributeLookupOptionId,
  languageId,
  siteId,
  siteGroupId,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/campaigns/product-id';

  const body = {
    referenceIds,
    productAttributeDefId,
    productAttributeLookupOptionId,
    languageId,
    siteId,
    siteGroupId,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Campaigns (ProductId)', 'Started : Campaigns : Prod ID (Update)')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
