import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';

import Modal from '../../../../components/Modal';

function EditModal({ open, onCloseModal }) {
  return (
    <Modal
      open={open}
      title="Confirm Name Change"
      onClose={() => onCloseModal(false)}
    >
      <Box className="flex flex-col items-center">
        <Typography className="mb-8 text-center">
          Changing the name of a brand will affect
          {' '}
          <b>ALL</b>
          {' '}
          products that are associated with it.
        </Typography>
      </Box>

      {/* Custom button section */}
      <Box className="flex justify-center items-center gap-6 mt-2">
        <Button onClick={() => onCloseModal(false)}>Cancel</Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onCloseModal(false);
          }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
}

export default EditModal;
