/* eslint-disable no-nested-ternary */
import Button from '@mui/material/Button';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

const SuccessTabRow = memo(({
  data,
  index,
  style,
  setSelected,
  setOpenModal,
}) => {
  const obj = data[index];

  const headerInfo = obj.HeaderInfo ? obj.HeaderInfo.replace(/\s*\(.*?\)\s*/g, ' ') : '';
  // Split the headerInfo by newline characters
  const items = headerInfo.split('\n').map((item) => item.trim()).filter((item) => item);

  // Extract the first item and count the remaining items
  const firstItem = items[0] || '';
  const remainingCount = items.length > 1 ? items.length - 1 : 0;

  // Create the display string
  const displayString = remainingCount > 0 ? `${firstItem} + ${remainingCount}` : firstItem;

  const operationTypes = useSelector((state) => state.bulk_operations.operation_types);

  const handleOperationName = (bulkOperationRequestType) => {
    const foundMatchingOperation = Object.keys(operationTypes ?? {}).find((key) => operationTypes[key] === bulkOperationRequestType);
    if (foundMatchingOperation) {
      return foundMatchingOperation;
    }
    return 'Operation Type Not Found';
  };

  const handleCrudType = (crudType) => {
    switch (crudType) {
      case 1:
        return 'Insert';
      case 2:
        return 'Read';
      case 3:
        return 'Update';
      case 4:
        return 'Delete';
      case 8:
        return 'Update';
      default:
        return 'CRUD Type Not Found';
    }
  };

  return (
    <section key={obj.Id} className="flex border-b border-neutral-400" style={style}>
      <div className="relative flex items-center w-full">
        <div className="flex flex-col w-3/4 gap-1">
          <p className="text-sm">
            <span className="font-gothammedium">ID:</span>
            {' '}
            {obj.Id}
          </p>
          <p className="text-sm">{obj.UserName}</p>
          <p className="text-sm">
            {handleOperationName(obj.BulkOperationRequestType)}
          </p>
          <p className="text-sm">
            {displayString}
          </p>

          <div className="flex items-center justify-start gap-4">
            <p>
              {handleCrudType(obj.CrudType)}
            </p>
            <span className="flex gap-[2px] whitespace-nowrap">
              <p>(</p>
              <p>{obj.Total}</p>
              <p>)</p>
            </span>
          </div>
          {obj.Status === 4 && (
          <p className="text-sm">
            U:
            {' '}
            {obj.Updated}
            , NC:
            {' '}
            {obj.NoChange}
            , F:
            {' '}
            {obj.Failed}
            , I:
            {' '}
            {obj.Inserted}
            , D:
            {' '}
            {obj.Deleted}
          </p>
          )}
          <p className="text-sm">
            Started:
            {' '}
            {new Date(obj.ProcessStartDate).toLocaleString('en-GB')}
          </p>
        </div>
        <div className="flex items-center justify-center w-1/4">
          <div>
            {obj.Status === 10 && <span className="ml-2" style={{ color: 'red' }}><strong>FAILED</strong></span>}
            {obj.Status === 4 && <span className="ml-2" style={{ color: 'orange' }}><strong>PARTIAL</strong></span>}
            {obj.Status === 5 && <span className="ml-2" style={{ color: 'green' }}><strong>PASSED</strong></span>}
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setSelected(obj);
                setOpenModal(true);
              }}
            >
              More Info
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}, areEqual);

export default SuccessTabRow;
