import deleteRequest from '../../../../../../deleteRequest';

/**
 * Bulk delete a campaign from multiple products.
 *
 * @param {{
 *  referenceIds: string[],
 *  productAttributeDefId: number,
 *  productAttributeLookupOptionId: number[],
 *  languageId: number,
 *  siteId: number,
 *  siteGroupId: number
 * }}
 * @param {string[]} referenceIds Product Ids
 * @param {number} productAttributeDefId Attribute Id
 * @param {number[]} productAttributeLookupOptionId Lookup Option Id
 * @param {number} languageId Language Id
 * @param {number} siteId Site Id
 * @param {number} siteGroupId Id of the Site Group
*/
export default async function deleteCampaignsProductId({
  referenceIds,
  productAttributeDefId,
  productAttributeLookupOptionId,
  languageId,
  siteId,
  siteGroupId,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/campaigns/product-id';

  const body = {
    referenceIds,
    productAttributeDefId,
    productAttributeLookupOptionId,
    languageId,
    siteId,
    siteGroupId,
  };

  const successMessage = 'Started : Campaigns : Prod ID (Delete)';
  const errorMessageHeader = `Failed to DELETE - DefId: ${productAttributeDefId} - LookupOptionId: ${productAttributeLookupOptionId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, errorMessageHeader, successMessage)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
