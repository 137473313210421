import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import VirtualizedCategorySelect from '../../../VirtualizedCategoryAutocomplete';

function CategoriesFilter() {
  const selectedSite = useSelector((state) => state.user.loginResponse.selectedSite);

  const [, setSearchParams] = useSearchParams();

  return (
    <div className="w-4/5 sm:w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Categories</Typography>
        <div className="flex flex-col w-full sm:w-[215px]">
          <VirtualizedCategorySelect
            id="category-virtualized-autocomplete"
            label="Categories"
            helperText="Select one or multiple"
            onChange={(e, value) => {
              setSearchParams((params) => {
                const categoryIds = value.map((obj) => obj.CategoryId);
                if (categoryIds.length > 0) {
                  params.set('categories', categoryIds);
                } else {
                  params.delete('categories');
                }
                return params;
              });
            }}
            multiple
            hideAllOption
            limitTags={2}
            size="small"
            siteCode={selectedSite.SiteCode}
          />
        </div>
      </Stack>
    </div>
  );
}

export default CategoriesFilter;
