import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import postProductNamesPluId from '../../../api/oc_product/bulk/bulk_process/product_names/upload/plu_id/posts/postProductNamesPluId';
import postProductNamesProductId from '../../../api/oc_product/bulk/bulk_process/product_names/upload/product_id/posts/postProductNamesProductId';
import getLanguages from '../../../api/oc_product/product/languages/gets/getLanguages';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import PluCsv from '../../../assets/bulk_templates/Bulk_Plu_Template.csv';
import ProductIdCsv from '../../../assets/bulk_templates/Bulk_Product_Id_Template.csv';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import doesObjectContainEmptyValues from '../../../utils/doesObjectContainEmptyValues';

const Input = styled('input')({ display: 'none' });

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONTENT_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'SOURCE':
      return {
        ...state,
        sourceId: action.payload,
      };
    case 'FILE':
      return {
        ...state,
        file: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};

const MAX_FILE_SIZE_MB = 5;

function ProductName({ refreshReports }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const fileInputRef = React.useRef(null);

  const initialState = {
    language: null,
    sourceId: null,
    file: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const [idType, setIdType] = React.useState('plu');

  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  const submitBtn = React.useRef(null);
  const [buttonWidth, setButtonWidth] = React.useState(0);
  const [buttonHeight, setButtonHeight] = React.useState(0);

  const [languagesOptions, setLanguagesOptions] = React.useState([]);
  const [sourceOptions, setSourceOptions] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.get('omsId');

  const handleSave = (e) => {
    e.preventDefault();
    if (idType === 'plu') {
      postProductNamesPluId({
        SiteId: siteId,
        SourceId: state.sourceId,
        LanguageId: state.language?.LanguageId,
        File: state.file,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    } else if (idType === 'productid') {
      postProductNamesProductId({
        SiteId: siteId,
        LanguageId: state.language?.LanguageId,
        File: state.file,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    }
  };

  React.useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input value
      dispatch({ type: 'FILE', payload: '' }); // Reset file state
      dispatch({ type: 'RESET', payload: initialState });
    }
    setIdType('plu');
    getLanguages(siteId)
      .then((res) => setLanguagesOptions(res.data))
      .catch((err) => console.error(err));
    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
    dispatch({ type: 'RESET', payload: initialState });
  }, [siteId]);

  React.useEffect(() => {
    if (submitBtn.current) {
      const sbmBtn = submitBtn.current.getBoundingClientRect();
      setButtonWidth(sbmBtn.width);
      setButtonHeight(sbmBtn.height);
    }
  }, [submitBtn.current]);

  React.useEffect(() => {
    if (idType !== 'plu') {
      delete state.sourceId;
    } else if (!state.sourceId) {
      state.sourceId = '';
    }
    const isEmpty = doesObjectContainEmptyValues(state);
    setSubmitDisabled(isEmpty);
  }, [state, idType]);

  React.useEffect(() => {
    if (state.file !== '' && state.file.type !== 'text/csv') {
      toast.error('Please upload CSV file');
    }

    if (state.file.type !== 'text/csv') {
      const isEmpty = doesObjectContainEmptyValues(state);
      setSubmitDisabled(isEmpty);
      state.file = '';
    }

    if (state.file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.error('File size is too big. Please upload a file smaller than 5MB.');
      const isEmpty = doesObjectContainEmptyValues(state);
      setSubmitDisabled(isEmpty);
      state.file = '';
    }
  }, [state.file]);

  React.useEffect(() => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
         * For some reason we have to do this...
         * If you do the, if not then delete, statement below within the for above
         * or do params.forEach
         * The loop with miss every even indexed key...
         */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  }, []);

  return (
    <div className="flex flex-col items-start justify-start w-full gap-6 mt-4 xl:flex-row">
      <div className="flex flex-col">
        <div
          className="mb-8 bg-white border rounded-lg right-8"
        >
          <div className="flex flex-col w-full h-full p-4">
            <span className="text-lg">Download Templates</span>
            <a
              href={PluCsv}
              download="Bulk_Plu_Template.csv"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-700"
            >
              Plu Template
            </a>
            <a
              href={ProductIdCsv}
              download="Bulk_Product_Id_Template.csv"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-700"
            >
              Prod Id Template
            </a>
          </div>
        </div>
        <section>
          <div className="flex-1 p-4 mb-6 text-left bg-white border-2 rounded w-96 border-error-600">
            <Typography gutterBottom className="font-gothammedium">
              Important
            </Typography>
            <ul className="ml-4">
              <li>
                Product names will be updated in the selected Content Language.
              </li>
              <li>
                Dates should be provided in either format:
                <ul className="ml-4">
                  <li><i>dd/MM/yyyy</i></li>
                  <li><i>dd/MM/yyyy hh:mm</i></li>
                </ul>
              </li>
              <li>
                The delimited in the CSV file is
                {' '}
                <code>|</code>
              </li>
              <li>
                Only valid entries will be saved.
              </li>
            </ul>
          </div>
        </section>
      </div>

      <Container maxWidth="md">
        <section className="w-full">
          <form className="flex h-[calc(100vh-185px)] flex-col flex-wrap gap-8" onSubmit={handleSave}>
            <section className="flex flex-col max-w-sm gap-8">
              <div key="step1">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      1
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Select Content Language
                  </Typography>
                </div>

                <Box sx={{ paddingTop: '1rem' }}>
                  <VirtualizedAutocomplete
                    options={languagesOptions.map((obj) => ({ ...obj, Name: obj.LanguageName }))}
                    textFieldLabel="Content Language"
                    textFieldVariant="outlined"
                    value={state.language}
                    getOptionLabel={(option) => option.LanguageName}
                    onSelectedValue={(value) => dispatch({ type: 'CONTENT_LANGUAGE', payload: value })}
                    optionKey="Name"
                  />
                </Box>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      2
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Select PLU and Source or Product ID
                  </Typography>
                </div>
                <Box className="flex justify-start">
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      aria-label="type"
                      defaultValue="plu"
                      name="radio-buttons-group"
                      onChange={(e) => setIdType(e.target.value)}
                      value={idType}
                      sx={{ mb: 2 }}
                    >
                      <FormControlLabel value="plu" control={<Radio />} label="PLU" />
                      <FormControlLabel
                        value="productid"
                        control={<Radio />}
                        label="Product ID"
                      />
                    </RadioGroup>
                    {idType === 'plu' ? (
                      <VirtualizedAutocomplete
                        options={sourceOptions}
                        textFieldLabel="Sources"
                        textFieldVariant="outlined"
                        value={sourceOptions.find((obj) => obj?.SourceId === state.sourceId) || null}
                        getOptionLabel={(option) => option.Name}
                        onSelectedValue={(value) => dispatch({ type: 'SOURCE', payload: value?.SourceId })}
                        optionKey="Name"
                      />
                    ) : (
                      <div className="hidden" />
                    )}
                  </FormControl>
                </Box>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      3
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Upload File
                  </Typography>
                </div>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                  }}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept=".csv"
                      id="contained-button-file"
                      type="file"
                      ref={fileInputRef}
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          dispatch({ type: 'FILE', payload: e.target.files[0] });
                        }
                      }}
                    />
                    <Button disabled={idType === 'plu' && !state.sourceId} variant="outlined" component="span" color="info">
                      Choose File
                    </Button>
                  </label>
                  <TextField
                    disabled
                    value={state.file?.name || ''}
                    variant="standard"
                    placeholder="No File Chosen"
                  />
                  {state.file && (
                    <CancelIcon
                      className="cursor-pointer text-error-400"
                      onClick={() => {
                        dispatch({ type: 'FILE', payload: '' });
                        fileInputRef.current.value = '';
                      }}
                    />
                  )}
                </Box>

                <div className="w-full">
                  <div className="relative float-right w-max">
                    <Button variant="contained" ref={submitBtn} className="z-10" disabled={submitDisabled} type="submit">
                      Submit
                    </Button>
                    {submitDisabled ? null : (
                      <div
                        className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 bg-primary-400 animate-ping"
                        style={{ width: `${buttonWidth / 1.5}px`, height: `${buttonHeight / 1.25}px` }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </form>
        </section>
      </Container>
    </div>
  );
}

export default ProductName;
