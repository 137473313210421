import getRequest from '../../../../getRequest';

/**
 * Get category details.
 * @param {number} siteId - 1
 * @param {number} categoryId - 123
 * @returns {
 * "RemoteIdSource": "656934 - Shogun, 597285 - FluentJD, 607826 - AnatwineUK",
 * "Translations": "English - Children Clothing (3-7 Year)",
 * "ProductCounts": 10408,
 * "CategoryGroupingName": ""
 * }
 */
export default async function getCategoryDetails(siteId, categoryId) {
  const endpoint = `/oc-data-management/v1/category/${categoryId}/${siteId}/details`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Category Details')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
