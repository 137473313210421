import patchRequest from '../../../../patchRequest';
/**
 * Updates all variant price and vat.
 *
 * @param {number} productId - Product ID
 * @param {number} siteId - Site ID
 * @param {array} vatArray - Array of VAT rows
 * @param {array} priceArray - Array of price rows
 * @returns {Promise} Promise resolving to the response data
 */

async function patchPriceAndVat(
  productId,
  siteId,
  requestBody,
) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/dropship-vatprice-update`;
  return new Promise((resolve, reject) => {
    patchRequest(endpoint, requestBody, 'Update the dropship vat and Price')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchPriceAndVat;
