import getRequest from '../../../getRequest';

/**
 * Get all product types for specific product.
 *
 * @param {number} productId
 * @param {number} siteId
 * @param {number} languageId
 * @returns [
 *   {
 *     "Name": "Test_Type",
 *     "ProductSiteId": 518637,
 *     "ProductTypeId": 388,
 *     "ProductProductTypeId": 1022273,
 *     "LanguageId": 1
 *   },
 *   {
 *     "Name": "CORE JDUK",
 *     "ProductSiteId": 518637,
 *     "ProductTypeId": 720,
 *     "ProductProductTypeId": 1022274,
 *     "LanguageId": 1
 *   }
 * ]
 */

export default function getProductsTypes(productId, siteId, languageId) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/product-types?languageId=${languageId}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Product Types For Specific Product')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
