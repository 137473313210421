import getRequest from '../../../../getRequest';

/**
 * Get similar language sites.
 */

export default async function getSimilarLanguageSites(languageId) {
  const endpoint = `/oc-data-management/v1/attributes/similar-language-sites?languageId=${languageId}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get attribute data types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
