/**
 * Returns whether a value matches the following criteria for empty:
 *   - undefined
 *   - null
 *   - object with no enumerable properties
 *   - array with no members
 *   - zero-length string
 *   - whitespace only string
 *   - zero-value number
 *   - boolean false
 *
 * @param {*} mixedData Data to check for emptiness
 * @return {bool} Is the data empty?
 */
const isEmpty = (mixedData) => {
  // Data is empty if null or undefined
  if (mixedData == null) {
    return true;
  }

  // If data is a number, then empty if 0
  if (typeof mixedData === 'number') {
    return mixedData === 0;
  }

  // If data boolean, then empty if false
  if (typeof mixedData === 'boolean') {
    return mixedData === false;
  }

  if (typeof mixedData === 'string') {
    return mixedData.length === 0 || !mixedData.trim();
  }

  // If data has some length, eg. a string or array, then empty if has no
  // elements
  if (typeof mixedData.length !== 'undefined') {
    return mixedData.length === 0;
  }

  // If data has a name property not empty then it's not empty
  // (added for files)
  if (typeof mixedData.name === 'string' && mixedData.name !== '') {
    return false;
  }

  // If data has an enumerable property, then it's not empty
  // eslint-disable-next-line no-restricted-syntax
  for (const data in mixedData) {
    if (Object.prototype.hasOwnProperty.call(mixedData, data)) {
      return false;
    }
  }

  return true;
};

export default isEmpty;
