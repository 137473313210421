import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

function HasVideoFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasVideo = searchParams.get('hasVideo');

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Has Video</Typography>
        <div className="flex flex-col w-max">
          <RadioGroup
            defaultValue="all"
            value={hasVideo || 'all'}
            name="hasVideo-radio-buttons-group"
            onChange={(event) => {
              setSearchParams((params) => {
                params.set('hasVideo', event.target.value);
                if (event.target.value === 'all') params.delete('hasVideo');
                return params;
              });
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>
      </Stack>
    </div>
  );
}

export default HasVideoFilter;
