import getRequest from '../../../../../../../getRequest';

/**
 * Get a task.
 *
 * @param {string} taskId Task id from MongoDB
 * @param {number} siteId Site ID
 * @param {number} transactionType Number relating to type of transaction
 * @param {string} action
 * @default action "export-to-csv"
 * @returns Details about a task
 */
export default function getExportTransactions(taskId, siteId, transactionType, fileName, action = 'export-to-csv') {
  const transactionTypeQuery = transactionType === null ? '' : `transactionType=${transactionType}`;
  const actionQuery = `action=${action}`;
  const fileNameQuery = `fileName=${fileName}`;
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/sites/${siteId}/reports/${taskId}/all-transactions?${transactionTypeQuery}&${fileNameQuery}&${actionQuery}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Export Transactions', {
      responseType: 'arraybuffer',
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
