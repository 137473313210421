import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList as VirtualizedList } from 'react-window';

import getMarginThresholdLogs from '../../api/oc_product/bulk/bulk_process/sites/reports/gets/getMarginThresholdLogs';
import JobModal from './components/JobModal';
import ProcessingTabRow from './components/tab_rows/ProcessingTabRow';
import SuccessTabRowMarginThreshold from './components/tab_rows/SuccessTabRowMarginThreshold';
import TabPanel from './components/TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: `${theme.palette.secondary[500]} !important`,
    height: '4px !important',
  },
  selected: {
    color: '#fff !important',
    opacity: '1 !important',
  },
  tabRoot: {
    color: '#fff !important',
    flex: 1,
    fontSize: '12px !important',
  },
}));

// const STATUS_DEF = {
//   Wait: 0,
//   New: 1,
//   Active: 2,
//   Processing: 3,
//   PartiallyProcessed: 4,
//   Processed: 5,
//   Failed: 10,
//   Removed: 20,
//   Disabled: 30,
// };

export default function ProgressSideBarMarginThreshold(inProgress) {
  const tabPanel = React.useRef(null);

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const styles = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [
    selectedId,
    setSelectedId,
  ] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const [successData, setSuccessData] = React.useState([]);
  const [inProgressData, setInProgressData] = React.useState([]);

  const [tabPanelHeight, setTabPanelHeight] = React.useState(0);

  const [selectedTransaction, setSelectedTransaction] = React.useState({});
  const [isLogsOpen, setIsLogsOpen] = useState(true);

  const handleGetReports = async () => {
    getMarginThresholdLogs(34, [2, 3]) // "Failed": 10,
      .then((res) => setInProgressData(res.data))
      .catch((err) => console.error(err));
    getMarginThresholdLogs(34, [4])
      .then((res) => {
        setSuccessData(res.data);
      })
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    setInProgressData(inProgress);
  }, [inProgress]);

  React.useEffect(() => {
    handleGetReports();
    const interval = setInterval(() => {
      handleGetReports();
    }, 10000); // 10 seconds
    return () => clearInterval(interval);
  }, [siteId]);

  React.useEffect(() => {
    if (tabPanel.current) {
      const { height } = tabPanel.current.getBoundingClientRect();
      setTabPanelHeight((height - 65));
    }
  }, [tabPanel.current]);

  const handleCloseLogs = () => {
    setIsLogsOpen(false);
  };

  const handleOpenLogs = () => {
    setIsLogsOpen(true);
  };

  return (
    <section className="h-[calc(100vh-120px)] sticky top-0">
      {openModal && <JobModal id={selectedId} open={openModal} setIsOpen={setOpenModal} parentData={selectedTransaction} />}
      {!isLogsOpen ? (
        <div className="h-[125px] w-[22px] z-10 fixed right-[14px] top-[43%]">
          <a
            href={handleOpenLogs}
            onClick={handleOpenLogs}
            className="h-full items-center text-1xl flex break-all text-center rounded text-white bg-[#217ABA] p-1.5 font-black cursor-pointer"
          >
            LOGS
          </a>
        </div>
      ) : (
        <div className="h-full min-w-[400px]">
          <div className="h-[25px] w-[25px] bg-[#737373] flex justify-center items-center rounded-full z-10 fixed right-[15px] top-[50%]">
            <ArrowForwardIosIcon
              className="text-white cursor-pointer"
              onClick={handleCloseLogs}
            />
          </div>
          <Box sx={{ width: '100%' }} className="h-full border rounded bg-neutral-100" ref={tabPanel}>
            <Box sx={{
              borderBottom: 1,
              borderColor: 'divider',
              position: 'sticky',
              top: 0,
              zIndex: 20,
            }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                classes={{ indicator: styles.indicator, root: styles.selected }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  label="Processing"
                  {...a11yProps(0)}
                  classes={{
                    root: styles.tabRoot,
                    selected: styles.selected,
                  }}
                />
                <Tab
                  label="Completed"
                  {...a11yProps(0)}
                  classes={{
                    root: styles.tabRoot,
                    selected: styles.selected,
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {inProgressData.length > 0 ? (
                <VirtualizedList
                  height={tabPanelHeight}
                  itemCount={inProgressData.length}
                  itemSize={130}
                  width={400}
                  itemData={inProgressData}
                >
                  {ProcessingTabRow}
                </VirtualizedList>
              ) : (
                <div className="flex items-center justify-center h-16 w-[400px]">
                  <p>No Operations Processing</p>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {successData.length > 0 ? (
                <VirtualizedList
                  height={tabPanelHeight}
                  itemCount={successData.length}
                  itemSize={130}
                  width={400}
                  itemData={successData}
                >
                  {({ data, index, style }) => (
                    <SuccessTabRowMarginThreshold
                      data={data}
                      index={index}
                      style={style}
                      setSelected={(obj) => {
                        setSelectedId(obj.Id);
                        setSelectedTransaction(obj);
                      }}
                      setOpenModal={setOpenModal}
                    />
                  )}
                </VirtualizedList>
              ) : (
                <div className="flex items-center justify-center h-16 w-[400px]">
                  <p>No Successful Operations</p>
                </div>
              )}
            </TabPanel>
          </Box>
        </div>
      )}
    </section>
  );
}
