import 'jspdf-autotable';

import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import FileSaver from 'file-saver';
import JsPDF from 'jspdf';
import React from 'react';
import { CSVLink } from 'react-csv';
// import useClipboard from 'react-hook-clipboard';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import postExportToExcelZip from '../api/oc_product/export/post/postExportToExcelZip';

// import postSearch from '../api/oc_product/search/posts/postSearch';

// const splitString = (str) => {
//   if (Array.isArray(str)) { // if value is already an array
//     return [];
//   }
//   // split string by either comma, space or new line
//   return str?.split(/,| |\n/).filter((str2) => str2 !== '');
// };

// async function getRows(state, totalRows, siteId) {
//   const search = await postSearch(
//     1,
//     totalRows,
//     {
//       siteId,
//       name: state.name,
//       productIds: splitString(state.productIds),
//       plus: splitString(state.plus),
//       eans: splitString(state.eans),
//       skus: splitString(state.skus),
//       hasSelectedSourcesOnly: state.hasSelectedSourcesOnly,
//       sourceIds: state.sourceIds,
//       productTypeIds: state.productTypeIds.map((type) => type.ProductTypeId),
//       categoryIds: state.categoryIds.map((category) => category.PimCategoryId),
//       brandIds: state.brandIds.map((brand) => brand.BrandId),
//       erps: state.erps,
//       omsId: state.omsId,
//       hasSelectedLocationsOnly: state.hasSelectedLocationsOnly,
//       hasStockInSourceLocationIds: state.hasStockInSourceLocationIds
//         .map((location) => location.SourceLocationId),
//       stockSourceLocationId: state.stockSourceLocationId?.SourceLocationId,
//       erpCategorySourceId: state.erpCategorySourceId?.Id,
//       isApproved: state.isApproved,
//       isOnSite: state.isOnSite,
//       isActive: state.isActive,
//       isComplete: state.isComplete,
//       hasImage: state.hasImage,
//       hasProductType: state.hasProductType,
//       hasCategories: state.hasCategories,
//       createdDateFrom: state.createdDateFrom,
//       createDateTo: state.createDateTo,
//       releaseDateFrom: state.releaseDateFrom,
//       releaseDateTo: state.releaseDateTo,
//       updateDateFrom: state.updateDateFrom,
//       updatedDateTo: state.updatedDateTo,
//     },
//   )
//     .catch((err) => {
//       console.error('ProductSearch', err);
//     });

//   return search.data;
// }

/**
 * ec() and delete_row()
 * are functions from here https://github.com/SheetJS/sheetjs/issues/352
 * They delete a whole row from the excel file
 */
function ec(r, c) {
  return XLSX.utils.encode_cell({ r, c });
}
function delete_row(ws, row_index) {
  const copyWS = structuredClone(ws);
  const variable = XLSX.utils.decode_range(ws['!ref']);
  for (let R = row_index; R < variable.e.r; ++R) {
    for (let C = variable.s.c; C <= variable.e.c; ++C) {
      copyWS[ec(R, C)] = ws[ec(R + 1, C)];
    }
  }
  // eslint-disable-next-line no-plusplus
  variable.e.r--;
  copyWS['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
  return copyWS;
}

function ExportButton({
  columns = [],
  rows = [],
  // totalRows,
  // tableState,
  title,
  // exportAllBody,
  filteredData,
}) {
  // const siteId = useSelector((state) => state.user.loginResponse.selectedSite.siteId);

  // CSV
  const [csvData, setCsvData] = React.useState([]);
  const [fileName, setFileName] = React.useState('PLPTableExport');
  const [exportAll, setExportAll] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [exportAllBody, setExportAllBody] = React.useState();

  const columnsRedux = useSelector((state) => state.columns.columnVisibilityModel) || {};

  const formatToCSVData = () => {
    const headers = columns?.map((column) => column?.headerName);
    const data = rows?.map((rowP) => columns?.map((column) => {
      if (column.field === 'DropShipements') {
        return rowP[column.field]?.map((obj) => `${obj.SourceName} - ${obj.IsDropShippable}`).join(', ');
      }
      return rowP[column.field];
    }));
    setCsvData([headers, ...data]);
  };

  React.useEffect(() => {
    const uniqueColumns = new Set();
    columns
      .filter((column) => columnsRedux[column.field.toLowerCase()] !== false)
      .forEach((column) => {
        uniqueColumns.add(column.field);
      });
    const uniqueColumnsArray = Array.from(uniqueColumns);

    setExportAllBody({
      searchRequest: filteredData,
      columnsToExport: uniqueColumnsArray,
    });
  }, []);

  React.useEffect(() => {
    formatToCSVData();
  }, [columns, rows]);

  // Excel
  const exportToExcel = React.useCallback(() => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    // I used delete_row() here to remove the first row.
    // The first row was just indexes.
    // https://jdplc.atlassian.net/browse/BE-4367
    const deletedRow = delete_row(ws, 0);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, deletedRow, 'Data');
    XLSX.writeFileXLSX(wb, `${fileName}.xlsx`);
  }, [csvData, fileName]);

  // PDF
  const exportPDF = () => {
    const unit = 'px';
    const size = 'A2'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const marginLeft = 40;
    const doc = new JsPDF(orientation, unit, size);
    doc.setFontSize(12);

    const headers = [columns.map((column) => column.headerName)];
    const data = rows.map((rowP) => columns.map((column) => {
      const value = rowP[column.field];
      if (value == null) return '';
      if (column.field === 'DropShipements') {
        return value?.map((obj) => `${obj.SourceName} - ${obj.IsDropShippable}`).join(', ');
      }
      return value.toString();
    }));

    const content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${fileName}.pdf`);
  };

  // Copy to clipboard
  // const [, copyToClipboard] = useClipboard();
  // const toClipboard = csvData;

  // const [exportAll, setExportAll] = React.useState(false);
  // const [exportAmount, setExportAmount] = React.useState(0);
  const [selectedExportType, setSelectedExportType] = React.useState('');

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedExportType(value);
  };

  const handleExport = async () => {
    if (selectedExportType === 'excel') exportToExcel(csvData);
    if (selectedExportType === 'pdf') exportPDF();
    if (exportAll) {
      setLoading(true);
      try {
        const response = await postExportToExcelZip(fileName, exportAllBody);
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          FileSaver.saveAs(blob, `${fileName}.zip`);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error exporting CSV:', error);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (exportAll) setSelectedExportType('');
  }, [exportAll]);

  // React.useEffect(() => {
  //   const x = getRows(tableState, totalRows, siteId);
  //   console.log(x);
  // }, [exportAll]);
  // React.useEffect(() => {
  //   if (exportAmount > 100) {
  //     const x = getRows(tableState, exportAmount, siteId);
  //     console.log(x);
  //   }
  // }, [exportAmount]);

  return (
    <div className="p-4 w-[250px]">
      {loading ? (
        <div className="flex items-center justify-center h-52">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Stack gap={2}>
          <p className="font-gothammedium">
            Export To:
          </p>
          {selectedExportType !== 'copy' && (
            <TextField
              label="Name"
              placeholder={fileName}
              onChange={(e) => setFileName(e.target.value || 'PLPTableExport')}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          )}
          <FormControl fullWidth size="small">
            <InputLabel id="type-select-label">
              Type
            </InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              label="Type"
              onChange={handleChange}
              value={selectedExportType}
              disabled={exportAll}
            >
              <MenuItem value="csv">CSV</MenuItem>
              <MenuItem value="excel">Excel</MenuItem>
              <MenuItem value="pdf">PDF</MenuItem>
              {/* <MenuItem value="copy">Copy</MenuItem> */}
            </Select>
            <FormControlLabel
              control={<Checkbox checked={exportAll} onClick={() => setExportAll(!exportAll)} />}
              label="All (Excel)"
            />
          </FormControl>
          {selectedExportType === 'csv' ? (
            <Button variant="contained" sx={{ padding: 0 }}>
              <CSVLink
                data={csvData}
                filename={`${fileName}.csv`}
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  width: '100%',
                  padding: '.4rem',
                  borderRadius: '.5rem',
                }}
              >
                Export
              </CSVLink>
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleExport()}
              disabled={selectedExportType === '' && exportAll === false}
            >
              Export
            </Button>
          )}
        </Stack>
      )}
    </div>
  );
}

export default ExportButton;
