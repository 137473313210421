import postRequest from '../../../postRequest';

/**
 * Post request for media check.
 *
 * @param {number} siteId Site Id
 * @param {string} siteCode
 * @param {number[]} productIds
 * @param {string[]} plus
 * @param {boolean} hasImage
 * @param {boolean} hasSpinSet
 * @param {boolean} hasVideo
 * @param {number} page Page count
 * @param {number} size Page size
 *
 */
export default function postMediaCheck(
  siteId,
  siteCode,
  productIds,
  plus,
  hasImage,
  hasSpinSet,
  hasVideo,
  page,
  size,
) {
  const pageQuery = page ? `?Page=${page}` : '';
  const sizeQuery = size ? `&Size=${size}` : '';
  const endpoint = `/oc-product/v1/media-check-image-links${pageQuery}${sizeQuery}`;

  function convertToBoolean(value) {
    if (value === 'all') {
      return null;
    } if (value === 'yes') {
      return true;
    } if (value === 'no') {
      return false;
    }
    return null; // Handle other cases or invalid values
  }

  const body = {
    siteId,
    siteCode,
    productIds,
    plus,
    hasImage: convertToBoolean(hasImage),
    hasSpinSet: convertToBoolean(hasSpinSet),
    hasVideo: convertToBoolean(hasVideo),
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Product Search')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
