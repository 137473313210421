import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import getEnrichment from '../../../../../../../api/oc_product/product/enrichment/gets/getEnrichment';
import { ProductContext } from '../../../ProductContext';

function ProductEnrichment() {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language.language?.LanguageId);

  const { id } = useParams();
  const { enrichmentRerender } = React.useContext(ProductContext);

  const [attributes, setAttributes] = React.useState([]);
  const [completePercent, setCompletePercent] = React.useState(0);
  const [enrichment, setEnrichment] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getEnrichmentData = async () => {
    setLoading(true);

    try {
      const enrichmentRes = await getEnrichment(id, siteId, languageId);

      const enrichmentPromises = [
        setEnrichment(enrichmentRes?.data),
      ];

      await Promise.all(enrichmentPromises);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getEnrichmentData();
  }, []);
  React.useEffect(() => {
    getEnrichmentData();
  }, [enrichmentRerender, id]);

  React.useEffect(() => {
    setCompletePercent(enrichment?.Percentage);
    setAttributes(enrichment?.enrichmentProgressDtos);
  }, [enrichment]);

  return (
    loading ? (
      <Skeleton variant="rounded" width={350} height={400} />
    ) : (
      <div className="flex flex-col items-start justify-start w-full h-full gap-4 p-4 bg-white border rounded-lg">
        <div className="flex items-center w-full gap-6">
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: '#F0F1F3',
              }}
              size={60}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              disableShrink
              sx={{
                // eslint-disable-next-line no-nested-ternary
                color: completePercent < 50 ? '#D65142' : ((completePercent >= 50 && completePercent < 100) ? '#EEBE43' : '#70BF73'),
                animationDuration: '550ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              size={60}
              thickness={4}
              value={completePercent}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body1" color="text.secondary" className="font-gothambold">
                {`${completePercent}%`}
              </Typography>
            </Box>
          </Box>
          <div className="flex flex-col justify-center w-80">
            <Typography variant="body1" className="font-gothammedium">
              Enrichment
            </Typography>
            <Typography variant="body2" sx={{ whitespace: 'wrap' }}>
              See which attributes have been completed.
            </Typography>
          </div>
        </div>
        <div className="flex justify-between w-full p-2 pt-0">
          <table>
            <tbody>
              {attributes?.slice(0, Math.round(attributes.length / 2)).map((obj) => (
                <tr key={obj.Attribute}>
                  <td>
                    <Chip
                      label={obj.Attribute}
                      sx={{
                        marginTop: '1rem',
                        background: 'transparent',
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                          fontWeight: 600,
                          // fontSize: '12px',
                          // paddingY: '4px',
                        },
                      }}
                      color={obj.Completed ? 'success' : 'error'}
                      size="small"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table>
            <tbody>
              {attributes?.slice(Math.round(attributes.length / 2)).map((obj) => (
                <tr key={obj.Attribute}>
                  <td>
                    <Chip
                      label={obj.Attribute}
                      sx={{
                        marginTop: '1rem',
                        background: 'transparent',
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                          fontWeight: 600,
                          // fontSize: '12px',
                          // paddingY: '4px',
                        },
                      }}
                      color={obj.Completed ? 'success' : 'error'}
                      size="small"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
}

export default ProductEnrichment;
