import getRequest from '../../../../getRequest';

/**
 * Get attribute data types.
 */

export default async function getAttributeDataTypes() {
  const endpoint = '/oc-data-management/v1/attributes/attribute-data-types';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get attribute data types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
