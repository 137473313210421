/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { groupBy } from 'lodash';

import postPrices from '../../../../api/intergration/pim/products/dropshipment/posts/postPrices';
import postSites from '../../../../api/intergration/pim/products/dropshipment/posts/postSites';
import postUpcs from '../../../../api/intergration/pim/products/dropshipment/posts/postUpcs';

/**
 * Takes 2 Set()'s and merges their values into 1 new Set().
 *
 * @param {Set} s1 First Set() you want to merge.
 * @param {Set} s2 Second Set() you want to merge.
 * @returns {Set}
 */
Set.union = (s1, s2) => {
  if (!(s1 instanceof Set) || !(s2 instanceof Set)) {
    console.error('The given objects are not of type Set');
    return null;
  }
  const newSet = new Set();
  s1.forEach((elem) => newSet.add(elem));
  s2.forEach((elem) => newSet.add(elem));
  return newSet;
};

/**
 * Generates an object containing the array of Currencies and Prices and Currency countries
 *
 * @param {object} pricesRes Response from prices endpoint.
 * @param {object} vat Object containing VAT VariantId.
 * @returns
 */
function generateArrayOfCurrencies(pricesRes, vat) {
  const currenciesSet = new Set([]);

  const anatwinePrices = pricesRes.data.Data.filter((p) => p.VariantId
            === Number(vat.variant)
            && p.Name.includes('Anatwine'));
  // example - { GB: [], FR: [] }
  const currenciesObj = groupBy(anatwinePrices, ({ Currency }) => Currency);
  const uniqueCurrencies = (Object.entries(currenciesObj))
    .map(([, value]) => value.map(({ Rrp, CountryCode, Currency }) => {
      currenciesSet.add(CountryCode);
      return {
        [CountryCode]: Rrp,
        Currency,
      };
    }));
  const flattenedCurrencies = uniqueCurrencies.flat();
  const objectsToArrays = flattenedCurrencies.map((objx) => Object.entries(objx));
  const currencyArray = objectsToArrays
    .map((cur) => ({
      currency: cur[0][0] === 'GB' ? 'UK' : cur[0][0],
      value: new Intl.NumberFormat('en-US', { style: 'currency', currency: cur[1][1] }).format(cur[0][1]),
    }));

  return {
    currenciesSet,
    currencyArray,
  };
}

/**
 * Calls multiple endpoints to generate the VAT table for the Summary page.
 *
 * @param {object} nike_wizard nike_wizard state from Redux.
 * @param {array} existingCountrySizes The existing countries sizes for that product.
 * @example ["UK", "EU", "US"]
 * @returns {object}
 * @example
 * {
 *   rows: [],
 *   rrp_countries: [],
 *   missingValue: false,
 * }
 */
async function generateVatTable(nike_wizard, existingCountrySizes) {
  const upcRes = await postUpcs(nike_wizard.productId, 'JD');

  const existingSites = await postSites(nike_wizard.productId, 'JD')
    .then((res) => {
      const sitesOnly = res.data.Data.map((obj) => obj.Country);
      const uniqueSites = [...new Set(sitesOnly)];
      return uniqueSites;
    })
    .catch((err) => console.error(err));

  const pricesRes = await postPrices(nike_wizard.productId, null)
    .catch((err) => console.error(err));
  const stateVAT = nike_wizard.productData.vat;

  let currMap = new Set([]);
  let missingValue = false;

  const createdRows = stateVAT.map((vat, index) => {
    const { currenciesSet, currencyArray } = generateArrayOfCurrencies(pricesRes, vat);
    currMap = Set.union(currMap, currenciesSet);

    const shogunFilteredPrices = pricesRes.data.Data.filter((p) => p.VariantId
            === Number(vat.variant)
            && p.Name === 'Shogun');
    const filtered = upcRes.data.Data.find((d) => d.VariantId === Number(vat.variant));

    const commodity_code = shogunFilteredPrices
      .filter((obj) => obj?.CommodityCode?.length > 0)[0]?.CommodityCode
      || 'No Commodity Code';

    let upc = filtered?.Upc;
    const rrp = currencyArray;
    let anatwineSku = vat.sku === 'No Anatwine SKU' ? undefined : vat.sku;

    if (upc == null) {
      missingValue = true;
      upc = (<span className="text-error-600">MISSING</span>);
    }
    Array.from(currMap).forEach((curr) => {
      // eslint-disable-next-line no-param-reassign
      if (curr === 'GB') curr = 'UK';
      if (rrp.some((obj) => obj.currency === curr) === false) {
        missingValue = true;
        rrp.push({
          currency: curr,
          value: (<span className="text-error-600">MISSING</span>),
        });
      }
    });

    existingSites.forEach((country) => {
      if (Array.from(currMap).includes(country) === false) {
        missingValue = true;
        rrp.push({
          currency: country,
          value: (<span className="text-error-600">N/A</span>),
        });
      }
    });

    if (anatwineSku == null) {
      missingValue = true;
      anatwineSku = (<span className="text-error-600">MISSING</span>);
    }

    let newSizes = vat.sizes;

    existingCountrySizes.forEach((size) => {
      if (vat.sizes.some((obj) => obj.country_code === size) === false) {
        missingValue = true;
        /**
         * It's name weird because I find it weird that I can't simply
         * do a push into the vat.sizes array without having to Object.assign it.
         */
        const weird = Object.assign([], vat.sizes);
        weird.push({
          country_code: size,
          size: (<span className="text-error-600">MISSING</span>),
        });
        newSizes = weird;
      }
    });

    return {
      ...vat,
      id: index,
      sku: anatwineSku,
      vat_code: vat.vat_code.map(({ is_vatable, country_code }) => ({
        is_vatable: is_vatable ? 'Yes' : 'No',
        country_code: country_code === 'GB' ? 'UK' : country_code,
      })),
      upc,
      sizes: newSizes,
      commodity_code,
      currency: rrp,
    };
  });

  return {
    rows: createdRows,
    rrp_countries: Array.from(currMap),
    missing: missingValue,
  };
}

export default generateVatTable;
