import getRequest from '../../../../../../getRequest';

/**
 * Get a task.
 *
 * @param {string} taskId Task id from MongoDB
 * @param {number} siteId ID of the site you are viewing
 * @param {0 | 1 | 2 | 3 | -1} transactionType ID of the site you are viewing
 * @param {number} page
 * @param {number} size Amount of rows to be returned.
 * @returns Details about a task
 */
export default function getTransactions(taskId, siteId, page, size, transactionType) {
  const transactionTypeQuery = `&transactionType=${transactionType}`;
  const query = `?Page=${page}&Size=${size}`;
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/sites/${siteId}/reports/${taskId}/transactions${query}${transactionType !== null ? transactionTypeQuery : ''}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Transactions')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
