import getRequest from '../../../../getRequest';

export default function getVats(siteId, brandId, sourceId) {
  const endpoint = `/oc-product/v1/product/${siteId}/vatcode?brandId=${brandId}&sourceId=${sourceId}`;
  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'vatcode')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
