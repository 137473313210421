import patchRequest from '../../../../patchRequest';

/**
 * Updates the products Name and/or Brand.
 *
 * @param {number} productId - 15974689
 * @param {number} siteId - 1
 * @param {number} brandId - 1
 * @param {string} productName - "Air Max"
 * @param {number} languageId - 1
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchProductStyles(
  productId,
  siteId,
  productName,
  languageId,
) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/product-style`;

  const body = {
    productName,
    languageId,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Product Styles')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchProductStyles;
