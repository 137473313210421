import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a release date by Plu values.
 *
 * @param {number} SourceId Source Id
 * @param {number} SiteId Site Id
 * @param {string[]} plus PLUs
*/
export default function postReleaseDatePluIdByValue({
  SiteId,
  sourceId,
  releaseDates,
  plus,
  removeReleaseDate,
}) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/release-dates/plu-id-by-value/${sourceId}`;

  const body = {
    siteId: SiteId,
    sourceId,
    releaseDate: releaseDates,
    plus,
    removeReleaseDate,
  };

  return new Promise((resolve, reject) => {
    postRequest(
      endpoint,
      body,
      'Release Dates PLU Ids',
      'STARTED: Release Dates PLU Ids',
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
