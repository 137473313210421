import isEmpty from './isEmpty';

/**
 * Loops through the object checking whether there are any empty values.
 * If there is an empty value it will return true.
 *
 * @param {object} obj Object you want to check
 * @returns {boolean}
 */
export default function doesObjectContainEmptyValues(obj) {
  if (obj != null) {
    const objectValues = Object.values(obj);
    const arrayOfBools = objectValues.map((v) => {
      if (Array.isArray(v)) {
        return isEmpty(v);
      }
      if (typeof v === 'object') {
        if (v?.id != null) { // delete ids from being checked
          // eslint-disable-next-line no-param-reassign
          delete v.id;
        }
        return doesObjectContainEmptyValues(v);
      }
      return isEmpty(v);
    });

    return arrayOfBools.flat().includes(true);
  }
  return true;
}
