import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useReducer } from 'react';
import { toast } from 'react-toastify';

import getSites from '../../../api/oc_product/fascias/gets/getFascias';
import deleteIndex from '../../../api/oc_product/index/deletes/deleteIndex';
import postProductIndex from '../../../api/oc_product/index/posts/postIndex';
import postFullIndex from '../../../api/oc_product/index/sites/posts/postSites';
import doesObjectContainEmptyValues from '../../../utils/doesObjectContainEmptyValues';
import splitString from '../../../utils/splitIdString';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ACTION':
      return {
        ...state,
        action: action.payload,
      };
    case 'RESET':
      return action.payload;
    case 'PRODUCTIDS':
      return {
        ...state,
        productIds: action.payload,
      };
    default:
      return state;
  }
};

function Indexing() {
  const initialState = {
    action: '',
    indextype: '',
    facia: '',
    ProductIds: [],
  };
  const [state] = useReducer(reducer, initialState);
  const [productIdLengthError, setProductIdLengthError] = React.useState(false);
  const [siteOptions, setSiteOptions] = React.useState([]);
  const [combinedSites, setCombinedSites] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [isFaciaVisible, setFaciaIsVisible] = React.useState(false); // Visibility state
  const [isProductVisible, setProductIsVisible] = React.useState(false); // Visibility state
  const [selectedType, setSelectedType] = React.useState(false); // Visibility state
  const [action, setAction] = React.useState('');
  const [actionType, setActionType] = React.useState('');
  const [selectedSite, setSelectedSite] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [disabledProduct, setDisabledProduct] = React.useState(true);

  const [indexTypeDisabled, setIndexTypeDisabled] = React.useState(false);

  const clearSelection = () => {
    setSelectedSite([]);
  };
  const getAllSites = () => {
    getSites()
      .then((res) => setSiteOptions(res.data))
      .catch((err) => console.error(err));
  };

  const getProductIds = (event) => {
    setSelectedProducts(event.target.value);
    setSubmitDisabled(false);
  };
  const submitIndexAction = () => {
    setSubmitDisabled(true);
    if (actionType === 'Update') {
      if (selectedType === 'Facia Index') {
        const siteCodes = selectedSite.map((site) => site.SiteCode);
        toast.success('Facia Index starting');
        postProductIndex(siteCodes, [])
          .then((response) => {
            toast.success('Indexing successful', response);
          })
          .catch((error) => {
            toast.error('Error during indexing:', error);
          });
      } else if (selectedType === 'Product Index') {
        // setSelectedSite();
        const siteCodes = selectedSite.map((site) => site.SiteCode);
        const productIds = splitString(selectedProducts);
        toast.success('Product Index starting');
        postProductIndex(siteCodes, productIds)
          .then((response) => {
            toast.success('Indexing successful', response);
          })
          .catch((error) => {
            toast.error('Error during indexing', error);
          });
      } else {
        toast.success('Full Index starting');
        setSubmitDisabled(true);
        postFullIndex()
          .then((response) => {
            toast.success('Indexing successful:', response);
          })
          .catch((error) => {
            toast.error('Error during indexing', error);
            setSubmitDisabled(false);
          });
      }
    }
    if (actionType === 'Delete') {
      // setSelectedSite();
      const siteCodes = selectedSite.map((site) => site.SiteCode);
      const productIds = splitString(selectedProducts);
      deleteIndex(siteCodes, productIds)
        .then((response) => {
          toast.success('Index deleted successfully', response);
        })
        .catch((error) => {
          toast.error('Error during deleting index', error);
        });
    }
    setSubmitDisabled(false);
  };

  const combineSites = (data) => {
    const sites = [];
    if (Array.isArray(data)) {
      data.forEach((obj) => {
        obj.Sites.forEach((site) => {
          sites.push({
            ...site,
            parent: obj.Name,
            SiteGroupId: obj.SiteGroupId,
          });
        });
      });
      return sites;
    }
    return [];
  };

  const handleActionChange = (event) => {
    setActionType(event.target.value);
    setProductIsVisible(false);
    setFaciaIsVisible(false);
    const selectedAction = event.target.value;
    if (selectedAction === 'Update') {
      setAction('');
      setIndexTypeDisabled(false);
      setProductIsVisible(false);
      setFaciaIsVisible(false);
    } else if (selectedAction === 'Delete') {
      getAllSites();
      setAction('Product Index');
      setDisabledProduct(false);
      setIndexTypeDisabled(true);
      setFaciaIsVisible(true);
      setProductIsVisible(true);
    } else {
      setFaciaIsVisible(false);
      setProductIsVisible(false);
    }
  };
  const handleIndexTypeChange = (event) => {
    clearSelection();
    if (actionType !== '') {
      getAllSites();
      const selectedValue = event.target.value;
      setAction(event.target.value);
      setSelectedType(selectedValue);
      if (selectedValue === 'Facia Index') {
        setProductIsVisible(false);
        setFaciaIsVisible(true);
        setSubmitDisabled(true);
      } else if (selectedValue === 'Product Index') {
        setDisabledProduct(true);
        setFaciaIsVisible(true);
        setProductIsVisible(true);
        setSubmitDisabled(true);
      } else {
        setFaciaIsVisible(false);
        setProductIsVisible(false);
        setSubmitDisabled(false);
      }
    } else {
      toast.error('Please select Action');
    }
  };

  React.useEffect(() => {
    setIndexTypeDisabled(true);
    const isEmpty = doesObjectContainEmptyValues(state);
    setSubmitDisabled(isEmpty);
  }, [state]);

  React.useEffect(() => {
    const splitIds = splitString(state.ProductIds);
    if (splitIds.some((str) => str.length > 9)) {
      setProductIdLengthError(true);
    } else {
      setProductIdLengthError(false);
    }
  }, [state.ProductIds]);

  React.useEffect(() => {
    if (siteOptions.length > 0) {
      const combined = combineSites(siteOptions);
      setCombinedSites(combined);
    }
  }, [siteOptions]);

  React.useEffect(() => {
    const cs = [...combinedSites];
    setOptions(cs);
  }, [combinedSites]);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <section className="w-full">
        {/* flex-col flex-wrap h-[calc(100vh-250px)] */}
        <form className="flex flex-col w-full gap-8 xl:flex-row">
          <section className="flex flex-col w-full gap-8">
            <div className="flex items-center gap-2">
              <Typography variant="body2">
                INDEXING
              </Typography>
            </div>
            <div className="flex flex-col w-[260px]">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    1
                  </Typography>
                </div>
                <Typography variant="body2">
                  Select Action
                </Typography>
              </div>
              <Box sx={{ paddingTop: '1rem' }}>
                <FormControl fullWidth>
                  <InputLabel id="action-label">Action</InputLabel>
                  <Select
                    labelId="action-label"
                    id="action-select"
                    label="ActionType"
                    value={actionType}
                    onChange={handleActionChange}
                  >
                    <MenuItem value="Update">Update</MenuItem>
                    <MenuItem value="Delete">Delete</MenuItem>
                  </Select>
                </FormControl>
              </Box>

            </div>
            <div className="flex flex-col w-[260px]">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    2
                  </Typography>
                </div>
                <Typography variant="body2"> Index Type </Typography>
              </div>
              <Box sx={{ paddingTop: '1rem' }}>
                <FormControl fullWidth>
                  <InputLabel id="indextype-label">Index Type</InputLabel>
                  <Select
                    labelId="indextype-label"
                    id="indextype-select"
                    label="Index Type"
                    value={action}
                    disabled={indexTypeDisabled}
                    onChange={handleIndexTypeChange}
                  >
                    <MenuItem value="Full Index">Full Index</MenuItem>
                    <MenuItem value="Facia Index">Facia Index</MenuItem>
                    <MenuItem value="Product Index">Product Index</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            {isFaciaVisible && (
              <div>
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      3
                    </Typography>
                  </div>
                  <Typography variant="body2">Select Facia</Typography>
                </div>

                <Box sx={{ paddingTop: '1rem' }}>
                  <div key="step2" className="flex flex-col w-[260px]">

                    <Autocomplete
                      key="indextype-select"
                      options={options}
                      textFieldLabel="Facia"
                      textFieldVariant="outlined"
                      getOptionLabel={(option) => option.Name}
                      onChange={(event, newValue) => {
                        setDisabledProduct(newValue?.length === 0);
                        if (newValue?.length === 0) {
                          setSelectedProducts(null);
                        }
                        setSelectedSite(newValue);
                        setSubmitDisabled(false);
                      }}
                      value={selectedSite}
                      optionKey="Name"
                      multiple
                      renderInput={(params) => (
                        <TextField {...params} label="Select Site" />
                      )}
                    />
                  </div>
                </Box>

              </div>
            )}
            {isProductVisible && (
              <div className="flex flex-col w-[260px]">

                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      4
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Enter  Product Ids
                  </Typography>
                </div>
                <Box sx={{ paddingTop: '1rem' }}>
                  <TextField
                    label="Product Ids"
                    placeholder="Type or Paste in your Product Ids here."
                    multiline
                    rows={6}
                    disabled={disabledProduct}
                    value={state.productIds}
                    onChange={(e) => getProductIds(e)}
                    error={productIdLengthError}
                    helperText={productIdLengthError ? 'One or more Id\'s contain 10 or more digits' : ''}
                  />
                </Box>

              </div>
            )}
            <div className="w-full">
              <Box className="flex items-center justify-center gap-6 mt-2">
                <Button
                  variant="contained"
                  disabled={submitDisabled}
                  onClick={() => {
                    submitIndexAction(false);
                  }}
                >
                  Submit
                </Button>
                {/* {!submitDisabled && (
                  <div
                    className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 bg-primary-400 animate-ping"
                  />
                )} */}
              </Box>
            </div>

          </section>
        </form>
      </section>
    </Container>
  );
}

export default Indexing;
