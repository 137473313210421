import postRequest from '../../../../postRequest';

/**
 * Put request for creating a new attribute definition.
 *
 *
 *   "productAttributeDefKey": "string",
 *   "siteIds": [
 *     0
 *   ],
 *   "attributeDataTypeId": 0,
  * }
 */
export default async function getAttributeConfirmation(
  body,
) {
  const endpoint = '/oc-data-management/v1/attributes/confirmation';
  // const defaultMessage = '';
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Attribute Def')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
