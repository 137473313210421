import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import deleteSingleLineAttributesPluId from '../../../api/oc_product/bulk/bulk_process/attributes/plu_id/deletes/deleteSingleLineAttributesPluId';
import postSingleLineAttributesPluId from '../../../api/oc_product/bulk/bulk_process/attributes/plu_id/posts/postSingleLineAttributesPluId';
import deleteSingleLineAttributesProductId from '../../../api/oc_product/bulk/bulk_process/attributes/product_id/deletes/deleteSingleLineAttributesProductId';
import postSingleLineAttributesProductId from '../../../api/oc_product/bulk/bulk_process/attributes/product_id/posts/postSingleLineAttributesProductId';
import getSiteAttributes from '../../../api/oc_product/bulk/sites/site_attributes/gets/getSiteAttributes';
import getLanguages from '../../../api/oc_product/product/languages/gets/getLanguages';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import PluCsv from '../../../assets/bulk_templates/Bulk_Plu_Template.csv';
import ProductIdCsv from '../../../assets/bulk_templates/Bulk_Product_Id_Template.csv';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import doesObjectContainEmptyValues from '../../../utils/doesObjectContainEmptyValues';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONTENT_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'ACTION':
      return {
        ...state,
        action: action.payload,
      };
    case 'ATTRIBUTE':
      return {
        ...state,
        attribute: action.payload,
      };
    case 'SOURCE':
      return {
        ...state,
        sourceId: action.payload,
      };
    case 'FILE':
      return {
        ...state,
        file: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};

const MAX_FILE_SIZE_MB = 5;

function Attributes({ refreshReports }) {
  const siteId = useSelector(
    (state) => state.user.loginResponse.selectedSite.SiteId,
  );
  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);
  const submitBtn = React.useRef(null);
  const fileInputRef = React.useRef(null);

  const initialState = {
    language: null,
    attribute: null,
    sourceId: null,
    file: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  const [idType, setIdType] = React.useState('plu');

  const [buttonWidth, setButtonWidth] = React.useState(0);
  const [buttonHeight, setButtonHeight] = React.useState(0);
  const [languagesOptions, setLanguagesOptions] = React.useState([]);
  const [sourceOptions, setSourceOptions] = React.useState([]);
  const [siteAttributeOptions, setSiteAttributeOptions] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.get('omsId');

  React.useEffect(() => {
    getLanguages(siteId).then((res) => setLanguagesOptions(res.data));
    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    if (state.language) {
      dispatch({ type: 'ATTRIBUTE', value: initialState.attribute });
      getSiteAttributes(siteId, 1, state.language)
        .then((res) => {
          setSiteAttributeOptions(res.data);
        })
        .catch((err) => console.error(err));
    } else {
      dispatch({ type: 'ATTRIBUTE', value: initialState.attribute });
      dispatch({ type: 'SOURCE', value: initialState.sourceId });
    }
  }, [state.language]);

  React.useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input value
      dispatch({ type: 'FILE', payload: '' }); // Reset file state
      dispatch({ type: 'RESET', payload: initialState });
    }
    setIdType('plu');
    getLanguages(siteId).then((res) => {
      setLanguagesOptions(res.data);
      dispatch({ type: 'CONTENT_LANGUAGE', payload: languageId });
    });
    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect(() => {
    if (submitBtn.current) {
      const sbmBtn = submitBtn.current.getBoundingClientRect();
      setButtonWidth(sbmBtn.width);
      setButtonHeight(sbmBtn.height);
    }
  }, [submitBtn.current]);

  React.useEffect(() => {
    if (idType !== 'plu') {
      delete state.sourceId;
    } else if (!state.sourceId) {
      state.sourceId = '';
    }
    const isEmpty = doesObjectContainEmptyValues(state);
    setSubmitDisabled(isEmpty);
  }, [state, idType]);

  React.useEffect(() => {
    if (state.file !== '' && state.file.type !== 'text/csv') {
      toast.error('Please upload CSV file');
    }

    if (state.file.type !== 'text/csv') {
      const isEmpty = doesObjectContainEmptyValues(state);
      setSubmitDisabled(isEmpty);
      state.file = '';
    }

    if (state.file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.error('File size is too big. Please upload a file smaller than 5MB.');
      const isEmpty = doesObjectContainEmptyValues(state);
      setSubmitDisabled(isEmpty);
      state.file = '';
    }
  }, [state.file]);

  React.useEffect(() => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
         * For some reason we have to do this...
         * If you do the, if not then delete, statement below within the for above
         * or do params.forEach
         * The loop with miss every even indexed key...
         */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  }, []);

  const Input = styled('input')({
    display: 'none',
  });

  const handleBulk = (e) => {
    e.preventDefault();
    if (idType === 'plu') {
      if (state.action === 4) { // delete
        deleteSingleLineAttributesPluId({
          SiteId: siteId,
          SourceId: state.sourceId,
          AttributeDefKeyId: state.attribute,
          LanguageId: state.language,
          File: state.file,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      } else {
        postSingleLineAttributesPluId({
          SiteId: siteId,
          SourceId: state.sourceId,
          AttributeDefKeyId: state.attribute,
          LanguageId: state.language,
          File: state.file,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      }
    }

    if (idType === 'productid') {
      if (state.action === 4) { // delete
        deleteSingleLineAttributesProductId({
          SiteId: siteId,
          AttributeDefKeyId: state.attribute,
          LanguageId: state.language,
          File: state.file,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      } else {
        postSingleLineAttributesProductId({
          SiteId: siteId,
          AttributeDefKeyId: state.attribute,
          LanguageId: state.language,
          File: state.file,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      }
    }
  };

  return (
    <div className="flex items-start justify-start w-full gap-10 mt-4 lg:flex-col xl:flex-row">
      <div className="flex flex-col">
        <div
          className="mb-8 bg-white border rounded-lg right-8"
        >
          <div className="flex flex-col w-full h-full p-4">
            <span className="text-lg">Download Templates</span>
            <a
              href={PluCsv}
              download="Bulk_Plu_Template.csv"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-700"
            >
              Plu Template
            </a>
            <a
              href={ProductIdCsv}
              download="Bulk_Product_Id_Template.csv"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-700"
            >
              Prod Id Template
            </a>
          </div>
        </div>
        <section>
          <div className="flex-1 p-4 mb-6 text-left bg-white border-2 rounded w-96 border-error-600">
            <Typography gutterBottom className="font-gothammedium">
              Important
            </Typography>
            <ul>
              <li className="pt-2">
                Dates should be provided in the format:
                {' '}
                <i>dd/MM/yyyy</i>
                {' '}
                or
                {' '}
                <i>dd/MM/yyyy hh:mm</i>
              </li>
              <li className="pt-2">Only valid entries will be saved.</li>
            </ul>
          </div>
        </section>
      </div>

      <Container maxWidth="md">
        <section className="w-full">
          <form className="flex h-[calc(100vh-185px)] flex-col flex-wrap gap-8" onSubmit={handleBulk}>
            <section className="flex flex-col max-w-sm gap-8">
              <div key="step1">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      1
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Select Content Language
                  </Typography>
                </div>

                <Box sx={{ paddingTop: '1rem' }}>
                  <VirtualizedAutocomplete
                    options={languagesOptions.map((obj) => ({
                      ...obj,
                      Name: obj.LanguageName,
                    }))}
                    textFieldLabel="Content Language"
                    textFieldVariant="outlined"
                    value={languagesOptions.find((obj) => obj?.LanguageId === state.language) || null}
                    getOptionLabel={(option) => option.LanguageName}
                    onSelectedValue={(value) => dispatch({ type: 'CONTENT_LANGUAGE', payload: value?.LanguageId })}
                    optionKey="LanguageName"
                  />
                </Box>
              </div>
              <div key="step1">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      2
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Select Action
                  </Typography>
                </div>

                <Box sx={{ paddingTop: '1rem' }}>
                  <FormControl fullWidth>
                    <InputLabel id="action-label">Action</InputLabel>
                    <Select
                      labelId="action-label"
                      id="action-select"
                      label="Action"
                      value={state.action}
                      onChange={(e) => dispatch({ type: 'ACTION', payload: e.target.value })}
                    >
                      {/* <MenuItem value={1}>Insert</MenuItem> */}
                      {/* <MenuItem value={2}>Read</MenuItem> */}
                      <MenuItem value={3}>Update</MenuItem>
                      <MenuItem value={4}>Delete</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div key="step2">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      3
                    </Typography>
                  </div>
                  <Typography variant="body2">Select Attribute</Typography>
                </div>

                <Box sx={{ paddingTop: '1rem' }}>
                  <VirtualizedAutocomplete
                    key="Attribute"
                    options={siteAttributeOptions}
                    textFieldLabel="Attribute"
                    textFieldVariant="outlined"
                    getOptionLabel={(option) => option.AttributeName}
                    onSelectedValue={(value) => dispatch({ type: 'ATTRIBUTE', payload: value?.ProductAttributeDefId })}
                    optionKey="AttributeName"
                    disabled={!state.language}
                    value={siteAttributeOptions.find((obj) => obj.ProductAttributeDefId === state.attribute) || null}
                  />
                </Box>
              </div>
            </section>
            <section className="flex flex-col max-w-sm gap-8">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      4
                    </Typography>
                  </div>
                  <Typography variant="body2">PLU or Product ID</Typography>
                </div>

                <Box className="flex justify-start">
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      aria-label="type"
                      defaultValue="plu"
                      name="radio-buttons-group"
                      onChange={(e) => setIdType(e.target.value)}
                      value={idType}
                      sx={{ mb: 2 }}
                    >
                      <FormControlLabel value="plu" control={<Radio />} label="PLU" />
                      <FormControlLabel
                        value="productid"
                        control={<Radio />}
                        label="Product ID"
                      />
                    </RadioGroup>
                    {idType === 'plu' ? (
                      <VirtualizedAutocomplete
                        options={sourceOptions}
                        textFieldLabel="Sources"
                        textFieldVariant="outlined"
                        value={sourceOptions.find((obj) => obj?.SourceId === state.sourceId) || null}
                        getOptionLabel={(option) => option.Name}
                        onSelectedValue={(value) => dispatch({ type: 'SOURCE', payload: value?.SourceId })}
                        optionKey="Name"
                        disabled={!state.language}
                      />
                    ) : (
                      <div className="hidden" />
                    )}
                  </FormControl>
                </Box>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      5
                    </Typography>
                  </div>
                  <Typography variant="body2">Upload File</Typography>
                </div>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                  }}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept=".csv"
                      id="contained-button-file"
                      multiple
                      type="file"
                      ref={fileInputRef}
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          dispatch({ type: 'FILE', payload: e.target.files[0] });
                        }
                      }}
                    />
                    <Button
                      disabled={idType === 'plu' && !state.sourceId}
                      variant="outlined"
                      component="span"
                      color="info"
                      className="mr-2"
                    >
                      Choose File
                    </Button>
                  </label>
                  <TextField
                    disabled
                    value={state.file?.name || ''}
                    variant="standard"
                    placeholder="No File Chosen"
                    className="mr-2"
                  />
                  {state.file && (
                    <CancelIcon
                      className="cursor-pointer text-error-400"
                      onClick={() => {
                        dispatch({ type: 'FILE', payload: '' });
                        fileInputRef.current.value = '';
                      }}
                    />
                  )}
                </Box>

                <div className="w-full">
                  <div className="relative float-right w-max">
                    <Button
                      variant="contained"
                      ref={submitBtn}
                      className="z-10"
                      disabled={submitDisabled}
                      type="submit"
                    >
                      Submit
                    </Button>
                    {submitDisabled ? null : (
                      <div
                        className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 animate-ping bg-primary-400"
                        style={{
                          width: `${buttonWidth / 1.5}px`,
                          height: `${buttonHeight / 1.25}px`,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </form>
        </section>
      </Container>
    </div>
  );
}

export default Attributes;
