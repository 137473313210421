import postRequest from '../../../../postRequest';

/**
 * Index all sites.
 */
function postSite() {
  const endpoint = '/oc-product/v1/index/sites';

  return new Promise((resolve, reject) => {
    postRequest(endpoint, null, 'ProductIndex Sites')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postSite;
