/* eslint-disable no-nested-ternary */
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import patchProductNewInDate from '../../../api/oc_product/bulk/bulk_process/newindates/patchNewInDates';
import postSearch from '../../../api/oc_product/search/posts/postSearch';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import Filter from '../../../components/filter/Filter';
import Image from '../../../components/image/Image';
import splitString from '../../../utils/splitIdString';

function ButtonsToolbar(
  props,
  toggleFilter,
  refreshReports,
  setIsOpen,
  isOpen,
  siteId,
) {
  const { selected } = props;

  const storedValue = localStorage.getItem('selectedDateTimeForNewInDate');
  const initialDateTime = storedValue ? new Date(storedValue) : new Date();

  const [selectedDateTimeForNewInDate, setSelectedDateTimeForNewInDate] = useState(initialDateTime);

  React.useEffect(() => {
    if (!selectedDateTimeForNewInDate && isOpen) {
      // Set to current date and time if no selected value
      setSelectedDateTimeForNewInDate(new Date());
    } else {
      // Save the selectedDateTimeForNewInDate to local storage
      localStorage.setItem('selectedDateTimeForNewInDate', selectedDateTimeForNewInDate);
    }
    if (selected.length === 0 && isOpen) {
      setSelectedDateTimeForNewInDate(new Date());
    }
  }, [selected, selectedDateTimeForNewInDate, isOpen]);

  const handleInvalidDate = (errorMessage) => {
    toast.error(errorMessage, { autoClose: 2000 });
    setIsOpen(false);
  };

  const handleSetDate = () => {
    const selectedToObj = selected.map((productId) => (productId));
    if (!selectedDateTimeForNewInDate) {
      handleInvalidDate('Please provide a New In date');
      return;
    }
    patchProductNewInDate(selectedToObj, siteId, selectedDateTimeForNewInDate)
      .then(() => {
        refreshReports(true);
      });
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        p: 1,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row" spacing={2}>
        {(isOpen && selected.length > 0)
          ? (
            <DateTimePicker
              label="New In Date"
              inputFormat="dd/MM/yyyy HH:mm:ss"
              size="small"
              views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
              value={selectedDateTimeForNewInDate}
              onChange={(newValue) => { setSelectedDateTimeForNewInDate(newValue); }}
              renderInput={(params) => <TextField {...params} size="small" />}
              ampm={false}
            />
          )
          : (
            <Button
              variant="contained"
              size="small"
              disabled={selected.length < 1}
              onClick={() => setIsOpen(true)}
            >
              Set Date
            </Button>
          )}
        <Button
          variant="contained"
          size="small"
          disabled={selected.length < 1 || (!isOpen)}
          color="info"
          onClick={() => handleSetDate()}
        >
          Submit
        </Button>
      </Stack>
      <Button variant="contained" onClick={() => toggleFilter(true)}>Filter</Button>
    </Box>
  );
}

function CustomPagination(
  selectedPage,
  onChangeSelectedPage,
  pageSize,
  onChangePageSize,
  pageAmount,
  totalProducts,
) {
  return (
    <div className="flex items-center justify-end gap-4">
      <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center mt-5 mb-0 sm:mt-0">
        <Typography variant="body2" className="mr-5">Rows per page:</Typography>
        <Select
          value={pageSize}
          onChange={onChangePageSize}
          autoWidth
          size="small"
          variant="standard"
        >
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={400}>400</MenuItem>
          <MenuItem value={600}>600</MenuItem>
          <MenuItem value={800}>800</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={pageAmount}
        page={selectedPage}
        onChange={onChangeSelectedPage}
        size="small"
      />
      <Typography variant="body2">Total:</Typography>
      <Typography variant="body2" className="mr-5">{totalProducts}</Typography>
    </div>
  );
}

function NewInDates({ refreshReports }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const siteCode = useSelector((state) => state.user.loginResponse.selectedSite.SiteCode);
  const [isOpen, setIsOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(true);
  const [siteSources, setSiteSources] = useState([]);

  const [selected, setSelected] = useState([]);

  const [pluColumns, setPluColumns] = useState([]);

  const pageNumber = searchParams.get('page');
  const [selectedPage, setSelectedPage] = useState(Number(pageNumber) || 1);
  const pageSize = searchParams.get('pageSize');
  const [pageAmount, setPageAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Number(pageSize) || 15);

  const [firstLoadExample, setFirstLoadExample] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [rowCountState, setRowCountState] = useState(totalPages);
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: 'ProductImage',
      headerName: 'Image',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <Image
            src={params.value}
            height="45px"
            width="45px"
            alt="File not found"
          />
        </Box>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 70,
      flex: 1,
    },
    ...pluColumns,
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'BrandName',
      headerName: 'Brand',
      flex: 1,
    },
    {
      field: 'IsActive',
      headerName: 'Active',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (params.value ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      )),
    },
    {
      field: 'IsApproved',
      headerName: 'Status',
      renderCell: (params) => (
        <Chip
          label={params.value ? 'Approved' : 'Unapproved'}
          color={params.value ? 'success' : 'error'}
          size="small"
        />
      ),
      flex: 1,
    },
    {
      field: 'NewInDate',
      headerName: 'New In Date',
      minWidth: 108,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Stack direction="column">
              Not available
            </Stack>
          );
        }
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
  ];

  const fetchData = async () => {
    setFilterOpen(false);
    setLoading(true);
    setFirstLoadExample(false);

    const idType = searchParams.get('idType');
    const ids = searchParams.get('ids');

    const selectedSourcesOnly = searchParams.get('selectedSourcesOnly');

    const approved = searchParams.get('approved');
    const active = searchParams.get('active');
    const onSite = searchParams.get('onSite');
    const hasProductType = searchParams.get('hasProductType');
    const categorised = searchParams.get('categorised');

    const hasImage = searchParams.get('hasImage');
    const complete = searchParams.get('complete');
    const dropshippable = searchParams.get('dropshippable');
    const selectedProductTypesOnly = searchParams.get('selectedProductTypesOnly');
    const hasStockLocationsOnly = searchParams.get('hasStockLocationsOnly');
    const attributeOptionIds = splitString(searchParams.get('productAttributeLookupOptionId')).map((str) => Number(str));
    const attributeValues = searchParams.get('productAttributeDefValues');
    const dataTypeName = searchParams.get('productAttributeDataTypeName');
    const dataTypeId = searchParams.get('dataTypeId');
    const blankAttributeValue = searchParams.get('selectedBlankAttributeValue');

    let modifiedAttributeValue;

    if (dataTypeName === 'date') {
      modifiedAttributeValue = `${attributeValues}T00:00:00.000Z`;
    } else if (dataTypeName === 'bit') {
      if (attributeValues === 'Yes') {
        modifiedAttributeValue = '1';
      } else if (attributeValues === 'No') {
        modifiedAttributeValue = '0';
      }
    } else if (dataTypeName === 'flag') {
      if (attributeValues === 'Yes') {
        modifiedAttributeValue = 'true';
      } else if (attributeValues === 'No') {
        modifiedAttributeValue = 'false';
      }
    } else {
      modifiedAttributeValue = attributeValues;
    }

    const filterData = {
      siteId,
      siteCode,
      omsId: searchParams.get('omsId'),
      productIds: idType === 'productIds' ? splitString(ids, 'number') : [],
      plus: idType === 'plus' ? splitString(ids) : [],
      eans: idType === 'eans' ? splitString(ids) : [],
      skus: idType === 'skus' ? splitString(ids) : [],
      sourceIds: splitString(searchParams.get('sources')),
      hasSelectedSourcesOnly: selectedSourcesOnly === 'true' ? true : selectedSourcesOnly === 'false' ? false : null,
      hasSelectedProductTypesOnly: selectedProductTypesOnly === 'true' ? true : selectedProductTypesOnly === 'false' ? false : null,
      categoryIds: splitString(searchParams.get('categories')),
      isApproved: approved === 'yes' ? true : approved === 'no' ? false : null,
      isActive: active === 'yes' ? true : active === 'no' ? false : null,
      isOnSite: onSite === 'yes' ? true : onSite === 'no' ? false : null,
      hasProductType: hasProductType === 'yes' ? true : hasProductType === 'no' ? false : null,
      hasCategories: categorised === 'yes' ? true : categorised === 'no' ? false : null,
      name: searchParams.get('name'),
      brandIds: splitString(searchParams.get('brands')),
      productTypeIds: splitString(searchParams.get('productTypes')),
      hasImage: hasImage === 'yes' ? true : hasImage === 'no' ? false : null,
      isComplete: complete === 'yes' ? true : complete === 'no' ? false : null,
      isDropshippable: dropshippable === 'yes' ? true : dropshippable === 'no' ? false : null,
      erpCategorySourceId: searchParams.get('erpSource'),
      erps: splitString(searchParams.get('erpCategoryIds')),
      stockSourceLocationId: searchParams.get('stockLocation'),
      hasStockInSourceLocationIds: splitString(searchParams.get('hasStockLocations')),
      hasSelectedLocationsOnly: hasStockLocationsOnly === 'on',
      createdDateFrom: searchParams.get('createdFrom') ? `${searchParams.get('createdFrom')}T00:00:00.000Z` : null,
      createDateTo: searchParams.get('createdTo') ? `${searchParams.get('createdTo')}T23:59:59.999Z` : null,
      updateDateFrom: searchParams.get('updatedFrom') ? `${searchParams.get('updatedFrom')}T00:00:00.000Z` : null,
      updatedDateTo: searchParams.get('updatedTo') ? `${searchParams.get('updatedTo')}T23:59:59.999Z` : null,
      releaseDateFrom: searchParams.get('releasedFrom') ? `${searchParams.get('releasedFrom')}T00:00:00.000Z` : null,
      releaseDateTo: searchParams.get('releasedTo') ? `${searchParams.get('releasedTo')}T23:59:59.999Z` : null,
      productAttributeLanguageId: Number(searchParams.get('languageId')),
      productAttributeDefId: Number(searchParams.get('productAttributeDefId')),
      productAttributeLookupOptionIds: attributeOptionIds || [],
      productAttributeDefValue: modifiedAttributeValue,
      attributeDataTypeName: dataTypeName,
      attributeDataTypeId: Number(dataTypeId),
      searchByAttributeRequest: {
        productAttributeLanguageId: Number(searchParams.get('languageId')),
        productAttributeDefId: Number(searchParams.get('productAttributeDefId')),
        productAttributeLookupOptionIds: attributeOptionIds || [],
        productAttributeDefValue: modifiedAttributeValue,
        attributeDataTypeName: dataTypeName,
        attributeDataTypeId: Number(dataTypeId),
        selectedBlankAttributeValue: blankAttributeValue === 'true' ? true : blankAttributeValue === 'false' ? false : null,
      },
    };

    const search = await postSearch(selectedPage, rowsPerPage, filterData)
      .catch((err) => {
        console.error('ProductSearch', err);
        setLoading(false);
      });

    const pluMapped = new Map();

    const mappedRows = search.data.Data.map((product) => {
      const newRow = {
        id: product.ProductId,
        ...product,
      };
      const rowToMap = new Map(Object.entries(newRow));
      product.PluSource?.forEach((pluSource) => {
        rowToMap.set(pluSource.SourceShortDescription, pluSource.RemoteSystemId);

        const foundSource = siteSources
          .find((source) => source.ShortDescription === pluSource.SourceShortDescription);
        if (foundSource) {
          pluMapped.set(foundSource.ShortDescription, foundSource.Name);
        }
      });

      const backToObject = Object.fromEntries(rowToMap);

      return backToObject;
    });

    const pluColumnsArray = [];
    pluMapped.forEach((value, key) => {
      switch (value) {
        case 'Shogun':
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 70,
            flex: 1,
          });
          break;
        case value.includes('Fluent'):
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 80,
            flex: 1,
          });
          break;
        case 'AnatwineUK':
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 100,
            flex: 1,
          });
          break;
        default:
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 80,
            flex: 1,
          });
          break;
      }
    });
    setPluColumns(pluColumnsArray);

    if (mappedRows === undefined) {
      setRows([]);
    } else {
      setRows(mappedRows);
    }

    setTotalPages(search.data?.Pagination.TotalCount);
    setPageAmount(search.data?.Pagination.PageCount);
    setTotalProducts(search.data?.Pagination.TotalRowCount);
    setLoading(false);
  };

  const onChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
  };

  const onChangePageSize = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalPages / newItemsPerPage);
    const newPage = Math.min(selectedPage, newPageCount);
    // setPageSize(newItemsPerPage);
    setSearchParams((params) => {
      params.set('pageSize', newItemsPerPage);
      return params;
    });
    setRowsPerPage(newItemsPerPage);
    setSelectedPage(newPage);
  };

  React.useEffect(() => {
    getSources(siteId)
      .then((res) => setSiteSources(res.data))
      .catch((err) => console.error(err));
    setRows([]);
    setTotalPages(0);
    setPageAmount(0);
    setRowCountState(0);
    setFilterOpen(true);
  }, [siteId]);

  React.useEffect(() => {
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) { // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter((str) => str !== 'page' && str !== 'siteCode' && str !== 'omsId' && str !== 'pageSize');
    const count = filtered.length;
    const hasParams = count > 0;
    if (hasParams && siteSources.length > 0) {
      fetchData();
    }
  }, [siteSources]);

  React.useEffect(() => {
    setSearchParams((params) => {
      params.set('pageSize', rowsPerPage);
      return params;
    });
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) { // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter((str) => str !== 'page' && str !== 'siteCode' && str !== 'omsId' && str !== 'pageSize');
    const count = filtered.length;
    const hasParams = count > 0;
    if (!firstLoadExample && hasParams) {
      fetchData();
    }
  }, [selectedPage, rowsPerPage]);

  React.useEffect(() => {
    setRowCountState((prev) => (totalPages !== undefined ? totalPages : prev));
  }, [totalPages]);

  React.useEffect(() => {
    if (firstLoadExample) {
      setSearchParams((params) => {
        const arr = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const key of params.keys()) {
          arr.push(key);
        }
        /**
         * For some reason we have to do this...
         * If you do the, if not then delete, statement below within the for above
         * or do params.forEach
         * The loop with miss every even indexed key...
         */
        arr.forEach((key) => {
          if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
            params.delete(key);
          }
        });
        return params;
      });
    }
  }, []);

  return (
    <main className="flex flex-col w-full h-full gap-4">
      <div>
        <Filter
          open={filterOpen}
          siteSources={siteSources}
          onSearch={() => fetchData()}
        />
      </div>
      <div className="min-h-[600px] h-full max-h-[calc(100vh-180px)] bg-white">
        <DataGrid
          rows={rows}
          columns={columns}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={[
            {
              groupId: 'PLUs',
              children: siteSources.map((obj) => ({ field: obj.ShortDescription })),
            },
          ]}
          slots={{
            toolbar: (props) => ButtonsToolbar(
              { ...props, selected },
              () => setFilterOpen(!filterOpen),
              () => refreshReports(true),
              () => setIsOpen(!isOpen),
              isOpen,
              siteId,
            ),
            pagination: () => CustomPagination(
              selectedPage,
              onChangeSelectedPage,
              rowsPerPage,
              onChangePageSize,
              pageAmount,
              totalPages,
              totalProducts,
            ),
          }}
          loading={loading}
          checkboxSelection
          rowSelectionModel={selected}
          onRowSelectionModelChange={(selectionModel) => setSelected(selectionModel)}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 100}
          paginationMode="server"
          pageSizeOptions={[15, 30, 50, 100, 200, 400, 600, 800, 1000]}
          rowsPerPageOptions={[15, 30, 50, 100, 200, 400, 600, 800, 1000]}
          rowCount={rowCountState}
        />
      </div>
    </main>
  );
}

export default NewInDates;
