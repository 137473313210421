import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import postPreReleaseProductsPluId from '../../../api/oc_product/bulk/bulk_process/pre_release_products/post/plud_id/postPreReleaseProductsPluId';
import postPreReleaseProductsProductId from '../../../api/oc_product/bulk/bulk_process/pre_release_products/post/product_id/postPreReleaseProductsProductId';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import PreReleaseProductsCsv from '../../../assets/bulk_templates/Bulk_Pre-Release_Template.csv';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import doesObjectContainEmptyValues from '../../../utils/doesObjectContainEmptyValues';
import restrictKeys from '../../../utils/restrictKeys';
import splitString from '../../../utils/splitIdString';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SOURCE':
      return {
        ...state,
        sourceId: action.payload,
      };
    case 'RELEASE_DATES':
      return {
        ...state,
        releaseDates: action.payload,
      };
    case 'FILE':
      return {
        ...state,
        file: action.payload,
      };
    case 'REFERENCES':
      return {
        ...state,
        plus: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};

const MAX_FILE_SIZE_MB = 5;

function PreReleaseProducts({ refreshReports }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const submitBtn = React.useRef(null);
  const fileInputRef = React.useRef(null);
  const [buttonWidth, setButtonWidth] = React.useState(0);
  const [buttonHeight, setButtonHeight] = React.useState(0);

  const initialState = {
    sourceId: '',
    file: '',
    releaseDates: '',
    plus: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [searchParams, setSearchParams] = useSearchParams();
  const [sourceOptions, setSourceOptions] = React.useState([]);
  const [idType, setIdType] = React.useState('plu');
  const [releasedDate, setReleasedDate] = React.useState(null);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  const [uploadType, setUploadType] = React.useState({
    label: 'File upload',
    value: 'fileUpload',
  });

  const handleKeyPress = (event) => {
    if (idType === 'productid') {
      restrictKeys(event);
    }
  };

  const Input = styled('input')({
    display: 'none',
  });

  const handleBulk = (e) => {
    e.preventDefault();
    if (uploadType.label === 'Value upload') {
      postPreReleaseProductsPluId({
        SiteId: siteId,
        sourceId: state.sourceId,
        releaseDates: state.releaseDates,
        File: state.file,
        plus: splitString(state.plus),
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    } else {
      postPreReleaseProductsProductId({
        SiteId: siteId,
        File: state.file,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    }
  };

  searchParams.get('omsId');

  React.useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input value
      dispatch({ type: 'FILE', payload: '' }); // Reset file state
      dispatch({ type: 'RESET', payload: initialState });
    }
    setIdType('plu');
    setUploadType({
      label: 'File upload',
      value: 'fileUpload',
    });
    dispatch({ type: 'RESET', payload: initialState });
    setReleasedDate(null);
    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect(() => {
    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
         * For some reason we have to do this...
         * If you do the, if not then delete, statement below within the for above
         * or do params.forEach
         * The loop with miss every even indexed key...
         */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  }, []);

  React.useEffect(() => {
    dispatch({ type: 'FILE', payload: '' });
  }, [siteId]);

  React.useEffect(() => {
    if (submitBtn.current) {
      const sbmBtn = submitBtn.current.getBoundingClientRect();
      setButtonWidth(sbmBtn.width);
      setButtonHeight(sbmBtn.height);
    }
  }, [submitBtn.current]);

  const resetState = () => {
    dispatch({ type: 'RESET', payload: initialState });
  };

  React.useEffect(() => {
    resetState();
  }, [uploadType]);

  React.useEffect(() => {
    if (idType !== 'plu') {
      delete state.sourceId;
    } else if (!state.sourceId) {
      state.sourceId = '';
    }
    const isEmpty = doesObjectContainEmptyValues(state);
    setSubmitDisabled(isEmpty);
  }, [state, idType]);

  React.useEffect(() => {
    if (state.file !== '' && state.file.type !== 'text/csv') {
      toast.error('Please upload CSV file');
    }

    if (state.file.type !== 'text/csv') {
      const isEmpty = doesObjectContainEmptyValues(state);
      setSubmitDisabled(isEmpty);
      state.file = '';
    }

    if (state.file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.error('File size is too big. Please upload a file smaller than 5MB.');
      state.file = '';
      dispatch({ type: 'FILE', payload: '' });
    }
  }, [state.file]);

  return (
    <div className="flex items-start justify-start w-full gap-10 mt-4 lg:flex-col xl:flex-row">
      <div className="flex flex-col">
        <div
          className="mb-8 bg-white border rounded-lg right-8"
        >
          <div className="flex flex-col h-full p-4 w-96">
            <span className="text-lg">Download Templates</span>
            <a
              href={PreReleaseProductsCsv}
              download="Bulk_Plu_Template.csv"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-700"
            >
              Pre-Release Products Template
            </a>
          </div>
        </div>
        <section>
          <div className="flex-1 p-6 mb-6 text-left bg-white border-2 rounded w-96 border-error-600">
            <Typography gutterBottom className="font-gothammedium">
              Important
            </Typography>
            <ul>
              <li className="pt-2">Pre-Release Products replaces : Product Info/Dates</li>
              <li className="pt-2">Create New Temp products that have not been imported yet</li>
              <li className="pt-2">Dates should be provided in the format:  dd/mm/yyyy hh:mm</li>
              <li className="pt-2">Example: 05/02/2024</li>
              <li className="pt-2">Only valid entries will be saved.</li>
            </ul>
          </div>
        </section>
      </div>

      <Container maxWidth="md">
        <section className="w-full">
          {/* flex-col flex-wrap h-[calc(100vh-250px)] */}
          <form className="flex h-[calc(100vh-185px)] flex-col flex-wrap gap-8" onSubmit={handleBulk}>
            <section className="flex flex-col max-w-sm gap-8">
              <div>
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      1
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Select an Upload Type
                  </Typography>
                </div>

                <Box sx={{ paddingTop: '1rem' }}>
                  <VirtualizedAutocomplete
                    textFieldLabel="Upload Type"
                    textFieldVariant="outlined"
                    value={uploadType}
                    onChange={(event, newValue) => setUploadType(newValue)}
                    options={[
                      { label: 'Value upload', value: 'valueUpload' },
                      { label: 'File upload', value: 'fileUpload' },
                    ]}
                    optionKey="label"
                    renderInput={(params) => (
                      <TextField {...params} label="Upload Type" variant="outlined" />
                    )}
                  />
                </Box>
              </div>
              {uploadType && uploadType.label === 'Value upload' ? (
                <section>
                  <div>
                    <div className="flex items-center gap-2">
                      <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                        <Typography variant="caption" className="font-gothammedium">
                          2
                        </Typography>
                      </div>
                      <Typography variant="body2">
                        Select A Source
                      </Typography>
                    </div>

                    <Box sx={{ paddingTop: '1rem' }}>
                      <FormControl component="fieldset" fullWidth>
                        <VirtualizedAutocomplete
                          options={sourceOptions}
                          textFieldLabel="Sources"
                          textFieldVariant="outlined"
                          value={sourceOptions.find((obj) => obj?.SourceId === state.sourceId) || null}
                          getOptionLabel={(option) => option.Name}
                          onSelectedValue={(value) => dispatch({ type: 'SOURCE', payload: value?.SourceId })}
                          optionKey="Name"
                        />
                      </FormControl>
                    </Box>
                  </div>
                </section>
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                      <Typography variant="caption" className="font-gothammedium">
                        2
                      </Typography>
                    </div>
                    <Typography variant="body2">Upload File</Typography>
                  </div>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept=".csv"
                        id="contained-button-file"
                        multiple
                        type="file"
                        ref={fileInputRef}
                        hidden
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            dispatch({ type: 'FILE', payload: e.target.files[0] });
                          }
                        }}
                      />
                      <Button
                        variant="outlined"
                        component="span"
                        color="info"
                        className="mr-2"
                      >
                        Choose File
                      </Button>
                    </label>
                    <TextField
                      disabled
                      value={state.file?.name || ''}
                      variant="standard"
                      placeholder="No File Chosen"
                      className="mr-2"
                    />
                    {state.file && (
                      <CancelIcon
                        className="cursor-pointer text-error-400"
                        onClick={() => {
                          dispatch({ type: 'FILE', payload: '' });
                          fileInputRef.current.value = '';
                        }}
                      />
                    )}
                  </Box>

                  <div className="w-full">
                    <div className="relative float-right w-max">
                      <Button
                        variant="contained"
                        ref={submitBtn}
                        className="z-10"
                        type="submit"
                        disabled={!state.file?.name}
                      >
                        Submit
                      </Button>
                      {!state.file?.name ? null : (
                        <div
                          className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 animate-ping bg-primary-400"
                          style={{
                            width: `${buttonWidth / 1.5}px`,
                            height: `${buttonHeight / 1.25}px`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </section>
            {uploadType && uploadType.label === 'Value upload' ? (
              <section className="flex flex-col max-w-sm gap-8">
                <div>
                  <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                      <Typography variant="caption" className="font-gothammedium">
                        3
                      </Typography>
                    </div>
                    <Typography variant="body2">
                      Set Release Dates
                    </Typography>
                  </div>

                  <Box sx={{ paddingTop: '1rem' }}>
                    <DateTimePicker
                      id="released-date-picker"
                      label="Released Date"
                      views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                      value={releasedDate}
                      onChange={(newValue) => {
                        setReleasedDate(newValue);
                        dispatch({ type: 'RELEASE_DATES', payload: newValue?.toJSON() });
                      }}
                      inputFormat="dd/MM/yyyy HH:mm:ss"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={releasedDate === ''}
                        />
                      )}
                      showDaysOutsideCurrentMonth
                      disabled={!state.sourceId}
                      className="w-full"
                    />
                  </Box>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                      <Typography variant="caption" className="font-gothammedium">
                        4
                      </Typography>
                    </div>
                    <Typography variant="body2">
                      Enter PLUs
                    </Typography>
                  </div>

                  <TextField
                    label="PLUs"
                    placeholder={'Type or Paste in your \'PLUs\' here.'}
                    multiline
                    rows={6}
                    value={state.plus}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => dispatch({ type: 'REFERENCES', payload: e.target.value })}
                  />
                </div>
                <div className="w-full">
                  <div className="relative float-right w-max">
                    <Button
                      ref={submitBtn}
                      variant="contained"
                      type="submit"
                      id="submit-button"
                      className="z-10"
                      disabled={releasedDate === '' || !state.sourceId || state.plus.length === 0 || !state.releaseDates}
                    >
                      Submit
                    </Button>
                    {!submitDisabled && (
                      <div
                        className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 bg-primary-400 animate-ping"
                        style={{ width: `${buttonWidth / 1.5}px`, height: `${buttonHeight / 1.25}px` }}
                      />
                    )}
                  </div>
                </div>
              </section>
            ) : (
              <div className="hidden" />
            )}
          </form>
        </section>
      </Container>
    </div>
  );
}

export default PreReleaseProducts;
