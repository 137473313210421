import postRequest from '../../../../postRequest';

/**
 * Post request to get pre calculation of how many products will be affected by margin threshold change.
 *
 * @param {number} marginThresholdConfigId
 * @param {number} newMarginThreshold
 *
 */
function postPreCalculation(marginThresholdConfigId, newMarginThreshold) {
  const endpoint = `/oc-data-management/v1/margin-thresholds/${marginThresholdConfigId}/pre-calculation?newMarginThreshold=${newMarginThreshold}`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, 'Get Margin Threshold Products Affected Calculation')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postPreCalculation;
