import './release_notes_styles.css';

import React from 'react';

import Release20230824 from './releases/2023/08/24';
import Release20230926 from './releases/2023/09/26';
import Release20231025 from './releases/2023/10/25';
import Release20231108 from './releases/2023/11/08';
import Release20231206 from './releases/2023/12/06';
import Release20231215 from './releases/2023/12/15';
import Release20240118 from './releases/2024/01/18';
import Release20240214 from './releases/2024/02/14';
import Release20240229 from './releases/2024/02/29';
import Release20240314 from './releases/2024/03/14';
import Release20240425 from './releases/2024/04/25';
import Release20240509 from './releases/2024/05/09';
import Release20240523 from './releases/2024/05/23';
import Release20240620 from './releases/2024/06/20';
import Release20240718 from './releases/2024/07/18';
import Release20240912 from './releases/2024/09/12';
import Release20241009 from './releases/2024/10/09';

function ReleaseNotes() {
  return (
    <article className="mx-auto mt-8 prose max-w-prose lg:text-lg">
      <h1 className="font-gothambold text-neutral-700">Release Notes</h1>
      {/* New release notes go here with a <hr /> in-between */}
      <Release20241009 />
      <hr />
      <Release20240912 />
      <hr />
      <Release20240718 />
      <hr />
      <Release20240620 />
      <hr />
      <Release20240523 />
      <hr />
      <Release20240509 />
      <hr />
      <Release20240425 />
      <hr />
      <Release20240314 />
      <hr />
      <Release20240229 />
      <hr />
      <Release20240214 />
      <hr />
      <Release20240118 />
      <hr />
      <Release20231215 />
      <hr />
      <Release20231206 />
      <hr />
      <Release20231108 />
      <hr />
      <Release20231025 />
      <hr />
      <Release20230926 />
      <hr />
      <Release20230824 />
    </article>
  );
}

export default ReleaseNotes;
