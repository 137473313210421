import React from 'react';

export default function Release20230926() {
  return (
    <section>
      <h2 className="font-gothammedium text-neutral-700">
        Bug Fixes and Improvements
      </h2>
      <p className="-mt-4">26th September 2023</p>

      <h3 className="font-gothammedium text-neutral-700">
        Product Indexing Service
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          <strong>
            Faster Speed
          </strong>
          <ul>
            <li>
              Updating the indexer when a change is made on the PDP is close to instant.
            </li>
            <li>
              Enrichment section now matches the PDP.
            </li>
          </ul>
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Products Table (PLP)
      </h3>
      <h4>
        Features:
      </h4>

      <ol>
        <li>
          Added Total Stock column to table.
        </li>
        <li>
          <strong>
            Export All
          </strong>
          <ul>
            <li>
              Zipped Files
            </li>
            <li>
              Faster Downloads
            </li>
          </ul>
        </li>
      </ol>
      <h4>
        Bug Fixes:
      </h4>
      <ul>
        <li>
          Flickering of the table has now been fixed.
        </li>
        <li>
          <strong>
            Has Stock Filter
          </strong>
          <ul>
            <li>
              Selected Locations Only
            </li>
            <li>
              Returns Correct Data.
            </li>
          </ul>
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Product Page (PDP)
      </h3>
      <h4>
        Bug Fixes:
      </h4>
      <ul>
        <li>
          Set a category as a products primary category.
        </li>
        <li>
          Fixed the issue of PFS + Other Details toggles erroring.
        </li>
        <li>
          Approve / Unapprove now refreshes on the variants table when the style section is updated.
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Bulk Uploads
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          Categories Page
        </li>
        <li>
          Filter Transaction table data.
        </li>
        <li>
          You can now upload an Excel or CSV file on selected pages.
        </li>
        <li>
          <strong>
            Export Transaction table data.
          </strong>
          <ul>
            <li>
              Zip File
            </li>
            <li>
              Fast Downloads
            </li>
          </ul>
        </li>
      </ol>
      <h4>
        Bug Fixes:
      </h4>
      <ol>
        <li>
          Bulk pagination and rows per page now working.
        </li>
        <li>
          More info for 5000+ products now returning data.
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Security Fixes
      </h3>
      <ol>
        <li>
          Auto-Complete Removed.
        </li>
        <li>
          Header Changes, making things more secure.
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Upcoming Features
      </h3>
      <ul>
        <li>
          Product Types.
        </li>
        <li>
          Bug fixes and improvements.
        </li>
        <li>
          Even more speed.
        </li>
      </ul>
    </section>
  );
}
