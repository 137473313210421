import patchRequest from '../../../../../patchRequest';

/**
 * Calls set-fulfilment endpoint to enable fulfilment for SKU level product in PIM DB.
 *
 * @param {array} skus -
 * @example
 * {
 *  "skus": [
 *    {
 *      "sku": "123456",
 *      "vatCode": [
 *        {
 *          "isVatable": true,
 *          "siteCode": "JF"
 *        }
 *      ]
 *    }
 *  ]
 * }
 * @returns {Promise}
 * @example
 * {
 *   data: {
 *     Success: true,
 *     ErrorMessage: null
 *   },
 *   status: 200
 * }
 */
async function patchVariantsVATCode(skus) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/variants-vatcode';

  const body = {
    skus,
  };
  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Variants VAT')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchVariantsVATCode;
