import Box from '@mui/material/Box';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';

import { fetchFascias } from '../redux/fascias/Fascias';
import { fetchPIMSites } from '../redux/sites/sites';
import ResponsiveAppBar from './responsive_menu/ResponsiveAppBar';

function Layout() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchFascias());
    dispatch(fetchPIMSites());
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <ResponsiveAppBar />
      <main className="flex flex-col w-full h-screen overflow-auto">
        <Outlet />
      </main>
    </Box>
  );
}

export default Layout;
