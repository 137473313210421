import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

function CreatedDateFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const createdFrom = searchParams.get('createdFrom');
  const createdTo = searchParams.get('createdTo');

  const handleDataFormatting = (date) => {
    const valueDate = new Date(date);
    const year = new Date(valueDate).getFullYear();
    const monthValue = new Date(valueDate).getMonth();
    const month = (monthValue + 1) < 10 ? `0${monthValue + 1}` : (monthValue + 1);
    const dayValue = new Date(valueDate).getDate();
    const day = dayValue < 10 ? `0${dayValue}` : dayValue;
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Created Date</Typography>
        <div className="flex flex-col w-max">
          <Stack spacing={2}>
            <DatePicker
              id="created-from-date-picker"
              label="From"
              value={createdFrom}
              onChange={(value) => {
                const parsedDate = Date.parse(value);
                if (value === null || (!Number.isNaN(parsedDate) && parsedDate >= Date.parse('1970-01-01'))) {
                  setSearchParams((params) => {
                    params.set('createdFrom', handleDataFormatting(value));
                    if (value === null) params.delete('createdFrom');
                    return params;
                  });
                } else {
                  setSearchParams((params) => {
                    params.delete('createdFrom');
                    return params;
                  });
                }
              }}
              inputFormat="dd-MM-yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                />
              )}
              showDaysOutsideCurrentMonth
              maxDate={createdTo ? new Date(createdTo) : new Date()}
            />
            <DatePicker
              id="created-to-date-picker"
              label="To"
              value={createdTo}
              onChange={(value) => {
                const parsedDate = Date.parse(value);
                if (value === null || (!Number.isNaN(parsedDate) && parsedDate >= Date.parse('1970-01-01'))) {
                  setSearchParams((params) => {
                    params.set('createdTo', handleDataFormatting(value));
                    if (value === null) params.delete('createdTo');
                    return params;
                  });
                } else {
                  setSearchParams((params) => {
                    params.delete('createdTo');
                  });
                }
              }}
              inputFormat="dd-MM-yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                />
              )}
              showDaysOutsideCurrentMonth
              minDate={createdFrom && new Date(createdFrom)}
              maxDate={new Date()}
            />
          </Stack>
        </div>
      </Stack>
    </div>
  );
}

export default CreatedDateFilter;
