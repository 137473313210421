import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a single line attribute.
 *
 * @param {number} SiteId Site Id
 * @param {number} AttributeDefKeyId Attribute Id
 * @param {number} LanguageId Language Id
 * @param {file} File Either a csv or excel file
*/
export default function postSingleLineAttributesProductId({
  SiteId,
  AttributeDefKeyId,
  LanguageId,
  File,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/single-line-attributes/product-id';

  const body = new FormData();
  body.append('SiteId', SiteId);
  body.append('AttributeDefKeyId', AttributeDefKeyId);
  body.append('LanguageId', LanguageId);
  body.append('File', File);

  return new Promise((resolve, reject) => {
    postRequest(
      endpoint,
      body,
      'Single Line Attributes Product Ids',
      'Started : Single Line Attributes : Prod ID (Update)',
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
