import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Pagination, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import postLogs from '../../../../../../api/oc_product/logs/posts/postLogs';
import parseDateString from '../../../../../../utils/parseDateString';
import { ProductContext } from '../../ProductContext';

function History() {
  const [currentPage, setCurrentPage] = useState(1);
  const [username, setUsername] = useState(''.toLowerCase());
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [resetData, setResetData] = useState(false);
  const [logs, setLogs] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [expandedLogs, setExpandedLogs] = useState([]);

  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const { historyReRender } = React.useContext(ProductContext);

  const fetchLogs = async () => {
    const logsData = await postLogs(
      id,
      siteId,
      currentPage,
      itemsPerPage,
      {
        sourceId: null,
        fromDate,
        toDate,
        userName: username,
        remoteSystemId: null,
        isLeadSite: null,
      },
    );
    setLogs(logsData.data.Data);
    setTotalPages(logsData.data.Pagination.PageCount);
    setTotalRows(logsData.data.Summary.TotalRowCount);
  };

  useEffect(() => {
    fetchLogs();
  }, [currentPage, itemsPerPage, historyReRender, id]);

  const handleExpandClick = (logId) => {
    setExpandedLogs((prevExpandedLogs) => {
      if (prevExpandedLogs.includes(logId)) {
        return prevExpandedLogs.filter((log) => log !== logId);
      }
      return [...prevExpandedLogs, logId];
    });
  };

  const handleSearch = () => {
    fetchLogs();
    setResetData(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterFromDateChange = (selectedDate) => {
    setFromDate(selectedDate);
  };

  const handleFilterToDateChange = (selectedDate) => {
    setToDate(selectedDate);
  };

  const handleFilterReset = () => {
    setUsername('');
    setFromDate(null);
    setToDate(null);
    setResetData(true);
  };

  useEffect(() => {
    if (resetData) {
      fetchLogs();
    }
  }, [resetData]);

  const handleChangeItemsPerPage = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(currentPage, newPageCount);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newPage);
  };

  return (
    <section className="flex flex-col gap-4">
      <div className="p-2 bg-white border rounded-lg w-max">
        <div className="flex flex-col items-center sm:flex-row">
          <TextField
            label="Filter by username"
            variant="outlined"
            value={username}
            size="small"
            onChange={(event) => setUsername(event.target.value)}
            className="w-[220px]"
          />
          <DatePicker
            label="From"
            inputFormat="dd-MM-yyyy"
            value={fromDate}
            onChange={handleFilterFromDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
              />
            )}
            showDaysOutsideCurrentMonth
            maxDate={toDate ? new Date(toDate) : new Date()}
            className="w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
          />
          <DatePicker
            label="To"
            inputFormat="dd-MM-yyyy"
            value={toDate}
            onChange={handleFilterToDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
              />
            )}
            showDaysOutsideCurrentMonth
            minDate={fromDate && new Date(fromDate)}
            maxDate={new Date()}
            className="w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
          />
          <Button
            onClick={handleSearch}
            variant="contained"
            className="mt-[16px] sm:mt-0 sm:ml-[16px]"
          >
            Search
          </Button>
          <Button
            onClick={handleFilterReset}
            variant="text"
            className="text-primary-600 mt-[16px] sm:mt-0 sm:ml-[16px]"
          >
            Reset
          </Button>
        </div>
      </div>
      <div className="py-2 bg-white border rounded-lg">
        {logs.length > 0 ? (
          <Timeline sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 'none',
              width: '110px',
            },
          }}
          >
            {logs.map((log) => {
              const jsonStartIndex = log.Message.indexOf('[');
              const jsonEndIndex = log.Message.lastIndexOf(']') + 1;
              const nonJsonMessage = log.Message.substring(0, jsonStartIndex);
              const jsonString = log.Message.substring(jsonStartIndex, jsonEndIndex);

              if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
                try {
                  const parsedJson = JSON.parse(jsonString);
                  const isExpanded = expandedLogs.includes(log.Created);
                  return (
                    <TimelineItem key={log.created}>
                      <TimelineOppositeContent style={{ padding: '2px 16px 6px 16px' }}>
                        <Stack>
                          <Typography variant="body2" style={{ color: '#6f7686' }}>
                            {parseDateString(log.Created)}
                          </Typography>
                        </Stack>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                        <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                      </TimelineSeparator>
                      <TimelineContent style={{ padding: '0 16px 6px 16px' }}>
                        <Typography>{nonJsonMessage}</Typography>
                        {isExpanded ? (
                          <>
                            <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                              Show less
                            </Typography>
                            <pre className="whitespace-pre-wrap">{JSON.stringify(parsedJson, null, 2)}</pre>
                          </>
                        ) : (
                          <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.Created)}>
                            Show more
                          </Typography>
                        )}
                        <Typography className="flex items-center" variant="body2" style={{ color: '#6f7686' }}>
                          <PersonOutlineIcon style={{ color: '#6f7686', marginRight: '5px' }} fontSize="small" />
                          {' '}
                          {log.UserName}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                } catch (error) {
                  console.error('Error parsing JSON:', error);
                  return null;
                }
              } else {
                return (
                  <TimelineItem key={log.created}>
                    <TimelineOppositeContent style={{ padding: '2px 16px 6px 16px' }}>
                      <Stack>
                        <Typography variant="body2" style={{ color: '#6f7686' }}>
                          {parseDateString(log.Created)}
                        </Typography>
                      </Stack>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                      <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                    </TimelineSeparator>
                    <TimelineContent style={{ padding: '0 16px 6px 16px' }}>
                      <Typography>{log.Message}</Typography>
                      <Typography className="flex items-center" variant="body2" style={{ color: '#6f7686' }}>
                        <PersonOutlineIcon style={{ color: '#6f7686', marginRight: '5px' }} fontSize="small" />
                        {' '}
                        {log.UserName}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              }
            })}
          </Timeline>
        ) : <Typography>No results found</Typography>}
      </div>
      <div className="flex flex-col items-center gap-6 p-2 pl-4 ml-auto bg-white border rounded-lg sm:flex-row w-max">
        <FormControl sx={{ minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center">
          <Typography variant="body2" className="mr-4">Rows per page:</Typography>
          <Select
            labelId="logs-per-page"
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
            autoWidth
            size="small"
            variant="standard"
          >
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </section>
  );
}

export default History;
