import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getSiteAttributes from '../../../../../api/oc_product/bulk/sites/site_attributes/gets/getSiteAttributes';
import deleteProductTypeAttribute from '../../../../../api/oc_product/productDataManagement/productTypes/delete/deleteProductTypeAttribute';
import getProductTypesAttribute from '../../../../../api/oc_product/productDataManagement/productTypes/get/getProductTypesAttribute';
import patchProductType from '../../../../../api/oc_product/productDataManagement/productTypes/patch/patchProductType';
import patchProductTypeAttributes from '../../../../../api/oc_product/productDataManagement/productTypes/patch/patchProductTypeAttributes';
import postProductTypeAttribute from '../../../../../api/oc_product/productDataManagement/productTypes/post/postProductTypeAttribute';
import Modal from '../../../../../components/Modal';

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL_STATE':
      return action.payload;
    case 'ID':
      return {
        ...state,
        id: action.payload,
      };
    case 'NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'ATTRIBUTE':
      return {
        ...state,
        attribute: action.payload,
      };
    default:
      return state;
  }
};

function EditToolbar(props) {
  const {
    setRows, setRowModesModel, rows,
  } = props;

  const handleClick = () => {
    const id = rows.length + 1;
    const newRow = {
      id,
      name: '',
      enabled: false,
      required: false,
      isNew: true,
    };
    setRows((prevRows) => [...prevRows, newRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={() => handleClick()}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function EditModal({
  onClose, open, selectedRowData, updatedSelectedRowData,
}) {
  const siteId = useSelector(
    (state) => state.user.loginResponse.selectedSite.SiteId,
  );
  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);
  const handleClose = () => onClose(false);

  const [state, dispatch] = useReducer(reducer, selectedRowData);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [originalRows, setOriginalRows] = React.useState([]);
  const [editName, setEditName] = React.useState('');
  const [editDepartmentCode, setEditDepartmentCode] = React.useState('');
  const [editIsDefault, setEditIsDefault] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [siteAttributeOptions, setSiteAttributeOptions] = React.useState([]);
  const [saveRowLoading, setSaveRowLoading] = React.useState(false);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  };

  const siteAttributeOptionsFormatted = siteAttributeOptions.map((option) => ({
    value: option.ProductAttributeDefId,
    label: option.AttributeName,
  }));

  const handleSaveClick = (params) => async () => {
    if (params.row.isNew) {
      try {
        setSaveRowLoading(true);
        const res = await postProductTypeAttribute(selectedRowData.productTypeId, params.row.productTypeAttributeId, params.row.enabled, params.row.required);
        if (res) {
          const updatedRows = rows.map((row) => {
            if (row.id === params.id) {
              return { ...row, isNew: false, productTypeAttributeId: res.data.ProductTypeAttributeId };
            }
            return row;
          });
          setRows(updatedRows);
          toast.success('Product Type Attribute successfully added');
        }
      } catch (error) {
        console.error('Error adding product type attribute:', error);
      } finally {
        setSaveRowLoading(false);
        setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
      }
    } else {
      try {
        const res = await patchProductTypeAttributes(params.row.productTypeAttributeId, params.row.enabled, params.row.required);
        if (res) {
          toast.success('Product Type Attribute successfully updated');
        }
      } catch (error) {
        console.error('Error updating product type attribute:', error);
      } finally {
        setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
      }
    }
  };

  const handleEditClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => async () => {
    const clickedRow = rows.find((row) => row.id === id);
    setRows(rows.filter((row) => row.id !== id));

    try {
      const { productTypeAttributeId } = clickedRow;
      await deleteProductTypeAttribute(productTypeAttributeId);
      toast.success('Successfully Deleted');
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete product type attribute');
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    let siteAttributes = [];
    const getAllSitesAttributes = async () => {
      try {
        const [response1, response2, response3] = await Promise.all([
          getSiteAttributes(siteId, 2, languageId),
          getSiteAttributes(siteId, 3, languageId),
          getSiteAttributes(siteId, 1, languageId),
        ]);
        siteAttributes = [
          ...response1.data,
          ...response2.data,
          ...response3.data,
        ];
      } catch (err) {
        console.error(err);
      }
    };
    getAllSitesAttributes();
    // const attributesArray = siteAttributes;
    const matchingAttribute = siteAttributes.find(
      (attr) => attr.ProductAttributeDefId === newRow.name,
    );

    const updatedRow = { ...newRow, name: matchingAttribute.AttributeName, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    await postProductTypeAttribute(
      selectedRowData.productTypeId,
      matchingAttribute.ProductAttributeDefId,
      updatedRow.enabled,
      updatedRow.required,
    );

    toast.success('Successfully added product type attribute');
    return updatedRow;
  };

  const handleRowChange = (id, field, value) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
    console.log(state);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      type: 'singleSelect',
      valueOptions: siteAttributeOptionsFormatted,
      renderCell: (params) => {
        const isNewRow = params.row.isNew;
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        return isNewRow && isInEditMode ? (
          <Autocomplete
            id="attribute-autocomplete"
            size="small"
            options={siteAttributeOptions || []}
            getOptionLabel={(option) => (option ? option.AttributeName : '')}
            onChange={(e, value) => {
              handleRowChange(params.id, 'name', value.AttributeName);
              handleRowChange(params.id, 'productTypeAttributeId', value.ProductAttributeDefId);
            }}
            // eslint-disable-next-line no-shadow
            renderInput={(params) => (
              <TextField
                {...params}
                label="Attributes"
                placeholder="Select an attribute"
                id="attribute-textfield"
              />
            )}
            className="w-full"
          />
        ) : (
          <div>
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        // eslint-disable-next-line no-nested-ternary
        return isInEditMode ? (
          <Checkbox
            checked={params.row.enabled}
            onChange={(event) => handleRowChange(params.id, 'enabled', event.target.checked)}
          />
        ) : params.row.enabled ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        );
      },
    },
    {
      field: 'required',
      headerName: 'Required',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        // eslint-disable-next-line no-nested-ternary
        return isInEditMode ? (
          <Checkbox
            checked={params.row.required}
            onChange={(event) => handleRowChange(params.id, 'required', event.target.checked)}
          />
        ) : params.row.required ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      editable: false,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={saveRowLoading ? <CircularProgress color="primary" /> : <SaveIcon />}
              label="Save"
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    const initialState = {
      id: selectedRowData?.id,
      attributeDefKey: selectedRowData?.row?.productAttributeDefKey,
      name: selectedRowData?.row?.eName,
      attributeDataType: selectedRowData?.row?.attributeDataType?.description,
    };
    dispatch({ type: 'INITIAL_STATE', payload: initialState });
    setEditName(selectedRowData?.name || '');
    setEditDepartmentCode(selectedRowData?.departmentCode || '');
    setEditIsDefault(selectedRowData?.default);
    const getAllAttributes = async () => {
      try {
        const [response1, response2, response3] = await Promise.all([
          getSiteAttributes(siteId, 2, languageId),
          getSiteAttributes(siteId, 3, languageId),
          getSiteAttributes(siteId, 1, languageId),
        ]);
        const siteAttributes = [
          ...response1.data,
          ...response2.data,
          ...response3.data,
        ];
        setSiteAttributeOptions(siteAttributes);
      } catch (err) {
        console.error(err);
      }
    };
    getAllAttributes();
  }, [selectedRowData, open]);

  React.useEffect(() => {
    if (searchValue !== '') {
      const filteredRows = originalRows.filter((row) => (row.name.toLowerCase().includes(searchValue.toLowerCase())));
      setRows(filteredRows);
    } else {
      setRows(originalRows);
    }
  }, [searchValue, originalRows]);

  React.useEffect(() => {
    if (searchValue.length > 0) {
      setSearchValue('');
    }
  }, [siteId]);

  React.useEffect(() => {
    const fetchProductData = async () => {
      try {
        // Fetch product attributes based on ProductTypeId
        const productTypesData = await getProductTypesAttribute(selectedRowData.productTypeId);
        const mappedRows = productTypesData.data.map((item, index) => ({
          id: index + 1,
          productTypeAttributeId: item.ProductTypeAttributeId,
          name: item.AttributeName,
          enabled: item.Enabled,
          required: item.Required,
        }));
        setRows(mappedRows);
        setOriginalRows(mappedRows);
      } catch (error) {
        console.error('Error fetching product types attribute data:', error);
      }
    };

    if (open) {
      fetchProductData();
    }
  }, [open, siteId]);

  return (
    <Modal
      open={open}
      title="Edit Product Types & Attributes"
      buttonLabel="Save"
      buttonOnClick={async () => {
        try {
          const updatedSelectedRow = {
            ...selectedRowData,
            name: editName,
            departmentCode: editDepartmentCode,
            default: editIsDefault,
          };

          updatedSelectedRowData(updatedSelectedRow);

          await patchProductType(
            siteId,
            selectedRowData.productTypeId,
            editName,
            editDepartmentCode,
            editIsDefault,
          );

          toast.success('Successfully saved Product Type');
          processRowUpdate();
          handleClose();
        } catch (error) {
          console.error('Error during save:', error);
        }
      }}
      onClose={() => {
        // Set all rows back to View mode before closing the modal
        const resetRowModesModel = {};
        Object.keys(rowModesModel).forEach((id) => {
          resetRowModesModel[id] = { mode: GridRowModes.View };
        });
        setRowModesModel(resetRowModesModel);
        handleClose();
      }}
      desktopModalWidth="70%"
      // desktopModalHeight="100%"
      containerWidth="xl"
    >
      <div className="flex flex-row">
        <Grid container spacing={2} className="max-h-[250px]" justifyContent="end">
          <Grid item xs={6} className="min-w-[80%]">
            <TextField
              label="Name"
              variant="outlined"
              size="small"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              className="w-full mb-8"
            />
            <TextField
              label="Department Code"
              variant="outlined"
              size="small"
              value={editDepartmentCode}
              onChange={(e) => setEditDepartmentCode(e.target.value)}
              className="w-full mb-8"
            />
            <p className="text-[14px]">Is Default</p>
            <Checkbox
              onClick={(event) => setEditIsDefault(event.target.checked)}
              checked={editIsDefault}
              className="mr-0"
            />
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className="mx-10"
        />
        <Grid container className="min-w-[600px]" spacing={2}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <TextField
              label="Search by Product Name Attribute"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              variant="outlined"
              size="small"
              className="mr-6 w-[300px]"
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              columns={columns.map((column) => ({
                ...column,
                // editable: isRowEditable,
              }))}
              rows={rows}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              className="bg-white cursor-pointer min-h-[320px] max-h-[320px]"
              editMode="row"
              rowModesModel={rowModesModel}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={(error) => {
                console.error('Error during row update:', error);
              }}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: {
                  setRows, setRowModesModel, rows,
                },
              }}
              sx={{
                '.MuiDataGrid-cell--withRenderer:nth-child(2), .MuiDataGrid-cell--withRenderer:nth-child(3)': {
                  justifyContent: 'center',
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export default EditModal;
