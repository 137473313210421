import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { toast } from 'react-toastify';

import postDisableMarginThreshold from '../../../api/oc_product/productDataManagement/marginThreshold/post/postDisableMarginThreshold';
import postEnableMarginThreshold from '../../../api/oc_product/productDataManagement/marginThreshold/post/postEnableMarginThreshold';
import postPreCalculation from '../../../api/oc_product/productDataManagement/marginThreshold/post/postPreCalculation';
import postReCalculate from '../../../api/oc_product/productDataManagement/marginThreshold/post/postReCalculate';
import Modal from '../../../components/Modal';

function Row(props) {
  const { row, backgroundColor } = props;
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [productsAffected, setProductsAffected] = React.useState();
  const [loadingMap, setLoadingMap] = React.useState({});
  const [updateProducts, setUpdateProducts] = React.useState('No');
  const [originalThreshold, setOriginalThreshold] = React.useState({});
  const [isChanged, setIsChanged] = React.useState(false);

  const handleSwitchClicked = (val, obj) => {
    setSelectedRow(obj);
    const updatedRows = rows.map((r) => {
      if (r.MarginThresholdConfigId === obj.MarginThresholdConfigId) {
        const updatedRow = {
          ...r,
          EnabledMarginThreshold: !r.EnabledMarginThreshold,
        };
        if (updatedRow.EnabledMarginThreshold === false) {
          updatedRow.MarginThreshold = originalThreshold[obj.MarginThresholdConfigId];
        }
        return updatedRow;
      }
      return r;
    });
    setRows(updatedRows);
  };
  const handleThresholdChange = (val, obj) => {
    setSelectedRow(obj);
    const updatedRows = rows.map((r) => {
      if (r.MarginThresholdConfigId === obj.MarginThresholdConfigId) {
        const updatedRow = {
          ...r,
          MarginThreshold: val === '-' || val === '' ? val : Number(val),
        };
        return updatedRow;
      }
      return r;
    });
    setRows(updatedRows);
  };

  const toggleLoading = (rowId, value) => {
    setLoadingMap((prevState) => ({
      ...prevState,
      [rowId]: value,
    }));
  };

  const handleModalOpen = async (selectedR) => {
    setSelectedRow(selectedR);
    setProductsAffected('Loading...');
    try {
      toggleLoading(selectedR.MarginThresholdConfigId, true);
      postPreCalculation(selectedR.MarginThresholdConfigId, selectedR.MarginThreshold)
        .then((res) => {
          if (res) {
            setProductsAffected(res.data[0].ProductItem);
          }
        });
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading(selectedR.MarginThresholdConfigId, false);
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUpdateProductsChange = (event) => {
    setUpdateProducts(event.target.value);
  };

  const handleModalAction = async () => {
    setLoading(true);
    const convertToBoolean = (value) => {
      const mapping = {
        Yes: true,
        No: false,
        true: true,
        false: false,
      };

      return mapping[value] !== undefined ? mapping[value] : null;
    };

    if (selectedRow.EnabledMarginThreshold) {
      try {
        const reCalculate = await postReCalculate(
          selectedRow.MarginThresholdConfigId,
          selectedRow.MarginThreshold,
          convertToBoolean(updateProducts),
          row.SiteId,
        );

        if (reCalculate) {
          toast.success('Enable/Disabling Affected Products - Process Started', { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err);
        toast.error('Margin threshold disable failed', { autoClose: 2000 });
      } finally {
        setSelectedRow();
        setLoading(false);
        setModalOpen(false);
      }
    } else {
      try {
        const res = await postEnableMarginThreshold(selectedRow.MarginThresholdConfigId);
        const reCalculate = await postReCalculate(
          selectedRow.MarginThresholdConfigId,
          selectedRow.MarginThreshold,
          convertToBoolean(updateProducts),
          row.SiteId,
        );

        if (res && reCalculate) {
          toast.success('Margin threshold enabled', { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err);
        toast.error('Margin threshold enable failed', { autoClose: 2000 });
      } finally {
        setSelectedRow();
        setLoading(false);
        setModalOpen(false);
      }
    }
  };

  const handleEnableDisableAction = async (selectedRowData) => {
    setLoading(true);

    if (selectedRowData.EnabledMarginThreshold) {
      try {
        const res = await postDisableMarginThreshold(selectedRowData.MarginThresholdConfigId);

        if (res) {
          toast.success('Margin threshold disabled', { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err);
        toast.error('Margin threshold disable failed', { autoClose: 2000 });
      } finally {
        setSelectedRow();
        setLoading(false);
        setModalOpen(false);
      }
    } else {
      try {
        const res = await postEnableMarginThreshold(selectedRowData.MarginThresholdConfigId);

        if (res) {
          toast.success('Margin threshold enabled', { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err);
        toast.error('Margin threshold enable failed', { autoClose: 2000 });
      } finally {
        setSelectedRow();
        setLoading(false);
        setModalOpen(false);
      }
    }
  };

  const handleResetThreshold = (obj) => {
    if (selectedRow && obj) {
      const originalThresholdValue = originalThreshold[obj.MarginThresholdConfigId];
      if (originalThresholdValue !== undefined) {
        const updatedRows = rows.map((r) => {
          if (r.MarginThresholdConfigId === obj.MarginThresholdConfigId) {
            const updatedRow = {
              ...r,
              MarginThreshold: originalThresholdValue,
            };
            return updatedRow;
          }
          return r;
        });
        setRows(updatedRows);
      }
    }
    setIsChanged(false);
  };

  const columns = [
    {
      field: 'MarginThresholdConfigId',
      headerName: 'ID',
      flex: 0.3,
    },
    {
      field: 'Currency',
      headerName: 'Currency',
      flex: 0.4,
    },
    {
      field: 'SourceName',
      headerName: 'Source',
      flex: 0.4,
    },
    {
      field: 'SourceLocation',
      headerName: 'Source Location',
      flex: 0.7,
    },
    {
      field: 'MarginFormula',
      headerName: 'Margin',
      flex: 1,
    },
    {
      field: 'VatRate',
      headerName: 'VAT',
      flex: 0.4,
    },
    {
      field: 'MarginThreshold',
      headerName: 'Threshold',
      flex: 0.4,
      renderCell: (params) => (
        <div className="relative">
          <TextField
            label="Threshold"
            id="threshold"
            value={params.value}
            defaultValue={params.value}
            onChange={(e) => {
              const { value } = e.target;
              const regex = /^-?\d*\.?\d*$/;
              if (regex.test(value)) {
                setIsChanged(value !== params.row.MarginThreshold);
                handleThresholdChange(value, params.row);
              }
            }}
            size="small"
            disabled={!params.row.EnabledMarginThreshold}
          />
          {selectedRow?.MarginThresholdConfigId === params.row.MarginThresholdConfigId && isChanged
          && params.row.MarginThreshold !== originalThreshold[params.row.MarginThresholdConfigId] && (
            <CloseIcon
              className="absolute top-2 right-3 cursor-pointer text-error-500"
              onClick={() => handleResetThreshold(params.row)}
            />
          )}
        </div>
      ),
    },
    {
      field: 'EnabledMarginThreshold',
      headerName: 'Enabled',
      flex: 0.4,
      renderCell: (params) => (
        <Switch
          size="small"
          checked={params.value}
          onClick={() => {
            handleSwitchClicked(params.value, params.row);
            handleEnableDisableAction(params.row);
          }}
        />
      ),
    },
    {
      field: 'Update',
      headerName: 'Update',
      flex: 0.4,
      renderCell: (params) => (
        <LoadingButton
          variant="outlined"
          size="small"
          loading={loadingMap[params.row.MarginThresholdConfigId] || false}
          onClick={() => handleModalOpen(params.row)}
          disabled={selectedRow?.MarginThresholdConfigId !== params.row.MarginThresholdConfigId
            || !params.row.EnabledMarginThreshold
            || params.row.MarginThreshold === originalThreshold[params.row.MarginThresholdConfigId]
            || params.row.MarginThreshold === ''}
        >
          Update
        </LoadingButton>
      ),
    },
  ];

  React.useEffect(() => {
    if (row) {
      const initialThresholds = {};
      row.MarginConfigs.forEach((r) => {
        initialThresholds[r.MarginThresholdConfigId] = r.MarginThreshold;
      });
      setOriginalThreshold(initialThresholds);
      setRows(row.MarginConfigs);
    }
  }, [row]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor }}>
        <TableCell
          onClick={() => setOpen(!open)}
          className="w-10 cursor-pointer"
        >
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.SiteName}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(obj) => obj.MarginThresholdConfigId}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        title="Products Affected"
        open={modalOpen}
        onClose={handleModalClose}
        buttonLabel="Confirm"
        buttonOnClick={handleModalAction}
        buttonLoading={loading}
        disableButton={productsAffected === 'Loading...' || productsAffected === undefined || Number.isNaN(productsAffected)}
      >
        <div className="flex flex-col items-center gap-2">
          <Typography>
            Product Count:&nbsp;
            <span style={{ color: productsAffected === 'Loading...' ? '#00b0ff' : 'inherit' }}>
              {productsAffected}
            </span>
          </Typography>
          <Typography>Do you want to update existing products?</Typography>
          <FormControl>
            <Select
              value={updateProducts}
              className="w-32"
              onChange={handleUpdateProductsChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
          <Typography>Yes - will enable/disable affected products</Typography>
          <Typography>No - will only update the threshold value</Typography>
        </div>
      </Modal>
    </>
  );
}

export default Row;
