import postRequest from '../../../../../../../postRequest';

/**
 * Bulk upload product name.
 *
 * @param {number} SourceId Source Id
 * @param {number} SiteId Site Id
 * @param {number} LanguageId Language Id
 * @param {file} File Either a csv or excel file
*/
export default function postProductNamesPluId({
  SourceId,
  SiteId,
  LanguageId,
  File,
}) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/product-names/plu-id/${SourceId}`;

  const body = new FormData();
  body.append('SiteId', SiteId);
  body.append('LanguageId', LanguageId);
  body.append('File', File);

  return new Promise((resolve, reject) => {
    postRequest(
      endpoint,
      body,
      'Product Name (PLU)',
      'STARTED: Product Name',
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
