import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
// import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import Countries from '../../../dummy_data/Countries';

function CountrySelect({
  defaultCountries = [],
  onChange,
  multiple = true,
  disabled = false,
  limitTags = 3,
}) {
  const [countries, setCountries] = React.useState([]);

  useEffect(() => {
    Countries.filter((country) => defaultCountries.includes(country.code)).forEach((country) => {
      setCountries((prevCountries) => [...prevCountries, country]);
    });
  }, [defaultCountries]);

  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limitTags}
      disabled={disabled}
      id="country-select-demo"
      options={Countries}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.name}
          {' '}
          {option.code}
        </Box>
      )}
      value={countries}
      onChange={(event, newValue) => {
        onChange(newValue);
        setCountries(newValue);
      }}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip
          icon={(
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
              alt=""
            />
          )}
          label={option.name}
          {...getTagProps({ index })}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Countries"
          placeholder="Countries"
          variant="outlined"
          // InputProps={multiple === false ? {
          //   ...params.InputProps,
          //   startAdornment: (
          //     <>
          //       {value != null && value !== '' && (
          //         <InputAdornment position="start">
          //           <img
          //             loading="lazy"
          //             width="20"
          //             src={`https://flagcdn.com/w20/${value?.code?.toLowerCase()}.png`}
          //             srcSet={`https://flagcdn.com/w40/${value?.code?.toLowerCase()}.png 2x`}
          //             alt=""
          //           />
          //         </InputAdornment>
          //       )}
          //       {params.InputProps.startAdornment}
          //     </>
          //   ),
          // } : { ...params.InputProps }}
        />
      )}
    />
  );
}

export default CountrySelect;
