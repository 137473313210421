import getRequest from '../../../getRequest';
/**
 * Get all Size Maps.
 * @param {number} siteId
 * @param {number} sizeMappingId
 * @param {string} departmentCode
 * @param {number} brandId
 * @param {string} sizeTo
 * @param {string} sizeFrom
 * @param {number} countryRegionIdFrom
 * @param {string} sizingAttribute
 * @param {string} RegionName
 * @param {string} RegionFromName
 * @param {string} RegionToName
 */
export default async function getSizeMap(
  siteId,
  SizeMappingId,
  DepartmentCode,
  BrandId,
  BrandName,
  CountryRegionIdTo,
  SizeTo,
  CountryRegionIdFrom,
  SizeFrom,
  SizingAttribute,
  Status,
  RegionName,
  RegionFromName,
  RegionToName,
) {
  const endpoint = `/oc-product/v1/size-mapping/${siteId}`;
  const body = {
    SizeMappingId,
    DepartmentCode,
    BrandId,
    BrandName,
    CountryRegionIdTo,
    SizeTo,
    CountryRegionIdFrom,
    SizeFrom,
    SizingAttribute,
    Status,
    RegionName,
    RegionFromName,
    RegionToName,
  };
  return new Promise((resolve, reject) => {
    getRequest(endpoint, body, 'GET')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
