import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import CloseSquare from './CloseSquare';
// import MinusSquare from './MinusSquare';
// import PlusSquare from './PlusSquare';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
  TreeItem,
  treeItemClasses,
  TreeView,
} from '@mui/x-tree-view';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import getAllCategories from '../../../../api/oc_product/product/attributes/categories/gets/getAllCategories';

const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    overflow: 'visible',
    width: '100%',
    [`& .${treeItemClasses.root}`]: {
      overflow: 'visible',
      margin: '.25rem 0',
      borderRadius: '5px',
    },
    [`& .${treeItemClasses.focused}`]: {
      outline: '#a3a3a3 solid 1px',
      borderRadius: '5px',
      background: 'transparent !important',
    },
    [`& .${treeItemClasses.selected}`]: {
      background: 'transparent !important',
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      marginLeft: 16,
      paddingLeft: 20,
    },
  }),
);

const CategoryMenu = forwardRef((props, ref) => {
  const {
    showMenu,
    enableSelect,
    onViewCategory,
    onSelectedValue = () => { },
    disabledCategories = [],
  } = props;

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [loading, setLoading] = useState(true);

  const [buttonAnchor, setButtonAnchor] = useState(null);
  const [addChildAnchor, setAddChildAnchor] = useState(null);

  const [selectedMenuOptionId, setSelectedMenuOptionId] = useState(null);
  const [selectedAddChild, setSelectedAddChild] = useState(null);

  const [newChild, setNewChild] = useState('');

  const handleMenuOpen = (event, id) => {
    event.stopPropagation();
    setSelectedMenuOptionId(id);
    setButtonAnchor(event.currentTarget);
  };
  const handleMenuClose = (event) => {
    event.stopPropagation();
    setSelectedMenuOptionId(null);
    setButtonAnchor(null);
    setAddChildAnchor(null);
    setSelectedAddChild(null);
  };
  const handleOption = (event, name) => {
    event.stopPropagation();
    // TODO : Send disable category request.
    // eslint-disable-next-line no-console
    console.log(name);
  };

  const handleViewCategory = (event, category) => {
    event.stopPropagation();
    onViewCategory(category);
    handleMenuClose(event);
  };
  const handleChildOption = (event, name, id) => {
    event.stopPropagation();
    setAddChildAnchor(event.currentTarget);
    setSelectedAddChild(id);
  };
  const submitNewChild = (event) => {
    event.stopPropagation();
    // TODO : Send add category child request.
    // eslint-disable-next-line no-console
    console.log({
      isActive: true,
      languageId: 1,
      name: newChild,
      parentId: selectedAddChild,
      siteId: 1,
    });
    handleMenuClose(event);
  };

  useImperativeHandle(ref, () => ({
    removeCategory(category) {
      const newSelectedItems = selectedItems.filter(
        (item) => item !== category,
      );
      setSelectedItems(newSelectedItems);
    },
    resetSelectedCategories() {
      setSelectedItems([]);
    },
  }));

  useEffect(() => {
    onSelectedValue(selectedItems);
  }, [selectedItems]);

  const handleSelect = (event, node) => {
    event.preventDefault();
    if (enableSelect) {
      if (!node.SubCategories && !node.ChildCategories) setSelectedItems([...selectedItems, node]);
      if (selectedItems.includes(node)) {
        setSelectedItems(selectedItems.filter((item) => item.Id !== node.Id));
      }
    }
  };

  const labelAndButton = (node) => (
    <>
      <span>{node.Category}</span>
      <Button
        size="small"
        onClick={(event) => handleMenuOpen(event, node.Id)}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id={`tree-menu-${node.Id}`}
        anchorEl={buttonAnchor}
        open={selectedMenuOptionId === node.Id}
        onClose={(event) => handleMenuClose(event)}
      >
        <MenuItem onClick={(event) => handleViewCategory(event, node)}>
          View
        </MenuItem>
        <MenuItem
          onClick={(event) => handleChildOption(event, node.FullCategoryPath, node.Id)}
        >
          Add Child
        </MenuItem>
        <MenuItem onClick={(event) => handleOption(event, 'disable')}>
          Disable
        </MenuItem>
      </Menu>
      <Popover
        id={node.Id}
        open={selectedAddChild === node.Id}
        anchorEl={addChildAnchor}
        onClick={(event) => event.stopPropagation()}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Paper className="flex items-center gap-4 p-4">
          <TextField
            label="Category Name"
            size="small"
            variant="outlined"
            onChange={(e) => setNewChild(e.target.value)}
          />
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={(event) => submitNewChild(event)}
          >
            Submit
          </Button>
        </Paper>
      </Popover>
    </>
  );

  const subCategories = (nodes, callback) => {
    if (Array.isArray(nodes.SubCategories) && !disabledCategories.includes(nodes)) {
      return nodes.SubCategories.map((node) => !disabledCategories.includes(node) && callback(node));
    }
    if (Array.isArray(nodes.ChildCategories) && !disabledCategories.includes(nodes)) {
      return nodes.ChildCategories.map((node) => !disabledCategories.includes(node) && callback(node));
    }
    return null;
  };

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.Id}
      nodeId={nodes.FullCategoryPath}
      label={showMenu ? labelAndButton(nodes) : nodes.Category}
      onClick={(e) => handleSelect(e, nodes)}
      className={selectedItems.includes(nodes) ? 'bg-neutral-200' : ''}
    >
      {subCategories(nodes, renderTree)}
    </StyledTreeItem>
  );

  React.useEffect(() => {
    setLoading(true);
    getAllCategories(siteId)
      .then((res) => {
        if (res) {
          setData(res.data);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [siteId]);

  return (
    <TreeView
      defaultExpanded={['root']}
      // defaultCollapseIcon={<MinusSquare />}
      // defaultExpandIcon={<PlusSquare />}
      // defaultEndIcon={<CloseSquare />}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        overflow: 'visible',
        overflowY: 'auto',
        width: '100%',
        padding: '4px',
      }}
      {...props}
    >
      {loading ? 'Loading...' : data?.map((node) => renderTree(node))}
    </TreeView>
  );
});

export default CategoryMenu;
