import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress from '@mui/material/LinearProgress';
import { default as MuiTable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  mbSticky: {
    position: 'relative',
    '@media only screen and (max-width: 768px)': {
      width: '100px',
      '&:first-child': {
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 10,
      },
    },
  },
}));

function rowTypeChecker(row, column) {
  if (typeof row[column.id] === 'boolean') {
    return row[column.id] ? (
      <CheckCircleIcon className="text-success-600" />
    ) : (
      <CancelIcon className="text-error-600" />
    );
  }

  if (Array.isArray(row[column.id]) && column.id === 'sizes') {
    const values = row[column.id].map((obj) => obj.country_code === column.label && (
      <span key={column.label}>{obj.size}</span>
    ));
    return values;
  }
  if (Array.isArray(row[column.id]) && column.id === 'currency') {
    const values = row[column.id].map((obj) => obj.currency === column.label && (
      <span key={column.label}>{obj.value}</span>
    ));
    return values;
  }
  if (Array.isArray(row[column.id]) && column.id === 'vat_code') {
    const values = row[column.id].map((obj) => obj.country_code === column.label && (
      <span key={column.label}>{obj.is_vatable}</span>
    ));
    return values;
  }

  if (Array.isArray(row[column.id])) {
    return row[column.id].map((value) => (typeof value === 'object' ? (
      <div className="border-b last:border-b-0" key={value}>
        {value}
      </div>
    ) : (
      <div className="border-b last:border-b-0" key={value}>
        <p className="text-sm">{value}</p>
      </div>
    )));
  }

  return row[column.id];
}

/**
 * Customized MUI Table component.
 *
 * @param {array} columnGrouping Title you want to give a group of column
 * @param {array} columns
 * @param {array} rows
 * @param {boolean} loading Loading State
 * @default loading false
 * @param {boolean} fixed "fixed" | "auto" - This will cause the column width to either be fixed
 * or automatically resize it's self to fix its content.
 * @default fixed true
 * @param {boolean} dense Height of the rows
 * @default dense true
 * @param {string} align "left" | "right" | "inherit" | "center" | "justify" - Position of the
 * cell content.
 * @default align center
 * @param {string} maxHeight Max height of the table.
 * @default maxHeight 60vh
 * @param {string} className Class name passed to TableContainer
 * @returns
 */
function Table({
  columnGrouping,
  columns,
  rows,
  className,
  sx,
  loading = false,
  fixed = true,
  dense = true,
  align = 'center',
  maxHeight = '60vh',
}) {
  const styles = useStyles();

  return (
    <TableContainer sx={{ maxHeight }} className={className}>
      <MuiTable
        stickyHeader
        size={dense ? 'small' : 'medium'}
        className={fixed ? 'table-fixed' : 'table-auto'}
        sx={sx}
      >
        <TableHead>
          {/* Column Grouping */}
          {columnGrouping?.length > 0 && (
            <TableRow>
              {columnGrouping.map((obj) => (
                <TableCell key={obj.label} colSpan={obj.span} className="sticky border-0 bold">
                  {obj.label.length > 0 && (
                    <div className="w-full pl-2 border-b bold border-b-neutral-600">
                      {obj.label}
                    </div>
                  )}
                </TableCell>
              ))}
            </TableRow>
          )}
          {/* Normal Column */}
          <TableRow>
            {columns?.map((column) => (
              <TableCell
                key={`${column.id}-${column.label}`}
                align={align}
                className={column.disableBold ? '' : `bold ${styles.mbSticky}`}
                sx={{ backgroundColor: 'white', ...column.styles }}
                size="small"
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
          {/* Loading Bar */}
          {loading && (
            <TableRow>
              <TableCell colSpan={columns?.length} className="p-0">
                <LinearProgress className="w-full" color="inherit" />
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {rows?.length > 0 ? rows.map((row) => (
            <TableRow key={row.id} hover>
              {columns?.map((column, index) => (
                <TableCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${column.id}-${index}`}
                  align={align}
                  className={styles.mbSticky}
                  sx={{ padding: '.5rem 0' }}
                >
                  {rowTypeChecker(row, column)}
                </TableCell>
              ))}
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={columns?.length} align="center">
                <Typography variant="body1">
                  {loading ? 'Loading...' : 'No results found'}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default Table;

Table.propTypes = {
  columnGrouping: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    span: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })),
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disableBold: PropTypes.bool,
    styles: PropTypes.shape({}),
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  fixed: PropTypes.bool,
  dense: PropTypes.bool,
  align: PropTypes.string,
  maxHeight: PropTypes.string,
};

Table.defaultProps = {
  // columns: [],
  // rows: [],
  className: '',
  columnGrouping: '',
  loading: false,
  fixed: true,
  dense: true,
  align: 'center',
  maxHeight: '60vh',
};
