import axios from 'axios';
import { toast } from 'react-toastify';

import getCountries from '../../../api/intergration/pim/countries/gets/getCountries';
import getSites from '../../../api/intergration/pim/sites/gets/getSites';
import httpErrors from '../../../utils/httpErrors';
import brandAndProductName from './brandAndProductName';
import connectedCustomers from './connectedCustomers';
import deleteCategory from './deleteCategory';
import exclusivityFrom from './exclusivityFrom';
import exclusivityTo from './exclusivityTo';
import licensed from './licensed';
import productAttribute from './productAttribute';
import saveCategory from './saveCategory';
import season from './season';
import seasonYear from './seasonYear';
import subbrand from './subbrand';
import vat from './vat';

const existingCountries = async () => {
  const res = await getCountries(1, 100)
    .catch((err) => {
      console.error(err.message);
      toast(`PIM ERROR: Countries - ${err.message}`, {
        type: 'error',
        autoClose: 30000,
        closeButton: true,
        style: {
          wordBreak: 'break-all',
          overflow: 'scroll',
        },
      });
    });

  return res.data.Data;
};

export default async function saveProductData(userEmail, nike_wizard, pim_sites) {
  const pimErrors = new Set();

  // setLoading(true);
  const countriesRes = await existingCountries();
  const sitesRes = await getSites(1, 56)
    .catch((err) => console.error(err));

  const getErrorReason = (error) => {
    const errorToString = error.toString();
    const getNumberRegex = /\d+/g;
    const matches = errorToString.match(getNumberRegex);
    if (matches) {
      const reason = httpErrors(matches[0]);
      return reason || error;
    }
    return error;
  };

  const deleteCategoryMap = nike_wizard.productData.initial_pim_categories
    ?.map((category) => new Promise((resolve, reject) => {
      deleteCategory(userEmail, nike_wizard, category)
        .then((res) => resolve(res))
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: Delete Category - ${reason}`);
          return reject(err);
        });
    }));
  let deleteCategoriesNullCheck = [Promise.resolve({ Success: true, ErrorMessage: null })];
  if (deleteCategoryMap != null && deleteCategoryMap.length > 0) {
    deleteCategoriesNullCheck = deleteCategoryMap;
  }

  const categoryRequests = nike_wizard.productData.categories.pim_category
    .map((category) => new Promise((resolve, reject) => {
      saveCategory(userEmail, nike_wizard, category)
        .then((res) => resolve(res))
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: Save Category - ${reason}`);
          return reject(err);
        });
    }));

  const attributeRequests = nike_wizard.sites[0].countries
    .map((country) => new Promise((resolve, reject) => {
      productAttribute(userEmail, nike_wizard, sitesRes, country)
        .then((res) => resolve(res))
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: Colour/Fabric/Description - ${reason}`);
          return reject(err);
        });
    }));

  const subbrandProm = new Promise((resolve, reject) => {
    if (nike_wizard.editedValues.includes('subbrand')) {
      subbrand(userEmail, nike_wizard)
        .then(() => resolve())
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: Subbrand - ${reason}`);
          reject(err);
        });
    } else {
      resolve();
    }
  });

  const seasonProm = new Promise((resolve, reject) => {
    if (nike_wizard.editedValues.includes('season')) {
      season(userEmail, nike_wizard)
        .then(() => resolve())
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: Season - ${reason}`);
          reject(err);
        });
    } else {
      resolve();
    }
  });

  const seasonYearProm = new Promise((resolve, reject) => {
    if (nike_wizard.editedValues.includes('seasonYear')) {
      seasonYear(userEmail, nike_wizard)
        .then(() => resolve())
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: SeasonYear - ${reason}`);
          reject(err);
        });
    } else {
      resolve();
    }
  });
  const licensedProm = new Promise((resolve, reject) => {
    // if (nike_wizard.editedValues.includes('licensed')) {
    licensed(userEmail, nike_wizard)
      .then(() => resolve())
      .catch((err) => {
        const reason = getErrorReason(err);
        pimErrors.add(`PIM ERROR: licensed - ${reason}`);
        reject(err);
      });
    // } else {
    //   resolve();
    // }
  });

  const connectedCustomersProm = new Promise((resolve, reject) => {
    // if (nike_wizard.editedValues.includes('connectedCustomers')) {
    connectedCustomers(userEmail, nike_wizard)
      .then(() => resolve())
      .catch((err) => {
        const reason = getErrorReason(err);
        pimErrors.add(`PIM ERROR: ConnectedCustomers - ${reason}`);
        reject(err);
      });
    // } else {
    //   resolve();
    // }
  });

  const exclusivityFromProm = new Promise((resolve, reject) => {
    if (nike_wizard.editedValues.includes('from')) {
      exclusivityFrom(userEmail, nike_wizard)
        .then(() => resolve())
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: ExclusivityDataFrom - ${reason}`);
          reject(err);
        });
    } else {
      resolve();
    }
  });

  const exclusivityToProm = new Promise((resolve, reject) => {
    if (nike_wizard.editedValues.includes('to')) {
      exclusivityTo(userEmail, nike_wizard)
        .then(() => resolve())
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: ExclusivityDataTo - ${reason}`);
          reject(err);
        });
    } else {
      resolve();
    }
  });

  const brandRequest = nike_wizard.sites[0].countries
    .map((country) => new Promise((resolve, reject) => {
      brandAndProductName(userEmail, nike_wizard, country, sitesRes, countriesRes)
        .then((res) => resolve(res))
        .catch((err) => {
          const reason = getErrorReason(err);
          pimErrors.add(`PIM ERROR: Brand/Name - ${reason}`);
          return reject(err);
        });
    }));

  const vatProm = new Promise((resolve, reject) => {
    vat(userEmail, nike_wizard, pim_sites)
      .then(() => resolve())
      .catch((err) => {
        const reason = getErrorReason(err);
        pimErrors.add(`PIM ERROR: VAT - ${reason}`);
        reject(err);
      });
  });

  await Promise.all([
    ...attributeRequests,
    ...brandRequest,
    ...deleteCategoriesNullCheck,
    ...categoryRequests,
    connectedCustomersProm,
    exclusivityFromProm,
    exclusivityToProm,
    seasonProm,
    licensedProm,
    seasonYearProm,
    subbrandProm,
    vatProm,
  ])
    .then(() => {
      toast.success('Product data has been saved.');
      // setLoading(false);
      // setActiveStep(activeStep + 1);
      // setOpenModal(false);
      // setPimErrors(new Set());
      return Promise.resolve();
    })
    .catch((err) => {
      // If the error message contains the word Cancel then dont do error stuff.
      // Reason for this is because if a request is cancelled, this isn't an error to do with
      // saving data so the user doesn't need to be notified of this.
      if (axios.isCancel(err) === false) {
        console.error(err);
        toast(`PIM ERROR: ${err}`, {
          type: 'error',
          autoClose: 30000,
          closeButton: true,
          style: {
            wordBreak: 'break-all',
            overflow: 'scroll',
          },
        });
        return Promise.reject(pimErrors);
      }
      return Promise.resolve();
    });
}
