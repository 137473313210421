import isEmpty from '../../utils/isEmpty';

function NextButtonDisabled(state, pageName) {
  const {
    productId,
    productPlu,
    sites,
    productData,
    translations,
  } = state;
  switch (pageName) {
    case 'FindProduct': {
      const reasons = new Set();
      const isProductIdEmpty = isEmpty(productId);
      if (isProductIdEmpty) {
        reasons.add('Empty Product ID');
      }
      if (!isProductIdEmpty) {
        reasons.delete('Empty Product ID');
      }
      const isProductPluEmpty = isEmpty(productPlu);
      if (isProductPluEmpty) {
        reasons.add('Empty Product PLU');
      }
      if (!isProductPluEmpty) {
        reasons.delete('Empty Product PLU');
      }
      const isSitesEmpty = isEmpty(sites) || isEmpty(sites[0]?.countries);
      if (isSitesEmpty) {
        reasons.add('Empty Sites');
      }
      if (!isSitesEmpty) {
        reasons.delete('Empty Sites');
      }
      const res = {
        disabled: (isProductIdEmpty || isProductPluEmpty || isSitesEmpty),
        reasons,
      };
      return res;
    }
    case 'WhereToSell': {
      const reasons = new Set();
      const areSiteFieldsEmpty = sites.filter((site) => {
        const enteries = Object.entries(site);

        const filteredEntries = enteries.filter(([, value]) => {
          if (typeof value === 'object') {
            return Object.keys(value)?.length <= 0;
          }
          return value?.length <= 0;
        });
        const fasciaAndCountrysFilled = filteredEntries?.length > 0;

        return fasciaAndCountrysFilled;
      })?.length > 0;
      if (areSiteFieldsEmpty) {
        reasons.add('Empty Sites');
      }
      if (!areSiteFieldsEmpty) {
        reasons.delete('Empty Sites');
      }
      const res = {
        disabled: areSiteFieldsEmpty,
        reasons,
      };
      return res;
    }
    case 'ProductData': {
      const {
        activeStep,
        brand,
        product_name,
        product_description,
        colours,
        fabric,
        categories,
        other_attributes,
      } = productData;

      const reasons = new Set();

      const onLastStep = (activeStep !== Object.keys(productData).length - 2)
        === (activeStep !== Object.keys(productData).length - 3);
      if (onLastStep) {
        reasons.add('Not Confirmed Last Step');
      }
      if (!onLastStep) {
        reasons.delete('Not Confirmed Last Step');
      }

      const isBrandEmpty = isEmpty(brand.brand_name.name);
      if (isBrandEmpty) {
        reasons.add('Empty Brand');
      }
      if (!isBrandEmpty) {
        reasons.delete('Empty Brand');
      }

      const isProductNameEmpty = isEmpty(product_name.name);
      if (isProductNameEmpty) {
        reasons.add('Empty Product Name Or Translations');
      }
      if (!isProductNameEmpty) {
        reasons.delete('Empty Product Name Or Translations');
      }

      const isProductDescriptionEmpty = isEmpty(product_description.description);
      if (isProductDescriptionEmpty) {
        reasons.add('Empty Product Description Or Translations');
      }
      if (!isProductDescriptionEmpty) {
        reasons.delete('Empty Product Description Or Translations');
      }

      const isColorsEmpty = isEmpty(colours.primary_colour);
      if (isColorsEmpty) {
        reasons.add('Empty Primary Colours');
      }
      if (!isColorsEmpty) {
        reasons.delete('Empty Primary Colours');
      }

      const isFabricEmpty = isEmpty(fabric.fabric_type);
      if (isFabricEmpty) {
        reasons.add('Empty Fabric Type Or Translations');
      }
      if (!isFabricEmpty) {
        reasons.delete('Empty Fabric Type Or Translations');
      }

      const isCategoriesEmpty = isEmpty(categories.pim_category)
        || isEmpty(categories.shogun_category)
        || isEmpty(categories.shogun_buyer_number);
      if (isCategoriesEmpty) {
        reasons.add('Empty Categories');
      }
      if (!isCategoriesEmpty) {
        reasons.delete('Empty Categories');
      }

      // const isVatEmpty = vat.map(({ vat_code }) => vat_code
      //   .filter(({ is_vatable }) => is_vatable === false)?.length > 0).includes(true);

      const isEtcEmpty = Object.entries(other_attributes).map(([key, value]) => {
        if (key === 'from' || key === 'to') {
          if (other_attributes.connected_customers === 1) {
            return isEmpty(value.toString());
          }
          return false;
        }
        if (key === 'connected_customers' || key === 'licensed') {
          return false;
        }
        return isEmpty(value);
      }).includes(true);
      if (isEtcEmpty) {
        reasons.add('Empty Other Attributes');
      }
      if (!isEtcEmpty) {
        reasons.delete('Empty Other Attributes');
      }

      const checkFinal = {
        disabled: onLastStep
          || isBrandEmpty
          || isProductNameEmpty
          || isProductDescriptionEmpty
          || isColorsEmpty
          || isFabricEmpty
          || isCategoriesEmpty
          || isEtcEmpty,
        reasons,
      };

      return checkFinal;
    }
    case 'Translations': {
      const isTranslationsEmpty = translations
        .filter((translation) => translation.product_name?.length <= 0)?.length > 0;
      return isTranslationsEmpty;
    }
    case 'Summary':
      return { disabled: false, reasons: [] };
    case 'SellingPrice':
      return { disabled: false, reasons: [] };
    case 'EnableFulfilment':
      return { disabled: false, reasons: [] };
    default:
      return { disabled: true, reasons: ['Error May Have Accured. Resorted To Default'] };
  }
}

export default NextButtonDisabled;
