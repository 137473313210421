import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import deleteBrandGroup from '../../../../../api/oc_product/brandGroup/delete/deleteBrandGroup';
import getBrandGroupBrands from '../../../../../api/oc_product/brandGroup/get/getBrandGroupBrands';
import patchAddRemoveBrands from '../../../../../api/oc_product/brandGroup/patch/patchAddRemoveBrands';
import Modal from '../../../../../components/Modal';
import { BrandGroupingContext } from '../BrandGroupingContext';

function BrandGroupCard({ group }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const { brands, setFetchData, setReFetchLogs } = useContext(BrandGroupingContext);

  const [editGroup, setEditGroup] = React.useState(false);
  const [originalBrands, setOriginalBrands] = React.useState([]);
  const [selectedBrands, setSelectedBrands] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (brands) {
      getBrandGroupBrands(siteId, group.BrandGroupId)
        .then((res) => {
          const filteredBrands = brands?.filter((brand) => res.data.some((selectedBrand) => selectedBrand.BrandId === brand.BrandId));
          setSelectedBrands(filteredBrands);
          setOriginalBrands(filteredBrands);
        })
        .catch((err) => console.error(err));
    }
  }, [group, brands]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleDeleteBrandGroup = async () => {
    try {
      setLoading(true);
      await deleteBrandGroup(siteId, group.BrandGroupId);
      setFetchData(true);
      setReFetchLogs(true);
      toast.success(`Brand group ${group.BrandGroupName} successfully deleted`);
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete brand group');
    } finally {
      setOpenModal(false);
    }
  };

  const handleUpdate = async () => {
    const removed = originalBrands.filter((item) => !selectedBrands.some((sel) => sel.BrandId === item.BrandId));
    const inserted = selectedBrands.filter((item) => !originalBrands.some((orig) => orig.BrandId === item.BrandId));

    const body = {
      brandGroupInfo: {
        removed: removed.map((brand) => brand.BrandId),
        inserted: inserted.map((brand) => brand.BrandId),
      },
    };

    try {
      await patchAddRemoveBrands(siteId, group.BrandGroupId, body);
      setFetchData(true);
      setReFetchLogs(true);
      toast.success(`Brand group ${group.BrandGroupName} successfully updated`);
    } catch (error) {
      console.error(error);
      toast.error('Failed to update brand group');
    } finally {
      setEditGroup(false);
    }
  };

  const handleCancel = () => {
    setEditGroup(false);
    getBrandGroupBrands(siteId, group.BrandGroupId)
      .then((res) => {
        const filteredBrands = brands?.filter((brand) => res.data.some((selectedBrand) => selectedBrand.BrandId === brand.BrandId));
        setSelectedBrands(filteredBrands);
      })
      .catch((err) => console.error(err));
  };

  const handleReset = async () => {
    getBrandGroupBrands(siteId, group.BrandGroupId)
      .then((res) => {
        const filteredBrands = brands?.filter((brand) => res.data.some((selectedBrand) => selectedBrand.BrandId === brand.BrandId));
        setSelectedBrands(filteredBrands);
      })
      .catch((err) => console.error(err));
  };

  const customRenderOption = (props, option) => (
    <li {...props} key={option.brandId}>
      <div>{option.Name}</div>
    </li>
  );

  return (
    <Card className="w-[270px] h-[260px] p-6 relative flex flex-col">
      <Typography variant="h6" className="font-gothammedium">
        {group.BrandGroupName}
      </Typography>
      <div className="flex-grow overflow-hidden">
        {/* <Tooltip
          title={selectedBrands.map((brand) => (
            <p className="text-sm" key={brand}>
              {brand}
            </p>
          ))}
        > */}
        <div className="pt-4 mr-4 h-[150px] overflow-y-auto">
          <Autocomplete
            multiple
            id="brands"
            readOnly={!editGroup}
            size="small"
            value={selectedBrands}
            onChange={(e, value) => setSelectedBrands(value)}
            options={brands || []}
            getOptionLabel={(brand) => (brand ? brand.Name : '')}
            limitTags={4}
            renderInput={(params) => <TextField {...params} label="Brand" />}
            renderOption={customRenderOption}
          />
        </div>
        {/* </Tooltip> */}
      </div>
      <div className="flex mt-2">
        {editGroup ? (
          <>
            <Tooltip
              title="Save changes"
            >
              <SaveIcon
                sx={{
                  cursor: 'pointer',
                  opacity: '0.5',
                  '&:hover': {
                    color: 'green',
                  },
                }}
                onClick={handleUpdate}
              />
            </Tooltip>
            <Tooltip
              title="Reset changes"
            >
              <RestartAltIcon
                sx={{
                  cursor: 'pointer',
                  opacity: '0.5',
                  '&:hover': {
                    color: 'blue',
                  },
                }}
                onClick={handleReset}
              />
            </Tooltip>
            <Tooltip
              title="Cancel update"
            >
              <ClearIcon
                sx={{
                  cursor: 'pointer',
                  opacity: '0.5',
                  '&:hover': {
                    color: 'red',
                  },
                }}
                onClick={handleCancel}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip
              title="Edit"
            >
              <EditIcon
                sx={{
                  cursor: 'pointer',
                  opacity: '0.5',
                  '&:hover': {
                    color: 'green',
                  },
                }}
                onClick={() => setEditGroup(true)}
              />
            </Tooltip>
            <Tooltip
              title="Delete"
            >
              <DeleteForeverIcon
                sx={{
                  cursor: 'pointer',
                  opacity: '0.5',
                  '&:hover': {
                    color: 'red',
                  },
                }}
                onClick={() => setOpenModal(true)}
              />
            </Tooltip>
          </>
        )}
      </div>
      <Modal
        title={`Delete ${group.BrandGroupName} brand group`}
        open={openModal}
        onClose={handleModalClose}
        buttonLabel="Confirm"
        buttonOnClick={handleDeleteBrandGroup}
      >
        {loading ? (
          <div className="flex items-center justify-center h-16">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Typography>{`Are you sure you want to delete the ${group.BrandGroupName} brand group`}</Typography>
        )}
      </Modal>
    </Card>
  );
}

export default BrandGroupCard;
