/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20240509() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S7/E14 - NEW PAGES</h2>
      <p className="-mt-4">09th May 2024</p>

      <h4>
        Notice
      </h4>

      <p><strong>Central PIM will be deprecated 24th may 2024 . Functionality will be migrated to Prism</strong></p>

      <p><strong>Date was agreed with Merch team managers and remaining functionality is being migrated over</strong></p>

      <h4>Indexing</h4>

      <ul className="-mt-4">
        <li>Allow admin users to run indexes within Prism, refreshing the products in the PLP</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Improvements</h3>

      <h4>Mapping</h4>

      <ul className="-mt-4">
        <li>Add Department Code search filter</li>
        <li>UI resizing and improvements</li>
      </ul>

      <h4>Attribute Definition</h4>

      <ul className="-mt-4">
        <li>UI Improvements, allowing users to see full value names when adding / editing attributes</li>
      </ul>

      <h4>Bulk Operations</h4>

      <ul className="-mt-4">
        <li>Pre-release date bulk operations UI Upload option for uploading PLU without using a file</li>
      </ul>

      <h4>General</h4>
      <ul className="-mt-4">
        <li>Side Bar UI Improvements</li>
        <ul>
          <li>
            Added Settings Page
            <ul>
              <li>Users, Indexing & release notes now site in this page</li>
            </ul>
          </li>
        </ul>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bug fixes</h3>

      <ul className="-mt-4">
        <li>(Fixed) - Bulk Operations : All transitions are failed with location not enabled error.</li>
        <li>(Fixed) - Bulk Operations : Dropdowns for Campaign & Collection, resetting after 10 seconds</li>
        <li>(Fixed) - Bulk Operations : Pre Release Dates- The more info button is redirecting to a blank page.</li>
        <li>Bulk Operations : More info button redirecting to Blank page while navigating across transactions</li>
        <li>(Fixed) - (Attributes Def) - Can not save values after switching to non-local language</li>
      </ul>

      <h4>MCTS</h4>
      <ul className="-mt-4">
        <li>HIP : Product description is being pulled from FP UK GUI rather than using the description in Prism.</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Upcoming</h3>
      <ul className="-mt-4">
        <li>Data Management - Pricing Page</li>
        <li>Release Dates UI Upload functionality</li>
        <li>Margin Threshold</li>
        <li>Refreshed Bulk Upload Logs</li>
        <li>Add images to Enable Stock / Approve</li>
        <li>Attributes and Categories UI Changes</li>
      </ul>
    </section>
  );
}

export default Release20240509;
