import postRequest from '../../../postRequest';

/**
 * Post request for style name.
 *
 * @param {number} siteId
 * @param {number} languageId Page size
 *
 */
function postStyleName(
  page,
  size,
  {
    siteId,
    name,
    languageId,
  },
) {
  const pageQuery = page ? `?Page=${page}` : '';
  const sizeQuery = size ? `&Size=${size}` : '';
  const endpoint = `/oc-data-management/v1/styles/style-name${pageQuery}${sizeQuery}`;

  const body = {
    siteId,
    name,
    languageId,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Styles')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postStyleName;
