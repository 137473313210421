import getRequest from '../../../../../getRequest';

/**
 * Get the Queue Process Statuses mapping for bulk operations.
 *
 * @returns Types mapping
 */
export default function getQueueProcessStatuses() {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/reports/queue-process-statuses';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Queue Process Statuses')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
