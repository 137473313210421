import patchRequest from '../../../../../patchRequest';

/**
 * Assign/Unassign category as a primary.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {array} productTypeIds Ids of the product categories
 * @returns {AxiosResponse}
 * @example
 * {
 *  "Success": true,
 *  "SiteCode": "JD",
 *  "SiteId": 1,
 *  "ProductId": 444
 *  "ProductTypeIds: [720]"
 * }
 */

export default function patchProductTypes(productId, siteId, productTypeIds) {
  const productTypeIdsQueryParam = productTypeIds.join('&productTypeIds=');

  const endpoint = productTypeIds.length > 0
    ? `/oc-product/v1/product/${productId}/${siteId}/Product-Types?productTypeIds=${productTypeIdsQueryParam}`
    : `/oc-product/v1/product/${productId}/${siteId}/Product-Types`;

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, 'Add/Remove Product Types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
