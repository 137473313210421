import axios from 'axios';

import patchVariantsVATCode from '../../../api/intergration/pim/products/dropshipment/patches/patchVariantsVATCode';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function vat(userEmail, nike_wizard, pim_sites) {
  return new Promise((resolve, reject) => {
    const vatObjToRequestFormat = nike_wizard.productData.vat.map((obj) => ({
      sku: obj.variant,
      vatCode: obj.vat_code.map((vatCode) => {
        const site = pim_sites.find((pimSite) => pimSite.CountryCode === vatCode.country_code
          && pimSite.Fascia === 'JD');
        return {
          isVatable: vatCode.is_vatable,
          siteCode: site.SiteCode,
        };
      }),
    }));
    patchVariantsVATCode(vatObjToRequestFormat)
      .then((res) => {
        if (res.data.Success) {
          vatObjToRequestFormat.forEach((obj) => {
            obj.vatCode.forEach((vatObj) => {
              if (nike_wizard.editedValues.includes(`vat-${obj.sku}`)) {
                postAddProductLog(
                  nike_wizard.productId,
                  'JD',
                  `[INFO]: User ${userEmail} updated SiteCode: ${vatObj.siteCode} - IsVatable: ${vatObj.isVatable} for SKU: ${obj.sku} for for ProductId: ${nike_wizard.productId} - SiteCode: JD`,
                );
              }
            });
          });
          resolve(res.data);
        } else {
          postAddProductLog(
            nike_wizard.productId,
            'JD',
            `[ERROR]: User ${userEmail} FAILED to update VAT for for ProductId: ${nike_wizard.productId} - SiteCode: JD. Returned Error Message: ${res.data.ErrorMessage}`,
          );
          reject(res.data.ErrorMessage);
        }
      })
      .catch((err) => {
        // If the error message contains the word Cancel then dont do error stuff.
        // Reason for this is because if a request is cancelled, this isn't an error to do with
        // saving data so the user doesn't need to be notified of this.
        if (axios.isCancel(err)) {
          resolve();
        } else {
          postAddProductLog(
            nike_wizard.productId,
            'JD',
            `[ERROR]: User ${userEmail} FAILED to update VAT for for ProductId: ${nike_wizard.productId} - SiteCode: JD. Returned Error Message: ${err.message}`,
          );
          reject(err);
        }
      });
  });
}

export default vat;
