import deleteRequest from '../../../../../../deleteRequest';

/**
 * Bulk upload a single line attribute.
 *
 * @param {number} SiteId Site Id
 * @param {number} AttributeDefKeyId Attribute Id
 * @param {number} LanguageId Language Id
 * @param {file} File Either a csv or excel file
*/
export default function deleteSingleLineAttributesProductId({
  SiteId,
  AttributeDefKeyId,
  LanguageId,
  File,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/single-line-attributes/product-id';

  const body = new FormData();
  body.append('SiteId', SiteId);
  body.append('AttributeDefKeyId', AttributeDefKeyId);
  body.append('LanguageId', LanguageId);
  body.append('File', File);

  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  const errorMessageHeader = `Failed to DELETE - DefId: ${AttributeDefKeyId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(
      endpoint,
      body,
      errorMessageHeader,
      'Started : Single Line Attributes : Prod ID (Delete)',
      headers,

    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
