import Typography from '@mui/material/Typography';
import React from 'react';

import Modal from '../../../../Modal';
import Table from '../../../../Table';

function ErrorModal({
  errorModal,
  modalRows,
  cancelClicked,
  productId,
  productPlu,
  loading,
  requestError,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    cancelClicked(true);
    setOpen(false);
  };

  React.useEffect(() => setOpen(errorModal), [errorModal]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${productPlu}/${productId}`}
      containerWidth="sm"
      mobileModalPadding="2rem 1rem"
      mobileModalWidth="90%"
    >
      {requestError?.length > 0 ?? (
      <Typography variant="h6" gutterBottom>
        {requestError}
      </Typography>
      )}
      <Table
        columns={[
          { id: 'ErrorCode', label: 'Error Code' },
          { id: 'ErrorMessage', label: 'Message' },
          { id: 'Upc', label: 'Barcode' },
          { id: 'SkuReference', label: 'Sku Reference' },
        ]}
        rows={modalRows.map((obj) => (obj.Upc == null ? { ...obj, Upc: 'No Barcode' } : obj))}
        loading={loading}
        className="w-full border rounded-lg"
      />
    </Modal>
  );
}

export default ErrorModal;
