import getRequest from '../../../../getRequest';

/**
 * Calls countries endpoint that gets existing countries in PIM DB.
 *
 * @param {number} page - 1
 * @param {number} size - 15
 * @returns {Promise}
 * @example
 * {
 *  data: {
 *    "Pagination": {
        "Page": 1,
        "PerPage": 100,
        "PageCount": 1,
        "TotalCount": 33,
        "EnablePrevious": false,
        "EnableNext": false
      },
      "Data": [
        {
          "CountryId": 269,
          "CountryCode": "AQ",
          "Description": "Antarctica",
          "Iso": "ATA",
          "Region": "EU",
          "Currency": "AUD",
          "LanguageId": 30
        },
      ],
      "Summary": { "TotalRowCount": 33 }
 *  },
 *  status: 200
 * }
 */
async function getCountries(page, size) {
  const queries = `Page=${page}&Size=${size}`;
  const endpoint = `/integration-pim/v1/countries?${queries}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Getting Countries')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getCountries;
