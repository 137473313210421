import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import deleteCategory from '../../../../api/oc_product/productDataManagement/category/delete/deleteCategory';
import getDisabledCategories from '../../../../api/oc_product/productDataManagement/category/get/getDisabledCategories';
import { CategoryContext } from '../CategoryContext';

function DisabledCategories() {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const userName = useSelector((state) => state.user.userProfile.sub);

  const { setReFetchLogs } = useContext(CategoryContext);

  const [disabledCategories, setDisabledCategories] = useState([]);
  const [disabledCategoriesLoading, setDisabledCategoriesLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedListItemIndex, setSelectedListItemIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchData = async () => {
    try {
      setDisabledCategoriesLoading(true);
      const res = await getDisabledCategories(siteId);
      if (res) {
        setDisabledCategories(res.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDisabledCategoriesLoading(false);
    }
  };

  const handlePopoverOpen = (event, index, category) => {
    setSelectedCategory(category);
    setAnchorEl(event.currentTarget);
    setSelectedListItemIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedListItemIndex(null);
  };

  const handleDelete = async () => {
    try {
      setDisabledCategoriesLoading(true);
      const res = await deleteCategory(selectedCategory.CategoryId, userName);
      if (res.data) {
        fetchData();
        toast.success('Category successfully deleted');
      }
    } catch (err) {
      console.error(err);
    } finally {
      handlePopoverClose();
      setDisabledCategoriesLoading(false);
      setReFetchLogs(true);
    }
  };

  useEffect(() => {
    if (siteId) {
      fetchData();
    }
  }, [siteId]);

  if (disabledCategoriesLoading) {
    return <p>Loading...</p>;
  }

  if (disabledCategories.length === 0) {
    return <p>No disabled categories</p>;
  }

  return (
    <Card variant="outlined" className="w-full h-[800px] overflow-auto mr-5 p-5">
      <List>
        {disabledCategories.map((category, index) => (
          <ListItem key={category.CategoryId} sx={{ display: 'flex', justifyContent: 'flex-start', padding: '0' }}>
            <div>{category.CategoryName}</div>
            <div>
              <IconButton
                aria-label="delete"
                onClick={(event) => handlePopoverOpen(event, index, category)}
              >
                <DeleteIcon />
              </IconButton>
              <Popover
                id={category?.Id}
                open={selectedListItemIndex === index}
                anchorEl={anchorEl}
                onClick={(event) => event.stopPropagation()}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                <Paper className="flex flex-col items-start gap-4 p-4 w-72" elevation={0}>
                  <Typography variant="body1">Are you sure you want to delete this category?</Typography>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </Paper>
              </Popover>
            </div>
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

export default DisabledCategories;
