import deleteRequest from '../../../../deleteRequest';

/**
 * Deletes product type attribute.
 *
 * @param {number} productTypeAttributeId
 * @param {string} userName
 *
 * @returns {Promise} { Success: true }, status: 200 }
 */
async function deleteProductTypeAttribute(productTypeAttributeId) {
  const endpoint = `/oc-data-management/v1/product-type/attributes/${productTypeAttributeId}`;

  const errorMessageHeader = `Delete ${productTypeAttributeId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteProductTypeAttribute;
