import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataCategories,
  setProductDataInitialPimCategories,
} from '../../../../../redux/wizard/nike/nike_wizard';
import VirtualizedIntergrationCategoryAutocomplete from '../../../../VirtualizedIntergrationCategoryAutocomplete';

function Categories() {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const { activeStep } = nike_wizard.productData;
  const [categories, setCategories] = React.useState({
    pim_category: [], // dropdown of all categories from api
    shogun_category: '', // helper text 2 char
    shogun_subcategory: '00',
    shogun_buyer_number: 0,
    shogun_division_number: '1',
  });

  React.useEffect(() => {
    const catIds = nike_wizard.productData.categories.pim_category
      .map((x) => ({ id: x.PimCategoryId }));
    dispatchToRedux(setProductDataInitialPimCategories(catIds));
    setCategories(nike_wizard.productData.categories);
  }, []);

  const handleSelect = (selected) => {
    if (selected.length > 0) {
      setCategories({
        ...categories,
        pim_category: selected,
        shogun_category: selected[0].ShogunCategory == null ? '' : selected[0].ShogunCategory,
        shogun_buyer_number: selected[0].Buyer == null ? '' : selected[0].Buyer,
      });
    }

    if (selected.length === 0) {
      setCategories({
        pim_category: selected,
        shogun_category: '',
        shogun_subcategory: '00',
        shogun_buyer_number: 0,
        shogun_division_number: '1',
      });
    }
  };

  return (
    <section>
      <Typography
        variant="h5"
        className="border-b font-gothammedium text-neutral-800 border-neutral-300"
        gutterBottom
      >
        Categories
      </Typography>
      <Typography variant="body1" className="text-neutral-800" marginBottom={4}>
        If PIM Category dropdown is showing blank chip, please delete and add the relevent category
        if none are already pre-populated.
      </Typography>
      <Stack spacing={3}>

        <VirtualizedIntergrationCategoryAutocomplete
          multiple
          value={categories.pim_category}
          onChange={(event, value) => handleSelect(value)}
          label="PIM Categories"
          hideAllOption
        />

        <Stack direction="row" spacing={2}>
          <TextField
            label="Shogun Category"
            fullWidth
            onChange={(e) => setCategories({ ...categories, shogun_category: e.target.value })}
            value={categories.shogun_category}
            inputProps={{ maxLength: 2 }}
            required
            error={categories.shogun_category?.length <= 1 || categories.shogun_category == null}
            disabled
          />
          <TextField
            label="Shogun Sub-Category"
            disabled
            fullWidth
            className="cursor-not-allowed"
            value={categories.shogun_subcategory}
          />
        </Stack>

        <Stack direction="row" gap={2} alignItems="center">
          <TextField
            label="Shogun Buyer Number"
            fullWidth
            onChange={(e) => {
              setCategories({ ...categories, shogun_buyer_number: e.target.value });
            }}
            value={categories.shogun_buyer_number}
            required
            error={categories.shogun_buyer_number?.length <= 0}
            disabled
          />
          <TextField
            label="Shogun Division Number"
            disabled
            fullWidth
            className="cursor-not-allowed"
            value={categories.shogun_division_number}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} className="float-right my-4">
        <Button
          variant="text"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep - 1));
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep + 1));
            dispatchToRedux(setProductDataCategories(categories));
            if (nike_wizard.productData.categories.pim_category !== categories.pim_category) {
              dispatchToRedux(setEditedValues('categories'));
            }
          }}
        >
          Confirm
        </Button>
      </Stack>
    </section>
  );
}

export default Categories;
