import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  columnVisibilityModel: {
    skus: false,
    eans: false,
    lastIndexed: false,
    lastIndexedBy: false,
  },
};

const columnsSlice = createSlice({
  initialState,
  name: 'columns',
  reducers: {
    setColumnsRedux: (state, action) => {
      state.columnVisibilityModel = action.payload;
    },
  },
});

export const { setColumnsRedux } = columnsSlice.actions;

export default columnsSlice.reducer;
