import getRequest from '../../../../getRequest';

/**
 * Calls sites endpoint that gets existing sites in PIM DB.
 *
 * @param {number} page - 1
 * @param {number} size - 15
 * @returns {Promise} { data: {}, status: 200 }
 */
async function getSites(page, size) {
  const queries = `Page=${page}&Size=${size}`;
  const endpoint = `/integration-pim/v1/sites?${queries}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Sites')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getSites;
