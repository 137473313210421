import getRequest from '../../../../getRequest';

/**
 * Calls logs endpoint that gets information on previously submitted products.
 *
 * @param {string} logId - Usually ShogunTransferMongoLogId, which is Mongo ObjectId.
 * @returns {Promise} { data: {}, status: 200 }
 */
async function getLogs(logId) {
  const endpoint = `/integration-shogun/v1/error-log/${logId}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Logs')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getLogs;
