const AttributeDefinitions = [
  {
    ProductAttributeDefId: 20200,
    ProductAttributeDefKey: 'strange_test',
    Name: 'strange_test_eng',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-02-06T16:06:12.917',
    Created: '2024-02-06T16:06:12.917',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20165,
    ProductAttributeDefKey: 'creation_test_glen2',
    Name: 'creation_test_glen2',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-02-06T15:28:29.973',
    Created: '2024-01-08T16:12:17.203',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20158,
    ProductAttributeDefKey: 'def_key_glen_test',
    Name: 'DEFINITION NAME',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: '',
    Updated: '2024-01-24T15:01:36.147',
    Created: '2024-01-05T08:54:31.35',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20196,
    ProductAttributeDefKey: 'glen_global_french',
    Name: 'glen_global_fr',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: true,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-23T14:33:43.997',
    Created: '2024-01-23T14:33:43.997',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20168,
    ProductAttributeDefKey: 'glen_test_size',
    Name: 'glen_test_size',
    DataType: 'Date',
    DataTypeId: 5,
    IsGlobal: true,
    IsReadOnly: true,
    IsSingleLine: true,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-22T15:00:12.9',
    Created: '2024-01-08T16:16:08.693',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20151,
    ProductAttributeDefKey: 'glen_test_group_jd_only',
    Name: 'glen_test_group_jd_only_3',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-22T14:11:19.99',
    Created: '2023-12-18T14:06:51.203',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20164,
    ProductAttributeDefKey: 'creation_test_glen222',
    Name: 'creation_test_glen',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-16T10:24:11.193',
    Created: '2024-01-08T16:08:47.107',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20191,
    ProductAttributeDefKey: 'deletetion_test',
    Name: 'deletetion_test',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-15T16:13:00.95',
    Created: '2024-01-15T16:13:00.95',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20182,
    ProductAttributeDefKey: 'test_creation_2l',
    Name: 'test_creation_2l',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-15T12:20:52.047',
    Created: '2024-01-15T12:20:52.047',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20181,
    ProductAttributeDefKey: 'test_creation_pppp',
    Name: 'test_creation_pppp',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-15T11:56:55.497',
    Created: '2024-01-15T11:56:55.497',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20177,
    ProductAttributeDefKey: 'test_multi_site',
    Name: 'test_multi_site',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-15T09:32:01.543',
    Created: '2024-01-15T09:32:01.543',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20172,
    ProductAttributeDefKey: 'creation_test_glen4',
    Name: 'creation_test_glen2',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-08T17:04:31.66',
    Created: '2024-01-08T17:04:31.66',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20171,
    ProductAttributeDefKey: 'creation_test_glen3',
    Name: 'creation_test_glen2',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-08T17:03:55.607',
    Created: '2024-01-08T17:03:55.607',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20169,
    ProductAttributeDefKey: 'glen_global_test',
    Name: 'glen_global_test',
    DataType: 'Multi Lookup List',
    DataTypeId: 9,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: false,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-08T16:22:14.07',
    Created: '2024-01-08T16:22:14.07',
    TotalUsageCount: null,
  },
  {
    ProductAttributeDefId: 20166,
    ProductAttributeDefKey: 'lll',
    Name: 'lll',
    DataType: 'varchar(Text)',
    DataTypeId: 3,
    IsGlobal: false,
    IsReadOnly: false,
    IsSingleLine: true,
    IsHidden: false,
    IsMandatory: false,
    DefaultValue: null,
    Updated: '2024-01-08T16:14:44.73',
    Created: '2024-01-08T16:14:44.73',
    TotalUsageCount: null,
  },
];

export default AttributeDefinitions;
