import deleteRequest from '../../../../../deleteRequest';

/**
 * Remove category from product.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} categoryId Id of the category to remove
 * @returns {AxiosResponse}
 * @example
 * {
 *  "Success": true,
 *  "SiteCode": "JD",
 *  "SiteId": 1,
 *  "ProductId": 444
 * }
 */
export default function removeProductCategory(productId, siteId, categoryId) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/remove-product-category?categoryId=${categoryId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, 'Remove Category')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
