import patchRequest from '../../../../patchRequest';

/**
 * Patch request to assign category remote id to a category.
 *
 * @param {number} categoryId 1
 * @param {number} siteId 1
 * @param {number} sourceId 1
 * @param {number} categoryRemoteSystemIdString Grouping name
 */
export default function assignErp(
  categoryId,
  siteId,
  sourceId,
  categoryRemoteSystemIdString,
) {
  const endpoint = `/oc-data-management/v1/category/${categoryId}/${siteId}/assign-erp`;
  const errorMessageHeader = 'Assign category remote id to a category';
  const body = {
    sourceId,
    categoryRemoteSystemIdString,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
