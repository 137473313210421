import patchRequest from '../../../../../patchRequest';

/**
 * Calls custom-attribute endpoint to save value to PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @param {string} attributeKey - subbrandcode
 * @param {string} attributeValue - Jordan
 * @param {string} productNameLanguageIso2 - ENG
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchCustomAttribute(
  productId,
  siteCode,
  attributeKey,
  attributeValue,
  productNameLanguageIso2,
) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/custom-attribute';

  const body = {
    productId,
    siteCode,
    attributeKey,
    attributeValue,
    productNameLanguageIso2,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, `Update ${attributeKey}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchCustomAttribute;
