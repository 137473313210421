import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useSelector } from 'react-redux';

import LogoutButton from './LogoutButton';

export default function UserMenu({ iconBGColor, backgroundColor }) {
  const user = useSelector((state) => state.user);
  const settings = ['Logout'];
  const userName = user?.userProfile.sub;
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <style>
        {`
          .profile-icon div {
            height: 28px!important;
            width: 28px!important;
          }
        `}
      </style>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title={userName}>
          <IconButton
            onClick={handleOpenUserMenu}
            className="profile-icon"
            sx={{ border: '2px solid', borderColor: iconBGColor, p: 0 }}
          >
            <Avatar sx={{ bgcolor: iconBGColor, color: 'white' }}><strong>{userName[0]}</strong></Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <LogoutButton backgroundColour={backgroundColor} />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
}
