import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Attributes from './tabs/Attributes';
import Categories from './tabs/Categories';
import General from './tabs/General';
import History from './tabs/History';
import Variants from './tabs/Variants';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: `${theme.palette.secondary[500]} !important`,
    height: '4px !important',
  },
  selected: {
    color: '#fff !important',
    opacity: '1 !important',
  },
  tabRoot: {
    color: '#fff !important',
  },
}));

function TabPanel({
  children,
  value,
  index,
  ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p="8px">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: {},
  index: 0,
  value: 0,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

function ScrollableTabs({
  productIdentifiers,
  productStatus,
  productTypes,
}) {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = ['General', 'Variants', 'Attributes', 'Categories', 'History'];

  React.useEffect(() => {
    const tabParam = searchParams.get('tab');
    if (tabParam) {
      setSelectedTab(Number(tabParam));
    } else {
      setSearchParams((params) => {
        params.set('tab', selectedTab);
        return params;
      });
    }
  }, []);

  return (
    <div className="w-full mb-4 overflow-hidden border rounded bg-neutral-100">
      <Tabs
        value={selectedTab}
        onChange={(event, newTab) => {
          setSelectedTab(newTab);
          setSearchParams((params) => {
            params.set('tab', newTab);
            return params;
          });
        }}
        classes={{ indicator: styles.indicator, root: styles.selected }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabs.map((tab) => (
          <Tab
            key={tab}
            label={tab}
            classes={{
              root: styles.tabRoot,
              selected: styles.selected,
            }}
          />
        ))}
      </Tabs>
      <TabPanel value={selectedTab} index={0} className="overflow-hidden">
        <General />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Variants
          productIdentifiers={productIdentifiers}
          productStatus={productStatus}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <Attributes productTypesFromApi={productTypes} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <Categories />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <History />
      </TabPanel>
    </div>
  );
}

export default ScrollableTabs;
