import React from 'react';

function Release20231108() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">
        Bug Fixes and Improvements
      </h2>
      <p className="-mt-4">8th November 2023</p>

      <h3 className="font-gothammedium text-neutral-700">
        Product Page (PDP)
      </h3>
      <h4>
        Improvements:
      </h4>
      <ol>
        <li>
          <strong>
            Style Section Refactor
          </strong>
          <ul>
            <li>
              Refactored the procedures for the style section. Loading the PDP is now significantly faster.
            </li>
          </ul>
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Bulk Operations
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          <strong>
            Dropdown
          </strong>
          <ul>
            <li>
              New Header in the dropdown to separate date uploads.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            New Operations
          </strong>
          <ul>
            <li>
              Attributes
            </li>
            <li>
              Release Dates (file upload)
            </li>
          </ul>
        </li>
      </ol>
      <h4>
        Improvements:
      </h4>
      <ul>
        <li>
          Migrated from Diagnostic API to API Sender when sending products to fluent.
        </li>
        <li>
          Updated the price threshold logic when users enable fulfilment for NIKE agent model products.
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Security Fixes
      </h3>
      <ul>
        <li>
          Implemented CORS.
        </li>
        <li>
          Added E-Tag info disclosure.
        </li>
      </ul>
      <h4>
        Bug Fixes:
      </h4>
      <ul>
        <li>
          Product Types Bulk Uploads - Invalid Product IDs/Plu IDs error.
        </li>
        <li>
          Export all bug where the PLUs were not being exported on the CSVs.
        </li>
      </ul>
    </section>
  );
}

export default Release20231108;
