import postRequest from '../../../postRequest';

/**
 * Post request for styles.
 *
 * @param {number} page Page count
 * @param {number} size Page size
 */
function postStyles(
  page,
  size,
  {
    siteId,
    siteCode,
    name,
    styleIds,
    productIds,
    plus,
    brandIds,
    hasStyle,
  },
) {
  const pageQuery = page ? `?Page=${page}` : '';
  const sizeQuery = size ? `&Size=${size}` : '';
  const endpoint = `/oc-data-management/v1/styles${pageQuery}${sizeQuery}`;

  const body = {
    siteId,
    siteCode,
    name,
    styleIds,
    productIds,
    plus,
    brandIds,
    hasStyle,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Styles')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postStyles;
