import getRequest from '../../../../getRequest';

/**
 * Get a list of the campaign or collections values.
 *
 * @param {number} siteId
 * @param {number} campaignOrCollection
 * @param {number} languageId
 * @returns Attributes
 */
export default function getSiteCampaignCollection(siteId, campaignOrCollection, languageId) {
  const query = `?collectionAndCampaignType=${campaignOrCollection}&languageId=${languageId}`;
  const endpoint = `/oc-product-bulkapi/v1/sites/${siteId}/site-campaign-collection${query}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Site Campaign Collection')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
