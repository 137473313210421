import autoAnimate from '@formkit/auto-animate';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toNumber } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getCountries from '../../../../api/intergration/pim/countries/gets/getCountries';
import postAttributes from '../../../../api/intergration/pim/products/dropshipment/posts/postAttributes';
import postCategories from '../../../../api/intergration/pim/products/dropshipment/posts/postCategories';
import postPrices from '../../../../api/intergration/pim/products/dropshipment/posts/postPrices';
import postSizes from '../../../../api/intergration/pim/products/dropshipment/posts/postSizes';
import postSkus from '../../../../api/intergration/pim/products/dropshipment/posts/postSkus';
import postStyles from '../../../../api/intergration/pim/products/dropshipment/posts/postStyles';
import getSites from '../../../../api/intergration/pim/sites/gets/getSites';
import {
  setProductDataActiveStep,
  setProductDataBrandName,
  setProductDataCategories,
  setProductDataColour,
  setProductDataDescription,
  setProductDataDescriptionTranslations,
  setProductDataFabricType,
  setProductDataFabricTypeTranslations,
  setProductDataOtherDataConnectedCustomers,
  setProductDataOtherDataExclusiveDateFrom,
  setProductDataOtherDataExclusiveDateTo,
  setProductDataOtherDataLicensed,
  setProductDataOtherDataSeason,
  setProductDataOtherDataSeasonYear,
  setProductDataProductName,
  setProductDataProductNameTranslations,
  setProductDataSubBrand,
  setProductDataVat,
} from '../../../../redux/wizard/nike/nike_wizard';
import toCapitalized from '../../../../utils/toCapitalized';
import Sidebar from './Sidebar';
import Steps from './Steps';

function ProductData({ group, reasons }) {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);

  const { productId } = nike_wizard;

  const [isLoading, setLoading] = React.useState([
    {
      name: 'styles',
      loading: false,
    },
    {
      name: 'attributes',
      loading: false,
    },
    {
      name: 'categories',
      loading: false,
    },
    {
      name: 'sizes',
      loading: false,
    },
  ]);
  const [sidemenuOpen, setSidemenuOpen] = React.useState(true);
  const [nameTranslations, setNameTranslations] = React.useState([]);

  const editChip = (stepIndex) => {
    dispatchToRedux(setProductDataActiveStep((stepIndex - 1)));
  };

  const groupsDupsRemoved = group.filter((value, index, self) => {
    const fasciaIndex = self.findIndex((t) => t.fascia === value.fascia);
    return fasciaIndex === index;
  });

  const apiData = async () => {
    const countriesData = await getCountries(1, 100)
      .catch((err) => {
        console.error(err);
      });
    const sitesData = await getSites(1, 100)
      .catch((err) => {
        console.error(err);
      });
    const startLoading = isLoading.map((obj) => {
      const clone = structuredClone(obj);
      clone.loading = true;
      return clone;
    });
    setLoading(startLoading);
    // Used for Brand name and ProductName in ProductData
    postStyles(Number(productId), null)
      .then(({ data }) => {
        const stylesLoading = isLoading.map((obj) => {
          if (obj.name === 'styles') {
            const clone = structuredClone(obj);
            clone.loading = false;
            return clone;
          }
          return obj;
        });
        setLoading(stylesLoading);
        const { Data } = data;
        //* Populates fields
        Data.forEach((obj) => {
          const productSites = group[0].countries;
          const countryInfo = countriesData.data.Data
            .map((x) => ({ CountryCode: x.CountryCode, LanguageId: x.LanguageId }));
          // Product is sold on the Site (GB) and the product is for that language (en)
          const correctCountry = countryInfo
            .find((country) => country.LanguageId === obj.LanguageId
                && productSites.includes(country.CountryCode));
          const correctSite = sitesData.data.Data
            .find((site) => site.CountryCode === correctCountry?.CountryCode
                && site.Fascia === 'JD');
          const country_included = obj.SiteId === correctSite?.SiteId
              && obj.LanguageId === correctCountry?.LanguageId;

          if (obj.CountryCode === 'GB' && obj.LanguageId === 1 && obj.SiteId === 1) {
            dispatchToRedux(setProductDataBrandName({
              id: obj.BrandId,
              name: obj.BrandName,
            }));
            dispatchToRedux(setProductDataProductName(obj.StyleName));
          }
          if (country_included) {
            const matchedSite = countriesData.data.Data.find((x) => x.CountryCode
                === obj.CountryCode);
            // if (obj.StyleName.length > 0) {}
            setNameTranslations(nameTranslations.push({
              country_code: matchedSite.CountryCode,
              translation: obj.StyleName,
            }));
          }
        });
        //* Populates any missing fields with GB data or Global data if GB isn't available
        // Gets the countries that have existing name translations
        const existingTranslationsCountries = nameTranslations.map((obj) => obj.country_code);
        // Countries the product is on
        const productSites = group[0].countries;
        // Get array of countries that are missing translations
        const missing = productSites.filter((country) => !(existingTranslationsCountries
          .includes(country)));
        // console.log('Missing', missing);
        // Get the obj that is either GB or Global translation
        const correctObj = Data.find(({ CountryCode }) => {
          const foundGB = CountryCode === 'GB';
          if (foundGB === false) {
            return CountryCode === null;
          }
          return foundGB;
        });
        const translation = correctObj?.StyleName;
        missing.forEach((str) => {
          if (str === 'GB') {
            dispatchToRedux(setProductDataBrandName({
              id: correctObj.BrandId,
              name: correctObj.BrandName,
            }));
            dispatchToRedux(setProductDataProductName(correctObj.StyleName));
          } else {
            nameTranslations.push({
              country_code: str,
              translation,
            });
          }
        });
        dispatchToRedux(setProductDataProductNameTranslations(nameTranslations));
      });
    // Used for Fabric, Description and Colors section in ProductData
    postAttributes(Number(productId), null)
      .then(({ data }) => {
        const attributesLoading = isLoading.map((obj) => {
          if (obj.name === 'attributes') {
            const clone = structuredClone(obj);
            clone.loading = false;
            return clone;
          }
          return obj;
        });
        setLoading(attributesLoading);
        const { Data } = data;
        const globalLevel = Data.filter((obj) => obj.AttributeSource === 'global');
        const groupLevel = Data.filter((obj) => obj.AttributeSource === 'site-group');
        const propertyLevel = Data.filter((obj) => obj.AttributeSource === 'site-property');
        const all = [globalLevel, groupLevel, propertyLevel];
        all.forEach((level) => {
          level.forEach((obj) => {
            const productSites = group[0].countries;
            const countryInfo = countriesData.data.Data
              .map((x) => ({ CountryCode: x.CountryCode, LanguageId: x.LanguageId }));
            // Product is sold on the Site (GB) and the product is for that language (en)
            const correctCountry = countryInfo
              .find((country) => country.LanguageId === obj.LanguageId
                && productSites.includes(country.CountryCode));
            const correctSite = sitesData.data.Data
              .find((site) => site.CountryCode === correctCountry?.CountryCode
                && site.Fascia === 'JD');
            const country_included = obj.SiteId === correctSite?.SiteId
              && obj.LanguageId === correctCountry?.LanguageId;

            if (obj.ProductAttributeDefKey === 'subbrandcode'
              && nike_wizard.productData.brand.subbrand.length <= 0
              && (obj.SiteId === 1 || obj.SiteId == null)) {
              dispatchToRedux(setProductDataSubBrand(obj.Value));
            }
            if (obj.ProductAttributeDefKey === 'licensed'
              && (obj.SiteId === 1 || obj.SiteId == null)) {
              const oneOrZero = obj.Value === 'yes' || obj.Value === '1' ? 1 : 0;
              dispatchToRedux(setProductDataOtherDataLicensed(oneOrZero));
            }

            if (obj.ProductAttributeDefKey === 'nike_connected_customers'
              // && state.other_attributes.connected_customers.length <= 0
              && (obj.SiteId === 1 || obj.SiteId == null)) {
              const oneOrZero = obj.Value === 'yes' || obj.Value === '1' ? 1 : 0;
              dispatchToRedux(setProductDataOtherDataConnectedCustomers(oneOrZero));
            }
            if (obj.ProductAttributeDefKey === 'exclusivity_date_from'
              && nike_wizard.productData.other_attributes.from.length <= 0
              && (obj.SiteId === 1 || obj.SiteId == null)) {
              if (obj.AttributeSource === 'site-group') {
                dispatchToRedux(setProductDataOtherDataExclusiveDateFrom(obj.Value));
              } else {
                dispatchToRedux(setProductDataOtherDataExclusiveDateFrom(obj.Value));
              }
            }
            if (obj.ProductAttributeDefKey === 'exclusivity_date_to'
              && nike_wizard.productData.other_attributes.to.length <= 0
              && (obj.SiteId === 1 || obj.SiteId == null)) {
              if (obj.AttributeSource === 'site-group') {
                dispatchToRedux(setProductDataOtherDataExclusiveDateTo(obj.Value));
              } else {
                dispatchToRedux(setProductDataOtherDataExclusiveDateTo(obj.Value));
              }
            }

            const saveFabricTranslation = () => {
              if (obj.SiteId !== 1 && obj.SiteId != null) {
                dispatchToRedux(setProductDataFabricTypeTranslations({
                  country_code: correctCountry.CountryCode,
                  translation: obj.Value,
                }));
              }
            };
            const saveFabric = () => {
              if (obj.LanguageId === 1 && (obj.SiteId === 1 || obj.SiteId == null)) {
                dispatchToRedux(setProductDataFabricType(obj.Value));
              }
              if (country_included) {
                saveFabricTranslation();
              }
            };
            if (obj.ProductAttributeDefKey === 'product_fabric'
              && nike_wizard.productData.fabric.fabric_type.length <= 0) {
              saveFabric();
            }

            const saveDescriptionTranslation = () => {
              if (obj.SiteId !== 1 && obj.SiteId != null && country_included) {
                dispatchToRedux(setProductDataDescriptionTranslations({
                  country_code: correctCountry?.CountryCode,
                  translation: obj.Value,
                }));
              }
            };
            const saveDescription = () => {
              if (obj.LanguageId === 1 && (obj.SiteId === 1 || obj.SiteId == null)) {
                dispatchToRedux(setProductDataDescription(obj.Value));
              }
              if (country_included) {
                saveDescriptionTranslation();
              }
            };
            if (obj.ProductAttributeDefKey === 'product_description'
              && nike_wizard.productData.product_description.description.length <= 0
              && obj.LanguageId === 1) {
              saveDescription();
            }
            if (obj.ProductAttributeDefKey === 'translated_product_description'
              && country_included) {
              saveDescriptionTranslation();
            }
            if (obj.ProductAttributeDefKey === 'product_primary_colours'
              && nike_wizard.productData.colours.primary_colour.length <= 0
              && (obj.SiteId === 1 || obj.SiteId == null)) {
              dispatchToRedux(setProductDataColour({
                primary_colour: obj.Value,
                secondary_colour: '',
                shogun_colour_code: '999',
              }));
            }
            if (obj.ProductAttributeDefKey === 'seasonyear'
              && nike_wizard.productData.other_attributes.season_year.length <= 0
              && obj.SiteId === null
              && obj.LanguageId === 1) {
              dispatchToRedux(setProductDataOtherDataSeasonYear(obj.Value));
            }
            if (obj.ProductAttributeDefKey === 'season'
              && nike_wizard.productData.other_attributes.season.length <= 0
              && obj.SiteId === null
              && obj.LanguageId === 1) {
              dispatchToRedux(setProductDataOtherDataSeason(obj.Value));
            }
          });
        });
      });
    // Used for Categories section in ProductData
    postCategories(Number(productId), 'JD')
      .then(({ data }) => {
        const categoriesLoading = isLoading.map((obj) => {
          if (obj.name === 'categories') {
            const clone = structuredClone(obj);
            clone.loading = false;
            return clone;
          }
          return obj;
        });
        setLoading(categoriesLoading);
        const { Data } = data;
        const globalData = Data.filter((obj) => obj.SiteId == null);
        if (globalData.length > 0 && nike_wizard.productData.categories.pim_category.length <= 0) {
          dispatchToRedux(setProductDataCategories({
            pim_category: [globalData[0].CategoryName], // dropdown of all categories
            shogun_category: globalData[0].ShogunCategory, // helper text 2 char
            shogun_buyer_number: globalData[0].Buyer,
            shogun_division_number: '1',
            shogun_subcategory: '00',
          }));
        } else {
          const jdData = Data.filter((obj) => obj.SiteId === 1);
          if (jdData.length > 0 && nike_wizard.productData.categories.pim_category.length <= 0) {
            dispatchToRedux(setProductDataCategories({
              pim_category: jdData, // dropdown of all categories
              shogun_category: jdData[0].ShogunCategory, // helper text 2 char
              shogun_buyer_number: jdData[0].Buyer,
              shogun_division_number: '1',
              shogun_subcategory: '00',
            }));
          }
        }
      });
    postSkus(productId, 'JD')
      .then((skus) => {
        postSizes(Number(productId), 'JD')
          .then(async ({ data }) => {
            const sizesLoading = isLoading.map((obj) => {
              if (obj.name === 'sizes') {
                const clone = structuredClone(obj);
                clone.loading = false;
                return clone;
              }
              return obj;
            });
            setLoading(sizesLoading);
            const { Data } = data;
            if (Data.length > 0) {
              const groupedData = Data.reduce((pre, curr) => {
                const { VariantId } = curr;
                if (!pre[VariantId]) {
                  // eslint-disable-next-line no-param-reassign
                  pre[VariantId] = [];
                }
                pre[VariantId].push({ size: curr.Size, country_code: curr.Name });
                return pre;
              }, {});
              const groupedDataArr = Object.entries(groupedData);
              if (nike_wizard.productData.vat[0]?.sku.length <= 0) {
                const pricesRes = await postPrices(productId, null)
                  .catch((err) => {
                    console.error(err.message);
                    toast.error(`PIM ERROR: Getting Prices - ${err.message}`, {
                      autoClose: 30000,
                    });
                  });

                const vatWithSize = groupedDataArr.map(([key, value]) => ({
                  sku: key,
                  sizes: value
                    .map((obj2) => ({ size: obj2.size, country_code: obj2.country_code })),
                  vat_code: groupsDupsRemoved.map((site) => site.countries.map((country) => ({
                    is_vatable: false,
                    country_code: country,
                  })))[0],
                }));

                if (vatWithSize[0].variant == null) {
                  const anatwineSkus = vatWithSize.map((vatObj) => {
                    // [{ CountryCode: "GB", VatCode: "False", VariantId: 123456 }]
                    const vatCodes = (pricesRes.data.Data.filter((p) => p.VariantId
                      === Number(vatObj.sku))).map((obj) => (
                      {
                        VatCode: obj?.VatCode,
                        CountryCode: obj?.CountryCode,
                        VariantId: obj?.VariantId,
                      }
                    ));
                    const filteredSkus = skus.data.Data
                      .find((sku) => sku.VariantId === toNumber(vatObj.sku));
                    const filteredPrices = pricesRes.data.Data.find((p) => p.VariantId
                      === Number(vatObj.sku)
                      && p.Name === 'Shogun'
                      && p.CountryCode === 'GB');
                    const newSku = filteredSkus == null ? 'No Anatwine SKU' : filteredSkus.SkuId;
                    const commodityCode = filteredPrices?.CommodityCode == null ? 'No Commodity Code'
                      : filteredPrices.CommodityCode;
                    return {
                      ...vatObj,
                      sku: newSku,
                      variant: vatObj.sku,
                      commodityCode,
                      vat_code: vatObj.vat_code.map((obj) => {
                        const vatCode = vatCodes?.find((vat) => vat?.CountryCode
                          === obj.country_code
                          && vat?.VatCode !== null);
                        return {
                          ...obj,
                          is_vatable: vatCode?.VatCode === '1' || vatCode?.VatCode === 'True',
                        };
                      }),
                    };
                  });
                  const anatwineSkusFiltered = anatwineSkus
                    .filter((obj) => obj.sku !== 'No Anatwine SKU');
                  dispatchToRedux(setProductDataVat(anatwineSkusFiltered));
                }
              }
            }
          });
      })
      .catch((err) => {
        console.error(`Product Data: ${err}`);
      });
  };

  useEffect(() => {
    apiData();
  }, []);

  const containerRef = React.useRef(null);
  useEffect(() => {
    autoAnimate(containerRef.current);
  }, [containerRef]);

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Grid container className="relative overflow-auto sm:h-full" ref={containerRef}>
      {matches && (
        <div className="absolute z-50 top-2 left-3">
          <Tooltip title={sidemenuOpen ? 'Close Side-Menu' : 'Open Side-Menu'}>
            <IconButton onClick={() => setSidemenuOpen(!sidemenuOpen)}>
              {sidemenuOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
          </Tooltip>
        </div>
      )}

      {/* Desktop Sidebar */}
      {sidemenuOpen && (
        <Grid item xs={4} className="hidden sm:block">
          <Sidebar
            state={nike_wizard.productData}
            group={group}
            reasons={reasons}
          />
        </Grid>
      )}

      {/* Mobile sidebar */}
      <Grid item xs={12} className="sm:hidden h-min">
        <Box key="box-2" className="p-4 border-b bg-neutral-50 text-tertiary-600">
          <Typography
            variant="h6"
            className="font-gothambold"
            marginBottom={2}
          >
            Edit Product Data
          </Typography>
          <div className="flex justify-between">
            {Object.entries(nike_wizard.productData).map(([key], index) => {
              const { productData } = nike_wizard;
              if (key === 'activeStep') return null;
              if (key === 'initial_pim_categories') return null;
              if (productData.activeStep === (index - 1)) return null;
              if (Object.keys(productData)[(productData.activeStep)] === key) {
                return (
                  <Box
                    key={`mobile-step-${key}`}
                    onClick={() => editChip(index)}
                    className="cursor-pointer"
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      index={index}
                      key={`steps-${key}`}
                      className="flex items-center font-gothammedium"
                    >
                      <NavigateBeforeIcon />
                      {`${toCapitalized(key)}`}
                    </Typography>
                  </Box>
                );
              }
              if (Object.keys(productData)[(productData.activeStep + 2)] === key) {
                return (
                  <Box key="box-navigation-next" onClick={() => editChip(index)} className="cursor-pointer">
                    <Typography
                      variant="body1"
                      gutterBottom
                      index={index}
                      key={`steps-${key}`}
                      className="flex items-center font-gothammedium"
                    >
                      {`${toCapitalized(key)}`}
                      <NavigateNextIcon onClick={() => editChip(index)} />
                    </Typography>
                  </Box>
                );
              }
              return null;
            })}
          </div>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={sidemenuOpen ? 8 : 12}
        className="sticky top-0 h-full mt-4 overflow-auto sm:mt-0"
      >
        <div className="px-8 overflow-auto sm:pt-8 sm:pr-14 sm:pl-8">
          <Steps sites={groupsDupsRemoved} loading={isLoading} />
        </div>
      </Grid>
    </Grid>
  );
}

export default ProductData;
