import deleteRequest from '../../../deleteRequest';

/**
 * Deletes size map.
 *
 * @param {number} siteId - 1
 * @param {number} sizeMappingId
 * @returns {Promise} { Success: true }, status: 200 }
 */
async function deleteSizeMap(siteId, sizeMappingId) {
  const endpoint = `/oc-product/v1/size-mapping/${sizeMappingId}/${siteId}`;

  const errorMessageHeader = `Delete ${sizeMappingId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteSizeMap;
