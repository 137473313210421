import axios from 'axios';

import patchProductStyle from '../../../api/intergration/pim/products/dropshipment/patches/patchProductStyle';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function brandAndProductName(userEmail, nike_wizard, country, sitesRes, countriesRes) {
  return new Promise((resolve, reject) => {
    const site = sitesRes.data.Data.find((sr) => sr.CountryCode === country && sr.Fascia === 'JD');
    const siteCode = country === 'GB' ? 'JD' : site.SiteCode;

    const productNameFoundTranslation = nike_wizard.productData.product_name.translations
      .find((t) => t.country_code === country);
    const nameData = productNameFoundTranslation == null
      || productNameFoundTranslation?.country_code === 'GB'
      ? nike_wizard.productData.product_name.name
      : productNameFoundTranslation.translation;

    const languageIso = countriesRes.find((c) => c.CountryCode === country);
    patchProductStyle(
      nike_wizard.productId,
      siteCode,
      nike_wizard.productData.brand.brand_name.id.toString(),
      nameData,
      languageIso.LanguageId,
    )
      .then((res) => {
        if (res.data.Success) {
          if (nike_wizard.editedValues.includes('brandname')) {
            postAddProductLog(
              nike_wizard.productId,
              siteCode,
              `[INFO]: User ${userEmail} updated BrandId: ${nike_wizard.productData.brand.brand_name.id} - BrandName: ${nike_wizard.productData.brand.brand_name.name} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
            );
          }
          if (nike_wizard.editedValues.includes('productName')) {
            postAddProductLog(
              nike_wizard.productId,
              siteCode,
              `[INFO]: User ${userEmail} updated ProductName: ${nameData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
            );
          }
          if (nike_wizard.editedValues.includes(`productNameTranslation-${languageIso.country_code}`)) {
            postAddProductLog(
              nike_wizard.productId,
              siteCode,
              `[INFO]: User ${userEmail} updated ProductName: ${nameData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
            );
          }
          resolve(res.data);
        } else {
          postAddProductLog(
            nike_wizard.productId,
            siteCode,
            `[ERROR]: User ${userEmail} FAILED to update BrandId: ${nike_wizard.productData.brand.brand_name.id} and ProductName: ${nameData} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: ${languageIso.CountryCode}. Return Error Message: ${res.data.ErrorMessage}`,
          );
          reject(res.data.ErrorMessage);
        }
      })
      .catch((err) => {
        // If the error message contains the word Cancel then dont do error stuff.
        // Reason for this is because if a request is cancelled, this isn't an error to do with
        // saving data so the user doesn't need to be notified of this.
        if (axios.isCancel(err)) {
          resolve();
        } else {
          postAddProductLog(
            nike_wizard.productId,
            siteCode,
            `[ERROR]: User ${userEmail} FAILED to update BrandId: ${nike_wizard.productData.brand.brand_name.id} and ProductName: ${nameData} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: ${languageIso.CountryCode}. Return Error Message: ${err.message}`,
          );
          reject(err);
        }
      });
  });
}

export default brandAndProductName;
