import patchCustomAttribute from '../../../api/intergration/pim/products/dropshipment/patches/patchCustomAttribute';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function licensed(userEmail, nike_wizard) {
  return new Promise((resolve, reject) => {
    patchCustomAttribute(
      nike_wizard.productId,
      'JD',
      'licensed',
      nike_wizard.productData.other_attributes.licensed.toString(),
      'GB',
    )
      .then((res) => {
        postAddProductLog(
          nike_wizard.productId,
          'JD',
          `[INFO]: User ${userEmail} updated License to ${nike_wizard.productData.other_attributes.licensed} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB`,
        );
        resolve(res.data);
      })
      .catch((error) => {
        postAddProductLog(
          nike_wizard.productId,
          'JD',
          `[ERROR]: User ${userEmail} FAILED to updated License to ${nike_wizard.productData.other_attributes.licensed} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB`,
        );
        reject(error);
      });
  });
}

export default licensed;
