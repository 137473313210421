import deleteRequest from '../../../../../../deleteRequest';

/**
 * Bulk delete Categories from multiple products.
 *
 * @param {{
 *  referenceIds: string[],
 *  categoryId: number[],
 *  languageId: number,
 *  siteId: number,
 * }}
 * @param {string[]} referenceIds Product Ids
 * @param {number[]} categoryId Categories Id
 * @param {number} siteId Site Id
*/
export default async function deleteCategoriesProductId({
  referenceIds,
  categoryId,
  siteId,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/categories/product-id';

  const body = {
    referenceIds,
    categoryId,
    siteId,
    languageId: 0,
  };

  const successMessage = 'Started : Categories : Prod ID (Delete)';
  const errorMessageHeader = `Failed to DELETE - CategoryIds: ${categoryId.toString()} from Products`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, errorMessageHeader, successMessage)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
