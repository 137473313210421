// import getRequest from '../../../getRequest';
import postRequest from '../../../postRequest';

/**
 * Product information. Designed for the PDP.
 *
 * @param {number} siteId Site Id of the product
 * @param {number} productId Id of the product
 * @param {number} page Page count
 * @param {number} size Page size
 */
export default function postLogs(
  siteId,
  productId,
  page,
  size,
  {
    sourceId,
    fromDate,
    toDate,
    userName,
    remoteSystemId,
    isLeadSite,
  },
) {
  const pageQuery = page ? `?Page=${page}` : '';
  const sizeQuery = size ? `&Size=${size}` : '';
  const endpoint = `/oc-product/v1/changes/log/product/${productId}/${siteId}/${pageQuery}${sizeQuery}`;

  const body = {
    sourceId,
    fromDate,
    toDate,
    userName,
    remoteSystemId,
    isLeadSite,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Logs')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
