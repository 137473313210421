import getRequest from '../../../../getRequest';

/**
 * Get a list of the attributes values.
 *
 * @param {number} siteId
 * @param {number} productAttributeDefId
 * @param {number} languageId
 * @returns Attributes
 */
export default function getAttributeOptions(siteId, productAttributeDefId, languageId) {
  const endpoint = `/oc-product/v1/sites/${siteId}/single-multi-attributes-values/?languageId=${languageId}&attributeDefId=${productAttributeDefId}`;
  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Attribute Values')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
