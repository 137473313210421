import getRequest from '../../../getRequest';

/**
 * Get all Brand Groups.
 * @param {number} siteId - 1
 * @param {number} brandGroupId - 1
 * @returns [
 *     {
 *         "BrandGroupSiteId": 463,
 *         "BrandGroupId": 1,
 *         "BrandGroupName": "adidas",
 *         "BrandId": 63,
 *         "BrandName": "adidas",
 *         "SiteId": 1,
 *         "Created": "0001-01-01T00:00:00"
 *       },
 *   ]
 */
export default async function getBrandGroupBrands(siteId, brandGroupId) {
  const endpoint = `/oc-data-management/v1/brand-group/${brandGroupId}/${siteId}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Brand Groups')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
