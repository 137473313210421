import AddIcon from '@mui/icons-material/Add';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import Modal from '../../components/Modal';
import UsersData from '../../dummy_data/Users';
// import getAllUsers from '../../api/private/users_management/gets/getAllUsers';
import UserList from './UserList';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: `${theme.palette.secondary[500]} !important`,
    height: '4px !important',
  },
  selected: {
    color: '#fff !important',
    opacity: '1 !important',
  },
  tabRoot: {
    color: '#fff !important',
    width: '50%',
  },
  table: {
    '& .MuiTableCell-root': {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
    '&.MuiTableCell-head': {
      backgroundColor: theme.palette.grey[100],
    },
    width: '70%',
    '&:first-child': {
      width: '30%',
    },
  },
}));

function TabPanel({
  children,
  value,
  index,
  ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p="8px">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: {},
  index: 0,
  value: 0,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function UserManagement() {
  const [tabValue, setTabValue] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const tabs = ['Users', 'Group'];
  const styles = useStyles();
  const rows = UsersData;
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 100,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email Address',
      width: 100,
      flex: 1,
    },
    {
      field: 'permission',
      headerName: 'Access Level',
      width: 100,
      flex: 1,
    },
    {
      field: 'group',
      headerName: 'User Type',
      width: 100,
      flex: 1,
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      width: 200,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <MoreVertSharpIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: 'red',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            setOpenModal(true);
          }}
          onMouseOver={params.value}
        />
      ),
    },
  ];

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="pt-4 md:p-8">
      <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <div className="flex flex-col justify-between mb-4 md:mb-8 md:items-center md:flex-row">
          <div>
            <Box sx={{ padding: '20px' }}>
              <Typography variant="h3" sx={{ color: '#384451', marginBottom: 1 }}>
                User Management
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 3 }}>
                Manage user access and group permissions
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Box sx={{ marginBottom: 0, width: '300px' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search Users"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        style: { borderRadius: 30, backgroundColor: '#E0F0F8', height: '45px' },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
          <Stack direction="column" gap={2}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Paper
                elevation={1}
                sx={{
                  borderRadius: '20px', padding: 1, textAlign: 'left', width: 150, border: '2px solid #5A9EA8',
                }}
              >
                <Typography variant="subtitle2" sx={{ color: '#5A9EA8', fontWeight: 'bold' }}>
                  Total Users
                </Typography>
                <Typography variant="h4">0</Typography>
              </Paper>
              <Paper
                elevation={1}
                sx={{
                  borderRadius: '20px', padding: 1, textAlign: 'left', width: 150, border: '2px solid #5A9EA8',
                }}
              >
                <Typography variant="subtitle2" sx={{ color: '#5A9EA8', fontWeight: 'bold' }}>
                  Groups
                </Typography>
                <Typography variant="h4">0</Typography>
              </Paper>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                sx={{
                  borderRadius: '20px', width: 150, height: 40, backgroundColor: '#384451',
                }}
              >
                Add User
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  borderRadius: '20px', width: 150, height: 40, backgroundColor: '#384451',
                }}
              >
                Add Group
              </Button>
            </Box>
          </Stack>
        </div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          classes={{ indicator: styles.indicator, root: styles.selected }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          className="flex flex-row"
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            overflow: 'hidden',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#384451',
            marginLeft: '20px',
          }}
        >
          {tabs.map((tab) => (
            <Tab
              {...a11yProps(tabValue)}
              key={tab}
              label={tab}
              classes={{
                root: styles.tabRoot,
                selected: styles.selected,
              }}
              sx={{ maxWidth: 'none' }}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <UserList columns={columns} rows={rows} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div />
      </TabPanel>
      <Modal
        title="Edit User"
        open={openModal}
        onClose={handleModalClose}
        buttonLabel="Confirm"
      />
    </div>
  );
}

export default UserManagement;
