import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';

import { AttributeDefinitionsContext } from '../../AttributeDefinitionsContext';

function renderSearchInput(
  defKeyType,
  id,
  setId,
  idError,
  setIdError,
  dataType,
  setDataType,
  name,
  setName,
  attributeDataTypes,
) {
  const handleIdChange = (inputValue) => {
    const regex = /^[0-9\b]+$/;
    if (inputValue === '' || regex.test(inputValue)) {
      setId(inputValue);
    }
  };

  switch (defKeyType) {
    case 'id':
      return (
        <TextField
          value={id}
          disabled={defKeyType === ''}
          id="search-field"
          label="Search"
          size="small"
          className="w-[250px]"
          onChange={(e) => handleIdChange(e.target.value)}
        />
      );
    case 'name':
      return (
        <TextField
          value={name}
          disabled={defKeyType === ''}
          id="search-field"
          label="Search"
          size="small"
          className="w-[250px]"
          onChange={(e) => setName(e.target.value)}
        />
      );
    case 'data-type':
      return (
        <Autocomplete
          id="data-types"
          size="small"
          value={dataType}
          onChange={(e, value) => setDataType(value)}
          className="w-[250px]"
          options={attributeDataTypes || []}
          getOptionLabel={(type) => (type ? type?.Description : '')}
          renderInput={(params) => <TextField {...params} label="Data Type" />}
        />
      );
    default:
      return (
        <TextField
          disabled={defKeyType === ''}
          size="small"
          value=""
        />
      );
  }
}

function DefinitionKeyFilter() {
  const {
    defKeyType,
    setDefKeyType,
    id,
    setId,
    idError,
    setIdError,
    dataType,
    setDataType,
    name,
    setName,
    attributeDataTypes,
  } = useContext(AttributeDefinitionsContext);

  const handleIdTypeChange = (event) => {
    setId(null);
    setDataType(null);
    setName('');
    setDefKeyType(event.target.value);
  };

  return (
    <Stack spacing={2} direction="column">
      <Typography variant="body2">Select Definition Type</Typography>
      <div className="flex flex-col gap-2 mt-2">
        <FormControl fullWidth size="small" id="type-filter-select-label">
          <InputLabel id="type-filter-select-label">Type</InputLabel>
          <Select
            labelId="type-filter-select-label"
            label="Type"
            value={defKeyType}
            onChange={(event) => handleIdTypeChange(event)}
          >
            <MenuItem value="id">ID</MenuItem>
            <MenuItem value="data-type">Data Type</MenuItem>
            <MenuItem value="name">Name</MenuItem>
          </Select>
        </FormControl>
        {renderSearchInput(
          defKeyType,
          id,
          setId,
          idError,
          setIdError,
          dataType,
          setDataType,
          name,
          setName,
          attributeDataTypes,
        )}
      </div>
    </Stack>
  );
}

export default DefinitionKeyFilter;
