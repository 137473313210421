import patchRequest from '../../../../../patchRequest';

/**
 * Assign/Unassign category as a primary.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} categoryId Id of the category to assign
 * @returns {AxiosResponse}
 * @example
 * {
 *  "Success": true,
 *  "SiteCode": "JD",
 *  "SiteId": 1,
 *  "ProductId": 444
 * }
 */
export default function patchPrimaryCategory(productId, siteId, categoryId) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/assign-unassign-category-as-primary?categoryId=${categoryId}`;

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, 'Assign/Unassign category as a primary')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
