import deleteRequest from '../../../../deleteRequest';

/**
 * Deletes a category.
 *
 * @param {string} disabledCategoryId - 1
 * @param {string} userName - Matthew Williams
 * @returns {Promise} { Success: true }, status: 200 }
 */
async function deleteCategory(disabledCategoryId, userName) {
  const endpoint = `/oc-data-management/v1/category/disabled/${disabledCategoryId}?userName=${userName}`;

  const errorMessageHeader = `Delete ${disabledCategoryId} category`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteCategory;
