/* eslint-disable no-param-reassign */
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
// import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import patchPriceAndVat from '../../../../../../api/oc_product/product/style/patches/patchPriceAndVat';
import patchVariantStockEnabled from '../../../../../../api/oc_product/product/style/patches/patchVariantStockEnabled';
import getVatCodeOptions from '../../../../../../api/oc_product/product/variants/gets/getVats';
import patchVariants from '../../../../../../api/oc_product/variants/patches/patchVariants';
// import ExportButton from '../../../../../../components/ExportButton';
import Modal from '../../../../../../components/Modal';
import { ProductContext } from '../../ProductContext';

function SkuVariantsTable({
  data,
  loading,
  productIdentifiers,
  productStatus,
}) {
  const DEFAULT_MIN_CELL_HEIGHT = 60;

  const columns = [
    {
      id: 'Updated',
      label: 'Stock Updated',
    },
    {
      id: 'Rrp',
      label: 'RRP',
    },
    {
      id: 'SellingPrice',
      label: 'Selling Price',
    },
    {
      id: 'CostPrice',
      label: 'Cost',
    },
    {
      id: 'IsOms',
      label: 'OMS',
      hidden: !productStatus.oms,
    },
    {
      id: 'IsStockEnabled',
      label: 'Enabled',
    },
  ];
  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const {
    variantReRender, setVariantReRender, setProductStyleRerender, productDetails,
  } = React.useContext(ProductContext);

  const [allRows, setAllRows] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [highlightedRow, setHighlightedRow] = React.useState('');

  const [filterString, setFilterString] = React.useState('');

  const [openEnabledModal, setOpenEnabledModal] = React.useState(false);
  const [openApprovedModal, setOpenApprovedModal] = React.useState(false);

  const [selectedVariantId, setSelectedVariantId] = React.useState('');
  const [selectedVariantIdStatus, setSelectedVariantIdStatus] = React.useState();
  const [approvalStatus, setApprovalStatus] = React.useState({});
  const [productSources, setProductSources] = React.useState([]);
  const [selectedVariant, setSelectedVariant] = React.useState();
  const [enableLoading, setEnableLoading] = React.useState(false);
  const [vatOptions, setVatOptions] = React.useState([]);
  const [vatRows, setVatRows] = React.useState([]);
  const [priceRows, setPriceRows] = React.useState([]);
  const [patchLoading, setPatchLoading] = React.useState(false);
  const [isValidRrpByVariantPriceId, setIsValidRrpByVariantPriceId] = React.useState({});

  const fetchVatOptions = async (brandId) => {
    try {
      if (data && data.length > 0) {
        data.forEach((item) => {
          item.VariantSources.forEach((variantSource) => {
            if (variantSource.SourceTypeId === 2) {
              getVatCodeOptions(siteId, brandId, variantSource.SourceId)
                .then((res) => {
                  const getVatOptions = res.data.map((option) => ({
                    Id: option.Value,
                    Value: option.Description,
                  }));
                  setVatOptions(getVatOptions);
                })
                .catch((err) => {
                  console.error('err:', err);
                });
            }
          });
        });
      }
    } catch (error) {
      console.error('Error fetching Vat Code:', error);
    }
  };

  React.useEffect(() => {
    if (productDetails && productDetails.brand && Object.keys(productDetails).length !== 0) {
      if (productDetails.brand.BrandId && data?.length > 0) {
        fetchVatOptions(productDetails.brand.BrandId);
      }
    }
  }, [siteId, productDetails, data]);

  React.useEffect(() => {
    const filteredRows = allRows.filter((obj) => obj.VariantId.toString().includes(filterString)
      || obj.Ean?.includes(filterString)
      || obj.VariantSources?.some(({ Source, Sku, VariantSourceStockLocations }) => Source?.includes(filterString)
        || Sku?.includes(filterString)
        || VariantSourceStockLocations?.some((innerRow) => innerRow.Location?.includes(filterString))));
    setRows(filteredRows);
  }, [filterString, allRows]);

  React.useEffect(() => {
    // Initialize the approval status for each variant from the data prop
    if (data.length !== 0 && data !== undefined) {
      const initialApprovalStatus = data.reduce(
        (status, variant) => ({
          ...status,
          [variant.VariantId]: variant.Approved,
        }),
        {},
      );
      setApprovalStatus(initialApprovalStatus);
      const sourcesSet = data.reduce(
        (values, curr) => {
          const x = curr.VariantSources?.map((obj) => obj.Source);
          x.forEach((str) => {
            values.add(str);
          });
          return values;
        },
        new Set(),
      );
      setProductSources(Array.from(sourcesSet));
      setRows(data);
      setAllRows(data);
    }
  }, [data, setProductStyleRerender]);

  const cellFormatting = (key, value, obj, VariantId) => {
    switch (key) {
      case 'Updated':
        if (value) {
          return new Date(value).toLocaleString();
        }
        return '';
      case 'IsOms': {
        if (value) {
          return <CheckIcon color="success" />;
        }
        if (value !== 1 && value !== null) {
          <CloseIcon color="error" />;
        }
        return <div />;
      }
      case 'IsStockEnabled':
        if (obj.IsStockEnabled === true || obj.IsStockEnabled === false) {
          return (
            <Switch
              checked={value}
              onChange={() => {
                setOpenEnabledModal(true);
                setSelectedVariant({
                  VariantId,
                  SourceLocationId: [obj.SourceLocationId],
                  Status: obj.IsStockEnabled,
                });
              }}
            />
          );
        }
        return value;
      default:
        return value;
    }
  };
  const restrictInput = (event) => {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };
  const handleVariantApproval = async (variantId, approved) => {
    try {
      await patchVariants(id, siteId, !approved, variantId);
      // Update the approval status after the patch operation is successful
      setApprovalStatus((prevStatus) => ({
        ...prevStatus,
        [variantId]: !approved,
      }));
      // Update the state after the patch operation is successful
      // eslint-disable-next-line max-len
      setAllRows((prevRows) => prevRows.map((row) => (row.VariantId === variantId ? { ...row, Approved: !approved } : row)));
      setProductStyleRerender(true);
    } catch (error) {
      console.error('Error patching variants:', error);
    }
  };

  const handleUpdate = async () => {
    const requestBody = {
      VatList: vatRows,
      PriceList: priceRows,
    };
    try {
      setPatchLoading(true);
      const res = await patchPriceAndVat(id, siteId, requestBody);
      if (res) {
        toast.success('Successfully updated variant');
      }
    } catch (error) {
      console.error('Error updating variant:', error);
    } finally {
      setPriceRows([]);
      setVatRows([]);
      setPatchLoading(false);
    }
  };

  const handleCancel = () => {
    setRows([]);
    setAllRows([]);
    setIsValidRrpByVariantPriceId({});
    setPriceRows([]);
    setVatRows([]);
    setVariantReRender(!variantReRender);
  };

  const handleEnableStockChange = () => {
    setEnableLoading(true);
    patchVariantStockEnabled(id, siteId, selectedVariant.VariantId, selectedVariant.SourceLocationId, !selectedVariant.Status)
      .then(() => {
        toast.success('Variants stock enabled updated', { autoClose: 2000 });
        setEnableLoading(false);
        setOpenEnabledModal(false);
        const updatedRows = rows.map((row) => {
          if (row.VariantId === selectedVariant.VariantId) {
            const updatedVariantSources = row.VariantSources.map((variantSource) => {
              const updatedStockLocations = variantSource.VariantSourceStockLocations.map((stockLocation) => {
                if (stockLocation.SourceLocationId === selectedVariant.SourceLocationId[0]) {
                  return { ...stockLocation, IsStockEnabled: !selectedVariant.Status };
                }
                return stockLocation;
              });
              return { ...variantSource, VariantSourceStockLocations: updatedStockLocations };
            });
            return { ...row, VariantSources: updatedVariantSources };
          }
          return row;
        });
        setRows(updatedRows);
        setProductStyleRerender(true);
      })
      .catch(() => {
        setEnableLoading(false);
        setOpenEnabledModal(false);
      });
  };

  const handleRrpChange = (key, VariantId, obj, variantSources, e) => {
    const rrpPrice = parseFloat(e.target.value);
    if (variantSources.length > 0) {
      variantSources.forEach((src) => {
        if (src.SourceTypeId === 2) {
          src.VariantSourceStockLocations.forEach((stk) => {
            if (obj.variantPriceId === stk.variantPriceId) {
              const sellingPrice = Math.floor(stk.SellingPrice);
              const minValidRrpPrice = sellingPrice;
              const maxValidRrpPrice = sellingPrice + 1;
              const rrpIntegerPart = Math.floor(rrpPrice);
              const rrpDecimalPart = rrpPrice - rrpIntegerPart;
              const isValidValue = (rrpIntegerPart === minValidRrpPrice && rrpDecimalPart >= 0)
                || (rrpIntegerPart === maxValidRrpPrice && rrpDecimalPart <= 1)
                || (rrpIntegerPart > minValidRrpPrice && rrpIntegerPart < maxValidRrpPrice);
              setIsValidRrpByVariantPriceId((prev) => ({
                ...prev,
                [obj.variantPriceId]: isValidValue,
              }));
            }
          });
          const VariantPriceId = obj.variantPriceId;
          const newRow = {
            variantId: VariantId,
            variantPriceId: VariantPriceId,
            rrpPrice,
          };
          setPriceRows((prevPriceRows) => {
            const index = prevPriceRows.findIndex((option) => option.variantPriceId === VariantPriceId);
            if (index !== -1) {
              const updatedOptions = [...prevPriceRows];
              updatedOptions[index] = newRow;
              return updatedOptions;
            }
            return [...prevPriceRows, newRow];
          });
        }
      });
    }
  };

  const [stockCount, setStockCount] = React.useState(new Map());

  const getSelectedVat = async (e, VariantId, VariantSiteId, variantSources) => {
    if (variantSources.length > 0) {
      const targetRow = rows.find((row) => row.VariantId === VariantId);
      if (targetRow) {
        // Update VatCode based on e
        const VatCode = e !== '0%' ? 'True' : 'False';

        // Update VatDescription in VariantSourceStockLocations
        targetRow.VariantSources.forEach((source) => {
          source.VariantSourceStockLocations.forEach((location) => {
            location.VatDescription = e;
            location.VatCode = VatCode;
          });
        });
      }
      // Create an array to hold the promises
      const promises = variantSources.map(async (src) => {
        if (src.SourceTypeId === 2) {
          const vatCode = e !== '0%' ? 'True' : 'False';
          const oldVatCodeObj = vatOptions.filter((obj) => obj.Value !== e);
          const oldVatCode = oldVatCodeObj[0].Value;

          const newRow = {
            variantId: VariantId,
            vatCode,
            oldVatCode,
            newVatCode: e,
          };

          setVatRows((prevVatRows) => {
            const index = prevVatRows.findIndex((option) => option.variantId === VariantId);
            if (index !== -1) {
              const updatedOptions = prevVatRows.filter((option) => option.variantId !== VariantId);
              return [...updatedOptions];
            }
            return [...prevVatRows, newRow];
          });
        }
      });

      // Wait for all promises to resolve
      await Promise.all(promises);
    }
  };

  const handleStockCount = () => {
    const finalCounts = new Map();
    const total = rows.reduce(
      (partialSum, obj) => partialSum
        + obj.VariantSources
          .reduce(
            (ps, obj2) => ps
              + obj2.VariantSourceStockLocations.reduce(
                (a, b) => a + b.StockAllocation,
                0,
              ),
            0,
          ),
      0,
    );

    if (productSources.includes('Shogun (Shogun)')) {
      productSources.unshift('Shogun (Shogun)');
    }

    let newProductSources = [];
    if (productSources.some((productSource) => productSource?.includes('Fluent'))) {
      newProductSources = [...productSources.filter((a) => !a.includes('Fluent')), ...productSources.filter((x) => x.includes('Fluent'))];
    } else {
      newProductSources = productSources;
    }

    for (let index = 0; index < newProductSources.length; index++) {
      const source = newProductSources[index];
      finalCounts.set(source, new Map());
    }

    rows.forEach((row) => {
      row.VariantSources.forEach((source) => {
        const sourceMap = finalCounts.get(source.Source);
        source.VariantSourceStockLocations.forEach((location) => {
          const locationCount = sourceMap?.get(location.Location);
          if (locationCount) {
            finalCounts.set(source.Source, sourceMap?.set(location.Location, (location.StockAllocation + locationCount)));
          } else if (location.Location) {
            finalCounts.set(source.Source, sourceMap?.set(location.Location, location.StockAllocation));
          }
        });
      });
    });

    newProductSources.forEach((source) => {
      const sourceMap = finalCounts.get(source);
      const sourceCount = rows.reduce(
        (partialSum, obj) => partialSum
          + obj.VariantSources
            .filter((src) => src.Source === source)
            .reduce(
              (ps, obj2) => ps
                + obj2.VariantSourceStockLocations.reduce(
                  (a, b) => a + b.StockAllocation,
                  0,
                ),
              0,
            ),
        0,
      );
      sourceMap.set('Total', sourceCount);
    });

    finalCounts.set('Total', total);
    setStockCount(finalCounts);
  };

  React.useEffect(() => {
    handleStockCount();
  }, [rows, productSources]);

  return (
    <>
      {openEnabledModal && (
        <Modal
          title={`Are you sure you want to ${selectedVariant?.Status ? 'Disable' : 'Enable'} this location?`}
          open={openEnabledModal}
          onClose={() => setOpenEnabledModal(false)}
          buttonLabel="Confirm"
          buttonOnClick={() => {
            handleEnableStockChange();
          }}
        >
          {enableLoading ? (
            <div className="flex items-center justify-center h-16">
              <CircularProgress color="primary" />
            </div>
          ) : (
            <p>{`Are you sure you want to update the variant ${selectedVariant?.VariantId}?`}</p>
          )}
        </Modal>
      )}
      {openApprovedModal && (
        <Modal
          title={`${selectedVariantIdStatus ? 'Unapprove' : 'Approve'} variant ${selectedVariantId}?`}
          open={openApprovedModal}
          onClose={() => setOpenApprovedModal(false)}
          buttonLabel={`${selectedVariantIdStatus ? 'Unapprove' : 'Approve'}`}
          buttonOnClick={() => {
            handleVariantApproval(selectedVariantId, selectedVariantIdStatus);
            setOpenApprovedModal(false);
          }}
        >
          <p>
            {`This will only ${selectedVariantIdStatus ? 'unapprove' : 'approve'} variant ${selectedVariantId}.
          None of the other variants in this product will be affected.`}
          </p>
        </Modal>
      )}
      <div className="flex items-end justify-between w-full pb-4 mb-4 border-b">
        {/* <ExportButton
          rows={rows.map((obj) => obj.VariantSources.map((x) => ({ sku: x.Sku, source: x.Source }))).flat()}
          columns={[
            { headerName: 'source', field: 'source' },
            { headerName: 'sku', field: 'sku' },
          ]}
        /> */}
        <TextField
          label="Filter"
          size="small"
          onChange={(e) => setFilterString(e.target.value)}
          helperText="Filter by Variant Id, EAN, Source, SKU and Location"
        />
        <div className="flex">
          {Object.entries(Object.fromEntries(stockCount)).map(([key, value]) => (
            <table className="block pr-8 mr-5 border-r last:border-r-0 last:pr-0">
              <thead>
                <tr>
                  <th className="text-left">
                    {key}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {typeof value === 'object' ? Object.entries(Object.fromEntries(value)).map(([location, value2]) => (
                      <p>
                        {location}
                        {': '}
                        {value2}
                      </p>
                    )) : (
                      <p className="text-center">
                        <strong>{value}</strong>
                      </p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </div>
      <div className={`overflow-auto border rounded ${loading ? 'h-max' : 'h-[calc(100vh-180px)]'}`}>
        <table className="w-full bg-white h-fit">
          <thead className="sticky top-0 z-30 bg-white outline outline-1 outline-neutral-300">
            <tr>
              <th
                className={`p-4 hover:bg-primary-100 hover:cursor-pointer w-32 sticky left-0
                ${highlightedRow === 'VariantId' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : 'bg-white outline outline-1 outline-neutral-300'}`}
                onClick={() => {
                  if (highlightedRow !== 'VariantId') {
                    setHighlightedRow('VariantId');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                Variant Id
              </th>
              <th
                className={`p-4 hover:bg-primary-100 hover:cursor-pointer w-16
                ${highlightedRow === 'Size' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                onClick={() => {
                  if (highlightedRow !== 'Size') {
                    setHighlightedRow('Size');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                Size
              </th>
              <th
                className={`p-4 hover:bg-primary-100 hover:cursor-pointer w-36
                ${highlightedRow === 'Ean' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                onClick={() => {
                  if (highlightedRow !== 'Ean') {
                    setHighlightedRow('Ean');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                EAN
              </th>
              <th
                className={`p-4 hover:bg-primary-100 hover:cursor-pointer w-40
                ${highlightedRow === 'Source' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                onClick={() => {
                  if (highlightedRow !== 'Source') {
                    setHighlightedRow('Source');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                Source
              </th>
              <th
                className={`p-4 hover:bg-primary-100 hover:cursor-pointer w-40
                ${highlightedRow === 'Sku' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                onClick={() => {
                  if (highlightedRow !== 'Sku') {
                    setHighlightedRow('Sku');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                SKU
              </th>
              <th
                className={`p-4 hover:bg-primary-100 hover:cursor-pointer w-40
                ${highlightedRow === 'Location' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                onClick={() => {
                  if (highlightedRow !== 'Location') {
                    setHighlightedRow('Location');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                Location
              </th>
              <th
                className={`p-4 hover:bg-primary-100
                hover:cursor-pointer w-40 ${highlightedRow === 'Actual Stock' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                onClick={() => {
                  if (highlightedRow !== 'Actual Stock') {
                    setHighlightedRow('Actual Stock');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                Actual Stock
              </th>
              {productStatus.oms && (
                <th
                  className={`p-4 hover:bg-primary-100
                  hover:cursor-pointer w-40 ${highlightedRow === 'Fluent Stock' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                  onClick={() => {
                    if (highlightedRow !== 'Fluent Stock') {
                      setHighlightedRow('Fluent Stock');
                    } else {
                      setHighlightedRow('');
                    }
                  }}
                >
                  Fluent Stock
                </th>
              )}
              {columns.map((columnObj) => !columnObj.hidden && (
                <th
                  key={columnObj.label}
                  className={`p-4 hover:bg-primary-100 hover:cursor-pointer ${columnObj.id === 'Updated' ? 'w-28' : 'w-28'}
                  ${columnObj.id === 'approved' ? 'w-32' : ''}
                  ${columnObj.id === 'Rrp' || columnObj.id === 'SellingPrice' || columnObj.id === 'CostPrice'}
                  ${highlightedRow === columnObj.id ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                  onClick={() => {
                    if (highlightedRow !== columnObj.id) {
                      setHighlightedRow(columnObj.id);
                    } else {
                      setHighlightedRow('');
                    }
                  }}
                >
                  {columnObj.label}
                </th>
              ))}
              {productIdentifiers.plus?.some((obj) => obj.Source === 'ChannelAdvisor' || obj.Source.includes('Anatwine')) && (
                <th
                  className={`p-4 hover:bg-primary-100
                  hover:cursor-pointer w-16 ${highlightedRow === 'VatCode' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                  onClick={() => {
                    if (highlightedRow !== 'VatCode') {
                      setHighlightedRow('VatCode');
                    } else {
                      setHighlightedRow('');
                    }
                  }}
                >
                  Vat Code
                </th>
              )}
              <th
                className={`p-4 hover:bg-primary-100
                hover:cursor-pointer w-16 ${highlightedRow === 'Approved' ? 'bg-primary-100 outline outline-1 outline-neutral-300' : ''}`}
                onClick={() => {
                  if (highlightedRow !== 'Approved') {
                    setHighlightedRow('Approved');
                  } else {
                    setHighlightedRow('');
                  }
                }}
              >
                Approved
              </th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td aria-label="Circular progress" colSpan={columns.length + 9} className="py-4 text-center">
                  <CircularProgress />
                </td>
              </tr>
            )}
            {!loading && rows.length === 0 && (
              <tr>
                <td colSpan={columns.length + 9} className="py-4 text-center">
                  <p>No Variants Available</p>
                </td>
              </tr>
            )}
            {rows?.map(({
              VariantId,
              VariantSiteId,
              Size,
              Ean,
              VariantSources,
              Approved,
            }, rowIndex) => (
              <tr key={VariantId} className="border-b border-collapse border-spacing-4 even:bg-neutral-100">
                {/* Variant Id */}
                <td className={`text-center first:sticky
                first:left-0 outline outline-1
                outline-neutral-300 ${highlightedRow === 'VariantId' ? 'bg-primary-100' : ''} ${rowIndex % 2 === 0 ? 'bg-white' : 'bg-neutral-100'}`}
                >
                  <p className="text-sm">
                    {VariantId}
                  </p>

                </td>
                {/* Size */}
                <td className={`text-center border-b border-r text-sm ${highlightedRow === 'Size' ? 'bg-primary-100 border-r border-l' : ''}`}>
                  {Size}
                </td>
                {/* EAN */}
                <td className={`text-center border-b border-r ${highlightedRow === 'Ean' ? 'bg-primary-100 border-r border-l' : ''}`}>
                  <p className="text-sm">{Ean}</p>
                </td>
                {/* Source */}
                <td>
                  {VariantSources.map(({ Source, VariantSourceStockLocations }, index) => (
                    <div
                      key={Source}
                      className={`flex items-center border-r pl-2 text-sm ${highlightedRow === 'Source' ? 'bg-primary-100 border-r border-l' : ''}`}
                      style={{
                        borderBottom: index !== (VariantSources.length - 1) ? '1px solid #e5e5e5' : '0',
                        height: DEFAULT_MIN_CELL_HEIGHT * VariantSourceStockLocations.length,
                        minHeight: DEFAULT_MIN_CELL_HEIGHT,
                      }}
                    >
                      <p className="text-sm">{Source}</p>
                    </div>
                  ))}
                </td>
                {/* SKUs */}
                <td>
                  {VariantSources.map(({ Sku, VariantSourceStockLocations }, index) => (
                    <div
                      key={Sku}
                      className={`flex items-center border-r pl-2 text-sm ${highlightedRow === 'Sku' ? 'bg-primary-100 border-r border-l' : ''}`}
                      style={{
                        borderBottom: index !== (VariantSources.length - 1) ? '1px solid #e5e5e5' : '0',
                        height: DEFAULT_MIN_CELL_HEIGHT * VariantSourceStockLocations.length,
                        minHeight: DEFAULT_MIN_CELL_HEIGHT,
                      }}
                    >
                      <p className="text-sm">{Sku}</p>
                    </div>
                  ))}
                </td>
                {/* Location */}
                <td>
                  {VariantSources.map(({ VariantSourceStockLocations }) => (
                    <div
                      style={{
                        boxSizing: 'border-box',
                      }}
                    >
                      {VariantSourceStockLocations.map((obj) => (
                        <p
                          className={`
                              flex flex-col justify-center items-center
                              border-b border-r
                              p-2
                              text-xs
                              ${highlightedRow === 'Location' ? 'bg-primary-100 border-r border-l' : ''}
                            `}
                          style={{
                            minHeight: DEFAULT_MIN_CELL_HEIGHT,
                            height: DEFAULT_MIN_CELL_HEIGHT / VariantSourceStockLocations.length,
                          }}
                        >
                          {cellFormatting('Location', obj.Location)}
                        </p>
                      ))}
                    </div>
                  ))}
                </td>
                {/* Actual Stock */}
                <td>
                  {VariantSources.map(({ VariantSourceStockLocations }) => (
                    <div
                      style={{
                        boxSizing: 'border-box',
                      }}
                    >
                      {VariantSourceStockLocations.map((obj) => (
                        <p
                          className={`
                              flex flex-col justify-center items-center
                              border-b border-r
                              p-2
                              text-sm
                              ${highlightedRow === 'Actual Stock' ? 'bg-primary-100 border-r border-l' : ''}
                            `}
                          style={{
                            minHeight: DEFAULT_MIN_CELL_HEIGHT,
                            height: DEFAULT_MIN_CELL_HEIGHT / VariantSourceStockLocations.length,
                          }}
                        >
                          {obj.IsOms !== null ? cellFormatting('Actual Stock', obj.StockAllocation) : ''}
                        </p>
                      ))}
                    </div>
                  ))}
                </td>
                {/* Fluent Stock */}
                {productStatus.oms && (
                  <td>
                    {VariantSources.map(({ Source, VariantSourceStockLocations }) => (
                      <div
                        style={{
                          boxSizing: 'border-box',
                        }}
                      >
                        {VariantSourceStockLocations.map((obj) => (
                          <p
                            className={`
                              flex flex-col justify-center items-center
                              border-b border-r
                              p-2
                              text-sm
                              ${highlightedRow === 'Fluent Stock' ? 'bg-primary-100 border-r border-l' : ''}
                            `}
                            style={{
                              minHeight: DEFAULT_MIN_CELL_HEIGHT,
                              height: DEFAULT_MIN_CELL_HEIGHT / VariantSourceStockLocations.length,
                            }}
                          >
                            {!Source.toLowerCase().includes('oms') ? '' : cellFormatting('Fluent Stock', obj.StockAllocation)}
                          </p>
                        ))}
                      </div>
                    ))}
                  </td>
                )}
                {/* Other Rows */}
                {columns.map((columnObj) => !columnObj.hidden && (
                  <td key={columnObj.id}>
                    {VariantSources.map(({ Source, VariantSourceStockLocations }) => (
                      <div
                        style={{
                          boxSizing: 'border-box',
                        }}
                      >
                        {VariantSourceStockLocations.map((obj) => (
                          <p
                            className={`
                              flex flex-col justify-center items-center
                              border-b border-r
                              p-2
                              text-sm
                              ${highlightedRow === columnObj.id ? 'bg-primary-100 border-r border-l' : ''}
                            `}
                            style={{
                              minHeight: DEFAULT_MIN_CELL_HEIGHT,
                              height: DEFAULT_MIN_CELL_HEIGHT / VariantSourceStockLocations.length,
                            }}
                          >
                            {columnObj.id === 'Rrp' && Source.includes('Anatwine') ? (
                              <div>
                                <input
                                  className="border-b border-r p-2 text-sm w-[80px] text-center"
                                  type="number"
                                  size="small"
                                  defaultValue={obj[columnObj.id]}
                                  onKeyPress={restrictInput}
                                  onChange={(e) => {
                                    handleRrpChange(columnObj.id, VariantId, obj, VariantSources, e);
                                  }}
                                />
                                <p className="text-[0.65rem] leading-3 text-red-600">
                                  {isValidRrpByVariantPriceId[obj.variantPriceId] === false
                                    ? `Enter value between ${Math.floor(obj.Rrp)}-${Math.ceil(obj.Rrp) + (Number.isInteger(obj.Rrp) ? 1 : 0)}`
                                    : ''}
                                </p>
                              </div>
                            ) : (
                              cellFormatting(columnObj.id, obj[columnObj.id], obj, VariantId)
                            )}
                          </p>
                        ))}
                      </div>
                    ))}
                  </td>
                ))}
                {/* VatCode */}
                {productIdentifiers.plus?.some((obj) => obj.Source === 'ChannelAdvisor' || obj.Source.includes('Anatwine')) && (
                  <td className={`text-center border-b border-r text-sm ${highlightedRow === 'VatCode' ? 'bg-primary-100 border-r border-l' : ''}`}>
                    {VariantSources.some(({ Source }) => Source.includes('Anatwine') || Source.includes('ChannelAdvisor')) ? (
                      <div className="mx-2">
                        {vatOptions.length > 0 ? (
                          <Select
                            className="text-sm bg-white test"
                            value={VariantSources.flatMap(({ VariantSourceStockLocations }) => VariantSourceStockLocations.map((location) => location.VatDescription)).find((desc) => desc) || '0%'}
                            onChange={(event) => {
                              getSelectedVat(event.target.value, VariantId, VariantSiteId, VariantSources);
                            }}
                            size="small"
                          >
                            {vatOptions?.map((option) => (
                              <MenuItem key={option.Id} value={option.Value}>
                                {option.Value}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <p className="text-sm">{VariantSources.flatMap(({ VariantSourceStockLocations }) => VariantSourceStockLocations.map((location) => location.VatDescription)).find((desc) => desc) || '0%'}</p>
                        )}
                      </div>
                    ) : (
                      <p className="text-sm">N/A</p>
                    )}
                  </td>
                )}
                {/* Approved */}
                <td className={`text-center border-b border-r text-sm ${highlightedRow === 'Approved' ? 'bg-primary-100 border-r border-l' : ''}`}>
                  <Button
                    color={Approved ? 'success' : 'error'}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setSelectedVariantId(VariantId);
                      setSelectedVariantIdStatus(approvalStatus[VariantId]);
                      setOpenApprovedModal(true);
                    }}
                  >
                    {Approved ? 'Approved' : 'Unapproved'}
                  </Button>
                </td>
              </tr>
            ))}
            {patchLoading ? (
              <div className="flex flex-row items-center m-4">
                <tr>
                  <td aria-label="Circular progress" colSpan={columns.length + 9}>
                    <CircularProgress />
                  </td>
                </tr>
              </div>
            ) : (
              <div className="flex flex-row items-center m-4">
                <Button
                  onClick={handleUpdate}
                  variant="contained"
                  className="mr-2 cursor-pointer max-h-11"
                  disabled={priceRows.length < 1 && vatRows.length < 1}
                >
                  Update
                </Button>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  className="cursor-pointer max-h-11"
                  color="error"
                  disabled={priceRows.length < 1 && vatRows.length < 1}
                >
                  Cancel
                </Button>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SkuVariantsTable;
