import 'react-quill/dist/quill.snow.css';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataDescriptionObject,
} from '../../../../../redux/wizard/nike/nike_wizard';

function TranslationEditor({ translations, country, onChange }) {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    const foundTranslation = translations
      .find((translation) => translation.country_code === country)?.translation;
    if (foundTranslation) {
      setValue(foundTranslation);
    }
  }, [translations]);

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      key={`description-translation-${country}`}
    >
      <img
        loading="lazy"
        width="40"
        src={`https://flagcdn.com/w40/${country.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`}
        alt=""
      />
      <ReactQuill
        value={value}
        theme="snow"
        onChange={(newValue) => {
          setValue(newValue);
          onChange(newValue);
        }}
        modules={{
          clipboard: {
            matchVisual: false,
          },
        }}
      />
    </Stack>
  );
}

function ProductDescription({ sites }) {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const { activeStep, product_description } = nike_wizard.productData;

  const reducer = (state, action) => {
    switch (action.type) {
      case 'PRIMARY_DESCRIPTION':
        return {
          ...state,
          description: action.payload,
        };
      case 'TRANSLATION': {
        const stateClone = structuredClone(state);
        const newTranslations = stateClone.translations
          .map((translation) => {
            if (translation.country_code === action.payload.country) {
              return {
                ...translation,
                translation: action.payload.value,
              };
            }
            return translation;
          });
        return {
          ...state,
          translations: newTranslations,
        };
      }
      case 'SET_FULL':
        return {
          ...action.payload,
        };
      default:
        return state;
    }
  };
  const initialState = {
    description: '',
    translations: [],
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const Block = Quill.import('blots/block');
    Block.tagName = 'div';
    Quill.register(Block);
    const reduxWithAddedSites = {
      ...product_description,
      translations: sites.map((site) => site.countries.map((country) => ({
        translation: product_description.translations.find((item) => item.country_code
          === country)?.translation || '',
        country_code: country,
      }))).flat(),
    };
    dispatch({ type: 'SET_FULL', payload: reduxWithAddedSites });
    dispatchToRedux(setProductDataDescriptionObject(reduxWithAddedSites));
  }, []);

  const onTranslationChange = (value, country) => {
    dispatch({ type: 'TRANSLATION', payload: { value, country } });
  };

  return (
    <section>
      <Typography
        variant="h5"
        className="border-b font-gothammedium text-neutral-800 border-neutral-300"
        gutterBottom
      >
        Product Description
      </Typography>
      <Typography variant="body1" className="text-neutral-800" marginBottom={4}>
        If translations don&apos;t load please click the translate button.
      </Typography>
      <Stack spacing={4}>
        <ReactQuill
          value={state?.description}
          theme="snow"
          onChange={(value) => dispatch({ type: 'PRIMARY_DESCRIPTION', payload: value })}
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
        />
        {sites.map((site) => site.countries.map((country) => country !== 'GB' && (
          <TranslationEditor
            key={country}
            translations={state?.translations}
            country={country}
            onChange={(value) => onTranslationChange(value, country)}
          />
        )))}
      </Stack>
      <Stack direction="row" spacing={2} className="float-right my-4">
        <Button
          variant="text"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep - 1));
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep + 1));
            dispatchToRedux(setProductDataDescriptionObject(state));
            if (state?.description !== product_description.description) {
              dispatchToRedux(setEditedValues('description'));
            }
            const stateTranslations = state?.translations;
            const reduxTranslations = product_description.translations;
            stateTranslations.forEach((componentTranslationState) => {
              const reduxTranslation = reduxTranslations
                .find((redux) => componentTranslationState.country_code === redux.country_code);
              if (state?.translation !== reduxTranslation?.translation) {
                dispatchToRedux(setEditedValues(`descriptionTranslation-${reduxTranslation.country_code}`));
              }
            });
          }}
        >
          Confirm
        </Button>
      </Stack>
    </section>
  );
}

export default ProductDescription;
