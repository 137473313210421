import PropTypes from 'prop-types';

/**
 * Gets value from session storage.
 *
 * @param {string} name Name of the key.
 * @returns {Object} Returns requested item.
 */
function getSessionStorage(name) {
  let storedValue = sessionStorage.getItem(name) || null;
  if (storedValue?.slice(0, 1) === '{' || Array.isArray(storedValue)) {
    storedValue = JSON.parse(storedValue);
  }
  return storedValue;
}

getSessionStorage.propTypes = {
  name: PropTypes.string.isRequired,
  storedValue: PropTypes.string || PropTypes.shape({}),
};

/**
 * Sets key and value to session storage.
 *
 * @param {string} name Name of the key.
 * @param {object} value Value of data wanting to be stored in session storage.
 */
function setSessionStorage(name, value) {
  let incomingValue = value;
  if (typeof value === 'object') incomingValue = JSON.stringify(value);
  sessionStorage.setItem(name, incomingValue);
}

setSessionStorage.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string || PropTypes.shape({}),
};

/**
 * Removes key from session storage.
 *
 * @param {string} name
 */
function removeSessionStorage(name) {
  sessionStorage.removeItem(name);
}

removeSessionStorage.propTypes = {
  name: PropTypes.string.isRequired,
};

export { getSessionStorage, removeSessionStorage, setSessionStorage };
