import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queries: '',
};

const queriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    setQueries: (state, action) => {
      state.queries = action.payload;
    },
  },
});

export const { setQueries } = queriesSlice.actions;

export default queriesSlice.reducer;
