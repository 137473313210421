import getRequest from '../../../getRequest';

/**
 * Get media links.
 * @param {number} siteId
 * @param {number} productId
 */
export default async function getMediaCheck(siteId, productId) {
  const endpoint = `/oc-product/v1/product-media-link?productId=${productId}&siteId=${siteId}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'GET')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
