import postRequest from '../../../../postRequest';
/**
 * Post request for colour Matching.
 *
 * @param {number} languageId - 12
 * @param {string} ColourName
 * @param {string} TranslatedColourName
 */

export default function postSwatchColour(
  languageId,
  ColourName,
  TranslatedColourName,
) {
  const endpoint = `/oc-data-management/v1/colourMatching/${languageId}`;
  const swatchOrTranslatedColour = languageId === 1 ? 'Swatch Colour' : 'Translated Colour';
  const body = {
    ColourName,
    TranslatedColourName,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, swatchOrTranslatedColour)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
