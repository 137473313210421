import postRequest from '../../../postRequest';

/**
 * Post request for move styles.
 *
 * @param {number[]} productIds
 * @param {number} styleIds Page size
 *
 */
function postMoveStyles(
  productIds,
  styleId,
) {
  const endpoint = '/oc-data-management/v1/styles/move';

  const body = {
    styleId,
    productIds,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Styles')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postMoveStyles;
