import deleteRequest from '../../../../../deleteRequest';
/**
 *  delete Categories from Product.
 *
 * @param {{
 *  productId: number,
 *  categoryIds: number[],
 *  siteId: number,
 * }}
 * @param {number} productId Product Id
 * @param {number[]} categoryIds Categories Id
 * @param {number} siteId Site Id
*/
export default async function removeProductCategories({
  productId,
  categoryIds,
  siteId,
}) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/remove-product-categories`;

  const body = {
    categoryIds,
  };

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, 'Remove Category')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
