import getRequest from '../../../../getRequest';

/**
 * Get all Site Source Locations for the given site id from PRISM db.
 *
 * @param {number} siteId
 * @returns {[
 *   {
 *     sourceLocationId: number,
 *     locationRef: string,
 *     description: string,
 *     userFriendlyDescription: string,
 *     sourceId: number,
 *   }
 * ]}
 */
function getSourceLocations(siteId) {
  const endpoint = `/oc-product/v1/sites/${siteId}/source-locations`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Source Locations')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getSourceLocations;
