import React from 'react';

function Release20231206() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">
        Bug Fixes and Improvements
      </h2>
      <p className="-mt-4">6th December 2023</p>

      <h3 className="font-gothammedium text-neutral-700">
        Product Page (PDP)
      </h3>
      <h4>
        Improvements:
      </h4>
      <ol>
        <li>
          <strong>
            Release Date
          </strong>
          <ul>
            <li>
              Added the ability to update the release date directly on the product page
            </li>
          </ul>
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Product Listing Page (PLP)
      </h3>
      <h4>
        Improvements:
      </h4>
      <ol>
        <li>
          <strong>
            New In Date
          </strong>
          <ul>
            <li>
              Added the product new in date to the product listing table
            </li>
          </ul>
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Bulk Operations
      </h3>
      <h4>
        Speed Increase
      </h4>
      <ol>
        <li>
          <strong>
            600% speed Decrease to Bulk Operation Transaction Times 20 + minutes for 10 000 Products
            {' -> '}
            Sub 3 minutes for 10 000+ Products
          </strong>
          <ul>
            <li>
              Approve
            </li>
            <li>
              Attributes
            </li>
            <li>
              Campaign
            </li>
            <li>
              Categories
            </li>
            <li>
              Collection
            </li>
            <li>
              Multi-Select Attributes
            </li>
            <li>
              Primary categories
            </li>
            <li>
              Product Name
            </li>
            <li>
              Single Select
            </li>
          </ul>
        </li>
      </ol>
      <h4>
        Improvements
      </h4>
      <ul>
        <li>
          Added template download to bulk operations
        </li>
        <li>
          UI improvement to show total counts of each operation in the bulk process
        </li>
        <li>
          Updated the important text for the bulk upload pages
        </li>
      </ul>
      <h4>
        Added new Bulk Operations
      </h4>
      <ul>
        <li>
          New In Date
        </li>
        <li>
          VAT
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Improvements
      </h3>
      <ul>
        <li>
          Migrated from Diagnostic API to API Sender when sending products to fluent
        </li>
        <li>
          Updated the price threshold logic when users enable fulfilment for NIKE agent model products
        </li>
        <li>
          Add Total transaction time time to Bulk Uploads (More Info) Logs
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Bug Fixes
      </h3>
      <ul>
        <li>
          Spelling of channel advisor to match response and show correct data
        </li>
        <li>
          Error when successVariantIds was null
        </li>
        <li>
          Enable stock toast errors on PDP
        </li>
        <li>
          Empty lines/rows are getting displayed on the PLP page when selecting a Hide All option
        </li>
        <li>
          Group level header text should display as Site Level for Release dates for all sites including group sites
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Upcoming Features
      </h3>
      <ul>
        <li>
          (New Page) Brand Grouping
        </li>
        <li>
          Remaining Speed Improvements
          <ol>
            <li>
              Release Dates
            </li>
            <li>
              New In Dates
            </li>
            <li>
              Product Type
            </li>
            <li>
              Enable Stock
            </li>
          </ol>
        </li>
      </ul>
    </section>
  );
}

export default Release20231206;
