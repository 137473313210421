import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useState } from 'react';
import Tridi from 'react-tridi';

function ImageViewer() {
  const [pluId, setPluId] = useState('');
  const [selectedSiteOption, setSelectedSiteOption] = useState('jd');
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mainimage, setMaiImage] = useState('');
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [video, setVideo] = useState([]);
  const [spinSets, setSpinSets] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const removeQueryParam = (key) => {
    const url = new URL(window.location.href); // Get the current URL
    url.searchParams.delete(key); // Remove the specified query parameter
    window.history.replaceState({}, '', url.toString()); // Update the URL without reloading
  };
  React.useEffect(() => {
    removeQueryParam('siteCode');
  }, []);

  const handlePluChange = (event) => {
    setPluId(event.target.value);
    setSubmitDisabled(false);
  };

  const handleChange = (event) => {
    setSelectedSiteOption(event.target.value);
    setSubmitDisabled(false);
  };
  const getImage = (src) => {
    setMaiImage(src);
  };
  const fetchImageData = async () => {
    setIsLoading(true);
    setError(null);
    setImages([]);
    setMaiImage('');
    setIsLoading(true);
    setSpinSets([]);
    setVideo([]);
    const mediajsonUrl = `https://cdn.media.amplience.net/s/jpl/${selectedSiteOption}_${pluId}_ms.json`;

    try {
      const response = await fetch(mediajsonUrl);
      if (!response.ok) {
        setImages([]);
        setMaiImage('');
        setIsLoading(true);
        setSpinSets([]);
        setVideo([]);
        setError(null);

        throw new Error('');
      }
      const data = await response.json();
      const productImages = data.items.filter((item) => item.type === 'img');
      const productVideo = data.items.find(
        (item) => item.type === 'video',
      );
      const productSpinSet = data.items.filter(
        (item) => item.type === 'set',
      );
      if (productSpinSet.length > 0) {
        const spinSetSrc = productSpinSet[0].src;
        const httpsSpinSet = spinSetSrc?.slice(0, 5) === 'http:'
          ? `https${spinSetSrc.slice(4)}`
          : spinSetSrc;
        const jsonSpinSet = httpsSpinSet.includes('.json')
          ? httpsSpinSet
          : `${httpsSpinSet}.json`;
        const spinSetResponse = await axios.get(jsonSpinSet);
        setSpinSets(spinSetResponse.data);
      } else {
        console.error('No Spin Set Available:', data);
      }
      setImages(productImages);
      setVideo(productVideo);
      if (productImages.length > 0) {
        setMaiImage(productImages[0].src);
      } else {
        setMaiImage('https://cdn.media.amplience.net/i/jpl/imagemissing');
      }
      setIsLoading(false);
    } catch (ex) {
      setError(ex.message);
      setIsLoading(false);
    }
  };
  const resetAll = () => {
    setSelectedSiteOption('jd');
    setPluId('');
    setImages([]);
    setMaiImage('');
    setIsLoading(false);
    setSpinSets([]);
    setVideo([]);
    setSubmitDisabled(true);
    setError(null);
  };
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <section className="w-full">
        <form className="flex flex-col w-full gap-8 xl:flex-row" onSubmit={(e) => { e.preventDefault(); fetchImageData(); }}>
          <section className="flex flex-col w-full gap-8">
            <Typography
              variant="h5"
              className="border-b font-gothammedium text-neutral-800 border-neutral-300"
              gutterBottom
            >
              Image Previewer
            </Typography>
            <div className="flex w-full">
              {/* Step 1: Enter Plu */}
              <div key="step1" className="flex flex-col w-1/3 justify-center items-center">
                <div className="flex items-center gap-2 justify-left w-full">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      1
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Enter Plu
                  </Typography>
                </div>
                <div className="flex items-center gap-2 justify-left w-full">
                  <Box sx={{ paddingTop: '1rem' }}>
                    <FormControl>
                      <TextField
                        id="pluId"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={pluId}
                        onChange={handlePluChange}
                      />
                    </FormControl>
                  </Box>
                </div>
              </div>

              {/* Step 2: Select Facia */}
              <div key="step2" className="flex flex-col w-1/3 justify-center items-center">
                <div className="flex items-center gap-2 justify-left w-full">
                  <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                    <Typography variant="caption" className="font-gothammedium">
                      2
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Select Facia
                  </Typography>
                </div>
                <div className="flex items-center gap-2 justify-left w-full">
                  <Box sx={{ paddingTop: '1rem' }}>
                    <FormControl variant="outlined" sx={{ width: 200 }}>
                      <Select
                        disabled={pluId.length === 0}
                        id="fascia"
                        value={selectedSiteOption}
                        onChange={handleChange}
                        size="small"
                        width="100%"
                      >
                        <MenuItem value="jd">JD Sports</MenuItem>
                        <MenuItem value="bk">Bank</MenuItem>
                        <MenuItem value="sz">Size</MenuItem>
                        <MenuItem value="sc">Scott</MenuItem>
                        <MenuItem value="bl">Blacks</MenuItem>
                        <MenuItem value="fp">Foot Patrol</MenuItem>
                        <MenuItem value="te">Tessuti</MenuItem>
                        <MenuItem value="ar">Ark</MenuItem>
                        <MenuItem value="op">Open</MenuItem>
                        <MenuItem value="hp">Hip Store</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>

              {/* Step 3: Submit Button */}
              <div key="step3" className="flex flex-col w-1/3 justify-center items-center">
                <div hidden="true" className="flex items-center gap-2 justify-left w-full">
                  <div hidden="true" className="flex items-center justify-center w-4 h-4 p-3 text-white">
                    <Typography hidden="true">
                      3
                    </Typography>
                  </div>
                  <Typography hidden="true" variant="body2">
                    Submit the Request
                  </Typography>
                </div>
                <div className="flex items-center gap-2 justify-left w-full">
                  <Box sx={{ paddingTop: '1rem' }}>
                    <FormControl>
                      <Button
                        variant="contained"
                        type="submit"
                        id="submit-button"
                        className="z-10"
                        disabled={submitDisabled}
                      >
                        Submit
                      </Button>
                    </FormControl>
                  </Box>
                  <Box sx={{ paddingTop: '1rem' }}>
                    <FormControl>
                      <Button id="reset-button" type="reset" variant="text" className="text-primary-600" onClick={() => resetAll()}>
                        Reset
                      </Button>
                    </FormControl>
                  </Box>
                </div>
              </div>
            </div>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={(event, value) => setSelectedTab(value)}
                className="bg-white"
              >
                <Tab
                  label="Images"
                  classes={{
                    // root: styles.tabRoot,
                  }}
                />
                <Tab
                  label="Video"
                  classes={{
                    // root: styles.tabRoot,
                  }}
                />
                <Tab
                  label="Spin Set"
                  classes={{
                    // root: styles.tabRoot,
                  }}
                />
              </Tabs>
            </Box>

            {selectedTab === 0 && (
              <div className="wrapper">
                {error && (
                  <p>
                    Error:
                    {error}
                  </p>
                )}
                {isLoading ? <p>Loading...</p> : (
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                    <div style={{ width: '20%', display: 'flex', flexDirection: 'column' }}>
                      {images.length > 0
                        && images.map((item) => (
                          <button
                            type="button"
                            key={item.src}
                            onClick={() => getImage(item.src)}
                            style={{ width: '50%', marginBottom: '10px' }}
                          >
                            <img src={item.src} alt={`View of ${item.description}`} style={{ width: '100%', marginBottom: '10px' }} />
                          </button>
                        ))}
                    </div>
                    <div style={{
                      width: '69%', height: '50%', display: 'flex', justifyContent: 'left',
                    }}
                    >
                      {images.length > 0 ? (
                        <img src={mainimage} alt={`Prominent view of ${images[0].description}`} style={{ width: '50 %', height: 'auto' }} />
                      )
                        : (
                          <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                            <img
                              src="https://cdn.media.amplience.net/i/jpl/imagemissing"
                              aria-hidden
                              alt="Picture of me taking a photo of an image"
                              style={{ width: '100%' }}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* Video */}
            {selectedTab === 1 && (
              <div className="flex items-center justify-center p-2">
                {video?.src ? (
                  <video controls>
                    <track default kind="captions" />
                    <source src={`${video.src}/mp4_720p`} type="video/mp4" />
                    Sorry your browser does not support HTML5 video.
                  </video>
                ) : (
                  <p>No Video Available</p>
                )}
              </div>
            )}
            {/* Spin Set */}
            {selectedTab === 2 && (
              <div className="flex items-center justify-center p-2">
                {spinSets.items ? (
                  <Tridi
                    images={spinSets.items?.map((obj) => obj.src)}
                    hintOnStartup
                    hintText="Drag To View"
                    autoplaySpeed={80}
                    showControlBar
                    className="pb-12"
                  />
                ) : (
                  <p>No Spin Set Available</p>
                )}
              </div>
            )}
          </section>
        </form>
      </section>
    </Container>
  );
}

export default ImageViewer;
