import patchRequest from '../../../../../patchRequest';

/**
 * Calls set-dropshipment endpoint to enable product for dropshipment in PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @param {string} sourceName - Shogun
 * @param {boolean} dropshippable - true
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchSetDropshipment(
  productId,
  siteCode,
  sourceName,
  dropshippable,
) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/set-dropshipment';

  const body = {
    productId,
    siteCode,
    sourceName,
    dropshippable,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Dropshipment')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchSetDropshipment;
