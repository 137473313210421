import getRequest from '../../../../getRequest';

/**
 * Gets all margin thresholds.
 */
async function getMarginThresholds() {
  const endpoint = '/oc-data-management/v1/margin-thresholds';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Getting Margin Thresholds')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getMarginThresholds;
