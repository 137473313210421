import getRequest from '../../../getRequest';

/**
 * Get all Fascias from PRISM db.
 *
 * @returns {[
 *   {
 *    siteGroupId: number,
 *    name: string,
 *    sites: [
 *      {
 *        SiteId: number,
 *        Name: string,
 *        SiteCode: string
 *      }
 *    ]
 *   }
 * ]}
 */
function getFascias() {
  const endpoint = '/oc-product/v1/fascias';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Fascias')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getFascias;
