import getRequest from '../../../getRequest';

/**
 * Get user status mapping from PRISM db.
 *
 * @returns {object}
 */
function getUserStatuses() {
  const endpoint = '/users-management/get-user-statuses/';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get User Statuses')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getUserStatuses;
