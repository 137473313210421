import postRequest from '../../../postRequest';

/**
 * Post request for size mapping.
 *
 * @param {number} siteId - 1
 * @param {string} DepartmentCode
 * @param {number} BrandId
 * @param {string} SizeTo
 * @param {string} SizeFrom
 * @param {number} CountryRegionIdFrom
 * @param {number} CountryRegionIdTo
 * @param {string} SizingAttribute
 */

export default function postSizeMap(
  siteId,
  departmentCode,
  brandId,
  countryRegionIdTo,
  sizeTo,
  countryRegionIdFrom,
  sizeFrom,
  sizingAttribute,
) {
  const endpoint = `/oc-product/v1/size-mapping/${siteId}`;

  const body = {
    departmentCode,
    brandId,
    countryRegionIdTo,
    sizeTo,
    countryRegionIdFrom,
    sizeFrom,
    sizingAttribute,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Size Map')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
