import InfoIcon from '@mui/icons-material/Info';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import getProductTypes from '../../../../api/oc_product/sites/product_types/gets/getProductTypes';

const useStyles = makeStyles({
  paper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
  },
});

function ProductTypesFilter() {
  const classes = useStyles();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const [searchParams, setSearchParams] = useSearchParams();

  const typeIds = searchParams.get('productTypes');
  const hasProductType = searchParams.get('hasProductType');

  const [siteTypes, setSiteTypes] = React.useState([]);
  const [selectedTypeIds, setSelectedTypeIds] = React.useState([]);
  const selectedProductTypesOnly = searchParams.get('selectedProductTypesOnly');

  React.useEffect(() => {
    getProductTypes(siteId)
      .then((res) => setSiteTypes(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);
  React.useEffect(() => {
    if (typeIds) {
      const selected = typeIds.split(',').map((id) => siteTypes.find((obj) => obj.ProductTypeId === Number(id)));
      if (!selected.includes(undefined)) {
        setSelectedTypeIds(selected);
      }
    } else {
      setSelectedTypeIds([]);
    }
  }, [typeIds, siteTypes]);

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Product Types</Typography>
        <div className="flex flex-col w-[215px]">
          <Autocomplete
            id="product-types-filter-autocomplete"
            multiple
            size="small"
            value={selectedTypeIds}
            options={siteTypes}
            getOptionLabel={(option) => option.Name}
            filterSelectedOptions
            limitTags={2}
            onChange={(e, value) => {
              setSelectedTypeIds(value);
              setSearchParams((params) => {
                params.set('productTypes', value.map((obj) => obj.ProductTypeId));
                if (value.length === 0) params.delete('productTypes');
                return params;
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Types"
                placeholder="Types"
              />
            )}
            classes={{ paper: classes.paper }}
            disabled={hasProductType === 'no'}
          />
        </div>
        <div className="flex items-center gap-2">
          <FormControlLabel
            control={(
              <Checkbox
                checked={selectedProductTypesOnly}
                onChange={(e) => {
                  setSearchParams((params) => {
                    params.set('selectedProductTypesOnly', e.target.checked);
                    if (e.target.checked === false) params.delete('selectedProductTypesOnly');
                    return params;
                  });
                }}
              />
            )}
            label={<p className="text-[14px]">Selected product types only</p>}
            className="mr-0"
            disabled={typeIds === null}
          />
          <Tooltip
            title={(
              <div className="text-sm">
                <p>
                  <span className="font-gothambold">Selected:</span>
                  {' '}
                  Filtered results will show products with the selected product types only.
                </p>
                <p>
                  <span className="font-gothambold">Un-Selected:</span>
                  {' '}
                  Filtered results will show products with the selected product types but the product
                  may also contain other unselected Sources.
                </p>
              </div>
            )}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      </Stack>
    </div>

  );
}

export default ProductTypesFilter;
