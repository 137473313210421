import patchRequest from '../../../../patchRequest';

/**
 * Updates all variant stock enabled.
 *
 * @param {number} productId - 15974689
 * @param {number} siteId - 1
 * @param {number} variantId - 1
 * @param {array} sourceLocationIds - [14, 22]
 * @param {boolean} status - true
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchVariantStockEnabled(
  productId,
  siteId,
  variantId,
  sourceLocationIds,
  status,
) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/variant-stock-enabled`;

  const body = {
    variantId,
    sourceLocationIds,
    status,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Stock Enabled')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchVariantStockEnabled;
