import { Image } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataUsageIcon from '@mui/icons-material/DataUsage';
// import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import InsightsIcon from '@mui/icons-material/Insights';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
// import PeopleIcon from '@mui/icons-material/People';
// import MergeTypeIcon from '@mui/icons-material/MergeType';
import SettingsIcon from '@mui/icons-material/Settings';
import StyleIcon from '@mui/icons-material/Style';
import React from 'react';

const InitialSidebarOptions = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: '/',
    icon: <DashboardIcon className="w-5" />,
  },
  {
    id: 'productInfo',
    name: 'Product Info',
    link: '/product-info/products',
    icon: <InfoIcon className="w-5" />,
  },
  {
    id: 'bulkOperations',
    name: 'Bulk Operations',
    link: '/bulk-operations',
    icon: <LibraryBooksIcon className="w-5" />,
  },
  {
    id: 'dropship',
    name: 'Dropship Mgmt',
    link: '/dropship/wizards/product',
    icon: <AttachMoneyIcon className="w-5" />,
  },
  {
    id: 'dataManagement',
    name: 'Data Mgmt',
    link: '#',
    icon: <DataUsageIcon className="w-5" />,
  },
  {
    id: 'marginThreshold',
    name: 'Margin Threshold',
    link: '/margin-threshold',
    icon: <InsightsIcon className="w-5" />,
  },
  // {
  //   id: 'merge',
  //   name: 'Merge',
  //   link: '#',
  //   icon: <MergeTypeIcon className="w-5" />,
  // },
  {
    id: 'styles',
    name: 'Styles',
    link: '/styles',
    icon: <StyleIcon className="w-5" />,
  },
  {
    id: 'reports',
    name: 'Reports',
    link: 'https://app.powerbi.com/groups/d924dba2-4a3d-4688-b381-31810fcac510/list?ctid=79b96e1f-2b65-4866-a960-61eedd745936&experience=power-bi',
    icon: <AssessmentIcon className="w-5" />,
  },
  {
    id: 'images',
    name: 'Images',
    link: '#',
    icon: <Image className="w-5" />,
  },
  {
    id: 'settings',
    name: 'Settings',
    link: '#',
    icon: <SettingsIcon className="w-5" />,
  },

  // {
  //   id: 'wiki',
  //   name: 'Wiki',
  //   link: '/wiki',
  //   icon: <HelpIcon className="w-5" />,
  // },
  // {
  //   id: 'users',
  //   name: 'Users',
  //   link: '/admin/users',
  //   icon: <PeopleIcon className="w-5" />,
  // },
];

export default InitialSidebarOptions;
