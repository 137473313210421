import getRequest from '../../../../getRequest';

/**
 * Get all options for specific attribute dropdown.
 *
 * @param {number} siteId
 * @param {number} languageId
 * @param {number} attributeId
 * @returns {[
 *   {
 *     ProductAttributeLookupOptionId: number
 *     ProductAttributeDefId: number
 *     ProductAttributeDefKey: string
 *     Value: string
 *     SiteId: number
 *     SiteGroupId: number
 *     IsDefaultValue: boolean
 *     SiteName: string
 *   }
 * ]}
 */
function getMultiLookupAttributes(siteId, languageId, attributeId) {
  const endpoint = `/oc-product/v1/product/attributes/1/multi-lookup-attributes?languageId=${languageId}&attributedefId=${attributeId}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Product Types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getMultiLookupAttributes;
