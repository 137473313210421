// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import Table from '../../../components/Table';
import NewInDateFilter from './filter/NewInDateFilter';
import EditModal from './modal/EditModal';

// function createData(id, image, pr
// oductId, pluSource, name, brand, active, status, date, actions) {
//   return {
//     id, image, productId, pluSource, name, brand, active, status, date, actions,
//   };
// }

function NewInDate() {
  const [openEditModal, setOpenEditModal] = useState(false);

  const [
    selectedRowToEdit,
    // setSelectedRowToEdit
  ] = useState({
    productId: '',
    plu: '',
    newInDate: '',
  });

  // const handleOpenModal = (productId, plu, newInDate) => {
  //   setSelectedRowToEdit({
  //     productId,
  //     plu,
  //     newInDate,
  //   });
  //   setOpenEditModal(true);
  // };
  const handleCloseModal = () => setOpenEditModal(false);

  const [loading, setLoading] = useState(false);
  const [
    products,
    // setProducts
  ] = useState({});
  const [
    rows,
    // setRows
  ] = useState([]);
  const [page, setPage] = useState(0);
  const [siteId] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [productsPostData, setProductsPostData] = useState({
    asc: true,
    field: 1,
    languageId: 1,
    orderBy: {
      asc: true,
      field: 1,
    },
    page: page + 1,
    pageSize: rowsPerPage,
    selectedSourcesOnly: false,
    siteId,
    sourceIds: [],
  });

  const columns = [
    { id: 'image', label: 'Image' },
    { id: 'productId', label: 'Product ID' },
    { id: 'pluSource', label: 'PLU - Source' },
    { id: 'name', label: 'Name' },
    { id: 'brand', label: 'Brand' },
    { id: 'active', label: 'Active' },
    { id: 'status', label: 'Status' },
    { id: 'date', label: 'New In Date' },
    { id: 'actions', label: '' },
  ];

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      // setProducts(search.data);

      // const ids = search.data.items.map((x) => x.id);

      // const mappedRows = search.data.items.map((product) => {
      //   const correctImage = firstImageLinks.data.items.filter(
      //     (image) => image.productId === product.id,
      //   );
      //   let thumbnail = '';

      //   if (correctImage[0].partialLink.includes('imagemissing')) {
      //     thumbnail = correctImage[0].partialLink;
      //   } else {
      //     thumbnail = `${correctImage[0].partialLink}_a`;
      //   }

      //   return createData(
      //     product.id,
      //     (
      //       <Box className="flex items-center justify-center">
      //         <img
      //           alt="product"
      //           src={thumbnail}
      //           className="w-20"
      //         />
      //       </Box>
      //     ),
      //     product.id,
      //     product.remoteId,
      //     product.name,
      //     product.brand,
      //     product.isActive,
      //     product.isApproved,
      //     product.newInDate == null ? 'No Rel
      // ease Date Set' : new Date(product.newInDate).toLocaleString(),
      //     (
      //       <Button
      //         variant="outlined"
      //         size="small"
      //         color="warning"
      //         onClick={() => handleOpenModal(product.id, product.remoteId, product.newInDate)}
      //       >
      //         Edit
      //       </Button>
      //     ),
      //   );
      // });
      setLoading(false);
      // setRows(mappedRows);
    }
    fetchData();
  }, [page, rowsPerPage, productsPostData, siteId]);

  const handleChangePage = (event, pageNumber) => {
    setProductsPostData({
      ...productsPostData,
      page: pageNumber + 1,
    });
    setPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setProductsPostData({
      ...productsPostData,
      page: 1,
      pageSize: event.target.value,
    });
  };

  return (
    <Container maxWidth="xl">
      <EditModal
        open={openEditModal}
        onCloseModal={() => handleCloseModal()}
        selectedRow={selectedRowToEdit}
      />
      <Grid container spacing={4} marginTop="-1rem">
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Typography variant="h4" className="font-gothamlight">New-In Dates</Typography>
          <NewInDateFilter />
        </Grid>

        <Grid item xs={12}>
          <Table columns={columns} rows={rows} loading={loading} fixed={false} maxHeight="70vh" />
          <TablePagination
            rowsPerPageOptions={[15, 30, 90]}
            component="div"
            count={products.totalItems || 16}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ height: 'fit-content' }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewInDate;
