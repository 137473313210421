import postRequest from '../../../../postRequest';

/**
 * Post request to disable a margin threshold.
 *
 * @param {number} marginThresholdConfigId
 *
 */
function postDisableMarginThreshold(marginThresholdConfigId) {
  const endpoint = `/oc-data-management/v1/margin-thresholds/${marginThresholdConfigId}/disable-job`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, 'Disable Margin Threshold')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postDisableMarginThreshold;
