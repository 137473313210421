import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';

import FilterDraw from '../../../../components/FilterDraw';

function DatesFilter() {
  return (
    <FilterDraw>
      <div className="p-4 relative max-w-lg">
        <Typography variant="h5" gutterBottom>Filters:</Typography>
        <Grid container spacing={3} className="flex flex-col items-stretch relative">
          <Grid item xs>
            <TextField
              id="outlined-textarea"
              label="PLU(s)"
              placeholder="Enter PLUs here seperated with comma. Select Source to speed up search."
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              id="outlined-textarea"
              label="Product ID(s)"
              placeholder="Enter Product IDs seperated with space or comma."
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs>
            <TextField id="outlined-basic" label="Name" variant="outlined" fullWidth margin="dense" />
            <TextField id="outlined-basic" label="Source" variant="outlined" fullWidth margin="dense" />
            <MenuItem
              value="start"
              control={<Checkbox color="primary" />}
              label="Selected Sources Only:"
              labelplacement="start"
            />
            <TextField
              id="outlined-textarea"
              label="EAN(s)"
              placeholder="Enter Product IDs seperated with space or comma."
              multiline
              rows={2}
              variant="outlined"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="approved-select-label">Approved</InputLabel>
              <Select
                labelId="approved-select-label"
                id="approved-select"
                label="Approved"
                defaultValue="both"
              >
                <MenuItem value="both">Both</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="unapproved">Unapproved</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="active-select-label">Active</InputLabel>
              <Select
                labelId="active-select-label"
                id="active-select"
                label="Active"
                defaultValue="both"
              >
                <MenuItem value="both">Both</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="complete-select-label">Complete</InputLabel>
              <Select
                labelId="complete-select-label"
                id="complete-select"
                label="Complete"
                defaultValue="both"
              >
                <MenuItem value="both">Both</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
                <MenuItem value="incomplete">Incomplete</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </FilterDraw>
  );
}

export default DatesFilter;
