import deleteRequest from '../../../../deleteRequest';

/**
 * Deletes product type.
 *
 * @param {number} siteId - 1
 * @param {number} productTypeId
 * @returns {Promise} { Success: true }, status: 200 }
 */
async function deleteProductType(siteId, productTypeId) {
  const endpoint = `/oc-data-management/v1/product-type/${productTypeId}/${siteId}`;

  const errorMessageHeader = `Delete ${productTypeId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteProductType;
