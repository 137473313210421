import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';

import { AttributeDefinitionsContext } from '../../AttributeDefinitionsContext';
import DefaultValueFilter from './DefaultValueFilter';
import DefinitionKeyFilter from './DefinitionKeyFilter';
import GlobalFilter from './GlobalFilter';
import HiddenFilter from './HiddenFilter';
import IncludeInSearchKeywordsFilter from './IncludeInSearchKeywordsFilter';
import MandatoryFilter from './MandatoryFilter';
import ReadOnlyFilter from './ReadOnlyFilter';

function Filter() {
  const {
    filterOpen, setFilterOpen, fetchData, resetAllFilters, includeProductCount, setIncludeProductCount,
  } = useContext(AttributeDefinitionsContext);
  return (
    <div className="w-full">
      <Collapse in={filterOpen} timeout="auto">
        <div className="flex flex-col w-full h-auto gap-4 p-4 bg-white border rounded-lg">
          <Typography variant="h6" className="font-gothamlight">
            Filters
          </Typography>
          <Grid item xs={12} container className="gap-12">
            <DefinitionKeyFilter />
            <Box className="flex flex-row gap-12">
              <ReadOnlyFilter />
              <HiddenFilter />
              <MandatoryFilter />
              <DefaultValueFilter />
              <GlobalFilter />
              <IncludeInSearchKeywordsFilter />
            </Box>
          </Grid>
          <div className="flex flex-col items-end w-full">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={includeProductCount}
                  onChange={(e) => setIncludeProductCount(e.target.checked)}
                />
              )}
              label={<p className="text-[14px]">Include product count</p>}
              className="mr-0"
            />
            <div className="flex items-center justify-end w-full gap-10">
              <Button variant="text" className="text-primary-600" onClick={resetAllFilters}>
                Reset
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setFilterOpen(false);
                  fetchData();
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default Filter;
