import postRequest from '../../../../../postRequest';

/**
 * @typedef {Object} RequestBody
 * @property {string} siteCode
 * @property {number} productId
 * @property {string} pluId
 * @property {number} categoryId
 * @property {number} statusCategory
 * @property {number} productStatus
 * @property {number} shogunTransferResult
 */

/**
 * Calls drop-shipment endpoint to get product information.
 *
 * @param {RequestBody} body The body of the post request. This is left up to the dev
 * as fields can be removed/null to allow for all results to return for that field. e.g.
 * categoryId: null will return the products without thinking about any category id's, but
 * if categoryId wasn't null you would be searching for products contain that category id.
 * @example
 * body = {
 *  siteCode, // string
 *  productId, // number
 *  pluId, // string
 *  categoryId, // number
 *  statusCategory, // number
 *  productStatus, // number
 *  shogunTransferResult, // number
 * }
 * @param {number} page - 1
 * @param {number} size - 15
 * @returns {Promise} { data: { Data: [{}], Pagination: {}, Summary: {} }, status: 200 }
 */
async function postDropshipment(body, page, size) {
  const queries = `Page=${page}&Size=${size}`;
  const endpoint = `/integration-pim/v1/products/drop-shipment?${queries}`;

  // const body = {
  //   siteCode,
  //   productId,
  //   pluId,
  //   categoryId,
  //   productStatus,
  //   shogunTransferResult,
  // };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Dropshipment')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postDropshipment;
