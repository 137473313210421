/* eslint-disable react/no-array-index-key */
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setProductDataActiveStep } from '../../../../redux/wizard/nike/nike_wizard';
import toCapitalized from '../../../../utils/toCapitalized';

function ReuseableChip({ data }) {
  const [label, setLabel] = React.useState('');

  React.useEffect(() => {
    if (typeof data === 'object') {
      if (data?.translation !== undefined) {
        if (data.translation?.length > 10) {
          setLabel(`${data.translation.replace(/<[^>]*>?/gm, '').slice(0, 10)}...`);
        } else {
          setLabel(data.translation);
        }
      }
      if (data?.name !== undefined) setLabel(data.name);
      if (data?.CategoryName !== undefined) setLabel(data.CategoryName);
    } else if (data?.length > 10 && typeof data === 'string') {
      setLabel(`${data.replace(/<[^>]*>?/gm, '').slice(0, 10)}...`);
    } else if (data === true || data === 1 || data === 'yes') {
      setLabel('Yes');
    } else if (data === false || data === 0 || data === 'no') {
      setLabel('No');
    } else {
      setLabel(data);
    }
  }, [data]);

  return label !== '' && label !== false && (
    <Chip label={label} size="small" />
  );
}
/**
 * The sidebar in the ProductData page that contains the chips that display the current data
 * to the user.
 *
 * @param {Array} group Array of Sites e.g. JD
 * @param {object} state Current ProductData state
 * @param {Set} reasons If step name is in reasons Set then there is missing required data.
 */
function Sidebar({ group, state, reasons }) {
  const dispatchToRedux = useDispatch();

  const groupsDupsRemoved = group.filter((value, index, self) => {
    const fasciaIndex = self.findIndex((t) => t.fascia === value.fascia);
    return fasciaIndex === index;
  });

  const editChip = (stepIndex) => {
    dispatchToRedux(setProductDataActiveStep(stepIndex - 1));
  };

  /**
   * If there are no VAT codes selected then it will return a p tag saying that there are no values.
   *
   * @param {array} value Array of VAT code objects.
   */
  const checkForEmptyList = (value) => {
    const chipOfValue = value.map((obj) => obj.vat_code.map((vatCode, index) => vatCode.is_vatable
      && (
        <ReuseableChip
          key={`${obj.sku}-${vatCode.country_code}-${vatCode.is_vatable}-${index}`}
          data={`${obj.sku}: ${vatCode.country_code} - ${vatCode.is_vatable}`}
        />
      )));
    const removedBools = chipOfValue.flat().filter((v) => v !== false);
    return removedBools.length > 0 ? removedBools : <p>No Values Selected</p>;
  };

  return (
    <Box
      key="box-1"
      className="h-full pt-4 pl-6 border-r bg-neutral-50 text-tertiary-600 rounded-tl-xl"
    >
      <div className="flex items-center justify-center">
        <Typography
          variant="h6"
          className="font-gothambold"
          marginBottom={1}
        >
          Edit Group Product Data
        </Typography>
      </div>

      <Stack spacing=".85rem">
        <Box key="group-container">
          <Typography variant="body1" className="font-gothammedium" gutterBottom>
            Groups:
          </Typography>
          <Stack direction="row" className="flex-wrap gap-2">
            {groupsDupsRemoved.map((obj) => (
              <Chip key={obj.id} label={obj.fascia} />
            ))}
          </Stack>
        </Box>
        {Object.entries(state).map(([key, value], index) => {
          if (key === 'activeStep') return null;
          if (key === 'initial_pim_categories') return null;
          return (
            <div key={`steps-${key}`}>
              <Stack direction="row" spacing={1}>
                <Typography
                  variant="body1"
                  gutterBottom
                  index={index}
                  className="font-gothammedium"
                >
                  {`${toCapitalized(key)}:`}
                </Typography>

                {(Array.from(reasons).map((v) => v.includes(toCapitalized(key)))).includes(true)
                  && (
                    <Tooltip arrow placement="top" title="One or more required fields are empty.">
                      <FiberManualRecordIcon className="text-red-500" />
                    </Tooltip>
                  )}

                <Tooltip title={`Edit ${toCapitalized(key)}`} arrow placement="top">
                  <EditIcon
                    sx={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      opacity: '0.8',
                    }}
                    onClick={() => editChip(index)}
                  />
                </Tooltip>
              </Stack>

              {Array.isArray(value) === false ? (
                <Stack key="false-array" direction="row" className="flex-wrap gap-2">
                  {Object.entries(value).map(([key2, value2]) => {
                    if (Array.isArray(value2)) {
                      return (
                        <Stack
                          key={`stack-${key2}`}
                          direction="row"
                          className="flex-wrap gap-2"
                        >
                          {value2.map((obj, objIndex) => obj.country_code !== 'GB' && (
                            <ReuseableChip
                              key={`array-chip-${key2}-${(objIndex + 1)}`}
                              data={obj}
                            />
                          ))}
                        </Stack>
                      );
                    }
                    if (key2 === 'to' || key2 === 'from') {
                      return (
                        <ReuseableChip
                          key={`chip-${key2}`}
                          index={index}
                          data={value2.toString()}
                        />
                      );
                    }
                    return (
                      <ReuseableChip
                        key={`chip-${key2}`}
                        index={index}
                        data={value2}
                      />
                    );
                  })}
                </Stack>
              ) : (
                <Stack key="array" direction="row" className="flex-wrap gap-2">
                  {checkForEmptyList(value)}
                </Stack>
              )}
            </div>
          );
        })}
      </Stack>
    </Box>
  );
}

export default Sidebar;
