import patchRequest from '../../../../../patchRequest';

/**
 * Calls product-attribute endpoint to update products attributes in PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @param {string} colour - Blue
 * @param {string} fabric - Cotton
 * @param {string} description - Description about product
 * @param {string} productNameLanguageIso - ENG
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchProductAttribute(
  productId,
  siteCode,
  colour,
  fabric,
  description,
  productNameLanguageIso,
) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/product-attribute';

  const body = {
    productId,
    siteCode,
    colour,
    fabric,
    description,
    productNameLanguageIso,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Product Attribute')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchProductAttribute;
