import postRequest from '../../../../../postRequest';

/**
 * Logs message into backend for audit trailing.
 *
 * @param {number} productId - 15974689
 * @param {string} siteCode - JD
 * @param {string} message - User made this change
 * @returns {Promise} { data: { Data: [{}], Pagination: {} }, status: 200 }
 */
async function postAddProductLog(productId, siteCode, message) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/add-product-log';

  const body = {
    productId,
    siteCode,
    message,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Add Product Log')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postAddProductLog;
