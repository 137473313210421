import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import getLanguages from '../../../../api/oc_product/product/languages/gets/getLanguages';
import getAttributeOptions from '../../../../api/oc_product/sites/attributes/gets/getAttributeOptions';
import getSiteAttributes from '../../../../api/oc_product/sites/attributes/gets/getAttributes';
import VirtualizedAutocomplete from '../../../VirtualizedAutocomplete';

function SearchByAttributes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const [languagesOptions, setLanguagesOptions] = React.useState([]);
  const [siteAttributeOptions, setSiteAttributeOptions] = React.useState([]);
  const [attributeValueOptions, setAttributeValueOptions] = React.useState([]);
  const languageId = searchParams.get('languageId');
  const [selectedLanguage, setSelectedLanguage] = React.useState(null);
  const productAttributeDefId = searchParams.get('productAttributeDefId');
  const [selectedProductAttributeDefId, setSelectedProductAttributeDefId] = React.useState(null);
  const productAttributeDefOptionId = searchParams.get('productAttributeLookupOptionId');
  const [selectedProductAttributeDefOptionId, setSelectedProductAttributeDefOptionId] = React.useState(null);
  const [selectedProductAttributeDefOptionIds, setSelectedProductAttributeDefOptionIds] = React.useState([]);
  const productAttributeDefValues = searchParams.get('productAttributeDefValues');
  const [selectedProductAttributeDefValues, setSelectedProductAttributeDefValues] = React.useState(null);
  const [dataTypeId, setDataTypeId] = React.useState(null);
  const [textFieldValue, setTextFieldValue] = React.useState('');
  const [isChecked, setIsChecked] = React.useState({ label: '', value: '' });
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedBlankAttributeValue, setSelectedBlankAttributeValue] = React.useState(false);

  const handleClearingData = () => {
    setSearchParams((params) => {
      params.delete('languageId');
      params.delete('productAttributeDefId');
      params.delete('dataTypeId');
      params.delete('productAttributeLookupOptionId');
      params.delete('productAttributeDefValues');
      params.delete('productAttributeDataTypeName');
      params.delete('blankAttributeDefValue');
      setSelectedLanguage(null);
      setSelectedProductAttributeDefId(null);
      setSelectedProductAttributeDefOptionId(null);
      setSelectedProductAttributeDefOptionIds([]);
      setSelectedProductAttributeDefValues(null);
      setSelectedBlankAttributeValue(false);
      return params;
    });
  };

  const handleContentLanguageChange = (value) => {
    setSelectedLanguage(value);
    if (value === null) {
      handleClearingData();
    }
    const attributeDefOptionIds = (dataTypeId === 8)
      ? selectedProductAttributeDefOptionId.ProductAttributeLookupOptionId
      : (dataTypeId === 9)
      && selectedProductAttributeDefOptionId?.map((obj) => obj.ProductAttributeLookupOptionId);
    if ((productAttributeDefId || selectedProductAttributeDefId)
      && (productAttributeDefOptionId || selectedProductAttributeDefOptionId || selectedProductAttributeDefValues)
      && value !== null) {
      if (value.AttributeDataTypeId !== 8 || value.AttributeDataTypeId !== 9) {
        setSearchParams((params) => {
          params.set('languageId', selectedLanguage?.LanguageId);
          params.set('productAttributeDefId', value?.ProductAttributeDefId);
          params.set('productAttributeDefValues', textFieldValue);
          params.set('dataTypeId', value?.AttributeDataTypeId);
          return params;
        });
      } else {
        setSearchParams((params) => {
          params.set('languageId', selectedLanguage?.LanguageId);
          params.set('productAttributeDefId', value?.ProductAttributeDefId);
          params.set('productAttributeLookupOptionId', attributeDefOptionIds);
          params.set('dataTypeId', value?.AttributeDataTypeId);
          params.set('productAttributeDataTypeName', value.AttributeDataTypeName);
          return params;
        });
      }
    }
  };

  const handleAttributeDefIdChange = (value) => {
    setSelectedProductAttributeDefId(value);
    setSelectedProductAttributeDefOptionId(null);
    setSelectedProductAttributeDefOptionIds([]);
    setDataTypeId(value?.AttributeDataTypeId);
    setSearchParams((params) => {
      params.delete('productAttributeDefValues');
      params.delete('productAttributeLookupOptionId');
      return params;
    });
    const attributeDefOptionIds = (dataTypeId === 8)
      ? selectedProductAttributeDefOptionId?.ProductAttributeLookupOptionId
      : (dataTypeId === 9)
      && selectedProductAttributeDefOptionIds?.map((obj) => obj.ProductAttributeLookupOptionId);
    if (!value) {
      setSearchParams((params) => {
        params.delete('languageId');
        params.delete('productAttributeDefId');
        params.delete('productAttributeLookupOptionId');
        params.delete('productAttributeDefValues');
        params.delete('productAttributeDataTypeName');
        params.delete('dataTypeId');
        params.set('selectedBlankAttributeValue', 'false');
        setSelectedProductAttributeDefOptionId(null);
        setSelectedProductAttributeDefOptionIds([]);
        setSelectedProductAttributeDefValues('');
        setSelectedBlankAttributeValue(false);
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.set('languageId', selectedLanguage?.LanguageId);
        params.set('productAttributeDefId', value?.ProductAttributeDefId);
        params.set('dataTypeId', value?.AttributeDataTypeId);
        params.set('productAttributeDataTypeName', value.AttributeDataTypeName);
        return params;
      });
    }
    if ((languageId || selectedLanguage)
      && (value !== null)
      && (selectedProductAttributeDefOptionIds.length > 0)
      && (selectedProductAttributeDefValues !== null)) {
      if (!(value?.AttributeDataTypeId === 8 || value?.AttributeDataTypeId === 9)) {
        setSearchParams((params) => {
          params.delete('productAttributeLookupOptionId');
          setSelectedProductAttributeDefOptionId(null);
          setSelectedProductAttributeDefOptionIds([]);
          params.set('languageId', selectedLanguage?.LanguageId);
          params.set('productAttributeDefId', value?.ProductAttributeDefId);
          params.set('productAttributeDefValues', textFieldValue);
          params.set('dataTypeId', value?.AttributeDataTypeId);
          params.set('productAttributeDataTypeName', value.AttributeDataTypeName);
          return params;
        });
      } else {
        setSearchParams((params) => {
          params.delete('productAttributeDefValues');
          params.delete('productAttributeLookupOptionId');
          params.set('languageId', selectedLanguage?.LanguageId);
          params.set('productAttributeDefId', value?.ProductAttributeDefId);
          params.set('productAttributeLookupOptionId', attributeDefOptionIds);
          params.set('dataTypeId', value?.AttributeDataTypeId);
          params.set('productAttributeDataTypeName', value.AttributeDataTypeName);
          return params;
        });
      }
    }
  };

  const handleProductAttributeDefOptionIdChange = (event, value) => {
    setSelectedProductAttributeDefOptionId(value);
    if (!value) {
      setSearchParams((params) => {
        params.delete('productAttributeLookupOptionId');
        return params;
      });
    }
    const attributeDefOptionIds = value?.ProductAttributeLookupOptionId;
    if (value?.length < 1) {
      setSearchParams((params) => {
        params.delete('productAttributeLookupOptionId');
        setSelectedProductAttributeDefOptionId(null);
        setSelectedProductAttributeDefOptionIds([]);
        return params;
      });
    } else if ((languageId || selectedLanguage)
      && (productAttributeDefId || selectedProductAttributeDefId)
      && value !== null) {
      setSearchParams((params) => {
        params.set('languageId', selectedLanguage?.LanguageId);
        params.set('productAttributeDefId', selectedProductAttributeDefId?.ProductAttributeDefId);
        params.set('productAttributeLookupOptionId', attributeDefOptionIds);
        return params;
      });
    }
  };

  const handleProductAttributeDefOptionIdsChange = (event, value) => {
    setSelectedProductAttributeDefOptionIds(value);
    const attributeDefOptionIds = value?.map((obj) => obj.ProductAttributeLookupOptionId);
    if (value.length < 1) {
      setSearchParams((params) => {
        params.delete('productAttributeLookupOptionId');
        setSelectedProductAttributeDefOptionId(null);
        setSelectedProductAttributeDefOptionIds([]);
        return params;
      });
    } else if ((languageId || selectedLanguage)
      && (productAttributeDefId || selectedProductAttributeDefId)
      && value !== null) {
      setSearchParams((params) => {
        params.set('languageId', selectedLanguage?.LanguageId);
        params.set('productAttributeDefId', selectedProductAttributeDefId?.ProductAttributeDefId);
        params.set('productAttributeLookupOptionId', attributeDefOptionIds);
        return params;
      });
    }
  };

  const handleTextFieldChange = (e) => {
    setTextFieldValue(e.target.value);
    // Set all params here
    if (e.target.value.length < 1) {
      setSearchParams((params) => {
        params.delete('productAttributeDefValues');
        setSelectedProductAttributeDefValues(null);
        return params;
      });
    } else if ((languageId || selectedLanguage)
      && (productAttributeDefId || selectedProductAttributeDefId)
      && e.target.value !== null) {
      setSearchParams((params) => {
        params.set('languageId', selectedLanguage?.LanguageId);
        params.set('productAttributeDefId', selectedProductAttributeDefId?.ProductAttributeDefId);
        params.set('productAttributeDefValues', e.target.value);
        return params;
      });
    }
  };

  const handleDateChange = (date) => {
    const isValidDate = date instanceof Date
      && !Number.isNaN(Number(date))
      && date.getTime() > new Date('1970-01-01').getTime();
    if (isValidDate) {
      const formattedDate = date.toISOString().split('T')[0]; // Format the date to a string (YYYY-MM-DD)
      setTextFieldValue(formattedDate); // Update your text field value
      setSelectedDate(formattedDate);

      if ((languageId || selectedLanguage)
        && (productAttributeDefId || selectedProductAttributeDefId)
        && date !== null) {
        setSearchParams((params) => {
          params.set('languageId', selectedLanguage?.LanguageId);
          params.set('productAttributeDefId', selectedProductAttributeDefId?.ProductAttributeDefId);
          params.set('productAttributeDefValues', formattedDate);
          return params;
        });
      }
    } else {
      // Handle the case when the date is cleared
      setTextFieldValue('');
      setSelectedDate(null);
      setSearchParams((params) => {
        params.delete('productAttributeDefValues');
        setSelectedProductAttributeDefValues(null);
        return params;
      });
    }
  };

  const handleBlankAttributeCheckboxChange = (e) => {
    setSelectedBlankAttributeValue(e.target.checked);
    setSearchParams((params) => {
      if (e.target.checked) {
        params.delete('productAttributeLookupOptionId');
        setSelectedProductAttributeDefOptionId(null);
        setSelectedProductAttributeDefOptionIds([]);
        params.delete('productAttributeDefValues');
        setSelectedProductAttributeDefValues(null);
        params.set('selectedBlankAttributeValue', 'true');
      } else {
        params.set('selectedBlankAttributeValue', 'false');
      }
      return params;
    });
  };
  const restrictInput = (event) => {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };
  const handleCheckboxChange = (e, newValue) => {
    setIsChecked(newValue);
    if (!newValue) {
      setSearchParams((params) => {
        params.delete('productAttributeDefValues');
        setSelectedProductAttributeDefValues('');
        return params;
      });
    } else if ((languageId || selectedLanguage)
      && (productAttributeDefId || selectedProductAttributeDefId)
      && newValue !== null) {
      setSearchParams((params) => {
        params.set('languageId', selectedLanguage?.LanguageId);
        params.set('productAttributeDefId', selectedProductAttributeDefId?.ProductAttributeDefId);
        params.set('productAttributeDefValues', newValue.value);
        return params;
      });
    }
  };

  React.useEffect(() => {
    getLanguages(siteId)
      .then((res) => setLanguagesOptions(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect(() => {
    if (selectedLanguage) {
      getSiteAttributes(siteId, selectedLanguage.LanguageId)
        .then((res) => {
          setSiteAttributeOptions(res.data);
          setDataTypeId(res.data.AttributeDataTypeId);
        })
        .catch((err) => console.error(err));
    }
  }, [siteId, selectedLanguage]);

  React.useEffect(() => {
    if (selectedProductAttributeDefId) {
      getAttributeOptions(siteId, selectedProductAttributeDefId.ProductAttributeDefId, selectedLanguage.LanguageId)
        .then((res) => {
          setAttributeValueOptions(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [siteId, selectedProductAttributeDefId]);

  React.useEffect(() => {
    if (!languageId) {
      setSelectedLanguage(null);
    }
    const selectedLanguageObj = languagesOptions.find((obj) => obj.LanguageId === Number(languageId));
    if (selectedLanguageObj) {
      setSelectedLanguage(selectedLanguageObj);
    }
  }, [languageId, languagesOptions]);

  React.useEffect(() => {
    if (!productAttributeDefId) {
      setSelectedProductAttributeDefId(null);
    }
    if (productAttributeDefId) {
      const productAttributeDefIdObj = siteAttributeOptions?.find((obj) => obj.ProductAttributeDefId === Number(productAttributeDefId));
      setSelectedProductAttributeDefId(productAttributeDefIdObj);
      setDataTypeId(productAttributeDefIdObj?.AttributeDataTypeId);
    }
  }, [productAttributeDefId, siteAttributeOptions]);

  React.useEffect(() => {
    if (!productAttributeDefOptionId) {
      setSelectedProductAttributeDefOptionId(null);
      setSelectedProductAttributeDefOptionIds([]);
    }
    if (productAttributeDefOptionId && attributeValueOptions) {
      if (dataTypeId === 8) {
        const obj = attributeValueOptions.find((object) => object.ProductAttributeLookupOptionId === Number(productAttributeDefOptionId));
        setSelectedProductAttributeDefOptionId(obj);
      } else if (dataTypeId === 9) {
        const idArray = productAttributeDefOptionId.split(',').map((id) => parseInt(id.trim(), 10));
        const filteredObjects = attributeValueOptions.filter((obj) => idArray.includes(obj.ProductAttributeLookupOptionId));
        setSelectedProductAttributeDefOptionIds(filteredObjects);
      }
    }
  }, [productAttributeDefOptionId, attributeValueOptions]);

  React.useEffect(() => {
    if (!productAttributeDefValues) {
      setSelectedProductAttributeDefValues([]);
      setTextFieldValue('');
      setSelectedDate(null);
      setIsChecked({ label: '', value: '' });
    }
    if (productAttributeDefValues) {
      setSelectedProductAttributeDefValues(productAttributeDefValues || '');
      setTextFieldValue(productAttributeDefValues);
      setSelectedDate(productAttributeDefValues);
      setIsChecked({ label: productAttributeDefValues, value: productAttributeDefValues });
    }
  }, [productAttributeDefValues]);

  function renderControl() {
    switch (dataTypeId) {
      case 1:
        return (
          <Autocomplete
            textFieldLabel={selectedProductAttributeDefId?.Attribute}
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            size="small"
            value={isChecked}
            onChange={handleCheckboxChange}
            renderInput={(params) => (
              <TextField {...params} label={selectedProductAttributeDefId?.Attribute} variant="outlined" />
            )}
          />
        );
      case 2:
        return (
          <TextField
            size="small"
            fullWidth
            type="number"
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            label={selectedProductAttributeDefId?.Attribute}
            InputLabelProps={{ shrink: true }}
            value={textFieldValue}
            onChange={handleTextFieldChange}
            onKeyPress={restrictInput}
          />
        );
      case 3:
        return (
          <TextField
            size="small"
            fullWidth
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            label={selectedProductAttributeDefId?.Attribute}
            InputLabelProps={{ shrink: true }}
            value={textFieldValue}
            onChange={handleTextFieldChange}
          />
        );
      case 4:
        return (
          <TextField
            size="small"
            fullWidth
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            label={selectedProductAttributeDefId?.Attribute}
            InputLabelProps={{ shrink: true }}
            value={textFieldValue}
            onChange={handleTextFieldChange}
          />
        );
      case 5:
        return (
          <DatePicker
            label={selectedProductAttributeDefId?.Attribute}
            renderInput={(params) => <TextField {...params} size="small" InputLabelProps={{ shrink: true }} />}
            value={selectedDate}
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            onChange={handleDateChange}
          />
        );
      case 6:
        return (
          <TextField
            size="small"
            label={selectedProductAttributeDefId?.Attribute}
            fullWidth
            type="number"
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            InputLabelProps={{ shrink: true }}
            value={textFieldValue}
            onChange={handleTextFieldChange}
            onKeyPress={restrictInput}
          />
        );
      case 7:
        return (
          <TextField
            size="small"
            label={selectedProductAttributeDefId?.Attribute}
            fullWidth
            type="number"
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            InputLabelProps={{ shrink: true }}
            value={textFieldValue}
            onChange={handleTextFieldChange}
            onKeyPress={restrictInput}
          />
        );

      case 8:
        return (
          <VirtualizedAutocomplete
            textFieldLabel={selectedProductAttributeDefId?.Attribute}
            options={attributeValueOptions || []}
            size="small"
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            getOptionLabel={(option) => (option ? option.Value : '')}
            optionKey="Value"
            value={selectedProductAttributeDefOptionId || null}
            onChange={handleProductAttributeDefOptionIdChange}
            name="single"
          />
        );
      case 9:
        return (
          <VirtualizedAutocomplete
            multiple
            textFieldLabel={selectedProductAttributeDefId?.Attribute}
            options={attributeValueOptions || []}
            size="small"
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            getOptionLabel={(option) => (option ? option.Value : '')}
            optionKey="Value"
            value={selectedProductAttributeDefOptionIds}
            onChange={handleProductAttributeDefOptionIdsChange}
            name="double"
          />
        );
      case 10:
        return (
          <TextField
            size="small"
            fullWidth
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            label={selectedProductAttributeDefId?.Attribute}
            InputLabelProps={{ shrink: true }}
            value={textFieldValue}
            onChange={handleTextFieldChange}
          />
        );
      case 11:
        return (
          <Autocomplete
            textFieldLabel={selectedProductAttributeDefId?.Attribute}
            disabled={(!selectedLanguage && !selectedProductAttributeDefId) || selectedBlankAttributeValue === true}
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            size="small"
            value={isChecked}
            onChange={handleCheckboxChange}
            renderInput={(params) => (
              <TextField {...params} label={selectedProductAttributeDefId?.Attribute} variant="outlined" />
            )}
          />
        );

      default:
        return null;
    }
  }
  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Search By Attribute</Typography>
        <div className="flex-col gap-2 w-[350px]">
          <VirtualizedAutocomplete
            options={languagesOptions.map((obj) => ({ ...obj, Name: obj.LanguageName }))}
            textFieldLabel="Content Language"
            textFieldVarian="outlined"
            value={selectedLanguage}
            getOptionLabel={(option) => option.LanguageName}
            onChange={(_, value) => handleContentLanguageChange(value)}
            optionKey="LanguageName"
            size="small"
            name="content lang"
            className="mb-4"
          />
          <div>
            <VirtualizedAutocomplete
              key="Attribute"
              options={siteAttributeOptions}
              disabled={!selectedLanguage}
              textFieldLabel="Attribute"
              textFieldVariant="outlined"
              getOptionLabel={(option) => option.Attribute}
              onChange={(_, value) => handleAttributeDefIdChange(value)}
              optionKey="Attribute"
              value={selectedProductAttributeDefId}
              size="small"
              name="attr options"
              className="mb-4"
            />
          </div>
          <div>
            {renderControl()}
          </div>
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={selectedBlankAttributeValue}
                  onChange={handleBlankAttributeCheckboxChange}
                />
              )}
              label={<p className="text-[14px]">Attribute value blank</p>}
              className="mr-0"
            // disabled={selectedBlankAttributeValue === null}
            />
          </div>
        </div>

      </Stack>
    </div>
  );
}

export default SearchByAttributes;
