/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20240912() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S13-S14_E19</h2>
      <p className="-mt-4">12th Sept 2024</p>

      <h3 className="font-gothammedium text-neutral-700">
        New Functionality
      </h3>

      <ul className="-mt-4">
        <li>Add Categorised column to the results table</li>
        <li>Add Translated Product Description to General Tab</li>
        <li>(Bulk Uploads) - Release Dates - Allow users to delete release dates</li>
        <li>(Image Checker) - Allow users to access only Image checker</li>
      </ul>

      {/* <p><strong>Central PIM access have been shut down.</strong></p> */}

      <h3 className="font-gothammedium text-neutral-700">Fixes & Improvements</h3>

      <h4>Margin Threshold</h4>

      <ul className="-mt-4">
        <li>(Logs) Margin Threshold / Bulk - Exported file generating with bulk process ID instead of user given name</li>
      </ul>

      <h4>Attributes Def</h4>

      <ul className="-mt-4">
        <li>(Attributes Def) - Allow user to create duplicate attribute key for multiple sites</li>
        <li>Attributes not showing up when adding to Product Type</li>
      </ul>

      <h4>Size Mapping</h4>

      <ul className="-mt-4">
        <li>User can create a size mapping record with either Sizing Attribute or Department Code</li>
      </ul>

      <h4>Styles</h4>
      <ul className="-mt-4">
        <li>(Styles) - Products Search (PLU and ID) is returning wrong data</li>
        <li>Improve the search for style when moving a product</li>
      </ul>

      <h4>Bulk Operations</h4>
      <ul className="-mt-4">
        <li>Notification emails are not receiving after completing bulk operations</li>
        <li>Add more information to the complete log tiles</li>
        <li>Notifications should reflect what has been done</li>
        <li>Text is overlapping with the line in the success tab for value upload PRE Release Products Bulk operation.</li>
      </ul>

      <h4>PDP</h4>
      <ul className="-mt-4">
        <li>Product Status is not refreshing when switching products on the PDP</li>
        <li>Time is inserting as 00-00-00 even though given the time</li>
        <li>VAT Code values are updating from Swagger, though the wrong "newVatCode" is submitted in API</li>
        <li>PFS Enabled attribute is showing wrong in Prism, Central PIM and Flair.</li>
      </ul>

      <h4>PLP</h4>
      <ul className="-mt-4">
        <li>Open Products in new tab from the PLP</li>
        <li>White screen crash when the screen is a certain size</li>
      </ul>

      <h4>Prism General Improvements</h4>
      <ul className="-mt-4">
        <li>(Logs) Margin Threshold / Bulk - Exported file generating with bulk process ID instead of user given name</li>
        <li>Prism API (bulk Uploads) - Search By Attributes in bulk returning incorrect values</li>
        <li>Cosmos Sport accounts to access Asset Uploader</li>
        <li>Site Code Undefined, every time a user logs into Prism causing white screen</li>
        <li>Campaign and Multi select attributes - Getting white screen when clicking on more info button in logs</li>
        <li>Product name Update issue</li>
        <li>Location and Stock updated attributes are not updating in PRISM after upload the files (Product, Stock and Price)</li>
        <li>After login product details coming but after changing to any other fascia product details are not coming</li>
        <li>Unable to add new users to Prism (The field UserName is invalid)</li>
      </ul>

    </section>
  );
}

export default Release20240912;
