import axios from 'axios';

import deleteCustomAttribute from '../../../api/intergration/pim/products/dropshipment/deletes/deleteCustomAttribute';
import patchCustomAttribute from '../../../api/intergration/pim/products/dropshipment/patches/patchCustomAttribute';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';

function exclusivityFrom(userEmail, nike_wizard) {
  return new Promise((resolve, reject) => {
    const fromDate = nike_wizard.productData.other_attributes.from;

    if (fromDate === '') {
      deleteCustomAttribute(
        nike_wizard.productId,
        'JD',
        'exclusivity_date_from',
      )
        .then((res) => {
          if (res.data.Success) {
          // if (nike_wizard.editedValues.includes('from')) {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[INFO]: User ${userEmail} deleted ExclusivityDateFrom from ProductId: ${nike_wizard.productId} - SiteCode: JD`,
            );
            // }
            resolve(res.data);
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to deleted ExclusivityDateFrom from ProductId: ${nike_wizard.productId} - SiteCode: JD. Return Error Message: ${res.data.ErrorMessage}`,
            );
            reject(res.data.ErrorMessage);
          }
        })
        .catch((err) => {
        // If the error message contains the word Cancel then dont do error stuff.
        // Reason for this is because if a request is cancelled, this isn't an error to do with
        // saving data so the user doesn't need to be notified of this.
          if (axios.isCancel(err)) {
            resolve();
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to deleted ExclusivityDateFrom from ProductId: ${nike_wizard.productId} - SiteCode: JD. Return Error Message: ${err.message}`,
            );
            reject(err);
          }
        });
    } else {
      patchCustomAttribute(
        nike_wizard.productId,
        'JD',
        'exclusivity_date_from',
        new Date(fromDate),
        'GB',
      )
        .then((res) => {
          if (res.data.Success) {
            // if (nike_wizard.editedValues.includes('from')) {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[INFO]: User ${userEmail} updated ExclusivityDateFrom: ${fromDate} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB`,
            );
            // }
            resolve(res.data);
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to update ExclusivityDateFrom: ${fromDate} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB. Return Error Message: ${res.data.ErrorMessage}`,
            );
            reject(res.data.ErrorMessage);
          }
        })
        .catch((err) => {
          // If the error message contains the word Cancel then dont do error stuff.
          // Reason for this is because if a request is cancelled, this isn't an error to do with
          // saving data so the user doesn't need to be notified of this.
          if (axios.isCancel(err)) {
            resolve();
          } else {
            postAddProductLog(
              nike_wizard.productId,
              'JD',
              `[ERROR]: User ${userEmail} FAILED to update ExclusivityDateFrom: ${fromDate} for ProductId: ${nike_wizard.productId} - SiteCode: JD - ISO: GB. Return Error Message: ${err.message}`,
            );
            reject(err);
          }
        });
    }
  });
}

export default exclusivityFrom;
