import axios from 'axios';
import qs from 'qs';

// Declare a Map to store the identification and cancellation functions for each request
const pending = new Map();

/**
 * Looks for the passed in string in the pending Map.
 * If it finds more than 1 request with the same information.
 * It will cancel the first one in the array.
 *
 * Example of needing this is in FindProducts page.
 * If you filter the table, the filter request returns a result faster
 * than the unfiltered request.
 * Causing the table to load the filtered table then replace the table with an
 * unfiltered table.
 *
 * @param {string} substring
 */
function removePendingSubstring(substring) {
  const pendingMapToObj = Object.fromEntries(pending);
  const pendingKeys = Object.keys(pendingMapToObj);

  const matches = pendingKeys.filter((pendingRequest) => pendingRequest.includes(substring));
  const firstRequest = matches[0];

  if (matches.length > 0) {
    const cancel = pending.get(firstRequest);
    cancel(firstRequest);
    pending.delete(firstRequest);
  }
}

/**
 * Add Request to pending.
 *
 * @param {Object} config
 */
function addPending(config) {
  const conf = config;
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');

  conf.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
    if (!pending.has(url)) { // If the current request does not exist in pending, add it
      pending.set(url, cancel);
    }
  });

  /**
   * Below is for FindProduct page.
   * Checks is for the dropshipment endpoint.
   * If the url contains statusCategory=2 then it checks if there is already one pending,
   * and cancels it.
   */
  // matchAndRemove('siteCode=JD&statusCategory=2');

  // Remove previous request for errored products.
  // removePendingSubstring('siteCode=JD&statusCategory=2');
  // Remove previous request for in review products.
  // removePendingSubstring('siteCode=JD&statusCategory=1');
}

/**
 * Remove Request from pending and cancel it.
 *
 * @param {Object} config
 */
function removePending(config) {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');

  // If the current request identity exists in pending, you need to cancel the current
  // request and remove it
  if (pending.has(url)) {
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
}

/**
 * Empty requests in pending (called on route jumps)
 */
function clearPending() {
  // eslint-disable-next-line no-restricted-syntax
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
}

export {
  addPending,
  clearPending,
  removePending,
  removePendingSubstring,
};
