import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useReducer } from 'react';

import Modal from '../../../../../components/Modal';
import AttributeDefinitions from '../../../../../dummy_data/AttributeDefinitions';

const reducer = (state, action) => {
  switch (action.type) {
    case 'NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'DEPARTMENT_CODE':
      return {
        ...state,
        departmentCode: action.payload,
      };
    case 'SITES':
      return {
        ...state,
        sites: action.payload,
      };

    default:
      return state;
  }
};

function CreateModal({ open, onCloseModal }) {
  const initialFormState = {
    name: '',
    departmentCode: '',
    sites: [],
  };
  const [formState, dispatch] = useReducer(reducer, initialFormState);

  return (
    <Modal
      open={open}
      title="Create Product Attribute"
      buttonLabel="Create"
      buttonOnClick={() => onCloseModal(false)}
      onClose={() => onCloseModal(false)}
    >
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="attribute-label">Attribute</InputLabel>
            <Select
              labelId="attribute-label"
              id="attribute-select"
              label="Attribute"
            >
              <MenuItem value="A8MQ">A8MQ</MenuItem>
              <MenuItem value="K3DF">K3DF</MenuItem>
              <MenuItem value="M6SS">M6SS</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Enabled" />
            <FormControlLabel control={<Checkbox />} label="Required" />
          </FormGroup>
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom>Copy Existing Attributes</Typography>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="product-type-label">Product Type</InputLabel>
            <Select
              labelId="product-type-label"
              id="product-type-select"
              label="Department Code"
            >
              <MenuItem value="A8MQ">A8MQ</MenuItem>
              <MenuItem value="K3DF">K3DF</MenuItem>
              <MenuItem value="M6SS">M6SS</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            multiple
            id="sites"
            options={AttributeDefinitions}
            getOptionLabel={(options) => options.id}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sites"
                value={formState.sites}
                onChange={(e) => dispatch({ type: 'SITES', payload: e.target.value })}
              />
            )}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}

export default CreateModal;
