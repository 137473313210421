import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataFabricObject,
} from '../../../../../redux/wizard/nike/nike_wizard';

function Fabric({ sites, loading }) {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const { activeStep } = nike_wizard.productData;
  const [fabric, setFabric] = React.useState({
    fabric_type: '',
    translations: sites.map((site) => site.countries.map((country) => ({
      translation: '',
      country_code: country,
    }))).flat(),
  });

  React.useEffect(() => {
    const reduxWithAddedSites = {
      ...nike_wizard.productData.fabric,
      translations: sites.map((site) => site.countries.map((country) => ({
        translation: nike_wizard.productData.fabric.translations.find((item) => item.country_code
          === country)?.translation || '',
        country_code: country,
      }))).flat(),
    };
    setFabric(reduxWithAddedSites);
    dispatchToRedux(setProductDataFabricObject(reduxWithAddedSites));
  }, []);

  const handleTranslations = (original, setter) => sites.map((site) => site.countries
    .map(async (country) => {
      if (country === 'GB' || country === 'IE') {
        setFabric((prev) => ({
          ...prev,
          translations: prev.translations.map((translation) => {
            if (translation.country_code === country) {
              return {
                ...translation,
                translation: original,
              };
            }
            return translation;
          }),
        }));
      }
      await axios.post('https://translationuatapi.cloud.jdplc.com/translate', {
        ObjectId: 0,
        Language: country,
        Text: original,
      }, {
        withCredentials: false,
      })
        .then(({ data }) => {
          setter((prev) => ({
            ...prev,
            translations: prev.translations.map((translation) => {
              if (translation.country_code === country && translation.translation === '') {
                return {
                  ...translation,
                  translation: data.text,
                };
              }
              return translation;
            }),
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    }));

  return (
    <section>
      <Typography
        variant="h5"
        className="font-gothammedium text-neutral-800 border-b border-neutral-300"
        gutterBottom
      >
        Fabric
      </Typography>
      <Typography variant="body1" className="text-neutral-800" marginBottom={4}>
        If translations don&apos;t load please click the translate button.
      </Typography>
      <Stack spacing={2}>
        <TextField
          label="Fabric"
          value={fabric.fabric_type}
          onChange={(event) => setFabric({ ...fabric, fabric_type: event.target.value })}
          fullWidth
          required
          error={fabric.fabric_type === ''}
          disabled={loading.loading}
          InputProps={{
            endAdornment: sites[0].countries.length > 1 && (
              <InputAdornment position="end">
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => handleTranslations(fabric.fabric_type, setFabric)}
                >
                  Translate
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {sites.map((site) => site.countries.map((country) => country !== 'GB' && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          key={`fabric-translation-${country}`}
        >
          <img
            loading="lazy"
            width="40"
            src={`https://flagcdn.com/w40/${country.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`}
            alt=""
          />
          <TextField
            label={`${country} Translation`}
            placeholder="Click translate button for auto translation."
            fullWidth
            value={fabric.translations
              .find((translation) => translation.country_code === country)?.translation}
            onChange={(e) => setFabric({
              ...fabric,
              translations: fabric.translations
                .map((translation) => (translation.country_code === country
                  ? {
                    ...translation,
                    translation: e.target.value,
                  } : translation)),
            })}
          />
        </Stack>
        )))}
      </Stack>
      <Stack direction="row" spacing={2} className="float-right my-4">
        <Button
          variant="text"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep - 1));
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            dispatchToRedux(setProductDataActiveStep(activeStep + 1));
            dispatchToRedux(setProductDataFabricObject(fabric));
            if (nike_wizard.productData.fabric.fabric_type !== fabric.fabric_type) {
              dispatchToRedux(setEditedValues('fabric'));
            }
            const stateTranslations = fabric.translations;
            const reduxTranslations = nike_wizard.productData.fabric.translations;
            stateTranslations.forEach((state) => {
              const reduxTranslation = reduxTranslations
                .find((redux) => state.country_code === redux.country_code);
              if (state?.translation !== reduxTranslation?.translation) {
                dispatchToRedux(setEditedValues(`fabricTranslation-${reduxTranslation.country_code}`));
              }
            });
          }}
        >
          Confirm
        </Button>
      </Stack>
    </section>
  );
}

export default Fabric;
