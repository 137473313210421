import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import restrictKeys from '../../../../../utils/restrictKeys';
import splitString from '../../../../../utils/splitIdString';

function IdsFilter({ onError }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const idType = searchParams.get('idType');
  const [chosenIdType, setChosenIdType] = React.useState('');
  const ids = searchParams.get('ids');
  const [enteredIds, setEnteredIds] = React.useState('');

  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  React.useEffect(() => {
    setEnteredIds(ids || '');
  }, [ids]);

  React.useEffect(() => {
    if (!idType) {
      setChosenIdType('');
    }
  }, [idType]);

  React.useEffect(() => {
    if (error) {
      if (chosenIdType === 'productIds') {
        setErrorMsg("One or more Id's contain 10 or more digits");
      }
    }
  }, [error]);

  const handleKeyPress = (event) => {
    if (chosenIdType === 'productIds') {
      restrictKeys(event);
    }
  };

  const formatSelectedType = () => {
    switch (idType) {
      case 'productIds':
        return 'Enter Product Id\'s';
      case 'plus':
        return 'Enter PLU\'s';
      default:
        return 'Please Select A ID Type';
    }
  };

  const handleClearingData = () => {
    setEnteredIds('');
    setError(false);
    onError(false);
    setSearchParams((params) => {
      params.delete('ids');
      return params;
    });
  };

  const handleIdTypeChange = (event) => {
    setChosenIdType(event.target.value);
    handleClearingData();
    if (event.target.value === '') {
      setSearchParams((params) => {
        params.delete('idType');
        return params;
      });
      handleClearingData();
    }
    if ((ids || enteredIds) && event.target.value !== '') {
      setSearchParams((params) => {
        params.set('idType', event.target.value);
        return params;
      });
    }
  };

  const handleIdChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue !== '') {
      setEnteredIds(inputValue);
      setError(false);
      onError(false);

      if (chosenIdType !== '') {
        setSearchParams((params) => {
          params.set('idType', chosenIdType);
          params.set('ids', inputValue);
          return params;
        });
      }

      if (idType === 'productIds') {
        const maxLength = idType === 'productIds' ? 9 : 13;
        const splitIds = splitString(inputValue);

        if (splitIds.some((str) => str.length > maxLength)) {
          setError(true);
          onError(true);
        } else {
          setError(false);
          onError(false);
        }
      }
    } else {
      handleClearingData();
    }
  };

  React.useEffect(() => {
    if (chosenIdType && enteredIds) {
      setSearchParams((params) => {
        params.set('idType', chosenIdType);
        params.set('ids', enteredIds);
        return params;
      });
    }
  }, []);

  React.useEffect(() => {
    if (enteredIds) {
      setSearchParams((params) => {
        params.set('ids', enteredIds);
        return params;
      });
    }
  }, [enteredIds]);

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Select ID Type</Typography>
        <div className="flex flex-col gap-2 w-[215px]">
          <FormControl fullWidth size="small" id="id-filter-select-label">
            <InputLabel id="id-filter-select-label">ID Type</InputLabel>
            <Select
              labelId="id-filter-select-label"
              label="ID Type"
              value={chosenIdType || idType || ''}
              onChange={(event) => handleIdTypeChange(event)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="productIds">Product Ids</MenuItem>
              <MenuItem value="plus">PLUs</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="id-filter-textfield"
            value={enteredIds}
            placeholder={formatSelectedType()}
            multiline
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            rows={5}
            onKeyPress={handleKeyPress}
            onChange={handleIdChange}
            disabled={!chosenIdType && !idType}
            type="text"
            error={error}
            helperText={error && errorMsg}
          />
        </div>
      </Stack>
    </div>
  );
}

export default IdsFilter;
