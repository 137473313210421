import postRequest from '../../../../postRequest';

/**
 * Post request for getting all attribute lookup options.
 *
 * @param {number} Page 1
 * @param {number} Size 5
 * @param {number} productAttributeDefId 20019
 * @param {number} languageId 1
 * @param {string} name "Luggage"
 * @param {boolean} isDefaultValue true
 *
 */
export default function postLookupOptions(
  Page,
  Size,
  productAttributeDefId,
  languageId,
  name,
  isDefaultValue,
) {
  const endpoint = `/oc-data-management/v1/attributes/attribute-lookup-options?Page=${Page}&Size=${Size}`;

  const body = {
    productAttributeDefId,
    languageId,
    name,
    isDefaultValue,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Attribute Lookup Options')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
