import getRequest from '../../../../getRequest';

/**
 * Get Read Only data.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} languageId Language Id of the product
 * @returns {AxiosResponse}
 * @example
 * {
 *    data: [
        {
          "TotalRowCount": 12,
          "items": [
            {
              "VariantId": 1353757,
              "ProductId": 56,
              "ProductName": "Ultra Boost",
              "Brand": {
                "BrandId": 119,
                "Name": "adidas"
              },
              "Size": "6",
              "Ean": "4059323338112 (ean-13)",
              "Approved": true,
              "Plu": "000056",
              "VariantSources": [
                {
                  "SourceId": 1,
                  "Source": "Shogun (Shogun)",
                  "Sku": "000985460",
                  "VariantSourceStockLocations": [
                    {
                      "SourceLocationId": 1,
                      "LocationRef": "0001",
                      "Location": "Kingsway DC",
                      "StockAllocation": 0,
                      "CostPrice": 0,
                      "SellingPrice": 0,
                      "Rrp": 0,
                      "Updated": "0001-01-01T00:00:00",
                      "VatDescription": null,
                      "VatCode": "true",
                      "OutputLocationId": 0,
                      "SourceImportFeedId": 1,
                      "VariantPriceId": 0,
                      "IsInternalPrice": false,
                      "IsStockEnabled": false,
                      "PriceUpdated": "0001-01-01T00:00:00"
                    },
                  ],
                }
              ]
            }
          ],
        }
 *    ]
 * }
 */
export default function getVariants(productId, siteId, languageId) {
  const query = languageId ? `?LanguageId=${languageId}` : '';
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/variants/${query}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Variants')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
