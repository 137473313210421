import postRequest from '../../../../postRequest';

/**
 * Post request to re calculate how many products will be affected by margin threshold change.
 *
 * @param {number} siteId
 * @param {number} marginThresholdConfigId
 * @param {number} newMarginThreshold
 * @param {boolean} useForAllProducts
 *
 */
function postReCalculate(marginThresholdConfigId, newMarginThreshold, useForAllProducts, siteId) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/re-calculate';
  const body = {
    siteId,
    newMarginThreshold,
    useForAllProducts,
    marginThresholdConfigId,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Get Margin Threshold Products Affected Calculation')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postReCalculate;
