/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20240718() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S11/E18 = S12/E19</h2>
      <p className="-mt-4">18th July 2024</p>

      <h5>
        Notice
      </h5>

      <p><strong>Central PIM access have been shut down.</strong></p>

      <h3 className="font-gothammedium text-neutral-700">Fixes & Improvements</h3>

      <h4>PLP</h4>

      <ul className="-mt-4">
        <li>Open Products in new tab from the PLP</li>
        <li>(Fixed) : Product details not loading after switching facias</li>
      </ul>

      <h4>Prism Set-up</h4>

      <ul className="-mt-4">
        <li>Switch to redux for all URL uses of site code</li>
        <li>(Fixed) : Unable to add new users to Prism (The field UserName is invalid)</li>
        <li>(Fixed) : Displaying Fluent stock on the Shogun-SF location</li>
      </ul>

      <h4>Dropship Wizard</h4>

      <ul className="-mt-4">
        <li>Submitted products are marked as in-review until they are re-submitted twice</li>
      </ul>

      <h4>Margin Threshold</h4>
      <ul className="-mt-4">
        <li>(Logs) Margin Threshold / Bulk - Exported file generating with bulk process ID instead of user given name</li>
      </ul>

      <h4>Attribute Definition</h4>
      <ul className="-mt-4">
        <li>UI Improvements, increased the Name Value column. Users are able to see longer names</li>
        <li>Allow user to create duplicate attribute key for multiple sites</li>
        <li>(Fixed) : Unable to create the global attribute</li>
        <li>(Fixed) : Attributes not showing up when adding to Product Type</li>
      </ul>

      <h4>Styles</h4>
      <ul className="-mt-4">
        <li>(Fixed) : Products Search (PLU and ID) is returning wrong data</li>
      </ul>

      <h4>Bulk Operations</h4>
      <ul className="-mt-4">
        <li>(Fixed) : Search By Attributes in bulk returning incorrect values</li>
        <li>(Fixed) : Pre Release Dates- Text is overlapping with the line in the success tab for value upload PRE Release Products Bulk operation</li>
        <li>Allow users to see their logs by default</li>
      </ul>

    </section>
  );
}

export default Release20240718;
