import axios from 'axios';

import patchProductAttribute from '../../../api/intergration/pim/products/dropshipment/patches/patchProductAttribute';
import postAddProductLog from '../../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';
import isEmpty from '../../../utils/isEmpty';

function productAttribute(userEmail, nike_wizard, sitesRes, country) {
  return new Promise((
    resolve,
    reject,
  ) => {
    const site = sitesRes.data.Data.find((sr) => sr.CountryCode === country && sr.Fascia === 'JD');
    const siteCode = country === 'GB' ? 'JD' : site.SiteCode;

    const primaryColour = nike_wizard.productData.colours.primary_colour;

    const fabric = nike_wizard.productData.fabric.translations
      .find((t) => t.country_code === country);
    const fabricData = fabric == null || fabric?.country_code === 'GB'
      ? nike_wizard.productData.fabric.fabric_type : fabric.translation;

    const description = nike_wizard.productData.product_description.translations
      .find((t) => t.country_code === country);
    const descriptionData = description == null || description?.country_code === 'GB'
      ? nike_wizard.productData.product_description.description
      : description.translation;

    const languageIso = sitesRes.data.Data.find((c) => c.CountryCode === country
                                                        && c.Fascia === 'JD');
    if (!isEmpty(fabricData) && !isEmpty(descriptionData)) {
      patchProductAttribute(
        nike_wizard.productId,
        siteCode,
        primaryColour,
        fabricData,
        descriptionData,
        languageIso.LanguageCode,
      )
        .then((res) => {
          if (res.data.Success) {
            if (nike_wizard.editedValues.includes('colour')) {
              postAddProductLog(
                nike_wizard.productId,
                siteCode,
                `[INFO]: User ${userEmail} updated PrimaryColour: ${primaryColour} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
              );
            }
            if (nike_wizard.editedValues.includes('fabric')) {
              postAddProductLog(
                nike_wizard.productId,
                siteCode,
                `[INFO]: User ${userEmail} updated Fabric: ${fabricData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
              );
            }
            if (nike_wizard.editedValues.includes(`fabricTranslation-${languageIso.CountryCode}`)) {
              postAddProductLog(
                nike_wizard.productId,
                siteCode,
                `[INFO]: User ${userEmail} updated Fabric: ${fabricData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
              );
            }
            if (nike_wizard.editedValues.includes('description')) {
              postAddProductLog(
                nike_wizard.productId,
                siteCode,
                `[INFO]: User ${userEmail} updated Description: ${descriptionData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
              );
            }
            if (nike_wizard.editedValues.includes(`descriptionTranslation-${languageIso.CountryCode}`)) {
              postAddProductLog(
                nike_wizard.productId,
                siteCode,
                `[INFO]: User ${userEmail} updated Description: ${descriptionData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}`,
              );
            }
            resolve(res.data);
          } else {
            postAddProductLog(
              nike_wizard.productId,
              siteCode,
              `[ERROR]: User ${userEmail} FAILED to update PrimaryColour: ${primaryColour}, Fabric: ${fabricData} and Description: ${descriptionData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}. Returned Error Message: "${res.data.ErrorMessage}".`,
            );
            reject(res.data.ErrorMessage);
          }
        })
        .catch((err) => {
          // If the error message contains the word Cancel then dont do error stuff.
          // Reason for this is because if a request is cancelled, this isn't an error to do with
          // saving data so the user doesn't need to be notified of this.
          if (axios.isCancel(err)) {
            resolve();
          } else {
            postAddProductLog(
              nike_wizard.productId,
              siteCode,
              `[ERROR]: User ${userEmail} FAILED to update PrimaryColour: ${primaryColour}, Fabric: ${fabricData} and Description: ${descriptionData} for ProductId: ${nike_wizard.productId} - SiteCode: ${siteCode} - ISO: ${languageIso.CountryCode}. Returned Error Message: "${err.message}".`,
            );
            reject(err);
          }
        });
    } else {
      resolve({ Success: true, ErrorMessage: null });
    }
  });
}

export default productAttribute;
