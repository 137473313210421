function httpErrors(httpCode) {
  switch (httpCode) {
    case '400':
      return 'Bad Request: Cannot process request as data may be wrong. Please review entered values';
    case '401':
      return 'Unauthorized: Please retry request, if this is not the first time you\'re seeing this. Please refresh the page or log-out then log back in.';
    case '403':
      return 'Forbidden: Please contact lead to grant permission.';
    case '404':
      return 'Not Found: Request cannot be found.';
    case '405':
      return 'Method Not Allowed: You cannot call this method.';
    case '408':
      return 'Request Timeout: Server has stopped processing this request.';
    case '429':
      return 'Too Many Requests: Server has blocked current request as too many have been made in a short period of time.';
    case '500':
      return 'Internal Server Error: Server has errored, please try again.';
    case '502':
      return 'Bad Gateway: Server got invalid response.';
    case '504':
      return 'Gateway Timeout: Server didn\'t get response within time.';
    default:
      return null;
  }
}

export default httpErrors;
