import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getAllUsers from '../../api/private/users_management/gets/getAllUsers';
import getUserApiAccessTypes from '../../api/private/users_management/gets/getUserApiAccessTypes';
import getUserRights from '../../api/private/users_management/gets/getUserRights';
import getUserStatuses from '../../api/private/users_management/gets/getUserStatuses';
import getUserTypes from '../../api/private/users_management/gets/getUserTypes';
// import postDisableUser from '../../api/private/users_management/posts/postDisableUser';
import postUpdateUser from '../../api/private/users_management/posts/postUpdateUser';
import VirtualizedAutocomplete from '../../components/VirtualizedAutocomplete';
import NewUserModal from './modals/NewUser';

/**
{
  "accountName": "string", // Part of userName before @
  "userType": 1, // JdplcUser | ApiUser
  "userRight": 10, // Admin, Dev, Finance etc
  "siteIds": [
    0
  ],
  "status": 0, // Active | Disabled
  "apiAccessType": 1, // PublicApi | PrivateApi
  "userName": "string" // Email Address
}
 */

function FieldInputBox(props) {
  const {
    id,
    value,
    field,
  } = props;
  const apiRef = useGridApiContext();

  const wrapperRef = React.useRef(null);

  const [currentValue, setCurrentValue] = React.useState(value);

  React.useEffect(() => {
    async function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        await apiRef.current.setEditCellValue({ id, field, value: currentValue });
        apiRef.current.stopCellEditMode({ id, field });
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, currentValue]);

  return (
    <Box ref={wrapperRef}>
      <TextField
        label="Standard"
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
        value={currentValue}
        className="w-full"
        onChange={(event) => setCurrentValue(event.target.value)}
      />
    </Box>
  );
}

function FieldSelectionBox(props) {
  const {
    id,
    value,
    field,
    options,
  } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus
      className="w-full"
    >
      {(Object.entries(options))?.map(([key]) => (
        <option>{key}</option>
      ))}
    </Select>
  );
}

function FieldAutocompleteBox(props) {
  const {
    id,
    value,
    field,
    options,
  } = props;
  const apiRef = useGridApiContext();

  const [sites, setSites] = React.useState([]);

  const handleChange = async (newValues) => {
    await apiRef.current.setEditCellValue({ id, field, value: newValues.map((obj) => obj.SiteId) });
    apiRef.current.stopCellEditMode({ id, field });
  };

  React.useEffect(() => {
    const validSiteIds = value.filter((siteId) => options.some((option) => option.SiteId === siteId));
    const foundSites = validSiteIds.map((siteId) => options.find((obj) => obj.SiteId === siteId));
    setSites(foundSites);
  }, [value, options]);

  return (
    <VirtualizedAutocomplete
      value={sites}
      onSelectedValue={handleChange}
      size="small"
      autoFocus
      multiple
      options={options}
      getOptionLabel={(option) => option?.Name}
      optionKey="Name"
    />
  );
}

const renderFieldSelectionBox = (params, options) => (
  <FieldSelectionBox {...params} options={options} />
);

const renderFieldAutocompleteBox = (params, options) => (
  <FieldAutocompleteBox {...params} options={options} />
);

const renderFieldInputBox = (params, options) => (
  <FieldInputBox {...params} options={options} />
);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function Users() {
  const reduxFascias = useSelector((state) => state.fascias);

  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [userTypes, setUserTypes] = React.useState({});
  const [userRights, setUserRights] = React.useState({});
  const [apiAccessTypes, setApiAccessTypes] = React.useState({});
  const [userStatus, setUserStatus] = React.useState({});
  const [loadingTable, setLoadingTable] = React.useState(true);

  const [fascias, setFascias] = React.useState([]);

  const [rows, setRows] = React.useState([]);

  const columns = [
    {
      field: 'AccountName',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      editable: true,
    },
    {
      field: 'UserName',
      headerName: 'Email Address',
      flex: 1,
      minWidth: 180,
      editable: true,
      renderEditCell: (params) => renderFieldInputBox(params),
    },
    {
      field: 'UserRight',
      headerName: 'Access Level',
      flex: 1,
      minWidth: 150,
      editable: true,
      renderEditCell: (params) => renderFieldSelectionBox(params, userRights),
    },
    {
      field: 'UserType',
      headerName: 'User Type',
      flex: 1,
      minWidth: 150,
      editable: true,
      renderEditCell: (params) => renderFieldSelectionBox(params, userTypes),
    },
    {
      field: 'ApiAccessType',
      headerName: 'Access Type',
      flex: 1,
      minWidth: 150,
      editable: true,
      renderEditCell: (params) => renderFieldSelectionBox(params, apiAccessTypes),
    },
    {
      field: 'SiteIds',
      headerName: 'Site Access',
      flex: 1,
      minWidth: 150,
      editable: true,
      renderCell: (params) => (
        <div>
          {params.value.map((id) => (
            <p>{fascias.find((obj) => obj.SiteId === id)?.Name}</p>
          ))}
        </div>
      ),
      renderEditCell: (params) => renderFieldAutocompleteBox(params, fascias),
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      editable: true,
      renderEditCell: (params) => renderFieldSelectionBox(params, userStatus),
    },
  ];

  React.useEffect(() => {
    setFascias(reduxFascias.map((obj) => obj.Sites).flat());
  }, []);

  const getTableInformation = async (values) => {
    await getAllUsers()
      .then((res) => {
        const userTypeEntries = Object.entries(values[0]);
        const apiATEntries = Object.entries(values[1]);
        const userRightsEntries = Object.entries(values[2]);
        const userStatusEntries = Object.entries(values[3]);

        const rowMapping = res.data.map((obj) => {
          const foundUserType = userTypeEntries.find(([, value]) => value === obj.UserType);
          const foundUserRight = userRightsEntries.find(([, value]) => value === obj.UserRight);
          const foundApiAccessType = apiATEntries.find(([, value]) => value === obj.ApiAccessType);
          const foundStatus = userStatusEntries.find(([, value]) => value === obj.Status);

          return {
            ...obj,
            UserType: foundUserType ? foundUserType[0] : obj.UserType,
            UserRight: foundUserRight ? foundUserRight[0] : obj.UserRight,
            ApiAccessType: foundApiAccessType ? foundApiAccessType[0] : obj.ApiAccessType,
            Status: foundStatus ? foundStatus[0] : obj.Status,
          };
        });
        setRows(rowMapping);
      })
      .catch((err) => {
        console.error(err);
      });
    setLoadingTable(false);
  };

  const getAllMappings = async () => {
    const ut = new Promise((resolve, reject) => {
      getUserTypes()
        .then((res) => {
          setUserTypes(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const aat = new Promise((resolve, reject) => {
      getUserApiAccessTypes()
        .then((res) => {
          setApiAccessTypes(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const ur = new Promise((resolve, reject) => {
      getUserRights()
        .then((res) => {
          setUserRights(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const us = new Promise((resolve, reject) => {
      getUserStatuses()
        .then((res) => {
          setUserStatus(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    Promise.all([ut, aat, ur, us])
      .then((values) => getTableInformation(values))
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    getAllMappings();
  }, []);

  const processRowUpdate = React.useCallback(
    async (newRow, userRightsMapping, userTypeMapping, apiAccessTypesMapping, statusMapping) => {
      const newUserRight = userRightsMapping[newRow.UserRight];
      const newUserType = userTypeMapping[newRow.UserType];
      const newApiAccessTypes = apiAccessTypesMapping[newRow.ApiAccessType];
      const newStatus = statusMapping[newRow.Status];

      const response = await postUpdateUser(
        newRow.AccountName,
        newRow.UserName,
        newUserType,
        newUserRight,
        newRow.SiteIds,
        newStatus,
        newApiAccessTypes,
        newRow.Id,
      );
      const userTypeEntries = Object.entries(userTypeMapping);
      const userRightsEntries = Object.entries(userRightsMapping);
      const apiATEntries = Object.entries(apiAccessTypesMapping);
      const userStatusEntries = Object.entries(statusMapping);

      const responseData = response.data;

      return {
        ...responseData,
        UserType: (userTypeEntries.find(([, value]) => value === responseData.UserType)) == null
          ? responseData.UserType
          : (userTypeEntries.find(([, value]) => value === responseData.UserType))[0],
        UserRight: (userRightsEntries.find(([, value]) => value === responseData.UserRight)) == null
          ? responseData.UserRight
          : (userRightsEntries.find(([, value]) => value === responseData.UserRight))[0],
        ApiAccessType: (apiATEntries.find(([, value]) => value
          === responseData.ApiAccessType)) == null
          ? responseData.ApiAccessType
          : (apiATEntries.find(([, value]) => value === responseData.ApiAccessType))[0],
        Status: (userStatusEntries.find(([, value]) => value === responseData.Status)) == null
          ? responseData.Status
          : (userStatusEntries.find(([, value]) => value === responseData.Status))[0],
      };
    },
    [postUpdateUser],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    toast.error(error);
  }, []);

  return (
    <Container maxWidth="xl">
      {open && (
        <NewUserModal
          open={open}
          handleClose={() => {
            getTableInformation();
            setOpen(false);
          }}
          userTypesMapping={userTypes}
          userRightsMapping={userRights}
          apiAccessTypesMapping={apiAccessTypes}
        />
      )}
      <div className="pt-4 md:p-8">
        <div className="flex flex-col justify-between mb-4 border-b md:mb-8 md:items-center md:flex-row">
          <div>
            <Typography variant="h4" className="font-gothambold">
              Users
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Here you can add, edit and delete users in PRISM.
              {' '}
              <span className="underline underline-offset-2 decoration-secondary-600">
                Double click field to edit.
              </span>
            </Typography>
          </div>
          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setOpen((prev) => !prev)}
              className="mb-4 md:mb-0"
            >
              Add User
            </Button>
          </Stack>
        </div>

        <Box component="div" sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
            getRowId={(r) => r.Id}
            processRowUpdate={(newRow) => processRowUpdate(
              newRow,
              userRights,
              userTypes,
              apiAccessTypes,
              userStatus,
            )}
            components={{
              Toolbar: CustomToolbar,
            }}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            disableSelectionOnClick
            className="bg-white"
            getRowHeight={() => 'auto'}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 30, 40]}
            loading={loadingTable}
          />
        </Box>
      </div>
    </Container>
  );
}

export default Users;
