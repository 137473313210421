import patchRequest from '../../../patchRequest';

/**
 * Updates the products Name and/or Brand.
 *
 * @param {number} siteId - 1
 * @param {number} productId - 15974689
 * @param {Date} releaseDate - 12/07/1974
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchReleaseDate(
  siteId,
  productId,
  releaseDate,
) {
  const endpoint = `/oc-product/v1/product/${siteId}/${productId}/release-date`;

  const body = {
    releaseDate,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Relese date')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchReleaseDate;
