import postRequest from '../../../postRequest';

/**
 * Updates the brand group brands.
 *
 * @param {number} siteId - 1
*  @param {number[]} productIds
 * @returns {Promise} { Success: true }, status: 200 }
 */
export default async function patchMediaCheck(
  siteId,
  productIds,
) {
  const endpoint = `/oc-product/v1/product-refresh-missing-images?productId=${productIds}&siteId=${siteId}`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, 'Refresh Images')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
