/**
 * Takes Id's separated by commas, spaces or new lines and splits them
 * into an array of id's.
 *
 * @param {string} str String you want to split
 * @param {string} type If you want to Id's to be converted to a number
 * @returns {(string | number)[]}
 */
export default function splitString(str, type) {
  if (str) {
    if (Array.isArray(str)) { // if value is already and array
      return [];
    }
    // split string by either comma, space or new line
    const idArray = str.split(/,| |\n/).filter((str2) => str2 !== '');
    if (type === 'number') {
      return idArray.map((str2) => Number(str2));
    }
    return idArray;
  }
  return [];
}
