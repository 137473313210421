import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function FilterDraw({ children, onSubmitFilter = false }) {
  const [openFilter, setOpenFilter] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setOpenFilter(open);
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={toggleDrawer(true)}>Filter</Button>

      <Drawer anchor="right" open={openFilter} onClose={toggleDrawer(false)} className="overflow-hidden relative">
        {children}
        <Box className="bg-dark-blue bottom-0 flex justify-around p-6 fixed top-auto max-w-lg w-full">
          <Button variant="outlined" color="background">Reset</Button>
          <Button variant="contained" color="success" onClick={() => onSubmitFilter(true)}>Submit</Button>
        </Box>
      </Drawer>
    </>
  );
}

export default FilterDraw;

FilterDraw.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmitFilter: PropTypes.func,
};

FilterDraw.defaultProps = {
  onSubmitFilter: () => { },
};
