import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import deleteProductTypesPluId from '../../../api/oc_product/bulk/bulk_process/product_types/plu_id/deletes/deleteProductTypesPluId';
import postProductTypesPluId from '../../../api/oc_product/bulk/bulk_process/product_types/plu_id/posts/postProductTypesPluId';
import deleteProductTypesProductId from '../../../api/oc_product/bulk/bulk_process/product_types/product_id/deletes/deleteProductTypesProductId';
import postProductTypesProductId from '../../../api/oc_product/bulk/bulk_process/product_types/product_id/posts/postProductTypesProductId';
// import getLanguages from '../../../api/oc_product/product/languages/gets/getLanguages';
import getProductTypes from '../../../api/oc_product/sites/product_types/gets/getProductTypes';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import doesObjectContainEmptyValues from '../../../utils/doesObjectContainEmptyValues';
import restrictKeys from '../../../utils/restrictKeys';
import splitString from '../../../utils/splitIdString';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONTENT_LANGUAGE':
      return {
        ...state,
        languageId: action.payload,
      };
    case 'ACTION':
      return {
        ...state,
        action: action.payload,
      };
    case 'SOURCE':
      return {
        ...state,
        sourceId: action.payload,
      };
    case 'TYPE_IDS':
      return {
        ...state,
        productTypeIds: action.payload?.map((obj) => obj.ProductTypeId),
      };
    case 'REFERENCES':
      return {
        ...state,
        referenceIds: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};

function ProductType({ refreshReports }) {
  const submitBtn = React.useRef(null);

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  // const siteGroupId = useSelector((state) => state.user.loginResponse.selectedSite.SiteGroupId);

  const initialState = {
    action: '',
    sourceId: '',
    productTypeIds: [],
    referenceIds: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const [idType, setIdType] = React.useState('plu');

  // const [languagesOptions, setLanguagesOptions] = React.useState([]);
  const [sourceOptions, setSourceOptions] = React.useState([]);
  const [productTypeOptions, setProductTypeOptions] = React.useState([]);

  const [productIdLengthError, setProductIdLengthError] = React.useState(false);

  const [buttonWidth, setButtonWidth] = React.useState(0);
  const [buttonHeight, setButtonHeight] = React.useState(0);

  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.get('omsId');

  const handleBulk = (e) => {
    e.preventDefault();
    if (idType === 'plu') {
      if (state.action === 4) { // delete
        deleteProductTypesPluId({
          referenceIds: splitString(state.referenceIds),
          productTypeIds: state.productTypeIds,
          sourceId: state.sourceId,
          siteId,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      } else {
        postProductTypesPluId({
          referenceIds: splitString(state.referenceIds),
          productTypeIds: state.productTypeIds,
          sourceId: state.sourceId,
          siteId,
        })
          .then(() => refreshReports(true))
          .catch((err) => console.error(err));
      }
    } else if (state.action === 4) { // delete
      deleteProductTypesProductId({
        referenceIds: splitString(state.referenceIds),
        productTypeIds: state.productTypeIds,
        siteId,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    } else {
      postProductTypesProductId({
        referenceIds: splitString(state.referenceIds),
        productTypeIds: state.productTypeIds,
        siteId,
      })
        .then(() => refreshReports(true))
        .catch((err) => console.error(err));
    }
  };

  const handleKeyPress = (event) => {
    if (idType === 'productid') {
      restrictKeys(event);
    }
  };

  React.useEffect(() => {
    dispatch({ type: 'RESET', payload: initialState });
    // getLanguages(siteId)
    //   .then((res) => setLanguagesOptions(res.data))
    //   .catch((err) => console.error(err));
    setIdType('plu');
    getSources(siteId)
      .then((res) => setSourceOptions(res.data))
      .catch((err) => console.error(err));
    getProductTypes(siteId)
      .then((res) => setProductTypeOptions(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect(() => {
    if (submitBtn.current) {
      const sbmBtn = submitBtn.current.getBoundingClientRect();
      setButtonWidth(sbmBtn.width);
      setButtonHeight(sbmBtn.height);
    }
  }, [submitBtn.current]);

  React.useEffect(() => {
    if (idType !== 'plu') {
      delete state.sourceId;
    } else if (!state.sourceId) {
      state.sourceId = '';
    }
    const isEmpty = doesObjectContainEmptyValues(state);
    setSubmitDisabled(isEmpty);
  }, [state, idType]);

  React.useEffect(() => {
    const splitIds = splitString(state.referenceIds);
    if (splitIds.some((str) => str.length > 9)) {
      if (idType === 'productid') {
        setProductIdLengthError(true);
        setSubmitDisabled(true);
      } else {
        setProductIdLengthError(false);
      }
    } else {
      setProductIdLengthError(false);
    }
  }, [state.referenceIds, idType]);

  React.useEffect(() => {
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
         * For some reason we have to do this...
         * If you do the, if not then delete, statement below within the for above
         * or do params.forEach
         * The loop with miss every even indexed key...
         */
      arr.forEach((key) => {
        if (key !== 'siteCode' && key !== 'page' && key !== 'omsId' && key !== 'pageSize') {
          params.delete(key);
        }
      });
      return params;
    });
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <section className="w-full">
        <form className="flex flex-col w-full gap-8 xl:flex-row" onSubmit={handleBulk}>
          <section className="flex flex-col w-full gap-8">
            {/* <div>
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    1
                  </Typography>
                </div>
                <Typography variant="body2">
                  Select Content Language
                </Typography>
              </div>

              <Box sx={{ paddingTop: '1rem' }}>
                <VirtualizedAutocomplete
                  options={languagesOptions.map((obj) => ({ ...obj, Name: obj.LanguageName }))}
                  textFieldLabel="Content Language"
                  textFieldVarian="outlined"
                  value={languagesOptions.find((obj) => obj?.LanguageId === state.languageId)}
                  getOptionLabel={(option) => option.LanguageName}
                  onSelectedValue={(value) => dispatch({ type: 'CONTENT_LANGUAGE', payload: value?.LanguageId })}
                  optionKey="LanguageName"
                />
              </Box>
            </div> */}

            <div>
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    1
                  </Typography>
                </div>
                <Typography variant="body2">
                  Select Action
                </Typography>
              </div>

              <Box sx={{ paddingTop: '1rem' }}>
                <FormControl fullWidth>
                  <InputLabel id="action-label">Action</InputLabel>
                  <Select
                    labelId="action-label"
                    id="action-select"
                    label="Action"
                    value={state.action}
                    onChange={(e) => dispatch({ type: 'ACTION', payload: e.target.value })}
                  >
                    {/* <MenuItem value={1}>Insert</MenuItem> */}
                    {/* <MenuItem value={2}>Read</MenuItem> */}
                    <MenuItem value={3}>Update</MenuItem>
                    <MenuItem value={4}>Delete</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    2
                  </Typography>
                </div>
                <Typography variant="body2">
                  PLU or Product ID
                </Typography>
              </div>

              <Box className="flex justify-start pt-4">
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="type"
                    defaultValue="plu"
                    name="radio-buttons-group"
                    onChange={(e) => setIdType(e.target.value)}
                    value={idType}
                    sx={{ mb: 2 }}
                  >
                    <FormControlLabel value="plu" control={<Radio />} label="PLU" />
                    <FormControlLabel
                      value="productid"
                      control={<Radio />}
                      label="Product ID"
                    />
                  </RadioGroup>
                  {idType === 'plu' ? (
                    <VirtualizedAutocomplete
                      options={sourceOptions}
                      textFieldLabel="Sources"
                      textFieldVariant="outlined"
                      value={sourceOptions.find((obj) => obj?.SourceId === state.sourceId) || null}
                      getOptionLabel={(option) => option.Name}
                      onSelectedValue={(value) => dispatch({ type: 'SOURCE', payload: value?.SourceId })}
                      optionKey="Name"
                    />
                  ) : (
                    <div className="hidden" />
                  )}
                </FormControl>
              </Box>
            </div>
          </section>
          <section className="flex flex-col w-full gap-8">
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    3
                  </Typography>
                </div>
                <Typography variant="body2">
                  Select Product Type
                </Typography>
              </div>
              <VirtualizedAutocomplete
                options={productTypeOptions || []}
                textFieldLabel="Product Type"
                textFieldVariant="outlined"
                getOptionLabel={(option) => option.Name}
                onSelectedValue={(value) => {
                  dispatch({ type: 'TYPE_IDS', payload: value });
                }}
                optionKey="Name"
                disabled={state.languageId === null}
                multiple
                value={productTypeOptions.filter((obj) => state.productTypeIds.includes(obj?.ProductTypeId)) || null}
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    4
                  </Typography>
                </div>
                <Typography variant="body2">
                  Enter
                  {' '}
                  {idType === 'plu' ? 'PLUs' : 'Product Ids'}
                </Typography>
              </div>

              <TextField
                label={idType === 'plu' ? 'PLUs' : 'Product Ids'}
                placeholder={`Type or Paste in your ${idType === 'plu' ? 'PLUs' : 'Product Ids'} here.`}
                multiline
                rows={6}
                value={state.referenceIds}
                onKeyPress={handleKeyPress}
                onChange={(e) => dispatch({ type: 'REFERENCES', payload: e.target.value })}
                error={productIdLengthError}
                helperText={productIdLengthError ? 'One or more Id\'s contain 10 or more digits' : ''}
              />

              <div className="w-full">
                <div className="relative float-right w-max">
                  <Button
                    ref={submitBtn}
                    variant="contained"
                    type="submit"
                    id="submit-button"
                    disabled={submitDisabled}
                    className="z-10"
                  >
                    Submit
                  </Button>
                  {!submitDisabled && (
                    <div
                      className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 bg-primary-400 animate-ping"
                      style={{ width: `${buttonWidth / 1.5}px`, height: `${buttonHeight / 1.25}px` }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </form>
      </section>
    </Container>
  );
}

export default ProductType;
