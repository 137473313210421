import getRequest from '../../../../getRequest';

/**
 * Get Disabled Categories.
 * @param {number} siteId - 1
 * @returns {
 * "CategoryId": 12345,
 * "CategoryName": "Footwear"
 * }
 */
export default async function getDisabledCategories(siteId) {
  const endpoint = `/oc-data-management/v1/category/disabled/${siteId}/`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Disabled Categories')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
