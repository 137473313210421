// import getRequest from '../../../getRequest';
import postRequest from '../../../../../postRequest';

/**
 * Read Only attribute data.
 *
 * @param {number} productId Id of the product
 * @param {number} siteId Site Id of the product
 * @param {number} languageId Language Id of the product
 * @param {number} page Page number
 * @param {number} size Page size
 * @returns {AxiosResponse}
 * @example
 * {
 *    data: [
        {
          "ProductAttributeDefId": 16517,
          "ProductAttributeDefKey": "adjustable_back_system",
          "Value": "No",
          "Id": 140190,
          "Name": "Adjustable Back System",
          "IsReadOnly": true
        }
 *    ]
 * }
 */
export default function postReadOnly(productId, siteId, languageId, page, size, body) {
  const endpoint = `/oc-product/v1/product/${productId}/${siteId}/attributes/read-only?languageId=${languageId}&Page=${page}&Size=${size}`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Read Only Attributes')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
