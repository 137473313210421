import getRequest from '../../../getRequest';

/**
 * Get user ApiAccess types mapping from PRISM db.
 *
 * @returns {object}
 */
function getUserApiAccessTypes() {
  const endpoint = '/users-management/get-user-api-access-types/';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Api Access Types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default getUserApiAccessTypes;
