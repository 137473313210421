import PropTypes from 'prop-types';

/**
 * Gets value from local storage.
 *
 * @param {string} name Name of the key.
 * @returns {Object} Returns requested item.
 */
function getLocalStorage(name) {
  let storedValue = localStorage.getItem(name) || null;
  if (storedValue?.slice(0, 1) === '{' || Array.isArray(storedValue)) {
    storedValue = JSON.parse(storedValue);
  }
  return storedValue;
}

getLocalStorage.propTypes = {
  name: PropTypes.string.isRequired,
  storedValue: PropTypes.string || PropTypes.shape({}),
};

/**
 * Sets key and value to local storage.
 *
 * @param {string} name Name of the key.
 * @param {object} value Value of data wanting to be stored in local storage.
 */
function setLocalStorage(name, value) {
  let incomingValue = value;
  if (typeof value === 'object') incomingValue = JSON.stringify(value);
  localStorage.setItem(name, incomingValue);
}

setLocalStorage.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string || PropTypes.shape({}),
};

/**
 * Removes key from local storage.
 *
 * @param {string} name
 */
function removeLocalStorage(name) {
  localStorage.removeItem(name);
}

removeLocalStorage.propTypes = {
  name: PropTypes.string.isRequired,
};

export { getLocalStorage, removeLocalStorage, setLocalStorage };
