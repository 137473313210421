import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getBrandGroups from '../../../../../api/oc_product/brandGroup/get/getBrandGroup';
import postBrandGroup from '../../../../../api/oc_product/brandGroup/post/postBrandGroup';
import { BrandGroupingContext } from '../BrandGroupingContext';

function BrandGroupingHeader({ brandGroups, setBrandGroups }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const { brands, setFetchData, setReFetchLogs } = useContext(BrandGroupingContext);

  const [filteredBrands, setFilteredBrands] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [searchType, setSearchType] = React.useState('group');
  const [addGroup, setAddGroup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newGroupBrand, setNewGroupBrand] = React.useState('');
  const [newGroupBrands, setNewGroupBrands] = React.useState([]);

  React.useEffect(() => {
    if (searchValue !== '') {
      getBrandGroups(siteId, searchType, searchValue)
        .then((res) => {
          setBrandGroups(res.data);
        })
        .catch((err) => console.error(err));
    } else {
      getBrandGroups(siteId)
        .then((res) => {
          setBrandGroups(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [searchValue, searchType]);

  React.useEffect(() => {
    const filteredBrandsArr = brands?.filter((brand) => !brandGroups?.some((group) => group.BrandGroupName === brand.Name));
    setFilteredBrands(filteredBrandsArr);
  }, [brandGroups, brands]);

  React.useEffect(() => {
    if (searchValue.length > 0) {
      setSearchValue('');
    }
  }, [siteId]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setNewGroupBrand('');
    setNewGroupBrands([]);
  };

  const handleCreateBrandGroup = () => {
    if (newGroupBrand) {
      const selectedBrandIds = newGroupBrands.map((brand) => brand.BrandId);
      const body = {
        groupName: newGroupBrand.Name,
        brandId: selectedBrandIds,
      };
      postBrandGroup(siteId, body)
        .then(() => {
          setFetchData(true);
          setReFetchLogs(true);
          toast.success('Brand Group Created');
          handlePopoverClose();
        })
        .catch((err) => console.error(err));
    }
  };

  const open = Boolean(anchorEl);

  const customRenderOption = (props, option) => (
    <li {...props} key={option.brandId}>
      <div>{option.Name}</div>
    </li>
  );

  return (
    <div className="flex flex-row justify-between w-full">
      <div className="flex flex-col w-full">
        <Typography variant="h5" className="text-secondary-600">Brand Grouping</Typography>
        <div className="flex flex-row justify-between mt-4">
          <div>
            <TextField
              label="Search by brand or group"
              variant="outlined"
              value={searchValue}
              size="small"
              onChange={(event) => setSearchValue(event.target.value)}
              className="mr-6"
            />
            <FormControl size="small" id="type" className="w-[100px]">
              <InputLabel id="type">Type</InputLabel>
              <Select
                label="Type"
                value={searchType || ''}
                onChange={(event) => setSearchType(event.target.value)}
              >
                <MenuItem value="group">Group</MenuItem>
                <MenuItem value="brand">Brand</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              onClick={(event) => {
                setAddGroup(!addGroup);
                handlePopoverOpen(event);
              }}
              variant="contained"
            >
              Create New Group
            </Button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  width: '400px',
                },
              }}
            >
              <div className="flex flex-col p-8">
                <div className="mb-3">
                  <div className="flex flex-row items-center mb-3">
                    <div className="flex items-center justify-center w-4 h-4 p-3 mr-3 text-white rounded-full bg-secondary-500">
                      <Typography variant="caption" className="font-gothammedium">
                        1
                      </Typography>
                    </div>
                    <div className="flex flex-col">
                      <Typography variant="h6" className="mb-3">Select group brand</Typography>
                      <Typography variant="body2" className="mb-3">
                        The group brand can be used as the name of the group and can only
                        be selected for one group
                      </Typography>
                      <Autocomplete
                        options={filteredBrands}
                        getOptionLabel={(brand) => brand?.Name}
                        size="small"
                        onChange={(e, value) => setNewGroupBrand(value)}
                        renderInput={(params) => (
                          <TextField {...params} label="Group Brand" />
                        )}
                        renderOption={customRenderOption}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="flex flex-row items-center mb-3">
                    <div className="flex items-center justify-center w-4 h-4 p-3 mr-3 text-white rounded-full bg-secondary-500">
                      <Typography variant="caption" className="font-gothammedium">
                        2
                      </Typography>
                    </div>
                    <div className="flex flex-col w-full">
                      <Typography variant="h6" className="mb-3">Select individual brands</Typography>
                      <Autocomplete
                        multiple
                        id="brands"
                        size="small"
                        value={newGroupBrands}
                        onChange={(e, value) => setNewGroupBrands(value)}
                        options={brands || []}
                        getOptionLabel={(brand) => (brand ? brand.Name : '')}
                        limitTags={4}
                        renderInput={(params) => <TextField {...params} label="Brand" />}
                        renderOption={customRenderOption}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    onClick={handleCreateBrandGroup}
                    disabled={!newGroupBrand || newGroupBrands.length === 0}
                  >
                    Save
                  </Button>
                  <Button variant="text" className="text-primary-600" onClick={handlePopoverClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandGroupingHeader;
