import postRequest from '../../../../../../postRequest';

/**
 * Get reports of the current bulk operations.
 *
 * @param {number} siteId
 * @param {Array<0 | 1 | 2 | 3 | 4 | 5 | 10 | 20 | 30>} statuses
 * @returns Report of current Bulk Uploads
 */
export default function postMediaCheckReports(siteId, statuses) {
  const endpoint = `oc-product-bulkapi/v1/bulk-process/sites/${siteId}/media-check-reports`;

  const body = {
    statusList: statuses,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Reports')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
