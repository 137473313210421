import React from 'react';

function Release20231215() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">NEW FEATURE</h2>
      <p className="-mt-4">15th December 2023</p>

      <h3 className="font-gothammedium text-neutral-700">
        Data Management
        {' -> '}
        Brand Grouping
      </h3>
      <h4>
        Ability to group brands together for improved search experience
      </h4>
      <p>If these brands are grouped under one group ‘Adidas’</p>
      <ul className="-mt-4">
        <li>Adidas</li>
        <li>Adidas Terrex</li>
        <li>Adidas Originals</li>
        <li>Stella McCartney x Adidas</li>
      </ul>
      <p>
        On the MESH website, when I filter for Adidas brand I
        should see all products that come under brand Adidas, Adidas Originals,
        Adidas Terrex and Stella McCartney x Adidas. Currently, I have to do
        select all these 4 brands on the left hand side brand filter to see all
        these products. By grouping them together it will improve the search
        experience for users on the website, and generate more sales.
      </p>
      <h4>
        Scope
      </h4>
      <p>Fascias:</p>
      <ul className="-mt-4">
        <li>All JD sites</li>
        <li>All FP sites</li>
        <li>All Size sites</li>
        <li>HIP</li>
      </ul>
      <p>Feeds:</p>
      <ul className="-mt-4">
        <li>FH feed: yes</li>
        <li>Aurora feed: no</li>
        <li>IR feeds: Giuseppe has confirmed no change required for IR feeds</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bulk Uploads</h3>
      <h4>New Uploads</h4>
      <ul className="-mt-4">
        <li>Enable Stock</li>
      </ul>
      <h4>Speed Improvements</h4>
      <ul className="-mt-4">
        <li>Product Types</li>
        <li>New In Date</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bug Fixes and Improvements</h3>
      <ul className="-mt-4">
        <li>PDP Enable Stock - All known bugs</li>
        <li>Updated important text for bulk operations</li>
      </ul>
    </section>
  );
}

export default Release20231215;
