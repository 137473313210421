import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setEditedValues,
  setProductDataActiveStep,
  setProductDataBrandObject,
} from '../../../../../redux/wizard/nike/nike_wizard';

function Brand({ productItems, activeStep, loading }) {
  const dispatchToRedux = useDispatch();
  const nike_wizard = useSelector((state) => state.nike_wizard);

  const [brand, setBrand] = React.useState({
    brand_name: {
      id: 0,
      name: '',
    },
    subbrand: '',
    shogun_supplier_number: '',
  });

  const nikeOrJordan = (subbrand) => {
    if (subbrand.toLowerCase() === 'jordan') {
      return '61226';
    }
    return '15';
  };

  React.useEffect(() => {
    setBrand(nike_wizard.productData.brand);
  }, []);

  React.useEffect(() => {
    setBrand((prev) => ({
      ...prev,
      shogun_supplier_number: nikeOrJordan(brand.subbrand),
    }));
  }, [brand.subbrand]);

  React.useEffect(() => {
    setBrand({
      ...nike_wizard.productData.brand,
      shogun_supplier_number: nikeOrJordan(nike_wizard.productData.brand.subbrand),
    });
  }, [nike_wizard.productData.brand, nike_wizard.productData.brand.subbrand]);

  return (
    <section>
      <Typography
        variant="h5"
        className="font-gothammedium text-neutral-800 border-b border-neutral-300"
        marginBottom={4}
      >
        Brand
      </Typography>
      <Stack spacing={2} mb={2}>
        <Autocomplete
          loading={loading.loading}
          loadingText="Loading Brands..."
          options={productItems}
          getOptionLabel={(option) => (option.BrandName == null ? option : option.BrandName)}
          onChange={(event, value) => setBrand({
            ...brand,
            brand_name: {
              id: value.BrandId,
              name: value.BrandName,
            },
          })}
          value={brand.brand_name.name}
          disabled={brand.brand_name.name !== ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Brands"
              required
              error={brand.brand_name.name === ''}
            />
          )}
        />
        <TextField
          label="Sub-Brand Name"
          helperText="(Optional)"
          value={brand.subbrand}
          onChange={(event) => {
            setBrand({ ...nike_wizard.productData.brand, subbrand: event.target.value });
          }}
        />
        <TextField
          label="Shogun Supplier Number"
          disabled
          className="cursor-not-allowed"
          value={brand.shogun_supplier_number}
        />
      </Stack>
      <Button
        variant="contained"
        color="info"
        className="float-right"
        onClick={() => {
          dispatchToRedux(setProductDataActiveStep(activeStep + 1));
          dispatchToRedux(setProductDataBrandObject(brand));
          if (nike_wizard.productData.brand.brand_name.name !== brand.brand_name.name) {
            dispatchToRedux(setEditedValues('brandname'));
          }
          if (nike_wizard.productData.brand.brand_name.name !== brand.brand_name.subbrand) {
            dispatchToRedux(setEditedValues('subbrand'));
          }
        }}
      >
        Confirm
      </Button>
    </section>
  );
}

export default Brand;
