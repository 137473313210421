import {
  Button, MenuItem, Select,
  TextField,
} from '@mui/material';
import React, {
  useEffect,
} from 'react';

function SearchColour({
  language, searchValue, setSearchValue, onSearch, onReset, colourType, setcolourType,
}) {
  const [searchBtnDisabled, setSearchBtnDisable] = React.useState(true);

  useEffect(() => {
    if (searchValue) {
      setSearchBtnDisable(false);
    } else {
      setSearchBtnDisable(true);
    }
  }, [searchValue]);

  const handleColourTypeChange = (event) => {
    setcolourType(event.target.value);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Select
        sx={{ m: 4, minWidth: 170 }}
        labelId="action-label"
        id="action-select"
        label="Action"
        size="small"
        variant="standard"
        value={colourType}
        onChange={handleColourTypeChange}
      >
        <MenuItem value={1}>Primary Colour</MenuItem>
        <MenuItem value={2}>{language === 1 ? 'Swatch Colour' : 'Translated Colour'}</MenuItem>
      </Select>
      <TextField
        sx={{ m: 3, minWidth: 100 }}
        label="Search"
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        variant="outlined"
        size="small"
        className="mr-6 w-[200px]"
      />
      <Button
        variant="contained"
        disabled={searchBtnDisabled}
        onClick={() => onSearch(true)}
      >
        Search
      </Button>
      <Button variant="outlined" onClick={onReset} size="small">
        Reset
      </Button>
    </div>
  );
}

export default SearchColour;
