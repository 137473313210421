import patchRequest from '../../../patchRequest';

/**
 * Updates the brand group brands.
 *
 * @param {number} siteId - 1
*  @param {number[]} productIds
 * @returns {Promise} { Success: true }, status: 200 }
 */
export default async function patchMediaCheck(
  siteId,
  productIds,
) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/refresh-missing-images';

  const body = {
    siteId,
    productIds,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Refresh Images')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
