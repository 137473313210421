import getRequest from '../../../../../getRequest';

/**
 * Get a list of attributes.
 *
 * @param {number} siteId
 * @param {1 | 2 | 3} attributeOptions
 * @param {number} languageId
 * @returns Attributes
 */
export default function getSiteAttributes(siteId, attributeOptions, languageId) {
  const query = `?attributeOptions=${attributeOptions}&languageId=${languageId}`;
  const endpoint = `/oc-product-bulkapi/v1/sites/${siteId}/site-attributes${query}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Site Attributes')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
