import postRequest from '../../../../../../postRequest';

/**
 * Bulk upload a campaign to multiple products.
 *
 * @param {{
 *  referenceIds: string[],
 *  productTypeIds: number[],
 *  siteId: number
 * }}
 * @param {string[]} referenceIds Product Ids
 * @param {number[]} productTypeIds Attribute Id
 * @param {number} siteId Site Id
*/
export default function postProductTypesProductId({
  referenceIds,
  productTypeIds,
  siteId,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/product-types/product-id';

  const body = {
    referenceIds,
    productTypeIds,
    siteId,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Product Types', 'Started : Product Types : Prod ID (Update)')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
