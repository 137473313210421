/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { CategoryContext } from './CategoryContext';
import Categories from './components/Categories';
import CategoryLogs from './components/CategoryLogs';
import DisabledCategories from './components/DisabledCategories';
import ErpCategoryMapping from './components/ErpCategoryMapping';

const useStyles = makeStyles(() => ({
  tabRoot: {
    color: '#ffffffa0 !important',
    margin: '0 .5rem !important',
  },
  selected: {
    color: 'white !important',
  },
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function Category() {
  const styles = useStyles();
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [reFetchLogs, setReFetchLogs] = React.useState(false);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabs = ['Categories', 'Disabled', 'ERP Category Mapping'];

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CategoryContext.Provider value={{
      reFetchLogs,
      setReFetchLogs,
    }}
    >
      <Container className="flex flex-row items-start w-full mt-6 max-w-none">
        <Grid item xs={8} className="w-full">
          <Box className="w-full bg-gray-50">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                bgcolor: theme.palette.secondary[500],
              }}
            >
              <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="secondary"
                centered
              >
                {tabs.map((tabLabel) => (
                  <Tab
                    label={tabLabel}
                    key={tabLabel}
                    classes={{
                      root: styles.tabRoot,
                      selected: styles.selected,
                    }}
                    className="w-[24%]"
                  />
                ))}
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0} className="text-left">
              <Categories />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <DisabledCategories />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <ErpCategoryMapping />
            </TabPanel>
          </Box>
        </Grid>
        <Box className="ml-4">
          <CategoryLogs />
        </Box>
      </Container>
    </CategoryContext.Provider>
  );
}

export default Category;
