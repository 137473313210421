import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import getVariants from '../../../../../../api/oc_product/product/variants/gets/getVariants';
import { ProductContext } from '../../ProductContext';
import SkuVariantsTable from './SkuVariantsTable';

function Variants({ productIdentifiers, productStatus }) {
  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language?.language?.LanguageId);

  const [variantsData, setVariantsData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { variantReRender } = useContext(ProductContext);

  React.useEffect(() => {
    setLoading(true);
    getVariants(id, siteId, languageId)
      .then((res) => setVariantsData(res?.data.items))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [id, variantReRender]);

  return (
    <div>
      <SkuVariantsTable
        data={variantsData}
        loading={loading}
        productIdentifiers={productIdentifiers}
        productStatus={productStatus}
      />
    </div>
  );
}

export default Variants;
