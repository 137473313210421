/* eslint-disable no-prototype-builtins */
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';

import getBrands from '../api/oc_product/brands/gets/getBrands';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    fontSize: '12px',
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].Name}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const useStyles = makeStyles({
  paper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
  },
});

// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  width: '300px !important',
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function VirtualizeBrandAutocomplete({
  label,
  size,
  multiple,
  value,
  limitTags,
  onChange,
  className,
  helperText,
  id,
}) {
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const brandIdsFromQuery = searchParams.get('brands');

  const [brands, setBrands] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getBrands()
      .then((res) => {
        if (res) {
          setBrands(res.data);
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (brandIdsFromQuery) {
      const selected = brandIdsFromQuery.split(',').map((brandId) => brands.find((obj) => obj.BrandId === Number(brandId)));
      if (!selected.includes(undefined)) {
        setSelectedValue(selected);
        onChange({}, selected);
      }
    } else {
      setSelectedValue(null);
    }
  }, [brands, brandIdsFromQuery]);

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Autocomplete
      id={id}
      loading={loading}
      loadingText="Loading..."
      size={size}
      multiple={multiple}
      value={selectedValue || []}
      limitTags={limitTags}
      onChange={(e, v) => {
        setSelectedValue(v);
        onChange(e, v);
      }}
      className={className}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={brands}
      getOptionLabel={(option) => option.Name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
        />
      )}
      renderOption={(props, option, state) => [props, option, state.index]}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params}
      classes={{ paper: classes.paper }}
    />
  );
}
