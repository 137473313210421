import Stack from '@mui/material/Stack';
import React from 'react';

import BrandGroupCard from './BrandGroupCard';
// import BrandGroupCardTwo from './BrandGroupCardTwo';

function BrandGroupingBody({ brandGroups }) {
  return (
    <div className="mt-10">
      {brandGroups?.length > 0 ? (
        <Stack direction="row" spacing={6} useFlexGap flexWrap="wrap" className="mb-12">
          {
            brandGroups?.map((group) => (
              <BrandGroupCard
                group={group}
              />
            ))
          }
        </Stack>
      ) : (
        <div>No results found</div>
      )}
    </div>
  );
}

export default BrandGroupingBody;
