import InfoIcon from '@mui/icons-material/Info';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import restrictKeys from '../../../../utils/restrictKeys';

const useStyles = makeStyles({
  paper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
  },
});

function ERPCategoryIDFilter({ sources }) {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const erpSource = searchParams.get('erpSource');
  const [erpSourceObj, setErpSourceObj] = React.useState(null);
  const erpCategoryIds = searchParams.get('erpCategoryIds');
  const [enteredIds, setEnteredIds] = React.useState('');

  const handleClearingData = () => {
    setSearchParams((params) => {
      params.delete('erpSource');
      params.delete('erpCategoryIds');
      setErpSourceObj(null);
      setEnteredIds('');
      return params;
    });
  };

  const handleErpSourceChange = (value) => {
    setErpSourceObj(value);
    if (value === null) {
      handleClearingData();
    }
    if ((erpCategoryIds || enteredIds) && value !== null) {
      setSearchParams((params) => {
        params.set('erpSource', value?.SourceId);
        return params;
      });
    }
  };

  const handleCategoryIdsChange = (event) => {
    if (event.target.value !== '') {
      if (erpSourceObj !== '') {
        setSearchParams((params) => {
          params.set('erpSource', erpSourceObj?.SourceId);
          return params;
        });
      }
      setSearchParams((params) => {
        params.set('erpCategoryIds', event.target.value);
        if (event.target.value.length === 0) params.delete('erpCategoryIds');
        return params;
      });
    } else {
      handleClearingData();
    }
  };

  React.useEffect(() => {
    if (erpSourceObj && enteredIds) {
      setSearchParams((params) => {
        params.set('erpSource', erpSourceObj?.SourceId);
        params.set('erpCategoryIds', enteredIds);
        return params;
      });
    }
  }, []);

  React.useEffect(() => {
    setEnteredIds(erpCategoryIds || '');
  }, [erpCategoryIds]);

  React.useEffect(() => {
    if (!erpSource) {
      setErpSourceObj(null);
    }
  }, [erpSource]);

  React.useEffect(() => {
    if (erpSource) {
      const selected = sources.find((obj) => obj?.SourceId === Number(erpSource));
      if (selected !== undefined) {
        setErpSourceObj(selected);
      }
    } else {
      setErpSourceObj(null);
    }
  }, [erpSource, sources]);

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <div className="flex flex-row items-center">
          <Typography variant="body2" className="mr-2">ERP Category ID</Typography>
          <Tooltip
            title={(
              <p className="text-sm">ERP Category ID is replacing Remote Category ID</p>
            )}
          >
            <InfoIcon />
          </Tooltip>
        </div>
        <div className="flex flex-col w-[215px]">
          <Stack spacing={2}>
            <Autocomplete
              id="erp-category-id-autocomplete"
              size="small"
              options={sources}
              getOptionLabel={(option) => option.Name}
              value={erpSourceObj}
              filterSelectedOptions
              limitTags={2}
              onChange={(_, value) => handleErpSourceChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source"
                  placeholder="Source"
                />
              )}
              classes={{ paper: classes.paper }}
            />
            <TextField
              id="erp-category-id-textfield"
              value={erpCategoryIds || ''}
              disabled={erpSourceObj == null}
              placeholder={erpSourceObj == null ? 'Please Select A Source' : 'Enter ERP Category Id\'s'}
              multiline
              onKeyPress={restrictKeys}
              rows={5}
              onChange={(event) => handleCategoryIdsChange(event)}
            />
          </Stack>
        </div>
      </Stack>
    </div>
  );
}

export default ERPCategoryIDFilter;
