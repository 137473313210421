import getRequest from '../../../../../getRequest';

/**
 * Get all category data for the category tree.
 *
 * @param {number} siteId Site Id of the product
 * @returns {AxiosResponse}
 * @example
 * {
 *    data: [
        {
          "Id": 125019,
          "Category": "Kids/2_uat_cat/"
        }
 *    ]
 * }
 */
export default function getAllCategories(siteId) {
  const endpoint = `/oc-product/v1/sites/${siteId}/categoriesHierarchy`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'All Categories')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
