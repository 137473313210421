import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import VirtualizeBrandSelect from '../../../../components/VirtualizedBrandsAutocomplete';
import splitString from '../../../../utils/splitIdString';
import CustomIdsFilter from '../../../product_info/media_check/components/filters/CustomIdsFilter';

function Filters({ onSearch, onClickClearTable }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const [idsError, setIdsError] = React.useState(false);
  const [filterOpen, setFilterOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchBtnDisabled, setSearchBtnDisabled] = React.useState(true);

  const name = searchParams.get('name');
  const styleIds = splitString(searchParams.get('styleId'), 'number');
  const hasStyle = searchParams.get('hasStyle');

  const resetAllFilters = () => {
    onClickClearTable();
    searchParams.delete('page');
    searchParams.delete('pageSize');
    setSearchParams((params) => {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of params.keys()) {
        arr.push(key);
      }
      /**
       * For some reason we have to do this...
       * If you do the, if not then delete, statement below within the for above
       * or do params.forEach
       * The loop with miss every even indexed key...
       */
      arr.forEach((key) => {
        if (
          key !== 'siteCode'
          && key !== 'page'
          && key !== 'omsId'
          && key !== 'pageSize'
        ) {
          params.delete(key);
        }
      });
      return params;
    });
  };

  const handleNoText = (event) => {
    event.stopPropagation();
    const filteredInput = event.target.value.replace(/[^0-9]/g, '');

    setSearchParams((params) => {
      params.set('styleId', filteredInput);
      if (event.target.value === '') params.delete('styleId');
      return params;
    });
  };

  React.useEffect(() => {
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
      // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter(
      (str) => str !== 'page'
        && str !== 'siteCode'
        && str !== 'omsId'
        && str !== 'pageSize'
        && str !== 'idType',
    );
    const count = filtered.length;
    const hasParams = count > 0;
    setSearchBtnDisabled(!hasParams);
  }, [searchParams]);

  React.useEffect(() => {
    resetAllFilters();
  }, [siteId]);

  return (
    <div className="w-full">
      <Collapse
        in={filterOpen}
        timeout="auto"
        sx={{
          '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            justifyContent: 'space-between',
          },
        }}
      >
        <Grid
          container
          spacing={2}
          className="rounded-lg border bg-white transition-all ml-[0px] mr-[0px]"
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="h6" className="pl-5 font-gothamlight">
              Filters
            </Typography>

            <div className="flex flex-wrap w-full h-full gap-4 p-5 mt-5">
              <div className="flex flex-wrap w-full h-full gap-4">
                <Box className="flex gap-6">
                  <div className="mr-6">
                    <CustomIdsFilter onError={setIdsError} />
                  </div>
                  <div className="mr-6 w-[220px]">
                    <Typography variant="body2" className="mb-4">Brands</Typography>
                    <VirtualizeBrandSelect
                      id="brand-virtualized-autocomplete"
                      multiple
                      limitTags={2}
                      className="max-w-300"
                      label="Brands"
                      helperText="Select one or multiple"
                      size="small"
                      onChange={(e, v) => {
                        setSearchParams((params) => {
                          params.set('brands', v.map((obj) => obj.BrandId));
                          if (v.length === 0) params.delete('brands');
                          return params;
                        });
                      }}
                    />
                  </div>
                  <div className="mr-6">
                    <Typography variant="body2" className="mb-4">Product Name</Typography>
                    <TextField
                      label="Enter Product Name"
                      variant="outlined"
                      size="small"
                      className="w-full"
                      value={name || ''}
                      onChange={(event) => {
                        setSearchParams((params) => {
                          params.set('name', event.target.value);
                          if (event.target.value.length === 0) params.delete('name');
                          return params;
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Typography variant="body2" className="mb-4">Style ID</Typography>
                    <TextField
                      label="Enter Style ID"
                      variant="outlined"
                      size="small"
                      className="w-full"
                      value={styleIds || ''}
                      onChange={handleNoText}
                    />
                  </div>
                  <div>
                    <Typography variant="body2">Has Style</Typography>
                    <div className="flex flex-col w-max">
                      <RadioGroup
                        defaultValue="all"
                        value={hasStyle || 'all'}
                        name="hasStyle-radio-buttons-group"
                        onChange={(event) => {
                          setSearchParams((params) => {
                            params.set('hasStyle', event.target.value);
                            if (event.target.value === 'all') params.delete('hasStyle');
                            return params;
                          });
                        }}
                      >
                        <FormControlLabel value="all" control={<Radio />} label="All" />
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                </Box>
                <div className="flex items-center justify-end w-full gap-10 pb-5 mb-5">
                  <Button
                    variant="text"
                    className="text-primary-600"
                    onClick={() => resetAllFilters()}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    disabled={idsError || searchBtnDisabled}
                    onClick={() => {
                      onSearch();
                      setFilterOpen(false);
                    }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Collapse>
      <Stack
        className="flex justify-between w-full mt-10 mb-5"
        direction="row"
        alignItems="center"
      >
        <Typography variant="h5" className="font-gothamlight">
          Product
        </Typography>
        <Stack direction="row" gap={4}>
          <Button
            variant="contained"
            onClick={() => setFilterOpen(!filterOpen)}
          >
            {filterOpen ? 'Close' : 'Filter'}
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

export default Filters;
