import Typography from '@mui/material/Typography';
import React from 'react';

import getMarginThresholdLogs from '../../api/oc_product/bulk/bulk_process/sites/reports/gets/getMarginThresholdLogs';
import ProgressSideBarMarginThreshold from '../../components/progress_sidebar/ProgressSideBarMarginThreshold';
import DropdownsContainer from './components/DropdownsContainer';

function MarginThreshold() {
  const [inProgressData, setInProgressData] = React.useState([]);
  const handleRefreshReports = () => {
    getMarginThresholdLogs(34, [2, 3]) // "Active": 2, "Processing": 3,
      .then((res) => setInProgressData(res.data.reverse()))
      .catch((err) => console.error(err));
  };
  return (
    <div>
      <Typography variant="h5" className="my-6 text-secondary-600 ml-8">Margin Threshold</Typography>
      <div className="flex gap-8 m-8">
        <DropdownsContainer refreshReports={() => handleRefreshReports()} inProgressData={inProgressData} />
        <ProgressSideBarMarginThreshold inProgressData={inProgressData} />
      </div>
    </div>
  );
}

export default MarginThreshold;
