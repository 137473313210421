import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

function WarningButton({
  label = 'Button',
  text = 'Are you sure?',
  color,
  variant,
  disabled,
  onSubmit,
  className,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePopoverToggle = (event) => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handlePopoverToggle}
        variant={variant}
        className={className}
        color={color}
        size="small"
        disabled={disabled}
      >
        {label}
      </Button>
      {/* Confirmation popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ maxWidth: 300 }}
      >
        <div className="w-full p-4 text-center">
          <WarningAmberIcon className="text-error-600" sx={{ fontSize: '2.5rem' }} />
          <Typography pt={1} variant="body1" flexWrap="wrap">
            {text}
          </Typography>
        </div>
        <div className="px-4 py-3 text-center bg-neutral-200">
          <Stack direction="row" spacing={2}>
            <Button
              color="primary"
              size="small"
              onClick={handlePopoverClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              disableElevation
              onClick={() => {
                onSubmit();
                setAnchorEl(null);
              }}
            >
              Confirm
            </Button>
          </Stack>
        </div>
      </Popover>
    </>
  );
}

export default WarningButton;
