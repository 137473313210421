import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';

import { setLocalStorage } from '../../../helper/LocalStorage';

function ReleaseNotes({ onHide, hideKey }) {
  return (
    <div
      className="absolute w-[320px] max-h-[100px] top-[80px] rounded-lg border-primary-300 shadow-lg right-8 border bg-white"
    >
      <IconButton
        onClick={() => {
          setLocalStorage(hideKey, true);
          onHide();
        }}
        className="absolute z-50 top-2 right-2"
      >
        <CloseIcon />
      </IconButton>
      <a href="settings/release-notes" className="flex flex-col w-full h-full p-4">
        <span className="text-xl font-gothammedium">New Release!</span>
        <span>Click to view the release notes.</span>
      </a>
    </div>
  );
}

export default ReleaseNotes;
