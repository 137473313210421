import postRequest from '../../../postRequest';

/**
 * Index products.
 *
 * @param {string []} siteCodes
 * @param {number[]} productIds
 */
function postIndex(siteCodes, productIds) {
  const endpoint = '/oc-product/v1/index';

  const body = {
    siteCodes,
    productIds,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'ProductIndex Index')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postIndex;
