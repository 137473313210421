import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getSites from '../../api/intergration/pim/sites/gets/getSites';

// First, create the thunk
export const fetchPIMSites = createAsyncThunk(
  'pim/sites',
  async () => {
    try {
      const { data } = await getSites(1, 100);
      return data;
    } catch (error) {
      return false;
    }
  },
);

const initialState = {
  sites: {
    SiteId: 0,
    SiteCode: '',
    Fascia: '',
    CountryCode: '',
    IsLead: true,
  },
};

const pimSitesSlice = createSlice({
  initialState,
  name: 'pimSites',
  reducers: {
    setPimSites: (state, action) => {
      state.sites = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPIMSites.fulfilled, (state, action) => action.payload.Data);
  },
});

export const { setPimSites } = pimSitesSlice.actions;

export default pimSitesSlice.reducer;
