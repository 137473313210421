/**
 * Add query to URL without refreshing page.
 *
 * @param {string} key Name of the Query Param
 * @param {string} value Value of the Query Param
 */
export function addQueryParam(key, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url.toString());
}

/**
 * Remove a query from the URL.
 * Or if you pass an Array of Strings you can remove multiple queries at once.
 *
 * @param {string | string[]} key Name of Query Param
 */
export function removeQueryParam(key) {
  const url = new URL(window.location.href);
  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      const k = key[i];
      url.searchParams.delete(k);
    }
  } else {
    url.searchParams.delete(key);
  }
  window.history.pushState({}, '', url.toString());
}

/**
 * Get the value of the query specified from the URL.
 *
 * @param {string} key Name of Query Param.
 * @returns {string}
 */
export function getQueryParam(key) {
  const url = new URL(window.location.href);
  return url.searchParams.get(key) || '';
}

export function getQueryParamSize() {
  const url = new URL(window.location.href);
  return url.searchParams.size;
}

/**
 * Remove all query params from the URL.
 * Or pass in an Array of query keys you don't want to be removed.
 *
 * @param {string[]} paramsToNotRemove Params you don't want to clear from the URL.
 */
export function clearQueryParams(paramsToNotRemove = []) {
  const url = new URL(window.location.href);
  let paramKeys = Array.from(url.searchParams.keys());

  if (paramsToNotRemove.length > 0) {
    for (let i = 0; i < paramsToNotRemove.length; i++) {
      const paramName = paramsToNotRemove[i];
      /**
        Remove the keys from the paramKeys array so they aren't
        deleted from the URL in the next for loop.
      */
      paramKeys = paramKeys.filter((key) => key !== paramName);
    }
  }

  // Removes all params in the paramsKeys array from the URL.
  for (let index = 0; index < paramKeys.length; index++) {
    const param = paramKeys[index];
    url.searchParams.delete(param);
  }

  window.history.pushState({}, '', url.toString());
}
