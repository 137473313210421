import postRequest from '../../../../../postRequest';

/**
 * Change if Stock is Enabled or Disabled for multiple products.
 *
 * @param {"enable" | "disable"} action
 * @param {{ productId: number, isEnabled: boolean }[]} productEnableOrDisableStocksModelList
 * @param {number} siteId
 * @param {number} sourceLocationId
 */
export default function postStocks(action, productEnableOrDisableStocksModelList, siteId, sourceLocationId) {
  const endpoint = `oc-product-bulkapi/v1/bulk-process/stocks?action=${action}`;

  const body = {
    productEnableOrDisableStocksModelList,
    siteId,
    sourceLocationId,
  };
  const status = productEnableOrDisableStocksModelList[0].isEnabled ? 'Enabled' : 'Disabled';
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Stocks', `Started : Bulk Operation : Stock ${status}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
