/* eslint-disable react/require-default-props */
/* eslint-disable no-prototype-builtins */
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';

import getCategories from '../api/oc_product/sites/categories/gets/getCategories';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    fontSize: '14px',
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].Hierarchy}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const useStyles = makeStyles({
  paper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
  },
});

// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  width: '600px !important',
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function VirtualizedCategoryAutocomplete({
  label,
  value,
  onChange,
  multiple,
  className,
  hideAllOption,
  size,
  helperText,
  limitTags = 1000,
}) {
  const classes = useStyles();

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const [searchParams] = useSearchParams();
  const categoryIdsFromQuery = searchParams.get('categories');

  const [categories, setCategories] = React.useState([
    {
      CategoryId: '',
      Name: 'All',
      Hierarchy: 'All',
    },
  ]);
  const [selectedValue, setSelectedValue] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getCategories(siteId)
      .then((res) => {
        if (res) {
          setCategories([
            {
              CategoryId: '',
              Name: 'All',
              Hierarchy: 'All',
            },
            ...res.data,
          ]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setLoading(false);
      });
  }, [siteId]);

  React.useEffect(() => {
    if (categoryIdsFromQuery) {
      const selected = categoryIdsFromQuery.split(',').map((id) => categories.find((obj) => obj.CategoryId === Number(id)));
      if (!selected.includes(undefined)) {
        setSelectedValue(selected);
        onChange(null, selected);
      }
    } else {
      setSelectedValue(null);
    }
  }, [categories, categoryIdsFromQuery]);

  React.useEffect(() => {
    if (Array.isArray(value)) {
      if (value.some((v) => typeof v === 'string')) {
        const selected = value.map((id) => categories.find((obj) => obj.CategoryId === Number(id)));
        if (!selected.includes(undefined)) {
          setSelectedValue(selected);
          onChange(null, selected);
        }
      }
    }
    setSelectedValue(value);
    // onChange(value);
  }, [value]);

  const getOptionLabel = (option) => {
    if (option.Hierarchy == null) {
      const foundCategory = categories
        .find((category) => category.CategoryId === option.CategoryId);
      return foundCategory?.Hierarchy;
    }
    return option.Hierarchy;
  };

  return (
    <Autocomplete
      id="virtualize-category-autocomplete"
      loading={loading}
      loadingText="Loading..."
      size={size}
      multiple={multiple}
      value={selectedValue || []}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      className={className}
      getOptionLabel={getOptionLabel}
      options={
        (categories.sort((a, b) => -b.Hierarchy.localeCompare(a.Hierarchy))).filter(
          (x) => {
            if (hideAllOption) {
              return x.Name !== 'All';
            }
            return x;
          },
        )
      }
      groupBy={(option) => option.Hierarchy.split('/')[0]}
      onChange={(e, v) => {
        setSelectedValue(v);
        onChange(e, v);
      }}
      limitTags={limitTags}
      renderInput={(params) => <TextField {...params} label={label} helperText={helperText} />}
      renderOption={(props, option) => [props, option]}
      renderGroup={(params) => params}
      classes={{ paper: classes.paper }}
    />
  );
}
