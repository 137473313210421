import postRequest from '../../../postRequest';

/**
 * Search for a product.
 *
 * @param {string} siteCode
 */
function postSearch(
  page,
  size,
  {
    siteId,
    siteCode,
    name,
    productIds,
    plus,
    eans,
    skus,
    hasSelectedSourcesOnly,
    hasSelectedProductTypesOnly,
    sourceIds,
    productTypeIds,
    categoryIds,
    brandIds,
    erps,
    omsId,
    hasSelectedLocationsOnly,
    hasStockInSourceLocationIds,
    stockSourceLocationId,
    erpCategorySourceId,
    isApproved,
    isOnSite,
    isActive,
    isComplete,
    isDropshippable,
    hasImage,
    hasProductType,
    hasCategories,
    createdDateFrom,
    createDateTo,
    releaseDateFrom,
    releaseDateTo,
    updateDateFrom,
    updatedDateTo,
    searchByAttributeRequest,
  },
) {
  let query = '?';
  if (page > 0) {
    query += `Page=${page}`;
  }
  if (page > 0 && size > 0) {
    query += '&';
  }
  if (size > 0) {
    query += `Size=${size}`;
  }
  const endpoint = `/oc-product/v1/search${query.length > 1 ? query : ''}`;

  const body = {
    siteId,
    siteCode,
    name,
    productIds,
    plus,
    eans,
    skus,
    hasSelectedSourcesOnly,
    hasSelectedProductTypesOnly,
    sourceIds,
    productTypeIds,
    categoryIds,
    brandIds,
    erps,
    omsId,
    hasSelectedLocationsOnly,
    hasStockInSourceLocationIds,
    stockSourceLocationId,
    erpCategorySourceId,
    isApproved,
    isOnSite,
    isActive,
    isComplete,
    isDropshippable,
    hasImage,
    hasProductType,
    hasCategories,
    createdDateFrom,
    createDateTo,
    releaseDateFrom,
    releaseDateTo,
    updateDateFrom,
    updatedDateTo,
    searchByAttributeRequest,
    pageNumber: page,
    pageSize: size,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Product Search')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postSearch;
