import React from 'react';

function Release20240118() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">NEW FEATURE</h2>
      <p className="-mt-4">18th January 2024</p>

      <h3 className="font-gothammedium text-neutral-700">PLP</h3>
      <h4>
        Advanced Search By Attributes
      </h4>
      <ul className="-mt-4">
        <li>New filter added to the PLP</li>
        <li>Search for all products that contain an Attribute Definition</li>
        <li>Search Products that contain a specific Attribute Definition Value</li>
      </ul>
      <h4>
        Pre-Release Dates
      </h4>
      <ul className="-mt-4">
        <li>
          Search for Hidden, Pre-Release and Restricted Products in the PLP
          <ul>
            <li>Hidden / Pre-Release  : Grey Outline</li>
            <li>Restricted : Red Outline</li>
          </ul>
        </li>
        <li>These are none clickable (Read Only on the PLP)</li>
      </ul>
      <h4>
        Selected Products only Button to Products Types filter
      </h4>
      <ul className="-mt-4">
        <li>
          Product Types Filter now works with the Had Product Type Filter
          <ul>
            <li>Returns products with no product type + Core JDUK.</li>
          </ul>
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bulk Uploads</h3>
      <ul className="-mt-4">
        <li>NEW: Pre-Release Dates (File Upload)</li>
        <li>The amount of flexibility added to the file upload sheet is unbelievable</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Improvements</h3>
      <ul className="-mt-4">
        <li>Display Money Attribute as Blank in the PDP instead of zero when there is no value</li>
        <li>Enable Stock Display in Bulk</li>
        <li>
          Expand and Retract the Logs Section
          <ul>
            <li>This is a minor thing, but we got super excited by this</li>
          </ul>
        </li>
        <li>Hide read Only Attributes that do not have values on the PDP</li>
        <li>Enable Dropship Management Pages on PIM for GO</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bug Fixes</h3>
      <ul className="-mt-4">
        <li>Fixed incorrect Passed / Failed count on Bulk Uploads Logs for Approve & New In Date</li>
        <li>Fixed Enable Stock : Conflicting instance with the same key value for ProductSiteId</li>
        <li>Fixed Categories are getting Added and then getting removed immediately from the list of assigned categories</li>
        <li>Updated ‘Site Level’ instead of ‘Group Level’ for Group sites on enable stock bulk operation</li>
      </ul>
    </section>
  );
}

export default Release20240118;
