import React from 'react';

function Release20240314() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S3/E11 - NEW PAGES</h2>
      <p className="-mt-4">14th March 2024</p>

      <h4>
        Dropship Management - Pricing & Vat
      </h4>
      <ul className="-mt-4">
        <li>Moved to the PLP</li>
        <ul>
          <li>Users can now change the dropship price and vat for products within the PDP</li>
          <li>Located in the variants tab</li>
        </ul>
      </ul>
      <h4>
        Data Management
      </h4>
      <ul className="-mt-4">
        <li>(Fixed) - Attributes Def - Old logs not displaying in the correct json format</li>
        <li>(Fixed) - Remove All instances of Content Language from Product Types</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PLP</h3>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Search By Attributes : Not returning any results when we search for Attributes (Date) without selecting a value.</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PDP</h3>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - (Attributes and Categories) - Users are unable to save Thai / special characters when using (Translated Product Description)</li>
        <li>(Fixed) - (Attributes and Categories) : Unable to use space when typing in multi-select dropdowns</li>
        <li>(Fixed) - Is PFS Enabled attributes is updating on DB and PIM UI but not in PRISM UI through the Import</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bulk Uploads</h3>
      <h4>Improvements</h4>
      <ul className="-mt-4">
        <li>Category Delete for outdoor facias now works on a hierarchy level</li>
        <ul>
          <li>If the Bottom  category is removed then we also remove the parents, as long as no other child category exist</li>
        </ul>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Prism General Improvements</h3>
      <ul className="-mt-4">
        <li>Testing for new Microservice</li>
        <li className="font-black">Data Management - Disable Tab is CPIM and redirect to Prism</li>
      </ul>
    </section>
  );
}

export default Release20240314;
