import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMissingVatTableValues } from '../../../../redux/wizard/nike/nike_wizard';
import Table from '../../../Table';
import generateVatTable from './generateVatTable';

function VatTable() {
  const nike_wizard = useSelector((state) => state.nike_wizard);
  const dispatchToRedux = useDispatch();

  const sizeArray = nike_wizard.productData.vat?.map((obj) => obj.sizes
    .map(({ country_code }) => country_code));
  const uniqueSizeArray = [...new Set(sizeArray.flat().filter((country_code) => country_code !== 'US'))];
  const vatCodeArray = nike_wizard?.productData.vat?.map((obj) => obj.vat_code
    .map(({ country_code }) => country_code));
  const uniqueVatCodeArray = [...new Set(vatCodeArray.flat())];
  const [rrpCountries, setRrpCountries] = React.useState([]);

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    generateVatTable(nike_wizard, uniqueSizeArray)
      .then((tableData) => {
        setRrpCountries(tableData.rrp_countries);
        setRows(tableData.rows);
        if (tableData.missing) {
          dispatchToRedux(setMissingVatTableValues(true));
        }
      });
  }, []);

  return (
    <Table
      fixed={false}
      align="center"
      columnGrouping={[
        {
          span: 1,
          label: '',
        },
        {
          span: uniqueSizeArray.length,
          label: 'Size',
        },
        {
          span: rrpCountries.length,
          label: 'RRP',
        },
        {
          span: uniqueVatCodeArray.length,
          label: 'VAT Code',
        },
        {
          span: 1,
          label: '',
        },
        {
          span: 1,
          label: '',
        },
      ]}
      columns={[
        { id: 'sku', label: 'SKU', styles: { position: 'sticky !important' } },
        ...uniqueSizeArray.sort().map((country) => ({
          id: 'sizes',
          label: country === 'GB' ? 'UK' : country,
          disableBold: true,
          styles: { position: 'sticky !important' },
        })),
        ...rrpCountries.sort().map((currency) => ({
          id: 'currency',
          label: currency === 'GB' ? 'UK' : currency,
          disableBold: true,
          styles: { position: 'sticky !important' },
        })),
        ...uniqueVatCodeArray.sort().map((country) => ({
          id: 'vat_code',
          label: country === 'GB' ? 'UK' : country,
          disableBold: true,
          styles: { position: 'sticky !important' },
        })),
        { id: 'upc', label: 'Barcode', styles: { position: 'sticky !important' } },
        { id: 'commodity_code', label: 'Commodity Code', styles: { position: 'sticky !important' } },
      ]}
      rows={rows}
      className="border rounded"
    />
  );
}

export default VatTable;
