/* eslint-disable no-nested-ternary */
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

const FailedTabRow = memo(({
  data,
  index,
  style,
  setSelected,
  setOpenModal,
}) => {
  const obj = data[index];

  const operationTypes = useSelector((state) => state.bulk_operations.operation_types);

  const handleOperationName = (bulkOperationRequestType) => {
    const foundMatchingOperation = Object.keys(operationTypes ?? {}).find((key) => operationTypes[key] === bulkOperationRequestType);
    if (foundMatchingOperation) {
      return foundMatchingOperation;
    }
    return 'Operation Type Not Found';
  };

  const handleCrudType = (crudType) => {
    switch (crudType) {
      case 1:
        return 'Insert';
      case 2:
        return 'Read';
      case 3:
        return 'Update';
      case 4:
        return 'Delete';
      case 8:
        return 'Update';
      default:
        return 'CRUD Type Not Found';
    }
  };

  return (
    <section key={obj.Id} style={style}>
      <div className="relative flex items-center w-full pb-4 border-b border-neutral-400">
        <div className="flex flex-col w-3/4 gap-1">
          <p className="text-sm">
            <span className="font-gothammedium">ID:</span>
            {' '}
            {obj.Id}
          </p>
          <p className="text-sm">
            Author:
            {' '}
            {obj.UserName}
          </p>
          <p className="text-sm whitespace-nowrap">
            Operation:
            {' '}
            {handleOperationName(obj.BulkOperationRequestType)}
          </p>

          <p className="text-sm">
            Type:
            {' '}
            {handleCrudType(obj.CrudType)}
          </p>

          <p className="text-sm">
            Started:
            {' '}
            {new Date(obj.ProcessStartDate).toLocaleString('en-GB')}
          </p>

          <div>
            <p className="text-sm font-gothammedium">Failure Reason:</p>
            {obj.ExceptionReason.length > 50 ? (
              <Tooltip
                title={<p className="text-sm">{obj.ExceptionReason}</p>}
              >
                <p className="text-sm">
                  {obj.ExceptionReason.substring(0, 50)}
                  ...
                </p>
              </Tooltip>
            ) : (
              <p className="text-sm">{obj.ExceptionReason}</p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center w-1/4">
          {obj.Index > 0 && (
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setSelected(obj);
                setOpenModal(true);
              }}
            >
              More Info
            </Button>
          )}
        </div>
      </div>
    </section>
  );
}, areEqual);

export default FailedTabRow;
