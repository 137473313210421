import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import postDropshipment from '../../../api/intergration/pim/products/dropshipment/posts/postDropshipment';

// First, create the thunk
export const fetchProductData = createAsyncThunk(
  'wizard/productData',
  async ({ productId, statusCategory }) => {
    try {
      const { data } = await postDropshipment({ productId, siteCode: 'JD', statusCategory }, 1, 20);
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
);

const pimSitesSlice = createSlice({
  initialState: {},
  name: 'selectedProductData',
  reducers: {
    setProductData: (state, action) => {
      state.sites = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductData.fulfilled, (state, action) => {
      state.product_data = action.payload;
    });
  },
});

export const { setProductData } = pimSitesSlice.actions;

export default pimSitesSlice.reducer;
