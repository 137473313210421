import React from 'react';

function Release20240229() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S2/E10 - NEW PAGES</h2>
      <p className="-mt-4">29th Feb 2024</p>

      <h4>
        Data Management - Attributes
      </h4>
      <ul className="-mt-4">
        <li>VIEW, EDIT, CREATE Attributes</li>
        <li>Added Product Count</li>
        <li>New UI</li>
        <li>Enhanced Search and Search Results</li>
        <li>Enhanced Logs</li>
        <li>Speeed!!</li>
      </ul>
      <h4>
        Data Management - Size Mapping
      </h4>
      <ul className="-mt-4">
        <li>VIEW, EDIT & CREATE  Size Maps</li>
        <li>Minor fixes and Improvements</li>
      </ul>
      <h4>
        Product Info- Media Check / Logs
      </h4>
      <ul className="-mt-4">
        <li>Media Check Logs added</li>
        <li>Minor fixes and Improvements</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PLP</h3>
      <h4>Improvements</h4>
      <ul className="-mt-4">
        <li>Search by Attributes - Attributes now used the Def Name</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) -HTML & All input boxes related attributes are not accepting space.</li>
        <li>(Fixed) - Export is ignoring the user inputted file name</li>
        <li>(Fixed) -  Search By Attributes not returning any products when no values are selected</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PDP</h3>
      <h4>Improvements</h4>
      <ul className="-mt-4">
        <li>Enable users to search for PLUs on the PDP</li>
        <li>Allow for more Attribute per page and set default to 50</li>
        <li>Allow HTML for Translated product description and product description</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Not able to update/edit the decimal attributes on the PDP Page</li>
        <li>(Fixed) - Attributes and Product Types - Returning NULL attributes for Thai Product types when it has attributes</li>
        <li>(Fixed) - Category id is displaying instead of category name when updated primary category</li>
        <li>
          (Fixed) - While removing categories from the product in PDP then showing an error ‘undefined: No category found’ Intermittently (Outdoor Facias)
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bulk Uploads</h3>
      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Enable Stock - Variants ids are overlapping in more info section.</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Prism General Improvements</h3>
      <ul className="-mt-4">
        <li>Logged in prism site and Changed system password but still able to access the prism site</li>
        <li>Run a periodic index on all facias</li>
        <li>Allow users without CPIM Access to access Prism</li>
        <li>Remove All instances of Content Language from Product Types</li>
      </ul>
    </section>
  );
}

export default Release20240229;
