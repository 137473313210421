/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20240425() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S4/E12 - S5/E13 - NEW PAGES</h2>
      <p className="-mt-4">25th April 2024</p>

      <h4>
        Outdoors group Page
      </h4>
      <ul className="-mt-4">
        <li>Outdoors Group is the group page for all outdoors facias. Group level bulk operations can be performed from this page</li>
      </ul>
      <h4>
        Styles
      </h4>
      <ul className="-mt-4">
        <li>Added Enhanced Styles Search</li>
        <li>Move and Split can now be done on the same page</li>
        <li>(Styles) : Move - Add a warn when the user tries to switch to a different brand</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Reporting</h3>

      <ul className="-mt-4">
        <li>Redirect the user to Power Bi for reporting.</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PLP</h3>

      <h4>New Functionality</h4>
      <ul className="-mt-4">
        <li>Search by Attributes Blank : User can now search for Attributes with the blank value</li>
        <li>Increase the rows per page on the PLP to 100</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Search By Attributes : Not returning any results when we search for Attributes (Date) without selecting a value</li>
        <ul>
          <li>If the Bottom  category is removed then we also remove the parents, as long as no other child category exist</li>
        </ul>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">PDP</h3>

      <h4>New Functionality</h4>
      <ul className="-mt-4">
        <li>Styles : User can now split or move a product directly from the PDP</li>
        <li>(PLP-Search Filters) : Has Product Type - Return Core JDUK when a user selects no in UK</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - (Attributes and Categories) - Users are unable to save Thai / special characters when using (Translated Product Description)</li>
        <li>(Fixed) - (Attributes and Categories) : Unable to use space when typing in multi-select dropdowns</li>
        <li>(Fixed) - Is PFS Enabled attributes is updating on DB and PIM UI but not in PRISM UI through the Import</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bulk Uploads</h3>

      <h4>New Functionality</h4>
      <ul className="-mt-4">
        <li>
          (Release Dates )
          {'->'}
          {' '}
          Combine Release Dates & Pre-Release Products Functionality : User can now add release dates to existing and pre-release products
        </li>
        <li>Single Line Attributes : Added Delete Option. Users can now select an option to bulk delete single line attributes</li>
      </ul>

      <h4>Improvements</h4>
      <ul className="-mt-4">
        <li>
          Category Delete for outdoor facias now works on a hierarchy level
          <ul>
            <li>
              If the Bottom category is removed then we also remove the parents, as long as no other child category exist
            </li>
          </ul>
        </li>
        <li>Enable Stock Speed Improvements</li>
        <li>Attributes Def (Allow users to delete values that products assigned to them)</li>
      </ul>

      <h4>Bugs</h4>
      <ul className="-mt-4">
        <li>(Fixed) - Bulk Uploads- Enable Stock- The products with Transaction log as 'Location not found' should have transaction status and Product transaction type as 'Failed'</li>
        <li>(Fixed) - BULK UPLOADS- APPROVE- The status of the products is not changing in the search results</li>
        <li>(Fixed) - (Bulk Uploads) JD FR, DE: Single Line Attributes- Attribute values are missing for the Attributes in PDP and failing the updates.</li>

      </ul>

      <h3 className="font-gothammedium text-neutral-700">Prism General Improvements</h3>
      <ul className="-mt-4">
        <li>Testing for new Microservice</li>
        <li className="font-black">Data Management - Disable Tab is CPIM and redirect to Prism</li>
        <li>(Indexing) : Allow users to index more than one facia at a time</li>
        <li>(Fixed) - JD UK - Nike Partner Programme | Onboarding Issues in Prism</li>
        <li>(Fixed) - (Dropship Management: Pricing): Dropship RRP is not updating to original Price after we cancel the action.</li>
        <li>Modify UAT DB Indexes/Constraints</li>
      </ul>
    </section>
  );
}

export default Release20240425;
