/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20240523() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">S8/E15 - NEW PAGES</h2>
      <p className="-mt-4">23rd May 2024</p>

      <h4>
        Notice
      </h4>

      <p><strong>Central PIM deprecation will be delayed, as we are experience some bulk upload issues and waiting for all outdoor reports to be moved to Power Bi</strong></p>

      <p><strong>New Date 07 / 06 / 2024</strong></p>

      <h3 className="font-gothammedium text-neutral-700">New Pages</h3>

      <h4>Margin Threshold V1</h4>

      <ul className="-mt-4">
        <li>
          Allow user to change or enable / disable the threshold of stock locations
          <ul>
            <li>JD Group Page Access Required</li>
          </ul>
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Improvements</h3>

      <h4>PDP</h4>

      <ul className="-mt-4">
        <li>Users can now expand the categories section</li>
        <li>Default number of logs increased to 15. Logs now go in increments of 15, 25, 50</li>
      </ul>

      <h4>Attribute Definition</h4>
      <ul className="-mt-4">
        <li>UI Improvements, increased the Name Value column even more.</li>
      </ul>

      <h4>Bulk Operations</h4>
      <ul className="-mt-4">
        <li>Release date bulk operations UI Upload option for uploading PLU without using a file.</li>
        <li>Added images column to Enable stock and Approve Bulk Operation Table.</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">Bug fixes</h3>
      <ul className="-mt-4">
        <li>(Fixed) - Bulk Operations : New In Date (Date/Time Component) is resetting after 10 seconds</li>
        <li>(Fixed) - Attributes and Categories : Incorrect number of attributes are loading</li>
        <li>(Fixed) - Styles : Style Id, Brand, Product count text message is not displaying in Move pop </li>
      </ul>
    </section>
  );
}

export default Release20240523;
