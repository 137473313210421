import getRequest from '../../../getRequest';

/**
 * Get all Brands from PRISM db.
 *
 * @returns {[
 *   {
 *    brandId: number,
 *    name: string
 *   }
 * ]}
 */
export default async function getBrands() {
  const endpoint = '/oc-product/v1/brands';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Brands')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
