import postRequest from '../../../postRequest';

/**
 * Invalidates the users Auth token.
 *
 * @param {string} token
 * @returns Object from DB
 */
function postRevokeToken(token) {
  const endpoint = '/users/revoke-token';

  const body = new FormData();
  body.append('token', token);

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Revoke Token', null, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postRevokeToken;
