/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20241009() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">Enrichment_24/S18_Saml Security</h2>
      <p className="-mt-4">09th Oct 2024</p>

      <h3 className="font-gothammedium text-neutral-700">
        New Functionality
      </h3>

      <h4>Saml Security</h4>

      <ul className="-mt-4">
        <li>Prism Login now uses SAML Protocol to align with JD Security</li>
        <li>This does not affect any user permissions</li>
      </ul>

      {/* <p><strong>Central PIM access have been shut down.</strong></p> */}

      <h3 className="font-gothammedium text-neutral-700">Fixes</h3>

      <h4>Bulk Operations</h4>

      <ul className="-mt-4">
        <li>(Fixed) - Value uploading time not matching  (Released as a hotfix)</li>
        <li>(Fixed) - Value uploading time not matching  (Released as a hotfix)</li>
      </ul>

      <h4>PLP</h4>

      <ul className="-mt-4">
        <li>Add " Last Indexed" Date to PDP page</li>
      </ul>

    </section>
  );
}

export default Release20241009;
