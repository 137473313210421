import Divider from '@mui/material/Divider';
import React from 'react';
import { useSelector } from 'react-redux';

import getBrandGroups from '../../../../api/oc_product/brandGroup/get/getBrandGroup';
import getBrands from '../../../../api/oc_product/brands/gets/getBrands';
import { BrandGroupingContext } from './BrandGroupingContext';
import BrandGroupingBody from './components/BrandGroupingBody';
import BrandGroupingHeader from './components/BrandGroupingHeader';
import BrandGroupingLogs from './components/BrandGroupingLogs';

function BrandGroupingContainer() {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const [brandGroups, setBrandGroups] = React.useState();
  const [brands, setBrands] = React.useState();
  const [fetchData, setFetchData] = React.useState(false);
  const [reFetchLogs, setReFetchLogs] = React.useState(false);

  const fetchBrandGroups = () => {
    getBrandGroups(siteId)
      .then((res) => setBrandGroups(res.data))
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    fetchBrandGroups();
    getBrands()
      .then((res) => {
        setBrands(res.data);
      })
      .catch((err) => console.error(err));
  }, [siteId]);

  React.useEffect(() => {
    if (fetchData) {
      fetchBrandGroups();
      setFetchData(false);
    }
  }, [fetchData]);

  const setBrandGroupFunc = (data) => {
    setBrandGroups(data);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BrandGroupingContext.Provider value={{
      setFetchData,
      brands,
      reFetchLogs,
      setReFetchLogs,
    }}
    >
      <div className="flex w-[95%] h-full gap-8 pt-2 m-8 mt-4">
        <div className="flex flex-col w-full">
          <BrandGroupingHeader brandGroups={brandGroups} setBrandGroups={setBrandGroupFunc} />
          <div className="mt-6">
            <Divider sx={{ borderTopWidth: 1 }} />
          </div>
          <BrandGroupingBody brandGroups={brandGroups} />
        </div>
        <BrandGroupingLogs />
      </div>
    </BrandGroupingContext.Provider>
  );
}

export default BrandGroupingContainer;
