import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FileSaver from 'file-saver';
import React from 'react';

import getExportTransactions from '../../../api/oc_product/bulk/bulk_process/sites/reports/all_transactions/gets/getExportTransactions';

function ExportJobButton({ taskId, siteId }) {
  const [loading, setLoading] = React.useState(false);

  // const [selectedType, setSelectedType] = React.useState('csv');
  const [selectedStatus, setSelectedStatus] = React.useState(999);
  const [fileName, setFileName] = React.useState(taskId);

  // const handleTypeChange = (value) => {
  //   setSelectedType(value);
  // };
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleExport = () => {
    setLoading(true);
    getExportTransactions(taskId, siteId, selectedStatus === 999 ? null : selectedStatus, fileName, 'export-to-csv-zip')
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, `${fileName}.zip`);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  return (
    <div className="p-4 w-[250px]">
      {loading ? (
        <div className="flex items-center justify-center h-52">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Stack gap={2}>
          <p className="font-gothammedium">
            Export To:
          </p>
          <TextField
            label="Name"
            placeholder={fileName}
            onChange={(e) => setFileName(e.target.value || taskId)}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          {/* <FormControl fullWidth size="small">
            <InputLabel id="type-select-label">
              Type
            </InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              label="Type"
              onChange={(event) => handleTypeChange(event.target.value)}
              value={selectedType}
            >
              <MenuItem value="csv">CSV</MenuItem>
              <MenuItem value="excel">Excel</MenuItem>
              <MenuItem value="pdf">PDF</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl fullWidth size="small">
            <InputLabel id="type-select-label">
              Status
            </InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              label="Type"
              onChange={(event) => handleStatusChange(event.target.value)}
              value={selectedStatus}
            >
              <MenuItem value={999}>All</MenuItem>
              {/* <MenuItem value={4}>Successful</MenuItem> */}
              <MenuItem value={-1}>Failed</MenuItem>
              <MenuItem value={0}>No Change</MenuItem>
              <MenuItem value={1}>Updated</MenuItem>
              {/* I think Created is more user friendly than Inserted */}
              <MenuItem value={2}>Created</MenuItem>
              <MenuItem value={3}>Deleted</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => handleExport()}
          >
            Export
          </Button>
        </Stack>
      )}
    </div>
  );
}

export default ExportJobButton;
