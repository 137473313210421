import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React from 'react';

import Modal from '../../../../components/Modal';

function CreateModal({ open, onCloseModal }) {
  return (
    <Modal
      open={open}
      title="Create Brand"
      buttonLabel="Create"
      buttonOnClick={() => onCloseModal(false)}
      onClose={() => onCloseModal(false)}
      containerWidth="xs"
    >
      <Box className="flex flex-col justify-center my-4">
        <TextField id="standard-basic" label="Name" variant="outlined" />
      </Box>
    </Modal>
  );
}

export default CreateModal;
