import axios from 'axios';
import { toast } from 'react-toastify';

import instanceAxios from '../axios/instanceAxios';
import catchError from './catchError';

/**
 * The HTTP DELETE request method deletes the specified resource.
 *
 * @param {string} endpoint
 * @example
 * "/example/endpoint"
 * @param {object} body - { productId: 123456, siteCode: "JD" }
 * @param {string} errorMessageHeader - Message that proceeds the error message.
 * Usually to prodive context on what exactly has errored.
 * @example
 * "Get Categories"
 * @param {string?} successMessage - "Product has successfully been deleted"
 * @param {object?} headers
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function deleteRequest(endpoint, body, errorMessageHeader, successMessage, headers) {
  return new Promise((resolve, reject) => {
    instanceAxios()
      .delete(endpoint, { data: body, headers })
      .then((response) => {
        if (response.data.Success === false) {
          reject(response.data.ErrorMessage);
        } else {
          if (successMessage?.length > 0) toast.success(successMessage);
          resolve(response);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          catchError(error, errorMessageHeader);
          reject(error);
        }
        console.error(error);
      });
  });
}

export default deleteRequest;
