import React from 'react';

function Release20231025() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">
        Bug Fixes and Improvements
      </h2>
      <p className="-mt-4">25th October 2023</p>

      <h3 className="font-gothammedium text-neutral-700">
        Products Table (PLP)
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          <strong>
            Added Total Stock
          </strong>
          <ul>
            <li>
              Total stock will be shown for each product in the table.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Export All
          </strong>
          <ul>
            <li>
              Exported data reflects the table more accurately. Hidden columns will now be hidden in the exported data file.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Selected columns persist between PLP and PDP
          </strong>
          <ul>
            <li>
              Any hidden/visible will stay hidden/visible when returning to the Products Table.
            </li>
          </ul>
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Product Page (PDP)
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          <strong>
            Total Stock based off Location
          </strong>
          <ul>
            <li>
              Shows the total stock by location, source and overall total.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Variants table sorting.
          </strong>
          <ul>
            <li>
              SKU on the variants table are now sorted like CentralPIM (Shogun first - Everything Else - Fluent last).
            </li>
          </ul>
        </li>
        <li>
          <strong>
            OC Group Renamed
          </strong>
          <ul>
            <li>
              Change OC group or Go Outdoors to JD Outdoors to facia selection.
            </li>
          </ul>
        </li>
      </ol>

      <h3 className="font-gothammedium text-neutral-700">
        Bulk Operations
      </h3>
      <h4>
        Features:
      </h4>
      <ol>
        <li>
          <strong>
            New Operations
          </strong>
          <ul>
            <li>
              Product Types
            </li>
            <li>
              Approve
            </li>
            <li>
              Primary Categories
            </li>
            <li>
              Product Name
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Group or Site Level
          </strong>
          <ul>
            <li>
              Bulk pages will now display Group or Site next to the page selector to information you of the operation level.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            More Failed Information
          </strong>
          <ul>
            <li>
              More Info button will display on some Failed transactions to see more information about that transaction.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Operation Transaction Progress
          </strong>
          <ul>
            <li>
              Added a progress bar to show the percentage complete of your transaction.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Clear Files
          </strong>
          <ul>
            <li>
              Added clear button to file upload pages to remove uploaded files.
            </li>
          </ul>
        </li>
      </ol>
      <h4>
        Bug Fixes:
      </h4>
      <ul>
        <li>
          Fixed Bulk Upload memory leak that was causing the Dev Environment to crash.
        </li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        Security Fixes
      </h3>
      <ul>
        <li>
          Auto Complete fixes.
        </li>
        <li>
          Added Cache control.
        </li>
      </ul>
      <h4>
        Bug Fixes:
      </h4>
      <ul>
        <li>
          Switched to Diagnostic API for sending data to fluent.
        </li>
      </ul>
    </section>
  );
}

export default Release20231025;
