import getRequest from '../../../../getRequest';

/**
 * Post request for product type attribute
 *
 * @param {number} productTypeId product Type Id
 *
 */
export default async function getProductTypesAttribute(productTypeId) {
  const endpoint = `/oc-data-management/v1/product-type/${productTypeId}/attributes`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get product types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
