import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  error: {
    marginTop: '1rem !important',
  },
  groupContainer: {
    position: 'absolute',
    top: '-7rem !important',
    width: 150,
  },
  imageBackgroundGradient: {
    background: 'linear-gradient(#394D5F, #333)',
  },
  model: {
    // backgroundColor: 'white',
    // borderRadius: '5px',
    boxShadow: '0px 0px 15px rgb(206, 206, 206)',
    height: '40rem !important',
    width: '70rem !important',
  },
}));

export default useStyles;
