import postRequest from '../../../postRequest';

/**
 * Post request for brand group logs.
 *
 * @param {number} siteId Site Id
 * @param {number} page Page count
 * @param {number} size Page size
 */
export default function postLogs(
  siteId,
  page,
  size,
  {
    fromDate,
    toDate,
    userName,
  },
) {
  const pageQuery = page ? `?Page=${page}` : '';
  const sizeQuery = size ? `&Size=${size}` : '';
  const endpoint = `/oc-data-management/v1/brand-group/log/${siteId}/${pageQuery}${sizeQuery}`;

  const body = {
    fromDate,
    toDate,
    userName,
  };

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Logs')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
