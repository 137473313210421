import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid } from '@mui/x-data-grid';
import { toNumber } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import patchInReview from '../../../../api/intergration/pim/products/dropshipment/patches/patchInReview';
import patchSetDropshipment from '../../../../api/intergration/pim/products/dropshipment/patches/patchSetDropshipment';
import patchSetFulfilment from '../../../../api/intergration/pim/products/dropshipment/patches/patchSetFulfilment';
import postFulfilment from '../../../../api/intergration/pim/products/dropshipment/posts/postFulfilment';
import postPrices from '../../../../api/intergration/pim/products/dropshipment/posts/postPrices';
import postSizes from '../../../../api/intergration/pim/products/dropshipment/posts/postSizes';
import postSkus from '../../../../api/intergration/pim/products/dropshipment/posts/postSkus';
import theme from '../../../../theme';
import WarningButton from '../../../warning_button/WarningButton';

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="body1">
        Error processing the data from Shogun.
      </Typography>
      <Typography variant="body1">
        Product has been put back into review.
      </Typography>
      <Typography variant="body1" className="font-gothammedium">
        Please click FINISH button and search for the product again in the In-Review table.
      </Typography>
    </Stack>
  );
}

function getChipProps(bool) {
  if (bool) {
    return {
      label: 'Enabled',
      style: {
        color: theme.palette.success[800],
        backgroundColor: theme.palette.success[200],
      },
    };
  }
  return { label: 'Disabled' };
}

function ButtonsToolbar(props, handleClick) {
  const { selected, dropshipSelected } = props;
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: theme.palette.neutral[50],
        p: 1,
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <Stack direction="row" spacing={2}>
        <WarningButton
          label="Enable"
          onSubmit={() => handleClick(1)}
          color="info"
          variant="contained"
          disabled={selected.length < 1 && !dropshipSelected}
        />
        <WarningButton
          label="Disable"
          onSubmit={() => handleClick(0)}
          color="info"
          variant="text"
          disabled={selected.length < 1 && !dropshipSelected}
        />
      </Stack>
    </Box>
  );
}

function EnableFulfilment({ productId, shogunResponse, onChange }) {
  const matches = useMediaQuery('(min-width:600px)');
  const nike_wizard = useSelector((state) => state.nike_wizard);

  const productInfo = useSelector((state) => state.selected_product_data.product_data);

  const createRows = (data) => data?.map(({
    AnatwineId, ShogunId, RRP, Price,
  }, index) => ({
    id: index,
    shogunId: ShogunId,
    anatwineSkuId: AnatwineId,
    rrp: RRP,
    price: Price,
    fulfilmentEnabled: false,
  }));

  const [data, setData] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [dropshipSelected, setDropshipSelected] = React.useState(false);
  const [dropshippableEnabled, setDropshippableEnabled] = React.useState(false);

  const [pageSize, setPageSize] = React.useState(10);

  const [countriesFromPrices, setCountriesFromPrices] = React.useState([]);

  const columns = [
    {
      flex: 1,
      minWidth: 140,
      field: 'shogunId',
      headerName: 'Shogun SKU ID',
    },
    {
      flex: 1,
      minWidth: 140,
      field: 'anatwineSkuId',
      headerName: 'Anatwine SKU ID',
    },
    ...countriesFromPrices,
    { width: 120, field: 'rrp', headerName: 'Anatwine RRP' },
    {
      flex: 1,
      minWidth: 160,
      field: 'price',
      description: 'This is the current price in PIM.',
      headerName: 'Shogun Selling Price',
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'fulfilmentEnabled',
      headerName: 'Fulfilment Enabled',
      renderCell: (params) => (typeof params.value === 'string' ? (
        <p className="whitespace-pre-wrap text-error-600">{params.value}</p>
      ) : (
        <Chip
          classes={{ label: 'font-gothammedium' }}
          size="small"
          {...getChipProps(params.value)}
        />
      )),
    },
  ];

  const handleClick = (isEnabled) => {
    if (dropshipSelected) {
      patchSetDropshipment(
        toNumber(productId),
        'JD',
        'Shogun',
        isEnabled,
      )
        .then(() => {
          toast.success(`${shogunResponse?.ProductDetail.ShogunProductId} has been ${!dropshippableEnabled
            ? 'Enabled' : 'Disabled'}`);
          setDropshippableEnabled(isEnabled);
        })
        .catch((err) => {
          console.error(err);
          toast.error(`Error: EF-HC-PSD-01 - SetDropshipment: ${err.message}`, {
            autoClose: 30000,
          });
        });
    }
    selected.forEach((id) => {
      patchSetFulfilment(
        Number(data[id].skuVariant.VariantId),
        'JD',
        'Shogun',
        isEnabled,
        false,
      )
        .then(() => {
          toast.success(`${data[id].shogunId} has been ${isEnabled
            ? 'Enabled' : 'Disabled'}`);
          const newData = data.map((prev) => ({
            ...prev,
            fulfilmentEnabled: selected.includes(prev.id)
              ? isEnabled : prev.fulfilmentEnabled,
          }));
          setData(newData);
        })
        .catch((err) => {
          console.error(err);
          toast.error(`Error: EF-HC-PSF-02 - SetFulfilment: ${err.message}`, {
            autoClose: 30000,
          });
        });
    });
  };

  const getData = async () => {
    const sizes = new Promise((resolve, reject) => {
      postSizes(productId, 'JD')
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error('generateRows Sizes:', err);
          toast.error(`Error: Sizes: ${err.message}`, {
            autoClose: 30000,
          });
          reject(err);
        });
    });
    const skus = new Promise((resolve, reject) => {
      postSkus(productId, 'JD')
        .then((res) => resolve(res))
        .catch((err) => {
          console.error('generateRows SKUs:', err);
          toast.error(`Error: SKUs: ${err.message}`, {
            autoClose: 30000,
          });
          reject(err);
        });
    });
    const fulfilment = new Promise((resolve, reject) => {
      postFulfilment(productId, 'JD')
        .then((res) => resolve(res))
        .catch((err) => {
          console.error('generateRows Fulfilment:', err);
          toast.error(`Error: Fulfilment: ${err.message}`, {
            autoClose: 30000,
          });
          reject(err);
        });
    });
    const prices = new Promise((resolve, reject) => {
      postPrices(productId, 'JD')
        .then((res) => resolve(res))
        .catch((err) => {
          console.error('generateRows SKUs:', err);
          toast.error(`Error: Prices: ${err.message}`, {
            autoClose: 30000,
          });
          reject(err);
        });
    });
    return Promise.all([sizes, skus, fulfilment, prices])
      .then((values) => values)
      .catch((err) => {
        console.error(err);
        patchInReview(nike_wizard.productId, 'JD', 'Shogun', true)
          .then(() => console.error('Error occurred. Product put back into review'))
          .catch((error) => console.error(error));
      });
  };

  const generateRows = async () => {
    setLoadingData(true);
    try {
      const rows = shogunResponse?.ProductDetail?.Skus?.Sku
        ? createRows(shogunResponse.ProductDetail.Skus.Sku) : [];
      const apiData = await getData();
      const sizesResponse = apiData[0];
      const skusResponse = apiData[1];
      const fulfilmentResponse = apiData[2];
      const pricesResponse = apiData[3];

      const sizeCountryNames = sizesResponse.data.Data.map((obj) => obj.Name);
      const uniqueCountries = Array.from(new Set([...sizeCountryNames])).filter((name) => name !== 'US');
      const toColumns = uniqueCountries.map((str) => ({
        width: 80, field: str, headerName: str,
      }));
      setCountriesFromPrices(toColumns);

      if (productInfo.Data?.length > 0) {
        try {
          const isDShippable = productInfo.Data[0]?.Isdropshippable;
          setDropshippableEnabled(isDShippable);
        } catch (error) {
          console.error(`GenerateRows: ${error}`);
          toast.error(error);
        }
      } else {
        toast.warning('Could not get default Enabled/Disabled values from DB. Enabling/Disabling products will still work.', {
          autoClose: 15000,
        });
      }

      const pricesWithSkusAndSizes = pricesResponse.data.Data.map((obj) => {
        const correctSkus = skusResponse.data.Data
          .filter((sku) => sku.VariantId === obj.VariantId);
        const correctSizes = sizesResponse.data.Data
          .filter((size) => size.VariantId === obj.VariantId);
        const correctFulfilment = fulfilmentResponse.data.Data
          .find((fulfilment) => fulfilment?.VariantId === obj.VariantId);
        return {
          ...obj,
          sizes: correctSizes.map((cr) => ({ [cr.Name]: cr.Size })),
          skuInfo: correctSkus,
          fulfilmentEnabled: correctFulfilment?.IsFulfilmentEnabled,
        };
      });

      const addedPricesRows = rows.map((row) => {
        // This comes from Shogun
        const anatwineskuId = Number(row.anatwineSkuId);

        const shogunPrice = pricesWithSkusAndSizes
          .find((pws) => (pws.skuInfo
            .filter((obj) => Number(obj.SkuId) === anatwineskuId).length > 0
            && pws.Name === 'Shogun'));
        const anatwinePrice = pricesWithSkusAndSizes
          .find((pws) => (pws.skuInfo
            .filter((obj) => Number(obj.SkuId) === anatwineskuId).length > 0
            && pws.Name === 'AnatwineUK'));

        if (shogunPrice || anatwinePrice) {
          const rrp = anatwinePrice?.Rrp;
          const sellingPrice = shogunPrice?.SellingPrice;

          const sizes = shogunPrice?.sizes == null
            ? anatwinePrice?.sizes
            : shogunPrice?.sizes;
          const fulfilmentEnabled = shogunPrice?.fulfilmentEnabled == null
            ? anatwinePrice?.fulfilmentEnabled
            : shogunPrice?.fulfilmentEnabled;
          const arrOfCountryAndSizeEntries = sizes?.map((sizeObj) => Object.entries(sizeObj));
          let countryAndSizeObject = {};
          if (arrOfCountryAndSizeEntries) {
            countryAndSizeObject = Object.fromEntries(arrOfCountryAndSizeEntries.flat());
          }
          const skuVariant = skusResponse.data.Data.find((sku) => sku.SkuId === row.anatwineSkuId);

          return {
            ...row,
            ...countryAndSizeObject,
            fulfilmentEnabled,
            rrp: rrp == null ? 'N/A'
              : new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                .format(rrp),
            price: sellingPrice == null ? 'N/A'
              : new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                .format(sellingPrice),
            skuVariant,
          };
        }
        return row;
      });

      const validation = addedPricesRows.map((row) => {
        const clonedRow = structuredClone(row);
        const mappedRow = new Map(Object.entries(clonedRow));
        if (clonedRow?.price == null || clonedRow?.price === 'N/A') {
          mappedRow.set('fulfilmentEnabled', 'No Price');
        }
        if (clonedRow?.price !== '' && (Number(clonedRow?.price) - 0.05) <= clonedRow?.rrp) {
          mappedRow.set('fulfilmentEnabled', 'Price is less than RRP by 5p or more');
        }
        setLoadingData(false);
        return Object.fromEntries(mappedRow);
      });

      setData(validation);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
      toast.error(`Error Loading Data: ${error.message}`, {
        autoClose: 30000,
      });
    }
  };

  React.useEffect(() => {
    generateRows();
  }, []);

  React.useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <div>
      <div className="sticky top-0 z-10 p-4 bg-white sm:p-8 sm:pb-4 rounded-tl-xl">
        <Typography
          variant={matches ? 'h6' : 'body1'}
          className="font-gothammedium text-neutral-900"
        >
          Enable Fulfilment -
          {' '}
          {!matches && <br />}
          <span className="text-sm text-info-500 sm:text-lg">
            Select products you want to enable or disable.
          </span>
        </Typography>
        <Typography variant="body1">
          Products for JDUK
        </Typography>
      </div>
      <Stack direction="column" spacing={2}>
        <Stack className="mx-6 sm:ml-10 sm:mr-14">
          <Typography variant="body2" className="font-gothambold" gutterBottom>
            Enable For Dropshipping
          </Typography>
          <div className="flex items-center gap-4 sm:gap-6">
            <Checkbox
              onClick={(event) => setDropshipSelected(event.target.checked)}
              checked={dropshipSelected}
              className="p-1 pl-0 sm:px-2"
            />
            <div>
              <Typography
                variant={matches ? 'body2' : 'caption'}
                className="font-gothammedium text-neutral-800"
              >
                Shogun Product Id
              </Typography>
              {shogunResponse && shogunResponse.ProductDetail && (
                <Typography variant={matches ? 'body1' : 'body2'}>
                  {shogunResponse.ProductDetail.ShogunProductId}
                </Typography>
              )}
            </div>
            <div>
              <Typography
                variant={matches ? 'body2' : 'caption'}
                className="font-gothammedium text-neutral-800"
              >
                Anatwine PLU
              </Typography>
              {shogunResponse && shogunResponse.ProductDetail && (
                <Typography variant={matches ? 'body1' : 'body2'}>
                  {shogunResponse.ProductDetail.AnatwineProductId}
                </Typography>
              )}
            </div>
            <Chip
              classes={{ label: 'font-gothammedium' }}
              size="small"
              label={dropshippableEnabled ? 'Enabled' : 'Disabled'}
              style={{
                color: dropshippableEnabled ? theme.palette.success[800]
                  : '',
                backgroundColor: dropshippableEnabled ? theme.palette.success[100]
                  : '',
              }}
            />
          </div>
        </Stack>
        <div className="mx-6 mb-4 sm:ml-10 sm:mr-14 sm:mb-8">
          <Box sx={{ height: 'calc(100vh - 430px)', width: '100%' }}>
            <DataGrid
              rows={data}
              columns={columns}
              loading={loadingData}
              rowSelectionModel={selected}
              onRowSelectionModelChange={(selectionModel) => {
                setSelected(selectionModel);
              }}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={[
                {
                  groupId: 'Sizes',
                  children: countriesFromPrices,
                },
              ]}
              classes={{
                columnSeparator: 'hidden',
                columnHeadersInner: 'bg-neutral-50',
                columnHeaderTitle: 'font-gothambold opacity-60',
              }}
              pagination
              rowsPerPageOptions={[10, 15, 20, 25, 30]}
              pageSize={pageSize}
              density="compact"
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              checkboxSelection
              isRowSelectable={(params) => !(typeof params.row.fulfilmentEnabled === 'string')}
              slots={{
                NoRowsOverlay,
                LoadingOverlay: LinearProgress,
                toolbar: (props) => ButtonsToolbar({ ...props, selected, dropshipSelected }, (value) => {
                  handleClick(value);
                }),
              }}
            />
          </Box>
        </div>
      </Stack>
    </div>
  );
}

export default EnableFulfilment;
