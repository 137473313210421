import patchRequest from '../../../../../patchRequest';

/**
 * Calls product-category endpoint to update categories the product is linked to in PIM DB.
 *
 * @param {number} productId - 15974689
 * @param {number} categoryId - 1
 * @returns {Promise} { data: { Success: true, ErrorMessage: null }, status: 200 }
 */
async function patchProductCategory(
  productId,
  categoryId,
) {
  const endpoint = '/integration-pim/v1/products/drop-shipment/product-category';

  const body = {
    productId,
    categoryId,
  };

  return new Promise((resolve, reject) => {
    patchRequest(endpoint, body, 'Update Category')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default patchProductCategory;
