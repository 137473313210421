import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';

import { AttributeDefinitionsContext } from '../../AttributeDefinitionsContext';

function IncludeInSearchKeywordsFilter() {
  const { includeInSearchKeywords, setIncludeInSearchKeywords } = useContext(AttributeDefinitionsContext);

  const handleChange = (e) => {
    const val = e.target.value === '' ? null : e.target.value;
    setIncludeInSearchKeywords(val);
  };

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Include In Search Keywords</Typography>
        <div className="flex flex-col w-max">
          <RadioGroup
            aria-labelledby="include-in-search-keywords-radio-buttons-group-label"
            defaultValue={null}
            value={includeInSearchKeywords}
            name="include-in-search-keywords-radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel value={null} control={<Radio />} label="All" />
            <FormControlLabel value control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </div>
      </Stack>
    </div>
  );
}

export default IncludeInSearchKeywordsFilter;
