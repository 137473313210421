import postRequest from '../../../postRequest';

/**
 * Post request to export all products to a Excel.
 *
 * @param {string} fileName Name of the file
 * @param {{ searchRequest: object; columnsToExport: string[]; }} body Body of search filters and columns wanted to export
 */
export default function postExportToExcelZip(fileName, body) {
  const endpoint = `/oc-product/v1/export/export-to-excel-zip?fileName=${fileName}`;

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Export Product To Excel', null, {
      responseType: 'arraybuffer',
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
