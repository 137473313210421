import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import React, { useContext, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getAttributeDefinitionConfirmation from '../../../../../api/oc_product/productDataManagement/attributes/get/getAttributeConfirmation';
import putAttribute from '../../../../../api/oc_product/productDataManagement/attributes/put/putAttribute';
import Modal from '../../../../../components/Modal';
import { AttributeDefinitionsContext } from '../../AttributeDefinitionsContext';
import ConfirmationModal from './ConfirmationModal ';

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL_STATE':
      return action.payload;
    case 'ATTRIBUTE_DEF_KEY':
      return {
        ...state,
        definitionKey: action.payload,
      };
    case 'NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'ENGLISH_NAME':
      return {
        ...state,
        eName: action.payload,
      };
    case 'SITES':
      return {
        ...state,
        sites: action.payload,
      };
    case 'ATTRIBUTE_DATA_TYPE':
      return {
        ...state,
        attributeDataType: action.payload,
      };
    case 'READ_ONLY':
      return {
        ...state,
        isReadOnly: action.payload,
      };
    case 'IS_MANDATORY':
      return {
        ...state,
        isMandatory: action.payload,
      };
    case 'HIDDEN':
      return {
        ...state,
        isHidden: action.payload,
      };
    case 'SEARCH_KEYWORDS':
      return {
        ...state,
        searchKeywords: action.payload,
      };
    case 'LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows } = props;

  const handleClick = () => {
    const id = rows.length + 1;
    setRows((oldRows) => [{
      id, name: '', isDefaultValue: false,
    }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function CreateModal({
  onClose, open, attributeDataTypes, fetchData,
}) {
  const { siteOptions, setReRenderLogs, languageOptions } = useContext(AttributeDefinitionsContext);
  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const isLargeScreen = useMediaQuery('(min-width:2000px)');
  const largeScreenWidth = isLargeScreen ? '60%' : '90%';
  const defLang = languageOptions.find((obj) => obj.LanguageId === languageId);
  const initialFormState = {
    definitionKey: '',
    name: '',
    eName: '',
    sites: [],
    attributeDataType: null,
    readOnly: false,
    isMandatory: false,
    isHidden: false,
    searchKeywords: false,
    language: defLang,
  };

  const [formState, dispatch] = useReducer(reducer, initialFormState);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [defKeyValidation, setDefKeyValidation] = React.useState(false);
  const [selectedSites, setSelectedSites] = React.useState([]);
  const [newSites, setNewSites] = React.useState([]);
  const [oldSites, setOldSites] = React.useState([]);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [selectedDataType, setSelectedDataType] = React.useState(null);

  const updateDisableBtn = () => {
    const isInEditMode = Object.values(rowModesModel).some((mode) => mode.mode === GridRowModes.Edit);
    setDisableBtn(isInEditMode);
    if (formState.definitionKey === '' || formState.name === '' || formState.eName === '' || formState.attributeDataType === null) {
      setDisableBtn(true);
    }
  };

  const handleClose = () => {
    onClose(false);
    dispatch({ type: 'INITIAL_STATE', payload: initialFormState });
    setLoading(false);
    setRows([]);
    setSelectedSites([]);
  };

  const handleCreateAttributeDef = async () => {
    const body = {
      productAttributeDefKey: formState.definitionKey,
      englishName: formState.eName,
      name: formState.name,
      language: formState.language.LanguageName,
      languageId: formState.language.LanguageId,
      siteIds: newSites.some((item) => item.SiteId === 0 || item.SiteId === null) ? [] : newSites.map((item) => item.SiteId),
      attributeDataTypeId: formState.attributeDataType.AttributeDataTypeId,
      attributeDataTypeName: formState.attributeDataType.Name,
      isReadOnly: formState.isReadOnly,
      allowUpdate: !formState.isReadOnly,
      isMandatory: formState.isMandatory,
      isHidden: formState.isHidden,
      includeInSearchKeywords: formState.searchKeywords,
      attributeLookupValues: rows.map((item) => ({
        languageId: 1,
        value: item.name,
        isDefaultValue: item.isDefaultValue,
      })),
    };
    try {
      const res = await putAttribute(body);
      if (res) {
        toast.success('Attribute successfully created');
      }
    } catch (error) {
      console.error('Error creating attribute:', error);
    } finally {
      setReRenderLogs(true);
      fetchData();
      setDisableBtn(true);
      handleClose();
      setNewSites([]);
      setOldSites([]);
      setSelectedSites([]);
      setLoading(false);
    }
  };
  const handleAttributeDefValidation = async (productAttributeDefKey, siteIds, attributeDataTypeId) => {
    const body = {
      productAttributeDefKey,
      siteIds,
      attributeDataTypeId,
    };
    try {
      setDisableBtn(false);
      const res = await getAttributeDefinitionConfirmation(body);
      if (res) {
        const newSiteData = res.data.SiteStatus.filter((site) => site.IsNew === true);
        const oldSiteData = res.data.SiteStatus.filter((site) => site.IsNew === false);
        setNewSites(newSiteData);
        setOldSites(oldSiteData);
      }
    } catch (error) {
      setNewSites([]);
      setOldSites([]);
      setDisableBtn(true);
      console.error('Error creating attribute:', error);
    }
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleDefKeyChange = (val) => {
    const isValidInput = /^[a-zA-Z0-9_]*$/.test(val);
    if (isValidInput) {
      dispatch({ type: 'ATTRIBUTE_DEF_KEY', payload: val });
      setDefKeyValidation(false);
    } else {
      setDefKeyValidation(true);
    }
  };
  const getconfirmation = () => {
    if (selectedSites.length > 0 || oldSites.length > 0) {
      setShowConfirmation(true);
    } else {
      setNewSites([{ SiteId: 0, SiteName: 'Global', IsNew: true }]);
      setShowConfirmation(true);
    }
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleConfirm = async (confirmed) => {
    try {
      if (confirmed) {
        setShowConfirmation(false);
        handleCreateAttributeDef();
      } else {
        setShowConfirmation(false);
      }

      // toast.success('Attribute successfully created for new sites');
    } catch (error) {
      toast.error('An error occurred during finalization');
    }
  };
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: true,
    },
    {
      field: 'isDefault',
      headerName: 'Is Default Value',
      type: 'boolean',
      width: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  React.useEffect(() => {
    console.log(selectedDataType);
  }, [setSelectedDataType]);

  React.useEffect(() => {
    // Call handleAttributeDefValidation whenever these dependencies change
    if (formState.definitionKey && formState.attributeDataType) {
      handleAttributeDefValidation(formState.definitionKey, selectedSites.map((site) => site.SiteId), formState.attributeDataType.AttributeDataTypeId);
    }
  }, [formState.definitionKey, selectedSites, formState.attributeDataType]);

  React.useEffect(() => {
    if (languageId && languageOptions.length > 0) {
      const defaultLang = languageOptions.find((obj) => obj.LanguageId === languageId);
      dispatch({ type: 'LANGUAGE', payload: defaultLang });
    }
  }, [languageId, languageOptions]);

  React.useEffect(() => {
    if (siteId && siteOptions.length > 0) {
      const defSite = siteOptions.find((obj) => obj.SiteId === siteId);
      setSelectedSites([defSite]);
      dispatch({ type: 'SITES', payload: [defSite.SiteId] });
    }
  }, [siteOptions]);

  React.useEffect(() => {
    updateDisableBtn();
  }, [rowModesModel]);

  React.useEffect(() => {
    updateDisableBtn();
  }, [formState]);

  return (
    <Modal
      open={open}
      title="Create Attribute Definition & Values"
      buttonLabel="Save"
      buttonOnClick={getconfirmation}
      onClose={handleClose}
      desktopModalWidth={
        (formState?.attributeDataType
          && (formState.attributeDataType.AttributeDataTypeId === 8 || formState.attributeDataType.AttributeDataTypeId === 9))
          ? largeScreenWidth : '40%'
      }
      containerWidth="xl"
      buttonLoading={loading}
      disableButton={disableBtn}
    >
      {/* {showConfirmation && ( */}
      <ConfirmationModal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => handleConfirm(true)} // Pass true for confirmation
        onCancel={() => handleConfirm(false)} // Pass false for cancellation
        newSites={newSites}
        oldSites={oldSites}
      />

      <div className="flex flex-row min-h-[200px]">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              error={defKeyValidation}
              label="Definition Key"
              id="definitionKey"
              helperText={
                defKeyValidation ? 'No spaces or special characters except _' : 'You will not be able to edit this once saved.'
              }
              value={formState.definitionKey}
              onChange={(e) => handleDefKeyChange(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Name"
              id="name"
              variant="outlined"
              value={formState?.name}
              onChange={(e) => {
                if (formState.language) {
                  if (formState.language.LanguageName !== 'English') {
                    dispatch({ type: 'NAME', payload: e.target.value });
                  } else {
                    dispatch({ type: 'NAME', payload: e.target.value });
                    dispatch({ type: 'ENGLISH_NAME', payload: e.target.value });
                  }
                }
              }}
            />
          </Grid>

          {
            formState.language
            && (formState.language.LanguageName !== 'English') && (
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="English Name"
                  id="englishName"
                  variant="outlined"
                  value={formState?.eName}
                  onChange={(e) => dispatch({ type: 'ENGLISH_NAME', payload: e.target.value })}
                />
              </Grid>
            )
          }

          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="sites-tags"
              limitTags={2}
              options={siteOptions}
              getOptionLabel={(option) => option.Name || ''}
              filterSelectedOptions
              value={selectedSites}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sites"
                />
              )}
              onChange={(e, value) => {
                const siteIds = value.map((site) => site.SiteId);
                dispatch({ type: 'SITES', payload: siteIds });
                setSelectedSites(value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="attribute-data-types"
              options={attributeDataTypes}
              getOptionLabel={(option) => option.Description || ''}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Attribute Data Types"
                />
              )}
              onChange={(e, value) => {
                setSelectedDataType(value);
                dispatch({ type: 'ATTRIBUTE_DATA_TYPE', payload: value });
              }}
            />
          </Grid>

          <Grid container item xs={12}>
            <FormGroup row className="flex justify-between">
              <FormControlLabel
                control={<Checkbox checked={formState?.isReadOnly} onChange={(e) => dispatch({ type: 'READ_ONLY', payload: e.target.checked })} />}
                disableTypography
                sx={{ fontSize: '14px' }}
                label="Read Only"
                id="read-only"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={formState?.isMandatory} onChange={(e) => dispatch({ type: 'IS_MANDATORY', payload: e.target.checked })} />
                }
                disableTypography
                sx={{ fontSize: '14px' }}
                label="Is Mandatory"
                id="is-mandatory"
              />
              <FormControlLabel
                control={<Checkbox checked={formState?.isHidden} onChange={(e) => dispatch({ type: 'HIDDEN', payload: e.target.checked })} />}
                disableTypography
                sx={{ fontSize: '14px' }}
                label="Hidden"
                id="hidden"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={formState?.searchKeywords} onChange={(e) => dispatch({ type: 'SEARCH_KEYWORDS', payload: e.target.checked })} />
                }
                disableTypography
                sx={{ fontSize: '14px' }}
                label="Include in Search Keywords?"
                id="search-keywords"
              />
            </FormGroup>
          </Grid>
        </Grid>
        {
          (formState?.attributeDataType
            && (formState.attributeDataType.AttributeDataTypeId === 8 || formState.attributeDataType.AttributeDataTypeId === 9)) && (
            <>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className="mx-10"
              />
              <Grid container spacing={4} className="mb-5">
                <Grid item xs={12}>
                  <Autocomplete
                    options={languageOptions.map((obj) => ({
                      ...obj,
                      Name: obj.LanguageName,
                    }))}
                    value={formState?.language}
                    renderInput={(params) => (
                      <TextField {...params} label="Language" />
                    )}
                    getOptionLabel={(option) => option.LanguageName}
                    size="small"
                    onChange={(e, value) => {
                      dispatch({ type: 'LANGUAGE', payload: value });
                    }}
                    optionKey="LanguageName"
                    className="w-[250px] mb-2"
                  />
                  <DataGrid
                    columns={columns}
                    rows={rows}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 15,
                        },
                      },
                    }}
                    className="bg-white cursor-pointer"
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                      toolbar: EditToolbar,
                    }}
                    slotProps={{
                      toolbar: { setRows, setRowModesModel, rows },
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )
        }
      </div>
    </Modal>
  );
}

export default CreateModal;
