import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles({
  paper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
  },
});

function StockLocationFilter({ locations }) {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const stockLocation = searchParams.get('stockLocation');

  const [selectedLocation, setSelectedLocation] = React.useState(null);

  React.useEffect(() => {
    if (stockLocation) {
      if (locations.length > 0) {
        const selected = locations.find((obj) => obj.SourceLocationId === Number(stockLocation));
        setSelectedLocation(selected);
      }
    } else {
      setSelectedLocation(null);
    }
  }, [locations, stockLocation]);

  return (
    <div className="w-max">
      <Stack spacing={2}>
        <Typography variant="body2">Stock Location</Typography>
        <div className="flex flex-col w-[215px]">
          <Autocomplete
            id="stock-location-autocomplete"
            size="small"
            options={locations}
            getOptionLabel={(option) => `${option.LocationRef} - ${option.Description}`}
            value={selectedLocation}
            filterSelectedOptions
            limitTags={2}
            onChange={(e, value) => {
              setSearchParams((params) => {
                if (value === null) {
                  params.delete('stockLocation');
                } else {
                  params.set('stockLocation', value.SourceLocationId);
                }
                return params;
              });
              setSelectedLocation(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Locations"
                placeholder="Select a location"
                id="stock-location-textfield"
              />
            )}
            classes={{ paper: classes.paper }}
          />
        </div>
      </Stack>
    </div>
  );
}

export default StockLocationFilter;
