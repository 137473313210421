import getRequest from '../../../../../getRequest';

/**
 * Get the Operation Types mapping for bulk operations.
 *
 * @returns Types mapping
 */
export default function getOperationTypes() {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/reports/bulk-operation-types';

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Operation Types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
