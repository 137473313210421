import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';

import Modal from '../../../components/Modal';

function EditModal({ open, onCloseModal, product }) {
  return (
    <Modal
      title="Edit Pricing"
      buttonLabel="Save"
      buttonOnClick={() => onCloseModal(true)}
      open={open}
      onClose={() => onCloseModal(false)}
      containerWidth="xs"
    >
      <Box>
        <Typography variant="body1" textAlign="center" className="bold">
          Product ID:
          {' '}
          {product}
        </Typography>

        <Stack spacing={2} direction="column" alignItems="center" marginTop={2}>
          <TextField
            id="RRP-field"
            label="RRP"
          />
          <TextField
            id="Sell-field"
            label="Sell"
          />
          <TextField
            id="Cost-field"
            label="Cost"
          />
        </Stack>
      </Box>
    </Modal>
  );
}

export default EditModal;
