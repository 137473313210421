import { TablePagination } from '@mui/material';
import React from 'react';

function ColourTable({
  language,
  rowsData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  colorHexCodes,
}) {
  const paginatedData = rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className="flex flex-col w-4/5 p-8 mt-5 bg-white mx-auto shadow-lg">
      {language === 1 ? (
        <table className="min-w-full divide-y divide-gray-200">
          <tbody>
            {paginatedData && paginatedData.length > 0 ? (
              paginatedData.map((group) => (
                <div key={group.SwatchColour1} className="bg-white shadow-md rounded-lg p-6">
                  <div className="flex items-center space-x-3 mb-2">
                    <h3 className="text-xl font-bold text-gray-800">{group.SwatchColour1}</h3>
                    <div
                      style={{
                        backgroundColor: colorHexCodes[group.SwatchColour1] || '#CCCCCC', // Fallback color if not found
                      }}
                      className="w-7 h-7 rounded-full mr-2 inline-block"
                    />
                  </div>
                  <div className="flex flex-wrap gap-2 custom-scrollbar overflow-y-scroll max-h-52">
                    {group.Colours && group.Colours.map((colour) => (
                      <div key={colour.SwatchColourId} className="bg-gray-200 text-gray-900 px-3 py-1 rounded-md">
                        {colour.Colour}
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <tr>
                <td colSpan={2} className="px-6 py-4 text-center">
                  No Colours Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-2 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider border-b">
                Uk Colour
              </th>
              <th className="px-2 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider border-b">
                Translated Swatch Colour
              </th>
              <th className="px-2 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider border-b">
                colour
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedData.map((colourItem, index) => (
              <tr key={colourItem.TranslatedColourId} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-2 py-2 text-center text-sm font-medium text-gray-800 border-b">
                  <div className="w-full flex justify-center">
                    {colourItem.Colour}
                  </div>
                </td>
                <td className="px-2 py-2 text-center text-sm text-gray-600 border-b">
                  <div className="w-full flex justify-center">{colourItem.TranslatedColour}</div>
                </td>
                <td className="px-2 py-2 text-center text-sm text-gray-600 border-b">
                  <div
                    style={{
                      backgroundColor: colorHexCodes[colourItem.Colour] || '#CCCCCC', // Fallback color if not found
                    }}
                    className="w-7 h-7 rounded-full mr-2 inline-block"
                  >
                    {/* Display hex code next to color name */}
                  </div>
                </td>
              </tr>
            ))}
            {paginatedData.length === 0 && (
              <tr>
                <td colSpan={2} className="px-6 py-4 text-center">
                  No Colours Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <TablePagination
        component="div"
        count={rowsData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]} // Adjust as needed
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}

export default ColourTable;
