import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import JDGroup from '../../assets/JDGroupLogo.png';
import LoginLogo from '../../assets/LoginLogo.png';
import { removeLocalStorage } from '../../helper/LocalStorage';
import useStyles from './styles';

function Login() {
  const styles = useStyles();

  const [bIsLoading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const errorFromUrl = searchParams.get('error');

  React.useEffect(() => {
    if (errorFromUrl) {
      const url = new URL(window.location.href); // Get the current URL
      url.searchParams.delete('error'); // Remove the specified query parameter
      window.history.replaceState({}, '', url.toString()); // Update the URL without reloading
    }
  }, [errorFromUrl, searchParams]);

  const onSubmitForm = async (event) => {
    event.preventDefault();

    setLoading(true);

    removeLocalStorage('logout-event');

    window.location.href = '/auth/login';
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen bg-neutral-200">
      <Grid container classes={{ root: styles.model }} className="bg-white rounded-lg">

        {/* Left */}
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          className={[
            styles.imageBackgroundGradient,
            'flex items-center justify-center rounded-tl-lg rounded-bl-lg overflow-hidden',
          ].join(' ')}
        >
          <Box className="flex flex-col items-center w-1/2 h-1/2 sm:block">
            <img src={LoginLogo} className="hidden object-contain w-full h-full mb-8 sm:block" alt="Prism" />
          </Box>
        </Grid>

        {/* Right */}
        <Grid item xs={12} sm={8} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center" alignItems="flex-end" sx={{ height: '10rem' }}>
              <Box sx={{ width: 150 }}>
                <img src={JDGroup} alt="JD Group" />
              </Box>
            </Grid>

            <Grid item xs={12} textAlign="center" className="mt-20">
              <Typography component="h5" variant="h5" color="secondary" gutterBottom>
                Welcome to
                {' '}
                <span className="text-2xl bold">PRISM</span>
              </Typography>
              <Typography component="p" variant="p">
                Sign in to PRISM with your JD account to access.
              </Typography>
            </Grid>

            <Grid item xs={12} container justifyContent="center">
              <form
                className="flex flex-col items-center justify-center w-3/4"
                onSubmit={onSubmitForm}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  color="secondary"
                  className="px-12 my-6"
                  loading={bIsLoading}
                >
                  Sign In
                </LoadingButton>
                <Typography color="error" variant="body2" className="mt-2">
                  {errorFromUrl}
                </Typography>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
