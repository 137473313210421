/* eslint-disable no-nested-ternary */
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import getSources from '../../api/oc_product/sites/sources/gets/getSources';
import postStyles from '../../api/oc_product/styles/post/postStyles';
import splitString from '../../utils/splitIdString';
import DataTable from './components/DataTable';
import Filter from './components/filters/Filter';
import { StylesContext } from './StylesContext';

function Styles() {
  const [searchedData, setSearchedData] = useState({});
  const [pagination, setPagination] = useState({});
  const [summary, setSummary] = useState({});
  const [filterParams, setFilterParams] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [stylesReRender, setStylesReRender] = useState(false);
  const [siteSources, setSiteSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const idType = searchParams.get('idType');
  const ids = searchParams.get('ids');
  const name = searchParams.get('name');
  const brandIds = splitString(searchParams.get('brands'));
  const styleIds = splitString(searchParams.get('styleId'), 'number');

  const siteCode = useSelector((state) => state.user.loginResponse.selectedSite.SiteCode);
  const hasStyle = searchParams.get('hasStyle');

  const productIds = idType === 'productIds' ? splitString(ids, 'number') : [];
  const plus = idType === 'plus' ? splitString(ids) : [];

  const convertToBoolean = (value) => {
    const mapping = {
      yes: true,
      no: false,
      true: true,
      false: false,
    };

    return mapping[value] !== undefined ? mapping[value] : null;
  };

  const fetchStyles = async (newPage, itemsPerPage) => {
    const page = Number(newPage) || 1;
    const rowsPerPage = itemsPerPage || 10;
    try {
      setLoading(true);
      const requestBody = {
        siteId,
        siteCode,
        name,
        styleIds,
        productIds,
        plus,
        brandIds,
        hasStyle: convertToBoolean(hasStyle),
      };
      const stylesData = await postStyles(page, rowsPerPage, requestBody);

      setSearchedData(stylesData.data.Data);
      setPagination(stylesData.data.Pagination);
      setSummary(stylesData.data.Summary);
    } catch (error) {
      toast.error('Error fetching styles', error);
      console.error('Error fetching styles:', error);
    } finally {
      setStylesReRender(false);
      setLoading(false);
    }
  };

  const handleFilterChange = (params) => {
    setFilterParams(params);
  };

  const clearDataTable = () => {
    setSearchedData({});
  };

  const contextValue = React.useMemo(() => ({
    stylesReRender,
    setStylesReRender,
    siteSources,
  }), [
    stylesReRender,
    setStylesReRender,
    siteSources,
  ]);

  React.useEffect(() => {
    setSearchParams((params) => {
      params.delete('pageSize');
      params.delete('page');
      params.delete('idType');
      params.delete('ids');
      params.delete('name');
      params.delete('brands');
      params.delete('name');
      params.delete('styleId');
      params.delete('hasStyle');
      return params;
    });
  }, [siteId]);

  React.useEffect(() => {
    if (stylesReRender) {
      fetchStyles();
    }
  }, [stylesReRender]);

  React.useEffect(() => {
    getSources(siteId)
      .then((res) => setSiteSources(res.data))
      .catch((err) => console.error(err));
  }, [siteId]);

  return (
    <div className="flex flex-row flex-wrap gap-8 pt-2 m-8 mt-4">
      <Typography variant="h5" className="my-6 text-secondary-600">Styles</Typography>
      <Filter
        onFilterChange={handleFilterChange}
        onSearch={fetchStyles}
        onClickClearTable={clearDataTable}
      />
      {searchedData.length >= 1 ? (
        <StylesContext.Provider value={contextValue}>
          <DataTable
            searchedData={searchedData}
            setSearchedData={setSearchedData}
            pagination={pagination}
            summary={summary}
            filterParams={filterParams}
            onSearch={fetchStyles}
            onClickClearTable={clearDataTable}
            requestBody={{
              siteId,
              siteCode,
              name,
              styleIds,
              productIds,
              plus,
              brandIds,
              hasStyle,
            }}
          />
        </StylesContext.Provider>
      ) : (
        loading ? (
          <div className="flex items-center justify-center w-full">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Typography variant="h6" className="w-full text-center font-gothamlight">
            No results found
          </Typography>
        )
      )}

    </div>
  );
}

export default Styles;
